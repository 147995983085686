import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import WarehouseService from "../../../app/data/warehouse/warehouseService";
import { TransactionsListRequestModel } from "../../../app/data/warehouse/models";
import { initialTransactionsState } from "./TransactionsState";

const warehouseService = WarehouseService.getInstance();

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState: initialTransactionsState,
  reducers: {
    resetTransactionsState: (state) => initialTransactionsState,
    requestStarted: (state, { payload }) => {
      state.request = payload;
      if (state.request.lastIds) {
        state.requestStarted = true;
      } else {
        state.initialRequestStarted = true;
      }
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestError = null;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.initialRequestStarted = false;
      state.requestFailed = true;
      state.requestSucceed = false;
      state.requestError = payload;
    },
    requestSucceed: (state, { payload }) => {
      state.requestStarted = false;
      state.initialRequestStarted = false;
      state.requestFailed = false;
      state.requestSucceed = true;
      state.requestError = null;
      state.requestedAll = !!(payload.content.length < 50);
      state.list = state.request.lastIds ? [...state.list, ...payload?.content] : payload?.content;
      state.request.lastIds = payload.scroll?.lastIds;
    } 
  }
});

export const {
  resetTransactionsState,
  requestStarted,
  requestFailed,
  requestSucceed
} = transactionsSlice.actions;

export const transactionsSelector = (state: IState) => {
  return state.transactions;
};

export const getTransactionsList = (
  subAccountId: string,
  request: TransactionsListRequestModel,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted(request));
  const response = await warehouseService.getTransactionsList(subAccountId, request);
  if (response.ok()) {
    dispatch(requestSucceed(response.data));
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

const transactionsReducer = transactionsSlice.reducer;
export default transactionsReducer;
