import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { Form, Formik, FormikProps } from "formik";
import mixpanel from "mixpanel-browser";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import RatesState from "../../../slices/rates/RatesState";
import {
  sendQuoteEmail,
  ratesSelector  
} from "../../../slices/rates/ratesSlice";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import {
  EmailQuoteModel,
  EmailQuoteSchema
} from "../../../app/data/rates/models";
import modalStyle from "../../../app/data/common/modalStyle"
import "../../../sass/modal.scss";

Modal.setAppElement("#root");

export interface ShareQuoteProps {
  quoteId: string | undefined;
}

let initialValues: EmailQuoteModel = {
  email: ""
};

const ShareQuote: React.FC<ShareQuoteProps> = (props) => {
  const ratesState: RatesState = useSelector(ratesSelector);
  const dispatch = useDispatch();
  const [modalOpened, setModalOpened] = useState<boolean>(false);

  const onClickSend = (data: EmailQuoteModel) => {
    if (!props.quoteId || !data.email) return;
    dispatch(sendQuoteEmail(data.email, props.quoteId, () => {
      toast.info("The quote was sent successfully!");
      mixpanel.track("Rate Quote was sent by email");
      setModalOpened(false);
    }));
  };

  useEffect(() => {
    if (!ratesState.requestFailed) return;
    toast.error(ratesState.requestError || "Error", { autoClose: 4000 });
  }, [ratesState.requestFailed, ratesState.requestError]);

  return (
    <>
      <div
        className="xgs-site__content-container__toolbar__button"
        onClick={() => setModalOpened(true)}
      >
        <XGSIcon 
          className="xgs-site__content-container__toolbar__button__icon"
          icon={XGSIcons.faShareAlt}
          size="lg"
          title="Share quote"
        />
        <div className="xgs-site__content-container__toolbar__button__label">Share</div>
      </div>
      <Modal
        isOpen={modalOpened}
        style={modalStyle}
      >
        <div className="xgs-modal__header">
          Send quote via email
        </div>
        <Formik
          onSubmit={onClickSend}
          initialValues={initialValues}
          validationSchema={EmailQuoteSchema}
        >
          {(props: FormikProps<EmailQuoteModel>) => (
            <Form>        
              <div className="xgs-modal__content">
                <XGSFormInput
                  type="text"
                  name="email"
                  label="Email to:"
                  labelMode={LabelModes.column}
                  required={true}
                  requiredAsteriskDisabled={true}
                  placeholder="name@domain.com"
                />
              </div>
              <div className="xgs-modal__buttons">
                <Button
                  theme={ButtonThemes.blue}
                  spinner={ratesState.requestStarted && ratesState.requestCreator === "SEND_QUOTE"}
                  type="submit"
                  className="xgs-modal__button"
                >
                  Send
                </Button>
                <Button
                  theme={ButtonThemes.gray}
                  onClick={() => setModalOpened(false)}
                  type="button"
                  className="xgs-modal__button"
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ShareQuote;
