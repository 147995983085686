
export const groups = [
  { 
    label: "Deliveries at risk",
    value: "DELIVERIES_AT_RISK",
    parentLabel: "At Risk Details"
  },
  { 
    label: "Very late",
    value: "VERY_LATE",
    parentLabel: "ETA Details"
  },
  { 
    label: "Late",
    value: "LATE",
    parentLabel: "ETA Details"
  },
  { 
    label: "On time",
    value: "ON_TIME",
    parentLabel: "ETA Details"
  },
  { 
    label: "Will call",
    value: "WILL_CALL",
    parentLabel: "Loads Snapshot"
  },
  { 
    label: "Out for delivery",
    value: "OUT_FOR_DELIVERY",
    parentLabel: "Loads Snapshot"
  },
  { 
    label: "In transit",
    value: "IN_TRANSIT",
    parentLabel: "Loads Snapshot"
  },
  { 
    label: "Delivered",
    value: "DELIVERED",
    parentLabel: "Loads Snapshot"
  }
];
