import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import ChatService from "../../app/data/chat/chatService";
import { ChatMessageModel } from "../../app/data/chat/models";
import { initialChatState } from "./ChatState";

const chatService = ChatService.getInstance();

export const chatSlice = createSlice({
  name: "chat",
  initialState: initialChatState,
  reducers: {
    resetChatState: (state) => initialChatState,
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestError = null;
      state.requestCreator = payload;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = true;
      state.requestSucceed = false;
      state.requestError = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestFailed = false;
      state.requestSucceed = true;
      state.requestError = null;
    },
    setChatId: (state, { payload }) => {
      state.chatId = payload;
    },
    setProbillNumber: (state, { payload }) => {
      state.probillNumber = payload;
    },
    addMessage: (state, { payload }) => {
      state.messages = [...state.messages, payload];
    },
    setAnimation: (state, { payload }) => {
      state.animation = payload;
    },
  }
});

export const {
  resetChatState,
  requestStarted,
  requestFailed,
  requestSucceed,
  setChatId,
  setProbillNumber,
  addMessage,
  setAnimation,
} = chatSlice.actions;

export const chatSelector = (state: IState) => {
  return state.chat;
};

export const sendChatMessage = (
  message: ChatMessageModel,
  onError?: (error: string | {}) => void,
): AppThunk => async (dispatch, getState) => {
  dispatch(setAnimation(true));

  dispatch(addMessage(message));  

  const { chat } = getState();

  const data = {
    chatId: chat.chatId,
    probillNumber: chat.probillNumber,
    ...message.promptId ? { promptId: message.promptId } : { query: message.text },
  };

  setTimeout(async () => {
    dispatch(requestStarted("SEND_MESSAGE"));

    const response = await chatService.sendMessage(data);
  
    if (response.ok()) {
      dispatch(requestSucceed());    
      dispatch(addMessage({
        incoming: true,
        text: response.data.responseMessage,
        prompts: response.data.chatPrompts,
      }));
  
      if (!chat.chatId) {
        dispatch(setChatId(response.data.chatId));
      }
    } else {
      dispatch(requestFailed(response.getError?.() || "Error"));
      onError?.(response.getError?.() || "Error");
    }
  }, 500);  
};

const chatReducer = chatSlice.reducer;
export default chatReducer;
