
export default interface ExportState {
  exportId?: string;
  exportLink: string | null | undefined;
  exportLoading: boolean;
}

export const initialExportState: ExportState = {
  exportId: undefined,
  exportLink: null,
  exportLoading: false
};
