import React, { ReactNode } from "react";

import "./markerTooltip.scss";

interface XGSMarkerTooltipProps {
  children?: ReactNode; 
}

const XGSMarkerTooltip: React.FC<XGSMarkerTooltipProps> = (props) => (
  <div className="marker-tooltip">
    {props.children}
  </div>
);

export default XGSMarkerTooltip;