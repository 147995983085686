import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import ContactService from "../../../app/data/contact/contactService";
import { Contact } from "../../../app/data/contact/models";
import { initialTeamAccountContactState } from "./teamAccountContactState";
import { ApiResponse } from "../../../app/data/api";

const contactService = ContactService.getInstance();

export const teamAccountContactSlice = createSlice({
  name: "teamAccountContact",
  initialState: initialTeamAccountContactState,
  reducers: {
    reset_team_account_contact_state: (state) => initialTeamAccountContactState,
    request_was_started: (state, { payload }) => {
      state.request_creator = payload;
      state.request_was_started = true;
      state.request_was_succeed = false;
      state.request_was_failed = false;
      state.request_fail_reason = null;
    },
    request_was_succeed: (state) => {
      state.request_was_succeed = true;
      state.request_was_started = false;
      state.request_was_failed = false;
    },
    request_was_failed: (state, { payload }) => {
      state.request_was_failed = true;
      state.request_was_succeed = false;
      state.request_was_started = false;
      state.request_fail_reason = payload;
    },
    set_account_contacts: (state, { payload }) => {
      state.contacts = payload;
    },
    add_account_contact_locally: (state, {payload}) => {
      state.contacts = [...state.contacts, payload];
    },
    edit_account_contact_locally: (state, {payload}) => {
      state.contacts = state.contacts.map(contact => {
        if (contact.id === payload.id) return payload;
        return contact;
      });
    },
    delete_account_contact_locally: (state, {payload}) => {
      state.contacts = state.contacts.filter(contact => contact.id !== payload);
    }
  }
});

export const {
  request_was_started,
  request_was_succeed,
  request_was_failed,
  reset_team_account_contact_state,
  set_account_contacts,
  add_account_contact_locally,
  edit_account_contact_locally,
  delete_account_contact_locally
} = teamAccountContactSlice.actions;

export const teamAccountContactSelector = (state: IState) => state.teamAccountContact;

export const getContacts = (
  accountId: String, 
): AppThunk => async (dispatch) => {
  dispatch(request_was_started("GET"));
  const response = await contactService.getContacts(accountId);
  if (response.ok()) {
    dispatch(request_was_succeed());
    dispatch(set_account_contacts(response.data));
  } else {
    response.getError && dispatch(request_was_failed(response.getError()));
  }
}

export const addContact = (
  accountId: String | null, 
  contact: Contact,
  onSuccess: any, 
  onError: () => any
): AppThunk => async (dispatch) => {
  dispatch(request_was_started("ADD"));
  let response: ApiResponse<any>;
  if (accountId !== null) {
    response = await contactService.addAccountContact(accountId, contact);
  } else {
    response = await contactService.addContact(contact);
  }

  if (response.ok()) {
    dispatch(request_was_succeed());
    dispatch(add_account_contact_locally(response.data));
    onSuccess(response.data);
  } else {
    dispatch(request_was_failed(response.getError()));
    onError();
  }
}

export const editContact = (
  accountId: String,
  contact: Contact,
  onSuccess: any, 
  onError: () => any
): AppThunk => async (dispatch) => {
  dispatch(request_was_started("ADD"));
  const response = await contactService.editAccountContact(accountId, contact);
  if (response.ok()) {
    dispatch(request_was_succeed());
    dispatch(edit_account_contact_locally(response.data))
    onSuccess(response.data);
  } else {
    dispatch(request_was_failed(response.getError()));
    onError();
  }
}

export const deleteContact = (
  accountId: String,
  contact: Contact,
  onSuccess: () => any, 
  onError: () => any
): AppThunk => async (dispatch) => {
  dispatch(request_was_started("DELETE"));
  const response = await contactService.deleteContact(accountId, contact);
  if (response.ok()) {
    dispatch(request_was_succeed());
    dispatch(delete_account_contact_locally(contact.id))
    onSuccess();
  } else {
    dispatch(request_was_failed(response.getError()));
    onError();
  }
}

const teamAccountContactReducer = teamAccountContactSlice.reducer;
export default teamAccountContactReducer;
