import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Form, Formik, FormikProps } from "formik";
import mixpanel from "mixpanel-browser";
import Helmet from "react-helmet";
import { SignupByInvitePath, PathParams } from "./route";
import Loading from "../../ui-components/loading/loading";
import XGSFormInput from "../../ui-components/form/input/xgsFormInput";
import XGSFormPhoneInput from "../../ui-components/form/phoneInput/xgsFormPhoneInput";
import { LabelModes } from "../../ui-components/molecules/labeled-inputs/labeledInput";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import SuccessBlock, { LoginLinkTypes } from "../../ui-components/success-block/successBlock";
import SignupByInviteState from "../../slices/signup-by-invite/SignupByInviteState";
import {
  checkInvite,
  signup,
  signupByInviteSelector
} from "../../slices/signup-by-invite/signupByInviteSlice";
import { getCurrentUser, logout } from "../../slices/user/userSlice";
import { SignupByInviteRequestModel, SignupByInviteSchema, UserInvitationType } from "../../app/data/user/requestModels";
import "../../sass/forms.scss";

let initialValues: SignupByInviteRequestModel = {
  firstName: "",
  lastName: "",
  title: "",
  phoneNumber: "",
  password: "",
  confirmPassword: "",
  token: ""
};

const ConfirmEmail: React.FC = () => {
  const dispatch = useDispatch();
  const signupByInviteState: SignupByInviteState = useSelector(signupByInviteSelector);
  const params = useParams() as PathParams;
  const signupFormRef = useRef<any>(null);
  const [phoneValue, setPhoneValue] = useState<string>("");
  const [invitationType, setInvitationType] = useState<UserInvitationType>();
  const onSubmit = async (data: SignupByInviteRequestModel) => {
    dispatch(signup(data));
  };

  useEffect(() => {
    if (signupByInviteState.confirmInvite_started
      || signupByInviteState.confirmInvite_succeed
      || signupByInviteState.confirmInvite_failed) return;
    mixpanel.reset();
    dispatch(getCurrentUser("", () => dispatch(logout("SILENT"))));
    const token = params[SignupByInvitePath.token];
    token && dispatch(checkInvite(token, (firstName, lastName, type) => {
      signupFormRef.current?.setFieldValue("firstName", firstName || "");
      signupFormRef.current?.setFieldValue("lastName", lastName || "");
      signupFormRef.current?.setFieldValue("token", token);
      setInvitationType(type)
    }));
  }, [dispatch, params, signupByInviteState]);

  return (
    <div className="xgs-public-form">
      <Helmet title="Sign Up" />
      {(signupByInviteState.confirmInvite_started || signupByInviteState.signup_started) && (
        <div className="text-center" style={{ paddingRight: 24 }}>
          <Loading isLoading={true} />
        </div>
      )}
      {signupByInviteState.confirmInvite_succeed && !signupByInviteState.signup_succeed && (
        <div className="xgs-public-form__form">
          <div className="xgs-public-form__title">Sign Up</div>
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={SignupByInviteSchema}
            innerRef={signupFormRef}
            enableReinitialize
          >
            {(props: FormikProps<SignupByInviteRequestModel>) => (
              <Form className="form-items">
                <div className="xgs-public-form__form__section">
                  <div className="xgs-public-form__form__section__header">Personal information</div>
                  <XGSFormInput
                    type="text"
                    name="firstName"
                    label="First Name:"
                    required={true}
                    labelMode={LabelModes.row}
                  />
                  <XGSFormInput
                    type="text"
                    name="lastName"
                    label="Last Name:"
                    required={true}
                    labelMode={LabelModes.row}
                  />
                  <XGSFormInput
                    type="text"
                    name="title"
                    label="Title:"
                    required={true}
                    labelMode={LabelModes.row}
                  />
                  <XGSFormPhoneInput
                    name="phoneNumber"
                    label="Phone:"
                    labelMode={LabelModes.row}
                    onValueChange={(value) => {
                      props.setFieldValue("phoneNumber", value);
                      setPhoneValue(value);
                    }}
                    onBlur={props.handleBlur}
                    value={phoneValue}
                    required={true}
                  />
                </div>
                <div className="xgs-public-form__form__section">
                  <div className="xgs-public-form__form__section__header">Set account password</div>
                  <XGSFormInput
                    type="password"
                    name="password"
                    label="Password:"
                    required={true}
                    labelMode={LabelModes.row}
                  />
                  <XGSFormInput
                    type="password"
                    name="confirmPassword"
                    label="Confirm password:"
                    required={true}
                    labelMode={LabelModes.row}
                  />
                </div>
                {signupByInviteState.signup_failed && (
                  <XGSErrorMessage className="xgs-public-form__error">
                    {signupByInviteState.signup_error && (<>{signupByInviteState.signup_error}</>)}
                  </XGSErrorMessage>
                )}
                <div className="xgs-public-form__form__submit">
                  <Button
                    theme={ButtonThemes.blue}
                    spinner={signupByInviteState.signup_started}
                    disabled={!props.isValid || !props.dirty}
                    type="submit">
                    Sign-up
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
      {(signupByInviteState.confirmInvite_failed || signupByInviteState.signup_failed) && (
        <div className="text-center" style={{
          margin: "32px auto 0 auto",
          maxWidth: 320
        }}>
          <XGSErrorMessage>
            {signupByInviteState.confirmInvite_error && (<>{signupByInviteState.confirmInvite_error}</>)}
            {signupByInviteState.signup_error && (<>{signupByInviteState.signup_error}</>)}
          </XGSErrorMessage>
        </div>
      )}
      {signupByInviteState.signup_succeed && (
        <SuccessBlock 
          loginLinkType={invitationType === UserInvitationType.AGENT ? LoginLinkTypes.AGENT : LoginLinkTypes.CUSTOMER} 
          showLoginLink={true}
        >
          You have successfully completed registration!
        </SuccessBlock>
      )}
    </div>
  );
};

export default ConfirmEmail;
