import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationModal from "../../../../ui-components/confirmation-modal/confirmationModal";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import ReturnItemForm from "./returnItemForm";
import ReturnState from "../../../../slices/return/ReturnState";
import {
  addItem,
  clearDeletedItems,
  deleteItem,
  returnSelector,
  updateItem
} from "../../../../slices/return/returnSlice";
import CommonState from "../../../../slices/common/CommonState";
import { commonSelector } from "../../../../slices/common/commonSlice";
import { StepProps } from "../../../../app/data/common/models";
import rollIcon from "../../../../images/roll_blue.svg";
import palleteIcon from "../../../../images/pallete_blue.svg";
import editIcon from "../../../../images/edit_blue.svg";
import removeIcon from "../../../../images/trash_blue.svg";
import "../../bol.scss";

interface ReturnItemsStepProps extends StepProps {
  showRateQuote: boolean;
}

const ReturnItemsStep: React.FC<ReturnItemsStepProps> = (props) => {
  const { previous, next, push, showRateQuote } = props;
  const dispatch = useDispatch();
  const returnState: ReturnState = useSelector(returnSelector);
  const commonState: CommonState = useSelector(commonSelector);
  const [showAddItemForm, setAddItemForm] = useState<boolean>(false);
  const [removeItemIndex, setRemoveItemIndex] = useState<number | undefined>(undefined);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState<boolean>(false);
  const [editItemIndex, setEditItemIndex] = useState<number | undefined>(undefined);

  const onClickBack = () => {
    dispatch(clearDeletedItems());
    previous && previous();
  };

  const onClickNext = () => {
    dispatch(clearDeletedItems());
    if (showRateQuote) {
      next && next();
    } else {
      push && push("return-completed-step");
    }
  };

  const getClassLabel = (ratingClassValue: string) => {
    let ratingClass = commonState.freightClasses.find(obj => obj.value === ratingClassValue);
    return ratingClass ? ratingClass.label : ratingClassValue;
  };

  return (
    <div className="xgs-bol__return__step">
      <div className="xgs-bol__section">Detail Information</div>
      {returnState.items.map((item, index) => (
        <div key={index}>
          {(index === editItemIndex) && (
            <ReturnItemForm
              itemIndex={index}
              onSubmit={(data) => {
                dispatch(updateItem({ index, data }));
                setEditItemIndex(undefined);
              }}
              onCancel={() => {
                setEditItemIndex(undefined);
              }}
            />
          )}
          {(editItemIndex === undefined || (index !== editItemIndex)) && !item.deleted && (
            <div className="xgs-items__item">
              <div className="xgs-items__item__columns xgs-items__item__columns--space-between">
                <div className="xgs-items__item__columns xgs-items__item__columns--no-margin xgs-items__item__columns--stretch">
                  <div className="xgs-items__item__icon">
                    {item.packageType === "ROLL" && (
                      <img
                        src={rollIcon}
                        alt="roll"
                      />
                    )}
                    {item.packageType === "PALLET" && (
                      <img
                        src={palleteIcon}
                        alt="pallet"
                      />
                    )}
                  </div>
                  <div className="xgs-items__item__flex-center-area">
                    <div className="xgs-items__item__type">
                      {item.packageType === "ROLL" && "Roll"}
                      {item.packageType === "PALLET" && "Pallet"}
                    </div>
                    {item.ratingClass && (<div className="xgs-items__item__property xgs-items__item__class">Class: <span>{getClassLabel(item.ratingClass)}</span></div>)}
                  </div>
                </div>
                <div className="xgs-items__item__columns xgs-items__item__columns--no-margin xgs-items__item__columns--actions">
                  <div
                    className="xgs-items__item__action"
                    onClick={() => {
                      setAddItemForm(false);
                      setEditItemIndex(index);
                    }}
                  >
                    <img
                      src={editIcon}
                      alt="edit"
                    />
                    Edit
                  </div>
                  <div
                    className="xgs-items__item__action"
                    onClick={() => {
                      setRemoveItemIndex(index);
                      setShowRemoveConfirmation(true);
                    }}
                  >
                    <img
                      src={removeIcon}
                      alt="remove"
                    />
                    Remove
                  </div>
                </div>
              </div>
              <div className="xgs-items__item__columns xgs-items__item__columns--allow-wrap">
                <div className="xgs-items__item__property">Units: <span>{item.units}</span></div>
                {item.yards && (<div className="xgs-items__item__property">Sq. Yards: <span>{item.yards}</span></div>)}
                <div className="xgs-items__item__property">Pounds: <span>{item.weight}</span></div>
                {!!(item.length && item.width && item.height) && (
                  <div className="xgs-items__item__property">Size: <span>{item.length}</span> x <span>{item.width}</span> x <span>{item.height}</span> in</div>
                )}
                {(item.packageType === "ROLL" && item.length) && (
                  <div className="xgs-items__item__property">Length: <span>{item.length}</span> in</div>
                )}
              </div>
              {item.description && (
                <div className="xgs-items__item__columns xgs-items__item__columns--allow-wrap">
                  <div className="xgs-items__item__property">Description: <span>{item.description}</span></div>
                </div>
              )}
              {item.sidemark && (
                <div className="xgs-items__item__columns xgs-items__item__columns--allow-wrap">
                  <div className="xgs-items__item__property">Sidemark: <span>{item.sidemark}</span></div>
                </div>
              )}
              {item.notes && (
                <div className="xgs-items__item__columns xgs-items__item__columns--allow-wrap">
                  <div className="xgs-items__item__property">Notes: <span>{item.notes}</span></div>
                </div>
              )}
            </div>
          )}
        </div>
      ))}
      {(showAddItemForm || returnState.items.length === 0) && <ReturnItemForm onSubmit={(data) => {
        dispatch(addItem(data));
        setAddItemForm(false);
      }} />}
      {!showAddItemForm &&
        (editItemIndex === undefined) &&
        (returnState.items.length > 0) &&
        (returnState.items.filter(item => !item.deleted).length < 10) && (
        <div className="xgs-bol__items__add">
          <span
            className="xgs-bol__items__link"
            onClick={() => { setAddItemForm(true); }}
          >
            + Add new item
          </span>
        </div>
      )}
      <div className="xgs-bol__items__limitation">
        {returnState.items.filter(item => !item.deleted).length === 0
          ? (
            <span>Up to 10 items are allowed.</span>
          )
          : (
            <span>{returnState.items.filter(item => !item.deleted).length} of 10 allowed items have been entered.</span>
          )
        }
      </div>
      <div className="xgs-bol__buttons">
        <Button
          type="button"
          theme={ButtonThemes.gray}
          className="xgs-bol__nav-button"
          onClick={onClickBack}
        >
          Back
        </Button>
        <Button
          type="button"
          theme={ButtonThemes.blue}
          disabled={
            returnState.items.length === 0
            || returnState.items.filter(item => !item.deleted).length === 0
            || (editItemIndex !== undefined)
          }
          className="xgs-bol__nav-button"
          spinner={returnState.requestStarted}
          onClick={onClickNext}
        >
          {showRateQuote ? "Next" : "Submit"}
        </Button>
      </div>
      <ConfirmationModal
        opened={showRemoveConfirmation}
        confirmButtonText="Remove"
        onCancel={() => {
          setShowRemoveConfirmation(false);
        }}
        onConfirm={() => {
          dispatch(deleteItem(removeItemIndex));
          setShowRemoveConfirmation(false);
          setRemoveItemIndex(undefined);
        }}>
        The item will be removed from the list.
      </ConfirmationModal>
    </div>
  );
};

export default ReturnItemsStep;
