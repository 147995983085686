import { BaseState } from "..";
import { PublicShipmentDetails } from "../../app/data/public-shipment-details/models";

export default interface PublicShipmentDetailsState extends BaseState {
  shipment: PublicShipmentDetails | null;
  loadingErrorStatus?: number;
}

export const initialPublicShipmentDetailsState: PublicShipmentDetailsState = {
  loading: false,
  loadingFailed: false,
  loadingError: null,
  loadingErrorStatus: undefined,
  loaded: false,
  shipment: null
};
