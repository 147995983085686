import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import NotificationsService from "../../app/data/notifications/notificationsService";
import { initialNotificationsState } from "./NotificationsState";

const notificationsService = NotificationsService.getInstance();

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: initialNotificationsState,
  reducers: {
    resetNotificationsState: (state) => initialNotificationsState,
    resetNotificationsErrors: (state) => {
      state.requestFailed = false;
      state.requestError = null;
    },    
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestCreator = payload;
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestError = null;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = true;
      state.requestSucceed = false;
      state.requestError = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestFailed = false;
      state.requestSucceed = true;
      state.requestError = null;
    },
    storeSettings: (state, { payload }) => {
      state.settings = payload;
    },
    updateNotificationLocally: (state, { payload }) => {
      const index = state.settings.findIndex((setting: any) => setting.type === payload.type);
      if (index === -1) return;
      state.settings[index].enabled = payload.enabled;
      state.settings[index].notifyBy = payload.notifyBy;
    }
  }
});

export const {
  resetNotificationsState,
  resetNotificationsErrors,
  requestStarted,
  requestFailed,
  requestSucceed,
  storeSettings,
  updateNotificationLocally
} = notificationsSlice.actions;

export const notificationsSelector = (state: IState) => {
  return state.notifications;
};

export const getNotifications = (
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("LOAD_NOTIFICATIONS"));
  const response = await notificationsService.getNotifications();
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(storeSettings(response.data));
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const updateNotification = (
  type: string,
  enabled: boolean,
  notifyBy: string,
  onSuccess: () => void,
  onError: (error: any) => void
): AppThunk => async (dispatch) => {
  dispatch(updateNotificationLocally({
    type,
    enabled,
    notifyBy
  }));
  dispatch(requestStarted("UPDATE_NOTIFICATIONS"));
  const response = await notificationsService.updateNotification(type, enabled, notifyBy);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess();
  } else {
    response.getError && dispatch(requestFailed(response.getError()));
    onError(response.getError ? response.getError() : null);
  }
};

export const updateTimezone = (
  timezone: string,
  userId: string,
  onSuccess: () => void,
  onError: (error: any) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("UPDATE_TIMEZONE"));
  const response = await notificationsService.updateTimezone(timezone, userId);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess();
  } else {
    response.getError && dispatch(requestFailed(response.getError()));
    onError(response.getError ? response.getError() : null);
  }
};

const notificationsReducer = notificationsSlice.reducer;
export default notificationsReducer;
