import React from "react";
import ContentContainer from "../../../templates/content-container/contentContainer";
import ReturnList from "../common/list";
import "../bol.scss";

const CombinedList: React.FC<{}> = (props) => {
  return (
    <ContentContainer title="Returns">
      <ReturnList defaultTypes={["RETURN"]} />
    </ContentContainer>
  );
};

export default CombinedList;
