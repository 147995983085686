import React from "react";

import "./warning.scss";
import XGSIcon from "../../icon/xgsIcon";
import XGSIcons from "../../icon/xgsIcons";

export interface WarningProps {
  children: any;
}

const Warning: React.FC<WarningProps> = ({ children }) => {
  return (
    <div className="xgs-warning">
      <XGSIcon
        icon={XGSIcons.faExclamationTriangle}
        className="xgs-warning__icon"
      />
      <div className="xgs-warning__text">
        {children}
      </div>
    </div>
  );
}

export default Warning;