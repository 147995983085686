import * as Yup from 'yup'
import { validationMessages } from '../common/validationMessages';

export interface HolidayDateModel {
  date: string;
  name?: string;
  localName?: string;
  countryCode?: string;
}

export interface HolidayLogModel {
  date: string;
  userCreated: string;
  dateCreated: string;
  action: "ADD" | "REMOVE"
}

export const CreateHolidaySchema = Yup.object({
  name: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).required("Name is required").min(3, "Name must be at least 3 characters")
}).defined()