import { storageKeys } from "./constants";
import SubAccount from "../data/account/SubAccount";
import { Terminal } from "../data/common/models";

export class BaseStorage {
  private _storage: Storage | null = null;

  protected constructor(storage: Storage) {
    this._storage = storage;
  }

  public setActiveSubAccount = (subAccount: SubAccount) => {
    this._storage?.setItem(
      storageKeys.activeSubAccount,
      JSON.stringify(subAccount)
    );
  };

  public getActiveSubAccount = (): SubAccount | null | undefined => {
    const stringObject = this._storage?.getItem(
      storageKeys.activeSubAccount
    );
    if (stringObject && stringObject !== "undefined") {
      return JSON.parse(stringObject);
    }

    return null;
  };

  public getActualizedActiveSubAccount = (
    availableSubAccounts: SubAccount[]
  ): SubAccount => {
    const activeAccount = this.getActiveSubAccount();

    if (
      !activeAccount ||
      !availableSubAccounts.find(
        (subAccount) => subAccount.id === activeAccount.id
      )
    ) {
      const newActiveAccount = availableSubAccounts[0];
      this.setActiveSubAccount(newActiveAccount);
      return newActiveAccount;
    }

    return activeAccount;
  };

  public setActiveTerminal = (terminal: Terminal) => {
    this._storage?.setItem(storageKeys.activeTerminal, JSON.stringify(terminal));
  };

  public getActiveTerminal = (): Terminal | null | undefined => {
    const stringObject = this._storage?.getItem(storageKeys.activeTerminal);
    return (stringObject && stringObject !== "undefined") ? JSON.parse(stringObject) : null;
  };
};
