import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Form, Formik, FormikProps } from "formik";
import { AddTeamShipperModel, AddTeamShipperSchema } from "../../../app/data/company/models";
import XGSPopup from "../../../ui-components/xgs-popup/xgsPopup";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import Loading from "../../../ui-components/loading/loading";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import CreateTeamState from "../../../slices/customers/teams/CreateTeamState";
import {
  clearAccountsInfo,
  createTeamSelector,
  getAccountsInfo
} from "../../../slices/customers/teams/createTeamSlice";
import TeamShippersState from "../../../slices/customers/teams/TeamShippersState";
import {
  addShippers,
  resetErrors,
  teamShippersSelector
} from "../../../slices/customers/teams/teamShippersSlice";
import "./addShippers.scss";

export interface AddShippersProps {
  teamId: string;
  show: boolean;
  onShippersAdded?: () => void;
  onClose: () => void;
}

let initialValues: AddTeamShipperModel = {
  accounts: ""
};

const AddShippers: React.FC<AddShippersProps> = (props) => {
  const [inputTimeout, setInputTimeout] = useState(0);
  const addShippersFormRef = useRef<any>(null);
  const createTeamState: CreateTeamState = useSelector(createTeamSelector);
  const teamShippersState: TeamShippersState = useSelector(teamShippersSelector);
  const dispatch = useDispatch();

  const parseAccounts = (accountNumbers: string): number[] => {
    return accountNumbers.split(/(?:,| )+/)
      .map(Number)
      .filter(accountNumber => accountNumber && accountNumber > 99 && accountNumber <= 99999999);
  };

  const onAccountNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    if (target.value.length > 2) {
      const accountNumbers = parseAccounts(target.value);
      inputTimeout && clearTimeout(inputTimeout);
      setInputTimeout(setTimeout(() => {
        dispatch(getAccountsInfo(accountNumbers));
      }, 500));
    }
    addShippersFormRef.current?.handleChange(e);
  };

  const onSubmitShippers = (data: AddTeamShipperModel) => {
    let accounts = parseAccounts(data.accounts);
    let isMultiple = accounts.length > 1;
    dispatch(addShippers(props.teamId, accounts, () => {
      toast.info(`The shipper${isMultiple ? 's have' : ' has'} been added!`);
      props.onShippersAdded && props.onShippersAdded();
      props.onClose();
    }));
  };  

  useEffect(() => {
    if (!props.show) return;
    dispatch(clearAccountsInfo());
    dispatch(resetErrors());
    setTimeout(() => {
      document.getElementById("xgs-team-shippers-accounts-field--js")?.focus();
    }, 200);
  }, [dispatch, props.show]);

  return (
    <XGSPopup
      title=""
      opened={props.show}
      onClose={props.onClose}
      className="xgs-teams__add-shippers__popup"
    >
      <div>
        <div className="xgs-teams__add-shippers__popup__header">Add shippers to the customer</div>
        <Formik
          onSubmit={onSubmitShippers}
          initialValues={initialValues}
          validationSchema={AddTeamShipperSchema}
          innerRef={addShippersFormRef}
        >
          {(props: FormikProps<AddTeamShipperModel>) => (
            <Form>
              <div className="xgs-teams__add-shippers__popup__field">
                <XGSFormInput
                  type="text"
                  name="accounts"
                  label="Account number(s):"
                  required={true}
                  requiredAsteriskDisabled={true}
                  labelMode={LabelModes.column}
                  onChange={onAccountNumberChange}
                  placeholder="One or more account number"
                  id="xgs-team-shippers-accounts-field--js"                  
                />
              </div>
              <div className="xgs-create-team__account__wrapper">
                {(createTeamState.accountsInfo_was_started || createTeamState.accountsInfo?.length > 0 || createTeamState.accountsInfo_was_failed) && !props.errors.accounts && (
                  <div className="xgs-create-team__account__area">
                    {createTeamState.accountsInfo && (
                      <div className="xgs-create-team__account__title">Account data:</div>
                    )}
                    <div className="xgs-create-team__account__data">
                      <Loading isLoading={createTeamState.accountsInfo_was_started} />
                      {createTeamState.accountsInfo_was_failed && (
                        <div className="xgs-create-team__account__error">
                          {createTeamState.accountsInfo_fail_reason || "Error"}
                        </div>
                      )}
                      {createTeamState.accountsInfo && createTeamState.accountsInfo.map((accountInfo: any) => (
                        <div
                          className="xgs-create-team__account__data__item"
                          key={accountInfo.accountId}>
                          <div className="xgs-create-team__account__data__item__id">{accountInfo.accountId}:</div>
                          <strong>{accountInfo.accountName}</strong><br />
                          {accountInfo.address ? `${accountInfo.address}, ` : ""}{accountInfo.city ? `${accountInfo.city}, ` : ""}{accountInfo.state ? `${accountInfo.state}, ` : ""}{accountInfo.zip}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              {(teamShippersState.request_was_failed && teamShippersState.request_fail_code === 400) && (
                <XGSErrorMessage>{teamShippersState.request_fail_reason}</XGSErrorMessage>
              )}

              <Button
                theme={ButtonThemes.blue}
                type="submit"
                className="xgs-teams__add-shippers__popup__button"
                disabled={!props.isValid || !props.dirty || !createTeamState.accountsInfo || createTeamState.accountsInfo.length === 0}
                spinner={teamShippersState.request_was_started}
              >
                Add&nbsp;shipper(s)
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </XGSPopup>
  );
};

export default AddShippers;
