import { XGSSelectOption } from "../../ui-components/xgs-select/xgsSelect";
import { TransitCalculatorResultModel } from "../../app/data/transit-calculator/models";

export default interface TransitCalculatorState {
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError?: string | null;
  requestCreator?: string | null;
  result: TransitCalculatorResultModel | null;
  originLocations: XGSSelectOption[];
  destinationLocations: XGSSelectOption[];
};

export const initialTransitCalculatorState: TransitCalculatorState = {
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestError: null,
  requestCreator: null,
  result: null,
  originLocations: [],
  destinationLocations: []
};
