import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import XGSFormInput from "../../../../ui-components/form/input/xgsFormInput";
import XGSFormPhoneInput from "../../../../ui-components/form/phoneInput/xgsFormPhoneInput";
import LabeledSelectInput from "../../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { XGSSelectOption } from "../../../../ui-components/xgs-select/xgsSelect";
import { LabelModes } from "../../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import FormAddressSelector from "../../../../ui-components/form/address/formAddressSelector";
import {
  ReturnConsigneeModel,
  ReturnConsigneeSchema
} from "../../../../app/data/return/models";
import { StepProps } from "../../../../app/data/common/models";
import ReturnState from "../../../../slices/return/ReturnState";
import {
  getMillsList,
  returnSelector,
  setReturnConsignee
} from "../../../../slices/return/returnSlice";
import "../../bol.scss";

const ReturnConsigneeStep: React.FC<StepProps> = (props) => {
  const { previous, next } = props;
  const returnState: ReturnState = useSelector(returnSelector);
  const [addressLookupValue, setAddressLookupValue] = useState<any>("");
  const [phoneFieldValue, setPhoneFieldValue] = useState<string>("");
  const dispatch = useDispatch();
  const consigneeFormRef = useRef<any>(null);
  const [millFormValue, setMillFormValue] = useState<XGSSelectOption | null>();

  let initialValues: ReturnConsigneeModel = {
    name: "",
    address: {
      address1: "",
      city: "",
      postalCode: "",
      state: "",
      additionalAddressLine: "",
    },
    phone: "",
    email: ""
  };

  const onClickBack = (data: ReturnConsigneeModel) => {
    dispatch(setReturnConsignee(data));
    previous && previous();
  };

  const onClickNext = (data: ReturnConsigneeModel) => {
    dispatch(setReturnConsignee(data));
    next && next();
  };

  const mills = () => {
    let options: XGSSelectOption[] = [];
    if (returnState.mills.length > 0) {
      for (let i = 0; i < returnState.mills.length; i++) {
        options.push({
          label: returnState.mills[i].name,
          subtitle: `${returnState.mills[i].address.city}, ${returnState.mills[i].address.state}`,
          value: `${i}`,
          valueForSearch: `${returnState.mills[i].name} ${returnState.mills[i].address.city}, ${returnState.mills[i].address.state}`,
        });
      }
    }
    return options;
  };

  const onMillSelect = (v: XGSSelectOption | null | undefined) => {
    setMillFormValue(v);
    if (!v?.value || v.value === "-1" || !returnState.mills || returnState.mills.length === 0) return;
    let index = Number(v.value);
    let mill = returnState.mills[index];
    consigneeFormRef.current?.resetForm();
    consigneeFormRef.current?.setFieldValue("name", mill.name);
    consigneeFormRef.current?.setFieldValue("phone", mill.phone.formatPhone());
    setPhoneFieldValue(mill.phone.formatPhone());
    consigneeFormRef.current?.setFieldValue("email", mill.email);
    consigneeFormRef.current?.setFieldValue("address.address1", mill.address.address1);
    consigneeFormRef.current?.setFieldValue("address.city", mill.address.city);
    consigneeFormRef.current?.setFieldValue("address.postalCode", mill.address.postalCode);
    consigneeFormRef.current?.setFieldValue("address.state", mill.address.state);
    consigneeFormRef.current?.setFieldValue("address.additionalAddressLine", mill.address.additionalAddressLine);
    setAddressLookupValue({
      label: "",
      value: ""
    });
    setTimeout(() => {
      consigneeFormRef.current?.validateForm();
    }, 50);
  };

  useEffect(() => {
    consigneeFormRef.current?.setFieldValue("name", returnState.returnConsignee.name);
    consigneeFormRef.current?.setFieldValue("address.address1", returnState.returnConsignee.address.address1);
    consigneeFormRef.current?.setFieldValue("address.city", returnState.returnConsignee.address.city);
    consigneeFormRef.current?.setFieldValue("address.postalCode", returnState.returnConsignee.address.postalCode);
    consigneeFormRef.current?.setFieldValue("address.state", returnState.returnConsignee.address.state);
    consigneeFormRef.current?.setFieldValue("address.additionalAddressLine", returnState.returnConsignee.address.additionalAddressLine);
    consigneeFormRef.current?.setFieldValue("phone", returnState.returnConsignee.phone);
    setPhoneFieldValue(returnState.returnConsignee.phone);
    consigneeFormRef.current?.setFieldValue("email", returnState.returnConsignee.email);
    if (returnState.returnConsignee.address.address1) {
      setAddressLookupValue({
        label: `${returnState.returnConsignee.address.address1}, ${returnState.returnConsignee.address.city}, ${returnState.returnConsignee.address.state}, ${returnState.returnConsignee.address.postalCode}`,
        value: {
          address: returnState.returnConsignee.address.address1,
          city: returnState.returnConsignee.address.city,
          state: returnState.returnConsignee.address.state,
          zip: returnState.returnConsignee.address.postalCode
        }
      });
    }
    setTimeout(() => {
      consigneeFormRef.current?.validateForm();
    }, 50);
  }, [returnState.returnConsignee]);

  useEffect(() => {
    if (returnState.mills.length > 0) return;
    dispatch(getMillsList());
  }, [dispatch, returnState.mills]);

  return (
    <div className="xgs-bol__return__step">
      <Formik
        onSubmit={onClickNext}
        initialValues={initialValues}
        validationSchema={ReturnConsigneeSchema}
        innerRef={consigneeFormRef}
        validateOnMount
        enableReinitialize
      >
        {(props: FormikProps<ReturnConsigneeModel>) => (
          <Form>
            <div className="xgs-bol__section">Return to Location</div>
            <LabeledSelectInput
              async
              name="consignee"
              label="Load Previous Mill:"
              placeholder="Select..."              
              labelMode={LabelModes.column}
              value={millFormValue}
              onValueChange={onMillSelect}
              options={mills()}
              required={false}
              requiredAsteriskDisabled={true}
              formik={true}
              className="xgs-bol__field xgs-bol__field--gray-bg"
              isLoading={returnState.requestStarted && returnState.requestCreator === "GET_MILLS"}
            />
            <XGSFormInput
              type="text"
              name="name"
              label="Mill or Vendor:"
              required={true}
              requiredAsteriskDisabled={false}
              labelMode={LabelModes.column}
              className="xgs-bol__field"
            />
            <div className="xgs-bol__address-info">
              <FormAddressSelector
                editable={true}
                required
                addressLookupValue={addressLookupValue}
                setAddressLookupValue={(v) => setAddressLookupValue(v)}
              />
            </div>
            <div className="xgs-bol__contact-row">
              <XGSFormPhoneInput
                name="phone"
                label="Phone:"
                labelMode={LabelModes.column}
                onValueChange={(value) => {
                  props.setFieldValue("phone", value);
                  setPhoneFieldValue(value);
                }}
                onBlur={props.handleBlur}
                value={phoneFieldValue}
                required={true}
                requiredAsteriskDisabled={false}
                className="xgs-bol__field xgs-bol__contact-row__phone"
              />
              <div className="xgs-form__field__notes xgs-form__field__notes--contact xgs-form__field__notes--mobile">
                <strong>Note:</strong> by providing a telephone number and submitting this form you are consenting to be contacted by SMS text message.
                Message &amp; data rates may apply. You can reply STOP to opt-out of further messaging.
              </div>
              <XGSFormInput
                type="text"
                name="email"
                label="Email:"
                required={false}
                requiredAsteriskDisabled={true}
                labelMode={LabelModes.column}
                className="xgs-bol__field xgs-bol__contact-row__email"
              />
            </div>
            <div className="xgs-form__field__notes xgs-form__field__notes--contact xgs-form__field__notes--desktop">
              <strong>Note:</strong> by providing a telephone number and submitting this form you are consenting to be contacted by SMS text message.
              Message &amp; data rates may apply. You can reply STOP to opt-out of further messaging.
            </div>
            <div className="xgs-bol__buttons">
              <Button
                type="button"
                theme={ButtonThemes.gray}
                className="xgs-bol__nav-button"
                onClick={() => onClickBack(props.values)}
              >
                Back
              </Button>
              <Button
                type="submit"
                theme={ButtonThemes.blue}
                disabled={!props.isValid || !props.values.name}
                className="xgs-bol__nav-button">
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ReturnConsigneeStep;
