import { useDispatch, useSelector } from "react-redux";
import {
  appointmentSelector,
  setCurrentAppointment,
} from "../../../slices/appointments/appointmentsSlice";
import React, { useEffect, useRef, useState } from "react";
import { ContactCard } from "../../company/account-contact/contactCard";
import { StepProps } from "../../../app/data/common/models";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import { AppointmentStepIndicator } from "./appointmentStepIndicator";
import { Form, Formik, FormikProps } from "formik";
import {
  appointmentLateReasons,
  AppointmentRequest,
  getAppointmentRequestModel,
  getEarlyTimeOptions,
  getLateTimeOptions,
} from "../../../app/data/appointments/models";
import LabeledInput, {
  LabelModes,
} from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import XGSFormSelect from "../../../ui-components/form/select/xgsFormSelect";
import XGSFormDate from "../../../ui-components/form/date/xgsFormDate";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import moment from "moment";
import dateFormats from "../../../app/data/common/dateFormats";
import arrowLeft from "../../../images/arrow-left.svg";
import XGSFormTextarea from "../../../ui-components/form/textarea/xgsFormTextarea";

const initialFormValues: AppointmentRequest = {
  probillNumber: "",
  date: "",
  earlyTime: "",
  lateTime: "",
  notes: "",
  reason: ""
};

interface CreateStepProps extends StepProps {
  probillNumber: number;
  eta: string;
  stepCount: number;
  stepIdx: number;
}

const CreateStep: React.FC<CreateStepProps> = ({ previous, next, eta, probillNumber, stepCount, stepIdx }) => {
  const appointmentState = useSelector(appointmentSelector);
  const dispatch = useDispatch();
  const formRef = useRef<FormikProps<AppointmentRequest>>(null);
  const [earlyTime, setEarlyTime] = useState<XGSSelectOption | null>();  
  const [lateTime, setLateTime] = useState<XGSSelectOption | null>();  
  const [reason, setReason] = useState<XGSSelectOption | null>();  
  
  const isLateCodeRequired = (eta: string, newDate?: string) => {
    if (!eta || !newDate) return false;
    return moment(eta, dateFormats.apiDate).isBefore(moment(newDate, dateFormats.uiDate));
  }

  const handleClickNext = (values: AppointmentRequest) => {
    formRef.current?.setFieldTouched('reason', true);
    dispatch(
      setCurrentAppointment({
        contactId: appointmentState.currentContact?.id,
        probillNumber: probillNumber,
        date: values.date.toApiDateFormat(),
        earlyTime: values.earlyTime.toApiTimeFormat(),
        lateTime: values.lateTime.toApiTimeFormat(),
        reason: values.reason || null,
        notes: values.notes
      })
    );
    next && next();
  };

  useEffect(() => {
    if (!appointmentState.currentAppointment || !formRef.current) return;
    formRef.current.setFieldValue("earlyTime", appointmentState.currentAppointment.earlyTime)
    formRef.current.setFieldValue("lateTime", appointmentState.currentAppointment.lateTime)
    formRef.current.setFieldValue("date", appointmentState.currentAppointment.date)
    formRef.current.setFieldValue("reason", appointmentState.currentAppointment.reason)
    formRef.current.setFieldValue("notes", appointmentState.currentAppointment.notes)
    setReason(appointmentLateReasons.find((reason) => appointmentState.currentAppointment?.reason === reason.value));
    setEarlyTime(getEarlyTimeOptions().find((time) => appointmentState.currentAppointment?.earlyTime.toUiTimeFormat() === time?.value));
    setLateTime(getLateTimeOptions(formRef.current.values.earlyTime).find((time) => appointmentState.currentAppointment?.lateTime.toUiTimeFormat() === time?.value));
  }, [appointmentState.currentAppointment])

  const getMinDate = () => {
    return new Date()
  }

  return (
    <>
      <div className="xgs-create-appt__wizard">
        <div className="xgs-create-appt__wizard__heading">Set Appointment</div>
        <div className="xgs-create-appt__wizard__desc">
          Please fill the form with appointment details.
        </div>
      </div>
      <AppointmentStepIndicator step={stepIdx} stepCount={stepCount}/>
      <div>
        {appointmentState.currentContact && (
          <ContactCard hideButtons contact={appointmentState.currentContact} />
        )}
        <Formik
          onSubmit={handleClickNext}
          initialValues={initialFormValues}
          innerRef={formRef}
          validationSchema={getAppointmentRequestModel(eta)}
        >
          {(props: FormikProps<AppointmentRequest>) => (
            <Form>
              <XGSFormDate
                label="Date:"
                required
                labelMode={LabelModes.column}
                minDate={getMinDate()}
                maxDate={moment().add(1, "month").toDate()}
                name="date"
                disableWeekends
                onChange={() => {}}
                className="xgs-create-appt__form__item"
                onDateChange={(value: any) => {
                  props.setFieldValue("date", value);
                  props.validateForm();
                }}
              />
              <LabeledInput
                label="Hours of Availability"
                required
                className="xgs-create-appt__form__item"
                labelMode={LabelModes.column}
              >
                <div className="xgs-create-appt__form__time-block">
                  <XGSFormSelect
                    name={"earlyTime"}
                    options={getEarlyTimeOptions()}
                    formik
                    menuPlacement="auto"
                    placeholder="Early time"
                    labelMode={LabelModes.column}
                    value={earlyTime}
                    onValueChange={(value: any) => {
                      props.setFieldValue("earlyTime", value?.value);
                      setEarlyTime(value);
                    }}
                  />
                  <span className="xgs-create-appt__form__time-block__seperator"></span>
                  <XGSFormSelect
                    name={"lateTime"}
                    options={getLateTimeOptions(props.values.earlyTime)}
                    formik
                    value={lateTime}
                    menuPlacement="auto"
                    placeholder="Late time"
                    labelMode={LabelModes.column}
                    onValueChange={(value: any) => {
                      props.setFieldValue("lateTime", value?.value);
                      setLateTime(value);
                    }}
                  />
                </div>
              </LabeledInput>
              <XGSFormSelect
                label="Late reason:"
                name="reason"
                required={isLateCodeRequired(eta, props.values?.date)}
                options={appointmentLateReasons}
                formik
                value={reason}
                menuPlacement="auto"
                placeholder="Reason"
                labelMode={LabelModes.column}
                onValueChange={(value: any) => {
                  props.setFieldValue("reason", value?.value);
                  setReason(value);
                }}
                className="xgs-create-appt__form__item"
              />
              <XGSFormTextarea
                name="notes"
                maxLength={256}
                label="Notes:"
                counter={256}
                className="xgs-create-appt__form__item"
              />
              {stepIdx > 0 && <div className="xgs-create-appt__form__submit">
                <Button
                  type="button"
                  onClick={() => previous && previous()}
                  theme={ButtonThemes.gray}
                >
                  <img src={arrowLeft} alt="arrow-left"/>
                  &nbsp;&nbsp;Back
                </Button>
                <Button type="submit" theme={ButtonThemes.blue}>Review Appointment</Button>
              </div>}
              {stepIdx === 0 && <Button type="submit" className="xgs-create-appt__button" theme={ButtonThemes.blue}>Review Appointment</Button>}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export { CreateStep as AppointmentCreateStep };
