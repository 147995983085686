import React from "react";
import XGSIcons from "../../icon/xgsIcons";
import XGSIcon from "../../icon/xgsIcon";
import Loading from "../../loading/loading";
import "./summaryBlock.scss";

export enum SummaryBlockTypes {
  success = "success",
  warning = "warning",
  error = "error",
  info = "info",
  custom = "custom"
};

export interface SummaryBlockProps {
  icon?: XGSIcons.IconDefinition;
  iconColor?: string;
  loading?: boolean;
  type: SummaryBlockTypes;
};

const SummaryBlock: React.FC<SummaryBlockProps> = (props) => {
  const getIcon = (): XGSIcons.IconDefinition => {
    if (props.icon) return props.icon;    
    let icon: XGSIcons.IconDefinition = XGSIcons.faExclamationTriangle;
    switch (props.type) {
      case SummaryBlockTypes.error:
        icon = XGSIcons.faExclamationTriangle;
        break;
      case SummaryBlockTypes.success:
        icon = XGSIcons.faCheckCircle;
        break;
      case SummaryBlockTypes.warning:
        icon = XGSIcons.faExclamationCircle;
        break;
      case SummaryBlockTypes.info:
        icon = XGSIcons.faInfoCircle;
        break;
    };
    return icon;
  };

  return (
    <div className={`xgs-summary-block ${props.type}`}>
      {props.loading && (
        <div className="xgs-summary-block__spinner">
          <Loading isLoading={true} />
        </div>
      )}
      {(props.type !== SummaryBlockTypes.custom) && (
        <div
          className="xgs-summary-block__icon-container"
          style={{
            color: props.iconColor ? props.iconColor : "inherit"
          }}
        >
          <XGSIcon
            icon={getIcon()}
            className="xgs-summary-block__icon"
          />
        </div>
      )}
      <div className="xgs-summary-block__content">
        {props.children}
      </div>
    </div>
  );
};

export default SummaryBlock;
