import React from "react";
import { useSelector } from "react-redux";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import SummaryBlock, { SummaryBlockTypes } from "../../../ui-components/molecules/summary-block/summaryBlock";
import LabledInformation from "../../../ui-components/molecules/labled-information/labledInformation";

const CreditHold: React.FC = (props) => {
  const userState: UserState = useSelector(userSelector);

  return (
    <>
      {userState.isOnCreditHold && (
        <SummaryBlock
          type={SummaryBlockTypes.error}
        >
          <LabledInformation
            label="Credit Hold"
            information={(
              <div style={{ lineHeight: 1.5 }}>
                One or more of your accounts is on credit hold. Please contact <a href="mailto:xgsar@xgsi.com" className="blue-link">xgsar@xgsi.com</a> to resolve.
              </div>
            )}
          />
        </SummaryBlock>
      )}
    </>
  );
};

export default CreditHold;
