import React from "react";
import { useField, FieldInputProps } from "formik";
import { OptionsType, GroupedOptionsType, ValueType } from "react-select";
import { XGSSelectOption, XGSSelectProps } from "../../xgs-select/xgsSelect";
import LabeledSelectInput from "../../molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { CommonLabeledInputProps, CommonLabeledInputElementProps } from "../../molecules/labeled-inputs/labeledInput";
import "./xgsFormInput.scss";

export interface XGSFormSelectProps
  extends CommonLabeledInputElementProps,
    CommonLabeledInputProps,
    XGSSelectProps {
  options: GroupedOptionsType<XGSSelectOption> | OptionsType<XGSSelectOption>;
}

const XGSFormSelect: React.FC<XGSFormSelectProps> = ({
  label,
  required,
  labelMode,
  requiredAsteriskDisabled,
  options,
  controlled,
  ...props
}) => {
  const [field, meta, helpers] = useField(props as unknown as FieldInputProps<string>);  

  const controlledSelectProps = () => {
    if (!controlled) return {};

    if (!props.isMulti) {
      return {
        onValueChange: (option?: XGSSelectOption | null): void => {
          helpers.setValue(option?.value);
          props.onValueChange?.(option);
        },
        value: options?.find((option) => field.value === option.value) as ValueType<XGSSelectOption> || null,
      }
    } else {
      return {
        onMultiValuesChange: (option?: XGSSelectOption[] | null): void => {
          helpers.setValue(option?.map(option => option.value));
          props.onMultiValuesChange?.(option);
        },
        value: field.value?.reduce((result: XGSSelectOption[], item: any) => {
          const option = options?.find((option) => item === option.value);

          return option ? [...result, option] : result;
        }, []) || null,
      }
    }
  }

  return (
    <LabeledSelectInput
      label={label}
      labelMode={labelMode}
      required={required}
      requiredAsteriskDisabled={requiredAsteriskDisabled}
      fields={field}
      meta={meta}
      options={options}
      {...props}
      {...controlledSelectProps()}      
    />
  );
};

export default XGSFormSelect