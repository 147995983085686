import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { IState } from "..";
import { initialExceptionState } from "./exceptionsState";
import ExceptionService from "../../app/data/exceptions/exceptionsService";

const exceptionService = ExceptionService.getInstance();

export const exceptionSlice = createSlice({
  name: "exception",
  initialState: initialExceptionState,
  reducers: {
    resetExceptionState: (state) => initialExceptionState,
    requestStarted: (state, { payload }) => {
      state.requestCreator = payload;
      state.requestStarted = true;
      state.requestFailed = false;
      state.requestSucceed = false;
    },
    requestSucceed: (state, { payload }) => {
      state.exceptions = payload;
      state.requestStarted = false;
      state.requestSucceed = true;
    },
    requestSuccedNoPayload: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
    },
    requestFailed: (state, { payload }) => {
      state.requestFailed = true;
      state.requestStarted = false;
      state.requestFailedReason = payload;
    },
    startLoadingExceptions: (state) => {
      state.loadedExceptions = false;
      state.loadingExceptions = true;
      state.loadingExceptionsFailed = false;
    },
    finishLoadingExceptions: (state, { payload }) => {
      state.exceptions = payload;
      state.loadedExceptions = true;
      state.loadingExceptions = false;
    },
    failedLoadingExceptions: (state, { payload }) => {
      state.loadedExceptions = false;
      state.loadingExceptions = false;
      state.loadingExceptionsFailed = true;
      state.loadingExceptionsFailReason = payload;
    },
    startLoadingRollPalletDetails: (state) => {
      state.loadedRollPalletDetails = false;
      state.loadingRollPalletDetails = true;
      state.loadingRollPalletDetailsFailed = false;
    },
    finishLoadingRollPalletDetails: (state, { payload }) => {
      state.rollPalletDetails = payload;
      state.loadedRollPalletDetails = true;
      state.loadingRollPalletDetails = false;
    },
    failedLoadingRollPalletDetails: (state, { payload }) => {
      state.loadedRollPalletDetails = false;
      state.loadingRollPalletDetails = false;
      state.loadingRollPalletDetailsFailed = true;
      state.loadingRollPalletDetailsFailReason = payload;
    },
    setShowExceptionModal: (state, { payload }) => {
      state.showModal = payload;
    }
  }
});

export const {
  resetExceptionState,
  requestFailed,
  requestStarted,
  requestSucceed,
  requestSuccedNoPayload,
  startLoadingExceptions,
  failedLoadingExceptions,
  finishLoadingExceptions,
  startLoadingRollPalletDetails,
  failedLoadingRollPalletDetails,
  finishLoadingRollPalletDetails,
  setShowExceptionModal
} = exceptionSlice.actions;

export const exceptionSelector = (state: IState) => state.exception;

export const getExceptions =
  (probillNumber: number): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingExceptions());
    const response = await exceptionService.getExceptions(probillNumber);
    if (response.ok()) {
      dispatch(finishLoadingExceptions(response.data));
    } else {
      response.getError && dispatch(failedLoadingExceptions(response.getError()));
    }
  };

export const getRollPalletDetails =
  (probillNumber: number): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingRollPalletDetails());
    const response = await exceptionService.getRollPalletDetails(probillNumber);
    if (response.ok()) {
      dispatch(finishLoadingRollPalletDetails(response.data));
    } else {
      response.getError && dispatch(failedLoadingRollPalletDetails(response.getError()));
    }
  };

export const createException =
  (
    data: any,
    onSuccess: () => void,
    onFailed: any
  ): AppThunk =>
  async (dispatch) => {
    dispatch(requestStarted("CREATE"));
    const response = await exceptionService.createException(data);
    if (response.ok()) {
      dispatch(requestSuccedNoPayload());
      onSuccess();
    } else {
      response.getError && dispatch(requestFailed(response.getError()));
      onFailed(response.getError());
    }
  };

export const exceptionReducer = exceptionSlice.reducer;
