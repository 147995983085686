import React from "react";

export interface DateCellProps {
  date: string;
}

const DateCell: React.FC<DateCellProps> = (props) => {

  return (
    <>
      {props.date && (
        <div className="xgs-table-date-cell">
          {props.date.toUiDateFormat()}
        </div>
      )}
    </>
  );
}

export default DateCell;