export default interface ShipperState {
  shipper: {
    name: string;
    address: {
      address1: string;
      city: string;
      state: string;
      postalCode: string;
    };
  };
  addressLookupValue: any;
}

export const initialShipperState: ShipperState = {
  shipper: {
    name: "",
    address: {
      address1: "",
      city: "",
      state: "",
      postalCode: "",
    }
  },
  addressLookupValue: null,
};