
export default interface CreateTeamState {
  request_was_started: boolean;
  request_was_succeed: boolean;
  request_was_failed: boolean;
  request_fail_reason?: string | null;
  accountsInfo_was_started: boolean;
  accountsInfo_was_succeed: boolean;
  accountsInfo_was_failed: boolean;
  accountsInfo_fail_reason?: string | null;
  accountsInfo: any;  
}

export const initialCreateTeamState: CreateTeamState = {
  request_was_started: false,
  request_was_succeed: false,
  request_was_failed: false,
  request_fail_reason: null,
  accountsInfo_was_started: false,
  accountsInfo_was_succeed: false,
  accountsInfo_was_failed: false,
  accountsInfo_fail_reason: null,
  accountsInfo: null
};
