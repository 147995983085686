import React, { useEffect } from "react";
import SlideOutSidebar from "../../ui-components/slide-out-sidebar/slideOutSidebar";
import Table from "../../ui-components/table/table";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerHolidayLogs, getHolidayLogs, holidayCalendarSelector } from "../../slices/holiday-calendar/holidayCalendarSlice";

const getTableColumns = () => (
  [
    {
      Header: "Date",
      accessor: "date",
      width: 130,
      Cell: (props: any) => <>{props.value.toUiDateFormat()}</>
    },
    {
      Header: "Action",
      width: 110,
      accessor: "action",
      Cell: (props: any) => {
        return <>{props.value}</>
      }
    },
    {
      Header: "Action Timestamp",
      accessor: "dateCreated",
      width: 190,
      Cell: (props: any) => <>{props.value.toUiDateTimeFormat()}</>
    },
    {
      Header: "Performed by",
      accessor: "userCreated",
      width: 210
    }
  ]
)

export interface HolidayChangeLogProps {
  show: boolean;
  accountId?: string;
  isCustomer?: boolean;
  onClose: () => void;
}

export const HolidayChangeLog: React.FC<HolidayChangeLogProps> = (props) => {
  const holidayCalendarState = useSelector(holidayCalendarSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.isCustomer) {
      let accountId = props.accountId!;
      dispatch(getCustomerHolidayLogs(accountId));
    } else {
      dispatch(getHolidayLogs());
    }
  }, [dispatch, props.show, props.isCustomer, props.accountId])

  return (
    <SlideOutSidebar onClose={props.onClose} spinner={holidayCalendarState.loadingLogs} show={props.show} header={props.isCustomer ? "Exception change log" : "Holiday change log"}>
      <div className="xgs-holiday-calendar__logs">
        <div className="xgs-holiday-calendar__logs__heading">Recent changes</div>
        <Table
          infiniteScroll
          columns={getTableColumns()}
          data={holidayCalendarState.logs}
        />
      </div>
    </SlideOutSidebar>
  )
}