import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";

import ContentContainer from "../../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledDateRangeInput from "../../../ui-components/molecules/labeled-inputs/labeled-date-range-input/labeledDateRangeInput";
import XGSFormSelect from "../../../ui-components/form/select/xgsFormSelect";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import Table from "../../../ui-components/table/table";
import Tag from "../../../ui-components/molecules/tag/tag";
import { TagColor } from "../../../app/data/common/tagColor";
import NumberFormat from "react-number-format";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import InvoicesSummary from "./invoicesSummary";
import InvoicesSelectedBar from "./invoicesSelectedBar";

import { InvoiceDeliveryStatus, InvoicePaymentStatus, InvoiceResponseModel, InvoicesFilterModel, InvoicesFilterSchema } from "../../../app/data/documents/models";
import { baseAgingPeriods } from "../../../app/data/aging/models";
import { getInvoices, getInvoicesSummary, invoicesSelector } from "../../../slices/documents/invoicesSlice";
import { SortOrder, SortParams } from "../../../app/data/common/models";

import "./invoices.scss";

const initialFilterValues: InvoicesFilterModel = {
  probill: undefined,
  customer: "",
  fromDate: undefined,
  toDate: undefined,
  missed: undefined,
};

const missedOptions = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

const missingPeriodOptions = Object.values(baseAgingPeriods).map(period => ({ label: period.title, value: period.period }));

const Invoices: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const invoicesState = useSelector(invoicesSelector);
  const filterFormRef = useRef<FormikProps<InvoicesFilterModel>>(null);
  const filterRef = useRef<InvoicesFilterModel>(initialFilterValues);
  const sortingRef = useRef<SortParams | null>(null);

  const getPaymentStatus = (row: InvoiceResponseModel) => {
    if (row.invoice.paid) {
      return InvoicePaymentStatus.paid;
    } else {
      return InvoicePaymentStatus.pending;
    }
  }

  const getPaymentStatusColor = (status: InvoicePaymentStatus) => {
    switch (status) {
      case InvoicePaymentStatus.paid: return TagColor.GREEN;
      case InvoicePaymentStatus.pending: return TagColor.ORANGE;
    }
  };

  const getDeliveryStatus = (row: InvoiceResponseModel) => {
    if (row.status.delivered) {
      return InvoiceDeliveryStatus.delivered;
    } else {
      return InvoiceDeliveryStatus.pending;
    }
  }

  const getDeliveryStatusColor = (status: InvoiceDeliveryStatus) => {
    switch (status) {
      case InvoiceDeliveryStatus.delivered: return TagColor.GREEN;
      case InvoiceDeliveryStatus.pending: return TagColor.ORANGE;
    }
  };

  const columns = [
    {
      Header: "Invoice #",
      accessor: "invoiceNumber",
      width: 112,
      Cell: (cellProps: any) => (
        <span
          className="xgs-table-link-cell"
          onClick={(e) => {
            e.stopPropagation();
            console.log(cellProps.value);
          }}
        >
          {cellProps.value}
        </span>
      )
    },
    {
      width: 205,
      Header: "Customer Name",
      accessor: "payor.info",
    },
    {
      width: 100,
      Header: "Delivery Approach",
      accessor: "deliveryApproach",
      sortId: "deliveryApproach",
    },
    {
      width: 135,
      Header: "Delivery Status",
      accessor: getDeliveryStatus,
      Cell: (cellProps: any) => (
        <Tag mods={{ color: getDeliveryStatusColor(cellProps.value), uppercase: true }}>
          {cellProps.value}
        </Tag>
      ),
      sortId: "deliveryStatus",
    },
    {
      width: 200,
      Header: "Due Date",
      accessor: (row: InvoiceResponseModel) => (row.invoice.dueDate?.toUiDateFormat() || ""),
      sortId: "dueDate",
    },
    {
      width: 200,
      Header: "Payment Date",
      accessor: (row: InvoiceResponseModel) => (row.invoice.paymentDate?.toUiDateFormat() || ""),
      sortId: "paymentDate",
    },
    {
      width: 104,
      Header: "Amount",
      accessor: "invoice.invoiceAmount",
      Cell: (cellProps: any) => (
        <div className="xgs-table__cell-amount">
          <span>$</span>
          <span className="xgs-table__cell-amount__value">
            <NumberFormat
              value={cellProps.value}
              decimalScale={2}
              displayType={"text"}
              fixedDecimalScale={true}
              thousandSeparator={true}
            />
          </span>
        </div>
      ),
      sortId: "amount",
    },
    {
      width: 135,
      Header: "Payment Status",
      accessor: getPaymentStatus,
      Cell: (cellProps: any) => (
        <Tag mods={{ color: getPaymentStatusColor(cellProps.value), uppercase: true }}>
          {cellProps.value}
        </Tag>
      ),
      sortId: "paymentStatus",
    },

  ];

  const onSubmitFilter = (values: InvoicesFilterModel) => {
    const filterValues = {
      ...values,
      ...values.fromDate && { fromDate: values.fromDate.toApiDateFormat()},
      ...values.toDate && { toDate: values.toDate.toApiDateFormat()},
      ...values.missed && { missed: (values.missed === "yes") },
    };
    
    filterRef.current = filterValues;
    sortingRef.current = null;
    dispatch(getInvoices(filterValues, null));
  };

  const onListInfiniteScroll = () => {
    if (invoicesState.invoicesRequest?.loadedAll) return;
    dispatch(getInvoices(filterRef.current, sortingRef.current, invoicesState.invoicesRequest?.lastIds));
  };

  const onSortByColumn = (index: number, sortOrder: boolean) => {
    const sortBy = columns[index].sortId;
    const order = sortOrder ? SortOrder.asc : SortOrder.desc;
    
    if (!sortBy) return;

    sortingRef.current = { sortBy, order };
    dispatch(getInvoices(filterRef.current, { sortBy, order }));
  }

  const renderInvoicesBar = (rows: {original: InvoiceResponseModel}[], toggleAllRowsSelected: (selected: boolean) => void) => {
    const invoiceNumbers = rows.map(row => row.original.invoiceNumber);
    return <InvoicesSelectedBar selectedInvoices={invoiceNumbers} onReset={() => {toggleAllRowsSelected(false)}}/>
  }

  useEffect(() => {
    dispatch(getInvoices(initialFilterValues, sortingRef.current));
    dispatch(getInvoicesSummary());
  }, [dispatch]);

  return (
    <ContentContainer
      className="xgs-list xgs-list--full-width"
      mods={{ "full-width": true }}
      titleComponent={
        <ContentContainerToolbar
          title="Invoices"
          mods={{ "full-width": true }}
        />
      }
    >
      <div className="xgs-documents__invoices">
        <div className="xgs-list__controls">
          <div className="xgs-list__controls__search">
            <Formik
              initialValues={initialFilterValues}
              onSubmit={onSubmitFilter}
              innerRef={filterFormRef}
              validationSchema={InvoicesFilterSchema}
            >
              {(props: FormikProps<InvoicesFilterModel>) => (
                <Form className="xgs-list__controls__search-form">
                  <XGSFormInput
                    type="text"
                    name="probill"
                    label="Probill #"
                    labelMode={LabelModes.column}
                    className="xgs-item-form__field"
                  />
                  <XGSFormInput
                    type="text"
                    name="customer"
                    label="Customer"
                    labelMode={LabelModes.column}
                    placeholder="Name or Number"
                    className="xgs-item-form__field"
                  />
                  <LabeledDateRangeInput
                    label="Due Date Range"
                    labelMode={LabelModes.column}
                    className="xgs-item-form__field xgs-list__controls__date-range"
                    start={props.values.fromDate}
                    end={props.values.toDate}
                    onStartChange={(v) => {
                      props.setFieldValue("fromDate", v || undefined);
                    }}
                    onEndChange={(v) => {
                      props.setFieldValue("toDate", v || undefined);
                    }}
                  />
                  <XGSFormSelect
                    name="deliveryApproach"
                    label="Delivery Approach"
                    labelMode={LabelModes.column}
                    placeholder="All types"
                    className="xgs-item-form__field"
                    options={[]}
                    isClearable
                    controlled={true}
                  />
                  <XGSFormSelect
                    name="missed"
                    label="Missed only"
                    labelMode={LabelModes.column}
                    placeholder="No"
                    className="xgs-item-form__field"
                    options={missedOptions}
                    isClearable
                    controlled={true}
                    onValueChange={(v) => {
                      if (v?.value === "yes") return;
                      props.setFieldValue("missedPeriod", undefined);
                    }}
                  />
                  <XGSFormSelect
                    name="missedPeriod"
                    label="Missing Period"
                    labelMode={LabelModes.column}
                    placeholder="All time"
                    className="xgs-item-form__field"
                    options={missingPeriodOptions}
                    isClearable
                    controlled={true}
                    disabled={props.values.missed !== "yes"}
                  />
                  <Button
                    type="submit"
                    theme={ButtonThemes.blue}
                    className=""
                    spinner={false}
                  >
                    Search
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <div className="xgs-documents__invoices__summary">
          <InvoicesSummary />
        </div>

        <div className="xgs-documents__invoices__list">
          {(invoicesState.request["GET_INVOICES"]?.requestFailed) && (
            <XGSErrorMessage>{invoicesState.request["GET_INVOICES"]?.requestError}</XGSErrorMessage>
          )}
          {!(invoicesState.request["GET_INVOICES"]?.requestFailed) && (
            <Table
              isLoading={invoicesState.request["GET_INVOICES"]?.requestStarted}
              keepTableOnLoading
              columns={columns}
              data={invoicesState.invoices}
              rowHeight={56}
              minTableHeight={340}
              noResultsText="There are no records"
              responsive
              infiniteScroll
              infiniteScrollLoading={invoicesState.request["GET_INVOICES_PORTION"]?.requestStarted}
              infiniteScrollHasNext={!invoicesState.invoicesRequest?.loadedAll}
              onInfiniteScroll={onListInfiniteScroll}
              sorting={true}
              onSortByColumn={onSortByColumn}
              sortingExcludeColumnIndexes={columns.reduce((result: number[], column, index) => (column.sortId ? result : [...result, index]), [])}
              selectable={true}              
              renderSelectedBar={renderInvoicesBar}
            />
          )}
        </div>
      </div>
    </ContentContainer>
  );
};

export default Invoices;