import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { userSelector } from "../../slices/user/userSlice";
import UserState from "../../slices/user/UserState";
import { UserUtils } from "../data/user/userUtils";
import { Routes } from "./RoutesConfig";

const ProtectedRoute: React.FC<any> = (props) => {
  const userState: UserState = useSelector(userSelector);  

  const { feature, children, ...rest } = props;

  return UserUtils.hasFeatureDisabled(userState.profile, feature)
    ? <Redirect to={Routes.home}/>
    : <Route {...rest}>{children}</Route>;
};

export default ProtectedRoute;
