import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { EmailConfirmPath, PathParams } from "./route";
import Loading from "../../../ui-components/loading/loading";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import SuccessBlock, { LoginLinkTypes } from "../../../ui-components/success-block/successBlock";
import { getCurrentUser, logout } from "../../../slices/user/userSlice";
import ProfileState from "../../../slices/user/profile/ProfileState";
import {
  checkEmailToken,
  profileSelector
} from "../../../slices/user/profile/profileSlice";

const EmailConfirm: React.FC = () => {
  const dispatch = useDispatch();
  const profileState: ProfileState = useSelector(profileSelector);
  const params = useParams() as PathParams;
  const token = params[EmailConfirmPath.token];

  useEffect(() => {
    if (profileState.update_was_started
      || profileState.update_was_succeed
      || profileState.update_was_failed) return;
    mixpanel.reset();
    dispatch(logout("SILENT"));

    token && dispatch(checkEmailToken(token, () => {
      dispatch(getCurrentUser("SIGN_IN", () => {
        if (localStorage.getItem("xgs-access-token")) localStorage.removeItem("xgs-access-token");
        if (localStorage.getItem("xgs-refresh-token")) localStorage.removeItem("xgs-refresh-token");
        if (localStorage.getItem("xgs-id-token")) localStorage.removeItem("xgs-id-token");
      }));
    }));
  }, [dispatch, params, profileState, token]);

  return (
    <div style={{
      textAlign: "center",
      marginTop: 32,
      marginLeft: -24
    }}>
      {profileState.update_was_started && (
        <Loading isLoading={true} />
      )}
      {profileState.update_was_failed && (
        <XGSErrorMessage className="block-center">
          {profileState.update_fail_reason && (<>{profileState.update_fail_reason || "Something went wrong"}</>)}
        </XGSErrorMessage>
      )}
      {profileState.update_was_succeed && (
        <SuccessBlock loginLinkType={LoginLinkTypes.CUSTOMER} showLoginLink={true}>
          You have successfully verified your email address!
        </SuccessBlock>
      )}
    </div>
  );
};

export default EmailConfirm;
