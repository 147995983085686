import { useDispatch, useSelector } from "react-redux";
import { downloadSelector, getDownload, resetDownload as reset } from "../slices/download/downloadSlice";
import { ApiResponse } from "../app/data/api";
import { toast } from "react-toastify";

export const useDownload = (
  downloadId: string,
  downloadMethod: () => Promise<ApiResponse<any>>,
) => {
  const downloadState = useSelector(downloadSelector);
  const loadingState = downloadState[downloadId];
  const dispatch = useDispatch();

  const onExportSuccess = (exportLink: string) => {
    if (!exportLink) return
    const link = document.createElement("a");
    link.href = exportLink;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onExportFailed = (error: string) => { toast.error(error); };
  
  const download = () => {
    dispatch(getDownload(downloadId, downloadMethod, onExportSuccess, onExportFailed));
  };  

  const resetDownload = () => { 
    dispatch(reset(downloadId));
  };

  return {
    download,
    resetDownload,
    loadingState,
  };
};