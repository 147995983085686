import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import ServiceCentersStatusState from "../../../slices/service-centers-status/ServiceCentersStatusState";
import {
  serviceCentersStatusSelector,
  getServiceCentersStatus
} from "../../../slices/service-centers-status/serviceCentersStatusSlice";
import SummaryBlock, { SummaryBlockTypes } from "../../../ui-components/molecules/summary-block/summaryBlock";
import * as styles from "./../../../sass/variables.module.scss";
import "./serviceCentersStatus.scss";

const ServiceCentersStatus: React.FC = (props) => {
  const dispatch = useDispatch();
  const serviceCentersStatusState: ServiceCentersStatusState = useSelector(serviceCentersStatusSelector);  

  const getIconColor = () => {
    if (serviceCentersStatusState.request_was_succeed) {
      if (serviceCentersStatusState.closedList.length === 0 && serviceCentersStatusState.limitedList.length === 0) {
        return styles.green1;
      } else if (serviceCentersStatusState.closedList.length > 0) {
        return styles.red;
      } else if (serviceCentersStatusState.limitedList.length > 0) {
        return styles.orange;
      }
    } else {
      return styles.gray3;
    }
  };

  useEffect(() => {
    dispatch(getServiceCentersStatus());
  }, [dispatch]);

  return (
    <div
      className="xgs-sc-status"
      onClick={() => {
        window.open(process.env.REACT_APP_WEBSITE_URL + "/resources-overview/weather-alert/", "_blank");
      }}
      title="Click to open the map in a new window"
    >
      <SummaryBlock
        icon={XGSIcons.faWarehouse}
        iconColor={getIconColor()}
        type={SummaryBlockTypes.info}
        loading={serviceCentersStatusState.request_was_started}
      >
        {serviceCentersStatusState.request_was_started && (
          <div className="xgs-sc-status__loading">
            Obtaining service centers status...
          </div>
        )}
        {serviceCentersStatusState.request_was_succeed && (
          <div className="xgs-sc-status__content">
            {(serviceCentersStatusState.closedList.length === 0 && serviceCentersStatusState.limitedList.length === 0) && (
              <div className="xgs-sc-status__content__one-line-center">All service centers are operational</div>
            )}
            {serviceCentersStatusState.closedList.length > 0 && (
              <>
                <div>Closed service centers:</div>
                <ul>
                  {serviceCentersStatusState.closedList.map((name: string) => (
                    <li key={name}>{name}</li>
                  ))}
                </ul>
              </>
            )}
            {serviceCentersStatusState.limitedList.length > 0 && (
              <>
                <div>Limited operation service centers:</div>
                <ul>
                  {serviceCentersStatusState.limitedList.map((name: string) => (
                    <li key={name}>{name}</li>
                  ))}
                </ul>
              </>
            )}
          </div>
        )}
        {serviceCentersStatusState.request_was_failed && (
          <div className="xgs-sc-status__content__one-line-center">
            {serviceCentersStatusState.requestError || "Error getting service center status"}
          </div>
        )}
      </SummaryBlock>
    </div>
  );
};

export default ServiceCentersStatus;
