import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import XGSIcon from "../../ui-components/icon/xgsIcon";
import XGSIcons from "../../ui-components/icon/xgsIcons";
import Loading from "../../ui-components/loading/loading";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import SlideOutSidebar from "../../ui-components/slide-out-sidebar/slideOutSidebar";
import Button, { ButtonSizes, ButtonThemes } from "../../ui-components/button/button";
import ClaimsState from "../../slices/claims/ClaimsState";
import {
  claimsSelector,
  downloadClaimDocument,
  getClaimDetails,
  storeClaimDetails
} from "../../slices/claims/claimsSlice";
import { ClaimDocumentModel } from "../../app/data/claims/models";
import {
  CLAIM_REASON_TYPES,
  CLAIM_STATUS,
  CLAIM_STATUSES,
  DOCUMENTS_TYPES
} from "./constants";
import { ReactComponent as EditIcon } from "../../images/edit_blue.svg";
import "./claims.scss";
import EditClaimPopup from "./editClaimPopup";

interface ClaimDetailsProps {
  claimNumber?: number;
  onClose: () => void;
};

const ClaimDetails: React.FC<ClaimDetailsProps> = (props) => {
  const dispatch = useDispatch();
  const claimsState: ClaimsState = useSelector(claimsSelector);
  const [downloadId, setDownloadId] = useState<string>("");
  const [showEditClaimPopup, setShowEditClaimPopup] = useState(false);

  const getNormalizedDocuments = () => {
    let normalizedDocuments = [];
    for (const documentTypeObj of DOCUMENTS_TYPES) {
      const filteredDocuments = claimsState.claimDetails.documents.filter((obj: ClaimDocumentModel) => obj.type === documentTypeObj.value);
      let i = 0;
      for (const documentObj of filteredDocuments) {
        i++;
        const docType = DOCUMENTS_TYPES.find(type => type.value === documentObj.type)?.label;
        normalizedDocuments.push({
          name: filteredDocuments.length > 1 ? `${docType} (${i})` : docType,
          id: documentObj.id,
          type: documentObj.type
        })
      }
    }
    return normalizedDocuments;
  };

  const onClickDownloadDocument = (id: string, name: string) => {
    if (!props.claimNumber) return;
    setDownloadId(id);
    dispatch(downloadClaimDocument(props.claimNumber, id, (file: Blob, fileType: string) => {
      let documentfileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = documentfileURL;
      link.download = `${props.claimNumber}_${name}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloadId("");
    }));
  };

  useEffect(() => {
    if (!props.claimNumber) return;
    dispatch(getClaimDetails(props.claimNumber));
  }, [dispatch, props.claimNumber]);

  useEffect(() => {
    return () => {
      dispatch(storeClaimDetails(undefined));
    }
  }, [dispatch]);

  return (
    <>
    <SlideOutSidebar
      header={`Claim Details ${claimsState.claimDetails?.claimNumber && !(claimsState.requestStarted && claimsState.requestCreator === "CLAIM_DETAILS") ? "#" + claimsState.claimDetails.claimNumber : ""}`}
      onClose={props.onClose}
      show={!!props.claimNumber}
      spinner={claimsState.requestStarted && claimsState.requestCreator === "CLAIM_DETAILS"}
    >
      <div className="xgs-claims__details">
        {claimsState.requestFailed && claimsState.requestCreator === "CLAIM_DETAILS" && (
          <XGSErrorMessage>{claimsState.requestError || "Error while obtaining claim details"}</XGSErrorMessage>
        )}
        {!(claimsState.requestFailed && claimsState.requestCreator === "CLAIM_DETAILS") && !(claimsState.requestStarted && claimsState.requestCreator === "CLAIM_DETAILS") && claimsState.claimDetails && (
          <div className="xgs-sidebar__details__rows">
            <div className="xgs-sidebar__details__row">
              <div className="xgs-sidebar__details__label">Status:</div>
              <div className="xgs-sidebar__details__value">
                <div className="xgs-status-label" style={{ backgroundColor: CLAIM_STATUSES.find(status => status.value === claimsState.claimDetails.status)?.color || "#cccccc" }}>
                  {CLAIM_STATUSES.find(status => status.value === claimsState.claimDetails.status)?.label}
                </div>
              </div>
            </div>
            <div>&nbsp;</div>
            <div className="xgs-sidebar__details__row">
              <div className="xgs-sidebar__details__label">Claim #:</div>
              <div className="xgs-sidebar__details__value">
                {claimsState.claimDetails.claimNumber}
              </div>
            </div>
            <div className="xgs-sidebar__details__row">
              <div className="xgs-sidebar__details__label">Probill #:</div>
              <div className="xgs-sidebar__details__value">
                {claimsState.claimDetails.probill}
              </div>
            </div>
            <div className="xgs-sidebar__details__row">
              <div className="xgs-sidebar__details__label">BOL #:</div>
              <div className="xgs-sidebar__details__value">
                {claimsState.claimDetails.bol}
              </div>
            </div>
            {claimsState.claimDetails.originalClaimNumber && (
              <div className="xgs-sidebar__details__row">
                <div className="xgs-sidebar__details__label">Claimants Claim #:</div>
                <div className="xgs-sidebar__details__value">
                  {claimsState.claimDetails.originalClaimNumber}
                </div>
              </div>
            )}
            <div className="xgs-sidebar__details__row">
              <div className="xgs-sidebar__details__label">Claim Amount:</div>
              <div className="xgs-sidebar__details__value">
                <span>${claimsState.claimDetails.totalClaimAmount.toFixed(2)}</span>
                  {claimsState.claimDetails.status === CLAIM_STATUS.NEW && <Button
                    className="xgs-sidebar__details__button"
                    theme={ButtonThemes.transparent}
                    size={ButtonSizes.auto}
                    onClick={() => {
                      setShowEditClaimPopup(true)
                    }}
                  >
                    <div className="xgs-btn__icon-container">
                      <EditIcon className="xgs-btn__icon" />
                    </div>
                    Edit
                  </Button>
                  }
              </div>
            </div>
            <div className="xgs-sidebar__details__row">
              <div className="xgs-sidebar__details__label">Date Created:</div>
              <div className="xgs-sidebar__details__value">
                {claimsState.claimDetails.createdAt && (
                  <>
                    {claimsState.claimDetails.createdAt.toUiDateFormat()}
                  </>
                )}
              </div>
            </div>
            <div className="xgs-sidebar__details__row">
              <div className="xgs-sidebar__details__label">Reason:</div>
              <div className="xgs-sidebar__details__value">
                {CLAIM_REASON_TYPES.find(reason => reason.value === claimsState.claimDetails.reason)?.label}
              </div>
            </div>
            <div className="xgs-sidebar__details__row">
              <div className="xgs-sidebar__details__label">Comments:</div>
              <div className="xgs-sidebar__details__value">
                {claimsState.claimDetails.comments || "-"}
              </div>
            </div>
            <div className="xgs-sidebar__details__row">
              <div className="xgs-sidebar__details__label">Date Resolved:</div>
              <div className="xgs-sidebar__details__value">
                {claimsState.claimDetails.dateResolved ? claimsState.claimDetails.dateResolved.toUiDateFormat() : "-"}
              </div>
            </div>
            <div className="xgs-sidebar__details__row">
              <div className="xgs-sidebar__details__label">Settlement Amount:</div>
              <div className="xgs-sidebar__details__value">
                {claimsState.claimDetails.settlementAmount ? `$${claimsState.claimDetails.settlementAmount}` : "-"}
              </div>
            </div>
            <div>&nbsp;</div>
            <div className="xgs-sidebar__details__row">
              <div className="xgs-sidebar__details__label">Documents:</div>
              <div className="xgs-sidebar__details__value">
                <div className="xgs-claims__details__documents">
                  {claimsState.claimDetails.documents && getNormalizedDocuments().map((doc: any, index: number) =>
                    <div
                      className={`xgs-claims__details__documents__item${downloadId ? " xgs-claims__details__documents__item--loading" : ""}`}
                      key={"doc-" + index}
                      onClick={() => !downloadId && onClickDownloadDocument(doc.id, doc.name)}
                    >
                      <XGSIcon
                        icon={XGSIcons.faFile}
                        className="xgs-claims__details__documents__item__icon"
                      />
                      {downloadId === doc.id && (
                        <div className="xgs-claims__details__documents__item__spinner">
                          <Loading isLoading={true} />
                        </div>
                      )}
                      <div className="xgs-claims__details__documents__item__header">{doc.name}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </SlideOutSidebar>

    <EditClaimPopup 
    show={showEditClaimPopup} 
    onClose={() => {setShowEditClaimPopup(false)}}
    claim={claimsState.claimDetails}
    />
    </>
  );
};

export default ClaimDetails;
