import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import {
  ConfirmDataRequestModel,
  LoginFormModel
} from "../../app/data/user/requestModels";
import {
  initialLegacyUserState,
  LegacyResponseModel
} from "./LegacyUserState";
import UserService from "../../app/data/user/userService";

const userService = UserService.getInstance();

export const legacyUserSlice = createSlice({
  name: "legacyUser",
  initialState: initialLegacyUserState,
  reducers: {
    resetLegacyUser: state => initialLegacyUserState,
    storeLegacyUserData: (state, { payload }) => {
      state.username = payload.username;
      state.password = payload.password;
      state.data = payload.data;
    },
    resetRequestState: (state) => {
      state.requestStarted = false;
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestCreator = "";
    },    
    request_was_started: (state, { payload }) => {
      state.requestStarted = true;
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestCreator = payload;
    },
    request_was_succeed: (state) => {
      state.requestStarted = false;
      state.requestFailed = false;
      state.requestSucceed = true;
    },
    request_was_failed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = true;
      state.requestSucceed = false;
      state.requestFailReason = payload;
    }
  }
});

export const {
  resetLegacyUser,
  storeLegacyUserData,
  resetRequestState,
  request_was_started,
  request_was_succeed,
  request_was_failed
} = legacyUserSlice.actions;

export const legacyUserSelector = (state: IState) => state.legacyUser;

export const checkLegacyUser = (
  request: LoginFormModel,
  onSuccess: (response: LegacyResponseModel) => void,
  onFail: (message: string | null) => void
): AppThunk => async (dispatch) => {
  dispatch(request_was_started("CHECK_USER"));
  const response = await userService.checkLegacyUser(request);
  if (response.ok()) {
    dispatch(request_was_succeed());
    onSuccess(response.data);
  } else {
    dispatch(request_was_failed(response.getError ? response.getError() : "Error"));
    let errorMessage = response.getError ? response.getError()?.toString() : undefined;
    onFail(errorMessage || "Bad credentials");
  }
};

export const confirmLegacyUserData = (
  request: ConfirmDataRequestModel
): AppThunk => async (dispatch) => {
  dispatch(request_was_started("CONFIRM_DATA"));
  const response = await userService.confirmLegacyUserData(request);
  if (response.ok()) {
    dispatch(request_was_succeed());
  } else {
    dispatch(request_was_failed(response.getError ? response.getError() : "Error"));
  }
};

export const checkToken = (
  token: string,
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(request_was_started("CHECK_TOKEN"));
  const response = await userService.checkConfirmLegacyUserToken(token);
  if (response.ok()) {
    dispatch(request_was_succeed());
    onSuccess();
  } else {
    dispatch(request_was_failed(response.getError ? response.getError() : "Error"));
  }
};

export const checkLegacyUserEmail = (
  username: string,
  onSuccess: (status: number, email?: string) => void
): AppThunk => async (dispatch) => {
  dispatch(request_was_started("CHECK_LEGACY_EMAIL"));
  const response = await userService.checkLegacyUserEmail(username);
  if (response.ok()) {
    dispatch(request_was_succeed());
    onSuccess(200, response.data.email);
  } else {
    const errorData = response.getFullError() as any;
    if (errorData?.status === 400 || errorData?.status === 404) {
      dispatch(request_was_succeed());
      onSuccess(errorData?.status);
    } else {
      response.getError && dispatch(request_was_failed(response.getError()));
    }
  }
};

const legacyUserReducer = legacyUserSlice.reducer;
export default legacyUserReducer;
