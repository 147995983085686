import api, { ApiResponse } from "../api";
import { Contact } from "./models";

class ContactService {
  private static instance: ContactService;

  private constructor() {}

  static getInstance(): ContactService {
    if (!ContactService.instance) {
      ContactService.instance = new ContactService();
    }

    return ContactService.instance;
  }

  public getContacts = async (accountId: String): Promise<ApiResponse<any>> => {
    return await api.get(`/accounts/${accountId}/contacts`)
  }

  public addAccountContact = async (accountId: String | null, contact: Contact): Promise<ApiResponse<any>> => {
    return await api.post(`/accounts/${accountId}/contacts`, contact);
  }

  public addContact = async (contact: Contact): Promise<ApiResponse<any>> => {
    return await api.post(`/contacts`, contact);
  }

  public editAccountContact = async (accountId: String, contact: Contact): Promise<ApiResponse<any>> => {
    return await api.put(`/accounts/${accountId}/contacts/${contact.id}`, contact);
  }

  public deleteContact = async (accountId: String, contact: Contact): Promise<ApiResponse<any>> => {
    return await api.delete(`/accounts/${accountId}/contacts/${contact.id}`);
  }
}

export default ContactService;
