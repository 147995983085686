import LogRocket from "logrocket";
import mixpanel from "mixpanel-browser";
import { batch } from "react-redux";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { IState } from "..";
import { initialUserState } from "./UserState";
import { resetAging } from "../aging/agingSlice";
import { resetInvoiceAging } from "../aging/agingInvoicesSlice";
import { resetInvoiceDetails } from "../invoice/invoiceSlice";
import UserProfile from "../../app/data/user/UserProfile";
import UserService from "../../app/data/user/userService";
import AccountService from "../../app/data/account/accountService";
import { TokenRequestModel } from "../../app/data/user/models";
import SessionStorage from "../../app/local-storage/SessionStorage";
import StatusCheckSubAccount, { SubAccountCreditStatusTypes } from "../../app/data/account/StatusCheckSubAccount";
import { resetPayment } from "../payment/paymentSlice";
import { resetResetPasswordState } from "../reset-password/resetPasswordSlice";
import { resetServiceCenters } from "../service-centers/serviceCentersSlice";
import { resetTrackShipment } from "../track-shipment/trackShipmentSlice";
import { resetShipmentDetails } from "../shipment-details/shipmentDetailsSlice";
import { UserUtils } from "../../app/data/user/userUtils";

declare const window: Window & typeof globalThis & {
  $crisp: any,
  CRISP_TOKEN_ID: string
};

const userService = UserService.getInstance();
const accountService = AccountService.getInstance();

export const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    resetUser: (state) => {
      state = { ...initialUserState };
      state.isOnCreditHold = false;
      state.loginFirstAttemptFinished = true;
    },
    loginStarted: (state) => {
      state.loginProcess = true;
      state.loginFailed = false;
      state.isOnCreditHold = false;
    },
    loginSucceed: (state) => {
      state.loggedIn = true;
      state.loginProcess = false;
      state.loginFailed = false;
      state.loginFirstAttemptFinished = true;
    },
    loginFailed: (state) => {
      state.loginFailed = true;
      state.loginProcess = false;
      state.loginFirstAttemptFinished = true;
    },
    logoutStarted: (state) => {
      state.logoutProcess = true;
    },
    setActiveSubAccount: (state, { payload }) => {
      state.activeSubAccount = payload;
      SessionStorage.getInstance().setActiveSubAccount(payload);
    },
    updateActiveSubAccount: (state, { payload }) => {
      const subAccountIndex = state.profile?.subAccounts.findIndex(subAccount => subAccount.id === payload.accountId);
      if (subAccountIndex !== -1 && state.profile) {
        state.profile.subAccounts[subAccountIndex!].name = payload.name;
        state.profile.subAccounts[subAccountIndex!].address.city = payload.city;
        state.profile.subAccounts[subAccountIndex!].address.state = payload.state;
      }
      if (state.activeSubAccount) {
        state.activeSubAccount.name = payload.name;
        state.activeSubAccount.address.city = payload.city;
        state.activeSubAccount.address.state = payload.state;
        SessionStorage.getInstance().setActiveSubAccount(state.activeSubAccount);
      }
    },
    getCurrentUser_has_Started: (state) => {
      state.getCurrentUser_is_Started = true;
      state.getCurrentUser_is_Succeed = false;
      state.getCurrentUser_is_Failed = false;
    },
    getCurrentUser_has_Succeed: (state, { payload }) => {
      state.getCurrentUser_is_Succeed = true;
      state.getCurrentUser_is_Started = false;
      state.getCurrentUser_is_Failed = false;

      const userProfile = payload as UserProfile;

      state.profile = userProfile;
      state.loginFirstAttemptFinished = true;

      if (userProfile.subAccounts) {
        let paypointAccount;
        if (userProfile && userProfile.paypointNumbers && userProfile.paypointNumbers.length > 0) {
          const paypointNumber = userProfile.paypointNumbers[0] || 0;
          paypointAccount = userProfile.subAccounts.find((account: any) => account.accountNumber === paypointNumber);
        }
        state.activeSubAccount = paypointAccount
          ? paypointAccount
          : SessionStorage.getInstance().getActualizedActiveSubAccount(userProfile.subAccounts);
      }
    },
    getCurrentUser_has_Failed: (state, { payload }) => {
      state.getCurrentUser_is_Failed = true;
      state.getCurrentUser_is_Started = false;
      state.getCurrentUser_is_Succeed = false;
      state.loginFirstAttemptFinished = true;
      state.failReason = payload;
    },
    setIsOnCreditHold: (state, { payload }) => {
      state.isOnCreditHold = payload.length > 0;
      if (state.profile) state.profile.accountsOnCreditHold = payload;
    },
    signupSucceed: (state) => {},
    openMobileSideMenu: (state) => {
      state.showMobileSideMenu = true;
    },
    closeMobileSideMenu: (state) => {
      state.showMobileSideMenu = false;
    },
    setUserTimezone: (state, { payload }) => {
      if (!state.profile) return;
      state.profile.timezone = payload;
    },
    setPortalState: (state, { payload }) => {
      state.portalState = payload;
    },
    setActiveTerminal: (state, { payload }) => {
      state.activeTerminal = payload;
      SessionStorage.getInstance().setActiveTerminal(payload);
    }
  }
});

export const {
  resetUser,
  loginStarted,
  loginSucceed,
  loginFailed,
  logoutStarted,
  setActiveSubAccount,
  updateActiveSubAccount,
  setIsOnCreditHold,
  getCurrentUser_has_Started,
  getCurrentUser_has_Succeed,
  getCurrentUser_has_Failed,
  openMobileSideMenu,
  closeMobileSideMenu,
  setUserTimezone,
  setPortalState,
  setActiveTerminal
} = userSlice.actions;

export const userSelector = (state: IState) => {
  return state.user;
};

export const loadCreditStatus = (): AppThunk => async (
  dispatch
) => {
  const response = await accountService.getStatusCheckAccounts();
  if (response.ok() && response.data) {
    const accounts = response.data as unknown as StatusCheckSubAccount[];
    const onHoldAccounts = accounts.filter(account => account.collectorType === SubAccountCreditStatusTypes.onHold).map(account => account.accountNumber);
    if (onHoldAccounts) {
      dispatch(setIsOnCreditHold(onHoldAccounts));
    }
  }
};

export const getCurrentUser = (
  processName: string,
  onSuccess?: (response: any) => void,
  onFailed?: (statusCode: number) => void
): AppThunk => async (dispatch) => {
  dispatch(getCurrentUser_has_Started());
  const response = await userService.getCurrent();
  if (response.ok()) {
    batch(() => {
      dispatch(loginSucceed());
      dispatch(getCurrentUser_has_Succeed(response.data));
    });
    if (onSuccess) {
      onSuccess(response.data);
    }
    dispatch(loadCreditStatus());
    if (process.env.REACT_APP_XGS_ENV === "production") {
      LogRocket.identify(response.data.id, {
        name: response.data.name || "",
        email: response.data.email || "",
        company: response.data.companyName || ""
      });
    }
    if (!UserUtils.isEmployee(response.data)) {
      mixpanel.identify(response.data.id);
      mixpanel.people.set({
        "name": response.data.name || "",
        $email: response.data.email || ""
      });
      window.CRISP_TOKEN_ID = response.data.id;
      window.$crisp.push(["do", "session:reset"]);
      window.$crisp.push(["set", "user:email", [response.data.email]]);
      window.$crisp.push(["set", "user:nickname", [response.data.name]]);
      response.data.team && window.$crisp.push(["set", "user:company", [response.data.team.name]]);
    }
    if (processName === "SIGN_IN") {
      console.log("role:", response.data.role)
      mixpanel.track("Logged In", { "Role": response.data.role });
    }
    window.CRISP_TOKEN_ID = response.data.id;
    window.$crisp.push(["do", "session:reset"]);
    window.$crisp.push(["set", "user:email", [response.data.email]]);
    window.$crisp.push(["set", "user:nickname", [response.data.name]]);
  } else {
    dispatch(getCurrentUser_has_Failed(response.getError ? response.getError() : "Error"));
    onFailed && onFailed(response.status);
  }
};

export const getPortalState = (
  onSuccess?: () => void,
): AppThunk => async (dispatch) => {
  const response = await userService.getPortalState();
  if (response.ok()) {
    dispatch(setPortalState(response.data));
    onSuccess && onSuccess();
  }
};

export const getAuthToken = (
  request: TokenRequestModel,
  onSuccess?: (response: any) => void,
  onFailed?: () => void
): AppThunk => async (dispatch) => {
  dispatch(loginStarted());
  const response = await userService.getAuthToken(request);
  if (response?.status === 200) {
    response.data.access_token && localStorage.setItem("xgs-access-token", response.data.access_token);
    response.data.id_token && localStorage.setItem("xgs-id-token", response.data.id_token);
    response.data.refresh_token && localStorage.setItem("xgs-refresh-token", response.data.refresh_token);
    dispatch(loginSucceed());
    onSuccess && onSuccess(response.data);
  } else {
    dispatch(loginFailed());
    onFailed && onFailed();
  }
};

export const logout = (
  mode?: string
): AppThunk => async (dispatch) => {
  dispatch(logoutStarted());
  window.CRISP_TOKEN_ID = "";
  window.$crisp.push(["do", "session:reset"]);
  const idToken = localStorage.getItem("xgs-id-token");
  if (mode !== "SILENT") {
    const authUrl = process.env.REACT_APP_LOGOUT_ENDPOINT + `?id_token_hint=${idToken}&post_logout_redirect_uri=${process.env.REACT_APP_DOMAIN}`;
    localStorage.removeItem("xgs-access-token");
    localStorage.removeItem("xgs-id-token");
    localStorage.removeItem("xgs-refresh-token");
    if (idToken && idToken !== "undefined") {
      window.location.assign(authUrl);
    } else {
      process.env.REACT_APP_DOMAIN && window.location.assign(process.env.REACT_APP_DOMAIN);
    }
  } else {
    await userService.silentLogout();
    localStorage.removeItem("xgs-access-token");
    localStorage.removeItem("xgs-id-token");
    localStorage.removeItem("xgs-refresh-token");
  }
};

export const resetAllStatesExceptUser = (): AppThunk => async (dispatch) => {
  dispatch(resetAging());
  dispatch(resetInvoiceAging());
  dispatch(resetInvoiceDetails());
  dispatch(resetPayment());
  dispatch(resetResetPasswordState());
  dispatch(resetServiceCenters());
  dispatch(resetTrackShipment());
  dispatch(resetShipmentDetails());
};

const usersReducer = userSlice.reducer;
export default usersReducer;
