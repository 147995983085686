
export default interface TrailerLocationState {
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError?: string | null;
  result: any;
}

export const initialTrailerLocationState: TrailerLocationState = {
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestError: null,
  result: null
};
