import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import MetricsService from "../../app/data/metrics/metricsService";
import { initialMetricsState } from "./MetricsState";

const metricsService = MetricsService.getInstance();

export const metricsSlice = createSlice({
  name: "metrics",
  initialState: initialMetricsState,
  reducers: {
    resetMetricsState: (state) => initialMetricsState,
    requestStarted: (state) => {
      state.requestStarted = true;
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestError = null;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = true;
      state.requestSucceed = false;
      state.requestError = payload;
    },
    requestSucceed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = false;
      state.requestSucceed = true;
      state.requestError = null;
      state.response = payload;
    }
  }
});

export const {
  resetMetricsState,
  requestStarted,
  requestFailed,
  requestSucceed
} = metricsSlice.actions;

export const metricsSelector = (state: IState) => {
  return state.metrics;
};

export const getMetrics = (
  accountId: string,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted());
  const response = await metricsService.getShipmentsStatus(accountId);
  if (response.ok()) {
    dispatch(requestSucceed(response.data));
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

const metricsReducer = metricsSlice.reducer;
export default metricsReducer;
