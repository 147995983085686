import React from "react";

import "./selectedBar.scss";

const SelectedBar: React.FC<{}> = (props) => {
  return (
    <div className="xgs-table__selected-bar">
      <div className="xgs-table__selected-bar__bar">
        {props.children}
      </div>
    </div>
  );
};

export default SelectedBar;
