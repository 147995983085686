import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SubAccount from "../../../app/data/account/SubAccount";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import AccountDetails from "../../company/accounts/accountDetails";
import Table from "../../../ui-components/table/table";
import { useHistory, useParams } from "react-router-dom";
import { Routes } from "../../../app/route/RoutesConfig";
import { CompanySettingsTabRoutes, CompanySettingsPath, PathParams } from "../route";
import { UserUtils } from "../../../app/data/user/userUtils";
import Tag from "../../../ui-components/molecules/tag/tag";
import { TagColor } from "../../../app/data/common/tagColor";
import {ReactComponent as LinkIcon} from "../../../images/new_window.svg";

const CompanySettingsAccounts: React.FC<{}> = () => {
  const userState: UserState = useSelector(userSelector);
  const [accountId, setAccountId] = useState<string | undefined>();
  const history = useHistory();
  const params = useParams() as PathParams;

  const columns = [
    {
      Header: (
        <div className="text-center">
          Account #
        </div>
      ),      
      accessor: "accountNumber",
      width: 120,
      Cell: (cellProps: any) => (
        <div className="text-center">
          { cellProps.value }
        </div>
      )
    },
    {
      Header: "Account name",
      accessor: "name",
      width: 240
    },
    {
      Header: (
        <div className="text-center">
          State
        </div>
      ),
      accessor: "address.state",
      width: 60,
      Cell: (cellProps: any) => (
        <div className="text-center">
          { cellProps.value }
        </div>
      )      
    },
    {
      Header: (
        <div className="text-center">
          Payor
        </div>
      ),      
      accessor: "payor",
      width: 90,
      Cell: (cellProps: any) => (
        <div className="text-center">
          { cellProps.value ? "Yes" : "No" }
        </div>
      )
    },
    {
      Header: "Credit Hold",
      id: "creditHoldColumn",
      width: 90,
      Cell: ({ row }: any) => (        
        <div className="text-center">
          {!userState.isOnCreditHold && (
            <>
              No
            </>
          )}
          {userState.isOnCreditHold && (
            <>
              { userState.profile?.accountsOnCreditHold.find(accountNumber => accountNumber === row.values.accountNumber) ? "Yes" : "No" }
            </>
          )}

        </div>
      )
    },
    {
      Header: "Availability time",
      accessor: "isAvailabilityHoursFilled",
      width: 120,
      Cell: (props: any) => (
        <div onClick={(e) => {
          e.stopPropagation();
          history.push(`${Routes.company.general}${CompanySettingsTabRoutes.accounts}/${props.row.original.accountNumber}/receiving-hours`)
        }}>
          {props.value ? (
            <Tag mods={{ color: TagColor.GREEN }}>
              AVAILABLE&nbsp;<LinkIcon />
            </Tag>
          ) : (
            <Tag mods={{ color: TagColor.GREY }}>
              MISSING&nbsp;<LinkIcon />
            </Tag>
          )}
        </div>
      )
    },
    {
      Header: "Contacts",
      accessor: "isContactsFilled",
      width: 120,
      Cell: (props: any) => (
        <div onClick={(e) => {
          e.stopPropagation();
          history.push(`${Routes.company.general}${CompanySettingsTabRoutes.accounts}/${props.row.original.accountNumber}/contacts`)
        }}>
          {props.value ? (
            <Tag mods={{ color: TagColor.GREEN }}>
              AVAILABLE&nbsp;<LinkIcon />
            </Tag>
          ) : (
            <Tag mods={{ color: TagColor.GREY }}>
              MISSING&nbsp;<LinkIcon />
            </Tag>
          )}
        </div>
      )
    },
  ];

  const onAccountClick = (row: SubAccount) => {
    setAccountId(row.id);
    UserUtils.isCustomer(userState.profile) && history.replace(`${Routes.company.general}${CompanySettingsTabRoutes.accounts}/${row.accountNumber}`)
  };

  useEffect(() => {
    if (params[CompanySettingsPath.selectedItem]) {
      let allAccounts = userState.profile?.subAccounts!;
      let selectedAccount = allAccounts.find(acc => String(acc.accountNumber) === params[CompanySettingsPath.selectedItem]);
      selectedAccount && setAccountId(selectedAccount.id);
    }
  }, [params, userState.profile])

  return (
    <>
      <Table
        isLoading={false}
        columns={columns}
        data={userState.profile?.subAccounts}
        onRowClicked={onAccountClick}
        cursorPointer={true}
        rowHeight={66}
        minTableHeight={420}
        noResultsText="There are no accounts"
      />
      <AccountDetails
        id={accountId}
        show={!!accountId}
        onClose={() => {
          setAccountId(undefined);
        }} />
    </>
  );
};

export default CompanySettingsAccounts;
