import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import CommonService from "../../app/data/common/commonService";
import TransitCalculatorService from "../../app/data/transit-calculator/transitCalculatorService";
import { TransitCalculatorModel } from "../../app/data/transit-calculator/models";
import { LocationDataModel } from "../../app/data/common/models";
import { initialTransitCalculatorState } from "./TransitCalculatorState";

const commonService = CommonService.getInstance();
const transitCalculatorService = TransitCalculatorService.getInstance();

export const transitCalculatorSlice = createSlice({
  name: "transitCalculator",
  initialState: initialTransitCalculatorState,
  reducers: {
    resetTransitCalculatorState: (state) => initialTransitCalculatorState,
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestError = null;
      state.requestCreator = payload;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = true;
      state.requestSucceed = false;
      state.requestError = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestFailed = false;
      state.requestSucceed = true;
      state.requestError = null;
    },
    storeResult: (state, { payload }) => {
      state.result = payload;
    },
    storeOriginLocations: (state, { payload }) => {
      state.originLocations = [];
      state.originLocations.push(...payload.map((location: LocationDataModel) => {
        return {
          label: `${location.zip} - ${location.city}, ${location.state}`,
          value: location.zip,
          valueForSearch: `${location.zip} ${location.city}`
        }
      }));
    },
    storeDestinationLocations: (state, { payload }) => {
      state.destinationLocations = [];
      state.destinationLocations.push(...payload.map((location: LocationDataModel) => {
        return {
          label: `${location.zip} - ${location.city}, ${location.state}`,
          value: location.zip,
          valueForSearch: `${location.zip} ${location.city}`
        }
      }));      
    },
    resetResult: (state) => {
      state.result = null;
      state.originLocations = [];
      state.destinationLocations = [];
    }
  }
});

export const {
  resetTransitCalculatorState,
  requestStarted,
  requestFailed,
  requestSucceed,
  storeResult,
  storeOriginLocations,
  storeDestinationLocations,
  resetResult
} = transitCalculatorSlice.actions;

export const transitCalculatorSelector = (state: IState) => {
  return state.transitCalculator;
};

export const getTransitTime = (
  request: TransitCalculatorModel,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("CALCULATE"));
  dispatch(resetResult());
  const response = await transitCalculatorService.getTransitTime(request);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(storeResult(response.data));
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const searchLocations = (
  type: string,
  query: string,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted(type));
  const response = await commonService.searchLocations(query);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(type === "ORIGIN" ? storeOriginLocations(response.data) : storeDestinationLocations(response.data));
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

const transitCalculatorReducer = transitCalculatorSlice.reducer;
export default transitCalculatorReducer;
