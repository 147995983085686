import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { StepObject } from "react-albus";
import { Form, Formik, FormikProps } from "formik";

import { WizardNavigation } from "../../xgs-wizard/wizardNavigation";
import XGSFormInput from "../../form/input/xgsFormInput";
import { LabelModes } from "../../molecules/labeled-inputs/labeledInput";
import { WizardStepIndicator } from "../../xgs-wizard/wizardStepIndicator";
import FormAddressSelector from "../../form/address/formAddressSelector";

import { setAddressLookupValue, setShipper, shipperSelector } from "../../../slices/shipper/shipperSlice";
import { CreateShipperModel, CreateShipperSchema } from "../../../app/data/shipper/models";

export interface SelectAddressStepProps {
  next: () => void;
  steps: StepObject[];
  onZipChange?: (zip: string) => void;  
  isLoading?: boolean;
  error?: string;
}

const SelectAddressStep: React.FC<SelectAddressStepProps> = (props) => {
  const shipperState = useSelector(shipperSelector);
  const dispatch = useDispatch();
  const formRef = useRef<any>(null);

  const { shipper, addressLookupValue } = shipperState;

  const storeShipper = () => {
    dispatch(setShipper(formRef.current.values));
  };

  const next = () => {
    formRef.current.submitForm().then(() => props.next());
  }

  return (
    <>
      <div className="wizard__heading">
        Add new shipper
      </div>

      <div className="wizard__desc">
        Add shipper contact information and press Next&nbsp;button.
      </div>

      <WizardStepIndicator step={0} stepCount={props.steps.length} />

      <Formik 
      innerRef={formRef}
      initialValues={shipper}
      onSubmit={storeShipper}
      validationSchema={CreateShipperSchema}
      >
        {(formProps: FormikProps<CreateShipperModel>) => (
          <Form className="wizard">

            <XGSFormInput
              type="text"
              name="name"
              label="Company name"
              required={true}
              labelMode={LabelModes.column}
              className="xgs-pickup__field"
            />

            <FormAddressSelector
              editable={true}
              required
              addressLookupValue={addressLookupValue}
              setAddressLookupValue={(v) => dispatch(setAddressLookupValue(v))}
              onZipChange={props.onZipChange}
              isLoading={props.isLoading}
              error={props.error}
            />            

            <WizardNavigation
              next={next}
              nextButtonDisabled={!formProps.isValid || !formProps.values.name || !!props.error}
            />

          </Form>
        )}
      </Formik >
    </>
  );
};

export default SelectAddressStep;
