import { InvoiceDocument } from "../../app/data/invoice/models";

export default interface InvoiceDocumentsState {
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError?: string | null;
  requestCreator?: string | null;
  documents?: InvoiceDocument[] | null;
  link?: string;
}

export const initialInvoiceDocumentsState: InvoiceDocumentsState = {
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestError: null,
  requestCreator: null,
  documents: null,
  link: undefined
};
