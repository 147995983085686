import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export interface ConfirmEmailRequestModel {
  token: string;
}

/* Sign Up by invite */
export interface SignupByInviteRequestModel {
  firstName: string,
  lastName: string,
  title: string | undefined,
  phoneNumber: string,
  password: string,
  confirmPassword: string,
  token: string
}

export const SignupByInviteSchema: Yup.ObjectSchema<SignupByInviteRequestModel> = Yup.object().shape({
  firstName: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(30, "The value must be no more than 30 characters").required(validationMessages.required),
  lastName: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(30, "The value must be no more than 30 characters").required(validationMessages.required),
  title: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(30, "The value must be no more than 30 characters").required(validationMessages.required),
  phoneNumber: Yup.string().matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, validationMessages.notValid).required(validationMessages.required),
  password: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).min(5, "The value must be more than 4 characters").max(30, validationMessages.max30chars).required(validationMessages.required),
  confirmPassword: Yup.string()
    .required(validationMessages.required)
    .test({
      name: "equalTo",
      exclusive: false,
      message: "Passwords do not match",
      params: {
        reference: Yup.ref("password"),
      },
      test(value: any) {
        return value === this.resolve(Yup.ref("password"));
      }
    }),
  token: Yup.string().required()
}).defined();
/* /Sign Up by invite */

/* Portal Registration */
export interface PortalRegistrationModel {
  firstName: string,
  lastName: string,
  title: string,
  email: string,
  confirmEmail: string,
  accountNumber?: string | number,
  companyName: string,
  companyAddress: {
    address1: string,
    address2?: string,
    city: string,
    state: string,
    postalCode: string
  },
  officePhone: string,
  mobilePhone: string
}

export const PortalRegistrationSchema: Yup.ObjectSchema<PortalRegistrationModel> = Yup.object().shape({
  firstName: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(30, "The value must be no more than 30 characters").required(validationMessages.required),
  lastName: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(30, "The value must be no more than 30 characters").required(validationMessages.required),
  title: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(30, "The value must be no more than 30 characters").required(validationMessages.required),
  email: Yup.string().max(60, "The value must be no more than 60 characters").email(validationMessages.notValid).required(validationMessages.required),
  confirmEmail: Yup.string()
    .required(validationMessages.required)
    .test({
      name: "equalTo",
      exclusive: false,
      message: "Email addresses do not match",
      params: {
        reference: Yup.ref("email"),
      },
      test(value: any) {
        return value === this.resolve(Yup.ref("email"));
      }
    }),
  accountNumber: Yup.string()
    .matches(/^\d+$/, validationMessages.notValid)
    .min(3, "The value must be no less than 3 digits")
    .max(8, "The value must be no more than 8 digits")
    .typeError(validationMessages.notValid),
  companyName: Yup.string().max(100, "The value must be no more than 100 characters").required(validationMessages.required),
  companyAddress: Yup.object().shape({
    address1: Yup.string().max(100, "The value must be no more than 100 characters").required(validationMessages.required),
    address2: Yup.string().max(100, "The value must be no more than 100 characters"),
    city: Yup.string().max(64, "The value must be no more than 64 characters").required(validationMessages.required),
    state: Yup.string().max(100, "The value must be no more than 100 characters").required(validationMessages.required),
    postalCode: Yup.string()
      .matches(/^\d*$/, validationMessages.notValid)
      .min(5, "The value must be no less than 5 digits")
      .max(5, "The value must be no more than 5 digits")
      .required(validationMessages.required)
  }).defined(),
  officePhone: Yup.string().matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, validationMessages.notValid).required(validationMessages.required),
  mobilePhone: Yup.string().matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, validationMessages.notValid).required(validationMessages.required)
}).defined();
/* /Portal Registration */

/* Request Approve */
export interface PermissionModel {
  accountId?: string,
  name?: string,
  accountNumber?: number,
  permissions: string[]  
}

export interface RegistrationApproveModel {
  request: string,
  team?: string,
  teamName?: string,
  accountNumber?: string,
  administrator: boolean
  permissions?: PermissionModel[]
}

export const RegistrationApproveSchema: Yup.ObjectSchema<RegistrationApproveModel> = Yup.object().shape({
  request: Yup.string().required(validationMessages.required),
  team: Yup.string()
    .when("teamName", {
      is: val => !val || val.length === 0,
      then: Yup.string().required(validationMessages.required)
    }),
  teamName: Yup.string()
    .when("team", {
      is: val => !val || val.length === 0,
      then: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(30, "The value must be no more than 30 characters").required(validationMessages.required)
    }),  
  administrator: Yup.boolean().required(validationMessages.required),
  permissions: Yup.array().of(
    Yup.object().shape({
      permissions: Yup.array().of(Yup.string().defined()).defined()
    }).defined(),
  )
}, [["team", "teamName"]]).defined();

export interface ShipperApproveModel {
  request: string,
  accountNumber: number,
}

export const ShipperApproveSchema: Yup.ObjectSchema<ShipperApproveModel> = Yup.object().shape({
  request: Yup.string().required(validationMessages.required),
  accountNumber: Yup.number().required(validationMessages.required)
}).defined();
/* /Request Approve\ */

/* Request Reject */
export interface RequestRejectModel {
  request: string,
  reason: string,
  comment?: string
}

export const RequestRejectSchema: Yup.ObjectSchema<RequestRejectModel> = Yup.object({
  request: Yup.string().required(validationMessages.required),
  reason: Yup.string().required(validationMessages.required),
  comment: Yup.string().max(250, "The value must be no more than 250 characters")
    .when("reason", {
      is: "OTHER",
      then: Yup.string().required(validationMessages.required).max(250, "The value must be no more than 250 characters")
    })
}).defined();
/* /Request Reject */

export interface ForgotPasswordRequest {
  login: string;
}

export const ForgotPasswordRequestSchema: Yup.ObjectSchema<ForgotPasswordRequest> = Yup.object({
  login: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).required(validationMessages.required)
}).defined();

export interface LoginFormModel {
  username: string;
  password: string;
}

export const LoginFormSchema: Yup.ObjectSchema<LoginFormModel> = Yup.object({
  username: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).required(validationMessages.required),
  password: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(30, validationMessages.max30chars).required(validationMessages.required)
}).defined();

export interface LoginRequest {
  email: string;
  password: string;
}

export interface ResetPasswordRequestModel {
  token: string;
  password: string;
  confirmPassword: string | unknown;
}

export const LegacyResetPasswordSchema: Yup.ObjectSchema<ResetPasswordRequestModel> = Yup.object(
  {
    token: Yup.string().required(),
    password: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(10, "The value must be no more than 10 characters").required(validationMessages.required),
    confirmPassword: Yup.string()
      .required(validationMessages.required)
      .test({
        name: "equalTo",
        exclusive: false,
        message: "Passwords do not match",
        params: {
          reference: Yup.ref("password"),
        },
        test(value: any) {
          return value === this.resolve(Yup.ref("password"));
        },
      }),
  }
).defined();

export const ResetPasswordSchema: Yup.ObjectSchema<ResetPasswordRequestModel> = Yup.object(
  {
    token: Yup.string().required(),
    password: Yup.string()
      .matches(/(\w+|-+)/, validationMessages.notValid)
      .min(5, "The value must be more than 4 characters")
      .max(30, validationMessages.max30chars)
      .required(validationMessages.required),
    confirmPassword: Yup.string()
      .required(validationMessages.required)
      .test({
        name: "equalTo",
        exclusive: false,
        message: "Passwords do not match",
        params: {
          reference: Yup.ref("password"),
        },
        test(value: any) {
          return value === this.resolve(Yup.ref("password"));
        }
      })
  }
).defined();

/* profile */
export interface ProfileSettingsRequestModel {
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  phoneNumber: string;
}

export const ProfileSettingsRequestSchema: Yup.ObjectSchema<ProfileSettingsRequestModel> = Yup.object({
  firstName: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(30, "The value must be no more than 30 characters").required(validationMessages.required),
  lastName: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(30, "The value must be no more than 30 characters").required(validationMessages.required),
  title: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(30, "The value must be no more than 30 characters").required(validationMessages.required),
  email: Yup.string().max(60, "The value must be no more than 60 characters").email(validationMessages.notValid).required(validationMessages.required),
  phoneNumber: Yup.string().matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, validationMessages.notValid).required(validationMessages.required)
}).defined();

export interface ProfilePasswordRequestModel {
  currentPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
}

export interface UserPasswordChangeRequestModel {
  userId: string;
  newPassword: string;
}

export const ProfilePasswordRequestSchema: Yup.ObjectSchema<ProfilePasswordRequestModel> = Yup.object({
  currentPassword: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).required(validationMessages.required),
  newPassword: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).min(5, "The value must be more than 4 characters").max(30, validationMessages.max30chars).required(validationMessages.required),
  newPasswordRepeat: Yup.string()
    .required(validationMessages.required)
    .test({
      name: "equalTo",
      exclusive: false,
      message: "Passwords do not match",
      params: {
        reference: Yup.ref("newPassword"),
      },
      test(value: any) {
        return value === this.resolve(Yup.ref("newPassword"));
      }
    })
}).defined();
/* /profile */

/* legacy user confirm data flow */
export interface ConfirmDataRequestModel {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  phone: string;
  title: string;  
  email: string;
}

export interface ConfirmDataFormModel {
  firstName: string;
  lastName: string;
  phone: string;
  title: string;
  email: string;
}

export const ConfirmDataFormSchema: Yup.ObjectSchema<ConfirmDataFormModel> = Yup.object({
  firstName: Yup.string().nullable().matches(/(\w+|-+)/, validationMessages.notValid).max(30, "The value must be no more than 30 characters").required(validationMessages.required),
  lastName: Yup.string().nullable().matches(/(\w+|-+)/, validationMessages.notValid).max(30, "The value must be no more than 30 characters").required(validationMessages.required),
  phone: Yup.string().nullable().matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, validationMessages.notValid).required(validationMessages.required),
  title: Yup.string().nullable().matches(/(\w+|-+)/, validationMessages.notValid).max(30, "The value must be no more than 30 characters").required(validationMessages.required),
  email: Yup.string().nullable().max(60, "The value must be no more than 60 characters").email(validationMessages.notValid).required(validationMessages.required)
}).defined();
/* /legacy user confirm data flow */

export enum UserInvitationType {
  AGENT = "AGENT",
  CUSTOMER = "CUSTOMER",
  EMPLOYEE = "EMPLOYEE"
}