import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ContentContainer from "../../templates/content-container/contentContainer";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { PathParams, CompanySettingsPath, CompanySettingsTabRoutes } from "./route";
import { Routes } from "../../app/route/RoutesConfig";
import { UserUtils } from "../../app/data/user/userUtils";
import CompanySettingsUsers from "./users/companySettingsUsers";
import CompanySettingsAccounts from "./accounts/companySettingsAccounts";
import CompanySettingsShippers from "./shippers/companySettingsShippers";
import UserState from "../../slices/user/UserState";
import { userSelector } from "../../slices/user/userSlice";
import "./companySettings.scss";
import "../../ui-components/tabs/tabs.scss";

const CompanySettings: React.FC<{}> = (props) => {
  const history = useHistory();
  const params = useParams() as PathParams;
  const userState: UserState = useSelector(userSelector);

  const tabRoutes: string[] = [
    CompanySettingsTabRoutes.users,
    CompanySettingsTabRoutes.accounts,
    CompanySettingsTabRoutes.shippers,
    // CompanySettingsTabRoutes.holidays
  ];

  const getInitialTab = () => {
    return params[CompanySettingsPath.activeTab] ? tabRoutes.findIndex(item => item === `/${params[CompanySettingsPath.activeTab]}`) : 0
  };

  const changeRoute = (index: number) => {
    history.replace(`${Routes.company.general}${tabRoutes[index]}`);
  };

  useEffect(() => {
    if (!UserUtils.isAdministrator(userState.profile)) {
      history.push(Routes.home);
    }
  }, [history, userState.profile]);

  return (
    <ContentContainer title="Company Settings">
      <div className="xgs-company">
        <Tabs defaultIndex={getInitialTab()} onSelect={index => changeRoute(index)}>
          <TabList>
            <Tab>Users</Tab>
            <Tab>Accounts</Tab>
            <Tab>Shippers</Tab>
            <Tab disabled>Integrations</Tab>
          </TabList>
          <TabPanel>
            <CompanySettingsUsers />
          </TabPanel>
          <TabPanel>
            <CompanySettingsAccounts />
          </TabPanel>
          <TabPanel>
            <CompanySettingsShippers />
          </TabPanel>
          <TabPanel>
          </TabPanel>
        </Tabs>
      </div>
    </ContentContainer>
  );
};

export default CompanySettings;
