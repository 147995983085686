import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import LabeledSelectInput from "../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import ConfirmationModal from "../../../ui-components/confirmation-modal/confirmationModal";
import Loading from "../../../ui-components/loading/loading";
import ClaimItemForm from "../claimItemForm";
import ClaimsState from "../../../slices/claims/ClaimsState";
import {
  addItem,
  claimsSelector,
  deleteItem,
  setClaimAllItems,
  setClaimAllItemsValues,
  setFreightCharges,
  setFreightChargesOnly,
  updateItem
} from "../../../slices/claims/claimsSlice";
import {
  ClaimFreightChargesModel,
  ClaimAllItemsModel,
  ClaimAllItemsSchema
} from "../../../app/data/claims/models";
import ShipmentDetailsState from "../../../slices/shipment-details/ShipmentDetailsState";
import {
  getShipmentDetails,
  shipmentDetailsSelector
} from "../../../slices/shipment-details/shipmentDetailsSlice";
import { StepProps } from "../../../app/data/common/models";
import "../claims.scss";
import XGSCheckbox from "../../../ui-components/xgs-checkbox/xgsCheckbox";

export const FREIGHT_CHARGES_ONLY = [
  {
    label: "Filing on items (freight charges can be included)",
    value: "FILE_ON_ITEMS"
  },
  {
    label: "Filing only on freight charges",
    value: "FILE_ON_FREIGHT_CHARGES"
  }
];

export const CLAIM_ALL_ITEMS = [
  {
    label: "I want to claim only specific items",
    value: "SPECIFIC_ITEMS"
  },
  {
    label: "I want to claim all items",
    value: "ALL_ITEMS"
  }
];

let initialClaimAllItemsValues: ClaimAllItemsModel = {
  totalClaimAmount: undefined,
  areFreightChargesIncluded: false
};

const ClaimItems: React.FC<StepProps> = (props) => {
  const { previous, next } = props;
  const claimsState: ClaimsState = useSelector(claimsSelector);
  const shipmentDetailsState: ShipmentDetailsState = useSelector(shipmentDetailsSelector);
  const dispatch = useDispatch();
  const claimsAllItemsFormRef = useRef<any>(null);
  const [areFreightChargesIncluded, setAreFreightChargesIncluded] = useState<boolean>(false);
  const [freightChargesCheckboxValues, setFreightChargesCheckboxValues] = useState<ClaimFreightChargesModel[]>([]);
  const [editItemIndex, setEditItemIndex] = useState<number | undefined>(undefined);
  const [showAddItemForm, setShowAddItemForm] = useState<boolean>(false);
  const [removeItemIndex, setRemoveItemIndex] = useState<number | undefined>(undefined);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState<boolean>(false);
  const [totalClaimAmount, setTotalClaimAmount] = useState<string | undefined>(undefined);

  const getProbillFreightCharges = () => {
    if (!shipmentDetailsState.shipment?.items || shipmentDetailsState.shipment.items.length === 0) return [];
    let freightChargesArr = [];
    for (const item of shipmentDetailsState.shipment.items) {
      if (item.freightCharges && item.freightCharges > 0) {
        freightChargesArr.push({
          description: item.description || "",
          price: item.freightCharges || 0
        });
      }
    }
    return freightChargesArr;
  };

  const onClickFreightChargesCheckbox = (item: ClaimFreightChargesModel) => {
    let newFreightCharges = [...freightChargesCheckboxValues];
    const existingItemIndex = newFreightCharges.findIndex(obj => obj.description === item.description && obj.price === item.price);
    if (existingItemIndex !== -1) {
      newFreightCharges.splice(existingItemIndex, 1);
      setFreightChargesCheckboxValues([...newFreightCharges]);
    } else {
      setFreightChargesCheckboxValues([...newFreightCharges, item]);
    }
  };

  const saveData = () => {
    let freightChargesArr = (claimsState.freightChargesOnly?.value === "FILE_ON_FREIGHT_CHARGES" || claimsState.claimAllItems?.value === "ALL_ITEMS") ? [...freightChargesCheckboxValues] : [];
    dispatch(setFreightCharges(freightChargesArr));
    if (claimsState.claimAllItems?.value === "ALL_ITEMS" && claimsAllItemsFormRef.current) {
      dispatch(setClaimAllItemsValues(claimsAllItemsFormRef.current.values));
    }
  };

  const onClickBack = () => {
    saveData();
    previous && previous();
  };

  const onClickNext = () => {
    saveData();
    next && next();
  };

  const isNextDisabled = useCallback(() => {
    if (claimsState.claimAllItems?.value === "SPECIFIC_ITEMS" && claimsState.items.filter(item => !item.deleted).length > 0) {
      return false;
    } else if (claimsState.freightChargesOnly?.value === "FILE_ON_FREIGHT_CHARGES" && freightChargesCheckboxValues.length > 0) {
      return false;
    }
    return true;
  }, [claimsState.claimAllItems, claimsState.freightChargesOnly, freightChargesCheckboxValues, claimsState.items]);

  useEffect(() => {
    if ((shipmentDetailsState.shipment?.items && shipmentDetailsState.shipment.items.length > 0) || shipmentDetailsState.loaded || !claimsState.general?.probillNumber) return;
    dispatch(getShipmentDetails(Number(claimsState.general.probillNumber)));
  }, [shipmentDetailsState.shipment, shipmentDetailsState.loaded, dispatch, claimsState.general]);

  useEffect(() => {
    if (!claimsState.freightCharges) return;
    setFreightChargesCheckboxValues([...claimsState.freightCharges]);
  }, [claimsState.freightCharges]);

  useEffect(() => {
    if (claimsState.claimAllItems?.value !== "ALL_ITEMS") return;
    initialClaimAllItemsValues = {
      totalClaimAmount: claimsState.totalClaimAmount,
      areFreightChargesIncluded: claimsState.areFreightChargesIncluded
    };
    setTotalClaimAmount(`${claimsState.totalClaimAmount ? claimsState.totalClaimAmount : ""}`);
    setAreFreightChargesIncluded(claimsState.areFreightChargesIncluded);
    setTimeout(() => {
      claimsAllItemsFormRef.current?.validateForm();
    }, 50);
  }, [claimsState.claimAllItems, claimsState.totalClaimAmount, claimsState.areFreightChargesIncluded]);

  return (
    <div className="xgs-claims__form">
      <div className="xgs-wizard__step__header">Item Information {shipmentDetailsState.loading && <Loading className="xgs-claims__section-header__loading" isLoading={true} />}</div>
      <div className="xgs-claims__section">
        {!(shipmentDetailsState.loaded && !shipmentDetailsState.shipment?.items) && (
          <div className="xgs-claims__row">
            <div className="xgs-claims__row__column-50">
              <div className="xgs-form__field">
                <LabeledSelectInput
                  options={FREIGHT_CHARGES_ONLY}
                  value={claimsState.freightChargesOnly}
                  label="What are you claiming?"
                  labelMode={LabelModes.column}
                  className="xgs-tracking-filter__input"
                  onValueChange={(v: any) => {
                    if (claimsState.freightChargesOnly?.value === v?.value) return;
                    dispatch(setFreightChargesOnly(v));
                    dispatch(setClaimAllItems(undefined));
                    setFreightChargesCheckboxValues([]);
                  }}
                  disabled={shipmentDetailsState.loading}
                />
              </div>
            </div>
          </div>
        )}
        {shipmentDetailsState.loaded && !shipmentDetailsState.shipment?.items && (
          <div className="xgs-claims__probill-no-items">
            <div className="xgs-claims__probill-no-items__error">The selected probill doesn't contain any items!</div>
            Go back and enter another one or contact support if you think an error has occurred.
          </div>
        )}
        {claimsState.freightChargesOnly?.value === "FILE_ON_ITEMS" && (
          <>
            <div className="xgs-claims__row">
              <div className="xgs-claims__row__column-50">
                <div className="xgs-form__field">
                  <LabeledSelectInput
                    options={CLAIM_ALL_ITEMS}
                    value={claimsState.claimAllItems}
                    label="Do you want to claim all your items?"
                    labelMode={LabelModes.column}
                    className="xgs-tracking-filter__input"
                    onValueChange={(v: any) => {
                      if (claimsState.claimAllItems?.value === v?.value) return;
                      dispatch(setClaimAllItems(v));
                      setTotalClaimAmount(undefined);
                      setAreFreightChargesIncluded(false);
                      setFreightChargesCheckboxValues([]);
                      dispatch(setClaimAllItemsValues({
                        totalClaimAmount: undefined,
                        areFreightChargesIncluded: false
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
            {claimsState.claimAllItems?.value === "ALL_ITEMS" && (
              <Formik
                onSubmit={onClickNext}
                initialValues={initialClaimAllItemsValues}
                validationSchema={ClaimAllItemsSchema}
                innerRef={claimsAllItemsFormRef}
                enableReinitialize
              >
                {(props: FormikProps<ClaimAllItemsModel>) => (
                  <Form>
                    <div className="xgs-claims__row">
                      <div className="xgs-claims__row__column-50">
                        <div className="xgs-form__field">
                          <XGSFormInput
                            type="text"
                            name="totalClaimAmount"
                            label="Requested claim amount:"
                            required={true}
                            requiredAsteriskDisabled={false}
                            labelMode={LabelModes.column}
                            value={totalClaimAmount}
                            onChange={(e) => {
                              setTotalClaimAmount(e.currentTarget.value);
                              props.handleChange(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {getProbillFreightCharges().length !== 0 && (
                      <>
                        <XGSCheckbox
                          name="areFreightChargesIncluded"
                          onChange={(e) => {
                            setAreFreightChargesIncluded(!areFreightChargesIncluded);
                            setFreightChargesCheckboxValues([]);
                            props.handleChange(e);
                          }}
                          checked={areFreightChargesIncluded}
                        >

                          Are you filing on freight charges?
                        </XGSCheckbox>
                        {areFreightChargesIncluded && (
                          <div className="xgs-claims__freight-charges">
                            {getProbillFreightCharges().map((freightChargesItem: ClaimFreightChargesModel, index: number) => (
                              <XGSCheckbox
                                key={"fc-" + index}
                                name={"freight-charges-checkbox-" + index}
                                onChange={() => onClickFreightChargesCheckbox(freightChargesItem)}
                                checked={freightChargesCheckboxValues.findIndex(obj => obj.description === freightChargesItem.description && obj.price === freightChargesItem.price) !== -1}
                              >
                                <span className="xgs-claims__freight-charges__description">{freightChargesItem.description}</span> (${freightChargesItem.price})

                              </XGSCheckbox>
                            ))}
                          </div>
                        )}
                      </>
                    )}
                    <div className="xgs-wizard__step__buttons">
                      <Button
                        type="button"
                        theme={ButtonThemes.gray}
                        onClick={onClickBack}
                      >
                        Back
                      </Button>
                      <Button
                        type="button"
                        theme={ButtonThemes.blue}
                        disabled={!props.isValid || !props.values.totalClaimAmount || (areFreightChargesIncluded && freightChargesCheckboxValues.length === 0)}
                        onClick={onClickNext}
                      >
                        Next
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
            {claimsState.claimAllItems?.value === "SPECIFIC_ITEMS" && (
              <div className="xgs-claims__items">
                {claimsState.items.map((item, index) => (
                  <div key={index}>
                    {(index === editItemIndex) && (
                      <div style={{ marginBottom: 16 }}>
                        <ClaimItemForm
                          itemIndex={index}
                          probillItems={shipmentDetailsState.shipment?.items || []}
                          onSubmit={(data) => {
                            dispatch(updateItem({ index, data }));
                            setEditItemIndex(undefined);
                          }}
                          onCancel={() => {
                            setEditItemIndex(undefined);
                          }}
                        />
                      </div>
                    )}
                    {(editItemIndex === undefined || (index !== editItemIndex)) && !item.deleted && (
                      <div className="xgs-claims__items__item">
                        <div className="xgs-claims__items__item__columns">
                          <div className="xgs-claims__items__item__properties">
                            <div className="xgs-claims__items__item__property">Description: {item.description}</div>
                            <div className="xgs-claims__items__item__property">Pieces: {item.claimedPiecesQty}</div>
                            <div className="xgs-claims__items__item__property">Total Weight: {item.claimedPiecesWeight}</div>
                            <div className="xgs-claims__items__item__property">Claim Amount: ${item.claimedPiecesPrice ? Number(item.claimedPiecesPrice).toFixed(2) : ""}</div>
                            {item.freightCharges && item.freightCharges.length > 0 && <div className="xgs-claims__items__item__property">Freight charges are filing on</div>}
                          </div>
                          <div className="xgs-claims__items__item__actions">
                            <div className="xgs-claims__items__item__action">
                              <span
                                className="blue-link"
                                onClick={() => {
                                  setShowAddItemForm(false);
                                  setEditItemIndex(index);
                                }}
                              >
                                Edit
                              </span>
                            </div>
                            <div className="xgs-claims__items__item__action">
                              <span
                                className="blue-link"
                                onClick={() => {
                                  setRemoveItemIndex(index);
                                  setShowRemoveConfirmation(true);
                                }}
                              >
                                Remove
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                {claimsState.items.filter(item => !item.deleted).length === 0 && (
                  <div className="xgs-claims__items__no-items">
                    You haven't added any items yet. Click the button below to do this.
                  </div>
                )}
                {showAddItemForm &&
                  <ClaimItemForm
                    probillItems={shipmentDetailsState.shipment?.items || []}
                    onSubmit={(data) => {
                      dispatch(addItem(data));
                      setShowAddItemForm(false);
                    }}
                  />
                }
                {!showAddItemForm &&
                  (editItemIndex === undefined) &&
                  (claimsState.items.filter(item => !item.deleted).length < 10) &&
                  (claimsState.items.filter(item => !item.deleted).length < (shipmentDetailsState.shipment?.items || []).length) && (
                  <Button
                    theme={ButtonThemes.blue}
                    type="button"
                    onClick={() => setShowAddItemForm(true)}
                    disabled={!shipmentDetailsState.shipment?.items}
                  >
                    {claimsState.items.filter(item => !item.deleted).length > 0 ? "Add New Item" : "Add Item"}
                  </Button>
                )}
              </div>
            )}
          </>
        )}
        {claimsState.freightChargesOnly?.value === "FILE_ON_FREIGHT_CHARGES" && (
          <div className="xgs-claims__freight-charges">
            {getProbillFreightCharges().map((freightChargesItem: ClaimFreightChargesModel, index: number) => (
              <XGSCheckbox
                key={"fc-" + index}
                name={"freight-charges-checkbox-" + index}
                onChange={() => onClickFreightChargesCheckbox(freightChargesItem)}
                checked={freightChargesCheckboxValues.findIndex(obj => obj.description === freightChargesItem.description && obj.price === freightChargesItem.price) !== -1}
              >
                <span className="xgs-claims__freight-charges__description">{freightChargesItem.description}</span> (${freightChargesItem.price})
              </XGSCheckbox>
            ))}
            {getProbillFreightCharges().length === 0 && (
              <div className="xgs-claims__freight-charges__no">
                There are no freight charges in the current probill!
              </div>
            )}
          </div>
        )}
        {claimsState.claimAllItems?.value !== "ALL_ITEMS" && (
          <div className="xgs-wizard__step__buttons">
            <Button
              type="button"
              theme={ButtonThemes.gray}
              onClick={onClickBack}
            >
              Back
            </Button>
            <Button
              type="button"
              theme={ButtonThemes.blue}
              disabled={isNextDisabled()}
              onClick={onClickNext}
            >
              Next
            </Button>
          </div>
        )}
      </div>
      <ConfirmationModal
        opened={showRemoveConfirmation}
        confirmButtonText="Remove"
        onCancel={() => {
          setShowRemoveConfirmation(false);
        }}
        onConfirm={() => {
          dispatch(deleteItem(removeItemIndex));
          setShowRemoveConfirmation(false);
          setRemoveItemIndex(undefined);
        }}>
        The item will be removed from the list.
      </ConfirmationModal>
    </div>
  );
};

export default ClaimItems;
