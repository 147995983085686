import React from "react";
import Button, { ButtonThemes } from "../button/button";
import { ReactComponent as ArrowLeft } from "../../images/arrow_left.svg";
import { ReactComponent as ArrowRight } from "../../images/arrow_right.svg";

export interface WizardNavigationProps {  
  previous?: () => void;
  next?: () => void;
  nextButtonLabel?: string;
  nextButtonDisabled?: boolean;
  hideNextButtonArrow?: boolean;
}

export const WizardNavigation: React.FC<WizardNavigationProps> = (props) => {
  return (
    <div className="wizard__navigation">
      {props.previous && (
        <Button
          type="button"
          onClick={props.previous}
          theme={ButtonThemes.gray}
        >
          <div className="xgs-btn__icon-container">
            <ArrowLeft />
          </div> 
          <span>Back</span> 
        </Button>
      )}
      {props.next && (
        <Button
          className="wizard__navigation__next-button"
          onClick={props.next}
          theme={ButtonThemes.blue}
          disabled={props.nextButtonDisabled}
        >
          <span>{props.nextButtonLabel || "Next"}</span>        
          {!props.hideNextButtonArrow && (<div className="xgs-btn__icon-container">
            <ArrowRight />
          </div>)}          
        </Button>
      )}
    </div>
  )
};