import { createSlice } from "@reduxjs/toolkit";
import { IState } from "../..";
import { AppThunk } from "../../../app/store";
import RoutesService from "../../../app/data/plan-probills/routes/routesService";
import { DeleteRouteState } from "./routesState";
import { getActiveRoutes } from "./activeRoutesSlice";

const initialState: DeleteRouteState = {
  loading: false,
  loadingFailed: false,
  loaded: false,
  error: ""
};

const routesService = RoutesService.getInstance();

export const deleteRouteSlice = createSlice({
  name: "deleteRoute",
  initialState,
  reducers: {
    deleteRouteResetState: (state) => initialState,
    deleteRouteLoadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
    },
    deleteRouteLoadingSucceed: (state) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
    },
    deleteRouteLoadingFailed: (state, { payload }) => {
      state.loading = false;
      state.loadingFailed = true;
      state.error = payload;
    },

  },
});

export const {
  deleteRouteResetState,
  deleteRouteLoadingStarted,
  deleteRouteLoadingSucceed,
  deleteRouteLoadingFailed,

} = deleteRouteSlice.actions;

export const deleteRouteSelector = (state: IState) => state.deleteRoute;

export const deleteRoute = (
  reqData: any,
  onSuccess?: () => void,
  onError?: (error: string) => void,
  onClose?: () => void
): AppThunk => async (dispatch) => {
  dispatch(deleteRouteLoadingStarted());
  const response = await routesService.deleteRoute({ ...reqData });
  if (response.ok() && response.data && response.data.result) {
    dispatch(deleteRouteLoadingSucceed());
    dispatch(getActiveRoutes({...reqData}));
    onSuccess && onSuccess();
    onClose && onClose();
  } else {
    let error = "Internal Server Error, re-try the action or contact support";
    if (response && response.data && response.data.error) error = response.data.error;
    dispatch(deleteRouteLoadingFailed(error));
    onError && onError(error)
  }
  onClose && onClose()
};

const deleteRouteReducer = deleteRouteSlice.reducer;
export default deleteRouteReducer;