import React from "react"
import XGSInput from "../../../../xgs-input/xgsInput";

interface DefaultColumnFilterPros {
    column: any
}

export const DefaultColumnFilter: React.FC<DefaultColumnFilterPros> = ({
    column
}) => {
    return (
        <XGSInput
            value={column.filterValue || ''}
            onChange={(e) => {
                column.setFilter((e.target as HTMLInputElement).value || undefined);
            }}
            placeholder="Search..."
        />
    );
};
