import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ManifestsState from "../../../slices/manifest/ManifestsState";
import { manifestsSelector } from "../../../slices/manifest/manifestSlice";
import {
  ConsigneeProfile,
  StopProfile,
  ProbillRaw,
  ProbillProfile,
  ManifestTableRow,
  ManifestStopProfileRaw,
  ProbillObjectRaw
} from "../../../app/data/manifest/manifestProfiles";
import { STOP_STATUSES } from "../../../app/data/manifest/models";

import SlideOutSidebar from "../../../ui-components/slide-out-sidebar/slideOutSidebar";
import DriverInfo from "./DriverInfo";
import ManifestMap from "./manifestMap";
import RouteStop from "./routeStop";
import PickupStop from "./pickupStop";

import "./manifestDetails.scss";

export interface ManifestDetailsProps {
  manifest: ManifestTableRow ;
  show: boolean;
  onClose: () => void;
}

const ManifestDetails: React.FC<ManifestDetailsProps> = (props) => {
  const dispatch = useDispatch();
  const manifestsState: ManifestsState = useSelector(manifestsSelector);
  const [stops, setStops] = useState<StopProfile[]>([]);
  const [consignees, setConsignees] = useState<any>([]);
  const [stopStatuses, setStopStatuses] = useState<any>({});

  const manifestNumber = props.manifest?.manifestNumber;

  const pickups = manifestNumber ? manifestsState.manifestsPickups[manifestNumber] : [];  

  const currentPendingStopAddress = (stops: StopProfile[]) => {
    const pendingStopIdx = stops.findIndex(stop => stop.stopStatus === STOP_STATUSES.INTERNAL.INPROGRESS)
    if (pendingStopIdx > -1) return stops[pendingStopIdx].address
    else return ""
  };

  /**
   * Fetching map data, setting state for stops, formatting them for further processing
   */
  useEffect(() => {
    const composeProbillProfile = ((probillRaw: ProbillRaw) : ProbillProfile => {
      return {
        manifestNumber: probillRaw.manifestNumber,
        probillNumber: probillRaw.probillNumber,
        probillStatus: probillRaw.probillStatus,
        probillItemsCount: probillRaw.probillItemsCount,
        probillExceptionCount: probillRaw.probillExceptionCount,
        consName: probillRaw.consName,
        stopNumber: probillRaw.stopNumber,
        item: probillRaw.probillItemsCount,

        probillPodSignedByName: probillRaw.probillPodSignedByName,
        podProcessingStatus: probillRaw.podProcessingStatus,
        podSignedByName: probillRaw.podSignedByName,
        deliveredDate: probillRaw.deliveredDate,
        deliveredTime: probillRaw.deliveredTime,
        deliveryEndDate: probillRaw.deliveryEndDate,
        deliveryStartDate: probillRaw.deliveryStartDate,
        dispatchedDate: probillRaw.dispatchedDate,
        dispatchedTime: probillRaw.dispatchedTime,
        driverAckDate: probillRaw.driverAckDate,
        driverAckTime: probillRaw.driverAckTime,
        driverAcknowledgment: probillRaw.driverAcknowledgment,
        driverEmployeeNumber: probillRaw.driverEmployeeNumber,
        driverName: probillRaw.driverName,
        podDeliveredDate: probillRaw.podDeliveredDate,
        podDeliveredTime: probillRaw.podDeliveredTime,
        podProcessedDate: probillRaw.podProcessedDate,
        podProcessedTime: probillRaw.podProcessedTime,
        reconcileDate: probillRaw.reconcileDate,
        trailerId: probillRaw.trailerId,
        trailerNumber: probillRaw.trailerNumber,
        trailerSize: probillRaw.trailerSize,
      } as ProbillProfile;
    })

    const formatStops = (stops: ManifestStopProfileRaw[]): StopProfile[] => {
      if (!Object.values(stops).length) return []

      let isFirstPendingStop = true
      const getStopStatus = (stopStatusRaw: string) => {
        if (stopStatusRaw === STOP_STATUSES.EXTERNAL.COMPLETE) return STOP_STATUSES.INTERNAL.COMPLETED

        if (stopStatusRaw === STOP_STATUSES.EXTERNAL.PENDING) {
          if (isFirstPendingStop) {
            isFirstPendingStop = false
            return STOP_STATUSES.INTERNAL.INPROGRESS
          }
          else return STOP_STATUSES.INTERNAL.UPCOMING
        }

        if (stopStatusRaw === STOP_STATUSES.EXTERNAL.INCOMPLETE) {
          return STOP_STATUSES.INTERNAL.INCOMPLETE
        }

        return STOP_STATUSES.INTERNAL.UNKNOWN
      }

      return Object.entries(stops).map(([id, stop]) => {
        return {
          id,
          address: stop.address,
          items: stop.items,
          probills: stop.probills,
          exceptions: stop.exceptions,
          driverEmployeeNumber: stop.driverEmployeeNumber,
          statuses: stop.statuses,
          stopStatus: getStopStatus(stop.stopStatus)
        }
      })
    };

    const formatConsignees = (probills: ProbillObjectRaw): any => {
      let consignees: { [key: number]: Array<ConsigneeProfile> } = {}

      Object.values(probills).forEach((probill) => {
        if (consignees[probill.stopNumber]) {
          // consignee already exists
          // check if probill already exists
          const idx = consignees[probill.stopNumber].findIndex(cons => probill.conNumber === cons.consigneeNumber)

          if (idx > -1) { // found index of probill in consignee
            consignees[probill.stopNumber][idx].consigneeItemsCount += probill.probillItemsCount
            consignees[probill.stopNumber][idx].consigneeExceptionCount += probill.probillExceptionCount ?? 0
            consignees[probill.stopNumber][idx].consigneeStatuses.push(probill.probillStatus)
            consignees[probill.stopNumber][idx].probills.push(composeProbillProfile(probill))
          } else { // no probill, create new one
            consignees[probill.stopNumber].push({
              stopNumber: probill.stopNumber,
              consigneeName: probill.consName,
              consigneeStatuses: [probill.probillStatus],
              consigneeNumber: probill.conNumber,
              consigneeAddress: probill.consAddress,
              consigneeItemsCount: probill.probillItemsCount,
              consigneeExceptionCount: probill.probillExceptionCount,
              consigneeStopDuration: probill.podProcessedTime,
              probills: [composeProbillProfile(probill)]
            } as ConsigneeProfile)
          }
        } else { // if no consignee found, we create new one
          consignees[probill.stopNumber] = [{
            stopNumber: probill.stopNumber,
            consigneeName: probill.consName,
            consigneeStatuses: [probill.probillStatus],
            consigneeNumber: probill.conNumber,
            consigneeAddress: probill.consAddress,
            consigneeItemsCount: probill.probillItemsCount,
            consigneeExceptionCount: probill.probillExceptionCount,
            consigneeStopDuration: probill.podProcessedTime,
            probills: [composeProbillProfile(probill)]
          } as ConsigneeProfile]
        }
      })

      return consignees
    };

    const prepareConsigneeData = (stops: ManifestStopProfileRaw[], probills: ProbillObjectRaw) => {
      const formattedStopList: StopProfile[] = formatStops(stops);
      const formattedConsigneeList: ConsigneeProfile[] = formatConsignees(probills);
      const stopStatuses = formattedStopList.reduce((result, stop) => ({...result, [stop.id]: stop.stopStatus.toUpperCase()}), {});
      setStops(formattedStopList);
      setStopStatuses(stopStatuses);
      setConsignees(formattedConsigneeList);
    };

    if (props.manifest && props.manifest.manifestNumber) {
      prepareConsigneeData(
          manifestsState.manifestsStops[props.manifest.manifestNumber],
          manifestsState.manifestsProbills[parseInt(props.manifest.manifestNumber)]
      )
    } // eslint-disable-next-line
  }, [
    dispatch,
    props.manifest,
    manifestsState.manifestsProbills,
    manifestsState.manifestsStops,
  ]);

  return (
    <SlideOutSidebar
      header={`Manifest Information ID #${props.manifest ? props.manifest.manifestNumber : ""}`}
      show={props.show}
      size="medium"
      onClose={props.onClose}
    >
      <div className="xgs-manifest__details-view"> 
        <ManifestMap
          manifestNumber={props.manifest?.manifestNumber}
          stopStatuses={stopStatuses}
        />   

        {stops && stops.length && (
          <DriverInfo
            driverName={props.manifest.driverName}
            driverId={props.manifest.driverLocationId}
            trailerNumber={props.manifest.trailerNumber}
            dispatchedDate={props.manifest.dispatchedTs}
            currentStop={currentPendingStopAddress(stops)}
          />
        )}

        {!!stops?.length && stops.map((stop) => (          
          <RouteStop stop={stop} consignees={consignees}/>
        ))}

        {!!pickups?.length && pickups.map(pickup => (
          <PickupStop pickup={pickup}/>
        ))}
      </div>
    </SlideOutSidebar>
  )
}

export default ManifestDetails;