import React, { useEffect } from "react";
import SlideOutSidebar from "../../ui-components/slide-out-sidebar/slideOutSidebar";
import { Step, Steps, Wizard } from "react-albus";
import { AppointmentContactStep } from "./steps/contactStep";
import { useDispatch, useSelector } from "react-redux";
import { appointmentSelector, getAppointment, getConsigneeContacts, resetAppointmentState } from "../../slices/appointments/appointmentsSlice";
import "./createAppointment.scss";
import { AppointmentCreateStep } from "./steps/createStep";
import { AppointmentConfirmStep } from "./steps/confirmStep";
import Loading from "../../ui-components/loading/loading";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import { AppointmentViewStep } from "./steps/viewStep";
import { trackShipmentSelector } from "../../slices/track-shipment/trackShipmentSlice";
import { getContacts, reset_team_account_contact_state } from "../../slices/customers/teams/teamAccountContactSlice";

export interface CreateAppointmentProps {
  probillNumber: number;
  consigneeNumber: number;
  eta: string;
  onClose: () => void;
}

export const CreateAppointment: React.FC<CreateAppointmentProps> = (props) => {
  const dispatch = useDispatch();
  const appointmentState = useSelector(appointmentSelector);
  const trackShipmentState = useSelector(trackShipmentSelector);

  useEffect(() => {
    if (appointmentState.consigneeInfo?.id) {
      dispatch(getContacts(appointmentState.consigneeInfo.id));
    }
    return () => {
      dispatch(reset_team_account_contact_state());
    }
  }, [appointmentState.consigneeInfo?.id, dispatch])

  useEffect(() => {
    dispatch(getConsigneeContacts(props.probillNumber, props.consigneeNumber));
    dispatch(getAppointment(props.probillNumber));
    return () => {
      dispatch(resetAppointmentState());
    };
  }, [dispatch, props.eta, props.consigneeNumber, props.probillNumber]);

  useEffect(() => {
    // Close modal when shipments are loading.
    if (trackShipmentState.loading) props.onClose();
  }, [trackShipmentState.loading, props])

  const getSteps = (existingAppointment: boolean) => {
    if (existingAppointment) {
      return ["appointment-view-step", "appointment-contact-step", "create-appointment-step", "confirm-appointment-step"];
    }
    if (!existingAppointment) {
      return ["appointment-contact-step", "create-appointment-step", "confirm-appointment-step"];
    }
    return [];
  };

  const steps = getSteps(!!appointmentState.existingAppointment);
  const stepCount = steps.length;

  return (
    <SlideOutSidebar size="small" header={ !!appointmentState.existingAppointment?.confirmedDateTime ? "Change appointment" : "Create Appointment"} show={!!props.probillNumber} onClose={props.onClose}>
      <div className="xgs-create-appt__details">
        <div className="xgs-create-appt__details__probill">Probill: {props.probillNumber}</div>
        {appointmentState.fetchConsigneeFailed && <XGSErrorMessage>{appointmentState.fetchConsigneeError}</XGSErrorMessage>}
        {appointmentState.consigneeInfo && (
          <>
            <div className="xgs-create-appt__details__consignee">
              <b>{appointmentState.consigneeInfo.name}</b><br />
              <span style={{ textTransform: "capitalize" }}>{appointmentState.consigneeInfo.address.line1.toLowerCase() + ", "}</span>
              <span style={{ textTransform: "capitalize" }}>{appointmentState.consigneeInfo.address.line2 ? appointmentState.consigneeInfo.address.line2.toLowerCase() + ", " : ""}</span><br />
              <span style={{ textTransform: "capitalize" }}>{appointmentState.consigneeInfo.address.city.toLowerCase()}</span>
              {appointmentState.consigneeInfo.address.city && appointmentState.consigneeInfo.address.state ? ", " : ""}
              {appointmentState.consigneeInfo.address.state}&nbsp;{appointmentState.consigneeInfo.address.zip}
            </div>
            <div className="xgs-create-appt__details__number">
              {appointmentState.consigneeInfo.accountNumber}
            </div>
          </>
        )}
      </div>
      <Loading isLoading={appointmentState.fetchAppointmentStarted || appointmentState.fetchConsigneeStarted} />
      {appointmentState.fetchAppointmentSucceed && appointmentState.fetchConsigneeSucceed && (
        <div className="xgs-create-appt__wizard-container">
          <Wizard>
            <Steps>
              {steps.map((stepId, index) => {
                const stepProps = {
                  eta: props.eta,
                  probillNumber: props.probillNumber,
                  stepCount: !!appointmentState.existingAppointment ? stepCount - 1 : stepCount,
                  stepIdx: !!appointmentState.existingAppointment ? index - 1 : index,
                  onClose: props.onClose
                };

                switch (stepId) {
                  case "appointment-view-step":
                    return (
                      <Step key={stepId} id={stepId} render={(wizardProps) => <AppointmentViewStep {...stepProps} {...wizardProps} />} />
                    );
                  case "appointment-contact-step":
                    return (
                      <Step key={stepId} id={stepId} render={(wizardProps) => <AppointmentContactStep {...stepProps} {...wizardProps} />} />
                    );
                  case "create-appointment-step":
                    return (
                      <Step key={stepId} id={stepId} render={(wizardProps) => <AppointmentCreateStep {...stepProps} {...wizardProps} />} />
                    );
                  case "confirm-appointment-step":
                    return (
                      <Step key={stepId} id={stepId} render={(wizardProps) => <AppointmentConfirmStep closeModal={props.onClose} {...stepProps} {...wizardProps} />} />
                    );
                  default:
                    return null;
                }
              })}
            </Steps>
          </Wizard>
        </div>
      )}
    </SlideOutSidebar>
  );
};
