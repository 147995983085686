import { createSlice } from "@reduxjs/toolkit";
import { UnplannableProbillsState } from "./unplannableProbillsState";
import { IState } from "../..";
import { AppThunk } from "../../../app/store";
import { UnplannableProbillsRequest } from "../../../app/data/unplannable-probills/models";
import UnplannableProbillsService from "../../../app/data/unplannable-probills/unplannableProbillsService";

const initialState: UnplannableProbillsState = {
  loading: false,
  loadingFailed: false,
  loaded: false,
  summary: {
    total: 0,
    atRisk: 0,
    catchUp: 0,
    late: 0,
    missed: 0
  },
  unplannableProbills: [],
  selectedSummaryBucket: "",
  selectedProbillsList: []
};

const unplannableProbillsService = UnplannableProbillsService.getInstance();

export const unplannableProbillsSlice = createSlice({
  name: "unplannableProbills",
  initialState,
  reducers: {
    resetUnplannableProbills: (state) => initialState,
    unplannableProbillsLoadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
    },
    unplannableProbillsLoadingSucceed: (state, { payload }) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
      state.summary = payload.summary || initialState.summary;
      state.unplannableProbills = payload.unplannableProbills || [];
      state.selectedSummaryBucket = "total";
      state.selectedProbillsList = payload.unplannableProbills || [];
    },
    unplannableProbillsLoadingFailed: (state) => {
      state.loading = false;
      state.loadingFailed = true;
      state.summary = initialState.summary;
      state.unplannableProbills = initialState.unplannableProbills;
      state.selectedSummaryBucket = initialState.selectedSummaryBucket;
      state.selectedProbillsList = initialState.selectedProbillsList;
    },
    changeTheSummaryBucket: (state, { payload }) => {
      state.selectedSummaryBucket = payload.selectedSummaryBucket;
      state.selectedProbillsList = payload.selectedProbillsList;
    }
  },
});

export const {
  resetUnplannableProbills,
  unplannableProbillsLoadingStarted,
  unplannableProbillsLoadingSucceed,
  unplannableProbillsLoadingFailed,
  changeTheSummaryBucket
} = unplannableProbillsSlice.actions;

export const unplannableProbillsSelector = (state: IState) => state.unplannableProbills;

export const getUnplannableProbills = (
  requestData: UnplannableProbillsRequest,
  onError?: (error: string) => void
): AppThunk => async (dispatch) => {
  if (!requestData || !requestData.terminalNumber) {
    dispatch(unplannableProbillsLoadingFailed());
    return;
  }
  dispatch(unplannableProbillsLoadingStarted());
  const response = await unplannableProbillsService.getUnplannableProbills(requestData);
  if (response.ok() && response.data && response.data.result) {
    dispatch(unplannableProbillsLoadingSucceed(response.data));
  } else {
    let error = "Internal Server Error, re-try the action or contact support";
    if (response && response.data && response.data.error) error = response.data.error;
    dispatch(unplannableProbillsLoadingFailed());
    onError && onError(error)
  }
};

const unplannableProbillsReducer = unplannableProbillsSlice.reducer;
export default unplannableProbillsReducer;