import React, { useCallback, useEffect, useState } from "react";
import ContentContainer from "../../templates/content-container/contentContainer";
import { useDispatch, useSelector } from "react-redux";
import { fetchLinehaulLanes, infiniteLoadLinehaulLanes, linehaulMileageSelector } from "../../slices/linehaul-mileage/linehaulMileageSlice";
import { LabelModes } from "../../ui-components/molecules/labeled-inputs/labeledInput";
import "./linehaulMileage.scss";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import LabeledSelectInput from "../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { getServiceCenters, serviceCentersSelector } from "../../slices/service-centers/serviceCentersSlice";
import ServiceCentersState from "../../slices/service-centers/ServiceCentersState";
import { XGSSelectOption } from "../../ui-components/xgs-select/xgsSelect";
import Table from "../../ui-components/table/table";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import { getLaneManagementColumns } from "./getLaneManagementColumns";
import { AddLane } from "./addLane";
import { LaneDetails } from "./laneDetails";

export const LinehaulLaneManagement: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const linehaulMileageState = useSelector(linehaulMileageSelector);
  const serviceCentersState: ServiceCentersState = useSelector(serviceCentersSelector);
  const [destination, setDestination] = useState<XGSSelectOption | null | undefined>();
  const [selectedLaneId, setSelectedLaneId] = useState<string | null>()
  const [origin, setOrigin] = useState<XGSSelectOption | null | undefined>();
  const [addNewLane, setAddNewLane] = useState(false);

  useEffect(() => {
    if (!serviceCentersState.loaded && !serviceCentersState.loading) dispatch(getServiceCenters());
  }, [dispatch, serviceCentersState]);

  const getOriginsOptions = () => {
    return (
      serviceCentersState.origins?.map(
        (origin): XGSSelectOption => ({
          label: origin.name,
          value: origin.id.toString(),
        })
      ) || []
    );
  };

  const getDestinationsOptions = () => {
    return (
      serviceCentersState.destinations?.map(
        (destination): XGSSelectOption => ({
          label: destination.name,
          value: destination.id.toString(),
        })
      ) || []
    );
  };
  
  const onOriginChanged = (_origin: XGSSelectOption | null | undefined) => {
    setOrigin(_origin);
    searchLanes(_origin, destination);
  };

  const searchLanes = useCallback((origin?: XGSSelectOption | null, destination?: XGSSelectOption | null) => {
    setSelectedLaneId(null);
    const originTerminal = origin?.value as unknown as number;
    const destTerminal = destination?.value as unknown as number;
    dispatch(fetchLinehaulLanes(originTerminal, destTerminal));
  }, [dispatch])

  const onDestinationChanged = (_destination: XGSSelectOption | null | undefined) => {
    setDestination(_destination);
    searchLanes(origin, _destination);
  };

  useEffect(() => {
    dispatch(fetchLinehaulLanes());
  }, [dispatch])

  const selectedLane = linehaulMileageState.lanes.find(lane => lane.laneId === selectedLaneId);

  const onInfiniteScroll = () => {
    dispatch(
      infiniteLoadLinehaulLanes(
        origin?.value as unknown as number,
        destination?.value as unknown as number,
        linehaulMileageState.lanesPageNumber + 1
      )
    )
  }
  return (
    <ContentContainer title="Linehaul Lane Management">
      <div className="xgs-lmi__controls">
        <div style={{ display: "flex", gap: 10 }}>
          <div className="xgs-lmi__controls__item">
            <LabeledSelectInput
              onValueChange={onOriginChanged}
              value={origin}
              options={getOriginsOptions()}
              label="Origin:"
              labelMode={LabelModes.column}
              className="xgs-tracking-filter__input"
              isClearable
            />
          </div>
          <div className="xgs-lmi__controls__item">
            <LabeledSelectInput
              onValueChange={onDestinationChanged}
              value={destination}
              options={getDestinationsOptions()}
              label="Destination:"
              labelMode={LabelModes.column}
              className="xgs-tracking-filter__input"
              isClearable
            />
          </div>
        </div>
        <div className="xgs-lmi__controls__button">
          <Button onClick={() => setAddNewLane(true)} theme={ButtonThemes.gray}>Add New Lane</Button>
        </div>
      </div>
      {linehaulMileageState.loadingLanesFailed && <XGSErrorMessage>{linehaulMileageState.loadingLanesError}</XGSErrorMessage>}
      {!linehaulMileageState.loadingLanesFailed && <div className="xgs-lmi__lanes">
        <Table
          isLoading={linehaulMileageState.loadingLanes}
          data={linehaulMileageState.lanes}
          columns={getLaneManagementColumns()}
          rowHeight={60}
          infiniteScroll
          onRowClicked={(row: any) => {
            setSelectedLaneId(row.laneId)
            setAddNewLane(false);
          }}
          onInfiniteScroll={onInfiniteScroll}
          infiniteScrollHasNext={linehaulMileageState.inifinteLoadingLanesHasNext}
          infiniteScrollLoading={linehaulMileageState.inifiniteLoadingLanes}
          highlightRow={linehaulMileageState.lanes.findIndex((lane) => lane.laneId === selectedLaneId)}
          cursorPointer
        />
      </div>}
      <LaneDetails
        show={!!selectedLaneId}
        lane={selectedLane}
        onClose={() => setSelectedLaneId(null)}
      />
      {addNewLane && <AddLane
        show={addNewLane}
        closeModal={() => {
          setSelectedLaneId(null);
          setAddNewLane(false);
        }}
      />}
    </ContentContainer>
  )
}
