import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import RecognitionLogsService from "../../app/data/documents/recognitionLogsService";
import { initialRecognitionLogsState } from "./RecognitionLogsState";

const recognitionLogsService = RecognitionLogsService.getInstance();

export const recognitionLogsSlice = createSlice({
  name: "recognitionLogs",
  initialState: initialRecognitionLogsState,
  reducers: {
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestError = "";
      state.requestCreator = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestError = payload;
    },
    resetErrors: (state) => {
      state.requestFailed = false;
      state.requestError = "";
    },
    storeRecords: (state, { payload }) => {
      state.records = payload;
    }
  }
});

export const {
  requestStarted,
  requestSucceed,
  requestFailed,
  resetErrors,
  storeRecords
} = recognitionLogsSlice.actions;

export const recognitionLogsSelector = (state: IState) => state.recognitionLogs;

export const getRecognitionLogsRecords = (): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_RECORDS"));
  const response = await recognitionLogsService.getRecognitionLogsRecords();
  if (response.ok()) {
    dispatch(storeRecords(response.data));
    dispatch(requestSucceed());
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const getLogfile = (
  filename: string,
  onSuccess: (arg: Blob) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_LOGFILE"));
  const response = await recognitionLogsService.getLogfile(filename);
  if (response.ok()) {
    dispatch(requestSucceed());
    let blob: Blob = new Blob([response.data], {type: response.data.type});
    onSuccess(blob);
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

const recognitionLogsReducer = recognitionLogsSlice.reducer;
export default recognitionLogsReducer;
