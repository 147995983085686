import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import Loading from "../../../../ui-components/loading/loading";
import XGSFormInput from "../../../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import XGSIcon from "../../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../../ui-components/icon/xgsIcons";
import XGSCheckbox from "../../../../ui-components/xgs-checkbox/xgsCheckbox";
import {
  ReturnCommonModel,
  ReturnCommonSchema
} from "../../../../app/data/return/models";
import { StepProps } from "../../../../app/data/common/models";
import ReturnState from "../../../../slices/return/ReturnState";
import {
  removeReferenceDocument,
  returnSelector,
  setReturnCommon,
  uploadReferenceDocument
} from "../../../../slices/return/returnSlice";
import {
  UPLOAD_MAX_SIZE,
  UPLOAD_FILE_TYPES
} from "../../constants";
import "../../bol.scss";

let initialValues: ReturnCommonModel = {
  requestorRole: "",
  referenceNumber: "",
  poNumber: ""
};

const ReturnCommonStep: React.FC<StepProps> = (props) => {
  const { next } = props;
  const returnState: ReturnState = useSelector(returnSelector);
  const dispatch = useDispatch();
  const commonFormRef = useRef<any>(null);
  const [fileError, setFileError] = useState<string>("");
  const [files, setFiles] = useState<any>([]);
  const [payorConfirmed, setPayorConfirmed] = useState<boolean>(false);
  const [raConfirmed, setRaConfirmed] = useState<boolean>(false);
  const [role, setRole] = useState<string>("");
  const [showOptionalParameters, setShowOptionalParameters] = useState<boolean>(false);

  const {
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: UPLOAD_FILE_TYPES,
    maxSize: UPLOAD_MAX_SIZE * 1048576,
    maxFiles: 1,
    onDrop: (acceptedFiles, fileRejections) => {
      setFileError("");
      if (fileRejections?.length > 0) {
        fileRejections[0].errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setFileError(`File no larger than ${UPLOAD_MAX_SIZE} MB is allowed!`);
          }
          if (err.code === "file-invalid-type") {
            setFileError("Only file of certain formats is allowed!");
          }
          if (err.code === "too-many-files") {
            setFileError(`Only one file is allowed!`);
          }
        });
      }
      if (acceptedFiles.length === 0) return;
      setFiles([...acceptedFiles]);
      dispatch(uploadReferenceDocument(acceptedFiles[0], (error: any) => {
        toast.error(error, { autoClose: 4000 });
        setFiles([]);
      }));
    }
  });

  const onRemoveFile = () => {
    setFiles([]);
    dispatch(removeReferenceDocument());
  };

  const onClickNext = (data: ReturnCommonModel) => {
    dispatch(setReturnCommon(data));
    next && next();
  };

  useEffect(() => {
    commonFormRef.current?.setFieldValue("requestorRole", returnState.returnCommon.requestorRole);
    setRole(returnState.returnCommon.requestorRole);
    commonFormRef.current?.setFieldValue("referenceNumber", returnState.returnCommon.referenceNumber);
    commonFormRef.current?.setFieldValue("poNumber", returnState.returnCommon.poNumber);
    commonFormRef.current?.setFieldValue("originalBolNumber", returnState.returnCommon.originalBolNumber);
    returnState.returnCommon.originalBolNumber && setShowOptionalParameters(true);
    if (returnState.returnCommon.referenceNumber) {
      setPayorConfirmed(true);
      setRaConfirmed(true);
      setTimeout(() => commonFormRef.current?.validateForm(), 50);
    }
    if (returnState.referenceDocumentFilename) {
      setFiles([{ path: returnState.referenceDocumentFilename }])
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="xgs-bol__return__step">
      <Formik
        onSubmit={onClickNext}
        initialValues={initialValues}
        validationSchema={ReturnCommonSchema}
        innerRef={commonFormRef}
        validateOnMount
        enableReinitialize
      >
        {(props: FormikProps<ReturnCommonModel>) => (
          <Form>
            <div className="xgs-bol__section">Common Information</div>
            <div className="xgs-bol__field">
              <div className="xgs-form__label">Please indicate your role in the process: <span>*</span></div>
              <div className="xgs-bol__return__checks__role__row">
                <label>
                  <input
                    type="radio"
                    name="role"
                    value="SHIPPER"
                    checked={role === "SHIPPER"}
                    onChange={(e) => {
                      setRole(e.currentTarget.value);
                      props.setFieldValue("requestorRole", e.currentTarget.value);
                    }}
                  /> I am at pickup location
                </label>
                <label>
                  <input
                    type="radio"
                    name="role"
                    value="CONSIGNEE"
                    checked={role === "CONSIGNEE"}
                    onChange={(e) => {
                      setRole(e.currentTarget.value);
                      props.setFieldValue("requestorRole", e.currentTarget.value);
                    }}
                  /> I am at destination location
                </label>
                <label>
                  <input
                    type="radio"
                    name="role"
                    value="THIRD_PARTY"
                    checked={role === "THIRD_PARTY"}
                    onChange={(e) => {
                      setRole(e.currentTarget.value);
                      props.setFieldValue("requestorRole", e.currentTarget.value);
                    }}
                  /> Neither pickup nor destination
                </label>
              </div>
            </div>
            <div className="xgs-bol__fields-row xgs-bol__fields-row--halves">
              <XGSFormInput
                type="text"
                name="referenceNumber"
                label="Return Authorization Number:"
                required={true}
                requiredAsteriskDisabled={false}
                labelMode={LabelModes.column}
                className="xgs-bol__field xgs-bol__field--min-width-200"
                prependText="RTM"
              />
              <XGSFormInput
                type="text"
                name="poNumber"
                label="Original PO #:"
                required={false}
                requiredAsteriskDisabled={true}
                labelMode={LabelModes.column}
                className="xgs-bol__field"
              />
            </div>
            <div className="xgs-accordion">
              <div
                className={`xgs-accordion__toggle${showOptionalParameters ? " xgs-accordion__toggle--opened" : ""}`}
                onClick={() => setShowOptionalParameters(!showOptionalParameters)}
              >
                Additional Information
                <XGSIcon
                  icon={showOptionalParameters ? XGSIcons.faCaretUp : XGSIcons.faCaretDown}
                  className="xgs-accordion__toggle__icon"
                  size="lg"
                  title="Toggle view"
                />
              </div>
              <div className={`xgs-accordion__content${showOptionalParameters ? " xgs-accordion__content--opened" : ""}`}>
                <XGSFormInput
                  type="text"
                  name="originalBolNumber"
                  label="Original BOL #:"
                  required={false}
                  requiredAsteriskDisabled={true}
                  labelMode={LabelModes.column}
                  className="xgs-bol__return__field xgs-bol__return__field--short"
                />
              </div>
            </div>
            <div className="xgs-bol__field">
              <div className="xgs-form__label">Return Authorization Document:</div>
              {returnState.requestStarted && returnState.requestCreator === "UPLOAD_REFERENCE" && (
                <div className="xgs-bol__return__reference__file-uploading">
                  <Loading isLoading={true} /> <span>Uploading...</span>
                </div>
              )}
              {!(returnState.requestStarted && returnState.requestCreator === "UPLOAD_REFERENCE") && (
                <>
                  {(files.length > 0) && (
                    <div className="xgs-bol__return__reference__file">
                      <div className="xgs-bol__return__reference__file__name">
                        {files[0].path}
                      </div>
                      <div
                        className="xgs-bol__return__reference__file__delete"
                        onClick={onRemoveFile}
                      >
                        <XGSIcon
                          icon={XGSIcons.faTimes}
                          className="xgs-bol__return__reference__file__delete__icon"
                        />
                      </div>
                    </div>
                  )}
                  {(files.length === 0) && (
                    <div {...getRootProps({ className: "xgs-upload__area" })}>
                      <input {...getInputProps()} />
                      <span className="blue-link">tap, click or drag &amp; drop document here</span>
                      <div className="xgs-upload__notes">(<strong>PDF</strong>, <strong>DOC</strong>, <strong>XLS</strong>, <strong>JPEG</strong> and <strong>PNG</strong> formats only)</div>
                      {fileError && (
                        <span className="xgs-upload__area__error">
                          {fileError}
                        </span>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="xgs-bol__field">
              <XGSCheckbox
                mix="xgs-bol__checkbox xgs-bol__checkbox--field"
                name="vendor-confirm-checkbox"
                onChange={() => setRaConfirmed(!raConfirmed)}
                checked={raConfirmed}
              >
                The return is authorized by the original vendor. <span>*</span>
              </XGSCheckbox>
              <div className="xgs-bol__return__field-notes" style={{ marginTop: -18 }}>
                <strong>Note:</strong> All returns must be authorized by the original vendor (mill/manufacturer). By checking the box, you are authorizing that you have obtained an accurate return authorization.
                Failure to provide this documentation can lead to service delays and potential fees.
              </div>
            </div>
            <div className="xgs-bol__field">
              <XGSCheckbox
                mix="xgs-bol__checkbox xgs-bol__checkbox--field"
                name="labeled-confirm-checkbox"
                onChange={() => setPayorConfirmed(!payorConfirmed)}
                checked={payorConfirmed}
              >
                As a requestor, I accept the responsibility for payment of the return shipment. <span>*</span>
              </XGSCheckbox>
              <div className="xgs-bol__return__field-notes" style={{ marginTop: -18 }}>
                <strong>Note:</strong> Somebody else is paying? To request a return, please contact the party that is supposed to pay for the return shipment.
              </div>
            </div>
            <div className="xgs-bol__buttons">
              <Button
                type="submit"
                theme={ButtonThemes.blue}
                disabled={!props.isValid || !props.values.referenceNumber || !raConfirmed || !payorConfirmed || (returnState.requestStarted && returnState.requestCreator === "UPLOAD_REFERENCE")}
                className="xgs-bol__nav-button"
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ReturnCommonStep;
