// src/store/linehaulMileage/linehaulMileageSlice.ts
import { createSlice } from '@reduxjs/toolkit';
import LinehaulMileageService from '../../app/data/linehaul-mileage/linehaulMileageService';
import { initialLinehaulMileageState, LinehaulMileageState } from './linehaulMileageState';
import { AppThunk } from '../../app/store';
import { IState } from '..';
import { AssignLaneRequest, LinehaulLaneRequest, LinehaulTripException } from '../../app/data/linehaul-mileage/models';
import { toast } from 'react-toastify';

const linehaulMileageService = LinehaulMileageService.getInstance();

export const linehaulMileageSlice = createSlice({
  name: 'linehaulMileage',
  initialState: initialLinehaulMileageState,
  reducers: {
    resetLinehaulMileageState: (state) => state,
    fetchTripsStarted: (state: LinehaulMileageState) => {
      state.loadingTrips = true;
      state.loadingTripsSucceed = false;
      state.loadingTripsFailed = false;
    },
    startTripInfiniteLoading: (state) => {
      state.inifiniteLoadingTrips = true;
      state.infiniteLoadingTripsSucceed = false;
    },
    finishTripInfiniteLoading: (state) => {
      state.inifiniteLoadingTrips = false;
      state.infiniteLoadingTripsSucceed = true;
    },
    fetchTripsSucceeded: (state: LinehaulMileageState, { payload }) => {
      state.loadingTrips = false;
      state.trips = payload;
      state.loadingTripsSucceed = true;
      state.loadingTripsFailed = false;
      state.tripsPageNumber = 1;
      state.inifinteLoadingTripsHasNext = payload.length >= 100;
    },
    setTrips: (state, { payload }) => {
      state.trips = payload.trips;
      state.tripsPageNumber = 1;
    },
    addTrips: (state, { payload }) => {
      state.trips.push(...payload.trips);
      state.tripsPageNumber = payload.pageNumber;
      state.inifinteLoadingTripsHasNext = payload.trips.length >= 100;
    },
    fetchTripsFailed: (state: LinehaulMileageState, { payload }) => {
      state.loadingTrips = false;
      state.loadingTripsFailed = true;
      state.loadingTripsError = payload;
    },
    fetchActivityStarted: (state: LinehaulMileageState) => {
      state.loadingActivity = true;
      state.loadingActivitySucceed = false;
      state.loadingActivityFailed = false;
    },
    fetchActivitySucceeded: (state: LinehaulMileageState, { payload }) => {
      state.loadingActivity = false;
      state.activity = payload;
      state.loadingActivitySucceed = true;
      state.loadingActivityFailed = false;
    },
    fetchActivityFailed: (state: LinehaulMileageState, { payload }) => {
      state.loadingActivity = false;
      state.loadingActivityFailed = true;
      state.loadingActivityError = payload;
    },
    fetchLanesStarted: (state: LinehaulMileageState) => {
      state.loadingLanes = true;
      state.loadingLanesSucceed = false;
      state.loadingLanesFailed = false;
    },
    fetchLanesSucceeded: (state: LinehaulMileageState, { payload }) => {
      state.loadingLanes = false;
      state.lanes = payload;
      state.loadingLanesSucceed = true;
      state.loadingLanesFailed = false;
      state.lanesPageNumber = 1;
      state.inifinteLoadingLanesHasNext = payload.length >= 100;
    },
    fetchLanesFailed: (state: LinehaulMileageState, { payload }) => {
      state.loadingLanes = false;
      state.loadingLanesFailed = true;
      state.loadingLanesError = payload;
    },
    startLaneInfiniteLoading: (state) => {
      state.inifiniteLoadingLanes = true;
      state.infiniteLoadingLanesSucceed = false;
    },
    finishLaneInfiniteLoading: (state) => {
      state.inifiniteLoadingLanes = false;
      state.infiniteLoadingLanesSucceed = true;
    },
    requestStarted: (state, { payload }) => {
      state.requestCreator = payload;
      state.requestStarted = true;
      state.requestFailed = false;
      state.requestSucceed = false;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
      state.requestFailed = false;
    },
    addLanes: (state, { payload }) => {
      state.lanes.push(...payload.lanes);
      state.lanesPageNumber = payload.pageNumber;
      state.inifinteLoadingLanesHasNext = payload.lanes.length >= 100;
    },
    requestFailed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
    },
    addLinehaulLaneLocally: (state, { payload }) => {
      state.lanes.push(payload);
    },
    editLinehaulLaneLocally: (state, { payload }) => {
      const laneIndex = state.lanes.findIndex(lane => lane.laneId === payload.laneId);
      if (laneIndex !== -1) {
        state.lanes[laneIndex] = payload.lane;
      }
    },
    deleteLinehaulLaneLocally: (state, { payload }) => {
      const laneIndex = state.lanes.findIndex(lane => lane.laneId === payload.laneId);
      if (laneIndex !== -1) {
        state.lanes.splice(laneIndex, 1);
      }
    },
    assignLaneToTripLocally: (state, { payload }) => {
      const tripIndex = state.trips.findIndex(trip => trip.tripId === payload.tripId);
      if (tripIndex !== -1) {
        state.trips[tripIndex].lane = payload.lane;
      }
    },
    assignDriverToTripLocally: (state, { payload }) => {
      const tripIndex = state.trips.findIndex(trip => trip.tripId === payload.tripId);
      if (tripIndex !== -1) {
        state.trips[tripIndex].driver = payload.driver;
      }
    },
    reviewTripLocally: (state, { payload }) => {
      const tripIndex = state.trips.findIndex(trip => trip.tripId === payload.tripId);
      if (tripIndex !== -1) {
        state.trips[tripIndex].isReviewed = !payload.unReviewed;
      }
    },
    addTripExceptionLocally: (state, { payload }) => {
      const tripIndex = state.trips.findIndex(trip => trip.tripId === payload.tripId);
      if (tripIndex !== -1) {
        state.trips[tripIndex].exceptions.push(payload.exception);
      }
    },
    deleteTripExceptionLocally: (state, { payload }) => {
      const tripIndex = state.trips.findIndex(trip => trip.tripId === payload.tripId);
      if (tripIndex !== -1) {
        const index = state.trips[tripIndex].exceptions.findIndex((exception) => exception.exceptionId === payload.exceptionId);
        state.trips[tripIndex].exceptions.splice(index, 1);
      }
    },
    resetLinehaulLanes: (state) => ({...state, lanes: []})
  }
});

export const { 
  resetLinehaulMileageState,
  fetchTripsStarted,
  fetchTripsSucceeded, 
  fetchTripsFailed,
  startTripInfiniteLoading,
  finishTripInfiniteLoading,
  addTrips,
  fetchActivityFailed,
  fetchActivityStarted,
  fetchActivitySucceeded,
  fetchLanesFailed, 
  fetchLanesStarted, 
  fetchLanesSucceeded,
  resetLinehaulLanes,
  requestFailed,
  requestStarted,
  requestSucceed,
  addLinehaulLaneLocally,
  assignLaneToTripLocally,
  reviewTripLocally,
  assignDriverToTripLocally,
  addTripExceptionLocally,
  deleteTripExceptionLocally,
  editLinehaulLaneLocally,
  deleteLinehaulLaneLocally,
  finishLaneInfiniteLoading,
  startLaneInfiniteLoading,
  addLanes
 } = linehaulMileageSlice.actions;

export const fetchLinehaulMileage = (fromDate?: string, toDate?: string, origin?: number, destination?: number, isReviewed?: boolean | null, pageNumber?: number): AppThunk => async (dispatch) => {
    dispatch(fetchTripsStarted());
    const response = await linehaulMileageService.getLinehaulMileage(fromDate, toDate, origin, destination, isReviewed, false, pageNumber);
    if (response.ok()) {
      dispatch(fetchTripsSucceeded(response.data.trips as any));
    } else {
      response.getError() && dispatch(fetchTripsFailed(response.getError()));
    }
};

export const infiniteLoadLinehaulTrips = (fromDate?: string, toDate?: string, origin?: number, destination?: number, isReviewed?: boolean | null, pageNumber?: number): AppThunk => async (dispatch) => {
  dispatch(startTripInfiniteLoading());
  const response = await linehaulMileageService.getLinehaulMileage(fromDate, toDate, origin, destination, isReviewed, false, pageNumber);
  if (response.ok()) {
    dispatch(finishTripInfiniteLoading());
    dispatch(addTrips({ pageNumber, trips: response.data.trips }))
  } else {
    response.getError() && dispatch(fetchTripsFailed(response.getError()));
  }
};

export const fetchLinehaulTripActivity = (dispatchedDate: string): AppThunk => async (dispatch) => {
    dispatch(fetchActivityStarted());
    const response = await linehaulMileageService.getTripActivity(dispatchedDate);
    if (response.ok()) {
      dispatch(fetchActivitySucceeded(response.data as any));
    } else {
      response.getError() && dispatch(fetchActivityFailed(response.getError()));
    }
};

export const fetchLinehaulLanes = (originTerminal?: number, destinationTerminal?: number): AppThunk => async (dispatch) => {
  dispatch(fetchLanesStarted());
  const response = await linehaulMileageService.getLanes(originTerminal, destinationTerminal);
  if (response.ok()) {
    dispatch(fetchLanesSucceeded(response.data.lanes as any))
  } else {
    response.getError() && dispatch(fetchLanesFailed(response.getError()));
  }
}

export const infiniteLoadLinehaulLanes = (origin?: number, destination?: number, pageNumber?: number): AppThunk => async (dispatch) => {
  dispatch(startTripInfiniteLoading());
  const response = await linehaulMileageService.getLanes(origin, destination, pageNumber);
  if (response.ok()) {
    dispatch(finishTripInfiniteLoading());
    dispatch(addLanes({ pageNumber, lanes: response.data.lanes }))
  } else {
    response.getError() && dispatch(fetchLanesFailed(response.getError()));
  }
};

export const createLinehaulLane = (request: LinehaulLaneRequest, onSuccess: any, onError: any): AppThunk => async (dispatch) => {
  dispatch(requestStarted("CREATE_LANE"));
  const response = await linehaulMileageService.createLane(request);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(addLinehaulLaneLocally(response.data))
    onSuccess();
  } else {
    dispatch(requestFailed());
    onError(response.getError());
  }
}

export const editLinehaulLane = (laneId: string, request: LinehaulLaneRequest, onSuccess: any, onError: any): AppThunk => async (dispatch) => {
  dispatch(requestStarted("EDIT_LANE"));
  const response = await linehaulMileageService.editLane(laneId!, request);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(editLinehaulLaneLocally({ lane: { ...request, laneId}, laneId: laneId }))
    onSuccess();
  } else {
    dispatch(requestFailed());
    onError(response.getError());
  }
}

export const deleteLinehaulLane = (laneId: string, onSuccess: any): AppThunk => async (dispatch) => {
  dispatch(requestStarted("DELETE_LANE"));
  const response = await linehaulMileageService.deleteLane(laneId);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess();
    dispatch(deleteLinehaulLaneLocally({ laneId }));
  } else {
    dispatch(requestFailed());
    toast.error(response.getError() || "Something went wrong");
  }
}

export const assignLinehaulLane = (request: AssignLaneRequest, onSuccess: any, onError: any): AppThunk => async (dispatch) => {
  dispatch(requestStarted("ASSIGN_LANE"));
  const response = await linehaulMileageService.assignLane(request);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess();
  } else {
    dispatch(requestFailed());
    onError(response.getError());
  }
}

export const createLinehaulTripException = (request: LinehaulTripException, onSuccess: any, onError: any): AppThunk => async (dispatch) => {
  dispatch(requestStarted("CREATE_EXCEPTION"));
  const response = await linehaulMileageService.createTripException(request);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess();
    dispatch(addTripExceptionLocally({exception: response.data, tripId: request.tripId}))
  } else {
    dispatch(requestFailed());
    response.getError && onError(response.getError());
  }
}

export const reviewTrip = (tripId: string, unReviewed: boolean, onSuccess: any, onError: any): AppThunk => async (dispatch) => {
  dispatch(requestStarted("REVIEW_TRIP"));
  const response = await (unReviewed ? linehaulMileageService.unReviewTrip(tripId) : linehaulMileageService.reviewTrip(tripId));
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(reviewTripLocally({ tripId, unReviewed }));
    onSuccess();
  } else {
    dispatch(requestFailed());
    response.getError && onError(response.getError());
  }
}

export const assignDriver = (tripId: string, driverId: string, onSuccess: any): AppThunk => async (dispatch) => {
  dispatch(requestStarted("ASSIGN_DRIVER"));
  const response = await linehaulMileageService.assignDriver(tripId, driverId);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(assignDriverToTripLocally({ driver: response.data, tripId: tripId }));
    onSuccess();
  } else {
    dispatch(requestFailed());
    response.getError && toast.error(response.getError());
  }
}

export const deleteTripException = (tripId: string, exceptionId: string): AppThunk => async (dispatch) => {
  dispatch(requestStarted("DELETE_EXCEPTION"));
  const response = await linehaulMileageService.deleteTripException(tripId, exceptionId)
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(deleteTripExceptionLocally({ exceptionId: exceptionId, tripId: tripId }));
    toast.info("Exception removed");
  } else {
    dispatch(requestFailed());
    response.getError && toast.error(response.getError());
  }
} 

export const linehaulMileageSelector = (state: IState) => state.linehaulMileage;

const linehaulMileageReducer = linehaulMileageSlice.reducer;
export default linehaulMileageReducer;