import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export interface RecordModel {
  probill: number | undefined;
  manifest: number | undefined;
  summary?: string;
}

export const RecordSchema: Yup.ObjectSchema<RecordModel> = Yup.object().shape({
  probill: Yup.number()
    .min(0, "The value must be no less than 0")
    .max(999999999, "The value must be no more than 999999999")
    .integer("The value must be an integer")
    .typeError(validationMessages.notValid)
    .when(["manifest"], {
      is: (manifest) => !manifest,
      then: Yup.number().typeError(validationMessages.notValid).required("At least one of the Probill or Manifest is required")
    }
  ),
  manifest: Yup.number()
    .min(0, "The value must be no less than 0")
    .max(999999, "The value must be no more than 999999")
    .integer("The value must be an integer")
    .typeError(validationMessages.notValid)
    .when(["probill"], {
      is: (probill) => !probill,
      then: Yup.number().typeError(validationMessages.notValid).required("At least one of the Probill or Manifest is required")
  }),
  summary: Yup.string().max(255, "The value must be no more than 255 characters")
}, [["probill", "manifest"]]).defined();
