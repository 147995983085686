import api, { ApiResponse } from "./../api";
import { RecognitionLogsResponseModel } from "./models";

class RecognitionLogsService {
  private static instance: RecognitionLogsService;
  private constructor() {}
  static getInstance(): RecognitionLogsService {
    if (!RecognitionLogsService.instance) {
      RecognitionLogsService.instance = new RecognitionLogsService();
    }
    return RecognitionLogsService.instance;
  }

  public getRecognitionLogsRecords = async (): Promise<ApiResponse<RecognitionLogsResponseModel>> => {
    return await api.get("/ocr-audit/log-files");
  };

  public getLogfile = async (
    filename: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/ocr-audit/log-download?filename=${filename}`);
  };
};

export default RecognitionLogsService;
