import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import XGSFormInput from "../../ui-components/form/input/xgsFormInput";
import XGSFormSelect from "../../ui-components/form/select/xgsFormSelect";
import { LabelModes } from "../../ui-components/molecules/labeled-inputs/labeledInput";
import { XGSSelectOption } from "../../ui-components/xgs-select/xgsSelect";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import {
  ClaimFreightChargesModel,
  ClaimItemModel,
  ClaimItemSchema
} from "../../app/data/claims/models";
import { InvoiceDetailsItem } from "../../app/data/invoice/models";
import ClaimsState from "../../slices/claims/ClaimsState";
import { claimsSelector } from "../../slices/claims/claimsSlice";
import XGSCheckbox from "../../ui-components/xgs-checkbox/xgsCheckbox";

interface ClaimItemFormProps {
  probillItems: InvoiceDetailsItem[],
  itemIndex?: number,
  onCancel?: () => void,
  onSubmit: (data: ClaimItemModel) => void
}

let initialValues: ClaimItemModel = {
  description: "",
  claimedPiecesPrice: undefined,
  claimedPiecesWeight: undefined,
  claimedPiecesQty: undefined,
  freightCharges: []
};

const ClaimItemForm: React.FC<ClaimItemFormProps> = (props) => {
  const {
    probillItems,
    itemIndex,
    onCancel,
    onSubmit
  } = props;
  const claimsState: ClaimsState = useSelector(claimsSelector);
  const claimItemRef = useRef<any>(null);
  const [areFreightChargesIncluded, setAreFreightChargesIncluded] = useState<boolean>(false);
  const [freightChargesCheckboxValues, setFreightChargesCheckboxValues] = useState<ClaimFreightChargesModel[]>([]);
  const [description, setDescription] = useState<string>("");

  const probillItemsOptions = () => {
    let options: XGSSelectOption[] = [];
    if (!probillItems) return options;
    for (let i = 0; i < probillItems.length; i++) {
      if (probillItems[i].weight !== 0) {
        options.push({
          label: `${probillItems[i].description} / ${probillItems[i].weight}`,
          value: `${probillItems[i].description} / ${probillItems[i].weight}`
        });
      }
    }
    return options;
  };

  const getItemFreightCharges = () => {
    if (!probillItems || probillItems.length === 0) return [];
    let freightChargesArr = [];
    for (const item of probillItems) {
      if (item.freightCharges && item.freightCharges > 0 && (!description || item.pieces === 0 || description === `${item.description} / ${item.weight}`)) {
        let alreadyUsed = false;
        if (claimsState.items) {
          for (const [claimItemsIndex, claimItems] of claimsState.items.entries()) {
            if (claimItemsIndex !== itemIndex && !claimItems.deleted) {
              alreadyUsed = !!claimItems.freightCharges?.some(obj => obj.description === item.description) || alreadyUsed;
            }
          }
        }
        !alreadyUsed && freightChargesArr.push({
          description: item.description || "",
          price: item.freightCharges || 0
        });
      }
    }
    return freightChargesArr;
  };

  const clearPreviousItemFreightCharges = (newDescription: string) => {
    let freightChargesNewCheckboxValues = [...freightChargesCheckboxValues];
    if (freightChargesNewCheckboxValues.length === 0) return;
    for (const item of probillItems.filter(obj => obj.pieces && obj.freightCharges)) {
      if (item.description !== newDescription) {
        const index = freightChargesNewCheckboxValues.findIndex(obj => obj.description === item.description);
        if (index !== -1) freightChargesNewCheckboxValues.splice(index, 1);
      }
    }
    setFreightChargesCheckboxValues([...freightChargesNewCheckboxValues]);
    claimItemRef.current.setFieldValue("freightCharges", [...freightChargesNewCheckboxValues]);
    if (freightChargesNewCheckboxValues.length === 0) setAreFreightChargesIncluded(false);
  };

  const onClickFreightChargesCheckbox = (item: ClaimFreightChargesModel) => {
    let newFreightCharges = [...freightChargesCheckboxValues];
    const existingItemIndex = newFreightCharges.findIndex(obj => obj.description === item.description && obj.price === item.price);
    if (existingItemIndex !== -1) {
      newFreightCharges.splice(existingItemIndex, 1);
      setFreightChargesCheckboxValues([...newFreightCharges]);
      claimItemRef.current.setFieldValue("freightCharges", [...newFreightCharges]);
    } else {
      setFreightChargesCheckboxValues([...newFreightCharges, item]);
      claimItemRef.current.setFieldValue("freightCharges", [...newFreightCharges, item]);
    }
  };

  useEffect(() => {
    if (itemIndex === undefined || !claimsState.items) return;
    initialValues = {
      description: claimsState.items[itemIndex].description,
      claimedPiecesPrice: claimsState.items[itemIndex].claimedPiecesPrice,
      claimedPiecesWeight: claimsState.items[itemIndex].claimedPiecesWeight,
      claimedPiecesQty: claimsState.items[itemIndex].claimedPiecesQty,
      freightCharges: claimsState.items[itemIndex].freightCharges
    };
    claimItemRef.current.setFieldValue("description", claimsState.items[itemIndex].description);
    setDescription(claimsState.items[itemIndex].description || "");
    claimItemRef.current.setFieldValue("claimedPiecesPrice", claimsState.items[itemIndex].claimedPiecesPrice);
    claimItemRef.current.setFieldValue("claimedPiecesWeight", claimsState.items[itemIndex].claimedPiecesWeight);
    claimItemRef.current.setFieldValue("claimedPiecesQty", claimsState.items[itemIndex].claimedPiecesQty);
    claimItemRef.current.setFieldValue("freightCharges", claimsState.items[itemIndex].freightCharges);
    const freightChargesArr = claimsState.items[itemIndex].freightCharges;
    setAreFreightChargesIncluded(!!(freightChargesArr && freightChargesArr.length > 0));
    claimsState.items[itemIndex].freightCharges && setFreightChargesCheckboxValues([...claimsState.items[itemIndex].freightCharges]);
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (itemIndex !== undefined) return;
    // Formik has issue when passing undefined values (https://github.com/formium/formik/issues/2332), so we passing empty strings
    claimItemRef.current.resetForm({ values: {
      description: "",
      claimedPiecesPrice: "",
      claimedPiecesWeight: "",
      claimedPiecesQty: "",
    }});
  }, [itemIndex]);

  useEffect(() => {
    if (!probillItems || probillItems.filter(obj => obj.weight !== 0).length !== 1) return;
    const probillItemsWPieces = probillItems.filter(obj => obj.weight !== 0 && obj.pieces !== 0);
    if (probillItemsWPieces.length > 0) claimItemRef.current.setFieldValue("description", `${probillItemsWPieces[0].description} / ${probillItemsWPieces[0].weight}`);
  }, [probillItems]);

  return (
    <div className="xgs-claims__item-form">
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={ClaimItemSchema}
        innerRef={claimItemRef}
        enableReinitialize
      >
        {(props: FormikProps<ClaimItemModel>) => (
          <Form>
            {(itemIndex === undefined) && (probillItemsOptions().length > 1) && (
              <XGSFormSelect
                isSearchable={false}
                label="Choose an item that you want to claim for:"
                labelMode={LabelModes.column}
                name="probillItemSelect"
                onValueChange={(v: any) => {
                  if (!v) return;
                  props.setFieldValue("description", v.value);
                  setDescription(v.value); // for immediately updated state of description for the list of Freight Charges
                  clearPreviousItemFreightCharges(v.value);
                }}
                options={probillItemsOptions()}
                required={true}
                requiredAsteriskDisabled={false}
                formik={false}
                className="xgs-claims__item-form__field"
              />
            )}
            {(itemIndex === undefined) && (probillItemsOptions().length === 1) && (
              <div className="xgs-claims__item-form__field">
                Description: {probillItems[0].description}
              </div>
            )}
            {(itemIndex !== undefined) && (
              <div className="xgs-claims__item-form__field">
                Description: {claimsState.items[itemIndex].description}
              </div>
            )}
            <XGSFormInput
              type="text"
              name="claimedPiecesQty"
              label="How many pieces are you claiming?"
              required={true}
              requiredAsteriskDisabled={false}
              labelMode={LabelModes.column}
              className="xgs-claims__item-form__field"
            />
            <XGSFormInput
              type="text"
              name="claimedPiecesWeight"
              label="What is the total weight of the pieces claimed?"
              required={true}
              requiredAsteriskDisabled={false}
              labelMode={LabelModes.column}
              className="xgs-claims__item-form__field"
            />
            <XGSFormInput
              type="text"
              name="claimedPiecesPrice"
              label="Requested claim amount:"
              required={true}
              requiredAsteriskDisabled={false}
              labelMode={LabelModes.column}
              className="xgs-claims__item-form__field"
            />
            {getItemFreightCharges().length !== 0 && (
              <XGSCheckbox
                mix="xgs-claims__item-form__field"
                name="areFreightChargesIncluded"
                onChange={(v) => {
                  if (areFreightChargesIncluded) {
                    props.setFieldValue("freightCharges", []);
                    setFreightChargesCheckboxValues([]);
                  }
                  setAreFreightChargesIncluded(!areFreightChargesIncluded);
                }}
                checked={areFreightChargesIncluded}
                disabled={!props.values.description}
              >
                Are you filing on freight charges?
              </XGSCheckbox>
            )}
            {areFreightChargesIncluded && (
              <div className="xgs-claims__freight-charges xgs-claims__freight-charges--narrow" style={{ marginTop: -16 }}>
                <div className="xgs-form__label">Freight Charges:</div>
                {getItemFreightCharges().map((freightChargesItem: ClaimFreightChargesModel, index: number) => (
                  <XGSCheckbox
                    key={"fc-" + index}
                    name={"freight-charges-checkbox-" + index}
                    onChange={() => onClickFreightChargesCheckbox(freightChargesItem)}
                    checked={freightChargesCheckboxValues.findIndex(obj => obj.description === freightChargesItem.description && obj.price === freightChargesItem.price) !== -1}
                  >
                    <span className="xgs-claims__freight-charges__description">{freightChargesItem.description}</span> (${freightChargesItem.price})
                  </XGSCheckbox>
                ))}
              </div>
            )}
            <div className="xgs-claims__item-form__buttons">
              <Button
                type="submit"
                theme={ButtonThemes.blue}
                disabled={
                  !props.isValid ||
                  !props.values.claimedPiecesQty ||
                  !!((itemIndex !== undefined) && !props.dirty)
                }
                className="xgs-claims__item-form__button"
              >
                {(itemIndex !== undefined) ? "Save changes" : "Add item"}
              </Button>
              {(itemIndex !== undefined) && (
                <Button
                  type="button"
                  theme={ButtonThemes.blue}
                  onClick={onCancel}
                  className="xgs-claims__item-form__button"
                >
                  Cancel
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ClaimItemForm;