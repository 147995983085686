import UserProfile from "./UserProfile";
import { UserRoles } from "./enums";

export class UserUtils {
  // customers
  static isUser = (user?: UserProfile | null) => {
    if (user && user.role) {
      return user.role === UserRoles.USER;
    }
  };

  static isAdministrator = (user?: UserProfile | null) => {
    if (user && user.role) {
      return user.role === UserRoles.ADMINISTRATOR;
    }
  };

  static isCustomer = (user?: UserProfile | null) => {
    if (user && user.role) {
      return (user.role === UserRoles.USER) || (user.role === UserRoles.ADMINISTRATOR);
    }
  };

  // employees
  static isXGSUser = (user?: UserProfile | null) => {
    if (user && user.role) {
      return user.role === UserRoles.XGS_USER;
    }
  };

  static isXGSAdministrator = (user?: UserProfile | null) => {
    if (user && user.role) {
      return user.role === UserRoles.XGS_ADMINISTRATOR;
    }
  };

  static isEmployee = (user?: UserProfile | null) => {
    if (user && user.role) {
      return (user.role === UserRoles.XGS_USER) || (user.role === UserRoles.XGS_ADMINISTRATOR);
    }
  };

  static isXGSDriver = (user?: UserProfile | null) => {
    if (user && user.role) {
      return user.role === UserRoles.XGS_DRIVER;
    }
  };

  static isAgent = (user?: UserProfile | null) => {
    if (user && user.role) {
      return user.role === UserRoles.AGENT
    }
  }

  static isSSO = (user?: UserProfile | null) => {
    if (user) {
      return user.sso;
    }
  }

  static hasPermission = (
    user: UserProfile | null,
    accountId: string | null,
    permission: string
  ): boolean => {
    if (user && user.role) {
      if ((user.role === UserRoles.ADMINISTRATOR && accountId) || user.role === UserRoles.XGS_USER || user.role === UserRoles.XGS_ADMINISTRATOR) return true;
      if (!accountId) return false;
      let currentAccount = user.subAccounts.find(account => account.id === accountId);
      if (!currentAccount) return false;
      return !!currentAccount.permissions.find(permissionsItem => permissionsItem === permission);
    } else {
      return false;
    }
  };

  static hasFeatureDisabled = (
    user: UserProfile | null | undefined,
    feature: string
  ): boolean => {
    if (user && user.disabledFeatures) {
      return !!user.disabledFeatures.find(disabledFeature => disabledFeature === feature);
    } else {
      return false;
    }
  };
}
