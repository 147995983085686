export const customerRoles = [
  {
    label: "Administrator",
    value: "ADMINISTRATOR"
  },
  {
    label: "User",
    value: "USER"
  }
];
