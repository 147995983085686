import { Dispatch } from "react";
import * as H from "history";
import { Routes } from "./RoutesConfig";
import SessionStorage from "../local-storage/SessionStorage";
import UserState from "../../slices/user/UserState";
import { setActiveTerminal } from "../../slices/user/userSlice";

class TerminalRoutingProvider {
  private _sessionStorage: SessionStorage = SessionStorage.getInstance();
  private _history: H.History<any> | undefined;
  private _location: H.Location<any> | undefined;
  private _dispatch: Dispatch<any>;
  private _user: UserState | undefined;
  private urlTerminalNumber?: number | undefined;

  constructor(
    history: H.History<any>,
    dispatch: Dispatch<any>,
    location: H.Location<any>,
    user: UserState
  ) {
    this._history = history;
    this._dispatch = dispatch;
    this._location = location;
    this._user = user;
  }

  setUrlTerminalNumber = (urlTerminalNumber?: number | undefined) => {
    this.urlTerminalNumber = urlTerminalNumber;
    return this;
  };

  /*
   * active terminal selecting priorities
   * 1. url param terminal number
   * 2. sessionStorage's or application state's terminal number
   * 3. first terminal from users terminals list
   */
  checkUrlTerminalNumberExist = (): TerminalRoutingProvider | undefined => {
    if (!this.urlTerminalNumber) {
      let activeTerminal = this._user?.activeTerminal;
      if (!activeTerminal) {
        activeTerminal =
          this._sessionStorage.getActiveTerminal() ||
          this._user?.profile?.serviceCenters.find((terminal) => terminal.primary) ||
          this._user?.profile?.serviceCenters[0];
        this._dispatch(setActiveTerminal(activeTerminal));
      }
      this._history?.replace(`/${activeTerminal?.id}${this._location?.pathname}${this._location?.search || ""}`);
      return undefined;
    }
    return this;
  };

  checkUrlTerminalNumberAccess = (): TerminalRoutingProvider | undefined => {
    const hasAccess = this.findUserTerminalByNumber(this.urlTerminalNumber) !== undefined;
    if (!hasAccess) {
      console.error(
        "You don't have access to this terminal",
        this.urlTerminalNumber
      );

      // if user doesn't have access to the url w/ terminal redirect him to home
      this._history?.replace(Routes.home);
      return undefined;
    }
    return this;
  };

  checkUrlTerminalNumberTheSameAsActiveTerminal = () => {
    const activeTerminal = this._user?.activeTerminal;
    if (!activeTerminal || activeTerminal.id.toString() !== this.urlTerminalNumber?.toString()) {
      this._dispatch(setActiveTerminal(this.findUserTerminalByNumber(this.urlTerminalNumber)));
    }
  };

  private findUserTerminalByNumber = (terminalId?: number | undefined) => {
    const foundTerminal = this._user?.profile?.serviceCenters.find((terminal) => terminal.id === terminalId);
    return foundTerminal;
  }
}

export default TerminalRoutingProvider;
