import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createStandingAppointment,
  getServiceDays,
  getStandingAppointment,
  getStandingAppointmentAutofill,
  resetStandingAppointmentState,
  standingAppointmentSelector,
  updateStandingAppointment,
  updateStandingAppointmentLocally,
} from "../../../slices/customers/standing-appointment/standingAppointmentSlice";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import "./standingAppointment.scss";
import { Form, Formik, FormikProps } from "formik";
import {
  AccountOpenHoursType,
  DayOfWeek,
  generateTimeOptions,
  getStandingAppointmentValidationSchema,
  StandingAppointmentDays,
} from "../../../app/data/standing-appointment/models";
import Button, { ButtonSizes, ButtonThemes } from "../../../ui-components/button/button";
import XGSFormSelect from "../../../ui-components/form/select/xgsFormSelect";
import Loading from "../../../ui-components/loading/loading";
import moment from "moment";
import { toast } from "react-toastify";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import LabeledValue from "../../../ui-components/molecules/labeled-value/labeledValue";
import { userSelector } from "../../../slices/user/userSlice";
import styles from "../../../sass/variables.module.scss";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import Switch from "react-switch";
import ReactSwitch from "react-switch";
import { accountInapplicableInformationSelector, disableInformationType, enableInformationType } from "../../../slices/account-inapplicable-information/accountInapplicableInformationSlice";

const getInitialValues = (serviceDays: Record<DayOfWeek, boolean> | null) => {
  let initialValues: any = {};
  for (let day of Object.values(DayOfWeek)) {
    let isServiceDay = serviceDays ? serviceDays[day] : false;
    initialValues[day] = {
      fromTime: null,
      toTime: null,
      isOpen: isServiceDay,
    };
  }
  initialValues[DayOfWeek.SUNDAY] = {
    isOpen: false
  }
  initialValues[DayOfWeek.SATURDAY] = {
    isOpen: false
  }
  return initialValues as StandingAppointmentDays;
};

const addHoursToTime = (time: string, hours: number) => {
  return moment(time, "hh:mm A").add(hours, 'hour').format("hh:mm A")
}

export const StandingAppointment: React.FC<{ accountId: string, type: AccountOpenHoursType }> = ({
  accountId,
  type
}) => {
  const standingAppointmentState = useSelector(standingAppointmentSelector);
  const accountInapplicableInformationState = useSelector(accountInapplicableInformationSelector);
  const [editable, setEditable] = useState(true);
  const formRef = useRef<FormikProps<StandingAppointmentDays>>(null);
  const userState = useSelector(userSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!accountId) return;
    dispatch(getStandingAppointment(accountId, type));
    dispatch(getServiceDays(accountId));
    dispatch(getStandingAppointmentAutofill(accountId));

    return () => {
      dispatch(resetStandingAppointmentState());
    };
  }, [accountId, type, dispatch]);

  let standingAppointmentExists = !!standingAppointmentState.standingAppointments?.dailyHoursMap;

  let initialFormValues = standingAppointmentState.standingAppointments?.dailyHoursMap || getInitialValues(standingAppointmentState.serviceDays);

  useEffect(() => {
    // If there's an existing standing appointment, disable editing
    if (standingAppointmentState.standingAppointments) {
        setEditable(false);
    } else {
      setEditable(true)
    }
  }, [standingAppointmentState.standingAppointments]);

  const onSubmit = (values: StandingAppointmentDays) => {
    if (!accountId) return;
    if (!standingAppointmentState.standingAppointments) {
      dispatch(
        createStandingAppointment(
          accountId,
          { dailyHoursMap: values },
          type,
          onCreateSuccess,
          onFailed
        )
      );
    } else {
      dispatch(
        updateStandingAppointment(
          accountId,
          { dailyHoursMap: values },
          type,
          onUpdateSuccess,
          onFailed
        )
      );
    }
  };

  const onCreateSuccess = () => {
    toast.info("Hours saved");
    dispatch(updateStandingAppointmentLocally({dailyHoursMap:formRef.current?.values, userUpdated: userState.profile?.email}));
  };

  const onUpdateSuccess = () => {
    toast.info("Hours updated");
    dispatch(updateStandingAppointmentLocally({dailyHoursMap:formRef.current?.values, userUpdated: userState.profile?.email}));
  };

  const onFailed = () => {
    toast.info("Something went wrong");
  };

  const getTimeSelectionOption = (timeValue?: string | null) => {
    return timeValue ? {label: moment(timeValue, "HH:mm:ss").format("h:mm A"), value: timeValue} : null;
  }

  const showAutofill = editable && !standingAppointmentExists && standingAppointmentState.standingAppointmentAutoFill
    && standingAppointmentState.standingAppointmentAutoFill?.type !== type;

  const autofillStandingAppointments = () => {
    if (!formRef.current || !standingAppointmentState.standingAppointmentAutoFill) return;
    formRef.current.setValues(standingAppointmentState.standingAppointmentAutoFill?.data.dailyHoursMap);
  }

  const onDisableType = () => {
    dispatch(disableInformationType(
      accountId,
      type,
      () => toast.info("Disabled information type"),
      () => toast.error('Something went wrong')))
  }

  const onEnableType = () => {
    dispatch(enableInformationType(
      accountId,
      type,
      () => toast.info("Enabled information type"),
      () => toast.error('Something went wrong')))
  }
      
  return (
    <div className="xgs-standing-appointment">
      <Loading isLoading={(standingAppointmentState.requestStarted && standingAppointmentState.requestCreator === "GET") || standingAppointmentState.loadingServiceDays} />
      {standingAppointmentState.loadingServiceDaysFailed && <XGSErrorMessage>Could not find service days, please try again later</XGSErrorMessage>}
      {(standingAppointmentState.loadedServiceDays && !(standingAppointmentState.requestStarted &&
        standingAppointmentState.requestCreator === "GET")) && (
        <>
          <div className="xgs-account-contact__unavailable-switch">
            <ReactSwitch
              checkedIcon={false}
              width={32}
              height={20}
              uncheckedIcon={false}
              onChange={(checked: any) => {
                checked ? onDisableType() : onEnableType()
              }}
              disabled={accountInapplicableInformationState.requestStarted || accountInapplicableInformationState.loading}
              checked={accountInapplicableInformationState.disabledInformationTypes.includes(type)}
            />
            <div>
              Not available for this account <Loading isLoading={accountInapplicableInformationState.requestStarted}/>
            </div>
          </div>
          {!accountInapplicableInformationState.disabledInformationTypes.includes(type) && (
            <>
              {showAutofill && <div className="xgs-standing-appointment__autofill">
                <span>Copy time from {standingAppointmentState.standingAppointmentAutoFill?.type.toLowerCase()} hours?</span>
                <Button onClick={autofillStandingAppointments} size={ButtonSizes.small} theme={ButtonThemes.blue}>Copy</Button>
              </div>}
              <Formik
                validationSchema={getStandingAppointmentValidationSchema(type)}
                onSubmit={onSubmit}
                initialValues={initialFormValues}
                enableReinitialize
                innerRef={formRef}
              >
                {(props) => (
                  <Form>
                    {Object.values(DayOfWeek).map((day, idx) => {
                      const isServiceDay = standingAppointmentState.serviceDays ? standingAppointmentState.serviceDays[day] : false;
                      const isOpen = props.values[day].isOpen;
                      const isWeekend = day === DayOfWeek.SATURDAY || day === DayOfWeek.SUNDAY;

                      return (
                        <div
                          key={idx}
                          className={`xgs-standing-appointment__day-block 
                            ${!isOpen ? `xgs-standing-appointment__day-block--closed` : ""} 
                            ${!editable ? "xgs-standing-appointment__day-block--non-editable" : ""}`
                          }
                        >
                          <label
                            htmlFor={`${day}.isOpen`}
                            className="xgs-standing-appointment__day-block__day-label"
                          >
                            <span className="xgs-standing-appointment__day-block__day-label--large">{day.charAt(0) + day.slice(1).toLowerCase()}</span>
                            <span className="xgs-standing-appointment__day-block__day-label--small">{day.slice(0,3).toUpperCase()}</span>
                          </label>
                          {editable && (
                            <div className={`xgs-standing-appointment__day-block__switch-container`}>
                              {!isWeekend && <Switch
                                checkedIcon={false}
                                width={32}
                                height={20}
                                uncheckedIcon={false}
                                onChange={(checked: any) => {
                                  props.setFieldValue(
                                    `${day}.isOpen`,
                                      checked
                                  )
                                }}
                                checked={!!isOpen}
                              />}
                            </div>)
                          }
                          {editable && (
                            <div className="xgs-standing-appointment__day-block__time-container">
                              <XGSFormSelect
                                name={day + ".fromTime"}
                                options={generateTimeOptions("07:00 am", type === AccountOpenHoursType.BUSINESS ? "5:00 pm" : "02:00 pm")}
                                formik
                                disabled={!isOpen}
                                menuPlacement="auto"
                                placeholder="From"
                                labelMode={LabelModes.column}
                                className="xgs-standing-appointment__day-block__time-input"
                                value={getTimeSelectionOption(props.values[day].fromTime)}
                                onValueChange={(value: any) => {
                                  props.setFieldValue(day + ".fromTime", value?.value);
                                }}
                              />
                              <span className="xgs-standing-appointment__day-block__seperator"></span>
                              <XGSFormSelect
                                name={day + ".toTime"}
                                options={
                                  generateTimeOptions(
                                    addHoursToTime(
                                      props.values[day].fromTime || "07:00 am", type === AccountOpenHoursType.BUSINESS ? 0.5 : 4), 
                                      "06:00 pm"
                                  )
                                }
                                formik
                                menuPlacement="auto"
                                labelMode={LabelModes.column}
                                placeholder="To"
                                disabled={!isOpen}
                                className="xgs-standing-appointment__day-block__time-input"
                                value={getTimeSelectionOption(props.values[day].toTime)}
                                onValueChange={(value) => {
                                  props.setFieldValue(day + ".toTime", value?.value);
                                }}
                              />
                              {!isServiceDay && 
                                <span className="xgs-standing-appointment__day-block__service-day">
                                  <XGSIcon icon={XGSIcons.faCalendarTimes} color={styles.red}/> 
                                </span>
                              }
                            </div>
                          )}
                          {!editable && (
                            <>
                              {isOpen && (
                                <>
                                  <span
                                    className={`xgs-standing-appointment__day-block__time xgs-standing-appointment__day-block__time--non-editable`}
                                  >
                                    {moment(props.values[day].fromTime, "HH:mm:ss").format("hh:mm a")}
                                  </span>
                                  <span className="xgs-standing-appointment__day-block__seperator"></span>
                                  <span className="xgs-standing-appointment__day-block__time">
                                    {moment(props.values[day].toTime, "HH:mm:ss").format("hh:mm a")}
                                  </span>
                                </>
                              )}
                              {!isOpen && (
                                <span className="xgs-standing-appointment__day-block__closed-text">
                                  Closed
                                </span>
                              )}
                              <span className="xgs-standing-appointment__day-block__service-day">
                                {!isServiceDay && <XGSIcon icon={XGSIcons.faCalendarTimes} color={styles.red}/>}
                              </span>
                            </>
                          )}
                        </div>
                      );
                    })}
                    {!editable && (
                      <div className="xgs-standing-appointment__submit-buttons">
                        <Button
                          onClick={() => setEditable(true)}
                          type="button"
                          theme={ButtonThemes.gray}
                          className="xgs-standing-appointment__submit-buttons__edit"
                        >
                          Edit schedule <XGSIcon icon={XGSIcons.faEdit} />
                        </Button>
                      </div>
                    )}
                    {editable && (
                      <div className="xgs-standing-appointment__submit-buttons">
                        <Button
                          type="button"
                          onClick={() => {
                            formRef.current?.resetForm();
                            setEditable(false);
                          }}
                          disabled={!standingAppointmentExists}
                          theme={ButtonThemes.gray}
                        >
                          Cancel
                        </Button>
                        <Button
                          spinner={
                            standingAppointmentState.requestStarted &&
                            standingAppointmentState.requestCreator !== "GET"
                          }
                          disabled={!props.dirty}
                          type="submit"
                          theme={ButtonThemes.blue}
                        >
                          Save
                        </Button>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
              {(!editable && standingAppointmentState.standingAppointments?.dateUpdated && standingAppointmentState.standingAppointments.userUpdated) &&
                <div className="xgs-standing-appointment__audit">
                  <LabeledValue 
                    label="Last updated:" 
                    value={`
                      ${standingAppointmentState.standingAppointments?.dateUpdated?.toUiDateTimeFormat()} by ${standingAppointmentState.standingAppointments?.userUpdated}
                    `}
                  />
                </div>
              }
            </>
          )}
        </>
      )}
    </div>
  );
};
