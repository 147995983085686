
export interface UserPermission {
  user: string;
  name: string;
  permissions: string[];
}

export default interface CompanySettingsAccountsState {
  fetch_was_started: boolean;
  fetchPortion_was_started: boolean;
  fetch_was_succeed: boolean;
  fetch_was_failed: boolean;
  fetch_fail_reason?: string | null;  
  update_was_started: boolean;
  update_was_succeed: boolean;
  update_was_failed: boolean;
  update_fail_reason?: string | null;
  accountPermissions: UserPermission[];
}

export const initialCompanySettingsAccountsState: CompanySettingsAccountsState = {
  fetch_was_started: false,
  fetchPortion_was_started: false,
  fetch_was_succeed: false,
  fetch_was_failed: false,
  fetch_fail_reason: null,
  update_was_started: false,
  update_was_succeed: false,
  update_was_failed: false,
  update_fail_reason: null,
  accountPermissions: []
};
