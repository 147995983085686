import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import ServiceCentersStatusService from "../../app/data/service-centers-status/serviceCentersStatusService";
import { initialServiceCentersStatusState } from "./ServiceCentersStatusState";

const serviceCentersStatusService = ServiceCentersStatusService.getInstance();

export const serviceCentersStatusSlice = createSlice({
  name: "serviceCentersStatus",
  initialState: initialServiceCentersStatusState,
  reducers: {
    resetServiceCentersStatus: (state) => initialServiceCentersStatusState,
    request_was_started: (state) => {
      state.request_was_started = true;
      state.request_was_succeed = false;
    },
    request_was_succeed: (state, { payload }) => {
      state.request_was_started = false;
      state.request_was_succeed = true;
      state.request_was_failed = false;
      state.closedList = [...payload.closed];
      state.limitedList = [...payload.limited];
    },
    request_was_failed: (state, { payload }) => {
      state.request_was_started = false;
      state.request_was_succeed = false;
      state.request_was_failed = true;
      state.requestError = payload;
    }
  }
});

export const {
  resetServiceCentersStatus,
  request_was_started,
  request_was_succeed,
  request_was_failed
} = serviceCentersStatusSlice.actions;

export const serviceCentersStatusSelector = (state: IState) => state.serviceCentersStatus;

export const getServiceCentersStatus = (): AppThunk => async (dispatch) => {
  dispatch(request_was_started());
  const response = await serviceCentersStatusService.getPageContent();
  if (response?.status === 200) {
    let closedList: string[] = [];
    let limitedList: string[] = [];
    let content;
    for (let obj of response.data) {
      if (obj.slug === "weather-alert" && obj.status === "publish") {
        content = obj.content.rendered;
      }
    }
    if (content) {
      content = content.replace(/(\r\n|\n|\r)/gm, "");
      let serviceCentersArr = content.match(/\[et_pb_map_pin_extended(.*?)\/et_pb_map_pin/gmi);
      for (let serviceCenterData of serviceCentersArr) {
        if (serviceCenterData.toLowerCase().indexOf("closedopsmarker") !== -1
          || serviceCenterData.toLowerCase().search(/[^.]closedsc/) !== -1
          || serviceCenterData.toLowerCase().indexOf(">closed<") !== -1) {
          let serviceCenterAddress = serviceCenterData.match(/title=&#8221;(.*?)&#8221;/g);
          // can't use lookbehind here because it unsupported in Safari
          serviceCenterAddress = serviceCenterAddress[0].replace(/title=&#8221;/, "");
          serviceCenterAddress = serviceCenterAddress.replace(/&#8221;/, "");
          serviceCenterAddress && closedList.push(serviceCenterAddress);
        }
        if (serviceCenterData.toLowerCase().indexOf("limitedopsmarker") !== -1
          || serviceCenterData.toLowerCase().search(/[^.]limitedsc/) !== -1
          || serviceCenterData.toLowerCase().indexOf(">limited service<") !== -1) {
          let serviceCenterAddress = serviceCenterData.match(/title=&#8221;(.*?)&#8221;/g);
          // can't use lookbehind here because it unsupported in Safari
          serviceCenterAddress = serviceCenterAddress[0].replace(/title=&#8221;/, "");
          serviceCenterAddress = serviceCenterAddress.replace(/&#8221;/, "");
          serviceCenterAddress && limitedList.push(serviceCenterAddress);
        }        
      }
    }
    dispatch(request_was_succeed({
      closed: closedList,
      limited: limitedList
    }));
  } else {
    dispatch(request_was_failed(response?.statusText || ""));
  }
};

const serviceCentersStatusReducer = serviceCentersStatusSlice.reducer;
export default serviceCentersStatusReducer;
