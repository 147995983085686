import { IState, InvoiceDetailsState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import InvoiceService from "../../app/data/invoice/invoiceService";
import { PaymentUtils } from "../../app/data/payment/paymentUtils";

const initialState: InvoiceDetailsState = {
  loading: false,
  loadingFailed: false,
  loadingError: null,
  loaded: false,
  needToReload: false,
  invoiceDetails: null
};

const invoiceService = InvoiceService.getInstance();

export const invoiceDetailsSlice = createSlice({
  name: "invoiceDetails",
  initialState,
  reducers: {
    resetInvoiceDetails: (state) => initialState,
    invoiceDetailsLoadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.needToReload = false;
      state.loaded = false;
    },
    invoiceDetailsLoadingSucceed: (state, { payload }) => {
      state.loading = false;
      state.loaded = true;
      PaymentUtils.setIsSuccess(payload.paymentStatus);
      state.invoiceDetails = payload;
    },
    invoiceDetailsLoadingFailed: (state, { payload }) => {
      state.loading = false;
      state.loadingFailed = true;
      state.invoiceDetails = null;
      state.loadingError = payload;
    },
    reloadInvoiceDetails: (state) => {
      if (state.loaded) {
        state.needToReload = true;
      }
    }
  }
});

export const {
  resetInvoiceDetails,
  invoiceDetailsLoadingStarted,
  invoiceDetailsLoadingSucceed,
  invoiceDetailsLoadingFailed,
  reloadInvoiceDetails
} = invoiceDetailsSlice.actions;

export const invoiceDetailsSelector = (state: IState) => state.invoiceDetails;

export const getInvoiceDetails = (
  subAccountId?: string,
  invoiceId?: number
): AppThunk => async (dispatch) => {
  if (!subAccountId || !invoiceId) return;
  dispatch(invoiceDetailsLoadingStarted());
  const response = await invoiceService.getInvoiceDetails({
    subAccountId,
    invoiceId,
  });
  if (response.ok()) {
    dispatch(invoiceDetailsLoadingSucceed(response.data));
  } else {
    response.getError && dispatch(invoiceDetailsLoadingFailed(response.getError()));
  }
};

const invoiceDetailsReducer = invoiceDetailsSlice.reducer;
export default invoiceDetailsReducer;
