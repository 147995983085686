import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export interface TransitCalculatorModel {
  originZip: string;
  destinationZip: string;
  tenderDate: string;
}

export const TransitCalculatorSchema: Yup.ObjectSchema<TransitCalculatorModel> = Yup.object({
  originZip: Yup
    .string()
    .matches(/^\d*$/, validationMessages.notValid)
    .min(5, "The value must be no less than 5 digits")
    .max(5, "The value must be no more than 5 digits")
    .required(validationMessages.required),
  destinationZip: Yup
    .string()
    .matches(/^\d*$/, validationMessages.notValid)
    .min(5, "The value must be no less than 5 digits")
    .max(5, "The value must be no more than 5 digits")
    .required(validationMessages.required),
  tenderDate: Yup
    .string()
    .nullable()
    .required(validationMessages.required)
}).defined();

export interface TransitCalculatorResultModel {
  data: {
    etaDate: string;
    fromZip: string;
    toZip: string;
    tenderDateLiteral: string;
    originCenter: {
      city: string;
      address: string;
      phone: string;
      operationDays: any;
    };
    destinationCenter: {
      city: string;
      address: string;
      phone: string;
      operationDays: any;
    };
    transitDays: number;
  };
  error: string;
}
