import React, { memo } from "react";
import NumberFormat from "react-number-format";
import LabeledInput, {
  CommonLabeledInputProps,
  CommonLabeledInputElementProps,
} from "../labeledInput";

export interface LabeledMaskInputProps
  extends CommonLabeledInputElementProps,
    CommonLabeledInputProps,
    React.HTMLProps<HTMLInputElement> {
  onValueChange: (value: string) => void;
}

export interface NumberFormatValues {
  floatValue: number | undefined;
  formattedValue: string;
  value: string;
}

const LabeledPhoneInput: React.FC<LabeledMaskInputProps> = memo(({
  label,
  required,
  labelMode,
  requiredAsteriskDisabled,
  meta,
  error,
  className,
  disabled,
  ...props
}) => {
  const isFailed = (): boolean => {
    return (meta?.touched && meta?.error) || error ? true : false;
  };

  return (
    <LabeledInput
      label={label}
      labelMode={labelMode}
      required={required}
      requiredAsteriskDisabled={requiredAsteriskDisabled}
      isFailed={isFailed}
      error={meta?.error || error}
      className={className || ""}
    >
      <div className="xgs-input">
        <NumberFormat
          disabled={disabled}
          type="tel"
          name={props.name}
          allowNegative={false}
          isNumericString={false}
          thousandSeparator={false}
          format="(###) ###-####"
          mask="_"
          className={`xgs-input__field ${isFailed && isFailed() ? "not-valid" : ""}`}
          value={`${props.value}`}
          onValueChange={(valuesObj: NumberFormatValues) => props.onValueChange(valuesObj.formattedValue || "")}
          onBlur={(e) => {
            props.onBlur && props.onBlur(e);
          }}        
          onKeyDown={(e) => {
            props.onKeyDown && props.onKeyDown(e);
          }}
        />
      </div>
    </LabeledInput>
  );
});

export default LabeledPhoneInput;
