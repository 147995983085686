import React from "react";

export const getLaneColumns = (assignedLaneId?: string | null, disableSelect?: boolean) => [
  ...disableSelect ? [] : [{
    Header: "",
    accessor: "select",
    width: 40,
    Cell: (cellProps: any) => {
      return (
        <div style={{ textAlign: "center" }}>
          <input
            type="radio"
            name="contactSelect"
            checked={cellProps.row.original.laneId === assignedLaneId}
            className="xgs-contact-card__radio"
          />
        </div>
    )}
  }],
  {
    Header: "Lane",
    accessor: "laneName",
    width: 152,
  },
  {
    Header: "Miles",
    accessor: "miles",
    width: 90,
  },
  {
    Header: "Lane Description",
    accessor: "laneNotes",
    width: 228,
  },
  {
    Header: "Relay Location",
    accessor: "relayCity",
    width: 260,
    Cell: (cellProps: any) => (
      <>
        {
          [cellProps.row.original.relayCity, cellProps.row.original.relayState, cellProps.row.original.relayZipcode]
            .filter(val => !!val)
            .join(", ")
        }
      </>
    )
  }
];