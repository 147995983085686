import React, { Component } from "react";
import XGSInput, { XGSInputProps } from "../../xgs-input/xgsInput";

export interface XGSDateInputProps extends XGSInputProps {
  placeholderText: string | undefined;
}

/** Must have this component as a class, since react-datepicker uses refs for custom inputs */
class XGSDateInput extends Component<XGSDateInputProps> {
  render() {
    const {
      isFailed,
      fields,
      placeholderText,
      ...dateInputProps
    } = this.props;

    return (
      <XGSInput
        isFailed={isFailed}
        fields={fields}
        className="xgs-date-input"
        placeholder={placeholderText}
        {...dateInputProps}
      />
    );
  }
}

export default XGSDateInput;
