import React from "react";
import Loading from "../../../../ui-components/loading/loading";
import { ReactComponent as PackageArrowUp } from "../../../../images/package_arrow_up.svg";
import { ReactComponent as Weight } from "../../../../images/weight.svg";
import { ReactComponent as TimeExpired } from "../../../../images/time_expired.svg";
import { ReactComponent as PackageCancel } from "../../../../images/package_cancel.svg";

export enum PicklistsSummaryTileTypes {
    totalItems = "totalItems",
    totalWeight = "totalWeight",
    lateLoadings = "lateLoadings",
    missingItems = "missingItems"
}
interface PicklistsSummaryTileProps {
    type: PicklistsSummaryTileTypes;
    isLoading: boolean;
    title: string;
    value: string | number;
}

const getTileIcon = (type: PicklistsSummaryTileTypes) => {
    if (type === "totalItems") return <PackageArrowUp />;
    else if (type === "totalWeight") return <Weight />;
    else if (type === "lateLoadings") return <TimeExpired />;
    else if (type === "missingItems") return <PackageCancel />;
    else return "";
}

export const PicklistsSummaryTile: React.FC<PicklistsSummaryTileProps> = (props) => {

    return (<div className="xgs-picklists-summary__tile">
        <div className={`icon-container --type-${props.type}`}>
            {getTileIcon(props.type)}
        </div>
        <div className="content">
            {props.isLoading ?
                <Loading isLoading={true} /> :
                <div className="value">{props.value}</div>
            }
            <div className="title">{props.title}</div>

        </div>
    </div>
    )
};