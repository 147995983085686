import api, { ApiResponse } from "../api2";
import { UnplannableProbillsRequest, UnplannableProbillsResponse } from "./models";

class UnplannableProbillsService {
  private source: any;
  private static instance: UnplannableProbillsService;

  private constructor() { }

  static getInstance(): UnplannableProbillsService {
    if (!UnplannableProbillsService.instance) {
      UnplannableProbillsService.instance = new UnplannableProbillsService();
    }
    return UnplannableProbillsService.instance;
  }

  public getUnplannableProbills = async (
    requestData: UnplannableProbillsRequest
  ): Promise<ApiResponse<UnplannableProbillsResponse>> => {
    let endpoint = `/tms/route/optimization/unplannable/probills`;
    return await api.get(endpoint, { params: requestData });
  };

}
export default UnplannableProbillsService;