import api, { ApiResponse } from "../api";
import axios from "axios";
import { Aging } from "./models";

class AgingService {
  private source: any;
  private static instance: AgingService;

  private constructor() {}

  static getInstance(): AgingService {
    if (!AgingService.instance) {
      AgingService.instance = new AgingService();
    }

    return AgingService.instance;
  }

  public get = async (subAccountId: string): Promise<ApiResponse<Aging>> => {
    this.source && this.source.cancel();
    this.source = axios.CancelToken.source();
    return await api.get(`/accounts/${subAccountId}/reports/aging`, {
      cancelToken: this.source.token
    });
  };
}

export default AgingService;