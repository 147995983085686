import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export interface OptionFormModel {
  label: string;
  value: string;
};

export interface PickupLocationModel {
  location: OptionFormModel | null,
  date: string
}

export const PickupLocationSchema: Yup.ObjectSchema<PickupLocationModel> = Yup.object({
  location: Yup.object({
    label: Yup.string().required(validationMessages.required),
    value: Yup.string().required(validationMessages.required)
  }).required(validationMessages.required),
  date: Yup.string().nullable().required(validationMessages.required)
}).defined();

export interface DestinationLocationModel {
  location: OptionFormModel | null
}

export const DestinationLocationSchema: Yup.ObjectSchema<DestinationLocationModel> = Yup.object({
  location: Yup.object({
    label: Yup.string().required(validationMessages.required),
    value: Yup.string().required(validationMessages.required)
  }).required(validationMessages.required),
}).defined();

export interface ServicesModel {
  services: string[]
}

export const ServicesSchema: Yup.ObjectSchema<ServicesModel> = Yup.object({
  services: Yup.array().of(Yup.string().defined()).defined()
}).defined();

export interface ShippingItemFormModel {
  freightClass: string,
  description?: string,
  pieces?: number,
  yards?: number,
  weight?: number,
  rolls?: boolean,
  deleted?: boolean // used to keep index of item in edit mode
}

export const ShippingItemSchema: Yup.ObjectSchema<ShippingItemFormModel> = Yup.object({
  freightClass: Yup.string().required(validationMessages.required),
  description: Yup.string().max(32, "The value must be no more than 32 characters"),
  pieces: Yup
    .number()
    .integer("The value must be a valid amount without decimal values")
    .positive()
    .max(9999)
    .typeError(validationMessages.notValid)
    .required(validationMessages.required),
  yards: Yup
    .number()
    .positive()
    .max(99999)
    .typeError(validationMessages.notValid)
    .when("freightClass", {
      is: "1",
      then: Yup.number().required(validationMessages.required).typeError(validationMessages.notValid)
    })
    .when("freightClass", {
      is: "3",
      then: Yup.number().required(validationMessages.required).typeError(validationMessages.notValid)
    }),
  weight: Yup
    .number()
    .integer("The value must be a weight without decimal values")
    .positive()
    .max(99999)
    .typeError(validationMessages.notValid)
    .required(validationMessages.required),
  rolls: Yup.boolean()
}).defined();

export interface GetRatesItemModel {
  pieces?: number,
  ratingClass: string,
  yards?: number,
  weight?: number,
  roll?: boolean,
  description?: string
}

export interface GetRatesRequestModel {
  account?: string,
  accountNumber?: number,
  originZip: string,
  destinationZip: string,
  tenderDate: string,
  items: GetRatesItemModel[],
  specialServices: string[]
}

export interface EmailQuoteModel {
  email: string;
}

export const EmailQuoteSchema: Yup.ObjectSchema<EmailQuoteModel> = Yup.object().shape({
  email: Yup.string().max(60, "The value must be no more than 60 characters").email(validationMessages.notValid).required(validationMessages.required)
}).defined();


export interface QuickRequestRatesModel {
  pickupLocation: OptionFormModel | null,
  destinationLocation: OptionFormModel | null,
  tenderDate: string,
  specialServices: string[]
}

export const QuickRequestRatesSchema: Yup.ObjectSchema<QuickRequestRatesModel> = Yup.object({
  pickupLocation: Yup.object({
    label: Yup.string().required(validationMessages.required),
    value: Yup.string().required(validationMessages.required)
  }).required(validationMessages.required),
  destinationLocation: Yup.object({
    label: Yup.string().required(validationMessages.required),
    value: Yup.string().required(validationMessages.required)
  }).required(validationMessages.required),
  tenderDate: Yup.string().nullable().required(validationMessages.required),
  specialServices: Yup.array().of(Yup.string().defined()).defined()
}).defined();
