import api, { ApiResponse } from "../api";
import { AccountOpenHoursType, DayOfWeek, StandingAppointmentDto } from "./models";

export class StandingAppointmentService {
  private static instance: StandingAppointmentService;
  private constructor() {}

  static getInstance(): StandingAppointmentService {
    if (!StandingAppointmentService.instance) {
      StandingAppointmentService.instance = new StandingAppointmentService();
    }
    return StandingAppointmentService.instance;
  }

  getServiceDays(accountId: string): Promise<ApiResponse<Record<DayOfWeek, boolean>>> {
    return api.get(`/standing-appointment/${accountId}/service-days`)
  }
  
  getStandingAppointment(
    accountId: string,
    type: AccountOpenHoursType
  ): Promise<ApiResponse<StandingAppointmentDto>> {
    return api.get(`/standing-appointment/${accountId}/${type}`);
  }

  createStandingAppointment(
    accountId: string,
    standingAppointmentDto: StandingAppointmentDto,
    type: AccountOpenHoursType
  ): Promise<ApiResponse<any>> {
    return api.post(
      `/standing-appointment/${accountId}/${type}`,
      standingAppointmentDto
    );
  }

  updateStandingAppointment(
    accountId: string,
    standingAppointmentDto: StandingAppointmentDto,
    type: AccountOpenHoursType
  ): Promise<ApiResponse<any>> {
    return api.post(
      `/standing-appointment/${accountId}/${type}`,
      standingAppointmentDto
    );
  }
}
