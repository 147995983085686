import React from "react";

import { PickupProfile } from "../../../app/data/manifest/manifestProfiles";
import { STOP_STATUSES } from "../../../app/data/manifest/models";
import { PickupStatus } from "../../../app/data/common/route";
import { formatAddress } from "../../../services/common/address";
import { cn } from "../../../services/common/className";

import Collapsible from "../../../ui-components/collapsible/collapsible";
import Stop from "./stop";

import "./manifestDetails.scss";
import Tag from "../../../ui-components/molecules/tag/tag";
import { TagColor } from "../../../app/data/common/tagColor";

export interface PickupStopProps {
  pickup: PickupProfile,
} 

const PickupStop: React.FC<PickupStopProps> = ({ pickup }) => {
  const statusCssClass = (status: string) => status === PickupStatus.COMPLETED ? "complete" : "in-progress";
  
  const statusColor = (status: string) => status === PickupStatus.COMPLETED ? TagColor.GREEN : TagColor.YELLOW;

  const packageTypeColor = (packageType: string): TagColor => {
    switch (packageType) {
      case "ROLL": return TagColor.BLUE;

      case "PALLET": return TagColor.PURPLE;

      default: return TagColor.GREY;
    }
  }  

  const pickupStatus = (status: string) => status === PickupStatus.COMPLETED ? STOP_STATUSES.INTERNAL.COMPLETED : STOP_STATUSES.INTERNAL.INPROGRESS;

  return (
    <Stop
      name="Pickup"
      address={formatAddress(pickup.shipper.address)}
      status={pickupStatus(pickup.pickupStatus)}
      mods={{ color: statusColor(pickup.pickupStatus) }}
    >
      <Collapsible
        key={pickup.pickupNumber}
        mix={cn("manifest-details__stop__collapsible")({status: statusCssClass(pickup.pickupStatus)})}
        header={(
          <div className="manifest-details__stop__card-content">
            <h4 className="manifest-details__stop__card-name">{pickup.consignee.name}</h4>
            <div className="manifest-details__stop__card-info">
              <span className="manifest-details__stop__card-info-item">{pickup.pickupNumber}</span>
              {!!pickup.probillNumber && (<span className="manifest-details__stop__card-info-item">
                <a href={"/shipments/" + pickup.probillNumber} target="_blank" rel="noopener noreferrer"
                  className="probill-number">{pickup.probillNumber}</a>
              </span>)}
              <span className="manifest-details__stop__card-info-item">Items: <b>{pickup.items.length}</b></span>
            </div>
          </div>         
        )}
      >
        <div className="manifest-details__stop__list">
          {pickup.items.map(item => (
            <div className="manifest-details__stop__list-item">
              <span className="manifest-details__stop__card-info-item">
                <Tag mods={{ color: packageTypeColor(item.packageType) }}>{item.packageType}</Tag>
              </span>
              <span className="manifest-details__stop__card-info-item">
                Units: <b>{item.units}</b>
              </span>
              <span className="manifest-details__stop__card-info-item">
                Weight: <b>{item.weight} lb</b>
              </span>
              {!!item.ratingClass && <span className="manifest-details__stop__card-info-item">
                Class: <b>{item.ratingClass}</b>
              </span>}
            </div>
          ))}
        </div>
      </Collapsible>
    </Stop>
  )
}

export default PickupStop;