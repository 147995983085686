import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import ReportsService from "../../app/data/reports/reportsService";
import { initialReportsState } from "./ReportsState";
import { ReportModel  } from "../../app/data/reports/models";

const reportsService = ReportsService.getInstance();

export const reportsSlice = createSlice({
  name: "reports",
  initialState: initialReportsState,
  reducers: {
    resetReportsState: (state) => initialReportsState,
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestError = null;
      state.requestCreator = payload;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = true;
      state.requestSucceed = false;
      state.requestError = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestFailed = false;
      state.requestSucceed = true;
      state.requestError = null;
    },
    storeReports: (state, { payload }) => {
      state.reports = payload;
    }
  }
});

export const {
  resetReportsState,
  requestStarted,
  requestFailed,
  requestSucceed,
  storeReports
} = reportsSlice.actions;

export const reportsSelector = (state: IState) => {
  return state.reports;
};

export const getReports = (
  subAccountId: string,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("list"));
  const response = await reportsService.getReports(subAccountId);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(storeReports(response.data));
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const createReport = (
  subAccountId: string,
  report: ReportModel,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("save"));
  const response = await reportsService.createReport(subAccountId, report);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const updateReport = (
  subAccountId: string,
  report: ReportModel,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("save"));
  const response = await reportsService.updateReport(subAccountId, report);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const deleteReport = (
  subAccountId: string,
  reportId: string,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("delete"));
  const response = await reportsService.deleteReport(subAccountId, reportId);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const runReport = (
  subAccountId: string,
  reportId: string,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("run"));
  const response = await reportsService.runReport(subAccountId, reportId);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const unsubscribeReport = (
  reportId: string,
  email: string,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("UNSUBSCRIBE"));
  const response = await reportsService.unsubscribeReport(reportId, email);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

const reportsReducer = reportsSlice.reducer;
export default reportsReducer;
