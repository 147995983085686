import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import AppointmentService from "../../app/data/appointments/appointmentService";
import { initialAppointmentState } from "./appointmentsState";
import { AppointmentRequest } from "../../app/data/appointments/models";
import { IState } from "..";

const appointmentService = AppointmentService.getInstance();

export const appointmentSlice = createSlice({
  name: "appointment",
  initialState: initialAppointmentState,
  reducers: {
    resetAppointmentState: (state) => initialAppointmentState,
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestCreator = payload;
      state.error = "";
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
      state.requestFailed = false;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.error = payload;
    },
    fetchConsigneeStarted: (state) => {
      state.fetchConsigneeStarted = true;
      state.fetchConsigneeSucceed = false;
      state.fetchConsigneeFailed = false;
      state.fetchConsigneeError = "";
    },
    fetchConsigneeSucceed: (state) => {
      state.fetchConsigneeSucceed = true;
      state.fetchConsigneeStarted = false;
      state.fetchConsigneeFailed = false;
    },
    fetchConsigneeFailed: (state, { payload }) => {
      state.fetchConsigneeFailed = true;
      state.fetchConsigneeSucceed = false;
      state.fetchConsigneeStarted = false;
      state.fetchConsigneeError = payload;
    },
    fetchAppointmentStarted: (state) => {
      state.fetchAppointmentStarted = true;
      state.fetchAppointmentSucceed = false;
      state.fetchAppointmentFailed = false;
      state.fetchAppointmentError = "";
    },
    fetchAppointmentSucceed: (state) => {
      state.fetchAppointmentSucceed = true;
      state.fetchAppointmentStarted = false;
      state.fetchAppointmentFailed = false;
    },
    fetchAppointmentFailed: (state, { payload }) => {
      state.fetchAppointmentFailed = true;
      state.fetchAppointmentSucceed = false;
      state.fetchAppointmentStarted = false;
      state.fetchAppointmentError = payload;
    },
    setCurrentAppointment: (state, { payload }) => {
      state.currentAppointment = payload;
    },
    setCurrentAppointmentContact: (state, { payload }) => {
      state.currentContact = payload;
    },
    setConsigneeContacts: (state, { payload }) => {
      state.contacts = payload;
    },
    setConsigneeInfo: (state, {payload}) => {
      state.consigneeInfo = payload;
    },
    addConsigneeContactLocally: (state, { payload }) => {
      state.contacts.push(payload);
    },
    setExistingAppointment: (state, { payload }) => {
      state.existingAppointment = payload;
    }
  }
});

export const {
  requestStarted,
  requestSucceed,
  requestFailed,
  fetchConsigneeStarted,
  fetchConsigneeSucceed,
  fetchConsigneeFailed,
  setCurrentAppointment,
  setConsigneeContacts,
  setConsigneeInfo,
  addConsigneeContactLocally,
  resetAppointmentState,
  setCurrentAppointmentContact,
  fetchAppointmentFailed,
  fetchAppointmentStarted,
  fetchAppointmentSucceed,
  setExistingAppointment
} = appointmentSlice.actions;

export const appointmentSelector = (state: IState) => state.appointment;

export const createAppointment = (request: AppointmentRequest, onSuccess: any, onError: any): AppThunk => async (dispatch) => {
  dispatch(requestStarted("create"));
  const response = await appointmentService.createAppointment(request);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess();
  } else {
    dispatch(requestFailed(response.getError()));
    onError(response.getError());
  }
};

export const getConsigneeContacts = (probillNumber: number, consigneeNumber: number): AppThunk => async (dispatch) => {
  dispatch(fetchConsigneeStarted());
  const response = await appointmentService.getConsigneeContacts(probillNumber, consigneeNumber);
  if (response.ok()) {
    dispatch(setConsigneeContacts(response.data.contacts));
    dispatch(setConsigneeInfo(response.data.consigneeInfo));
    dispatch(fetchConsigneeSucceed());
  } else {
    response.getError && dispatch(fetchConsigneeFailed(response.getError()));
  }
};

export const getAppointment = (probillNumber: number): AppThunk => async (dispatch) => {
  dispatch(fetchAppointmentStarted());
  const response = await appointmentService.getAppointment(probillNumber);
  if (response.ok()) {
    dispatch(setExistingAppointment(response.data))
    dispatch(fetchAppointmentSucceed());
  } else {
    if (response.status !== 404 ) {
      response.getError && dispatch(fetchAppointmentFailed(response.getError()));
    } else {
      dispatch(fetchAppointmentSucceed());
    }
  }
}

const appointmentReducer = appointmentSlice.reducer;
export default appointmentReducer;
