
export default interface PortalRegistrationState {
  request_started: boolean;
  request_failed: boolean;
  request_succeed: boolean;
  request_error: string | null;
  requestCreator: string;
  locations: any[];
}

export const initialPortalRegistrationState: PortalRegistrationState = {
  request_started: false,
  request_failed: false,
  request_succeed: false,
  request_error: null,
  requestCreator: "",
  locations: []
};
