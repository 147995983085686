import React, { useEffect, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import Helmet from "react-helmet";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import {
  LegacyResetPasswordSchema,
  ResetPasswordRequestModel,
  ResetPasswordSchema
} from "../../../app/data/user/requestModels";
import ResetPasswordState from "../../../slices/reset-password/ResetPasswordState";
import { useSelector, useDispatch } from "react-redux";
import {
  resetPasswordSelector,
  getLegacyUser,
  submitResetPassword,
} from "../../../slices/reset-password/resetPasswordSlice";
import LoginTitle from "../loginTitle";
import LoginWrapper from "../loginWrapper";
import LoginContent from "../loginContent";
import LoginButton from "../loginButton";
import { PathParams, ResetPasswordPath } from "./route";
import { useParams } from "react-router-dom";
import PageLoading from "../../page-loading/pageLoading";
import Loading from "../../../ui-components/loading/loading";
import Exclamation, {
  ExclamationTypes,
} from "../../../ui-components/molecules/exclamation/exclamation";
import "./resetPassword.scss";

const initialValues: ResetPasswordRequestModel = {
  password: "",
  confirmPassword: "",
  token: "",
};

let userInfoLoadedCache = false;
const ResetPassword: React.FC = () => {
  const [userInfoLoaded, setUserInfoLoaded] = useState(userInfoLoadedCache);
  const resetPasswordState: ResetPasswordState = useSelector(resetPasswordSelector);
  const dispatch = useDispatch();
  const params = useParams() as PathParams;

  useEffect(() => {
    if (!userInfoLoadedCache) {
      userInfoLoadedCache = true;
      setUserInfoLoaded(userInfoLoadedCache);
      const token = params[ResetPasswordPath.token];
      initialValues.token = token;
      dispatch(getLegacyUser(token));
    }
  }, [dispatch, params]);

  const onSubmit = async (data: ResetPasswordRequestModel) => {
    dispatch(submitResetPassword(data));
  };

  return (
    <LoginWrapper>
      <LoginContent>
        <Helmet title="Set New Password" />
        <PageLoading
          isLoading={resetPasswordState.loadingLegacyUser || !userInfoLoaded}
        />
        <LoginTitle>
          <span style={{ marginRight: 16 }}>Set New Password</span>
          <Loading isLoading={resetPasswordState.submitting} />
        </LoginTitle>
        {resetPasswordState.submit_error && (
          <XGSErrorMessage className="xgs-reset-password-failed-message">
            {resetPasswordState.loadingLegacyUserError}
          </XGSErrorMessage>
        )}
        {!resetPasswordState.loadingLegacyUserError &&
          !resetPasswordState.submit_is_Succeed &&
          resetPasswordState.legacyUser && (
            <Formik
              onSubmit={onSubmit}
              initialValues={initialValues}
              validationSchema={resetPasswordState.legacyUser.legacy ? LegacyResetPasswordSchema : ResetPasswordSchema}
            >
              {(props: FormikProps<ResetPasswordRequestModel>) => (
                <Form className="form-items">
                  <input type="hidden" name="token" />
                  <XGSFormInput
                    type="password"
                    name="password"
                    label="New password:"
                    required={true}
                    labelMode={LabelModes.column}
                  />
                  <XGSFormInput
                    type="password"
                    name="confirmPassword"
                    label="Repeat password:"
                    required={true}
                    labelMode={LabelModes.column}
                  />
                  <LoginButton
                    as={Button}
                    theme={ButtonThemes.blue}
                    type="submit"
                    disabled={resetPasswordState.submitting}
                  >
                    Save new password
                  </LoginButton>
                </Form>
              )}
            </Formik>
          )
        }

        {resetPasswordState.loadingLegacyUserError && (
          <div className="xgs-reset-password__result">
            <Exclamation type={ExclamationTypes.warning}>
              {resetPasswordState.loadingLegacyUserError}
            </Exclamation>
          </div>
        )}

        {resetPasswordState.submit_is_Succeed && (
          <div className="xgs-reset-password__result">
            <Exclamation type={ExclamationTypes.success}>
              Password for {resetPasswordState.legacyUser?.email} successfully changed.
            </Exclamation>
            <div className="xgs-site__box__link" style={{ textAlign: "center", marginTop: 24 }}>Go to <a href="/" className="blue-link">Log In</a></div>
          </div>
        )}
      </LoginContent>
    </LoginWrapper>
  );
};

export default ResetPassword;
