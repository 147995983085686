import api, { ApiResponse } from "../api";
import { TokenRequest } from "./TokenRequest";
import { TokenInfo } from "./TokenInfo";
import { PaymentResult } from "./PaymentResult";

class PaymentService {
  private static instance: PaymentService;
  private communicatorUrl = `${process.env.REACT_APP_DOMAIN}/paymentCallback.html`;

  private constructor() {}

  static getInstance(): PaymentService {
    if (!PaymentService.instance) {
      PaymentService.instance = new PaymentService();
    }

    return PaymentService.instance;
  }

  public getPaymentInfo = async (
    requestModel: TokenRequest
  ): Promise<ApiResponse<TokenInfo>> => {
    requestModel.hostedPaymentIFrameCommunicatorUrl = this.communicatorUrl;
    // requestModel.continueUrl = `${process.env.REACT_APP_API_BASE_URL}/payments/processResponse`;
    // requestModel.cancelUrl = `${process.env.REACT_APP_API_BASE_URL}/payments/processResponse`;
    return await api.post("/payments/token", requestModel);
  };

  public savePayment = async (
    requestModel: PaymentResult
  ): Promise<ApiResponse<TokenInfo>> => {
    return await api.post("/payments/processResponse", requestModel);
  };
}

export default PaymentService;
