import React, { useEffect, useState, useRef } from "react";
import "./map.scss";

import { Coordinates } from "../../app/data/map/models";

export interface XgsMapProps extends google.maps.MapOptions {
  center?: Coordinates;
  fitBounds?: boolean,
  points?: Coordinates[],
  children?: React.ReactNode; 
}

const XGSMap: React.FC<XgsMapProps> = ({ center, points, fitBounds, children, ...props }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  const mapId = () => Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {
        zoom: 12,
        mapId: `xgs-map-${mapId}`
      }));
    }
  }, [ref, map, center]);

  useEffect(() => {
    if (!map || !center) return;
    map.setCenter({lat: center.lat, lng: center.lng})
  }, [map, center]);

  useEffect(() => {
    if (!map) return;
    map.setOptions(props);
  }, [map, props]);

  useEffect(() => {
    if (!map || !fitBounds || !points?.length) return;

    const bounds = new window.google.maps.LatLngBounds();

    points.forEach(point => bounds.extend(new google.maps.LatLng(point)))

    //center the map to the geometric center of all points
    map.setCenter(bounds.getCenter());

    map.fitBounds(bounds);

  }, [map, points, fitBounds]);

  return (
    <>
      <div ref={ref} className="xgs-map" />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

export default XGSMap;
