import React from "react";
import { useSelector} from "react-redux";
import Table from "../../../ui-components/table/table";
import DateCell from "../../../ui-components/table/custom-cells/date-cell/dateCell";
import { exceptionSelector } from "../../../slices/exceptions/exceptionsSlice";

const ShipmentExceptions: React.FC<{probillNumber: number}> = (props) => {
  const exceptionState = useSelector(exceptionSelector);

  const columns = [
    {
      Header: "Date",
      accessor: "addedDate",
      width: 80,
      Cell: (cellProps: any) => <DateCell date={cellProps.value} />
    },
    {
      Header: "Terminal",
      accessor: "terminalNumber",
      width: 80
    },
    {
      Header: "Type",
      accessor: "type",
      width: 120
    },
    {
      Header: "Started",
      accessor: "startedDate",
      width: 90,
      Cell: (cellProps: any) => <DateCell date={cellProps.value} />
    },
    {
      Header: "Resolved",
      accessor: "resolvedDate",
      width: 90,
      Cell: (cellProps: any) => <DateCell date={cellProps.value} />
    },
    {
      Header: "Roll",
      accessor: "itemSerial",
      width: 130
    },
    {
      Header: "Description (limited to 60 characters)",
      accessor: "description",
      width: 350
    }
  ]

  const calcMinTableHeight = () => {
    if (!exceptionState.exceptions) return;
    if (exceptionState.exceptions.length === 2) return 90;
    if (exceptionState.exceptions.length === 3) return 135;
    if (exceptionState.exceptions.length === 4) return 180;
    if (exceptionState.exceptions.length === 5) return 225;
    return (exceptionState.exceptions.length > 5) ? 240 : 80;
  };

  return (
    <>
      <div className="xgs-shipment-details__dock-log">
        <Table
          columns={columns}
          data={exceptionState.exceptions}
          infiniteScroll={true}
          infiniteScrollLoading={false}
          infiniteScrollHasNext={false}
          onInfiniteScroll={() => {}}
          isLoading={exceptionState.loadingExceptions}
          rowHeight={45}
          minTableHeight={calcMinTableHeight()}
          strictMinTableHeight={true}
        />
      </div>
    </>
  );
};

export default ShipmentExceptions;
