import { AppThunk } from '../../app/store'
import { IState } from '../index'
import { createSlice } from '@reduxjs/toolkit'
import { initialChangeUserPasswordState } from './ChangeUserPasswordState'
import UserService from '../../app/data/user/userService'
import { UserPasswordChangeRequestModel } from '../../app/data/user/requestModels'

const userService = UserService.getInstance();

export const changeUserPasswordSlice = createSlice({
  name: "updateUserPassword",
  initialState: initialChangeUserPasswordState,
  reducers: {
    resetChangeUserPassword: state => initialChangeUserPasswordState,
    changeUserPassword_was_started: (state) => {
      state.started = true;
      state.failed = false;
      state.succeed = false;
    },
    changeUserPassword_was_succeed: (state) => {
      state.started = false;
      state.failed = false;
      state.succeed = true;
    },
    changeUserPassword_was_failed: (state, { payload }) => {
      state.started = false;
      state.failed = true;
      state.succeed = false;
      state.failReason = payload;
    }
  }
});

export const {
  resetChangeUserPassword,
  changeUserPassword_was_started,
  changeUserPassword_was_succeed,
  changeUserPassword_was_failed
} = changeUserPasswordSlice.actions;

export const changeUserPasswordSelector = (state: IState) => state.changeUserPassword;


export const changeUserPassword = (
  requestModel: UserPasswordChangeRequestModel,
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(changeUserPassword_was_started());
  const response = await userService.changeUserPassword(requestModel);
  if (response.ok()) {
    dispatch(changeUserPassword_was_succeed());
    onSuccess && onSuccess();
  } else {
    dispatch(changeUserPassword_was_failed(response.getError ? response.getError() : "Error"));
  }
};

const changeUserPasswordReducer = changeUserPasswordSlice.reducer;
export default changeUserPasswordReducer;
