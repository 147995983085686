import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import Helmet from "react-helmet";
import AddressLookup from "../../../ui-components/address-lookup/addressLookup";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import XGSFormPhoneInput from "../../../ui-components/form/phoneInput/xgsFormPhoneInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import PortalRegistrationState from "../../../slices/portal-registration/PortalRegistrationState";
import {
  portalRegistrationSelector,
  resetPortalRegistrationState,
  signup
} from "../../../slices/portal-registration/portalRegistrationSlice";
import {
  PortalRegistrationModel,
  PortalRegistrationSchema
} from "../../../app/data/user/requestModels";
import "../../../sass/forms.scss";
import "./portalRegistration.scss";

let initialValues: PortalRegistrationModel = {
  firstName: "",
  lastName: "",
  title: "",
  email: "",
  confirmEmail: "",
  accountNumber: "",
  companyName: "",
  companyAddress: {
    address1: "",
    address2: "",
    city: "",
    state: "",
    postalCode: ""
  },
  officePhone: "",
  mobilePhone: ""
};

const PortalRegistration: React.FC = () => {  
  const dispatch = useDispatch();
  const portalRegistrationState: PortalRegistrationState = useSelector(portalRegistrationSelector);  
  const [officePhoneValue, setOfficePhoneValue] = useState<string>("");
  const [mobilePhoneValue, setMobilePhoneValue] = useState<string>("");
  
  const onSubmit = async (data: PortalRegistrationModel) => {
    let request = {...data} as Partial<PortalRegistrationModel>;
    delete request.confirmEmail;
    dispatch(signup(data));
  };

  useEffect(() => {
    dispatch(resetPortalRegistrationState());
  }, [dispatch]);

  return (
    <div className="xgs-public-form xgs-portal-registration">
      <Helmet title="Portal Registration" />
      <div className="xgs-public-form__form xgs-public-form__form--wide">
        {!(portalRegistrationState.request_succeed && portalRegistrationState.requestCreator === "SUBMIT") && (
          <>
            <div className="xgs-public-form__title">Portal Registration</div>
            <Formik
              onSubmit={onSubmit}
              initialValues={initialValues}
              validationSchema={PortalRegistrationSchema}
              enableReinitialize
            >
              {(props: FormikProps<PortalRegistrationModel>) => (
                <Form className="form-items">
                  <div className="xgs-public-form__form__section">
                    <div className="xgs-public-form__form__section__header">Personal information</div>
                    <XGSFormInput
                      type="text"
                      name="firstName"
                      label="First Name:"
                      required={true}
                      labelMode={LabelModes.row}
                    />
                    <XGSFormInput
                      type="text"
                      name="lastName"
                      label="Last Name:"
                      required={true}
                      labelMode={LabelModes.row}
                    />
                    <XGSFormInput
                      type="text"
                      name="title"
                      label="Title:"
                      required={true}
                      labelMode={LabelModes.row}
                    />
                    <XGSFormInput
                      type="text"
                      name="email"
                      label="Email:"
                      required={true}
                      labelMode={LabelModes.row}
                    />
                    <XGSFormInput
                      type="text"
                      name="confirmEmail"
                      label="Confirm Email:"
                      required={true}
                      labelMode={LabelModes.row}
                    />
                  </div>
                  <div className="xgs-public-form__form__section">
                    <div className="xgs-public-form__form__section__header">Company information</div>
                    <XGSFormInput
                      type="text"
                      name="accountNumber"
                      label="Account Number:"
                      required={true}
                      requiredAsteriskDisabled={true}
                      labelMode={LabelModes.row}
                    />
                    <XGSFormInput
                      type="text"
                      name="companyName"
                      label="Company Name:"
                      required={true}
                      labelMode={LabelModes.row}
                    />
                    <AddressLookup
                      label="Company Address:"
                      labelMode={LabelModes.row}
                      required={true}
                      onValueChange={(val) => {
                        props.setFieldValue("companyAddress.address1", val.address);
                        props.setFieldValue("companyAddress.city", val.city);
                        props.setFieldValue("companyAddress.state", val.state);
                        props.setFieldValue("companyAddress.postalCode", val.zip);
                        setTimeout(() => {
                          props.setFieldTouched("companyAddress.address1", true, true);
                        }, 100);
                      }}
                      onBlur={() => {
                        props.setFieldTouched("companyAddress.address1", true, true);
                      }}
                    />
                    {props.values.companyAddress.address1 && (
                      <div className="xgs-portal-registration__address-info">
                        <div><strong>Address:</strong> {props.values.companyAddress.address1}</div>
                        <div><strong>City:</strong> {props.values.companyAddress.city}</div>
                        <div><strong>State:</strong> {props.values.companyAddress.state}</div>
                        <div><strong>Zip:</strong> {props.values.companyAddress.postalCode}</div>
                      </div>
                    )}
                    <XGSFormInput
                      type="text"
                      name="companyAddress.address2"
                      label="Suite:"
                      required={false}
                      requiredAsteriskDisabled={true}
                      labelMode={LabelModes.row}
                    />
                  </div>
                  <div className="xgs-public-form__form__section">
                    <div className="xgs-public-form__form__section__header">Contact information</div>
                    <XGSFormPhoneInput
                      name="officePhone"
                      label="Office Phone:"
                      labelMode={LabelModes.row}
                      onValueChange={(value) => {
                        props.setFieldValue("officePhone", value);
                        setOfficePhoneValue(value);
                      }}
                      onBlur={props.handleBlur}
                      value={officePhoneValue}
                      required={true}
                    />
                    <XGSFormPhoneInput
                      name="mobilePhone"
                      label="Mobile Phone:"
                      labelMode={LabelModes.row}
                      onValueChange={(value) => {
                        props.setFieldValue("mobilePhone", value);
                        setMobilePhoneValue(value);
                      }}
                      onBlur={props.handleBlur}
                      value={mobilePhoneValue}
                      required={true}
                    />
                    <div className="xgs-form__field__notes">
                      <strong>Note:</strong> by providing a telephone number and submitting this form you are consenting to be contacted by SMS text message.
                      Message &amp; data rates may apply. You can reply STOP to opt-out of further messaging.
                    </div>
                  </div>
                  {(portalRegistrationState.request_failed && portalRegistrationState.requestCreator === "SUBMIT") && (
                    <XGSErrorMessage className="xgs-public-form__error">
                      {portalRegistrationState.request_error && (<>{portalRegistrationState.request_error}</>)}
                    </XGSErrorMessage>
                  )}
                  <div className="xgs-public-form__form__submit">
                    <Button
                      theme={ButtonThemes.blue}
                      spinner={portalRegistrationState.request_started && portalRegistrationState.requestCreator === "SUBMIT"}
                      disabled={!props.isValid || !props.dirty}
                      type="submit"
                    >
                      Sign up
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
      </div>
      {portalRegistrationState.request_succeed && portalRegistrationState.requestCreator === "SUBMIT" && (
        <div className="xgs-public-form__success">
          <h3>The registration request has&nbsp;been&nbsp;sent.</h3>
          We will review the information soon and send you an email with an invitation if everything is in order.
        </div>
      )}
      <div className="xgs-site__box__link">Go back to <a href="/" className="blue-link">Log In</a></div>
    </div>
  );
};

export default PortalRegistration;
