import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import RoutesService from "../../../app/data/plan-probills/routes/routesService";
import { RequestRouteOptimizationState } from "./routesState";
import { getActiveRouteAndStops } from "./activeRoutesSlice";

const initialState: RequestRouteOptimizationState = {
  loading: false,
  loadingFailed: false,
  loaded: false,
  error: ""
};

const routesService = RoutesService.getInstance();

export const requestRouteOptimizationSlice = createSlice({
  name: "requestRouteOptimization",
  initialState,
  reducers: {
    resetRequestRouteOptimizationState: (state) => initialState,
    requestRouteOptimizationloadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
      state.error = "";
    },
    requestRouteOptimizationloadingSucceed: (state) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
      state.error = "";
    },
    requestRouteOptimizationloadingFailed: (state, { payload }) => {
      state.loading = false;
      state.loadingFailed = true;
      state.error = payload;
    }
  },
});

export const {
  resetRequestRouteOptimizationState,
  requestRouteOptimizationloadingStarted,
  requestRouteOptimizationloadingSucceed,
  requestRouteOptimizationloadingFailed
} = requestRouteOptimizationSlice.actions;

export const requestRouteOptimizationSelector = (state: IState) => state.requestRouteOptimization;

export const requestRouteOptimization = (
  reqData: any,
  onSuccess?: () => void,
  onError?: (error: string) => void
): AppThunk => async (dispatch) => {
  dispatch(requestRouteOptimizationloadingStarted());
  const response = await routesService.requestRouteOptimization({ ...reqData });
  if (response.ok() && response.data && response.data.result) {
    dispatch(requestRouteOptimizationloadingSucceed());
    dispatch(getActiveRouteAndStops({ ...reqData}));
    onSuccess && onSuccess();
  } else {
    let error = "Internal Server Error, re-try the action or contact support";
    if (response && response.data && response.data.error) error = response.data.error;
    dispatch(requestRouteOptimizationloadingFailed(error));
    onError && onError(error)
  }
};

const requestRouteOptimizationReducer = requestRouteOptimizationSlice.reducer;
export default requestRouteOptimizationReducer;