import React from "react";

export const getLaneManagementColumns = () => [
  {
    Header: "Lane name",
    accessor: "laneName",
    width: 170,
  },
  {
    Header: "Origin",
    accessor: "origin",
    width: 140,
    Cell: (cellProps: any) => (
      <div className="xgs-lmi__trips__address-cell">
        {cellProps.row.original.originTerminalAirportcode} - {cellProps.row.original.originTerminalName} <span>{cellProps.row.original.originTerminalNumber}</span>
      </div>
    )
  },
  {
    Header: "Destination",
    accessor: "destination",
    width: 140,
    Cell: (cellProps: any) => (
      <div className="xgs-lmi__trips__address-cell">
        {cellProps.row.original.destinationTerminalAirportcode} - {cellProps.row.original.destinationTerminalName} <span>{cellProps.row.original.destinationTerminalNumber}</span>
      </div>
    )
  },
  {
    Header: "Relay",
    accessor: "relayCity",
    width: 260,
    Cell: (cellProps: any) => (
      <>
        {
          [cellProps.row.original.relayCity, cellProps.row.original.relayState, cellProps.row.original.relayZipcode]
            .filter(val => !!val)
            .join(", ")
        }
      </>
    )
  },
  {
    Header: "Miles",
    accessor: "miles",
    width: 105,
  },
  {
    Header: "Notes",
    accessor: "laneNotes",
    width: 253,
  }
]