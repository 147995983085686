import api, { ApiResponse } from "../../api2";

class RoutesService {
  private source: any;
  private static instance: RoutesService;

  private constructor() { }

  static getInstance(): RoutesService {
    if (!RoutesService.instance) {
      RoutesService.instance = new RoutesService();
    }
    return RoutesService.instance;
  }

  public getActiveRoutes = async (
    rawRequestModel: any
  ): Promise<ApiResponse<any>> => {
    return await api.post('/tms/route/optimization/get-active-routes', rawRequestModel, {
    });
  };

  public getRouteStops = async (
    rawRequestModel: any
  ): Promise<ApiResponse<any>> => {
    return await api.post('/tms/route/optimization/get-route-stops', rawRequestModel, {
    });
  };

  public updateRoute = async (
    rawRequestModel: any
  ): Promise<ApiResponse<any>> => {
    return await api.post('/tms/route/optimization/update', rawRequestModel, {
    });
  };

  public addProbillsToRoute = async (
    rawRequestModel: any
  ): Promise<ApiResponse<any>> => {
    return await api.post('/tms/route/optimization/add/probill', rawRequestModel, {
    });
  };

  public addStopsToRoute = async (
    rawRequestModel: any
  ): Promise<ApiResponse<any>> => {
    return await api.post('/tms/route/optimization/add/stops', rawRequestModel, {
    });
  };

  public removeProbillFromRoute = async (
    rawRequestModel: any
  ): Promise<ApiResponse<any>> => {
    return await api.post('/tms/route/optimization/remove/probill', rawRequestModel, {
    });
  };

  public removeStopFromRoute = async (
    rawRequestModel: any
  ): Promise<ApiResponse<any>> => {
    return await api.post('/tms/route/optimization/remove/stop', rawRequestModel, {
    });
  };

  public requestRouteOptimization = async (
    rawRequestModel: any
  ): Promise<ApiResponse<any>> => {
    return await api.post('/tms/route/optimization/request', rawRequestModel, {
    });
  };

  public createPicklistFromRoute = async (
    rawRequestModel: any
  ): Promise<ApiResponse<any>> => {
    return await api.post('/tms/route/optimization/picklist/create', rawRequestModel, {
    });
  };

  public deleteRoute = async (
    rawRequestModel: any
  ): Promise<ApiResponse<any>> => {
    return await api.post('/tms/route/optimization/delete/route', rawRequestModel, {
    });
  };
}

export default RoutesService;