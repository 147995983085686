import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";

export interface LoadingProps {
  className?: string;
  isLoading?: boolean;
}

const Loading: React.FC<LoadingProps> = (props) => {
  return (
    <>
      {props.isLoading && (
        <FontAwesomeIcon
          icon={Icons.faSpinner}
          size="lg"
          spin={true}
          className={`xgs-loading ${props.className ? props.className : ""}`}
        />
      )}
    </>
  );
}

export default Loading;