import React from "react";
import "./stepsMilestones.scss";

interface StepsMilestonesProps {
  steps: stepsMilestonesDataType[];
  step: any;
  push: (id: string) => void;
}

export interface stepsMilestonesDataType {
  id: string;
  name: string;
  data: React.ReactNode | null;
  hidden?: boolean;
}

const StepsMilestones: React.FC<StepsMilestonesProps> = (props) => {
  const isPreviousStep = (checkStep: string, currentStep: string) => {
    const checkStepIndex = props.steps.findIndex((step: stepsMilestonesDataType) => step.id === checkStep);
    const currentStepIndex = props.steps.findIndex((step: stepsMilestonesDataType) => step.id === currentStep);
    return checkStepIndex < currentStepIndex;
  };

  return (
    <div className="xgs-steps-milestones">
      {props.steps.map((step: stepsMilestonesDataType, index: number) => (
        <>
          {!step.hidden && (
            <div
              className={`xgs-steps-milestones__item${props.step.id === step.id ? " xgs-steps-milestones__item--current" : ""}${isPreviousStep(step.id, props.step.id) ? " xgs-steps-milestones__item--previous" : ""}`}
              key={index}
            >
              <div className="xgs-steps-milestones__item__columns">
                <div className="xgs-steps-milestones__item__circle"></div>
                <div className="xgs-steps-milestones__item__name">
                  {isPreviousStep(step.id, props.step.id)
                    ? (
                      <span
                        className="xgs-steps-milestones__item__name--link"
                        onClick={() => {props.push(step.id)}}>
                        {step.name}
                      </span>
                    )
                    : (<>{step.name}</>)
                  }
                </div>
              </div>
              {(index + 1) <= props.steps.length && (
                <div className="xgs-steps-milestones__item__columns">
                  <div className={`xgs-steps-milestones__item__line ${((index + 1) === props.steps.length) ? " xgs-steps-milestones__item__line--invisible" : ""}`}></div>
                  {step.data && (
                    <div className={`xgs-steps-milestones__item__data ${((index + 1) === props.steps.length) ? " xgs-steps-milestones__item__data--last" : ""}`}>
                      {step.data}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default StepsMilestones;
