import React from "react";
import CustomCell, { CustomCellTypes } from "../custom-cells/customCell";
import "./tableTotal.scss";

export interface BaseTableTotalProps {
  title?: string | undefined;
  type: CustomCellTypes;
  value: any;
}

export interface TableTotalProps {
  totals: BaseTableTotalProps[];
  data: any[];
}

const TableTotal: React.FC<TableTotalProps> = ({
  totals,
  data
}) => {
  return (
    <div className="xgs-table__totals" style={{ textTransform: "uppercase", textAlign: "right" }}>
      {totals.map((total: BaseTableTotalProps, index: number) => (
        <div className="xgs-table__totals__row" key={index}>
          {total.title && (
            <div className="xgs-table__totals__title">
              {total.title}:
            </div>
          )}
          <div className="xgs-table__totals__value">
            <CustomCell data={data} value={total.value} cellType={total.type} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default TableTotal;
