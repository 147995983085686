import axios, { CancelTokenSource } from "axios";
import api, { ApiResponse } from "./../api";
import { AppointmentRequest } from "./models";

class AppointmentService {
  private static instance: AppointmentService;
  private getConsigneeCancelToken: CancelTokenSource | null = null;
  private getAppointmentCancelToken: CancelTokenSource | null = null;

  private constructor() {}
  static getInstance(): AppointmentService {
    if (!AppointmentService.instance) {
      AppointmentService.instance = new AppointmentService();
    }
    return AppointmentService.instance;
  };

  public createAppointment = async (request: AppointmentRequest): Promise<ApiResponse<any>> => {
    return await api.post("/appointments", request)
  }

  public getConsigneeContacts = async (probillNumber: number, consigneeNumber: number): Promise<ApiResponse<any>> => {
    this.getConsigneeCancelToken && this.getConsigneeCancelToken.cancel();
    this.getConsigneeCancelToken = axios.CancelToken.source();
    return await api.get(`/appointments/${probillNumber}/contacts/${consigneeNumber}`, {
      cancelToken: this.getConsigneeCancelToken.token
    });
  }

  public getAppointment = async (probillNumber: number): Promise<ApiResponse<any>> => {
    this.getAppointmentCancelToken && this.getAppointmentCancelToken.cancel();
    this.getAppointmentCancelToken = axios.CancelToken.source();
    return await api.get(`/appointments/${probillNumber}`, {
      cancelToken: this.getAppointmentCancelToken.token
    })
  }
};

export default AppointmentService;
