import React, { memo } from "react";
import XGSIcon from "../../../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../../../ui-components/icon/xgsIcons";
import { PlanningTipTypes } from "./planningTipTypes";
import './planningTip.scss';

interface PlanningTipPros {
    tipType: PlanningTipTypes,
    tipText: string
}

const planningTipsIconsMaps = {
    "attention": XGSIcons.faLightbulb,
    "ai": XGSIcons.faRobot,
    "alert":  XGSIcons.faExclamationTriangle,
}
const PlanningTip: React.FC<PlanningTipPros> = memo((props) => {
    return (<>
        {(props && props.tipType && props.tipText) && <div
            className={`planning-tips-box planning-tip-type-${props.tipType}`}
        >
            <span className="planning-tip-icon">
                <XGSIcon icon={planningTipsIconsMaps[props.tipType]}>
                </XGSIcon>
            </span>
            {props.tipText || ""}
        </div>
        }
    </>
    );
}
);
export default PlanningTip;
