import api, { ApiResponse } from "../api";
import { TransitCalculatorModel } from "./models";

class TransitCalculatorService {
  private static instance: TransitCalculatorService;
  private constructor() {}

  static getInstance(): TransitCalculatorService {
    if (!TransitCalculatorService.instance) {
      TransitCalculatorService.instance = new TransitCalculatorService();
    }
    return TransitCalculatorService.instance;
  }

  public getTransitTime = async (
    request: TransitCalculatorModel
  ): Promise<ApiResponse<any>> => {
    return await api.get("/transit", {
      params: request
    });
  };
}

export default TransitCalculatorService;
