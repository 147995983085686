import moment from "moment";

export const HUMAN_FRIENDLY_ACTIONS = {
  "ASSIGNED": "Assigned to",
  "CANCELED": "Canceled",
  "COMPLETED": "Completed by",
  "CREATED": "Created",
  "EMAIL_SENT": "The email was sent to",
  "MISSED": "Became missed",
  "RESCHEDULED_FROM": "Rescheduled from",
  "RESCHEDULED_TO": "Rescheduled to",
  "UNASSIGNED": "Unassigned"
};

export const getDateClassName = (pickupDays, date: Date) => {
  if (!pickupDays) return;
  const dateDayOfWeek = moment(date).format("dd").toLowerCase();
  return pickupDays[dateDayOfWeek] === false ? "xgs-pickup__date__non-service-day" : "";
};
