import React, { memo, useEffect } from "react";
import "./addProbills.scss";
import Table from "../../../../../ui-components/table/table";
import getPlanProbillsColumns from "../../../plan-probills/planProbillsColumns";
import { DispatchPlanProbillsState } from "../../../../../slices/dispatch-planning/dispatchProbillsState";
import { useDispatch, useSelector } from "react-redux";
import { dispatchPlanProbillsSelector, getPlannableProbills } from "../../../../../slices/dispatch-planning/dispatchPlanProbillsSlice";
import { PlannableProbills } from "../../../../../app/data/plan-probills/models";
import XGSIcon from "../../../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../../../ui-components/icon/xgsIcons";
import { addProbillsToRouteResetState } from "../../../../../slices/dispatch-planning/routes/addProbillsToRouteSlice";
import { setProbillsToAddToRoute } from "../../../../../slices/dispatch-planning/routes/probillsToAddToRouteSlice";
import AddProbillsHeader from "./addProbillsHeader";

interface AddProbillsProps {
    show: boolean;
    routeId: string;
    stopNumber: number;
    stopInternalId: number;
    onClose?: any;
    terminalNumber: string
}
const AddProbills: React.FC<AddProbillsProps> = memo((props) => {
    const dispatch = useDispatch();
    const dispatchPlanProbillsState: DispatchPlanProbillsState = useSelector(
        dispatchPlanProbillsSelector
    );
    // const probillsToAddToRouteState: ProbillsToAddToRouteState = useSelector(
    //     probillsToAddToRouteSelector
    // );
    const selectedProbillsChanged = React.useCallback(
        (state: {
            selectedRowsData: PlannableProbills[];
        }) => {
            const selectedProbill: PlannableProbills[] = state.selectedRowsData.map(
                (selectedRow) => selectedRow
            );
            dispatch(setProbillsToAddToRoute(selectedProbill));
            // setSelectedProbills(sele)
        },
        [dispatch]
    );

    useEffect(() => {
        dispatch(getPlannableProbills(props.terminalNumber));
        return () => {
            props.onClose(false);
            dispatch(addProbillsToRouteResetState());
        }
    }, [dispatch, props])
    return (
        <>
            {props.show &&
                <div className="add-probills-overlay">
                    <div className="add-probills-modal">
                        <div className="add-probills-modal-header">
                            <div className="add-probills-modal-header-titile">
                                Add Probills to Route-{props.routeId}, Stop-{props.stopNumber}, Terminal Number- {props.terminalNumber}
                                <AddProbillsHeader 
                                show= {props.show}
                                routeId= {props.routeId}
                                stopNumber= {props.stopNumber}
                                stopInternalId = {props.stopInternalId}
                                onClose = {props.onClose}
                                terminalNumber= {props.terminalNumber} />
                            </div>
                            <div
                                className="add-probills-modal-header-close"
                                onClick={() => { props.onClose(false) }}
                            >
                                <XGSIcon
                                    icon={XGSIcons.faTimesCircle} />
                            </div>
                        </div>
                        <Table
                            isLoading={dispatchPlanProbillsState.loading}
                            columns={getPlanProbillsColumns()}
                            data={dispatchPlanProbillsState.plannableProbills}
                            ignoreCellClick="selection"
                            cursorPointer={true}
                            onSelectedRowsChange={selectedProbillsChanged}
                            infiniteScroll={false}
                            responsive={true}
                        />
                    </div>
                </div>
            }
        </>
    );
});

export default AddProbills;