import React from "react";
import { AppointmentStats } from "./appointmentStats";
import LabeledDateRangeInput from "../../../../../ui-components/molecules/labeled-inputs/labeled-date-range-input/labeledDateRangeInput";
import { LabelModes } from "../../../../../ui-components/molecules/labeled-inputs/labeledInput";
import "./appointmentFilters.scss";
import Switch from "react-switch";
import Button, { ButtonSizes, ButtonThemes } from "../../../../../ui-components/button/button";
import { ReactComponent as ResetFilterIcon } from "./../../../../../images/reset-filters.svg"
import filter from "./../../../../../images/filter.svg"
import moment from "moment";

export interface AppointmentFiltersProps {
  etaFrom?: string;
  etaTo?: string;
  onEtaFromChanged: any;
  onEtaToChanged: any;
  onAppointmentPendingChanged: any;
  onConsigneeHomeDepotOnlyChanged: any;
  consigneeHomeDepotOnly: any;
  appointmentPending: boolean;
  fetchCSV: any;
  showAllFilters: () => void;
  showFiltersNotification: () => boolean;
  clearFilters: () => void;
}

export const AppointmentFilters: React.FC<AppointmentFiltersProps> = (props) => {

  return (
    <div className="xgs-appointment-filters">
      <div className="xgs-appointment-filters__top-row">
        <div className="xgs-appointment-filters__controls">
          <div>
            <LabeledDateRangeInput
              label="ETA range:"
              labelMode={LabelModes.column}
              start={props.etaFrom}
              end={props.etaTo}
              onStartChange={props.onEtaFromChanged}
              minDate={moment().subtract(1, "y").toDate()}
              onEndChange={props.onEtaToChanged}
            />
          </div>
          <Button className="xgs-appointment-filters__controls__search-button" theme={ButtonThemes.blue}>
            Search
          </Button>
        </div>
        <AppointmentStats onAppointmentPendingChanged={props.onAppointmentPendingChanged} />
      </div>
      <div className="xgs-appointment-filters__bottom-row">
        <div className="xgs-appointment-filters__bottom-row__filters">
          <div className="xgs-appointment-filters__item__switch">
            <Switch
              onChange={(checked) => props.onConsigneeHomeDepotOnlyChanged(checked)}
              checked={props.consigneeHomeDepotOnly}
              width={32}
              height={20}
              offColor="#BDBDBD"
              onColor="#2F80ED"
              uncheckedIcon={<></>}
              checkedIcon={<></>}
            />
            <span>Home Depot only</span>
          </div>
          <div className="xgs-appointment-filters__item__switch">
            <Switch
              onChange={(checked) => props.onAppointmentPendingChanged(checked)}
              checked={props.appointmentPending}
              offColor="#BDBDBD"
              onColor="#2F80ED"
              width={32}
              height={20}
              uncheckedIcon={<></>}
              checkedIcon={<></>}
            />
            <span>Appointment needed only</span>
          </div>
        </div>
        <div className="xgs-appointment-filters__bottom-row__buttons">
          <Button
            theme={ButtonThemes.transparent}
            size={ButtonSizes.auto}
            className={`xgs-appointment-filters__small-button`}
            onClick={() => props.clearFilters()}
            disabled={!props.showFiltersNotification()}
          >
            <ResetFilterIcon />
            Reset Filters
          </Button>
          <Button
            theme={ButtonThemes.transparent}
            size={ButtonSizes.auto}
            className="xgs-appointment-filters__small-button"
            onClick={props.showAllFilters}
          >
            <img src={filter} alt=""/> 
            All Filters
          </Button>
        </div>
      </div>
    </div>
  )
}