import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Wizard, Steps, Step } from "react-albus";
import ContentContainer from "../../../templates/content-container/contentContainer";
import ReturnCommonStep from "./steps/returnCommon";
import ReturnShipperStep from "./steps/returnShipper";
import ReturnConsigneeStep from "./steps/returnConsignee";
import ReturnItemsStep from "./steps/returnItems";
import ReturnRateQuoteStep from "./steps/returnRateQuote";
import ReturnCompletedStep from "./steps/returnCompleted";
import StepsMilestones, { stepsMilestonesDataType } from "../../../ui-components/steps-milestones/stepsMilestones";
import ReturnState from "../../../slices/return/ReturnState";
import {
  resetReturnState,
  returnSelector
} from "../../../slices/return/returnSlice";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import { UserUtils } from "../../../app/data/user/userUtils";
import {
  REQUESTOR_ROLES,
  RETURN_SERVICES
} from "../constants";
import "../bol.scss";

const initialFullStepsData: stepsMilestonesDataType[] = [
  {
    id: "return-common-step",
    name: "Common Information",
    data: null
  },
  {
    id: "return-shipper-step",
    name: "Pickup Location",
    data: null
  },
  {
    id: "return-consignee-step",
    name: "Return to Location",
    data: null
  },
  {
    id: "return-items-step",
    name: "Detail Information",
    data: null
  },
  {
    id: "return-rate-step",
    name: "Rate Quote",
    data: null
  },
  {
    id: "return-completed-step",
    name: "Request Created",
    data: null
  }
];

const initialStepsData: stepsMilestonesDataType[] = [
  {
    id: "return-common-step",
    name: "Common Information",
    data: null
  },
  {
    id: "return-shipper-step",
    name: "Pickup Location",
    data: null
  },
  {
    id: "return-consignee-step",
    name: "Return to Location",
    data: null
  },
  {
    id: "return-items-step",
    name: "Detail Information",
    data: null
  },
  {
    id: "return-completed-step",
    name: "Request Created",
    data: null
  }
];

const ReturnCreate: React.FC<{}> = (props) => {
  const dispatch = useDispatch();  
  const returnState: ReturnState = useSelector(returnSelector);
  const userState: UserState = useSelector(userSelector);
  const showRateQuote = useCallback(() => {
    return UserUtils.hasPermission(userState.profile || null, userState.activeSubAccount?.id || null, "RATE_QUOTE") 
      && !!(userState.profile?.paypointNumbers && userState.profile.paypointNumbers.length > 0);
  }, [userState.profile, userState.activeSubAccount]);
  const [stepsData, setStepsData] = useState<stepsMilestonesDataType[]>(showRateQuote() ? initialFullStepsData : initialStepsData);

  useEffect(() => {
    return () => {
      dispatch(resetReturnState());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!stepsData) return;
    let newStepsData = [...stepsData];
    let index = newStepsData.findIndex(step => step.id === "return-common-step");
    if (index === -1) return;
    newStepsData[index].data = returnState.returnCommon.referenceNumber
      ? (
          <>
            <div><span>Role:</span> {REQUESTOR_ROLES.find(role => role.value === returnState.returnCommon.requestorRole)?.label}</div>
            <div><span>Return Auth. #:</span> RTM{returnState.returnCommon.referenceNumber}</div>
            {returnState.referenceDocumentId && <div>Return Auth. Document provided</div>}
            {returnState.returnCommon.poNumber && <div><span>PO #:</span> {returnState.returnCommon.poNumber}</div>}
            {returnState.returnCommon.originalBolNumber && <div><span>Original BOL #:</span> {returnState.returnCommon.originalBolNumber}</div>}
          </>
        )
      : null;
    setStepsData([...newStepsData]);
    // eslint-disable-next-line
  }, [returnState.returnCommon, returnState.referenceDocumentId]);

  useEffect(() => {
    if (!stepsData) return;
    let newStepsData = [...stepsData];
    let index = newStepsData.findIndex(step => step.id === "return-shipper-step");
    let specialServicesArr = [];
    let specialServices = "";
    if (index === -1) return;
    for (let specialService of returnState.returnShipper.specialServices) {
      const serviceHumanReadable = RETURN_SERVICES.find(service => service.value === specialService)?.label;
      serviceHumanReadable && specialServicesArr.push(serviceHumanReadable);
    }
    if (specialServicesArr.length > 0) specialServices = specialServicesArr.join(", ");
    newStepsData[index].data = returnState.returnShipper.name
      ? (
        <>
          <div>{returnState.returnShipper.name}</div>
          <div>{returnState.returnShipper.address.address1}, {returnState.returnShipper.address.city}, {returnState.returnShipper.address.state}, {returnState.returnShipper.address.postalCode}</div>
          {returnState.returnShipper.address.additionalAddressLine && <div>{returnState.returnShipper.address.additionalAddressLine}</div>}
          <div>{returnState.returnShipper.phone}</div>
          <div>{returnState.returnShipper.email}</div>
          <div>{returnState.returnShipper.shipperAvailabilityHours.from} - {returnState.returnShipper.shipperAvailabilityHours.to}</div>
          {returnState.returnShipper.specialInstructions && <div><span>Special Instructions:</span> {returnState.returnShipper.specialInstructions}</div>}
          {specialServicesArr.length > 0 && <div><span>Special Services:</span> {specialServices}</div>}
          <div><span>Freight Available:</span> {returnState.returnShipper.pickupDate}</div>
        </>
      )
      : null;
    setStepsData([...newStepsData]);
    // eslint-disable-next-line
  }, [returnState.returnShipper]);

  useEffect(() => {
    if (!stepsData) return;
    let newStepsData = [...stepsData];
    let index = newStepsData.findIndex(step => step.id === "return-consignee-step");
    if (index === -1) return;
    newStepsData[index].data = returnState.returnConsignee.name
      ? (
        <>
          <div>{returnState.returnConsignee.name}</div>
          <div>{returnState.returnConsignee.address.address1}, {returnState.returnConsignee.address.city}, {returnState.returnConsignee.address.state}, {returnState.returnConsignee.address.postalCode}</div>
          {returnState.returnConsignee.address.additionalAddressLine && <div>{returnState.returnConsignee.address.additionalAddressLine}</div>}
          <div>{returnState.returnConsignee.phone}</div>
          {returnState.returnConsignee.email && <div>{returnState.returnConsignee.email}</div>}
        </>
      )
      : null;
    setStepsData([...newStepsData]);
    // eslint-disable-next-line
  }, [returnState.returnConsignee]);

  useEffect(() => {
    if (!stepsData) return;
    let newStepsData = [...stepsData];
    let index = newStepsData.findIndex(step => step.id === "return-items-step");
    if (index === -1) return;
    const itemsCount = returnState.items.filter(item => !item.deleted).length;
    newStepsData[index].data = itemsCount > 0
      ? (
        <>
          <div>{itemsCount} item{itemsCount !== 1 ? "s" : ""}</div>
        </>
      )
      : null;
    setStepsData([...newStepsData]);
    // eslint-disable-next-line
  }, [returnState.items]);  

  return (
    <ContentContainer title="Create Return">
      <div className="xgs-bol__create-wizard xgs-bol__create-wizard--return">
        <Wizard
          render={({ step, push }) => (
            <>
              <Steps>
                <Step
                  id="return-common-step"
                  render={({ next }) => (
                    <ReturnCommonStep next={next} />
                  )}
                />
                <Step
                  id="return-shipper-step"
                  render={({ next, previous}) => (
                    <ReturnShipperStep previous={previous} next={next} push={push} />
                  )}
                />
                <Step
                  id="return-consignee-step"
                  render={({ next, previous }) => (
                    <ReturnConsigneeStep previous={previous} next={next} />
                  )}
                />
                <Step
                  id="return-items-step"
                  render={({ next, previous }) => (
                    <ReturnItemsStep previous={previous} next={next} push={push} showRateQuote={showRateQuote()} />
                  )}
                />
                <Step
                  id="return-rate-step"
                  render={({ next, previous }) => (
                    <ReturnRateQuoteStep previous={previous} next={next} />
                  )}
                />
                <Step
                  id="return-completed-step"
                  render={({ previous, push }) => (
                    <ReturnCompletedStep previous={previous} push={push} />
                  )}
                />
              </Steps>
              {step.id !== "return-completed-step" &&
                stepsData && 
                <StepsMilestones steps={stepsData} step={step} push={push} />
              }
            </>
          )}
        />
      </div>
    </ContentContainer>
  );
};

export default ReturnCreate;
