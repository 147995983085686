import React from "react"

export interface LoadingTaskProps {
  name: string;
}

const LoadingTask: React.FC<LoadingTaskProps> = ({ name, children }) => {
  return (
    <article className="picklist-details__loading-task">
      <header className="picklist-details__loading-task__head">
        <h4 className="picklist-details__loading-task__address">
          <b>{name}</b>
        </h4>
        <span className="picklist-details__loading-task__head--line"></span>
      </header>

      <ul className="picklist-details__loading-task__body">
        {children}
      </ul>
    </article>
  )
}

export default LoadingTask