import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import CompanyService from "../../app/data/company/companyService";
import { initialTeamsState } from "./CompanyState";

const companyService = CompanyService.getInstance();

export const teamsSlice = createSlice({
  name: "teams",
  initialState: initialTeamsState,
  reducers: {
    teams_fetch_was_started: (state, { payload }) => {
      if (payload.portion) {
        state.teams_fetchPortion_was_started = true;
      } else {
        state.teams_fetch_was_started = true;
      }
      state.teamsSearchString = payload.searchString;
      state.teams_fetch_was_succeed = false;
      state.teams_fetch_was_failed = false;
      state.teams_fetch_fail_reason = null;
      state.teamsFetchedAll = false;
    },
    teams_fetch_was_succeed: (state) => {
      state.teams_fetch_was_succeed = true;
      state.teams_fetch_was_started = false;
      state.teams_fetchPortion_was_started = false;
      state.teams_fetch_was_failed = false;
    },
    set_teams: (state, { payload }) => {
      state.teams = payload.content;
      state.teamsScrollRequest = (payload.content.length < 50) ? null : payload.scroll;
      state.teamsFetchedAll = !!(payload.content.length < 50) || !!payload.property;
      state.as400Account = payload.property ? payload.property : null;
    },
    add_teams: (state, { payload }) => {
      state.teams = [...state.teams, ...payload.content];
      state.teamsScrollRequest = (payload.content.length < 50) ? null : payload.scroll;
      state.teamsFetchedAll = !!(payload.content.length < 50);
    },
    teams_fetch_was_failed: (state, { payload }) => {
      state.teams_fetch_was_failed = true;
      state.teams_fetch_was_succeed = false;
      state.teams_fetch_was_started = false;
      state.teams_fetch_fail_reason = payload;
    },
    set_teams_sorting: (state, { payload }) => {
      state.teamsSorting = payload;
    },
    updateTeamNameLocally: (state, { payload }) => {
      const i = state.teams.findIndex((team: any) => team.id === payload.teamId);
      if (i === -1) return;
      state.teams[i].name = payload.name;
    },
    decreaseTeamAccountsCount: (state, { payload }) => {
      const i = state.teams.findIndex((team: any) => team.id === payload);
      state.teams[i].accounts--;
    },
    increaseTeamAccountsCount: (state, { payload }) => {
      const i = state.teams.findIndex((team: any) => team.id === payload);
      state.teams[i].accounts++;
    },
    decreaseTeamUsersCount: (state, { payload }) => {
      const i = state.teams.findIndex((team: any) => team.id === payload);
      state.teams[i].users--;
    },
    increaseTeamUsersCount: (state, { payload }) => {
      const i = state.teams.findIndex((team: any) => team.id === payload);
      state.teams[i].users++;
    },
    accounts_fetch_was_started: (state, { payload }) => {
      if (payload) {
        state.accounts_fetchPortion_was_started = true;
      } else {
        state.accounts_fetch_was_started = true;
      }
      state.accounts_fetch_was_succeed = false;
      state.accounts_fetch_was_failed = false;
      state.accounts_fetch_fail_reason = null;
      state.accountsFetchedAll = false;
    },
    accounts_fetch_was_succeed: (state, { payload }) => {
      state.accounts_fetch_was_succeed = true;
      state.accounts_fetchPortion_was_started = false;
      state.accounts_fetch_was_failed = false;
      state.accounts_fetch_was_started = false;
      state.accountsSearchString = payload;
    },
    set_accounts: (state, { payload }) => {
      state.accounts = payload.content;
      state.accountsRequest = (payload.content.length < 50) ? null : payload.scroll;
      state.accountsFetchedAll = !!(payload.content.length < 50);
    },
    add_accounts: (state, { payload }) => {
      state.accounts = [...state.accounts, ...payload.content];
      state.accountsRequest = (payload.content.length < 50) ? null : payload.scroll;
      state.accountsFetchedAll = !!(payload.content.length < 50);
    },
    accounts_fetch_was_failed: (state, { payload }) => {
      state.accounts_fetch_was_failed = true;
      state.accounts_fetch_was_succeed = false;
      state.accounts_fetch_fail_reason = payload;
      state.accounts_fetch_was_started = false;
    },
    removeAccountFromTeamLocally: (state, { payload }) => {
      const i = state.accounts.findIndex((account: any) => account.id === payload);
      if (i === -1) return;
      state.accounts.splice(i, 1);
    },
    resetErrors: (state) => {
      state.teams_fetch_was_failed = false;
      state.teams_fetch_fail_reason = null;
      state.accounts_fetch_was_failed = false;
      state.accounts_fetch_fail_reason = null;
    },
    resetTeams: (state) => {
      state.teams = null;
      state.teams = [];
      state.teamsScrollRequest = null;
      state.teamsSearchString = "";
      state.teamsFetchedAll = false;
    },
    resetSorting: (state) => {
      state.teamsSorting = null;
    },
    resetAs400Account: (state) => {
      state.as400Account = null;
    }
  }
});

export const {
  teams_fetch_was_started,
  teams_fetch_was_succeed,
  set_teams,
  add_teams,
  teams_fetch_was_failed,
  set_teams_sorting,
  updateTeamNameLocally,
  decreaseTeamAccountsCount,
  increaseTeamAccountsCount,
  decreaseTeamUsersCount,  
  increaseTeamUsersCount,

  accounts_fetch_was_started,
  accounts_fetch_was_succeed,
  set_accounts,
  add_accounts,
  accounts_fetch_was_failed,
  removeAccountFromTeamLocally,

  resetErrors,
  resetTeams,
  resetSorting,
  resetAs400Account
} = teamsSlice.actions;

export const teamsSelector = (state: IState) => state.teams;

export const getCustomers = (
  lastIds: string,
  searchString: string,
  fullSearch: boolean,
  sorting?: any
): AppThunk => async (dispatch) => {
  dispatch(teams_fetch_was_started({
    portion: !!lastIds,
    searchString: searchString
  }));
  const response = await companyService.getCustomers(lastIds, searchString, fullSearch, sorting && sorting);
  dispatch(set_teams_sorting(sorting && sorting));
  if (response.ok()) {
    dispatch(teams_fetch_was_succeed());
    dispatch(lastIds ? add_teams(response.data) : set_teams(response.data));
  } else {
    response.getError && dispatch(teams_fetch_was_failed(response.getError()));
  }
};

export const getAgents = (
  lastIds: string,
  searchString: string,
  fullSearch: boolean,
  sorting?: any
): AppThunk => async (dispatch) => {
  dispatch(teams_fetch_was_started({
    portion: !!lastIds,
    searchString: searchString
  }));
  const response = await companyService.getAgents(lastIds, searchString, fullSearch, sorting && sorting);
  dispatch(set_teams_sorting(sorting && sorting));
  if (response.ok()) {
    dispatch(teams_fetch_was_succeed());
    dispatch(lastIds ? add_teams(response.data) : set_teams(response.data));
  } else {
    response.getError && dispatch(teams_fetch_was_failed(response.getError()));
  }
};

export const getAccounts = (
  teamId: string,
  searchString: string | null,
  lastIds?: string
): AppThunk => async (dispatch) => {
  dispatch(accounts_fetch_was_started(!!lastIds));
  const response = await companyService.getAccounts(teamId, searchString, lastIds && lastIds);
  if (response.ok()) {
    dispatch(accounts_fetch_was_succeed(searchString));
    dispatch(lastIds ? add_accounts(response.data) : set_accounts(response.data));
  } else {
    response.getError && dispatch(accounts_fetch_was_failed(response.getError()));
  }
};

const teamsReducer = teamsSlice.reducer;
export default teamsReducer;
