import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import CompanySettingsService from "../../app/data/company-settings/companySettingsService";
import { initialCompanySettingsAccountsState } from "./CompanySettingsAccountsState";

const companySettingsService = CompanySettingsService.getInstance();

export const companySettingsAccountsSlice = createSlice({
  name: "companySettingsAccounts",
  initialState: initialCompanySettingsAccountsState,
  reducers: {
    fetch_was_started: (state) => {
      state.fetch_was_started = true;
      state.fetch_was_succeed = false;
      state.fetch_was_failed = false;
      state.fetch_fail_reason = null;
    },
    fetch_was_succeed: (state, { payload }) => {
      state.fetch_was_succeed = true;
      state.fetch_was_started = false;
      state.fetch_was_failed = false;
      state.accountPermissions = payload;
    },
    fetch_was_failed: (state, { payload }) => {
      state.fetch_was_failed = true;
      state.fetch_was_succeed = false;
      state.fetch_was_started = false;
      state.fetch_fail_reason = payload;
    },
    update_was_started: (state) => {
      state.update_was_started = true;
      state.update_was_succeed = false;
      state.update_was_failed = false;
      state.update_fail_reason = null;
    },
    update_was_succeed: (state) => {
      state.update_was_succeed = true;
      state.update_was_started = false;
      state.update_was_failed = false;
    },
    update_was_failed: (state, { payload }) => {
      state.update_was_failed = true;
      state.update_was_succeed = false;
      state.update_was_started = false;
      state.update_fail_reason = payload;
    },
    resetErrors: (state) => {
      state.fetch_was_failed = false;
      state.fetch_fail_reason = null;
      state.update_was_failed = false;
      state.update_fail_reason = null;
    },
    resetPermissions: (state) => {
      state.accountPermissions = [];
    },
    updatePermissionsLocally: (state, { payload }) => {
      if (payload.isNew) {
        state.accountPermissions.push({
          user: payload.userId,
          name: payload.userName,
          permissions: payload.permissions
        });
      } else {
        const i = state.accountPermissions.findIndex(permission => permission.user === payload.userId);
        if (i !== -1) {
          if (payload.permissions.length > 0) {
            state.accountPermissions[i].permissions = payload.permissions;
          } else {
            state.accountPermissions = state.accountPermissions.slice(0, i).concat(state.accountPermissions.slice(i + 1, state.accountPermissions.length));
          }
        }
      }
    },
    updateAllPermissions: (state, { payload }) => {
      state.accountPermissions = payload;
    }
  }
});

export const {
  fetch_was_started,
  fetch_was_succeed,
  fetch_was_failed,
  update_was_started,
  update_was_succeed,
  update_was_failed,
  resetErrors,
  resetPermissions,
  updatePermissionsLocally,
  updateAllPermissions
} = companySettingsAccountsSlice.actions;

export const companySettingsAccountsSelector = (state: IState) => state.companySettingsAccounts;

export const getPermissions = (
  accountId: string
): AppThunk => async (dispatch) => {
  dispatch(fetch_was_started());
  const response = await companySettingsService.getPermissions(accountId);
  if (response.ok()) {
    dispatch(fetch_was_succeed(response.data));
  } else {
    response.getError && dispatch(fetch_was_failed(response.getError()));
  }
};

export const updatePermissions = (
  accountId: string,
  userId: string,
  userPermissions: string[] | undefined | null
): AppThunk => async (dispatch) => {
  dispatch(update_was_started());
  const response = await companySettingsService.updatePermissions(accountId, userId, userPermissions || []);
  if (response.ok()) {
    dispatch(update_was_succeed());
  } else {
    response.getError && dispatch(update_was_failed(response.getError()));
  }
};

export const setAllUsersPermissions = (
  accountId: string
): AppThunk => async (dispatch) => {
  dispatch(update_was_started());
  const response = await companySettingsService.setAllUsersPermissions(accountId);
  if (response.ok()) {
    dispatch(updateAllPermissions(response.data))
    dispatch(update_was_succeed());
  } else {
    response.getError && dispatch(update_was_failed(response.getError()));
  }
};

const companySettingsAccountsReducer = companySettingsAccountsSlice.reducer;
export default companySettingsAccountsReducer;
