import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Switch from "react-switch";
import mixpanel from "mixpanel-browser";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import LabeledSelectInput from "../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import Loading from "../../../ui-components/loading/loading";
import NotificationsState from "../../../slices/notifications/NotificationsState";
import {
  notificationsSelector,
  getNotifications,  
  updateNotification,
  updateTimezone,
  resetNotificationsErrors
} from "../../../slices/notifications/notificationsSlice";
import { userSelector } from "../../../slices/user/userSlice";
import { setUserTimezone } from "../../../slices/user/userSlice";
import UserState from "../../../slices/user/UserState";
import { timezones } from "./constants";
import "./notifications.scss";

export interface GroupedOption {
  readonly label: string;
  readonly options: readonly XGSSelectOption[];
};

const Notifications: React.FC<{}> = (props) => {
  const [timezoneValue, setTimezoneValue] = useState<XGSSelectOption | null>();
  const [errorText, setErrorText] = useState<string | null | undefined>(null);
  const notificationsState: NotificationsState = useSelector(notificationsSelector);
  const userState: UserState = useSelector(userSelector);
  const dispatch = useDispatch();

  const onSettingsStateChange = (type: string, enable: boolean) => {
    if (!type || !notificationsState.settings) return;
    setErrorText(null);
    const notifyBy = notificationsState.settings.find((setting: any) => setting.type === type)?.notifyBy;
    dispatch(updateNotification(type, enable, notifyBy, () => {
      toast.info("Settings saved!", { autoClose: 2000 });
      mixpanel.track("Notification settings were changed", {
        "type": type,
        "notifyBy": notifyBy,
        "enable": enable
      });
    }, (error: any) => {
      setErrorText(error);
      dispatch(getNotifications());
    }));
  };

  const onSettingsMethodChange = (type: string, byEmail: boolean) => {
    if (!type) return;
    setErrorText(null);
    dispatch(updateNotification(type, true, byEmail ? "EMAIL" : "PHONE", () => {
      toast.info("Settings saved!", { autoClose: 2000 });
    }, (error: any) => {
      setErrorText(error);
      dispatch(getNotifications());
    }));
  };

  const onChangeTimezone = (timezone: string) => {
    userState.profile?.id && dispatch(updateTimezone(timezone, userState.profile.id, () => {
      dispatch(setUserTimezone(timezone));
      toast.info("The timezone has been saved!", { autoClose: 2000 });
    }, (error: any) => {
      toast.error(error || "Error! Please try again later...", { autoClose: 3000 });
    }));
  };

  useEffect(() => {
    setErrorText(null);
    dispatch(getNotifications());

    return () => {
      dispatch(resetNotificationsErrors());
    };
  }, [dispatch]);

  useEffect(() => {
    let userTimezone = userState.profile?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    let timezoneOption;
    for (const timezonesGroup of timezones) {
      let timezonesGroupOption = timezonesGroup.options.find((obj: any) => obj.value === userTimezone);
      if (timezonesGroupOption) {
        timezoneOption = timezonesGroupOption;
      }
    }
    timezoneOption && setTimezoneValue(timezoneOption);
  }, [userState.profile]);

  useEffect(() => {
    if (userState.profile?.timezone) return;
    onChangeTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="xgs-notifications__timezone">
        <LabeledSelectInput
          isSearchable={true}
          label="Your Timezone:"
          labelMode={LabelModes.row}
          name="timezone"
          onValueChange={(v: any) => {
            setTimezoneValue(v);
            onChangeTimezone(v.value);
          }}
          options={timezones}
          required={false}
          requiredAsteriskDisabled={true}
          formik={true}
          value={timezoneValue}
          className="xgs-notifications__timezone__select"
        />
        <div className="xgs-notifications__timezone__loading">
          <Loading isLoading={notificationsState.requestStarted && notificationsState.requestCreator === "UPDATE_TIMEZONE"} />
        </div>
      </div>
      <div className="xgs-notifications__loading">
        <Loading isLoading={notificationsState.requestStarted && (notificationsState.requestCreator === "LOAD_NOTIFICATIONS" || notificationsState.requestCreator === "UPDATE_NOTIFICATIONS")} />
      </div>
      {errorText && (
        <div style={{ marginBottom: 24 }}>
          <XGSErrorMessage>
            {errorText}
          </XGSErrorMessage>
        </div>
      )}
      {notificationsState.settings && (
        <>
          <div className="xgs-notifications__toggles">
            <div className="xgs-notifications__toggles__headers">
              <div className="xgs-notifications__toggles__header__name"></div>
              <div className="xgs-notifications__toggles__header__control xgs-notifications__toggles__header__control__enable">Enable</div>
              <div className="xgs-notifications__toggles__header__control">Via</div>
              <div className="xgs-notifications__toggles__header__contact-data">To</div>
            </div>          
            {notificationsState.settings.map((setting: any, index: number) => (
              <div
                className="xgs-notifications__toggles__item"
                key={index}
              >
                <div className="xgs-notifications__toggles__item__name">
                  {setting.label}
                </div>
                <div className="xgs-notifications__toggles__item__control xgs-notifications__toggles__item__control__enable">
                  <Switch
                    onChange={(checked) => onSettingsStateChange(setting.type, checked)}
                    checked={setting.enabled}
                  />
                </div>
                <div className="xgs-notifications__toggles__item__control xgs-notifications__toggles__item__control__method">
                  {setting.methodChangeLocked ? (
                    <>
                      {setting.notifyBy === "EMAIL" && <>Email</>}
                      {setting.notifyBy === "SMS" && <>SMS</>}
                    </>
                  ) : (
                    <Switch
                      onChange={(checked) => onSettingsMethodChange(setting.type, checked)}
                      checked={setting.notifyBy === "EMAIL"}
                      disabled={!setting.enabled}
                      width={74}
                      offColor="#2F80ED"
                      onColor="#2F80ED"
                      uncheckedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 14,
                            color: "white",
                            paddingRight: 4
                          }}
                        >
                          SMS
                        </div>
                      }
                      checkedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 14,
                            color: "white",
                            paddingLeft: 6
                          }}
                        >
                          Email
                        </div>
                      }                    
                    />
                  )}
                </div>
                <div className="xgs-notifications__toggles__item__contact-data">
                {setting.enabled && (
                  <>
                    <span className="xgs-notifications__toggles__item__contact-data__to">to</span> {setting.notifyBy === "EMAIL" ? userState.profile?.email : userState.profile?.phoneNumber }
                  </>
                )}
                {!setting.enabled && (
                  <span className="xgs-notifications__toggles__item__no-contact-data">
                    -
                  </span>
                )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>    
  );
};

export default Notifications;
