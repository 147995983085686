import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Formik,
  FormikProps
} from "formik";
import { Routes } from "../../../app/route/RoutesConfig";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import XGSFormPhoneInput from "../../../ui-components/form/phoneInput/xgsFormPhoneInput";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import LoginTitle from "../loginTitle";
import LoginButton from "../loginButton";
import LoginWrapper from "../loginWrapper";
import LoginContent from "../loginContent";
import {
  ConfirmDataFormModel,
  ConfirmDataFormSchema  
} from "../../../app/data/user/requestModels";
import LegacyUserState from "../../../slices/user/LegacyUserState";
import {
  confirmLegacyUserData,
  legacyUserSelector,
  resetLegacyUser,
  resetRequestState
} from "../../../slices/user/legacyUserSlice";
import styles from "../../../sass/variables.module.scss";

const initialValues: ConfirmDataFormModel = {
  firstName: "",
  lastName: "",
  phone: "",
  title: "",
  email: ""
};

const ConfirmDataForm: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const legacyUserState: LegacyUserState = useSelector(legacyUserSelector);
  const [phoneValue, setPhoneValue] = useState<string>("");
  const confirmDataFormRef = useRef<any>(null);

  const onSubmit = (data: ConfirmDataFormModel) => {
    if (!legacyUserState.password || !legacyUserState.username) return;
    dispatch(confirmLegacyUserData({
      username: legacyUserState.username,
      password: legacyUserState.password,
      firstName: data.firstName,
      lastName: data.lastName,
      title: data.title,
      email: data.email,
      phone: data.phone
    }));
  };

  useEffect(() => {
    dispatch(resetRequestState());
    if (!legacyUserState.password || !legacyUserState.username) history.push(Routes.home);

    return () => {
      dispatch(resetLegacyUser());
    }
  }, [dispatch, history, legacyUserState.password, legacyUserState.username]);

  useEffect(() => {
    if (!legacyUserState.data) return;
    confirmDataFormRef.current?.setFieldValue("firstName", legacyUserState.data.firstName);
    confirmDataFormRef.current?.setFieldValue("lastName", legacyUserState.data.lastName);
    confirmDataFormRef.current?.setFieldValue("phone", legacyUserState.data.phone);
    setPhoneValue(legacyUserState.data.phone);
    confirmDataFormRef.current?.setFieldValue("title", legacyUserState.data.title);
    confirmDataFormRef.current?.setFieldValue("email", legacyUserState.data.email);
    if (!legacyUserState.data.email) {
      const emailField = document.getElementById("xgs-login-form__confirm-data__email--js") as HTMLInputElement;
      if (emailField) emailField.focus();
    }    
    setTimeout(() => {
      confirmDataFormRef.current?.validateForm();
    }, 50);
  }, [legacyUserState.data]);

  return (
    <LoginWrapper>
      <LoginContent>
        {!(legacyUserState.requestSucceed && legacyUserState.requestCreator === "CONFIRM_DATA") && (
          <>
            <LoginTitle>Confirm Profile</LoginTitle>
            <div className="xgs-login-form__description">
              To continue using the XGS Portal you need to confirm your profile information.
              {!legacyUserState.data?.email && <div className="xgs-login-form__description__red-text">Please enter a unique email address that will be used as the user&nbsp;ID.</div>}
            </div>
            <Formik
              onSubmit={onSubmit}
              initialValues={initialValues}
              innerRef={confirmDataFormRef}
              validationSchema={ConfirmDataFormSchema}
            >
              {(props: FormikProps<ConfirmDataFormModel>) => (
                <Form className="form-items">
                  <XGSFormInput
                    type="text"
                    name="firstName"
                    label="First Name:"
                    required={true}
                    requiredAsteriskDisabled={true}
                    labelMode={LabelModes.column}
                  />
                  <XGSFormInput
                    type="text"
                    name="lastName"
                    label="Last Name:"
                    required={true}
                    requiredAsteriskDisabled={true}
                    labelMode={LabelModes.column}
                  />
                  <XGSFormInput
                    type="text"
                    name="title"
                    label="Title:"
                    required={true}
                    requiredAsteriskDisabled={true}
                    labelMode={LabelModes.column}
                  />
                  <XGSFormInput
                    type="text"
                    name="email"
                    label="Email:"
                    required={true}
                    requiredAsteriskDisabled={true}
                    labelMode={LabelModes.column}
                    id="xgs-login-form__confirm-data__email--js"
                  />
                  <XGSFormPhoneInput
                    name="phone"
                    label="Phone:"
                    labelMode={LabelModes.column}
                    onValueChange={(value) => {
                      props.setFieldValue("phone", value);
                      setPhoneValue(value);
                    }}
                    onBlur={props.handleBlur}
                    value={phoneValue}
                    required={true}
                    requiredAsteriskDisabled={true}
                  />
                  {legacyUserState.requestFailed && (
                    <XGSErrorMessage className="xgs-public-form__error xgs-login-failed-message--bottom">
                      {legacyUserState.requestFailReason
                        ? (<>{legacyUserState.requestFailReason}</>)
                        : (<>Something wents wrong!</>)
                      }
                    </XGSErrorMessage>
                  )}
                  <LoginButton
                    as={Button}
                    theme={ButtonThemes.blue}
                    type="submit"
                    spinner={legacyUserState.requestStarted}
                    disabled={!props.isValid}
                  >
                    Save
                  </LoginButton>
                </Form>
              )}
            </Formik>
          </>
        )}
        {(legacyUserState.requestSucceed && legacyUserState.requestCreator === "CONFIRM_DATA") && (
          <div className="xgs-login-form__result-text">
            <div>
              <XGSIcon
                icon={XGSIcons.faCheckCircle}
                style={{
                  color: styles.green1,
                  marginBottom: 16
                }}
                size="3x"
              />
            </div>
            Check your inbox or spam folder. We have sent you a confirmation email, please use the link inside it to continue.
          </div>
        )}
        <div className="xgs-site__box__link">Go back to <a href="/" className="blue-link">Log In</a></div>
      </LoginContent>
    </LoginWrapper>
  );
};

export default ConfirmDataForm;
