import React, { DetailedHTMLProps, HTMLAttributes,memo } from "react";

import "./infoGrid.scss";

export interface InfoGridProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const InfoGrid: React.FC<InfoGridProps> = memo(({className, ...props}) => {
  return (
    <div className={`xgs-info-grid ${className ? className : ""}`} {...props}>
      {props.children}
    </div>
  );
});

export default InfoGrid;
