import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import RatesService from "../../app/data/rates/ratesService";
import { initialRatesState } from "./RatesState";

const ratesService = RatesService.getInstance();

export const ratesSlice = createSlice({
  name: "rates",
  initialState: initialRatesState,
  reducers: {
    resetRatesState: (state) => initialRatesState,
    requestStarted: (state, { payload }) => {
      if (payload === "GET_RATES_PORTION") {
        state.requestPortionStarted = true;
        state.requestStarted = false;
      } else {
        state.requestPortionStarted = false;
        state.requestStarted = true;
      }
      state.requestCreator = payload;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestError = "";
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
      state.requestPortionStarted = false;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestPortionStarted = false;
      state.requestFailed = true;
      state.requestError = payload;
    },
    setRates: (state, { payload }) => {
      state.rates = payload.content;
      state.ratesRequest = payload.scroll;
      state.ratesFetchedAll = !!(payload.content.length < 50);
    },
    addRates: (state, { payload }) => {
      state.rates = [...state.rates, ...payload.content];
      state.ratesRequest = payload.scroll;
      state.ratesFetchedAll = !!(payload.content.length < 50);
    },
    setRatesDetails: (state, { payload }) => {
      state.ratesDetails = payload;
    }
  }
});

export const {
  resetRatesState,
  requestStarted,
  requestSucceed,
  requestFailed,
  setRates,
  addRates,
  setRatesDetails
} = ratesSlice.actions;

export const ratesSelector = (state: IState) => {
  return state.rates;
};

export const getRatesList = (
  lastIds?: string
): AppThunk => async (dispatch) => {
  dispatch(requestStarted(lastIds ? "GET_RATES_PORTION" : "GET_RATES"));
  const response = await ratesService.getRatesList(lastIds && lastIds);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(lastIds ? addRates(response.data) : setRates(response.data));
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const getRatesDetails = (
  id: string
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_RATES_DETAILS"));
  const response = await ratesService.getRatesDetails(id);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setRatesDetails(response.data));
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const downloadDocument = (
  quoteNumber: number,
  onSuccess: (arg: Blob) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("DOWNLOAD_PDF"));
  const response = await ratesService.downloadDocument(quoteNumber);
  if (response.ok()) {
    dispatch(requestSucceed());
    let blob: Blob = new Blob([response.data], {type: "application/pdf"});
    onSuccess(blob);
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const sendQuoteEmail = (
  email: string,
  quoteId: string,
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("SEND_QUOTE"));
  const response = await ratesService.sendQuoteEmail(email, quoteId);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

const ratesReducer = ratesSlice.reducer;
export default ratesReducer;
