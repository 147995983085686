import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import AddressLookup from "../../../../ui-components/address-lookup/addressLookup";
import XGSFormInput from "../../../../ui-components/form/input/xgsFormInput";
import XGSFormPhoneInput from "../../../../ui-components/form/phoneInput/xgsFormPhoneInput";
import XGSFormSelect from "../../../../ui-components/form/select/xgsFormSelect";
import { XGSSelectOption } from "../../../../ui-components/xgs-select/xgsSelect";
import { LabelModes } from "../../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import {
  BolPayorModel,
  BolPayorSchema
} from "../../../../app/data/bol/models";
import { StepProps } from "../../../../app/data/common/models";
import BolState from "../../../../slices/bol/BolState";
import {
  bolSelector,
  setBolPayor
} from "../../../../slices/bol/bolSlice";
import { usaStates } from "../../../../app/data/common/usaStates";
import "../../bol.scss";

let initialValues: BolPayorModel = {
  name: "",
  address: {
    address1: "",
    city: "",
    postalCode: "",
    state: ""
  },
  phone: ""
};

const BolPayorStep: React.FC<StepProps> = (props) => {
  const { previous, next } = props;
  const bolState: BolState = useSelector(bolSelector);
  const [stateValue, setStateValue] = useState<XGSSelectOption | null>();  
  const [addressLookupValue, setAddressLookupValue] = useState<any>("");
  const [phoneFieldValue, setPhoneFieldValue] = useState<string>("");
  const dispatch = useDispatch();
  const payorFormRef = useRef<any>(null);

  const onClickBack = (data: BolPayorModel) => {
    dispatch(setBolPayor(data));
    previous && previous();
  };

  const onClickNext = (data: BolPayorModel) => {
    dispatch(setBolPayor(data));
    next && next();
  };

  useEffect(() => {
    initialValues = {
      name: bolState.payor ? bolState.payor.name : "",
      address: {
        address1: bolState.payor ? bolState.payor.address.address1 : "",
        city: bolState.payor ? bolState.payor.address.city : "",
        postalCode: bolState.payor ? bolState.payor.address.postalCode : "",
        state: bolState.payor ? bolState.payor.address.state : ""
      },
      phone: bolState.payor ? bolState.payor.phone : ""
    }
    payorFormRef.current?.setFieldValue("name", bolState.payor ? bolState.payor.name : "");
    if (bolState.payor?.address.address1) {
      setAddressLookupValue({
        label: `${bolState.payor.address.address1}, ${bolState.payor.address.city}, ${bolState.payor.address.state}, ${bolState.payor.address.postalCode}`,
        value: {
          address: bolState.payor.address.address1,
          city: bolState.payor.address.city,
          state: bolState.payor.address.state,
          zip: bolState.payor.address.postalCode
        }
      });
    }
    payorFormRef.current?.setFieldValue("address.address1", bolState.payor ? bolState.payor.address.address1 : "");
    payorFormRef.current?.setFieldValue("address.city", bolState.payor ? bolState.payor.address.city : "");
    payorFormRef.current?.setFieldValue("address.state", bolState.payor?.address.state || "");
    const stateOption = usaStates.find(state => state.value === bolState.payor?.address.state);
    setStateValue(stateOption || null);
    payorFormRef.current?.setFieldValue("address.postalCode", bolState.payor ? bolState.payor.address.postalCode : "");
    payorFormRef.current?.setFieldValue("phone", bolState.payor ? bolState.payor.phone : "");
    setPhoneFieldValue(bolState.payor ? bolState.payor.phone : "");
  }, [bolState.payor]);

  return (
    <div className="xgs-bol__payor">
      <Formik
        onSubmit={onClickNext}
        initialValues={initialValues}
        validationSchema={BolPayorSchema}
        innerRef={payorFormRef}
        validateOnMount
      >
        {(props: FormikProps<BolPayorModel>) => (
          <Form>
            <div className="xgs-bol__section">Payor Information</div>
            <XGSFormInput
              type="text"
              name="name"
              label="Payor:"
              required={true}
              requiredAsteriskDisabled={false}
              labelMode={LabelModes.column}
              className="xgs-bol__field"
            />
            <div className="xgs-bol__address-info">
              <AddressLookup
                defaultValue={addressLookupValue}
                onValueChange={(val) => {
                  props.setFieldValue("address.address1", val.address);
                  props.setFieldValue("address.city", val.city);
                  props.setFieldValue("address.state", val.state);
                  const stateOption = usaStates.find(state => state.value === val.state);
                  stateOption && setStateValue(stateOption);
                  props.setFieldValue("address.postalCode", val.zip);
                  setTimeout(() => {
                    props.setFieldTouched("address.address1", true, true);
                  }, 100);
                }}
                onBlur={() => {
                  props.setFieldTouched("address.address1", true, true);
                }}
              />            
              <div className="xgs-form__address-block">
                <XGSFormInput
                  type="text"
                  name="address.address1"
                  label="Address:"
                  required={true}
                  requiredAsteriskDisabled={false}
                  labelMode={LabelModes.column}
                  className="xgs-bol__field"
                />
                <XGSFormInput
                  type="text"
                  name="address.city"
                  label="City:"
                  required={true}
                  requiredAsteriskDisabled={false}
                  labelMode={LabelModes.column}
                  className="xgs-bol__field"
                />
                <div className="xgs-bol__fields-row">
                  <XGSFormSelect
                    isSearchable={false}
                    label="State:"
                    labelMode={LabelModes.column}
                    name="address.state"
                    onValueChange={(v: any) => {
                      props.setFieldValue("address.state", v?.value);
                      if (v?.value) {
                        const stateOption = usaStates.find(state => state.value === v.value);
                        stateOption && setStateValue(stateOption);
                      }
                    }}
                    options={usaStates}
                    required={true}
                    requiredAsteriskDisabled={false}
                    formik={true}
                    value={stateValue}
                    className="xgs-bol__field xgs-bol__fields-row__state"
                  />
                  <XGSFormInput
                    type="text"
                    name="address.postalCode"
                    label="Zip:"
                    required={true}
                    requiredAsteriskDisabled={false}
                    labelMode={LabelModes.column}
                    className="xgs-bol__field xgs-bol__field--zip"
                  />
                </div>
              </div>
            </div>
            <XGSFormPhoneInput
              name="phone"
              label="Phone:"
              labelMode={LabelModes.column}
              onValueChange={(value) => {
                props.setFieldValue("phone", value);
                setPhoneFieldValue(value);
              }}
              onBlur={props.handleBlur}
              value={phoneFieldValue}
              required={true}
              requiredAsteriskDisabled={false}
              className="xgs-bol__field xgs-bol__field--short"
            />
            <div className="xgs-form__field__notes">
              <strong>Note:</strong> by providing a telephone number and submitting this form you are consenting to be contacted by SMS text message.
              Message &amp; data rates may apply. You can reply STOP to opt-out of further messaging.
            </div>
            <div className="xgs-bol__buttons">
              <Button
                type="button"
                theme={ButtonThemes.gray}
                className="xgs-bol__nav-button"
                onClick={() => { onClickBack(props.values) }}
              >
                Back
              </Button>
              <Button
                type="submit"
                theme={ButtonThemes.blue}
                disabled={!props.isValid || !props.values.name}
                className="xgs-bol__nav-button">
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BolPayorStep;
