import api, { ApiResponse } from "../api";
import { ExportDetails, GetInvoiceExportIdRequestModel } from "./models";

class ExportService {
  private static instance: ExportService;

  private constructor() {}

  static getInstance(): ExportService {
    if (!ExportService.instance) {
      ExportService.instance = new ExportService();
    }

    return ExportService.instance;
  }

  public getExportId = async (
    requestModel: GetInvoiceExportIdRequestModel,
    multiple: boolean
  ): Promise<ApiResponse<{ exportId: string }>> => {
    return multiple
      ? await api.post(`/invoices/exports`, {
        type: requestModel.type,
        invoiceNumbers: requestModel.invoices
      })
      : await api.post(`/invoices/${requestModel.invoices}/exports`, {
        type: requestModel.type
      });
  };

  public getExportLink = async (
    exportId: string
  ): Promise<ApiResponse<ExportDetails>> => {
    return await api.get(`/exports/${exportId}`);
  };
}

export default ExportService;
