// src/store/warehouseInventory/warehouseInventorySlice.ts
import { createSlice } from '@reduxjs/toolkit';
import WarehouseInventoryService from '../../app/data/warehouse-inventory/warehouseInventoryService';
import { initialWarehouseInventoryState, WarehouseInventoryState } from './warehouseInventoryState';
import { AppThunk } from '../../app/store';
import { IState } from '..';
import { WarehouseItemFilterRequest } from '../../app/data/warehouse-inventory/models';
import { toast } from 'react-toastify';

const warehouseInventoryService = WarehouseInventoryService.getInstance();

export const warehouseInventorySlice = createSlice({
  name: 'warehouseInventory',
  initialState: initialWarehouseInventoryState,
  reducers: {
    resetWarehouseInventoryState: (state) => state,
    setItemsFilters: (state, { payload }) => {
      state.itemsFilter = payload;
    },
    fetchItemsStarted: (state: WarehouseInventoryState) => {
      state.loadingItems = true;
      state.loadingItemsSucceed = false;
      state.loadingItemsFailed = false;
    },
    fetchItemsSucceeded: (state: WarehouseInventoryState, { payload }) => {
      state.loadingItems = false;
      state.items = payload.items;
      state.nextItemId = payload.nextItemId
      state.loadingItemsSucceed = true;
      state.loadingItemsFailed = false;
      state.inifiniteLoadingItemsHasNext = payload.items.length >= 25;
    },
    inifiniteLoadingItemsStarted: (state: WarehouseInventoryState) => {
      state.infiniteLoadingItems = true;
      state.infiniteLoadingItemsSucceed = false;
    },
    inifiniteLoadingItemsSucceed: (state: WarehouseInventoryState, { payload }) => {
      state.infiniteLoadingItems = false;
      state.infiniteLoadingItemsSucceed = true;
      state.items.push(...payload.items);
      state.nextItemId = payload.nextItemId;
      state.inifiniteLoadingItemsHasNext = payload.items.length >= 25;
    },
    fetchItemsFailed: (state: WarehouseInventoryState, { payload }) => {
      state.loadingItems = false;
      state.loadingItemsFailed = true;
      state.loadingItemsError = payload;
    },
    fetchLocationSummaryStarted: (state: WarehouseInventoryState) => {
      state.loadingLocationSummary = true;
      state.loadingLocationSummarySucceed = false;
      state.loadingLocationSummaryFailed = false;
    },
    fetchLocationSummarySucceeded: (state: WarehouseInventoryState, { payload }) => {
      state.loadingLocationSummary = false;
      state.locationSummary = payload;
      state.loadingLocationSummarySucceed = true;
      state.loadingLocationSummaryFailed = false;
    },
    fetchLocationSummaryFailed: (state: WarehouseInventoryState, { payload }) => {
      state.loadingLocationSummary = false;
      state.loadingLocationSummaryFailed = true;
      state.loadingLocationSummaryError = payload;
    },
    fetchLowQuantityItemsStarted: (state: WarehouseInventoryState) => {
      state.loadingLowQuantityItems = true;
      state.loadingLowQuantityItemsSucceed = false;
      state.loadingLowQuantityItemsFailed = false;
    },
    fetchLowQuantityItemsSucceeded: (state: WarehouseInventoryState, { payload }) => {
      state.loadingLowQuantityItems = false;
      state.lowQuantityItems = payload;
      state.loadingLowQuantityItemsSucceed = true;
      state.loadingLowQuantityItemsFailed = false;
    },
    fetchLowQuantityItemsFailed: (state: WarehouseInventoryState, { payload }) => {
      state.loadingLowQuantityItems = false;
      state.loadingLowQuantityItemsFailed = true;
      state.loadingLowQuantityItemsError = payload;
    },
    fetchTopSellingItemsStarted: (state: WarehouseInventoryState) => {
      state.loadingTopSellingItems = true;
      state.loadingTopSellingItemsSucceed = false;
      state.loadingTopSellingItemsFailed = false;
    },
    fetchTopSellingItemsSucceeded: (state: WarehouseInventoryState, { payload }) => {
      state.loadingTopSellingItems = false;
      state.topSellingItems = payload;
      state.loadingTopSellingItemsSucceed = true;
      state.loadingTopSellingItemsFailed = false;
    },
    fetchTopSellingItemsFailed: (state: WarehouseInventoryState, { payload }) => {
      state.loadingTopSellingItems = false;
      state.loadingTopSellingItemsFailed = true;
      state.loadingTopSellingItemsError = payload;
    },
    fetchTerminalsStarted: (state) => {
      state.loadedTerminals = false;
      state.loadingTerminals = true;
    },
    fetchTerminalsSucceed: (state, { payload }) => {
      state.loadingTerminals = false;
      state.loadedTerminals = true;
      state.terminals = payload;
    },
    fetchTerminalsFailed: (state) => {
      state.loadingTerminals = false;
      state.loadedTerminals = false;
      state.loadingTerminalsFailed = true;
    }
  }
});

export const { 
  resetWarehouseInventoryState,
  fetchItemsStarted,
  fetchItemsSucceeded,
  fetchItemsFailed,
  fetchLocationSummaryStarted,
  fetchLocationSummarySucceeded,
  fetchLocationSummaryFailed,
  fetchLowQuantityItemsStarted,
  fetchLowQuantityItemsSucceeded,
  fetchLowQuantityItemsFailed,
  fetchTopSellingItemsStarted,
  fetchTopSellingItemsSucceeded,
  fetchTopSellingItemsFailed,
  inifiniteLoadingItemsStarted,
  inifiniteLoadingItemsSucceed,
  setItemsFilters,
  fetchTerminalsFailed,
  fetchTerminalsStarted,
  fetchTerminalsSucceed,
} = warehouseInventorySlice.actions;

export const fetchWarehouseItems = (filterRequest: WarehouseItemFilterRequest): AppThunk => async (dispatch) => {
  dispatch(fetchItemsStarted());
  dispatch(setItemsFilters(filterRequest));
  const response = await warehouseInventoryService.getCustomerInventoryItems(filterRequest);
  if (response.ok()) {
    dispatch(fetchItemsSucceeded({ items: response.data.items, nextItemId: response.data.pagination.next}));
  } else {
    response.getError && dispatch(fetchItemsFailed(response.getError()));
  }
};

export const infiniteLoadWarehouseItems = (filterRequest: WarehouseItemFilterRequest, nextItemId: string): AppThunk => async (dispatch) => {
  dispatch(inifiniteLoadingItemsStarted());
  filterRequest.next = nextItemId;
  dispatch(setItemsFilters(filterRequest))
  const response = await warehouseInventoryService.getCustomerInventoryItems(filterRequest);
  if (response.ok()) {
    dispatch(inifiniteLoadingItemsSucceed({ nextItemId: response.data.pagination.next, items: response.data.items }))
  } else {
    response.getError && dispatch(fetchItemsFailed(response.getError()));
  }
};

export const fetchWarehouseLocationSummary = (): AppThunk => async (dispatch) => {
  dispatch(fetchLocationSummaryStarted());
  const response = await warehouseInventoryService.getLocationSummary();
  if (response.ok()) {
    dispatch(fetchLocationSummarySucceeded(response.data.locationsSummary));
  } else {
    response.getError() && dispatch(fetchLocationSummaryFailed(response.getError()));
  }
};

export const fetchLowQuantityItems = (): AppThunk => async (dispatch) => {
  dispatch(fetchLowQuantityItemsStarted());
  const response = await warehouseInventoryService.getLowQuantityItems();
  if (response.ok()) {
    dispatch(fetchLowQuantityItemsSucceeded(response.data.skus));
  } else {
    response.getError() && dispatch(fetchLowQuantityItemsFailed(response.getError()));
  }
};

export const fetchTopSellingItems = (): AppThunk => async (dispatch) => {
  dispatch(fetchTopSellingItemsStarted());
  const response = await warehouseInventoryService.getTopSellingItems();
  if (response.ok()) {
    dispatch(fetchTopSellingItemsSucceeded(response.data.skus));
  } else {
    response.getError() && dispatch(fetchTopSellingItemsFailed(response.getError()));
  }
};

export const fetchAllTerminals = (): AppThunk => async (dispatch) => {
  dispatch(fetchTerminalsStarted());
  const response = await warehouseInventoryService.getAllTerminals();
  if (response.ok()) {
    dispatch(fetchTerminalsStarted());
    dispatch(fetchTerminalsSucceed(response.data));
  } else {
    dispatch(fetchTerminalsFailed());
    toast.error("Loading terminals failed");
  }
}

export const warehouseInventorySelector = (state: IState) => state.warehouseInventory;

const warehouseInventoryReducer = warehouseInventorySlice.reducer;
export default warehouseInventoryReducer;
