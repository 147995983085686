
export default interface ServiceCentersStatusState {
  request_was_started: boolean;
  request_was_succeed: boolean;
  request_was_failed: boolean;
  requestError?: string | null;
  closedList: string[];
  limitedList: string[];
};

export const initialServiceCentersStatusState: ServiceCentersStatusState = {
  request_was_started: false,
  request_was_succeed: false,
  request_was_failed: false,
  requestError: null,
  closedList: [],
  limitedList: []
};
