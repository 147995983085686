import { weekDays } from "../../ui-components/xgs-date/xgs-date/xgsDate";

export default interface CommonState {
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestCreator: string;
  requestError: string;
  freightClasses: any[];
  pickupDays: weekDays | null;
}

export const initialCommonState: CommonState = {
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestCreator: "",
  requestError: "",
  freightClasses: [],
  pickupDays: null
};
