import React, { useEffect, useState } from "react";
import timeLeft from "../../images/time-left.svg";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import SlideOutSidebar from "../../ui-components/slide-out-sidebar/slideOutSidebar";
import Table from "../../ui-components/table/table";
import { getActivityLogColumns } from "./activityHistoryColumns";
import { useDispatch, useSelector } from "react-redux";
import { fetchLinehaulTripActivity, linehaulMileageSelector } from "../../slices/linehaul-mileage/linehaulMileageSlice";

export const LinehaulTripAcitivity: React.FC<{ show: boolean, tripId?: string }> = (props) => {
  const [show, setShow] = useState(false);
  const linehaulMileageState = useSelector(linehaulMileageSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!props.show) {
      setShow(false);
    }
  }, [props.show])

  useEffect(() => {
    if(show && props.tripId) dispatch(fetchLinehaulTripActivity(props.tripId!));
  }, [show, props.tripId, dispatch])
  
  return (
    <div className="xgs-lmi__activity">
      <Button onClick={() => setShow(state => !state)} theme={ButtonThemes.white}>
        <img src={timeLeft} alt="time left" />&nbsp; Activity History
      </Button>
      <SlideOutSidebar header="Activity History" show={show} onClose={() => setShow(false)}>
      <div className="xgs-lmi__details">
        <div style={{ display: "flex" }} className="xgs-lmi__title">
          Activity Log
        </div>
        <Table
          infiniteScroll
          columns={getActivityLogColumns()}
          data={linehaulMileageState.activity}
          isLoading={linehaulMileageState.loadingActivity}
          rowHeight={120}
        />
      </div>
      </SlideOutSidebar>
    </div>
  )
}