import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import RoutesService from "../../../app/data/plan-probills/routes/routesService";
import { UpdateRouteState } from "./routesState";
import { getActiveRouteAndStops } from "./activeRoutesSlice";

const initialState: UpdateRouteState = {
  loading: false,
  loadingFailed: false,
  loaded: false,
  error: ""
};

const routesService = RoutesService.getInstance();

export const updateRouteSlice = createSlice({
  name: "updateRoute",
  initialState,
  reducers: {
    resetUpdateRouteState: (state) => initialState,
    updateRouteloadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
      state.error = "";
    },
    updateRouteLoadingSucceed: (state) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
      state.error = "";
    },
    updateRouteLoadingFailed: (state, { payload }) => {
      state.loading = false;
      state.loadingFailed = true;
      state.error = payload;
    }
  },
});

export const {
  resetUpdateRouteState,
  updateRouteloadingStarted,
  updateRouteLoadingSucceed,
  updateRouteLoadingFailed
} = updateRouteSlice.actions;

export const updateRouteSelector = (state: IState) => state.updateRoute;

export const updateRoute = (
  routeRequest: any,
  terminalAndRoute: any,
  onSuccess?: () => void,
  onError?: (error: string) => void
): AppThunk => async (dispatch) => {
  dispatch(updateRouteloadingStarted());
  const response = await routesService.updateRoute({ ...routeRequest });
  if (response.ok() && response.data && response.data.result) {
    dispatch(updateRouteLoadingSucceed());
    dispatch(getActiveRouteAndStops(terminalAndRoute));
    onSuccess && onSuccess();
  } else {
    let error = "Internal Server Error, re-try the action or contact support";
    if (response && response.data && response.data.error) error = response.data.error;
    dispatch(updateRouteLoadingFailed(error));
    onError && onError(error)
  }
};

const updateRouteReducer = updateRouteSlice.reducer;
export default updateRouteReducer;