import React from "react";
import Button, { ButtonSizes, ButtonThemes } from "../button/button";
import { ReactComponent as RefreshIcon } from "../../images/refresh.svg";

export interface ReloadButtonProps {  
  title?: string;
  onClick: () => void;
  spinner?: boolean,
  disabled?: boolean;
}

const ReloadButton: React.FC<ReloadButtonProps> = ({  
  title,
  onClick,  
  spinner,
  ...props
}) => {
  return (
    <Button
      {...props}
      theme={ButtonThemes.transparent}
      size={ButtonSizes.auto}
      onClick={onClick}
      spinner={spinner}
    >
      {!spinner && (
          <div className="xgs-btn__icon-container">
            <RefreshIcon className="xgs-btn__icon" />
          </div>
        )
      }
      {title}
    </Button>
  )
};


export default ReloadButton;