import { Form, Formik, FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Contact, AccountContactModel } from "../../../app/data/contact/models";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import XGSFormSelect from "../../../ui-components/form/select/xgsFormSelect";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSFormPhoneInput from "../../../ui-components/form/phoneInput/xgsFormPhoneInput";

export interface ContactFormProps {
  initialFormValues: Contact
  isEditing?: boolean;
  onSubmit: any;
  onCancelEditing: any;
  isSubmitting: boolean;
  contacts: Contact[];
  disablePrepopulate?: boolean;
}

export const AccountContactForm: React.FC<ContactFormProps> = (props) => {
  const [phoneFieldValue, setPhoneFieldValue] = useState("");
  const formRef = useRef<FormikProps<Contact>>(null);

  const getPrepopulateOptions= () => {
    return props.contacts.filter(contact => contact.type !== props.initialFormValues.type).map(contact => ({label: contact.firstName + " " + contact.lastName, value: contact.id || ""}))
  }

  useEffect(() => {
    props.initialFormValues.phone && setPhoneFieldValue(props.initialFormValues.phone);
  }, [props.initialFormValues])

  const populateFields = (id: string) => {
    let contact = props.contacts.find(contact => contact.id === id);
    if (contact) formRef.current?.setValues({...contact, type: props.initialFormValues.type});
    if (contact?.phone) setPhoneFieldValue(contact.phone)
  }

  return (
    <div>
      <Formik enableReinitialize validationSchema={AccountContactModel} innerRef={formRef} initialValues={props.initialFormValues} onSubmit={props.onSubmit}>
        {(formProps: FormikProps<Contact>) => (
          <Form className="xgs-contact-form">
            {(!props.isEditing && !props.disablePrepopulate) && <XGSFormSelect 
              className="xgs-contact-form__span-2"
              options={getPrepopulateOptions()} 
              label="Prepopulate from existing contact" 
              labelMode={LabelModes.column}
              name="address"
              onValueChange={(option) => populateFields(option?.value || "")}
            />}
            <XGSFormInput labelMode={LabelModes.column} label="First name" required placeholder="First name" name="firstName"/>
            <XGSFormInput labelMode={LabelModes.column} label="Last name" required placeholder="Last name" name="lastName"/>
            <XGSFormInput labelMode={LabelModes.column} className="xgs-contact-form__span-2" label="Title" required placeholder="Title" name="title"/>
            <XGSFormPhoneInput
              name="phone"
              label="Phone:"
              labelMode={LabelModes.column}
              onValueChange={(value) => {
                formProps.setFieldValue("phone", value);
                setPhoneFieldValue(value);
              }}
              onBlur={formProps.handleBlur}
              value={phoneFieldValue}
              required={true}
              requiredAsteriskDisabled={false}
            />
            <XGSFormInput labelMode={LabelModes.column} label="E-mail" required placeholder="E-mail" name="email"/>
            <div className="xgs-form__field__notes xgs-contact-form__span-2">
              <strong>Note:</strong> by providing a telephone number and submitting this form you are consenting to be contacted by SMS text message.
              Message &amp; data rates may apply. You can reply STOP to opt-out of further messaging.
            </div>
            <div className="xgs-contact-form__buttons">
              <Button disabled={props.isSubmitting} onClick={props.onCancelEditing} type="button" theme={ButtonThemes.gray}>Cancel</Button>
              <Button spinner={props.isSubmitting} type="submit" theme={ButtonThemes.blue}>{props.isEditing ? "Edit Contact" : "Add Contact"}</Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}