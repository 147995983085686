import * as Yup from "yup"

export interface PlanningIssue {
    type: string;
    details: string;
};

export enum UnplannableProbillsSummaryTypes {
    total = "total",
    atRisk = "atRisk",
    catchUp = "catchUp",
    late = "late",
    missed = "missed"
};

export const UnplannableProbillsSummaryDesc = {
    total: "All Unplannable probills",
    atRisk: "Probills with ETA greator than today",
    catchUp: "Probills for ETA as today",
    late: "Probills with ETA within last 7 days",
    missed: "Probills with ETA older than 7 days or more"
};

export interface UnplannableProbillsSummaryData {
    total: number,
    atRisk: number,
    catchUp: number,
    late: number,
    missed: number
};

export interface UnplannableProbill {
    probillNumber: number,
    probillLevel: string,
    probillStatusCode: string,
    probillStatus: string,
    consigneeNumber: number,
    consigneeName: string,
    consigneeAddress: string,
    consigneeCity: string,
    consigneeState: string,
    consigneeZip: 0,
    probillWeight: number,
    probillItems: number,
    etaDate: number,
    billDate: number,
    planningIssues: [PlanningIssue],
    totalItems: number | null,
    plannableItems: number | null,
    itemsOnPicklist: number | null,
    summaryBucketType: UnplannableProbillsSummaryTypes
};

export interface UnplannableProbillsResponse {
    result: boolean,
    summary?: UnplannableProbillsSummaryData,
    unplannableProbills?: [UnplannableProbill],
    error?: string
};

export interface UnplannableProbillsFilters {
    probillNumber?: number | null;
    etaStartDate?: string;
    etaEndDate?: string;
    probillStatus?: string | null;
};

export interface UnplannableProbillsRequest extends UnplannableProbillsFilters {
    terminalNumber: number
};

export const UnplannableProbillsStatusOptions = [
    { label: "TENDERED AT ORIGIN", value: "3" },
    { label: "IN TRANSIT TO SERVICE CENTER", value: "4" },
    { label: "ARRIVED At DEST.SRV CNTR", value: "5" },
    { label: "TRANSFERRED AT HUB", value: "6" },
    { label: "IN TRANSIT TO HUB", value: "7" },
    { label: "DEPARTED ORIGIN", value: "8" },
    { label: "MANIFESTED AT ORIGIN", value: "9" },
    { label: "PARTIAL PICKUP OR DELIVERY", value: "11" },
    { label: "PICKED UP FROM SHIPPER", value: "30" },
    { label: "AT DESTINATION AGENT", value: "31" },
    { label: "REFUSED BY CUSTOMER-ON HAND", value: "32" },
    { label: "RETURNED TO SERVICE CENTER", value: "45" },
    { label: "UNLOADED At DEST.SRV CNTR", value: "51" },
    { label: "UNDELIVERABLE-AT DEST SRV CNTR", value: "55" },
    { label: "SHORT INTO DEST. SRV CNTR", value: "56" },
    { label: "BROKERED", value: "75" },
    { label: "NON-FREIGHT CHARGES", value: "95" },
    { label: "RETURNED TO SHIPPER", value: "97" },
    { label: "CREDITED/REBILLED", value: "98" },
];

export const UnplannableProbillsFiltersSchema: Yup.ObjectSchema<UnplannableProbillsFilters> = Yup.object().shape({
    probillNumber: Yup.number()
        .typeError("Probill must be a number")
        .max(99999999, "The value must be less then 9 digits")
        .nullable(),
    etaStartDate: Yup.string(),
    etaEndDate: Yup.string(),
    probillStatus: Yup.string().nullable()
}).defined();