import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import Loading from "../../../ui-components/loading/loading";
import RatesState from "../../../slices/rates/RatesState";
import {
  downloadDocument,
  ratesSelector  
} from "../../../slices/rates/ratesSlice";

export interface DownloadPDFProps {
  quoteNumber: number | undefined;
}

const DownloadPDF: React.FC<DownloadPDFProps> = (props) => {
  const ratesState: RatesState = useSelector(ratesSelector);
  const dispatch = useDispatch();

  const onClickDownload = () => {
    if (!props.quoteNumber) return;
    dispatch(downloadDocument(props.quoteNumber, (file: Blob) => {
      let documentfileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = documentfileURL;
      link.download = "quote_" + props.quoteNumber;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      mixpanel.track("Rate Quote PDF was downloaded");
    }));
  };

  useEffect(() => {
    if (!ratesState.requestFailed) return;
    toast.error(ratesState.requestError || "Error", { autoClose: 4000 });
  }, [ratesState.requestFailed, ratesState.requestError]);

  return (
    <>
      {!(ratesState.requestStarted && ratesState.requestCreator === "DOWNLOAD_PDF") && (
        <div
          className="xgs-site__content-container__toolbar__button"
          onClick={onClickDownload}
        >
          <XGSIcon
            className="xgs-site__content-container__toolbar__button__icon"
            icon={XGSIcons.faPrint}
            size="lg"
            title="Download PDF"
          />
          <div className="xgs-site__content-container__toolbar__button__label">Print</div>
        </div>
      )}
      {(ratesState.requestStarted && ratesState.requestCreator === "DOWNLOAD_PDF") && (
        <div style={{
          marginRight: 16,
          marginTop: 8
        }}>
          <Loading isLoading={true} />
        </div>
      )}
    </>
  );
};

export default DownloadPDF;
