import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { PodRecognitionFilterModel } from "../../../app/data/documents/models";
import PodRecognitionService from "../../app/data/documents/podRecognitionService";
import { initialPodRecognitionState } from "./PodRecognitionState";

const podRecognitionService = PodRecognitionService.getInstance();

export const podRecognitionSlice = createSlice({
  name: "podRecognition",
  initialState: initialPodRecognitionState,
  reducers: {
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestError = "";
      state.requestCreator = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestError = payload;
    },
    resetErrors: (state) => {
      state.requestFailed = false;
      state.requestError = "";
    },
    setRecordsRequest: (state, { payload }) => {
      state.recordsRequest = {
        page: payload.page,
        last: payload.last
      }
    },
    setRecords: (state, { payload }) => {
      state.records = payload;
    },
    addRecords: (state, { payload }) => {
      state.records = [...state.records, ...payload];
    }
  }
});

export const {
  requestStarted,
  requestSucceed,
  requestFailed,
  resetErrors,
  setRecordsRequest,
  setRecords,
  addRecords
} = podRecognitionSlice.actions;

export const podRecognitionSelector = (state: IState) => state.podRecognition;

export const getPodRecognitionRecords = (
  filter: PodRecognitionFilterModel,
  page: number
): AppThunk => async (dispatch) => {
  dispatch(requestStarted(page ? "GET_RECORDS_PORTION" : "GET_RECORDS"));
  const response = await podRecognitionService.getPodRecognitionRecords(filter, page);
  if (response.isCancel?.()) return;
  if (response.ok()) {
    dispatch(setRecordsRequest({
      page: response.data?.number,
      last: response.data?.last
    }));
    dispatch(page ? addRecords(response.data.content) : setRecords(response.data.content));
    dispatch(requestSucceed());
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

const podRecognitionReducer = podRecognitionSlice.reducer;
export default podRecognitionReducer;
