import { createSlice } from "@reduxjs/toolkit";
import { IState } from "../..";
import { AppThunk } from "../../../app/store";
import { ConvertPicklistToRouteState } from "./picklistsState";
import PicklistsService from "../../../app/data/picklists/PicklistsService";
import { getPicklistsLoadingStatus } from "./picklistsLoadingStatusSlice";

const initialState: ConvertPicklistToRouteState = {
  loading: false,
  loadingFailed: false,
  loaded: false,
  error: ""
};

const picklistsService = PicklistsService.getInstance();

export const convertPicklistToRouteSlice = createSlice({
  name: "convertPicklistToRoute",
  initialState,
  reducers: {
    convertPicklistToRouteResetState: (state) => initialState,
    convertPicklistToRouteLoadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
    },
    convertPicklistToRouteLoadingSucceed: (state) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
    },
    convertPicklistToRouteLoadingFailed: (state, { payload }) => {
      state.loading = false;
      state.loadingFailed = true;
      state.error = payload;
    },

  },
});

export const {
  convertPicklistToRouteResetState,
  convertPicklistToRouteLoadingStarted,
  convertPicklistToRouteLoadingSucceed,
  convertPicklistToRouteLoadingFailed,

} = convertPicklistToRouteSlice.actions;

export const convertPicklistToRouteSelector = (state: IState) => state.convertPicklistToRoute;

export const convertPicklistToRoute = (
  picklistNumber: any,
  terminalNumber: any,
  onSuccess?: () => void,
  onError?: (error: string) => void,
  onClose?: () => void
): AppThunk => async (dispatch) => {
  dispatch(convertPicklistToRouteLoadingStarted());
  const response = await picklistsService.convertPicklistToRoute({ picklistNumber });
  if (response.ok() && response.data && response.data.result) {
    onClose && onClose()
    dispatch(convertPicklistToRouteLoadingSucceed());
    dispatch(getPicklistsLoadingStatus(terminalNumber));
    onSuccess && onSuccess();
  } else {
    let error = "Internal Server Error, re-try the action or contact support";
    if (response && response.data && response.data.error) error = response.data.error;
    dispatch(convertPicklistToRouteLoadingFailed(error));
    onError && onError(error)
  }
};

const convertPicklistToRouteReducer = convertPicklistToRouteSlice.reducer;
export default convertPicklistToRouteReducer;