import React, { ReactElement } from "react";
import XGSIcon from "../icon/xgsIcon";
import XGSIcons from "../icon/xgsIcons";
import { cn } from "../../services/common/className";

import "./card.scss";

export interface CardProps {    
  title?: string;
  subtitle?: string;
  icon?: ReactElement;
  onClickEdit?: any;
  handleDelete?: any;
  selectable?: boolean;
  onSelect?: any;
  selected?: boolean;
  hideButtons?: boolean;
  onClick?: () => void;
  mix?: string; 
}

export const Card: React.FC<CardProps> = (props) => {
  return (    
      <div className={cn("card")({ clickable: !!props.onClick }, props.mix)} onClick={props.onClick}>
        <div className="card__header">
          {props.icon && <div className="card__header__icon">
            {props.icon}
          </div>}
          <div className="card__header__content">
            <div              
              className="card__title"
            >
              {props.title}
            </div>
          {props.subtitle && (
            <div className="card__subtitle">
              {props.subtitle}
            </div>
          )}
        </div>
          {!props.hideButtons && (
            <div className="card__buttons">
              {!props.selectable && (
                <>
                  <div
                    onClick={props.onClickEdit}
                    className="card__buttons__edit"
                  >
                    <XGSIcon icon={XGSIcons.faEdit} />{" "}
                    <span className="card__user__buttons--mobile-hidden">Edit</span>
                  </div>
                  <div
                    onClick={props.handleDelete}
                    className="card__buttons__edit"
                  >
                    <XGSIcon icon={XGSIcons.faTrash} />{" "}
                    <span className="card__buttons--mobile-hidden">Remove</span>
                  </div>
                </>
              )}
              {props.selectable && (
                <input
                  type="radio"
                  name="itemSelect"
                  checked={props.selected}
                  onChange={props.onSelect}
                  className="card__radio xgs-form__radio"
                />
              )}
            </div>
          )}
        </div>
        <div className="card__content">
          {props.children}
        </div>
      </div>    
  );
};
