import React, { memo, useEffect, useState } from "react";
import XGSIcon from "../../../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../../../ui-components/icon/xgsIcons";
import "./routeStop.scss";
import AddProbills from "../add-probills/addProbills";
import ConfirmationModal from "../../../../../ui-components/confirmation-modal/confirmationModal";
import { Link } from "react-router-dom";
import { RemoveProbillFromRouteState, RemoveStopFromRouteState, RouteStopsState } from "../../../../../slices/dispatch-planning/routes/routesState";
import { useDispatch, useSelector } from "react-redux";
import { routeStopsSelector } from "../../../../../slices/dispatch-planning/routes/routeStopsSlice";
import { removeProbillFromRoute, removeProbillFromRouteSelector } from "../../../../../slices/dispatch-planning/routes/removeProbillFromRouteSlice";
import { toast } from "react-toastify";
import { removeStopFromRoute, removeStopFromRouteSelector } from "../../../../../slices/dispatch-planning/routes/removeStopFromRouteSlice";

export interface StopProps {
    stopObj: any;
    index: number;
    setDragged: any,
    isAllStopsCollapsed: boolean
}

const RouteStop: React.FC<StopProps> = memo(({ stopObj, index, setDragged, isAllStopsCollapsed }) => {
    const dispatch = useDispatch();
    const [isShowPlannableProbills, setIsShowPlannableProbills] = useState<boolean>(false);
    const [probillSelectedToBeRemoved, setProbillSelectedToBeRemoved] = useState<any>({});
    const [isShowRemoveProbillModal, setIsShowRemoveProbillModal] = useState<boolean>(false);
    const [stopSelectedToBeRemoved, setStopSelectedToBeRemoved] = useState<any>({});
    const [isShowRemoveStopModal, setIsShowRemoveStopModal] = useState<boolean>(false);
    const [isCollapased, setIsCollapsed] = useState<boolean>(false);

    useEffect(() => {
        setIsCollapsed(isAllStopsCollapsed);
    }, [isAllStopsCollapsed]);

    const routesStopsState: RouteStopsState = useSelector(
        routeStopsSelector
    );

    const removeStopFromRouteState: RemoveStopFromRouteState = useSelector(
        removeStopFromRouteSelector
    );

    const removeProbillFromRouteState: RemoveProbillFromRouteState = useSelector(
        removeProbillFromRouteSelector
    );

    const cancleRemoveProbill = () => {
        setProbillSelectedToBeRemoved({});
        setIsShowRemoveProbillModal(false);
    }
    const setRemoveProbill = (probillObj: any) => {
        setProbillSelectedToBeRemoved(probillObj);
        setIsShowRemoveProbillModal(true)
    }
    const removeSelectedProbill = () => {
        dispatch(removeProbillFromRoute(
            { ...probillSelectedToBeRemoved },
            () => { toast.info("Route Edits saved successfully!") },
            (error) => { toast.error(error) },
            () => {
                setProbillSelectedToBeRemoved({});
                setIsShowRemoveProbillModal(false);
            }
        ));
    }
    const cancleRemoveStop = () => {
        setStopSelectedToBeRemoved({});
        setIsShowRemoveStopModal(false);
    }
    const setRemoveStop = (probillObj: any) => {
        setStopSelectedToBeRemoved(probillObj);
        setIsShowRemoveStopModal(true)
    }
    const removeSelectedStop = () => {
        dispatch(removeStopFromRoute(
            { ...stopSelectedToBeRemoved },
            () => { toast.info("Stop Removed successfully!") },
            (error) => { toast.error(error) },
            () => {
                setStopSelectedToBeRemoved({});
                setIsShowRemoveStopModal(false);
            }
        ));
    }
    const getFormattedStopTime = (rawTime: number) => {
        return `${Math.floor(rawTime % 3600 / 60)} min`;
    }
    const getFormattedArrivalTime = (date: number, offset: number) => {
        if (!date) return "";
        let dateTime = new Date(date * 1000 + offset * 60 * 1000).toISOString() || "";
        if (!dateTime) return "";
        // let arrivalDate = `${dateTime.slice(5, 7)}/${dateTime.slice(8, 10)}/${dateTime.slice(0, 4)}`

        let time = `${dateTime.slice(11, 13)}${dateTime.slice(14, 16)}${dateTime.slice(17, 19)}`;
        let timeStr = `000000${time}`.slice(-6);
        let hours = +timeStr.slice(0, 2);
        let minutes = +timeStr.slice(2, 4);
        let modifier = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        let minutesStr = minutes < 10 ? '0' + minutes : minutes;
        let strTime = hours + ':' + minutesStr + ' ' + modifier;
        return strTime;
    }

    return (
        <div className={`route-stop ${((!stopObj.stopNumber || stopObj.stopNumber === 0) && (!routesStopsState.isRouteEditied)) ? "unassigned-stop" : ""}  `}>
            <div className="route-stop-collapse-button"><XGSIcon
                icon={(isCollapased) ? XGSIcons.faPlusSquare : XGSIcons.faMinusSquare}
                onClick={() => { setIsCollapsed(!isCollapased) }} /></div>
            <div className="route-stop-content">
                <div className="route-stop-detail-heading">
                    <div className="route-stop-detail-box">
                        <div className="route-stop-heading">Stop :</div>
                        <div className="route-stop-sequence-number">
                            {(!routesStopsState.isRouteEditied) ? (!stopObj.stopNumber || stopObj.stopNumber === 0) ? <span>Unassigned</span> : stopObj.stopNumber :
                                <>
                                    <span style={{
                                        textDecoration: "line-through",
                                        marginRight: "4px",
                                        opacity: "0.5"
                                    }}>{(!stopObj.stopNumber || stopObj.stopNumber === 0) ? <span>Unassigned</span> : stopObj.stopNumber}</span>
                                    {1 + index}
                                </>
                            }
                        </div>
                        {isCollapased && <div className="route-stop-address">{`, ${stopObj.address}`}</div>}

                    </div>
                    <div className="route-stop-detail-box">
                        <div
                            className="route-stop-probills-item-remove"
                            title="Remove Stop from Route"
                            onClick={() => { setRemoveStop(stopObj) }}
                        >
                            <XGSIcon
                                icon={XGSIcons.faTrashAlt} />
                        </div>

                    </div>
                </div>
                {!isCollapased && <>
                <div className="route-stop-detail-box">
                    <div className="route-stop-heading">Address:</div>
                    <div className="route-stop-address">{stopObj.address}</div>
                </div>
                <div className="route-stop-detail-times">
                    <div className="route-stop-detail-box">
                        <div className="route-stop-heading">Est Arrival:</div>
                        <div className="route-stop-est-arrival"
                            style={(routesStopsState.isRouteEditied) ? { textDecoration: "line-through" } : {}}
                        >{(stopObj.details && stopObj.details.estArrival) ? getFormattedArrivalTime(stopObj.details.estArrival, 0) : ""}</div>
                    </div>
                    <div className="route-stop-detail-box">
                        <div className="route-stop-heading">Stop Time:</div>
                        <div className="route-stop-service-time">{(stopObj.details && stopObj.details.stopTime) ? `${getFormattedStopTime(stopObj.details.stopTime.value)} ` : ""}
                            <span
                                className="route-stop-appointment-details-tool"
                                title={(stopObj.details && stopObj.details.stopTime) ? stopObj.details.stopTime.message : ""}>
                                <XGSIcon
                                    icon={XGSIcons.faQuestionCircle} /></span>
                        </div>
                    </div>
                </div>
                <div className="route-stop-detail-box">
                    <div className="route-stop-heading">Appointment:</div>
                    <div className="route-stop-appointment">{(stopObj.details && stopObj.details.appointmentWindow) ? stopObj.details.appointmentWindow : ""} <span
                        className="route-stop-appointment-details-tool"
                        title={(stopObj.details && stopObj.details.appointmentDetails) ? stopObj.details.appointmentDetails : ""}>
                        <XGSIcon
                            icon={XGSIcons.faQuestionCircle} /></span></div>
                </div>
                <div className="route-stop-probills">
                    <div className="route-stop-heading">Probills:</div>
                    <div className="route-stop-probills-list">
                        {(stopObj.probills && stopObj.probills.length) ? stopObj.probills.map((probillObj: any) =>
                            <div
                                id={`${probillObj.probillNumber}_p`}
                                className="route-stop-probills-item">
                                <div className="route-stop-probills-item-probill-number">
                                    <Link
                                        className="route-stop-probills-item-probill-number"
                                        to={`/shipments/${probillObj.probillNumber}`}
                                        target={"_blank"}
                                        rel={"noopener noreferrer"}
                                    >
                                        {probillObj.probillNumber}
                                    </Link>
                                </div>
                                <div className="route-stop-probills-item-consignee-name">{probillObj.consigneeName}</div>
                                <div
                                    className="route-stop-probills-item-remove"
                                    title="Remove Probill from Route"
                                    onClick={() => { setRemoveProbill(probillObj) }}
                                >
                                    <XGSIcon
                                        icon={XGSIcons.faTimesCircle} />
                                </div>
                            </div>
                        ) : ""
                        }
                        {/* <div
                            className="add-probill-to-stop"
                            onClick={() => { setIsShowPlannableProbills(!isShowPlannableProbills) }}
                        >
                            <div
                                className="route-stop-probills-item-add"
                                title="Add Probils to stop"
                            >
                                Add Probills
                                <span className="route-stop-probills-item-add-icon">
                                    <XGSIcon
                                        icon={XGSIcons.faPlusCircle} />
                                </span>
                            </div>
                        </div> */}
                    </div>
                </div>
                </>}
            </div>
            <div className="route-stop-drag-bar"><XGSIcon
                icon={XGSIcons.faGripVertical}
                onMouseDown={(e) => {
                    e.preventDefault();
                    setDragged(index);
                }} /></div>
            {isShowPlannableProbills &&
                <AddProbills
                    show={isShowPlannableProbills}
                    routeId={stopObj.routeId}
                    stopNumber={(1 + index !== stopObj.stopNumber) ? 1 + index : stopObj.stopNumber}
                    stopInternalId={stopObj.stopInternalId}
                    onClose={setIsShowPlannableProbills}
                    terminalNumber={`${stopObj.terminalNumber}`}
                />
            }
            {
                <ConfirmationModal
                    opened={isShowRemoveProbillModal}
                    header="Remove Probill"
                    confirmButtonText="Remove"
                    spinner={removeProbillFromRouteState.loading}
                    onCancel={() => { cancleRemoveProbill() }}
                    onConfirm={() => { removeSelectedProbill(); }}
                >
                    The Probill- {probillSelectedToBeRemoved.probillNumber} will be removed
                </ConfirmationModal>
            }
            {
                <ConfirmationModal
                    opened={isShowRemoveStopModal}
                    header="Remove Stop"
                    confirmButtonText="Remove"
                    spinner={removeStopFromRouteState.loading}
                    onCancel={() => { cancleRemoveStop() }}
                    onConfirm={() => { removeSelectedStop(); }}
                >
                    The Stop Number- {stopSelectedToBeRemoved.stopNumber} will be removed
                </ConfirmationModal>
            }
        </div>

    );
});

export default RouteStop;