import React, { useEffect, useRef, useState } from "react";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import { ReactComponent as SendMessageIcon } from "../../../../images/send_message.svg";
import ChatMessage from "./chatMessage";
import { cn } from "../../../../services/common/className";
import { ChatMessageModel, ChatPromptModel } from "../../../../app/data/chat/models";
import GrowingTextarea from "../../../../ui-components/growing-textarea/growingTextarea";

import "./chat.scss";

export interface ChatProps {
  messages: ChatMessageModel[];
  mix?: string;
  onSend: (message: ChatMessageModel) => void;
  isLoading: boolean;
  isDisabled: boolean;
  animated: boolean;
}

const Chat: React.FC<ChatProps> = (props) => {
  const [message, setMessage] = useState("");
  const messagesContainerRef = useRef<HTMLDivElement | null>(null);

  const send = () => {
    if (!message) return;

    props.onSend({ text: message });
    setMessage("");
  }

  const suggest = (prompt: ChatPromptModel) => {
    props.onSend({ promptId: prompt.promptId, text: prompt.promptMessage });
  };

  useEffect(() => {
    if (messagesContainerRef?.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    } 
  }, [props.messages, props.isLoading]);

  return (
    <div className={cn("xgs-chat")({animated: props.animated}, props.mix)}>
      <div className="xgs-chat__messages" ref={messagesContainerRef}>
        {props.messages.map((item, i) => (
          <>
            {item.newGroup && <div className="xgs-chat__divider" />}
            <ChatMessage
              key={i}
              message={item}
              onPromptClick={suggest}
            />
          </>
        ))}

        {props.isLoading && (
          <ChatMessage message={{incoming: true, text: ""}} isLoading={true}/>
        )}
      </div>
      <div className="xgs-chat__controls">
        <GrowingTextarea
          name="message"
          value={message}
          onChange={(e) => setMessage(e.currentTarget.value)}
          disabled={props.isDisabled}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              e.currentTarget.value.trim() && send();
            }
          }}
        />
        <Button
          theme={ButtonThemes.blue}
          onClick={send}
          disabled={props.isDisabled || !message.trim()}
          mods={{ "only-icon": true }}
        >
          <SendMessageIcon />
        </Button>
      </div>
    </div>
  );
};

export default Chat;
