import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import WarehouseService from "../../../app/data/warehouse/warehouseService";
import { ReceiptsListRequestModel } from "../../../app/data/warehouse/models";
import { initialReceiptsState } from "./ReceiptsState";

const warehouseService = WarehouseService.getInstance();

export const receiptsSlice = createSlice({
  name: "receipts",
  initialState: initialReceiptsState,
  reducers: {
    resetReceiptsState: (state) => initialReceiptsState,
    requestStarted: (state, { payload }) => {
      state.request = payload;
      if (state.request.lastIds) {
        state.requestStarted = true;
      } else {
        state.initialRequestStarted = true;
      }
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestError = null;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.initialRequestStarted = false;
      state.requestFailed = true;
      state.requestSucceed = false;
      state.requestError = payload;
    },
    requestSucceed: (state, { payload }) => {
      state.requestStarted = false;
      state.initialRequestStarted = false;
      state.requestFailed = false;
      state.requestSucceed = true;
      state.requestError = null;
      state.requestedAll = !!(payload.content.length < 50);
      state.list = state.request.lastIds ? [...state.list, ...payload?.content] : payload?.content;
      state.request.lastIds = payload.scroll?.lastIds;
    } 
  }
});

export const {
  resetReceiptsState,
  requestStarted,
  requestFailed,
  requestSucceed
} = receiptsSlice.actions;

export const receiptsSelector = (state: IState) => {
  return state.receipts;
};

export const getReceiptsList = (
  subAccountId: string,
  request: ReceiptsListRequestModel,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted(request));
  const response = await warehouseService.getReceiptsList(subAccountId, request);
  if (response.ok()) {
    dispatch(requestSucceed(response.data));
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

const receiptsReducer = receiptsSlice.reducer;
export default receiptsReducer;
