import React, { useEffect, useState } from "react";
import moment from "moment";
import Button, { ButtonThemes } from "../../../ui-components/button/button";

import PickupCancel from "../../pickup/management/pickupCancel";
import PickupReschedule from "../../pickup/management/pickupReschedule";
import boxArrowIcon from "../../../images/box-arrow-up_blue.svg";
import exclamationIcon from "../../../images/exclamation-triangle_red.svg";
import "../bol.scss";
import { CustomerPickup } from "../../../app/data/customer-pickup/models";

export interface PickupDetailsProps {
  pickup: CustomerPickup;
  isReturn?: boolean;
  onCancelPickup: () => void;
  onReschedulePickup: (
    newPickupId: string,
    newPickupNumber: string,
    date: string,
    readyTime: string,
    closeTime: string,
  ) => void;
};

const PickupDetails: React.FC<PickupDetailsProps> = (props) => {  
  const [showReschedulePickup, setShowReschedulePickup] = useState(false);
  const [showCancelPickup, setShowCancelPickup] = useState(false);  
  const [dataShaking, setDataShaking] = useState(false);  

  useEffect(() => {
    setShowCancelPickup(false);
    setShowReschedulePickup(false);
  }, [props.pickup]);

  return (
    <div className="xgs-bol__details__block">
      <div className="flex-space-between flex-wrap gap-16">
        <div className="xgs-bol__details__block__header">
          <div className="xgs-bol__details__block__header__icon">
            <img src={boxArrowIcon} alt="box" />
          </div>
          <div className={`xgs-bol__details__block__header__text${dataShaking ? " xgs-animation__shaking" : ""}`}>
            Pickup {props.pickup.pickupNumber && (<span className="xgs-bol__details__pickup-number">{props.pickup.pickupNumber}</span>)}
          </div>
        </div>
        <div className={`xgs-bol__details__pickup-date${dataShaking ? " xgs-animation__shaking" : ""}`}>
          {props.pickup.pickupDate.toUiDateFormat()} {moment(props.pickup.pickupReadyTime, "hh:mm:ss").format("h:mm a")}
          {props.pickup.pickupCloseTime && <> - {moment(props.pickup.pickupCloseTime, "hh:mm:ss").format("h:mm a")}</>}
        </div>
      </div>
      {props.pickup.pickupNumber && props.pickup.pickupStatus !== "CANCELED" && props.pickup.pickupStatus !== "COMPLETED" && (
        <div className="xgs-bol__details__controls">
          <Button
            theme={ButtonThemes.blue}
            onClick={() => setShowReschedulePickup(true)}
            type="button"
          >
            Reschedule Pickup
          </Button>
          <Button
            theme={ButtonThemes.redInverted}
            onClick={() => setShowCancelPickup(true)}
            type="button"
          >
            Cancel Pickup
          </Button>
          <PickupReschedule
            show={showReschedulePickup}
            pickupId={props.pickup.pickupId}
            pickupNumber={props.pickup.pickupNumber}
            shipperZip={props.pickup.shipperZip}
            onReschedule={(newPickupId: string, newPickupNumber: string, date: string, readyTime: string, closeTime: string) => {
              props.onReschedulePickup(newPickupId, newPickupNumber, date, readyTime, closeTime);
              setShowReschedulePickup(false);
              setDataShaking(true);
              setTimeout(() => setDataShaking(false), 1000);
            }}
            onClose={() => setShowReschedulePickup(false)}
            type="CUSTOMER"
          />
          <PickupCancel
            show={showCancelPickup}
            pickupId={props.pickup.pickupId}
            pickupNumber={props.pickup.pickupNumber}
            onCancel={() => {
              props.onCancelPickup();
              setShowCancelPickup(false);
            }}
            onClose={() => setShowCancelPickup(false)}
            type="CUSTOMER"
          />
        </div>
      )}
      {(props.pickup.pickupStatus === "CANCELED") && (
        <div className="xgs-bol__details__pickup-canceled">
          <div className="xgs-bol__details__pickup-canceled__icon"><img src={exclamationIcon} alt="exclamation" /></div>
          <div>This pickup has been canceled.</div>
        </div>
      )}
    </div>
  );
};

export default PickupDetails;
