import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";
import Loading from "../../../ui-components/loading/loading";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import {
  ClaimDocumentModel,
  ClaimModel
} from "../../../app/data/claims/models";
import { Routes } from "../../../app/route/RoutesConfig";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import ClaimsState from "../../../slices/claims/ClaimsState";
import {
  claimsSelector,
  removeDocumentData,
  resetClaimsState,
  submitClaim,
  uploadClaimDocument
} from "../../../slices/claims/claimsSlice";
import ShipmentDetailsState from "../../../slices/shipment-details/ShipmentDetailsState";
import {
  checkStatusHistory,
  shipmentDetailsSelector
} from "../../../slices/shipment-details/shipmentDetailsSlice";
import { StepProps } from "../../../app/data/common/models";
import {
  UPLOAD_MAX_SIZE,
  UPLOAD_FILE_TYPES
} from "../constants";
import "../claims.scss";

const ClaimDocuments: React.FC<StepProps> = (props) => {
  const { previous } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const userState: UserState = useSelector(userSelector);
  const claimsState: ClaimsState = useSelector(claimsSelector);
  const shipmentDetailsState: ShipmentDetailsState = useSelector(shipmentDetailsSelector);
  const [manufactureInvoiceFiles, setManufactureInvoiceFiles] = useState<any>([]);
  const [manufactureInvoiceFileError, setManufactureInvoiceFileError] = useState<string>("");
  const [damagedPiecesFiles, setDamagedPiecesFiles] = useState<any>([]);
  const [damagedPiecesFileError, setDamagedPiecesFileError] = useState<string>("");
  const [pickupConfirmationFiles, setPickupConfirmationFiles] = useState<any>([]);
  const [pickupConfirmationFileError, setPickupConfirmationFileError] = useState<string>("");
  const [otherDocumentsFiles, setOtherDocumentsFiles] = useState<any>([]);
  const [otherDocumentsFileError, setOtherDocumentsFileError] = useState<string>("");

  const {
    getRootProps:getManufactureInvoiceRootProps,
    getInputProps:getManufactureInvoiceInputProps
  } = useDropzone({
    accept: UPLOAD_FILE_TYPES,
    maxSize: UPLOAD_MAX_SIZE * 1048576,
    maxFiles: 1,
    disabled: claimsState.requestStarted,
    onDrop: (acceptedFiles, fileRejections) => {
      setManufactureInvoiceFileError("");
      if (fileRejections?.length > 0) {
        fileRejections[0].errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setManufactureInvoiceFileError(`File no larger than ${UPLOAD_MAX_SIZE} MB is allowed!`);
          }
          if (err.code === "file-invalid-type") {
            setManufactureInvoiceFileError("Only file of certain formats is allowed!");
          }
          if (err.code === "too-many-files") {
            setManufactureInvoiceFileError("Only one file is allowed!");
          }
        });
      }
      if (acceptedFiles.length === 0) return;
      setManufactureInvoiceFiles([...acceptedFiles]);
      dispatch(uploadClaimDocument("INVOICE", acceptedFiles, (error: any) => {
        toast.error(error, { autoClose: 4000 });
        setManufactureInvoiceFiles([]);
      }));
    }
  });

  const {
    getRootProps:getDamagedRootProps,
    getInputProps:getDamagedInputProps
  } = useDropzone({
    accept: UPLOAD_FILE_TYPES,
    maxSize: UPLOAD_MAX_SIZE * 1048576,
    maxFiles: 5 - damagedPiecesFiles.length,
    disabled: claimsState.requestStarted,
    onDrop: (acceptedFiles, fileRejections) => {
      setDamagedPiecesFileError("");
      if (fileRejections?.length > 0) {
        fileRejections[0].errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setDamagedPiecesFileError(`Files no larger than ${UPLOAD_MAX_SIZE} MB are allowed!`);
          }
          if (err.code === "file-invalid-type") {
            setDamagedPiecesFileError("Only files of certain formats are allowed!");
          }
          if (err.code === "too-many-files") {
            setDamagedPiecesFileError("Only 5 files are allowed!");
          }
        });
      }
      if (acceptedFiles.length === 0) return;
      setDamagedPiecesFiles([...acceptedFiles]);
      dispatch(uploadClaimDocument("DAMAGE", acceptedFiles, (error: any) => {
        toast.error(error, { autoClose: 4000 });
        setDamagedPiecesFiles([]);
      }));
    }
  });

  const {
    getRootProps:getPickupConfirmationRootProps,
    getInputProps:getPickupConfirmationInputProps
  } = useDropzone({
    accept: UPLOAD_FILE_TYPES,
    maxSize: UPLOAD_MAX_SIZE * 1048576,
    maxFiles: 1,
    disabled: claimsState.requestStarted,
    onDrop: (acceptedFiles, fileRejections) => {
      setPickupConfirmationFileError("");
      if (fileRejections?.length > 0) {
        fileRejections[0].errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setPickupConfirmationFileError(`File no larger than ${UPLOAD_MAX_SIZE} MB is allowed!`);
          }
          if (err.code === "file-invalid-type") {
            setPickupConfirmationFileError("Only file of certain formats is allowed!");
          }
          if (err.code === "too-many-files") {
            setPickupConfirmationFileError("Only one file is allowed!");
          }
        });
      }
      if (acceptedFiles.length === 0) return;
      setPickupConfirmationFiles([...acceptedFiles]);
      dispatch(uploadClaimDocument("PICKUP_CONFIRMATION", acceptedFiles, (error: any) => {
        toast.error(error, { autoClose: 4000 });
        setPickupConfirmationFiles([]);
      }));
    }
  });

  const {
    getRootProps:getOtherDocumentsRootProps,
    getInputProps:getOtherDocumentsInputProps
  } = useDropzone({
    accept: UPLOAD_FILE_TYPES,
    maxSize: UPLOAD_MAX_SIZE * 1048576,
    maxFiles: 3 - otherDocumentsFiles.length,
    disabled: claimsState.requestStarted,
    onDrop: (acceptedFiles, fileRejections) => {
      setOtherDocumentsFileError("");
      if (fileRejections?.length > 0) {
        fileRejections[0].errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setOtherDocumentsFileError(`Files no larger than ${UPLOAD_MAX_SIZE} MB are allowed!`);
          }
          if (err.code === "file-invalid-type") {
            setOtherDocumentsFileError("Only files of certain formats are allowed!");
          }
          if (err.code === "too-many-files") {
            setOtherDocumentsFileError("Only 3 files are allowed!");
          }
        });
      }
      if (acceptedFiles.length === 0) return;
      setOtherDocumentsFiles([...acceptedFiles]);
      dispatch(uploadClaimDocument("OTHER", acceptedFiles, (error: any) => {
        toast.error(error, { autoClose: 4000 });
        setOtherDocumentsFiles([]);
      }));
    }
  });

  const onRemoveFile = (fileId: string) => {
    dispatch(removeDocumentData(fileId));
  };

  const onClickBack = () => {
    previous && previous();
  };

  const onClickSubmit = () => {
    if (!claimsState.general || !claimsState.contact || !claimsState.remitTo) return;
    let items = claimsState.items.filter(item => !item.deleted);
    let documents: ClaimDocumentModel[] = [];
    for (const documentData of claimsState.documentsData) {
      if (documentData.type !== "DAMAGE" || claimsState.general?.reason === "VISIBLE" || claimsState.general?.reason === "CONCEALED") {
        documents.push({
          type: documentData.type,
          id: documentData.id
        });
      }
    }
    let request: ClaimModel = {
      details: claimsState.general,
      contactInformation: {
        ...claimsState.contact,
        phoneAllowSms: claimsState.contact.phoneAllowSms || false
      },
      remitToInformation: claimsState.remitTo,
      ...((claimsState.claimAllItems?.value === "SPECIFIC_ITEMS") && {claimedItems: items}),
      ...((claimsState.freightChargesOnly?.value === "FILE_ON_FREIGHT_CHARGES" || claimsState.claimAllItems?.value === "ALL_ITEMS") && {claimedFreightCharges: claimsState.freightCharges}),
      ...(claimsState.claimAllItems?.value === "ALL_ITEMS" && {totalClaimAmount: claimsState.totalClaimAmount}),
      documents: documents
    };
    dispatch(submitClaim(request, () => {
      dispatch(resetClaimsState());
      mixpanel.track("Claim was created");
      toast.info(<>The claim was successfully sent!<br /> We will contact you as soon as possible.</>);
      history.push(`/${userState.activeSubAccount?.accountNumber}${Routes.claims.list}`);
    }));
  };

  const isSubmitDisabled = () => {
    return manufactureInvoiceFiles.length === 0
      || ((claimsState.general?.reason === "VISIBLE" || claimsState.general?.reason === "CONCEALED") && damagedPiecesFiles.length === 0)
      || (!shipmentDetailsState.statusHistoryPresent && pickupConfirmationFiles.length === 0)
      || (claimsState.requestStarted && claimsState.requestCreator !== "SUBMIT_CLAIM");
  };

  useEffect(() => {
    setManufactureInvoiceFiles([]);
    setDamagedPiecesFiles([]);
    setPickupConfirmationFiles([]);
    setOtherDocumentsFiles([]);
    if (claimsState.documentsData.length > 0) {
      for (const documentData of claimsState.documentsData) {
        const documentPreparedObj = {
          path: documentData.originalFilename,
          id: documentData.id
        }
        if (documentData.type === "INVOICE") {
          setManufactureInvoiceFiles((original: any) => [...original, documentPreparedObj]);
        } else if (documentData.type === "DAMAGE") {
          setDamagedPiecesFiles((original: any) => [...original, documentPreparedObj]);
        } else if (documentData.type === "PICKUP_CONFIRMATION") {
          setPickupConfirmationFiles((original: any) => [...original, documentPreparedObj]);
        } else if (documentData.type === "OTHER") {
          setOtherDocumentsFiles((original: any) => [...original, documentPreparedObj]);
        }
      }
    }
    // eslint-disable-next-line
  }, [claimsState.documentsData]);

  useEffect(() => {
    if (!claimsState.general?.probillNumber) return;
    dispatch(checkStatusHistory(Number(claimsState.general.probillNumber)));
  }, [dispatch, claimsState.general]);

  return (
    <div className="xgs-claims__form">
      <div className="xgs-wizard__step__header">Documents</div>
      <div className="xgs-claims__section">
        <div className="xgs-claims__documents__item">
          <div className="xgs-form__label">Copy of the manufacture's invoice:<span>*</span></div>
          {claimsState.requestStarted && claimsState.requestCreator === "INVOICE" && (
            <div className="xgs-bol__return__reference__file-uploading">
              <Loading isLoading={true} /> <span>Uploading...</span>
            </div>
          )}
          {!(claimsState.requestStarted && claimsState.requestCreator === "INVOICE") && (
            <>
              {(manufactureInvoiceFiles.length > 0) && (
                <>
                  {manufactureInvoiceFiles.map((manufactureInvoiceFile: any, index: number) => (
                    <div className="xgs-bol__return__reference__file" key={"invoice-file-" + index} style={{marginBottom: 4}}>
                      <div className="xgs-bol__return__reference__file__name">
                        {manufactureInvoiceFile.path}
                      </div>
                      <div
                        className="xgs-bol__return__reference__file__delete"
                        onClick={() => onRemoveFile(manufactureInvoiceFile.id)}
                      >
                        <XGSIcon
                          icon={XGSIcons.faTimes}
                          className="xgs-bol__return__reference__file__delete__icon"
                        />
                      </div>
                    </div>
                  ))}
                </>
              )}
              {(manufactureInvoiceFiles.length === 0) && (
                <div {...getManufactureInvoiceRootProps({ className: "xgs-upload__area" })}>
                  <input {...getManufactureInvoiceInputProps()} />
                  <span className="blue-link">tap, click or drag &amp; drop document here</span>
                  <div className="xgs-upload__notes">(<strong>PDF</strong>, <strong>JPEG</strong> and <strong>PNG</strong> formats only)</div>
                  {manufactureInvoiceFileError && (
                    <span className="xgs-upload__area__error">
                      {manufactureInvoiceFileError}
                    </span>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        {(claimsState.general?.reason === "CONCEALED" || claimsState.general?.reason === "VISIBLE") && (
          <div className="xgs-claims__documents__item">
            <div className="xgs-form__label">Picture(s) of damaged piece(s):<span>*</span></div>
            {claimsState.requestStarted && claimsState.requestCreator === "DAMAGE" && (
              <div className="xgs-bol__return__reference__file-uploading">
                <Loading isLoading={true} /> <span>Uploading...</span>
              </div>
            )}
            {!(claimsState.requestStarted && claimsState.requestCreator === "DAMAGE") && (
              <>
                {(damagedPiecesFiles.length > 0) && (
                  <>
                    {damagedPiecesFiles.map((damagedPiecesFile: any, index: number) => (
                      <div className="xgs-bol__return__reference__file" key={"damaged-pieces-file-" + index} style={{marginBottom: 4}}>
                        <div className="xgs-bol__return__reference__file__name">
                          {damagedPiecesFile.path}
                        </div>
                        <div
                          className="xgs-bol__return__reference__file__delete"
                          onClick={() => onRemoveFile(damagedPiecesFile.id)}
                        >
                          <XGSIcon
                            icon={XGSIcons.faTimes}
                            className="xgs-bol__return__reference__file__delete__icon"
                          />
                        </div>
                      </div>
                    ))}
                  </>
                )}
                {(damagedPiecesFiles.length < 5) && (
                  <div {...getDamagedRootProps({ className: "xgs-upload__area" })}>
                    <input {...getDamagedInputProps()} />
                    <span className="blue-link">tap, click or drag &amp; drop documents here</span>
                    <div className="xgs-upload__notes">(<strong>PDF</strong>, <strong>JPEG</strong> and <strong>PNG</strong> formats only)</div>
                    {damagedPiecesFileError && (
                      <span className="xgs-upload__area__error">
                        {damagedPiecesFileError}
                      </span>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {!shipmentDetailsState.loading && !shipmentDetailsState.statusHistoryPresent && (
          <div className="xgs-claims__documents__item">
            <div className="xgs-form__label">Pickup confirmation:<span>*</span></div>
            {claimsState.requestStarted && claimsState.requestCreator === "PICKUP_CONFIRMATION" && (
              <div className="xgs-bol__return__reference__file-uploading">
                <Loading isLoading={true} /> <span>Uploading...</span>
              </div>
            )}
            {!(claimsState.requestStarted && claimsState.requestCreator === "PICKUP_CONFIRMATION") && (
              <>
                {(pickupConfirmationFiles.length > 0) && (
                  <>
                    {pickupConfirmationFiles.map((pickupConfirmationFile: any, index: number) => (
                      <div className="xgs-bol__return__reference__file" key={"pickup-file-" + index} style={{marginBottom: 4}}>
                        <div className="xgs-bol__return__reference__file__name">
                          {pickupConfirmationFile.path}
                        </div>
                        <div
                          className="xgs-bol__return__reference__file__delete"
                          onClick={() => onRemoveFile(pickupConfirmationFile.id)}
                        >
                          <XGSIcon
                            icon={XGSIcons.faTimes}
                            className="xgs-bol__return__reference__file__delete__icon"
                          />
                        </div>
                      </div>
                    ))}
                  </>
                )}
                {(pickupConfirmationFiles.length === 0) && (
                  <div {...getPickupConfirmationRootProps({ className: "xgs-upload__area" })}>
                    <input {...getPickupConfirmationInputProps()} />
                    <span className="blue-link">tap, click or drag &amp; drop documents here</span>
                    <div className="xgs-upload__notes">(<strong>PDF</strong>, <strong>JPEG</strong> and <strong>PNG</strong> formats only)</div>
                    {pickupConfirmationFileError && (
                      <span className="xgs-upload__area__error">
                        {pickupConfirmationFileError}
                      </span>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        )}
        <div className="xgs-claims__documents__item">
          <div className="xgs-form__label">Other related documents:</div>
          {claimsState.requestStarted && claimsState.requestCreator === "OTHER" && (
            <div className="xgs-bol__return__reference__file-uploading">
              <Loading isLoading={true} /> <span>Uploading...</span>
            </div>
          )}
          {!(claimsState.requestStarted && claimsState.requestCreator === "OTHER") && (
            <>
              {(otherDocumentsFiles.length > 0) && (
                <>
                  {otherDocumentsFiles.map((otherDocumentsFile: any, index: number) => (
                    <div className="xgs-bol__return__reference__file" key={"other-file-" + index} style={{marginBottom: 4}}>
                      <div className="xgs-bol__return__reference__file__name">
                        {otherDocumentsFile.path}
                      </div>
                      <div
                        className="xgs-bol__return__reference__file__delete"
                        onClick={() => onRemoveFile(otherDocumentsFile.id)}
                      >
                        <XGSIcon
                          icon={XGSIcons.faTimes}
                          className="xgs-bol__return__reference__file__delete__icon"
                        />
                      </div>
                    </div>
                  ))}
                </>
              )}
              {(otherDocumentsFiles.length < 3) && (
                <div {...getOtherDocumentsRootProps({ className: "xgs-upload__area" })}>
                  <input {...getOtherDocumentsInputProps()} />
                  <span className="blue-link">tap, click or drag &amp; drop documents here</span>
                  <div className="xgs-upload__notes">(<strong>PDF</strong>, <strong>JPEG</strong> and <strong>PNG</strong> formats only)</div>
                  {otherDocumentsFileError && (
                    <span className="xgs-upload__area__error">
                      {otherDocumentsFileError}
                    </span>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="xgs-wizard__step__buttons">
        <Button
          type="button"
          theme={ButtonThemes.gray}
          onClick={onClickBack}
        >
          Back
        </Button>
        <Button
          type="button"
          theme={ButtonThemes.blue}
          disabled={isSubmitDisabled()}
          onClick={onClickSubmit}
          spinner={claimsState.requestStarted && claimsState.requestCreator === "SUBMIT_CLAIM"}
        >
          Submit Claim
        </Button>
      </div>
    </div>
  );
};

export default ClaimDocuments;
