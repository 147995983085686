import React, { memo, useEffect, useRef, useState } from "react";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import { useSelector, useDispatch } from "react-redux";
import SlideOutSidebar from "../../../../ui-components/slide-out-sidebar/slideOutSidebar";
import { Form, Formik } from "formik";
import { ProbillsRouteSchema, defaultStopTimeOptions, initialFormValues, routeDurationOptions, timeOptions, trailerCapacityOptions } from "../../../../app/data/plan-probills/models";
import XGSFormSelect from "../../../../ui-components/form/select/xgsFormSelect";
import { LabelModes } from "../../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledDateInput from "../../../../ui-components/form/date/xgsFormDate";
import XGSFormInput from "../../../../ui-components/form/input/xgsFormInput";
import { DispatchEditRouteDetailsState } from "../../../../slices/dispatch-planning/dispatchProbillsState";
import { toast } from "react-toastify";
import { dispatchEditRouteDetailsSelector, editRouteDetails, resetState } from "../../../../slices/dispatch-planning/dispatchEditRouteDetailsSlice";
import { XGSSelectOption } from "../../../../ui-components/xgs-select/xgsSelect";
import XGSIcon from "../../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../../ui-components/icon/xgsIcons";
import "./editRouteDetails.scss";
import ConfirmationModal from "../../../../ui-components/confirmation-modal/confirmationModal";
import { DeleteRouteState } from "../../../../slices/dispatch-planning/routes/routesState";
import { deleteRoute, deleteRouteSelector } from "../../../../slices/dispatch-planning/routes/deleteRouteSlice";

interface EditRouteDetailsProps {
    show: boolean;
    onClose?: any;
    routeDetails: any;
}
const EditRouteDetails: React.FC<EditRouteDetailsProps> = memo((props) => {
    const dispatch = useDispatch();
    const dispatchEditRouteDetailsState: DispatchEditRouteDetailsState = useSelector(
        dispatchEditRouteDetailsSelector
    );
    const deleteRouteState: DeleteRouteState = useSelector(
        deleteRouteSelector
    );
    const [startTime, setStartTime] = useState<XGSSelectOption>();
    const [routeDuration, setRouteDuration] = useState<XGSSelectOption>();
    const [trailerCapacity, setTrailerCapacity] = useState<XGSSelectOption>();
    const [defaultStopTime, setDefaultStopTime] = useState<XGSSelectOption>();
    const [isShowDeleteRouteModal, setIsShowDeleteRouteModal] = useState<boolean>(false);
        
    const formRef = useRef<any>(null);
    const getRouteEndTs = (startDate: string, startTime: string, routeDuration: string): Object => {
        let startDateTime = new Date(`${startDate.slice(0, 4)}-${startDate.slice(4, 6)}-${startDate.slice(6, 8)}T${startTime.slice(0, 2)}:${startTime.slice(2, 4)}:${startTime.slice(4, 6)}.000+00:00`);
        startDateTime.setHours(startDateTime.getHours() + (+routeDuration.slice(0, 2)));
        let startTs = startDateTime.toISOString();
        return { endDate: `${startTs.slice(0, 4)}${startTs.slice(5, 7)}${startTs.slice(8, 10)}`, endTime: `${startTs.slice(11, 13)}${startTs.slice(14, 16)}${startTs.slice(17, 19)}` };
    }
    const getRouteStartTime = (startTime: number) => {
        let startTimeStr = `000000${startTime}`.slice(-6);
        let routeStartTime = timeOptions[0];
        timeOptions.forEach(option => {
            if (option.value === startTimeStr) {
                routeStartTime = option;
            }
        });
        setStartTime(routeStartTime);
        return routeStartTime.value;
    }

    const getRouteDurration = (routeDetails: any): Object => {
        let startDate = `${routeDetails.startDate}`;
        let startTime = `000000${routeDetails.startTime}`.slice(-6);
        let startDateTime = new Date(`${startDate.slice(0, 4)}-${startDate.slice(4, 6)}-${startDate.slice(6, 8)}T${startTime.slice(0, 2)}:${startTime.slice(2, 4)}:${startTime.slice(4, 6)}.000+00:00`);
        let endDate = `${routeDetails.endDate}`;
        let endTime = `000000${routeDetails.endTime}`.slice(-6);
        let endDateTime = new Date(`${endDate.slice(0, 4)}-${endDate.slice(4, 6)}-${endDate.slice(6, 8)}T${endTime.slice(0, 2)}:${endTime.slice(2, 4)}:${endTime.slice(4, 6)}.000+00:00`);
        let hoursDifferece = Math.floor(Math.abs(endDateTime.getTime() - startDateTime.getTime()) / 3600000);
        let durration = `00${hoursDifferece}0000`.slice(-6);
        let routeDuration = routeDurationOptions[0];
        routeDurationOptions.forEach(option => {
            if (option.value === durration) {
                routeDuration = option;
            }
        });
        setRouteDuration(routeDuration);
        return routeDuration.value;
    }
    const getRouteTrailerCapacity = (trailerCapacity: number) => {
        let trailerCapacityString = `${trailerCapacity}`;
        let routeTrailerCapacity = trailerCapacityOptions[0];
        trailerCapacityOptions.forEach(option => {
            if (option.value === trailerCapacityString) {
                routeTrailerCapacity = option;
            }
        });
        setTrailerCapacity(routeTrailerCapacity)
        return routeTrailerCapacity.value;
    }
    const getRouteDefaultStopTime = (defaultStopTime: number) => {
        let defaultStopTimeStr = `000000${defaultStopTime}`.slice(-6);
        let routeDefaultStopTime = defaultStopTimeOptions[0];
        defaultStopTimeOptions.forEach(option => {
            if (option.value === defaultStopTimeStr) {
                routeDefaultStopTime = option;
            }
        });
        setDefaultStopTime(routeDefaultStopTime);
        return routeDefaultStopTime.value;
    }
    const preFillFormValues = () => {
        console.log(formRef.current);
        formRef.current?.setFieldValue("routeName", props.routeDetails.routeName);
        formRef.current?.setFieldValue("startDate", `${props.routeDetails.startDate}`);
        formRef.current?.setFieldValue("startTime", getRouteStartTime(props.routeDetails.startTime));
        formRef.current?.setFieldValue("routeDuration", getRouteDurration(props.routeDetails));
        formRef.current?.setFieldValue("trailerNumber", props.routeDetails.trailerNumber);
        formRef.current?.setFieldValue("trailerCapacity", getRouteTrailerCapacity(props.routeDetails.trailerCapacity));
        formRef.current?.setFieldValue("defaultStopTime", getRouteDefaultStopTime(props.routeDetails.defaultStopTime));
    };
    useEffect(() => {
        dispatch(resetState())
        preFillFormValues();
        // eslint-disable-next-line
    }, [ props.routeDetails])

    const deleteSelectedRoute = () => {
        dispatch(deleteRoute(
            {
                routeId: props.routeDetails.routeId,
                terminalNumber: props.routeDetails.terminalNumber,
            },
            () => { toast.info("Route Deleted successfully!") },
            (error) => { toast.error(error) },
            () => {
                props.onClose(false);
            }
        ));
    }
    const onSubmit = (values: any) => {
        dispatch(editRouteDetails(
            {
                ...values,
                ...getRouteEndTs(values.startDate, values.startTime, values.routeDuration),
                terminalNumber: props.routeDetails.terminalNumber,
                routeId: props.routeDetails.routeId
            },
            () => { toast.info("Route Edits saved successfully!") },
            (error) => { toast.error(error) },
            () => { props.onClose() }
        )
        )
    };

    const getFormattedDate = (date: string) => {
        if (!date || date.length !== 10) return "";
        else return `${date.slice(6, 10)}${date.slice(0, 2)}${date.slice(3, 5)}`;
    }

    return (
        <SlideOutSidebar
            header={`Edit Route Details of ${props.routeDetails.routeId}`}
            onClose={() => {
                props.onClose(false);
            }}
            show={props.show}
            spinner={false}
            size="small"
        >
            <div className="xgs-edit-route-details__delete-route"
                onClick={() => { setIsShowDeleteRouteModal(true) }}
            >
                <XGSIcon className="xgs-edit-route-details__delete-route__icon"
                    icon={XGSIcons.faTrash} />
                Delete Route

            </div>
            {(!dispatchEditRouteDetailsState.loaded && !dispatchEditRouteDetailsState.loadingFailed) &&
                <Formik
                    validationSchema={ProbillsRouteSchema}
                    onSubmit={onSubmit}
                    initialValues={initialFormValues}
                    enableReinitialize
                    innerRef={formRef}
                >
                    {(props) => (
                        <Form>
                            <XGSFormInput
                                required={false}
                                name={"routeName"}
                                label="Route Name"
                                placeholder="ex: My Route"
                                labelMode={LabelModes.column}
                            />
                            <div className="create-route-input-container">
                                <LabeledDateInput
                                    className="create-route-input"
                                    required={true}
                                    placeholderText="Select Date"
                                    name={"startDate"}
                                    disabled={false}
                                    labelMode={LabelModes.column}
                                    label="Start Date"
                                    onDateChange={(value) => {
                                        props.setFieldValue("startDate", getFormattedDate(value));
                                    }}
                                    onChange={() => null}
                                />
                            </div>
                            <XGSFormSelect
                                required={true}
                                name={"startTime"}
                                options={timeOptions}
                                formik
                                disabled={false}
                                placeholder="Start Time"
                                labelMode={LabelModes.column}
                                label="Start Time"
                                value={startTime}
                                onValueChange={(value) => {
                                    props.setFieldValue("startTime", value?.value);
                                    setStartTime(value!)
                                }}
                            />
                            <XGSFormSelect
                                required={true}
                                name={"routeDuration"}
                                options={routeDurationOptions}
                                formik
                                disabled={false}
                                placeholder="Route Duration"
                                labelMode={LabelModes.column}
                                label="Route Duration"
                                value={routeDuration}
                                onValueChange={(value) => {
                                    props.setFieldValue("routeDuration", value?.value);
                                    setRouteDuration(value!);
                                }}
                            />
                            <XGSFormInput
                                required={true}
                                name={"trailerNumber"}
                                label="Trailer Number"
                                labelMode={LabelModes.column}
                            />
                            <XGSFormSelect
                                required={true}
                                name={"trailerCapacity"}
                                options={trailerCapacityOptions}
                                formik
                                disabled={false}
                                placeholder="Trailer Capacity"
                                labelMode={LabelModes.column}
                                label="Trailer Capacity"
                                value={trailerCapacity}
                                onValueChange={(value) => {
                                    props.setFieldValue("trailerCapacity", value?.value);
                                    setTrailerCapacity(value!);
                                }}
                            />
                            <XGSFormSelect
                                required={true}
                                name={"defaultStopTime"}
                                options={defaultStopTimeOptions}
                                formik
                                disabled={true}
                                placeholder="Default Stop Time"
                                labelMode={LabelModes.column}
                                label="Default Stop Time(Can not be changed for a route)"
                                value={defaultStopTime}
                                onValueChange={(value) => {
                                    props.setFieldValue("defaultStopTime", value?.value);
                                    setDefaultStopTime(value!);
                                }}
                            />
                            <Button
                                className="xgs-edit-route-details__form__save"
                                spinner={dispatchEditRouteDetailsState.loading}
                                disabled={!props.isValid || dispatchEditRouteDetailsState.loading}
                                type="submit"
                                theme={ButtonThemes.blue}
                            >
                                Save
                            </Button>
                        </Form>
                    )}
                </Formik>
            }
            {
                <ConfirmationModal
                    opened={isShowDeleteRouteModal}
                    header="Delete Route"
                    confirmButtonText="Delete"
                    spinner={deleteRouteState.loading}
                    onCancel={() => { setIsShowDeleteRouteModal(false) }}
                    onConfirm={() => { deleteSelectedRoute(); }}
                >
                    Are you sure, to delete the Route {`${props.routeDetails.routeId}`}
                </ConfirmationModal>
            }
        </SlideOutSidebar>
    );
}
);

export default EditRouteDetails;
