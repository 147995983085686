import React from "react";
import { Card, CardProps } from "../card/card";
import buildingIcon from "../../images/building.svg";
import XGSIcon from "../icon/xgsIcon";
import XGSIcons from "../icon/xgsIcons";

import "./shipperCard.scss";

export interface ShipperCardProps extends CardProps {  
  shipper: any;
}

export const ShipperCard: React.FC<ShipperCardProps> = ({ shipper, ...props }) => {
  const address = shipper.address;

  return (
    <Card
      {...props}
      icon={(
        <div className="shipper-card__icon">
          <img src={buildingIcon} alt="building" />
        </div>
      )}
      title={shipper.name}
    >
      <div className="card__info__item">
        <XGSIcon className="card__info__item" icon={XGSIcons.faMapPin} />
        <div>{`${address.address1}, ${address.city}, ${address.state} ${address.postalCode}`}</div>
      </div>
      <div className="card__info__item card__info__item--secondary">
        {address.additionalAddressLine}
      </div>
    </Card>
  );
};
