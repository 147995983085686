import { ExceptionModel } from "../../app/data/exceptions/models";
import { InvoiceDetailsItem } from "../../app/data/invoice/models";


export type ExceptionState = {
  showModal: boolean;
  exceptions: ExceptionModel[] | null;
  rollPalletDetails: InvoiceDetailsItem[] | null;
  requestCreator: string;
  requestStarted: boolean;
  requestFailed: boolean;
  requestSucceed: boolean;
  requestFailedReason?: string;
  loadingExceptions: boolean;
  loadingExceptionsFailed?: boolean;
  loadedExceptions: boolean;
  loadingExceptionsFailReason?: string;
  loadingRollPalletDetails: boolean;
  loadingRollPalletDetailsFailed?: boolean;
  loadedRollPalletDetails: boolean;
  loadingRollPalletDetailsFailReason?: string;
};

export const initialExceptionState: ExceptionState = {
  showModal: false,
  exceptions: null,
  rollPalletDetails: null,
  requestCreator: "",
  requestStarted: false,
  requestFailed: false,
  requestSucceed: false,
  loadingExceptions: false,
  loadedExceptions: false,
  loadingExceptionsFailed: false,
  loadingRollPalletDetails: false,
  loadedRollPalletDetails: false,
  loadingRollPalletDetailsFailed: false,
};