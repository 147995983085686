import { IState, AgingInvoicesPaymentState } from "..";
import { createSlice } from "@reduxjs/toolkit";

const initialState: AgingInvoicesPaymentState = {
  selectedInvoiceNumbers: [],
};

export const agingInvoicesPaymentSlice = createSlice({
  name: "agingInvoicesPayment",
  initialState,
  reducers: {
    setSelectedInvoiceNumbers: (state, { payload }) => {
      state.selectedInvoiceNumbers = payload;
    },
  },
});

export const { setSelectedInvoiceNumbers } = agingInvoicesPaymentSlice.actions;

export const agingInvoicesPaymentSelector = (state: IState) =>
  state.agingInvoicesPayment;

const agingInvoicesPaymentReducer = agingInvoicesPaymentSlice.reducer;
export default agingInvoicesPaymentReducer;
