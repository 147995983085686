import React from "react";
import Legend from "../../molecules/legend/legend";
import "./mapHeader.scss";

export interface MapHeaderProps {
  title: string;
  legend: React.ReactElement,
};

const MapHeader: React.FC<MapHeaderProps> = ({ title, legend }) => {
  return (
    <div className="xgs-map-header">
      <div>{title}</div>

      <Legend mix="xgs-map-header__legend-trigger">
        {legend}
      </Legend>
    </div>
  )
};

export default MapHeader;
