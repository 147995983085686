import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import RecordsService from "../../app/data/records/recordsService";
import { initialRecordsState } from "./RecordsState";

const recordsService = RecordsService.getInstance();

export const recordsSlice = createSlice({
  name: "records",
  initialState: initialRecordsState,
  reducers: {
    resetRecordsState: (state) => initialRecordsState,
    requestStarted: (state, { payload }) => {
      if (payload === "ADD_RECORDS") {
        state.fetchPortionStarted = true;
        state.recordsFetchedAll = false;
      } else {
        state.requestStarted = true;
      }
      state.requestCreator = payload;
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestError = null;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.fetchPortionStarted = false;
      state.requestFailed = true;
      state.requestSucceed = false;
      state.requestError = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.fetchPortionStarted = false;
      state.requestFailed = false;
      state.requestSucceed = true;
      state.requestError = null;
    },
    setRecords: (state, { payload }) => {
      state.records = payload.content;
      state.recordsRequest = payload.scroll;
      state.recordsFetchedAll = !!(payload.content.length < 50);
    },
    addRecords: (state, { payload }) => {
      state.records = [...state.records, ...payload.content];
      state.recordsRequest = payload.scroll;
      state.recordsFetchedAll = !!(payload.content.length < 50);
    },
    storeProbills: (state, { payload }) => {
      state.probills = payload.map((obj: any) => {
        return {
          value: obj.probill,
          label: obj.probill,
          subtitle: obj.manifest || "",
          valueForSearch: obj.probill
        };
      });
      state.probillsRaw = payload;
    },
    updateRecordLocally: (state, { payload }) => {
      const i = state.records.findIndex((record: any) => record.id === payload.recordId);
      if (i === -1) return;
      state.records[i] = payload.recordData;
    },
    removeRecordLocally: (state, { payload }) => {
      const i = state.records.findIndex((record: any) => record.id === payload);
      if (i === -1) return;
      state.records.splice(i, 1);
    },
    removePhotoLocally: (state, { payload }) => {
      const i = state.records.findIndex((record: any) => record.id === payload.recordId);
      if (i === -1) return;
      const j = state.records[i].items.findIndex((item: any) => item.id === payload.photoId);
      state.records[i].items.splice(j, 1);
    },
    updatePhotoLocally: (state, { payload }) => {
      const i = state.records.findIndex((record: any) => record.id === payload.recordId);
      if (i === -1) return;
      const j = state.records[i].items.findIndex((item: any) => item.id === payload.photoId);
      if (payload.requestObj.comment !== undefined) state.records[i].items[j].comment = payload.requestObj.comment;
      if (payload.requestObj.internalOnly !== undefined) state.records[i].items[j].internalOnly = payload.requestObj.internalOnly;
    },
    storeSearch: (state, { payload }) => {
      state.search = payload;
    }
  }
});

export const {
  resetRecordsState,
  requestStarted,
  requestFailed,
  requestSucceed,
  setRecords,
  addRecords,
  storeProbills,
  updateRecordLocally,
  removeRecordLocally,
  removePhotoLocally,
  updatePhotoLocally,
  storeSearch
} = recordsSlice.actions;

export const recordsSelector = (state: IState) => {
  return state.records;
};

export const getRecords = (
  payload: any,
  lastIds?: string
): AppThunk => async (dispatch) => {
  dispatch(requestStarted(lastIds ? "ADD_RECORDS" : "SET_RECORDS"));
  dispatch(storeSearch(payload));
  const response = await recordsService.getRecords(payload, lastIds && lastIds);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(lastIds ? addRecords(response.data) : setRecords(response.data));
  } else {
    response.getError && dispatch(requestFailed(response.getError()));
  }
};

export const submitRecord = (
  data: any,
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("SUBMIT_RECORD"));
  const response = await recordsService.submitRecord(data);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const searchProbills = (
  query: string
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("SEARCH_PROBILL"));
  const response = await recordsService.searchProbills(query);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(storeProbills(response.data));
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};    

export const removeRecord = (
  recordId: string,
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("REMOVE_RECORD"));
  const response = await recordsService.removeRecord(recordId);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(removeRecordLocally(recordId));
    onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const updateRecord = (
  recordId: string,
  requestPayload: any,
  onSuccess: () => void,
  onFail: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("UPDATE_RECORD"));
  const response = await recordsService.updateRecord(recordId, requestPayload);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(updateRecordLocally({recordId, recordData: response.data.recordInfo}));
    onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
    onFail();
  }
};

export const removePhoto = (
  recordId: string,
  photoId: string,
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("REMOVE_PHOTO"));
  const response = await recordsService.removePhoto(photoId);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(removePhotoLocally({recordId, photoId}));
    onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const updatePhoto = (
  recordId: string,
  photoId: string,
  requestObj: any,
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("UPDATE_PHOTO"));
  const response = await recordsService.updatePhoto(photoId, requestObj);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(updatePhotoLocally({recordId, photoId, requestObj}));
    onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

const recordsReducer = recordsSlice.reducer;
export default recordsReducer;
