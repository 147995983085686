import React from "react";
import { ReactComponent as TruckIcon } from "./../../../images/truck.svg";
import { MARKER_STROKE_COLOR, TRUCK_MARKER } from "../../../services/map";

interface MarkerProps {
  lat: number | undefined;
  lng: number | undefined;
}

const Marker: React.FC<MarkerProps> = () => <div className="xgs-map__marker"><TruckIcon style={{ color: TRUCK_MARKER.COLOR, stroke: MARKER_STROKE_COLOR, width: "42px" }} /></div>;

export default Marker;
