import React from "react";
import { StopLocationInfo } from "../../../app/data/map/models";

import "./stopMarkerInfo.scss";

interface StopMarkerInfoProps {
  info: StopLocationInfo, 
}

const StopMarkerInfo: React.FC<StopMarkerInfoProps> = ({info}) => (
  <div className="stop-marker-info">
    <div className="stop-marker-info__item">
      <span className="stop-marker-info__label">Address:</span>
      {info.address}
    </div>

    {info.consignees && <div className="stop-marker-info__item">
      <span className="stop-marker-info__label">Consignees:</span>
      {info.consignees}
    </div>}

    {info.driver && <div className="stop-marker-info__item">
      <span className="stop-marker-info__label">Driver:</span>
      {info.driver}
    </div>}
  </div>
);

export default StopMarkerInfo;