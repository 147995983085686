import React, { DetailedHTMLProps, ButtonHTMLAttributes } from "react";

import "./labeledValue.scss";

export enum LabeledValueDirection {
  vertical = "vertical",
  horizontal = "horizontal",
}

export enum LabeledValueValueSize {
  normal = 14,
  medium = 18,
  large = 36,
}

export interface LabeledValueProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  label: string;
  value: any;
  direction?: LabeledValueDirection;
  centered?: boolean;
  valueSize?: LabeledValueValueSize;
  labelFixWidth?: boolean;
  optional?: boolean;
  extraClassName?: string;
}

const LabeledValue: React.FC<LabeledValueProps> = ({
  label,
  value,direction,centered,valueSize,labelFixWidth,optional,
  ...props
}) => {
  const defaultDirection = LabeledValueDirection.horizontal;
  const getDirection = () => {
    return direction ? direction : defaultDirection;
  };

  if (optional && (value === undefined || value === null)) {
    return null;
  }

  return (
    <div
      className={`xgs-labeled-value ${getDirection()} ${props.extraClassName && props.extraClassName}`}
      style={{ alignItems: `${centered ? "center" : "flex-start"}` }}
      {...props}
    >
      <label
        style={{
          width: labelFixWidth ? 136 : "auto",
        }}
      >
        {label}
      </label>
      <span
        style={{
          fontSize: valueSize ? valueSize : LabeledValueValueSize.normal,
          width: labelFixWidth ? "122px" : "auto",
          wordWrap: "break-word"
        }}
      >
        {value}
      </span>
    </div>
  );
};

export default LabeledValue;
