import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export interface InviteSendModel {
  email: string,
  firstName: string,
  lastName: string,
  administrator: boolean | undefined,
  teamId?: string
};

export const InviteSendSchema: Yup.ObjectSchema<InviteSendModel> = Yup.object({
  email: Yup.string().max(60, "The value must be no more than 60 characters").email(validationMessages.emailNotValid).required(validationMessages.required),
  firstName: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(30, "The value must be no more than 30 characters").required(validationMessages.required),
  lastName: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(30, "The value must be no more than 30 characters").required(validationMessages.required),
  administrator: Yup.boolean()
}).defined();
