import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import SlideOutSidebar from "../../../ui-components/slide-out-sidebar/slideOutSidebar";
import { CustomerPickup } from "../../../app/data/customer-pickup/models";
import { customerPickupSelector, updateCustomerPickup, updateCustomerPickupStatus } from "../../../slices/customer-pickup/customerPickupSlice";
import PickupDetails from "../common/pickupDetails";

import "../bol.scss";
import { PickupStatus } from "../../../app/data/common/route";

export interface CustomerPickupDetailsProps {
  pickup: CustomerPickup;
  show: boolean;
  onClose: () => void;
};

const CustomerPickupDetails: React.FC<CustomerPickupDetailsProps> = (props) => {  
  const customerPickupState = useSelector(customerPickupSelector); 
  const dispatch = useDispatch(); 

  useEffect(() => {
    if (!customerPickupState.requestFailed) return;
    toast.error(customerPickupState.requestError || "Error", { autoClose: 4000 });
  }, [customerPickupState.requestFailed, customerPickupState.requestError]);

  return (
    <SlideOutSidebar
      header={"Pickup Details"}
      onClose={props.onClose}
      show={props.show}
      size="small-605"
    >
      <div className="xgs-bol__details">
        {props.pickup && <PickupDetails
          pickup={{
            ...props.pickup,
            shipperZip: props.pickup.shipper.address.postalCode,
          }}
          onCancelPickup={() => {
            dispatch(updateCustomerPickupStatus({
              id: props.pickup.id,
              status: PickupStatus.CANCELED,
            }));
          }}
          onReschedulePickup={(
              newPickupId: string,
              newPickupNumber: string,
              pickupDate: string,
              readyTime: string,
              closeTime: string) => {
              dispatch(updateCustomerPickup({
                id: props.pickup.id,              
                newPickupId,
                pickupNumber: newPickupNumber,
                pickupDate,
                readyTime,
                closeTime
              }));
            }}
        />}
      </div>
    </SlideOutSidebar>
  );
};

export default CustomerPickupDetails;
