import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import { PicklistsLoadingStatusState } from "./picklistsState";
import PicklistsService from "../../../app/data/picklists/PicklistsService";
import { resetPicklistDetailsState } from "./picklistDetailsSlice";

const initialState: PicklistsLoadingStatusState = {
  loading: false,
  loadingFailed: false,
  loaded: false,
  summary: {
    totalItemsCount: 0,
    loadedItemsCount: 0,
    totalItemsWeight: 0,
    loadedItemsWeight: 0,
    missingItemsCount: 0,
    lateLoadingCount: 0
  },
  picklists: [],
  selectedPicklistNumber: 0
};

const picklistService = PicklistsService.getInstance();

export const picklistsLoadingStatusSlice = createSlice({
  name: "picklistsLoadingStatus",
  initialState,
  reducers: {
    resetState: (state) => initialState,
    loadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
      state.summary = initialState.summary;
      state.picklists = [];
      state.selectedPicklistNumber = 0;
    },
    loadingSucceed: (state, { payload }) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
      state.summary = payload.summary;
      state.picklists = payload.picklists;
    },
    loadingFailed: (state) => {
      state.loading = false;
      state.loadingFailed = true;
      state.summary = initialState.summary;
      state.picklists = [];
    },
    setSelectedPicklistNumber: (state, { payload }) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
      state.selectedPicklistNumber = payload;
    },
  },
});

export const {
  resetState,
  loadingStarted,
  loadingSucceed,
  loadingFailed,
  setSelectedPicklistNumber
} = picklistsLoadingStatusSlice.actions;

export const picklistsLoadingStatusSelector = (state: IState) => state.picklistsLoadingStatus;

export const getPicklistsLoadingStatus = (
  terminalNumber: number,
  onError?: (error: string) => void
): AppThunk => async (dispatch) => {
  dispatch(loadingStarted());
  const response = await picklistService.getPicklistsLoadingStatus(terminalNumber);
  dispatch(resetPicklistDetailsState());
  if (response.ok() && response.data && response.data.result) {
    dispatch(loadingSucceed(response.data));
  } else {
    dispatch(loadingFailed());
    let error = "Internal Server Error, re-try the action or contact support";
    if (response && response.data && response.data.error) error = response.data.error;
    dispatch(loadingFailed());
    onError && onError(error)
  }
};

const picklistsLoadingStatusReducer = picklistsLoadingStatusSlice.reducer;
export default picklistsLoadingStatusReducer;