import {
  ReceiptsItemModel,
  ReceiptsListRequestModel
} from "../../../app/data/warehouse/models";

export default interface ReceiptsState {
  request: ReceiptsListRequestModel;
  initialRequestStarted: boolean;
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError?: string | null;
  requestedAll: boolean;  
  list: ReceiptsItemModel[];
}

export const initialReceiptsState: ReceiptsState = {
  request: {
    from: "",
    to: ""
  },
  initialRequestStarted: false,
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestError: null,
  requestedAll: false,  
  list: []
};
