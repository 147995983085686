import api, { ApiResponse } from "../api";
import { InvoiceDetailsItem } from "../invoice/models";
import { ExceptionModel } from "./models";

class ExceptionService {
  private static instance: ExceptionService;
  private constructor() {}
  static getInstance(): ExceptionService {
    if (!ExceptionService.instance) {
      ExceptionService.instance = new ExceptionService();
    }
    return ExceptionService.instance;
  }

  public getExceptions = async (
    probillNumber: number
  ): Promise<ApiResponse<ExceptionModel[]>> => {
    return await api.get(`/exceptions/${probillNumber}`);
  };

  public getRollPalletDetails = async (
    probillNumber: number
  ): Promise<ApiResponse<InvoiceDetailsItem[]>> => {
    return await api.get(`/exceptions/items/${probillNumber}`);
  };

  public createException = async (fd: FormData) : Promise<ApiResponse<any>> => {
    return await api.post(`/exceptions`, fd, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  }
}

export default ExceptionService;
