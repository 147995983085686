import * as styles from "../../sass/variables.module.scss";

export const CLAIM_REASON_TYPES = [
  {
    label: "Concealed Damage",
    value: "CONCEALED"
  },
  {
    label: "Delay",
    value: "DELAY"
  },
  {
    label: "Lost",
    value: "LOST"
  },
  {
    label: "Miscellaneous",
    value: "MISCELLANEOUS"
  },
  {
    label: "Theft",
    value: "THEFT"
  },
  {
    label: "Visible Damage",
    value: "VISIBLE"
  },
  {
    label: "Water Damage",
    value: "WATER"
  },
  {
    label: "Wreck",
    value: "WRECK"
  }
];

export const CLAIM_STATUS = {
  NEW: "NEW",
  APPROVED: "CLMAPP",
  DECLINED: "APPRVL",
};


export const CLAIM_STATUSES = [
  {
    label: "New",
    value: CLAIM_STATUS.NEW,
    color: styles.blue2,
    test: "test"
  },
  {
    label: "Approved",
    value: CLAIM_STATUS.APPROVED,
    color: styles.green1
  },
  {
    label: "Declined",
    value: CLAIM_STATUS.DECLINED,
    color: styles.red
  }
];

export const UPLOAD_MAX_SIZE = 4; // MB

export const UPLOAD_FILE_TYPES = {
  "application/pdf": [],
  "image/jpeg": [],
  "image/png": []
};

export const DOCUMENTS_TYPES = [
  {
    label: "Invoice",
    value: "INVOICE"
  },
  {
    label: "Damage",
    value: "DAMAGE"
  },
  {
    label: "Pickup",
    value: "PICKUP_CONFIRMATION"
  },
  {
    label: "Other",
    value: "OTHER"
  }
];
