import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { SubscriptionModel } from "../../app/data/subscriptions/models"
import SubscriptionsService from "../../app/data/subscriptions/subscriptionsService";
import { initialSubscriptionsState } from "./SubscriptionsState";
import { setShipmentSubscribeStatus } from "../shipment-details/shipmentDetailsSlice"

const subscriptionsService = SubscriptionsService.getInstance();

export const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState: initialSubscriptionsState,
  reducers: {
    resetSubscriptionsState: (state) => initialSubscriptionsState,
    startLoading: (state) => {
      state.loading = true;
      state.loaded = false;
      state.loadingFailed = false;
    },
    loadingSubscriptions_succeed: (state, { payload }) => {
      state.subscriptions = payload;
      state.loading = false;
      state.loaded = true;
      state.loadingFailed = false;
    },
    loadingSubscriptions_has_failed: (state, { payload }) => {
      state.loading = false;
      state.loaded = false;
      state.loadingFailed = true;
      state.loadingError = payload;
    },
    subscribeRequestStarted: (state) => {
      state.subscribeStarted = true;
      state.subscribeSuccess = false;
      state.subscribeFailed = false;
    },
    subscribeRequestSuccessed: (state) => {
      state.subscribeStarted = false;
      state.subscribeSuccess = true;
      state.subscribeFailed = false;
    },
    subscribeRequestFailed: (state, { payload }) => {
      state.subscribeStarted = false;
      state.subscribeSuccess = false;
      state.subscribeFailed = true;
      state.subscribeError = payload;
    },
    removeProbillStatusSubscriptionLocally: (state, { payload }) => {
      const i = state.subscriptions.findIndex((subscription: SubscriptionModel) => subscription.probill === payload);
      if (i === -1) return;
      state.subscriptions.splice(i, 1);
    }
  }
});

export const {
  resetSubscriptionsState,
  startLoading,
  loadingSubscriptions_succeed,
  loadingSubscriptions_has_failed,
  subscribeRequestStarted,
  subscribeRequestSuccessed,
  subscribeRequestFailed,
  removeProbillStatusSubscriptionLocally
} = subscriptionsSlice.actions;

export const subscriptionsSelector = (state: IState) => state.subscriptions;

export const subscribeProbillStatus = (
  invoiceNumber: number,
  timezone: string,
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(subscribeRequestStarted());
  const response = await subscriptionsService.subscribeProbill(invoiceNumber, timezone);
  if (response.ok()) {
    dispatch(subscribeRequestSuccessed());
    dispatch(setShipmentSubscribeStatus(true));
    onSuccess();
  } else {
    dispatch(subscribeRequestFailed(response.getError()));
  }
};

export const unsubscribeProbillStatus = (
  invoiceNumber: number,
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(subscribeRequestStarted());
  const response = await subscriptionsService.unsubscribeProbill(invoiceNumber);
  if (response.ok()) {
    dispatch(subscribeRequestSuccessed());
    dispatch(setShipmentSubscribeStatus(false));
    onSuccess();
  } else {
    dispatch(subscribeRequestFailed(response.getError()));
  }
};

export const getSubscriptions = (): AppThunk => async (dispatch) => {
  dispatch(startLoading());
  const response = await subscriptionsService.getSubscriptions();
  if (response.ok()) {
    dispatch(loadingSubscriptions_succeed(response.data));
  } else {
    dispatch(loadingSubscriptions_has_failed(response.getError()));
  }  
};

const subscriptionsReducer = subscriptionsSlice.reducer;
export default subscriptionsReducer;
