import React from "react";
import { useSelector } from "react-redux";

import { ReactComponent as CheckIcon } from "../../../images/check.svg";
import Summary from "../../../ui-components/summary/summary";

import { invoicesSelector } from "../../../slices/documents/invoicesSlice";
import { SummaryItemColor } from "../../../ui-components/summary/summary-tile/summaryTile";

const InvoicesSummary: React.FC<{}> = (props) => {  
  const invoicesState = useSelector(invoicesSelector);

  const getSummary = () => (
    [{
      value: invoicesState.summary?.total,
      description: "Invoices Available",
      icon: <CheckIcon />,
      color: SummaryItemColor.blue,
    },
    {
      value: invoicesState.summary?.undelivered,
      description: "Undelivered Invoices",
      icon: <CheckIcon />,
      color: SummaryItemColor.red,
    },
    {
      value: invoicesState.summary?.paidAmount,
      description: "Paid amount",
      icon: "$",
      color: SummaryItemColor.green,
    },
    {
      value: invoicesState.summary?.partiallyPaidAmount,
      description: "Partially paid amount",
      icon: "$",
      color: SummaryItemColor.orange,
    },
    {
      value: invoicesState.summary?.missedAmount,
      description: "Missed amount",
      icon: <CheckIcon />,
      color: SummaryItemColor.red,
    },
    {
      value: invoicesState.summary?.missedPayments,
      description: "Missed payments",
      icon: <CheckIcon />,
      color: SummaryItemColor.red,
    }
  ]); 

  return (
    <Summary data={getSummary()} isLoading={invoicesState.request["GET_INVOICES_SUMMARY"]?.requestStarted}/>
  );
};

export default InvoicesSummary;