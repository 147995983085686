import { BaseState } from "..";
import { SubscriptionModel } from "../../app/data/subscriptions/models";

export default interface SubscriptionsState extends BaseState {
  subscriptions: SubscriptionModel[];
  subscribeStarted: boolean;
  subscribeSuccess: boolean;
  subscribeFailed: boolean;
  subscribeError?: string;
}

export const initialSubscriptionsState: SubscriptionsState = {
  subscriptions: [],
  subscribeStarted: false,
  subscribeSuccess: false,
  subscribeFailed: false,
  subscribeError: undefined,
  loading: false,
  loadingFailed: false,
  loadingError: null,
  loaded: false
};
