
export const ACCEPTED_FORMATS = {
  // "image/*": [],
  "image/bmp": [],
  "image/gif": [],
  "image/jpeg": [],
  "image/png": [],
  "image/webp": []
};

export const MAX_SIZE = 12; // MB
export const MAX_PHOTOS = 20;
