import React from "react";

import { ReactComponent as ZoomIn } from "./../../images/zoom_in.svg";

import "./imageThumbnail.scss";

export interface ImageThumbnailProps {
  src: string;
  alt?: string;
  onClick: (src: string) => void;
}

const ImageThumbnail: React.FC<ImageThumbnailProps> = (props) => {
  const onClick = () => props.onClick(`data:image/jpeg;base64,${props.src}`);

  return (
    <div className="image-thumbnail" onClick={onClick}>
      <div className="image-thumbnail__overlay">
        <ZoomIn/>
      </div>

      <img src={`data:image/jpeg;base64,${props.src}`} alt={props.alt || "img"} />
    </div>
  );
}

export default ImageThumbnail;