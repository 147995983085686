import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import CompanySettingsService from "../../app/data/company-settings/companySettingsService";
import { SubAccount } from "../../app/data/company-settings/models";
import { initialCompanyAccountDetailsState } from "./companyAccountDetailsState";
const companySettingsService = CompanySettingsService.getInstance();

export const companyAccountDetailsSlice = createSlice({
  name: "companyAccountDetails",
  initialState: initialCompanyAccountDetailsState,
  reducers: {
    resetCompanyAccountDetails: () => initialCompanyAccountDetailsState,
    fetch_was_started: (state) => {
      state.fetch_was_started = true;
      state.fetch_was_succeed = false;
      state.fetch_was_failed = false;
      state.fetch_fail_reason = null;
    },
    fetch_was_succeed: (state, { payload }) => {
      state.fetch_was_succeed = true;
      state.fetch_was_started = false;
      state.fetch_was_failed = false;
      state.accountDetails = payload;
    },
    fetch_was_failed: (state, { payload }) => {
      state.fetch_was_failed = true;
      state.fetch_was_succeed = false;
      state.fetch_was_started = false;
      state.fetch_fail_reason = payload;
    },
    fetch_logs_started: (state) => {
      state.fetch_logs_started = true;
      state.fetch_logs_succeed = false;
      state.fetch_logs_failed = false;
    },
    fetch_logs_succeed: (state, {payload}) => {
      state.fetch_logs_started = false;
      state.fetch_logs_succeed = true;
      state.changelogs = payload;
      state.fetch_logs_failed = false;
    },
    fetch_logs_failed: (state, {payload}) => {
      state.fetch_logs_started = false;
      state.fetch_logs_failed = true;
      state.fetch_logs_succeed = false;
      state.fetch_fail_reason = payload;
    },
    update_was_started: (state) => {
      state.update_was_started = true;
      state.update_was_succeed = false;
      state.update_was_failed = false;
      state.update_fail_reason = null;
    },
    update_was_succeed: (state) => {
      state.update_was_succeed = true;
      state.update_was_started = false;
      state.update_was_failed = false;
    },
    update_was_failed: (state, { payload }) => {
      state.update_was_failed = true;
      state.update_was_succeed = false;
      state.update_was_started = false;
      state.update_fail_reason = payload;
    },
    resetErrors: (state) => {
      state.fetch_was_failed = false;
      state.fetch_fail_reason = null;
      state.update_was_failed = false;
      state.update_fail_reason = null;
    },
    updateAccountDetailsLocally: (state, {payload}) => {
      state.accountDetails = payload;
    }
  }
});

export const {
  fetch_was_started,
  fetch_was_succeed,
  fetch_was_failed,
  fetch_logs_failed,
  fetch_logs_started,
  fetch_logs_succeed,
  update_was_started,
  update_was_succeed,
  update_was_failed,
  resetErrors,
  resetCompanyAccountDetails,
  updateAccountDetailsLocally
} = companyAccountDetailsSlice.actions;

export const companyAccountDetailsSelector = (state: IState) => state.companyAccountDetails;

export const getAccountDetails = (accountId: string): AppThunk => async (dispatch) => {
  dispatch(fetch_was_started())
  const response = await companySettingsService.getSubAccount(accountId)
  if (response.ok()) {
    dispatch(fetch_was_succeed(response.data))
  } else {
    response.getError && dispatch(fetch_was_failed(response.getError()))
  }
}

export const updateAccountDetails = (
  accountId: string,
  model: SubAccount,
  onSuccess: () => void,
  onError: () => void
): AppThunk => async (dispatch) => {
  dispatch(update_was_started());
  const response = await companySettingsService.updateSubAccount(accountId ,model)
  if (response.ok()) {
    dispatch(update_was_succeed())
    onSuccess();
  } else {
    response.getError && dispatch(update_was_failed(response.getError()))
    onError();
  }
}

export const getAccountDetailsChangeLog = (
  accountId: string,
): AppThunk => async (dispatch) => {
  dispatch(fetch_logs_started());
  const response = await companySettingsService.getAccountDetailsChangeLog(accountId);
  if (response.ok()) {
    dispatch(fetch_logs_succeed(response.data));
  } else {
    response.getError && dispatch(update_was_failed(response.getError()));
  }
}

const companyAccountDetailsReducer = companyAccountDetailsSlice.reducer;
export default companyAccountDetailsReducer;
