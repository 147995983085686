import { BaseState } from "..";
import { NameIdModel } from "../../app/data/common/models";

export default interface ServiceCentersState extends BaseState {
  origins?: NameIdModel[] | null;
  destinations?: NameIdModel[] | null;
}

export const initialServiceCentersState: ServiceCentersState = {
  origins: [],
  destinations: [],
  loading: false,
  loadingFailed: false,
  loaded: false,
};
