import api, { ApiResponse } from "./../api";
import axios, { CancelTokenSource } from "axios";
import { PodRecognitionResponseModel } from "./models";
import { urlParams } from "../../../services/common/urlParams";

export const REQUEST_ID: {
  [key: string]: string;
} = {
  GET_RECORDS: "get_pod_recognition_records"
};

class PodRecognitionService {
  private source: {
    [key: string]: CancelTokenSource;
  };

  private static instance: PodRecognitionService;

  private constructor() {
    this.source = {};
  };

  static getInstance(): PodRecognitionService {
    if (!PodRecognitionService.instance) {
      PodRecognitionService.instance = new PodRecognitionService();
    }
    return PodRecognitionService.instance;
  };

  public getPodRecognitionRecords = async (
    filter: PodRecognitionFilterModel,
    page: number
  ): Promise<ApiResponse<PodRecognitionResponseModel>> => {
    this.source[REQUEST_ID.GET_RECORDS] && this.source[REQUEST_ID.GET_RECORDS].cancel();
    this.source[REQUEST_ID.GET_RECORDS] = axios.CancelToken.source();
    return await api.get("/ocr-audit", { params: urlParams(page ? {...filter, page} : filter), cancelToken: this.source[REQUEST_ID.GET_RECORDS].token });
  };
};

export default PodRecognitionService;
