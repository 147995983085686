import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import { PicklistDetailsState } from "./picklistsState";
import PicklistsService from "../../../app/data/picklists/PicklistsService";

const initialState: PicklistDetailsState = {
  loading: false,
  loadingFailed: false,
  loaded: false,
  picklistDetails: []
};

const picklistsService = PicklistsService.getInstance();

export const picklistDetailsSlice = createSlice({
  name: "picklistDetails",
  initialState,
  reducers: {
    resetPicklistDetailsState: (state) => initialState,
    picklistDetailsLoadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
      state.picklistDetails = [];
    },
    picklistDetailsLoadingSucceed: (state, { payload }) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
      state.picklistDetails = payload;
    },
    picklistDetailsLoadingFailed: (state) => {
      state.loading = false;
      state.loadingFailed = true;
      state.picklistDetails = [];
    }
  },
});

export const {
  resetPicklistDetailsState,
  picklistDetailsLoadingStarted,
  picklistDetailsLoadingSucceed,
  picklistDetailsLoadingFailed
} = picklistDetailsSlice.actions;

export const picklistDetailsSelector = (state: IState) => state.picklistDetails;

export const getPicklistDetails = (
  picklistNumber: number,
  onError?: (error: string) => void
): AppThunk => async (dispatch) => {
  dispatch(picklistDetailsLoadingStarted());
  const response = await picklistsService.getPicklistDetails(picklistNumber);
  if (response.ok() && response.data && response.data.result) {
    dispatch(picklistDetailsLoadingSucceed(response.data.loadDetails));
  } else {
    let error = "Internal Server Error, re-try the action or contact support";
    if (response && response.data && response.data.error) error = response.data.error;
    dispatch(picklistDetailsLoadingFailed());
    onError && onError(error)
  }
};

const picklistDetailsReducer = picklistDetailsSlice.reducer;
export default picklistDetailsReducer;