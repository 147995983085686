import api, { ApiResponse } from "./../api";

class PublicShipmentDetailsService {
  private static instance: PublicShipmentDetailsService;
  private constructor() {}
  static getInstance(): PublicShipmentDetailsService {
    if (!PublicShipmentDetailsService.instance) {
      PublicShipmentDetailsService.instance = new PublicShipmentDetailsService();
    }
    return PublicShipmentDetailsService.instance;
  };

  public getPublicShipmentDetails = async (
    probill: number
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/probills/track/${probill}`);
  };

  public shareShipment = async (
    email: string,
    probill: number
  ): Promise<ApiResponse<any>> => {
    return await api.post("/probills/share", {
      email,
      probillNumber: probill
    });
  };
};

export default PublicShipmentDetailsService;
