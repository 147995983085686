import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export interface ReportModel {
  id?: string;
  dateCreated?: string;
  dateUpdated?: string;
  userCreated?: string;
  userUpdated?: string;
  name: string;
  subject: string;
  message: string;
  frequency: string;
  runDay?: string;
  email1: string;
  email2?: string;
  email3?: string;
  fields?: object;
  subAccountId?: string;
}

export const ReportSchema: Yup.ObjectSchema<ReportModel> = Yup.object({
  id: Yup.string(),
  name: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(100, "The value must be no more than 100 characters").required(validationMessages.required),
  subject: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(100, "The value must be no more than 100 characters").required(validationMessages.required),
  message: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(65535, "The value must be no more than 65535 characters").required(validationMessages.required),
  runDay: Yup.string(),
  subAccountId: Yup.string(),
  frequency: Yup.string().required(validationMessages.required),
  email1: Yup.string().max(60, "The value must be no more than 60 characters").email(validationMessages.emailNotValid).required(validationMessages.required),
  email2: Yup.string().max(60, "The value must be no more than 60 characters").email(validationMessages.emailNotValid),
  email3: Yup.string().max(60, "The value must be no more than 60 characters").email(validationMessages.emailNotValid),
  fields: Yup.object()
}).defined();
