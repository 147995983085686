import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import TrackingService from "../../app/data/tracking/trackingService";
import { initialTrailerLocationState } from "./TrailerLocationState";

const trackingService = TrackingService.getInstance();

export const trailerLocationSlice = createSlice({
  name: "trailerLocation",
  initialState: initialTrailerLocationState,
  reducers: {
    resetTrailerLocation: (state) => initialTrailerLocationState,
    requestStarted: (state) => {
      state.requestStarted = true;
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestError = null;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestFailed = false;
      state.requestSucceed = true;
      state.requestError = null;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = true;
      state.requestSucceed = false;
      state.requestError = payload;
    },
    setTrailerLocation: (state, { payload }) => {
      state.result = payload;
    }
  }
});

export const {
  resetTrailerLocation,
  requestStarted,
  requestSucceed,
  requestFailed,
  setTrailerLocation,
} = trailerLocationSlice.actions;

export const trailerLocationSelector = (state: IState) => state.trailerLocation;

export const getTrailerLocation = (
  invoiceNumber: number,
  silent: boolean
): AppThunk => async (dispatch) => {
  !silent && dispatch(requestStarted());
  const response = await trackingService.getTrailerLocation(invoiceNumber);
  if (response.ok()) {
    !silent && dispatch(requestSucceed());
    dispatch(setTrailerLocation(response.data));
  } else {
    !silent && dispatch(requestFailed(response.getError()));
  }
};

const trailerLocationReducer = trailerLocationSlice.reducer;
export default trailerLocationReducer;
