import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import JWTService from "../../app/data/jwt-generator/jwtService";
import { initialJWTGeneratorState } from "./JWTGeneratorState";

const jwtService = JWTService.getInstance();

export const jwtGeneratorSlice = createSlice({
  name: "jwtGenerator",
  initialState: initialJWTGeneratorState,
  reducers: {
    startLoadingToken: (state) => {
      state.loading = true;
      state.loading_was_succeed = false;
      state.loading_was_failed = false;
      state.loading_error = null;
    },
    setToken: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.token = (payload && payload.length > 0) ? payload[0] : null;
      } else {
        state.token = payload;
      }
      state.loading = false;
      state.loading_was_failed = false;
      state.loading_was_succeed = true;
      state.loading_error = null;      
    },
    loading_was_failed: (state, { payload }) => {
      state.loading = false;
      state.loading_was_failed = true;
      state.loading_was_succeed = false;
      state.loading_error = payload;
    }
  },
});

export const {
  startLoadingToken,
  setToken,
  loading_was_failed
} = jwtGeneratorSlice.actions;

export const jwtGeneratorSelector = (state: IState) => state.jwtGenerator;

export const getToken = (subAccountId?: string): AppThunk => async (dispatch) => {
  if (!subAccountId) return
  dispatch(startLoadingToken());
  const response = await jwtService.getToken(subAccountId);
  if (response.ok()) {
    dispatch(setToken(response.data));
  } else {
    dispatch(loading_was_failed(response.data));
  }
};

export const requestToken = (subAccountId?: string): AppThunk => async (dispatch) => {
  if (!subAccountId) return
  dispatch(startLoadingToken());
  const response = await jwtService.requestToken(subAccountId);
  if (response.ok()) {
    dispatch(setToken(response.data));
  } else {
    dispatch(loading_was_failed(response.data));
  }
};

const jwtGeneratorReducer = jwtGeneratorSlice.reducer;
export default jwtGeneratorReducer;
