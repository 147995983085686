import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import Table from "../../../ui-components/table/table";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import Loading from "../../../ui-components/loading/loading";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import {
  getSubscriptions,
  subscriptionsSelector,
  unsubscribeProbillStatus,
  removeProbillStatusSubscriptionLocally
} from "../../../slices/subscriptions/subscriptionsSlice";
import SubscriptionsState from "../../../slices/subscriptions/SubscriptionsState";
import { userSelector } from "../../../slices/user/userSlice";
import UserState from "../../../slices/user/UserState";
import "./subscriptions.scss";

const Subscriptions: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const subscriptionsState: SubscriptionsState = useSelector(subscriptionsSelector);
  const userState: UserState = useSelector(userSelector);
  const [activeProbill, setActiveProbill] = useState<number | undefined>(undefined);

  const columns = [
    {
      Header: "Probill",
      accessor: "probill",
      width: 95,
      Cell: (cellProps: any) => (
        <Link
          className="xgs-table-link-cell"
          target="_blank"
          to={`${userState.activeSubAccount?.accountNumber ? "/" + userState.activeSubAccount?.accountNumber : ""}/shipments/${cellProps.value}`}
        >
          {cellProps.value}
        </Link>
      )
    },
    {
      Header: "Destination",
      accessor: "destination.name",
      width: 145
    },
    {
      Header: "Consignee",
      accessor: "consignee.info",
      width: 150
    },
    {
      Header: "Status",
      accessor: "status.translate",
      width: 130
    },
    {
      width: 52,
      Header: "",
      id: "actionColumn",
      Cell: ({ row }: any) => (
        <div style={{ textAlign: "center" }}>
          <Button
            className="xgs-subscriptions__remove-button"
            type="button"
            theme={ButtonThemes.gray}
            onClick={(e) => {
              e.stopPropagation();
              removeSubscription(row.original.probill);
              setActiveProbill(row.original.probill);
            }}
            spinner={activeProbill === row.original.probill}
          >
            {activeProbill !== row.original.probill && (
              <XGSIcon
                icon={XGSIcons.faBellSlash}
                size="1x"
              />
            )}
          </Button>
        </div>
      )
    }
  ];

  const removeSubscription = (probill: number) => {
    dispatch(unsubscribeProbillStatus(probill, () => {
      dispatch(removeProbillStatusSubscriptionLocally(probill));
      toast.info(`You unsubscribed from status updates of probill ${probill}`, { autoClose: 3000 });
      mixpanel.track("Unsubscribed from probill status updates", {
        "Probill": probill
      });
    }));
  };

  useEffect(() => {
    dispatch(getSubscriptions());
  }, [dispatch]);

  return (
    <div className="xgs-subscriptions">
      <div className="xgs-subscriptions__header">Delivery statuses</div>
      {subscriptionsState.loading && (
        <div className="xgs-subscriptions__loading">
          <Loading isLoading={true} />
        </div>
      )}
      {subscriptionsState.subscriptions && subscriptionsState.subscriptions.length > 0 && !subscriptionsState.loading && (
        <div className="xgs-subscriptions__table">
          <Table
            columns={columns}
            data={subscriptionsState.subscriptions}
            rowHeight={64}
          />
        </div>
      )}
      {(!subscriptionsState.subscriptions || subscriptionsState.subscriptions.length === 0) && subscriptionsState.loaded && (
        <div className="xgs-subscriptions__no-data">There are no subscriptions.</div>
      )}
      {subscriptionsState.loadingFailed && (
        <XGSErrorMessage>
          {subscriptionsState.loadingError || "Error while loading subscriptions."}
        </XGSErrorMessage>
      )}
    </div>
  );
};

export default Subscriptions;
