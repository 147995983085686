import { createSlice } from '@reduxjs/toolkit';
import AccountInapplicableInformationService from '../../app/data/account-inapplicable-information/accountInapplicableInformationService';
import { initialAccountInapplicableInformationState, AccountInapplicableInformationState } from './accountInapplicableInformationState';
import { AppThunk } from '../../app/store';
import { IState } from '..';
import { AccountInapplicableInformationType } from '../../app/data/account-inapplicable-information/models';

const accountInapplicableInformationService = AccountInapplicableInformationService.getInstance();

export const accountInapplicableInformationSlice = createSlice({
  name: 'accountInapplicableInformation',
  initialState: initialAccountInapplicableInformationState,
  reducers: {
    resetAccountInapplicableInformationState: (state) => initialAccountInapplicableInformationState,
    fetchDisabledInformationTypesStarted: (state: AccountInapplicableInformationState) => {
      state.loading = true;
      state.loadingSucceeded = false;
      state.loadingFailed = false;
    },
    fetchDisabledInformationTypesSucceeded: (state: AccountInapplicableInformationState, { payload }) => {
      state.loading = false;
      state.disabledInformationTypes = payload;
      state.loadingSucceeded = true;
      state.loadingFailed = false;
    },
    fetchDisabledInformationTypesFailed: (state: AccountInapplicableInformationState, { payload }) => {
      state.loading = false;
      state.loadingFailed = true;
      state.loadingError = payload;
    },
    disableInformationTypeLocally: (state, { payload }) => {
      state.disabledInformationTypes.push(payload);
    },
    enableInformationTypeLocally: (state, { payload }) => {
      state.disabledInformationTypes = state.disabledInformationTypes.filter(
        (type) => type !== payload
      );
    },
    requestStarted: (state: AccountInapplicableInformationState) => {
      state.requestStarted = true;
      state.requestSucceeded = false;
      state.requestFailed = false;
      state.requestError = "";
    },
    requestSucceeded: (state: AccountInapplicableInformationState) => {
      state.requestStarted = false;
      state.requestSucceeded = true;
    },
    requestFailed: (state: AccountInapplicableInformationState, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = true;
      state.requestError = payload;
    }
  }
});

export const {
  fetchDisabledInformationTypesStarted,
  fetchDisabledInformationTypesSucceeded,
  fetchDisabledInformationTypesFailed,
  disableInformationTypeLocally,
  enableInformationTypeLocally,
  resetAccountInapplicableInformationState,
  requestFailed,
  requestStarted,
  requestSucceeded
} = accountInapplicableInformationSlice.actions;

export const fetchDisabledInformationTypes = (accountId: string): AppThunk => async (dispatch) => {
  dispatch(fetchDisabledInformationTypesStarted());
  const response = await accountInapplicableInformationService.getDisabledInformationTypes(accountId);
  if (response.ok()) {
    dispatch(fetchDisabledInformationTypesSucceeded(response.data));
  } else {
    response.getError() && dispatch(fetchDisabledInformationTypesFailed(response.getError()));
  }
};

export const disableInformationType = (accountId: string, type: AccountInapplicableInformationType, onSuccess: any, onError: any): AppThunk => async (dispatch) => {
  dispatch(requestStarted());
  const response = await accountInapplicableInformationService.disableInformationType(accountId, type);
  if (response.ok()) {
    dispatch(requestSucceeded());
    dispatch(disableInformationTypeLocally(type));
    onSuccess();
  } else {
    onError(response.getError());
  }
};

export const enableInformationType = (accountId: string, type: AccountInapplicableInformationType, onSuccess: any, onError: any): AppThunk => async (dispatch) => {
  dispatch(requestStarted())
  const response = await accountInapplicableInformationService.enableInformationType(accountId, type);
  if (response.ok()) {
    dispatch(requestSucceeded());
    dispatch(enableInformationTypeLocally(type));
    onSuccess();
  } else {
    onError(response.getError());
  }
};

export const accountInapplicableInformationSelector = (state: IState) => state.accountInapplicableInformation;

const accountInapplicableInformationReducer = accountInapplicableInformationSlice.reducer;
export default accountInapplicableInformationReducer;
