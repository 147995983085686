import LegacyUser from "../../app/data/user/LegacyUser";

export default interface ResetPasswordState {
  sending_request: boolean;
  request_is_Succeed: boolean;
  request_is_Failed: boolean;

  resetedUser: string | null;

  loadingLegacyUser: boolean;
  loadingLegacyUserError?: string | null;
  legacyUser?: LegacyUser | null;

  submitting: boolean;
  submit_is_Succeed: boolean;
  submit_is_Failed: boolean;
  submit_error?: string | null;
}

export const initialResetPasswordState: ResetPasswordState = {
  sending_request: false,
  request_is_Succeed: false,
  request_is_Failed: false,

  resetedUser: null,

  loadingLegacyUser: false,
  loadingLegacyUserError: null,
  legacyUser: null,

  submitting: false,
  submit_is_Succeed: false,
  submit_is_Failed: false,
  submit_error: null
};