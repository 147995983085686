import styled from "styled-components";

const LoginContent = styled.div`
  width: 450px;
  margin-top: 64px;
  padding: 56px 64px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 460px) { 
    width: 100%;
    padding: 56px 32px;
  }
`;

export default LoginContent;
