import {
  InventoryItemModel,
  TransactionsItemModel,
  InventoryListRequestModel
} from "../../../app/data/warehouse/models";

export default interface InventoryState {
  listRequest: InventoryListRequestModel;
  listInitialRequestStarted: boolean;
  listRequestStarted: boolean;
  listRequestSucceed: boolean;
  listRequestFailed: boolean;
  listRequestError?: string | null;
  listRequestedAll: boolean;
  list: InventoryItemModel[];
  transactionsLastIds?: string | null;
  transactionsInitialRequestStarted: boolean;
  transactionsRequestStarted: boolean;
  transactionsRequestSucceed: boolean;
  transactionsRequestFailed: boolean;
  transactionsRequestError?: string | null;
  transactionsRequestedAll: boolean;  
  transactions: TransactionsItemModel[];
}

export const initialInventoryState: InventoryState = {
  listRequest: {
    item: "",
    invoiceNumber: "",
    lastIds: ""
  },
  listInitialRequestStarted: false,
  listRequestStarted: false,
  listRequestSucceed: false,
  listRequestFailed: false,
  listRequestError: null,
  listRequestedAll: false,
  list: [],
  transactionsLastIds: null,
  transactionsInitialRequestStarted: false,
  transactionsRequestStarted: false,
  transactionsRequestSucceed: false,
  transactionsRequestFailed: false,
  transactionsRequestError: null,
  transactionsRequestedAll: false,  
  transactions: []
};
