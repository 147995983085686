import React, { useEffect, useState } from "react";
import SlideOutSidebar from "../../ui-components/slide-out-sidebar/slideOutSidebar";
import Tag from "../../ui-components/molecules/tag/tag";
import { TagColor } from "../../app/data/common/tagColor";
import Table from "../../ui-components/table/table";
import { LinehaulTrip } from "../../app/data/linehaul-mileage/models";
import moment from "moment";
import driverGray from "../../images/driver_gray.svg";
import driverBlue from "../../images/driver_blue.svg";
import editBlue from "../../images/edit_blue.svg";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import { getAccessorialColumns } from "./accessorialColumns";
import { SelectDriver } from "./selectDriver";
import { AddLane } from "./addLane";
import plusBlue from "../../images/plus__blue.svg"
import { useDispatch, useSelector } from "react-redux";
import { assignDriver, deleteTripException, fetchLinehaulLanes, fetchLinehaulTripActivity, linehaulMileageSelector, resetLinehaulLanes, reviewTrip } from "../../slices/linehaul-mileage/linehaulMileageSlice";
import { toast } from "react-toastify";
import { LinehaulTripAcitivity } from "./linehaulTripActivity";
import { AddAccessorial } from "./addAccessorial";
import { getLaneColumns } from "./getLaneColumns";
import { LaneAssignment } from "./laneAssignment";
import dateFormats from "../../app/data/common/dateFormats";
import infoIcon from "../../images/info_blue.svg";

export interface TripDetailsProps {
  trip?: LinehaulTrip,
  onClose: any;
  show: boolean;
}

export interface Driver {
  name: string;
  id: string;
}

const getInfoMessage = (driver: boolean, lane: boolean) => {
  if (!driver && !lane) {
    return "Please assign driver and lane to mark the trip as reviewed."
  } else if (!lane) {
    return "Please assign lane to mark the trip as reviewed."
  } else if (!driver) {
    return "Please assign driver to mark the trip reviewed."
  }
}

export const TripDetails: React.FC<TripDetailsProps> = (props) => {
  const [showDriverModal, setShowDriverModal] = useState(false);
  const [addNewLane, setAddNewLane] = useState(false);
  const [addNewAccessorial, setAddNewAccessorial] = useState(false);
  const [assignLane, setAssignLane] = useState(false);
  const linehaulMileageState = useSelector(linehaulMileageSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!props.show) {
      setShowDriverModal(false);
      setAddNewAccessorial(false);
      setAddNewLane(false);
      setAssignLane(false);
    }
  }, [props.show])

  useEffect(() => {
    setShowDriverModal(false);
    setAddNewAccessorial(false);
    setAddNewLane(false);
    setAssignLane(false);
  }, [props.trip])

  useEffect(() => {
    dispatch(fetchLinehaulLanes(props.trip?.originTerminalNumber, props.trip?.destinationTerminalNumber));
    return () => {
      dispatch(resetLinehaulLanes());
    }
  }, [props.trip?.originTerminalNumber, props.trip?.destinationTerminalNumber, dispatch])

  useEffect(() => {
    if (!props.trip?.tripId) return;
    dispatch(fetchLinehaulTripActivity(props.trip.tripId));
  }, [props.trip?.tripId, props.trip?.lane, props.trip?.exceptions, dispatch])

  const onReviewTrip = () => {
    dispatch(
      reviewTrip(props.trip?.tripId!,
        false,
        () => toast.info("Lane reviewed"),
        (error: string) => toast.error(error || "Something went wrong")
      )
    )
  }

  const onUnReviewTrip = () => {
    dispatch(
      reviewTrip(props.trip?.tripId!,
        true,
        () => toast.info("Lane unreviewed"),
        (error: string) => toast.error(error || "Something went wrong")
      )
    )
  }

  const onDriverAssign = (driverId: string) => {
    dispatch(
      assignDriver(
        props.trip?.tripId!, 
        driverId, 
        () => {
          toast.info("Driver assigned");
          setShowDriverModal(false);
        })
    )
  }

  const assignedLane = props.trip?.lane;

  return (
    <SlideOutSidebar header="Trip Details" show={props.show} onClose={props.onClose}>
      <div className="xgs-lmi__details">
        <div className="xgs-lmi__title">
            Manifest: {props.trip?.manifestNumber}
            {
              props.trip?.isReviewed ? (
                <Tag mods={{color: TagColor.GREEN}}>REVIEWED</Tag>
              ) : (
                <Tag mods={{color: TagColor.RED}}>NOT REVIEWED</Tag>
              )
            }
          <LinehaulTripAcitivity tripId={props.trip?.tripId} show={props.show}/>
        </div>
        {(!props.trip?.driver || !props.trip?.lane) && <div className="xgs-lmi__info">
          <img src={infoIcon} alt="info"/>
          {getInfoMessage(!!props.trip?.driver, !!props.trip?.lane)}
        </div>}
        <div className="xgs-lmi__details__block">
          <div className="xgs-lmi__details__block-title">Route Summary</div>
          <div className="xgs-lmi__details__header">
            <div className="xgs-lmi__details__header__item">
              <label>Manifest:</label>
              <div>{props.trip?.manifestNumber}</div>
            </div>
            <div className="xgs-lmi__details__header__item">
              <label>Dispatched at:</label>
              <div>{moment(props.trip?.dispatchedDate, "YYYYMMDD").format(dateFormats.uiDate)}</div>
            </div>
            <div className="xgs-lmi__details__header__item">
              <label>Origin:</label>
              <div>{props.trip?.originTerminalName}, {props.trip?.originTerminalAirportcode} <span>{props.trip?.originTerminalNumber}</span></div>
            </div>
            <div className="xgs-lmi__details__header__item">
              <label>Destination:</label>
              <div>{props.trip?.destinationTerminalName}, {props.trip?.destinationTerminalAirportcode} <span>{props.trip?.destinationTerminalNumber}</span></div>
            </div>
          </div>
        </div>
        <div className="xgs-lmi__details__block">
          <div className="xgs-lmi__details__block-title">Driver Summary</div>
          <div className="xgs-lmi__details__driver">
            {!props.trip?.driver ? (
              <>
                <img src={driverGray} alt="Driver"/>
                <div className="xgs-lmi__details__driver__info">
                  <label>No driver assigned</label>
                </div>
                <Button className="xgs-lmi__details__driver__info__button--large" onClick={() => setShowDriverModal(true)} theme={ButtonThemes.blue}>Assign a driver</Button>
                <Button className="xgs-lmi__details__driver__info__button--small" onClick={() => setShowDriverModal(true)} theme={ButtonThemes.blue}>Assign</Button>
              </>
            ): (
              <>
                <img src={driverBlue} alt="Driver"/>
                <div className="xgs-lmi__details__driver__info">
                  <label>Assigned driver</label>
                  <div className="xgs-lmi__details__driver__info__name">{props.trip?.driver?.firstName + " " + props.trip?.driver?.lastName} <span>{props.trip?.driver?.driverId}</span></div>
                </div>
                <Button onClick={() => setShowDriverModal(true)} className="xgs-lmi__details__driver__edit" theme={ButtonThemes.transparent}><img src={editBlue} alt="Edit"/>&nbsp;&nbsp; Edit</Button>
              </>
            )}
          </div>
        </div>
        <div className={`xgs-lmi__details__block ${!assignedLane ? "hide-table-content" : ""}`}>
          <div className="xgs-lmi__details__block-title">Assigned Lane</div>
          <Table
            columns={getLaneColumns(props.trip?.lane?.laneId, true)}
            data={assignedLane ? [assignedLane] : []}
          />
          <Button onClick={() => setAssignLane(true)} className="xgs-lmi__details__table-button" theme={ButtonThemes.blue}>
            <img src={plusBlue} alt="Plus"/>{!props.trip?.lane ? "Assign Lane" : "Reassign lane"}
          </Button>
        </div>
        <div className={`xgs-lmi__details__block ${props.trip?.exceptions.length ? "" : "hide-table-content"}`}>
          <div className="xgs-lmi__details__block-title">Exceptions</div>
          {!!props.trip && (
            <Table
              columns={getAccessorialColumns((tripId: string, exceptionId: string) => {
                dispatch(deleteTripException(tripId, exceptionId));
              })}
              data={props.trip?.exceptions}
            />
          )}
          <Button onClick={() => setAddNewAccessorial(true)} className="xgs-lmi__details__table-button" theme={ButtonThemes.blue}>
            <img src={plusBlue} alt="Plus"/>Add new
          </Button>
        </div>
      </div>
      {!!addNewAccessorial && <AddAccessorial
        show={addNewAccessorial}
        closeModal={() => setAddNewAccessorial(false)}
        tripId={props.trip?.tripId!}
      />}
      {!!showDriverModal && <SelectDriver 
        closeModal={() => setShowDriverModal(false)}
        onDriverSelect={onDriverAssign}
        show={showDriverModal}
      />}
      {!!addNewLane && <AddLane
        originTerminalNumber={props.trip?.originTerminalNumber} 
        destinationTerminalNumber={props.trip?.destinationTerminalNumber}
        show={addNewLane}
        closeModal={() => setAddNewLane(false)}
      />}
      {!!assignLane && <LaneAssignment
        originTerminalNumber={props.trip?.originTerminalNumber!}
        destinationTerminalNumber={props.trip?.destinationTerminalNumber!}
        show={assignLane}
        closeModal={() => setAssignLane(false)}
        trip={props.trip}
      />}
      {!props.trip?.isReviewed && <Button style={{ marginBottom: 140 }} spinner={linehaulMileageState.requestStarted} disabled={!props.trip?.lane || !props.trip?.driver} onClick={onReviewTrip} theme={ButtonThemes.blue}>
        Review
      </Button>}
      {props.trip?.isReviewed && <Button style={{ marginBottom: 140 }} spinner={linehaulMileageState.requestStarted} disabled={!props.trip?.lane || !props.trip?.driver} onClick={onUnReviewTrip} theme={ButtonThemes.gray}>
        Unreviewed
      </Button>}
    </SlideOutSidebar>
  )
}