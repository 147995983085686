import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import XGSFormInput from "../../../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledSelectInput from "../../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { XGSSelectOption } from "../../../../ui-components/xgs-select/xgsSelect";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import { ItemModel } from "../../../../app/data/common/models";
import { BolItemSchema } from "../../../../app/data/bol/models";
import BolState from "../../../../slices/bol/BolState";
import {
  bolSelector  
} from "../../../../slices/bol/bolSlice";
import CommonState from "../../../../slices/common/CommonState";
import { commonSelector } from "../../../../slices/common/commonSlice";

interface BolItemFormProps {
  itemIndex?: number,
  onCancel?: () => void,
  onSubmit: (data: ItemModel) => void
}

let initialValues: ItemModel = {
  packageType: "ROLL",
  units: undefined,
  yards: undefined,
  weight: undefined,
  length: undefined,
  width: undefined,
  height: undefined,
  ratingClass: "",
  description: "",
  sidemark: "",
  notes: ""
};

const BolItemForm: React.FC<BolItemFormProps> = (props) => {
  const {
    itemIndex,
    onCancel,
    onSubmit
  } = props;
  const bolState: BolState = useSelector(bolSelector);
  const commonState: CommonState = useSelector(commonSelector);
  const [ratingClassOption, setRatingClassOption] = useState<XGSSelectOption | null>();
  const [packageType, setPackageType] = useState<string>("ROLL");
  const bolItemRef = useRef<any>(null);

  useEffect(() => {
    if (itemIndex === undefined) return;
    initialValues = {
      packageType: bolState.items[itemIndex].packageType,
      units: bolState.items[itemIndex].units,
      ratingClass: bolState.items[itemIndex].ratingClass,
      description: bolState.items[itemIndex].description,
      notes: bolState.items[itemIndex].notes,
      yards: bolState.items[itemIndex].yards,
      weight: bolState.items[itemIndex].weight,
      length: bolState.items[itemIndex].length,
      width: bolState.items[itemIndex].width,
      height: bolState.items[itemIndex].height,
      sidemark: bolState.items[itemIndex].sidemark
    };
    setPackageType(bolState.items[itemIndex].packageType);
    if (bolState.items[itemIndex].ratingClass) {
      let ratingClass = commonState.freightClasses.find(obj => obj.value === bolState.items[itemIndex].ratingClass);
      ratingClass && setRatingClassOption({
        value: ratingClass.value,
        label: ratingClass.label
      });
    }
    if (bolState.quoteNumber) {
      const notesField = document.getElementById("xgs-bol__item__notes--js") as HTMLInputElement;
      if (notesField) notesField.focus();
    }
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (itemIndex !== undefined) return;
    // Formik has issue when passing undefined values (https://github.com/formium/formik/issues/2332), so we passing empty strings
    bolItemRef.current.resetForm({ values: {
      packageType: "ROLL",
      units: "",
      yards: "",
      weight: "",
      length: "",
      width: "",
      height: "",
      ratingClass: "",
      description: "",
      sidemark: "",
      notes: ""
    }});
    setRatingClassOption(null);
  }, [itemIndex]);

  return (
    <div className="xgs-bol__item-form">
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={BolItemSchema}
        innerRef={bolItemRef}
        enableReinitialize
      >
        {(props: FormikProps<ItemModel>) => (
          <Form>
            <div className="xgs-tracking-filter__controls__item">
              <div className="xgs-form__label">Package type:</div>
              <div className="xgs-form__radio">
                <label>
                  <input
                    type="radio"
                    name="packageType"
                    value="ROLL"
                    checked={packageType === "ROLL"}
                    onChange={(e) => {
                      setPackageType("ROLL");
                      setTimeout(() => {
                        props.setFieldValue("packageType", "ROLL")
                        props.setFieldValue("width", "");
                        props.setFieldValue("height", "");
                      }, 100);
                      props.handleChange(e);
                    }}
                  /> Roll
                </label>
                <label>
                  <input
                    type="radio"
                    name="packageType"
                    value="PALLET"
                    checked={packageType === "PALLET"}
                    onChange={(e) => {
                      setPackageType("PALLET");
                      setTimeout(() => {
                        props.setFieldValue("packageType", "PALLET")
                        props.setFieldValue("yards", "");
                      }, 100);
                      if (ratingClassOption?.value === "1" || ratingClassOption?.value === "3") {
                        setTimeout(() => props.setFieldValue("ratingClass", ""), 100);
                        setRatingClassOption(null);
                      }
                      props.handleChange(e);
                    }}
                  /> Pallet
                </label>
              </div>
            </div>
            <div className="xgs-item-form__row">
              <XGSFormInput
                type="text"
                name="units"
                label="Pieces:"
                required={true}
                requiredAsteriskDisabled={false}
                labelMode={LabelModes.column}
                className="xgs-item-form__field"
              />
              {packageType === "ROLL" && (
                <XGSFormInput
                  type="text"
                  name="yards"
                  label="Sq. Yards:"
                  required={true}
                  requiredAsteriskDisabled={false}
                  labelMode={LabelModes.column}
                  className="xgs-item-form__field"
                />
              )}
              <XGSFormInput
                type="text"
                name="weight"
                label="Weight:"
                required={true}
                requiredAsteriskDisabled={false}
                labelMode={LabelModes.column}
                className="xgs-item-form__field"
              />
            </div>
            <div className="xgs-item-form__row">
              <XGSFormInput
                type="text"
                name="length"
                label="Length (in.):"
                required={false}
                requiredAsteriskDisabled={true}
                labelMode={LabelModes.column}
                className={"xgs-item-form__field" + ((packageType === "ROLL") ? " xgs-item-form__field--full-width" : "")}
                value={(props.values.length && !Number.isNaN(props.values.length)) ? props.values.length : ""}
              />
              {packageType === "PALLET" && (
                <>
                  <XGSFormInput
                    type="text"
                    name="width"
                    label="Width (in.):"
                    required={false}
                    requiredAsteriskDisabled={true}
                    labelMode={LabelModes.column}
                    className="xgs-item-form__field"
                    value={(props.values.width && !Number.isNaN(props.values.width)) ? props.values.width : ""}
                  />
                  <XGSFormInput
                    type="text"
                    name="height"
                    label="Height (in.):"
                    required={false}
                    requiredAsteriskDisabled={true}
                    labelMode={LabelModes.column}
                    className="xgs-item-form__field"
                    value={(props.values.height && !Number.isNaN(props.values.height)) ? props.values.height : ""}
                  />
                </>
              )}
            </div>
            <LabeledSelectInput
              name="packaging"
              value={ratingClassOption}
              options={packageType === "PALLET" ? commonState.freightClasses.filter(freightClass => freightClass.value !== "1" && freightClass.value !== "3") : commonState.freightClasses}
              label="Class:"
              labelMode={LabelModes.column}
              required={true}
              requiredAsteriskDisabled={false}
              className="xgs-pickup__field"
              onValueChange={(v) => {
                if (!v || !v.value || !v.label) {
                  setRatingClassOption(null);
                  setTimeout(() => props.setFieldValue("ratingClass", ""), 100);
                } else {
                  setRatingClassOption({
                    label: v.label,
                    value: v.value
                  });
                  setTimeout(() => props.setFieldValue("ratingClass", v.value), 100);
                }
              }}
            />
            {!(commonState.freightClasses.find(freightClass => freightClass.value === "1" || freightClass.value === "3")) && (commonState.freightClasses.length > 0) && (
              <div className="xgs-bol__item-form__freight-notes">
                <strong>Note:</strong> Carpet and Carpet Tile class can only be used for outbound shipments from North Georgia or South California.
              </div>
            )}
            <div className="xgs-item-form__row">
              <XGSFormInput
                type="text"
                name="sidemark"
                label="Sidemark:"
                required={false}
                requiredAsteriskDisabled={true}
                labelMode={LabelModes.column}
                className="xgs-item-form__field xgs-item-form__field--full-width"
              />
            </div>
            <div className="xgs-bol__item-form__row">
              <XGSFormInput
                type="text"
                name="description"
                label="Description:"
                required={false}
                requiredAsteriskDisabled={false}
                labelMode={LabelModes.column}
                className="xgs-bol__item-form__field"
                disabled={!!bolState.quoteNumber}
              />
            </div>
            <div className="xgs-pickup__textarea xgs-bol__item-form__row" style={{ marginBottom: 16 }}>
              <XGSFormInput
                type="text"
                name="notes"
                label="Notes:"
                required={false}
                requiredAsteriskDisabled={true}
                labelMode={LabelModes.column}
                className="xgs-bol__item-form__field"
                id="xgs-bol__item__notes--js"
                disabled={!!bolState.quoteNumber}
              />
            </div>
            <div className="xgs-bol__item-form__misc-text">
              <ul>
                <li>If <strong>description</strong> isn't provided it will be listed as &quot;Miscellaneous&quot;.</li>
                <li>Any <strong>notes</strong> added will appear under the line item on the bill of lading.</li>
                <li><strong>Sidemark</strong> will appear on bill of lading and shipping label.</li>
              </ul>
            </div>
            <div className="xgs-bol__item-form__row">
              <Button
                type="submit"
                theme={ButtonThemes.blue}
                disabled={
                  !props.isValid ||
                  !props.values.units ||
                  !!((itemIndex !== undefined) && !props.dirty)
                }
                className="xgs-bol__item-form__button"
              >
                {(itemIndex !== undefined) ? "Save changes" : "Add item"}
              </Button>
              {(itemIndex !== undefined) && (
                <Button
                  type="button"
                  theme={ButtonThemes.blue}
                  onClick={onCancel}
                  className="xgs-bol__item-form__button"
                >
                  Cancel
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BolItemForm;