import React, {
  ReactNode,
  DetailedHTMLProps,
  HTMLAttributes,
  memo,
} from "react";

import "./infoBlock.scss";

export interface InfoBlockProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  title: string;
  headerInfo?: ReactNode;
}

const InfoBlock: React.FC<InfoBlockProps> = memo(({title, headerInfo, className, ...props}) => {
  return (
    <div className={`xgs-info-block ${className ? className : ""}`} {...props}>
      {(title || headerInfo) && (
        <div className="xgs-info-block__header">
          <div className="xgs-info-block__header__title">{title}</div>
          <div className="xgs-info-block__header__info">{headerInfo}</div>
        </div>
      )}
      <div className="xgs-info-block__container">{props.children}</div>
    </div>
  );
});

export default InfoBlock;
