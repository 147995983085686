import { AnnouncementModel } from "../../app/data/announcement/models";

export default interface AnnouncementState {
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError: string;
  requestCreator: string;
  announcement: AnnouncementModel | null;
  announcements: AnnouncementModel[];
  announcementChecked: boolean;
}

export const initialAnnouncementState: AnnouncementState = {
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestError: "",
  requestCreator: "",
  announcement: null,
  announcements: [],
  announcementChecked: false
};
