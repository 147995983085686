import React, { useEffect, useRef, useState } from "react";

export interface PolylineProps extends google.maps.PolylineOptions {
  polylineData: string;
  onClick?: () => void;
}

const XGSPolyline: React.FC<PolylineProps> = ({ polylineData, onClick, ...props}) => {
  const [polyline, setPolyline] = useState<google.maps.Polyline>();

  const onClickListener = useRef<google.maps.MapsEventListener>();  

  useEffect(() => {
    // if (!polyline) {
    //   setPolyline(new google.maps.Polyline({
    //     path: window.google.maps.geometry.encoding.decodePath(polylineData),
    //     strokeColor: "#000077",
    //     strokeOpacity: 1.0,
    //     strokeWeight: 5,
    //   }));
    // }

    return () => {
      if (polyline) polyline.setMap(null);
    };
  }, [polyline, polylineData]);

  useEffect(() => {
    if (polyline) polyline.setOptions(props);
  }, [polyline, props]);

  useEffect(() => {
    if (polyline) polyline.setMap(null);
    setPolyline(new google.maps.Polyline({
      path: window.google.maps.geometry.encoding.decodePath(polylineData),
      strokeColor: "#000077",
      strokeOpacity: 1.0,
      strokeWeight: 5,
    }));
  // eslint-disable-next-line
  }, [polylineData]);

  useEffect(() => {
    if (polyline && onClick) {
      if (onClickListener.current) {
        google.maps.event.removeListener(onClickListener.current);
      }

      onClickListener.current = google.maps.event.addListener(polyline, 'click', onClick);
    }
  }, [polyline, onClick]);

  return null;
};

export default XGSPolyline;
