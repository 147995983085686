import React, { useState } from "react";

import ConfirmationModal from "../confirmation-modal/confirmationModal";
import XGSRegularIcons from "../icon/xgsRegularIcons";
import XGSIcon from "../icon/xgsIcon";

import "./help.scss";

export interface HelpProps {
  header: string;
}

const Help: React.FC<HelpProps> = ({ header, children }) => {  
  const [open, setOpen] = useState<boolean>(false);  

  return (
    <>
      <div
        className="help"
        title="Click to view related help instructions"
        onClick={() => { setOpen(true); }}
      >
        <XGSIcon
          icon={XGSRegularIcons.faQuestionCircle}
          size="1x"
        />
      </div>
      <ConfirmationModal
        opened={open}
        header={header}
        confirmButtonText="Close"
        onConfirm={() => { setOpen(false); }}
      >
        {children}
      </ConfirmationModal>
    </>
  );
};

export default Help;
