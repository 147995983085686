import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";
import Modal from "react-modal";
import { Form, Formik, FormikProps } from "formik";
import { copyTextToClipboard } from "../../../hooks/utils";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSRegularIcons from "../../../ui-components/icon/xgsRegularIcons";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import LabeledTextInput from "../../../ui-components/molecules/labeled-inputs/labeled-text-input/labeledTextInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import modalStyle from "../../../app/data/common/modalStyle"
import {
  ShipmentShareModel,
  ShipmentShareSchema
} from "../../../app/data/public-shipment-details/models";
import PublicShipmentDetailsState from "../../../slices/public-shipment-details/PublicShipmentDetailsState";
import {
  publicShipmentDetailsSelector,
  shareShipment
} from "../../../slices/public-shipment-details/publicShipmentDetailsSlice";
import "../../../sass/modal.scss";

Modal.setAppElement("#root");

export interface ShipmentShareProps {
  invoiceNumber: number;
}

let initialValues: ShipmentShareModel = {
  email: ""
};

const ShipmentShare: React.FC<ShipmentShareProps> = (props) => {
  const dispatch = useDispatch();
  const publicShipmentDetailsState: PublicShipmentDetailsState = useSelector(publicShipmentDetailsSelector);
  const [modalOpened, setModalOpened] = useState<boolean>(false);

  const onClickSend = (data: ShipmentShareModel) => {
    if (!props.invoiceNumber || !data.email) return;
    dispatch(shareShipment(data.email, props.invoiceNumber, () => {
      toast.info("The shipment was successfully shared!");
      mixpanel.track("Shipment was shared by email");
      setModalOpened(false);
    }, (error: any) => {
      toast.error(error);
    }));
  };

  const shipmentLink = () => {
    return process.env.REACT_APP_DOMAIN + "/shipment-tracking/" + props.invoiceNumber;
  };

  return (
    <>
      <span className="xgs-shipment-details__subscribe__button">
        <div
          className="xgs-shipment-details__subscribe__button xgs-site__content-container__toolbar__buttons__item"
          onClick={() => setModalOpened(true)}
        >
          <XGSIcon
            className="xgs-shipment-details__subscribe__button__icon"
            icon={XGSIcons.faShareAlt}
            size="lg"
            title="Share Shipment"
          />
          <div className="xgs-shipment-details__subscribe__button__label">Share</div>
        </div>
      </span>
      <Modal
        isOpen={modalOpened}
        style={modalStyle}
      >
        <div className="xgs-modal__header">
          Share Shipment
        </div>
        <div className="xgs-shipment-details__share__clipboard">
          <div className="xgs-shipment-details__share__clipboard__field">
            <LabeledTextInput
              label="Via link:"
              labelMode={LabelModes.column}
              type="text"
              value={shipmentLink()}
              onClick={() => copyTextToClipboard(shipmentLink())}
              disabled
            />
          </div>
          <div className="xgs-shipment-details__share__clipboard__button">
            <XGSIcon
              icon={XGSRegularIcons.faCopy}
              size="lg"
              title="Copy to clipboard"
              onClick={() => copyTextToClipboard(shipmentLink())}
            />
          </div>
        </div>
        <hr className="xgs-shipment-details__share__hr" />
        <Formik
          onSubmit={onClickSend}
          initialValues={initialValues}
          validationSchema={ShipmentShareSchema}
        >
          {(props: FormikProps<ShipmentShareModel>) => (
            <Form>
              <div className="xgs-modal__content">
                <XGSFormInput
                  type="text"
                  name="email"
                  label="Via email:"
                  labelMode={LabelModes.column}
                  required={true}
                  requiredAsteriskDisabled={true}
                  placeholder="name@domain.com"
                />
              </div>
              <div className="xgs-modal__buttons">
                <Button
                  theme={ButtonThemes.blue}
                  spinner={publicShipmentDetailsState.loading}
                  type="submit"
                  className="xgs-modal__button"
                  disabled={!props.isValid || !props.dirty}
                >
                  Share
                </Button>
                <Button
                  theme={ButtonThemes.gray}
                  onClick={() => setModalOpened(false)}
                  type="button"
                  className="xgs-modal__button"
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ShipmentShare;
