import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import { toast } from "react-toastify";
import XGSPopup, { PopupTheme } from "../../../ui-components/xgs-popup/xgsPopup";
import XGSFormTextarea from "../../../ui-components/form/textarea/xgsFormTextarea";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import { ERROR_MESSAGES } from "../../../app/data/common/errorMessages";
import {
  ShipmentAddCommentModel,
  ShipmentAddCommentSchema
} from "../../../app/data/tracking/ShipmentCommentsModels";
import {
  addComment,
  shipmentDetailsSelector
} from "../../../slices/shipment-details/shipmentDetailsSlice";

export interface ShipmentAddCommentProps {
  invoiceNumber: number;
  show: boolean;
  onClose: () => void;
}

let initialValues: ShipmentAddCommentModel = {
  note: ""
};

const ShipmentAddComment: React.FC<ShipmentAddCommentProps> = ({ onClose, ...props}) => {
  const dispatch = useDispatch();
  const shipmentDetailsState = useSelector(shipmentDetailsSelector);

  const onAddComment = (data: ShipmentAddCommentModel) => {
    dispatch(addComment(props.invoiceNumber, data.note, onClose, (error) => toast.error(error || ERROR_MESSAGES.COMMON)));
  };

  return (
    <XGSPopup
      opened={props.show}
      onClose={onClose}
      closeOnDocumentClick={false}
      theme={PopupTheme.NEW}
      className="xgs-shipment-details__add-comment-modal"
    >
      <div className="xgs-modal__header">
        Add new comment
      </div>
      <Formik
        onSubmit={onAddComment}
        initialValues={initialValues}
        validationSchema={ShipmentAddCommentSchema}
      >
        {(props: FormikProps<ShipmentAddCommentModel>) => (
          <Form>
            <div className="xgs-modal__content">
              <XGSFormTextarea
                name="note"
                label="Comment Text:"
                required={true}
                rows={3}
                counter={60}
              />
            </div>
            <div className="xgs-modal__buttons">
              <Button
                theme={ButtonThemes.blue}
                spinner={shipmentDetailsState.addingComment}
                type="submit"
                className="xgs-modal__button"
                disabled={!props.isValid || !props.dirty}
              >
                Add
              </Button>
              <Button
                theme={ButtonThemes.gray}
                onClick={onClose}
                type="button"
                className="xgs-modal__button"
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </XGSPopup>
  );
};

export default ShipmentAddComment;
