import React from "react";

import { ReactComponent as LocationMarker } from "../../../images/locationMarker.svg";
import { ReactComponent as TruckIcon } from "../../../images/truck.svg";
import { MARKER_STROKE_COLOR, TRUCK_MARKER, getStopFillColor, getStopLabelProps } from "../../../services/map";
import { PickupStatus, StopStatus, StopType } from "../../../app/data/common/route";

import "./stopsLegend.scss";

const stopsConfig = [
  {
    markerColor: getStopFillColor(StopType.PICKUP, PickupStatus.UNASSIGNED),
    labelStyle: getStopLabelProps(StopType.PICKUP),
    label: "PU",
    description: "Pickup location",
  },
  {
    markerColor: getStopFillColor(StopType.SERVICE_CENTER),
    labelStyle: getStopLabelProps(StopType.SERVICE_CENTER),
    label: "SC",
    description: "Service Center",
  },
  {
    markerColor: getStopFillColor(StopType.STOP, StopStatus.UPCOMING),
    labelStyle: getStopLabelProps(StopStatus.UPCOMING),
    label: "3",
    description: "Upcoming Stop",
  },
  {
    markerColor: getStopFillColor(StopType.STOP, StopStatus.ACTIVE),
    labelStyle: getStopLabelProps(StopStatus.ACTIVE),
    label: "3",
    description: "In Progress Stop",
  },
  {
    markerColor: getStopFillColor(StopType.STOP, StopStatus.COMPLETED),
    labelStyle: getStopLabelProps(StopStatus.COMPLETED),
    label: "3",
    description: "Completed Stop",
  },
  {
    markerColor: getStopFillColor(StopType.PICKUP, PickupStatus.ASSIGNED),
    labelStyle: getStopLabelProps(StopType.PICKUP),
    label: "PU",
    description: "Assigned Pickup",
  },
  {
    markerColor: getStopFillColor(StopType.PICKUP, PickupStatus.COMPLETED),
    labelStyle: getStopLabelProps(StopType.PICKUP),
    label: "PU",
    description: "Assigned Pickup Completed",
  },
];

const StopsLegend: React.FC = () => {
  return (
    <div>
      {stopsConfig.map((item, i) => (
        <div className="stops-legend__item" key={i}>
          <div className="stops-legend__marker">
            <LocationMarker style={{ color: item.markerColor }} />
            <span className="stops-legend__label" style={item.labelStyle}>{item.label}</span>
          </div>
          <span className="stops-legend__description">{item.description}</span>
        </div>
      ))}

      <div className="stops-legend__item">
        <div className="stops-legend__marker">
          <TruckIcon style={{ color: TRUCK_MARKER.COLOR, stroke: MARKER_STROKE_COLOR }} />
        </div>
        <span className="stops-legend__description">Driver Location</span>
      </div>
    </div>
  )
};

export default StopsLegend;