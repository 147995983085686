import React, { useState } from "react";
import { useSelector } from "react-redux";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import LabeledMaskInput from "../../ui-components/molecules/labeled-inputs/labeled-mask-input/labeledMaskInput";
import LabeledSelectInput from "../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import LabeledDateRangeInput from "../../ui-components/molecules/labeled-inputs/labeled-date-range-input/labeledDateRangeInput";
import { LabelModes } from "../../ui-components/molecules/labeled-inputs/labeledInput";
import { XGSSelectOption } from "../../ui-components/xgs-select/xgsSelect";
import { ClaimSearchModel } from "../../app/data/claims/models";
import ClaimsState from "../../slices/claims/ClaimsState";
import { claimsSelector } from "../../slices/claims/claimsSlice";
import {
  CLAIM_REASON_TYPES,
  CLAIM_STATUSES
} from "./constants";
import "./claims.scss";

interface ClaimsListFilersProps {
  onChange: (arg: ClaimSearchModel | null) => void
};

const ClaimsListFilters: React.FC<ClaimsListFilersProps> = (props) => {
  const claimsState: ClaimsState = useSelector(claimsSelector);
  const [searchClaim, setSearchClaim] = useState<string>("");
  const [searchProbill, setSearchProbill] = useState<string>("");
  const [searchReason, setSearchReason] = useState<XGSSelectOption | null>();
  const [searchStatus, setSearchStatus] = useState<XGSSelectOption | null>();
  const [searchStartDate, setSearchStartDate] = useState<string>();
  const [searchEndDate, setSearchEndDate] = useState<string>();

  const onSearchStart = (latestChangesObj?: ClaimSearchModel) => {
    let searchParams: ClaimSearchModel = {
      ...(searchClaim && {claimNumber: Number(searchClaim)}),
      ...(searchProbill && {probill: Number(searchProbill)}),
      ...(searchReason && {reason: searchReason.value || ""}),
      ...(searchStatus && {status: searchStatus.value || ""}),
      ...(searchStartDate && {from: searchStartDate.toApiDateFormat()}),
      ...(searchEndDate && {to: searchEndDate.toApiDateFormat()}),
      ...(latestChangesObj && {...latestChangesObj})
    };
    props.onChange(searchParams);
  };

  const onSearchClear = () => {
    setSearchClaim("");
    setSearchProbill("");
    setSearchReason(null);
    setSearchStatus(null);
    setSearchStartDate("");
    setSearchEndDate("");
    props.onChange(null);
  };

  return (
    <div className="xgs-list__controls xgs-claims__filters">
      <div className="xgs-list__controls__search">
        <LabeledMaskInput
          allowNegative={false}
          label="Claim #:"
          labelMode={LabelModes.column}
          className="xgs-list__controls__search__input xgs-list__controls__search__input--narrow"
          onValueChange={(v) => setSearchClaim(v)}
          onKeyDown={(e) => (e.key === "Enter") && !(claimsState.requestStarted && claimsState.requestCreator === "GET_CLAIMS") && onSearchStart()}
          value={searchClaim}
          format="######"
        />
        <LabeledMaskInput
          allowNegative={false}
          label="Probill:"
          labelMode={LabelModes.column}
          onValueChange={(v) => setSearchProbill(v)}
          onKeyDown={(e) => (e.key === "Enter") && !(claimsState.requestStarted && claimsState.requestCreator === "GET_CLAIMS") && onSearchStart()}
          value={searchProbill}
          className="xgs-list__controls__search__input xgs-list__controls__search__input--narrow"
          format="########"
        />
        <LabeledSelectInput
          value={searchReason}
          options={CLAIM_REASON_TYPES}
          label="Reason:"
          labelMode={LabelModes.column}
          className="xgs-list__controls__search__input"
          onValueChange={(v) => {
            setSearchReason(v);
            onSearchStart({
              reason: v?.value
            });
          }}
          isClearable
        />
        <LabeledSelectInput
          value={searchStatus}
          options={CLAIM_STATUSES}
          label="Status:"
          labelMode={LabelModes.column}
          className="xgs-list__controls__search__input xgs-list__controls__search__input--narrow"
          onValueChange={(v) => {
            setSearchStatus(v);
            onSearchStart({
              status: v?.value
            });
          }}
          isClearable
        />
        <LabeledDateRangeInput
          label="Dates range:"
          labelMode={LabelModes.column}
          className="xgs-list__controls__search__input"
          start={searchStartDate}
          end={searchEndDate}
          onStartChange={(v) => {
            setSearchStartDate(v);
            onSearchStart({
              from: v?.toApiDateFormat()
            });
          }}
          onEndChange={(v) => {
            setSearchEndDate(v);
            onSearchStart({
              to: v?.toApiDateFormat()
            });
          }}
        />
        <div className="xgs-claims__filters__buttons">
          <Button
            className="xgs-list__controls__button"
            theme={ButtonThemes.blue}
            disabled={claimsState.requestStarted && claimsState.requestCreator === "GET_CLAIMS"}
            onClick={() => onSearchStart()}>
            Search
          </Button>
          <Button
            className="xgs-list__controls__button"
            theme={ButtonThemes.gray}
            onClick={onSearchClear}>
            Clear
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ClaimsListFilters;
