import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import Loading from "../../../../ui-components/loading/loading";
import Amount from "../../../../ui-components/molecules/amount/amount";
import { StepProps } from "../../../../app/data/common/models";
import { GetRatesRequestModel } from "../../../../app/data/rates/models";
import ReturnState from "../../../../slices/return/ReturnState";
import {
  getReturnRates,
  returnSelector
} from "../../../../slices/return/returnSlice";
import { userSelector } from "../../../../slices/user/userSlice";
import UserState from "../../../../slices/user/UserState";
import "../../bol.scss";

const ReturnRateQuoteStep: React.FC<StepProps> = (props) => {
  const { previous, next } = props;
  const returnState: ReturnState = useSelector(returnSelector);
  const userState: UserState = useSelector(userSelector);
  const dispatch = useDispatch();

  const onClickBack = () => {
    previous && previous();
  };

  const onClickSubmit = () => {
    next && next();
  };

  const getRates = useCallback(() => {
    let items = returnState.items.filter(item => !item.deleted).map(({units, ...rest}) => ({...rest, pieces: units}));;
    let request: GetRatesRequestModel = {
      accountNumber: userState.activeSubAccount?.accountNumber,
      originZip: returnState.returnShipper.address.postalCode,
      destinationZip: returnState.returnConsignee.address.postalCode,
      tenderDate: returnState.returnShipper.pickupDate.toApiDateFormat(),
      items,
      specialServices: returnState.returnShipper.specialServices
    };
    dispatch(getReturnRates(request));
  }, [
    dispatch,
    userState.activeSubAccount?.accountNumber,
    returnState.items,
    returnState.returnShipper,
    returnState.returnConsignee
  ]);

  useEffect(() => {
    getRates();
  }, [getRates]);

  return (
    <div className="xgs-bol__return__step">
      <div className="xgs-bol__section">Rate Quote</div>
      <div className="xgs-bol__return__rates__result">
        {returnState.requestStarted && returnState.requestCreator === "GET_RATES" && (
          <Loading isLoading={true} />
        )}
        {!(returnState.requestStarted && returnState.requestCreator === "GET_RATES") && (
          <>
            {returnState.rates?.error?.message && (
              <span>
                {returnState.rates.error.message}
              </span>
            )}
            {!returnState.rates?.error?.message && returnState.rates?.totalAmount && (
              <>
                Total Charges: <span><Amount amount={returnState.rates.totalAmount} /></span>
                <div className="xgs-bol__return__rates__result__notes">Rate quote is based on the inputs provided and is subject to change if inaccurate.</div>
              </>
            )}
          </>
        )}
      </div>
      <div className="xgs-bol__buttons">
        <Button
          type="button"
          theme={ButtonThemes.gray}
          className="xgs-bol__nav-button"
          onClick={onClickBack}
        >
          Back
        </Button>
        <Button
          type="button"
          theme={ButtonThemes.blue}
          className="xgs-bol__nav-button"
          onClick={onClickSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default ReturnRateQuoteStep;
