import React, { memo } from "react";
import { Field } from "formik";

import XGSCheckbox, { XGSCheckboxProps } from "../../xgs-checkbox/xgsCheckbox";

const XGSFormCheckbox: React.FC<XGSCheckboxProps> = memo((props) => {
  return (
    <XGSCheckbox
      {...props}
      input={Field}
    >
      {props.children}
    </XGSCheckbox>
  );
});

export default XGSFormCheckbox;