import {
  LocationColors,
  PickupColors,
  PickupStatus,
  StopColors,
  StopStatus,
  StopType,
} from "../../app/data/common/route";

export const getStopFillColor = (type: StopType, status?: StopStatus | PickupStatus | null) => {
  const defaultColor = "#E0E0E0";

  switch (type) {
    case StopType.SERVICE_CENTER:
      return LocationColors[type] || defaultColor;

    case StopType.PICKUP:
      return PickupColors[status as PickupStatus] || defaultColor

    case StopType.STOP:
      return StopColors[status as StopStatus] || defaultColor;

    default:
      return defaultColor;
  }
};

export const getStopLabelProps = (statusOrType?: StopStatus | StopType | null) => {  
  return ({
    color: statusOrType === StopStatus.UPCOMING ? "#000000" : "#FFFFFF",
    fontSize: statusOrType && statusOrType in StopStatus ? "18px" : "16px",
  });
};

export const MARKER_STROKE_COLOR = "#FFFFFF";

export const TRUCK_MARKER = {
  PATH: "M22.2143 9V8.5H21.7143H5H4.5V9V10.9091V11.4091H5H11.9286V12.3182H5H4.5V12.8182V14.7273V15.2273H5H10.0714V16.1364H5H4.5V16.6364V18.5455V19.0455H5H8.21429V19.9545H5H4.5V20.4545V26.1818V26.6818H5H6.38518C6.62657 28.8211 8.3978 30.5 10.5714 30.5C12.7451 30.5 14.5163 28.8211 14.7577 26.6818H21.2423C21.4837 28.8211 23.2549 30.5 25.4286 30.5C27.6022 30.5 29.3734 28.8211 29.6148 26.6818H31H31.5V26.1818V19.5V19.1785L31.2075 19.0451L28.9562 18.0181L26.3063 12.5986L26.1692 12.3182H25.8571H22.2143V9ZM24.4 15.2273L25.7779 18.0455H22.2143V15.2273H24.4ZM11.9286 26.1818C11.9286 26.9731 11.308 27.5909 10.5714 27.5909C9.83481 27.5909 9.21429 26.9731 9.21429 26.1818C9.21429 25.3905 9.83481 24.7727 10.5714 24.7727C11.308 24.7727 11.9286 25.3905 11.9286 26.1818ZM26.7857 26.1818C26.7857 26.9731 26.1652 27.5909 25.4286 27.5909C24.692 27.5909 24.0714 26.9731 24.0714 26.1818C24.0714 25.3905 24.692 24.7727 25.4286 24.7727C26.1652 24.7727 26.7857 25.3905 26.7857 26.1818Z",
  COLOR: "#23356d",
};

export const LOCATION_MARKER = {
  PATH: "M9.56408 32.2151C8.60121 30.6706 7.49676 29.2381 6.41077 27.8296L6.36848 27.7747C5.26367 26.3417 4.1797 24.9318 3.24554 23.4175L3.24513 23.4168C1.4704 20.5499 0.5 17.3803 0.5 14C0.5 6.53614 6.53614 0.5 14 0.5C21.4639 0.5 27.5 6.53614 27.5 14C27.5 17.3801 26.5297 20.549 24.7361 23.4147L24.7337 23.4187C23.7997 24.9425 22.7159 26.3573 21.6115 27.7897L21.5155 27.9142C20.4472 29.2996 19.3633 30.7053 18.4165 32.2143L18.4163 32.2145C17.4575 33.7445 16.786 35.1705 16.0452 36.7923L16.0448 36.7931C15.9536 36.9938 15.8621 37.2265 15.7723 37.4549C15.7335 37.5536 15.695 37.6515 15.657 37.7456C15.5249 38.0727 15.3877 38.3844 15.2255 38.6565C14.9031 39.1975 14.5374 39.5 14 39.5C13.4625 39.5 13.0966 39.1975 12.7712 38.6553C12.6076 38.3824 12.4684 38.0703 12.3332 37.7423C12.2984 37.6578 12.2632 37.5705 12.2277 37.4825C12.132 37.2454 12.034 37.0026 11.9346 36.7917C11.2112 35.1644 10.5208 33.7419 9.56408 32.2151ZM9.56408 32.2151C9.56395 32.2149 9.56382 32.2147 9.56368 32.2145L9.14 32.48L9.56431 32.2155C9.56423 32.2154 9.56416 32.2153 9.56408 32.2151Z",
  COLOR: "#E0E0E0",
};
