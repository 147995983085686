import { createSlice } from "@reduxjs/toolkit";
import { IState } from "../..";
import { AppThunk } from "../../../app/store";
import RoutesService from "../../../app/data/plan-probills/routes/routesService";
import { RemoveProbillFromRouteState } from "./routesState";
import { getRouteStops } from "./routeStopsSlice";

const initialState: RemoveProbillFromRouteState = {
  loading: false,
  loadingFailed: false,
  loaded: false,
  error: ""
};

const routesService = RoutesService.getInstance();

export const removeProbillFromRouteSlice = createSlice({
  name: "removeProbillFromRoute",
  initialState,
  reducers: {
    removeProbillFromRouteResetState: (state) => initialState,
    removeProbillFromRouteLoadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
    },
    removeProbillFromRouteLoadingSucceed: (state) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
    },
    removeProbillFromRouteLoadingFailed: (state, { payload }) => {
      state.loading = false;
      state.loadingFailed = true;
      state.error = payload;
    },

  },
});

export const {
  removeProbillFromRouteResetState,
  removeProbillFromRouteLoadingStarted,
  removeProbillFromRouteLoadingSucceed,
  removeProbillFromRouteLoadingFailed,

} = removeProbillFromRouteSlice.actions;

export const removeProbillFromRouteSelector = (state: IState) => state.removeProbillFromRoute;

export const removeProbillFromRoute = (
  reqData: any,
  onSuccess?: () => void,
  onError?: (error: string) => void,
  onClose?: () => void
): AppThunk => async (dispatch) => {
  dispatch(removeProbillFromRouteLoadingStarted());
  const response = await routesService.removeProbillFromRoute({ ...reqData });
  if (response.ok() && response.data && response.data.result) {
    dispatch(removeProbillFromRouteLoadingSucceed());
    dispatch(getRouteStops({...reqData}));
    onSuccess && onSuccess();
  } else {
    let error = "Internal Server Error, re-try the action or contact support";
    if (response && response.data && response.data.error) error = response.data.error;
    dispatch(removeProbillFromRouteLoadingFailed(error));
    onError && onError(error)
  }
  onClose && onClose()
};

const removeProbillFromRouteReducer = removeProbillFromRouteSlice.reducer;
export default removeProbillFromRouteReducer;