export default interface InvoiceRecordsState {
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError?: string | null;
  requestCreator?: string | null;
  records?: any | null;
}

export const initialInvoiceRecordsState: InvoiceRecordsState = {
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestError: null,
  requestCreator: null,
  records: null
};
