import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import RoutesService from "../../../app/data/plan-probills/routes/routesService";
import { CreatePicklistFromRouteState } from "./routesState";
import { getActiveRouteAndStops } from "./activeRoutesSlice";

const initialState: CreatePicklistFromRouteState = {
  loading: false,
  loadingFailed: false,
  loaded: false,
  error: ""
};

const routesService = RoutesService.getInstance();

export const createPicklistFromRouteSlice = createSlice({
  name: "createPicklistFromRoute",
  initialState,
  reducers: {
    resetCreatePicklistFromRouteState: (state) => initialState,
    createPicklistFromRouteloadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
      state.error = "";
    },
    createPicklistFromRouteloadingSucceed: (state) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
      state.error = "";
    },
    createPicklistFromRouteloadingFailed: (state, { payload }) => {
      state.loading = false;
      state.loadingFailed = true;
      state.error = payload;
    }
  },
});

export const {
  resetCreatePicklistFromRouteState,
  createPicklistFromRouteloadingStarted,
  createPicklistFromRouteloadingSucceed,
  createPicklistFromRouteloadingFailed
} = createPicklistFromRouteSlice.actions;

export const createPicklistFromRouteSelector = (state: IState) => state.createPicklistFromRoute;

export const convertRouteToPicklist = (
  reqData: any,
  terminalReq: any,
  onSuccess?: () => void,
  onError?: (error: string) => void
): AppThunk => async (dispatch) => {
  dispatch(createPicklistFromRouteloadingStarted());
  const response = await routesService.createPicklistFromRoute({ ...reqData });
  if (response.ok() && response.data && response.data.result) {
    dispatch(createPicklistFromRouteloadingSucceed());
    dispatch(getActiveRouteAndStops({ ...reqData, ...terminalReq}));
    onSuccess && onSuccess();
  } else {
    let error = "Internal Server Error, re-try the action or contact support";
    if (response && response.data && response.data.error) error = response.data.error;
    dispatch(createPicklistFromRouteloadingFailed(error));
    onError && onError(error)
  }
};

const createPicklistFromRouteReducer = createPicklistFromRouteSlice.reducer;
export default createPicklistFromRouteReducer;