import { PaginationModel } from "../../app/data/common/models";

export default interface CustomerPickupState {
  requestStarted: boolean;
  fetchPortionStarted: boolean;
  requestCreator: string;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestFailStatus: number;
  requestError: string;
  pickups: any[];
  pickupsRequest: PaginationModel | null,
};

export const initialCustomerPickupState: CustomerPickupState = {
  requestStarted: false,
  fetchPortionStarted: false,
  requestCreator: "",
  requestSucceed: false,
  requestFailed: false,
  requestFailStatus: 0,
  requestError: "",
  pickups: [],
  pickupsRequest: null,
};