import React from "react";
import ShipmentDetailsModel, { steppedProgressDataType } from "../../app/data/tracking/ShipmentDetailsModel";
import { PublicShipmentDetails } from "../../app/data/public-shipment-details/models";
import { SHIPMENT_STATUSES } from "../../app/data/common/ShipmentStatuses";
import TrailerLocation from "../../features/trailer-location/trailerLocation";
import "./steppedProgress.scss";

interface SteppedProgressProps {
  className?: string;
  shipmentDetails: ShipmentDetailsModel | PublicShipmentDetails;
  public?: boolean;
}

const SteppedProgress: React.FC<SteppedProgressProps> = (props) => {
  const isOutForDelivery = () => props.shipmentDetails.status?.description === SHIPMENT_STATUSES.outForDelivery;

  const isLastActiveStep = (i: number) => {
    if (!props.shipmentDetails.progress[i].reached) return false;
    if (props.shipmentDetails.progress[i + 1] && !props.shipmentDetails.progress[i + 1].reached) return true;
    return false;
  };

  return (
    <ul className={`xgs-stepped-progress ${props.className ? props.className : ""}`}>
      {props.shipmentDetails.progress.map((step: steppedProgressDataType, index: number) => (
        <li
          className={`${step.reached ? "reached" : ""} ${(!step.completed && step.reached) ? "current" : ""}`}
          key={index}
        >
          <div className="xgs-stepped-progress__step__header">{step.statusTitle}</div>
          {/*{step.date && <div className="xgs-stepped-progress__step__subheader">{step.date.toUiDateFormat()}</div>}*/}
          {!props.public &&
            isOutForDelivery() &&
            props.shipmentDetails.trailer &&
            props.shipmentDetails.showOnMap &&
            (step.statusTitle === "Out for Delivery" || isLastActiveStep(index)) &&
            <TrailerLocation invoiceNumber={props.shipmentDetails.invoice.invoiceNumber} textMode={true} />
          }
        </li>
      ))}
    </ul>
  );
};

export default SteppedProgress;
