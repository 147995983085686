import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentContainer from "../../../templates/content-container/contentContainer";
import Chat from "./chat/chat";
import { Form, Formik, FormikProps } from "formik";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import { ChatFormModel, ChatFormSchema, ChatMessageModel } from "../../../app/data/chat/models";
import { addMessage, chatSelector, sendChatMessage, setProbillNumber, requestStarted, requestSucceed, setAnimation, setChatId } from "../../../slices/chat/chatSlice";

import "./shipmentChat.scss";
import { toast } from "react-toastify";

const initialValues = {
  probillNumber: "",
};

const INITIAL_MESSAGES = [
  "Hello! I'm AI-powered helper.",
  "Can you please provide the probill number in form above 👆 for the shipment you need help with?",
];

const MESSAGE_DELAY = 500;

const ShipmentChat: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const chatState = useSelector(chatSelector);
  const messages = chatState.messages;
  const formRef = useRef<FormikProps<ChatFormModel>>(null);

  const send = (message: ChatMessageModel) => {
    dispatch(sendChatMessage(
      message,
      () => toast.error("Something went wrong.")
    ));
  }

  const onSubmitProbillNumber = (values: ChatFormModel) => {
    const newGroup = !!chatState.probillNumber;
    dispatch(setProbillNumber(values.probillNumber));
    dispatch(setChatId(null));
    send({ text: `Probill Number: ${values.probillNumber}`, newGroup });

    formRef.current?.resetForm();
  };

  useEffect(() => {
    let timeouts: any[] = [];

    if (chatState.messages.length < INITIAL_MESSAGES.length) {
      INITIAL_MESSAGES.slice(chatState.messages.length).forEach((message, i) => {
        dispatch(requestStarted(""));
        const timeout = setTimeout(() => {
          dispatch(requestSucceed());
          dispatch(addMessage({
            incoming: true,
            text: message,
          }));
        }, MESSAGE_DELAY * (i + 1));

        timeouts.push(timeout);
      }); 
    }

    return () => {  
      timeouts.forEach((timeout: any) => { clearTimeout(timeout) });
    }
  }, [chatState.messages, dispatch]);

  useEffect(() => {
    return () => { dispatch(setAnimation(false)); }
  }, [dispatch]);

  return (
    <ContentContainer title="Shipment Chat" mods={{ "screen-height": true, "mobile-no-padding": true }}>
      <div className="shipment-chat">
      <Formik
          onSubmit={onSubmitProbillNumber}
          initialValues={initialValues}
          validationSchema={ChatFormSchema}
          innerRef={formRef}
        >
          {(formikProps: FormikProps<ChatFormModel>) => (
            <Form className="shipment-chat__form">              
                <XGSFormInput
                  type="text"
                  name="probillNumber"
                  required={true}
                  requiredAsteriskDisabled={true}
                  labelMode={LabelModes.column}
                  placeholder="Enter Probill Number"
                  maxLength={8}
                />

                <Button
                  type="submit"
                  theme={ButtonThemes.blue}
                  disabled={!formikProps.isValid || !formikProps.dirty}
                >
                  Submit
                </Button>
              
            </Form>
          )}
        </Formik>

        <Chat
          mix="shipment-chat__chat"
          messages={messages}
          onSend={send}
          isLoading={chatState.requestStarted}
          isDisabled={!chatState.probillNumber}
          animated={chatState.animation}
        />
      </div>
    </ContentContainer>
  );
};

export default ShipmentChat;
