import api, { ApiResponse } from "../api";
import axios, { CancelTokenSource } from "axios";
import ShipmentModel from "./ShipmentModel";
import { InfiniteScrollCollectionResponse } from "../common/models";
import ShipmentDetailsModel from "./ShipmentDetailsModel";
import ShipmentTripHistoryModel from "./ShipmentTripHistoryModel";
import ServiceCentersModel, { ServiceCentersRequestModel } from "./ServiceCentersModel";
import SearchShipmentRequestModel from "./SearchShipmentRequestModel";
import { urlParams } from "../../../services/common/urlParams";
import { TrackShipmentStats } from "./TrackShipmentStats";

export const REQUEST_ID: {
  [key: string]: string;
} = {
  GET_SERVICE_CENTERS: "get_service_centers",
  GET_SHIPMENTS: "get_shipments",
};

class TrackingService {
  private source: {
    [key: string]: CancelTokenSource;
  };
  private statsCancelToken: CancelTokenSource | null = null;
  private static instance: TrackingService;

  private constructor() {
    this.source = {};
  }

  static getInstance(): TrackingService {
    if (!TrackingService.instance) {
      TrackingService.instance = new TrackingService();
    }

    return TrackingService.instance;
  }

  public getServiceCenters = async (params?: ServiceCentersRequestModel): Promise<
    ApiResponse<ServiceCentersModel>
  > => {
    this.source[REQUEST_ID.GET_SERVICE_CENTERS] && this.source[REQUEST_ID.GET_SERVICE_CENTERS].cancel();
    this.source[REQUEST_ID.GET_SERVICE_CENTERS] = axios.CancelToken.source();
    return await api.get("/service-centers", { params: urlParams(params), cancelToken: this.source[REQUEST_ID.GET_SERVICE_CENTERS].token });
  };

  public searchShipments = async (
    rawRequestModel: SearchShipmentRequestModel
  ): Promise<ApiResponse<InfiniteScrollCollectionResponse<ShipmentModel>>> => {
    let requestModel = (({ lastIds, ...rest }) => rest)(rawRequestModel);
    this.source[REQUEST_ID.GET_SHIPMENTS] && this.source[REQUEST_ID.GET_SHIPMENTS].cancel();
    this.source[REQUEST_ID.GET_SHIPMENTS] = axios.CancelToken.source();
    return await api.post(`/invoice-search${rawRequestModel.lastIds ? "?lastIds=" + rawRequestModel.lastIds : ""}`, requestModel, {
      cancelToken: this.source[REQUEST_ID.GET_SHIPMENTS].token
    });
  };

  public getShipmentStats = async (
    rawRequestModel: SearchShipmentRequestModel
  ): Promise<ApiResponse<TrackShipmentStats>> => {
    let requestModel = (({ lastIds, ...rest }) => rest)(rawRequestModel);
    this.statsCancelToken && this.statsCancelToken.cancel();
    this.statsCancelToken = axios.CancelToken.source();
    return await api.post(`/invoice-search/appointments/stats`, requestModel, {
      cancelToken: this.statsCancelToken.token
    })
  }

  public searchTeams = async (
    value: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/teams/search?name=${encodeURIComponent(value)}`);
  };

  public getShipmentDetails = async (
    invoiceNumber: number
  ): Promise<ApiResponse<ShipmentDetailsModel>> => {
    return await api.get(`/invoices/${invoiceNumber}`);
  };

  public getDockLog = async (
    invoiceNumber: number
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/invoices/${invoiceNumber}/dock-log`);
  };

  public getStatusHistory = async (
    invoiceNumber: number
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/invoices/${invoiceNumber}/status-history`);
  };

  public checkStatusHistory = async (
    invoiceNumber: number
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/invoices/${invoiceNumber}/status-history-summary`);
  };

  public getShipmentTripHistory = async (
    invoiceNumber: number
  ): Promise<ApiResponse<ShipmentTripHistoryModel>> => {
    return await api.get(`/invoices/${invoiceNumber}/tripHistory`);
  };

  public getTrailerLocation = async (
    invoiceNumber: number
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/invoices/${invoiceNumber}/location`);
  };

  public downloadCSV = async (
    rawRequestModel: SearchShipmentRequestModel
  ): Promise<ApiResponse<any>> => {
    let requestModel = (({ lastIds, ...rest }) => rest)(rawRequestModel);
    return await api.post("/invoice-search/download", requestModel);
  };

  public subscribeProbill = async (
    probill: number,
    timezone: string
  ): Promise<ApiResponse<any>> => {
    return await api.post("/notifications/probill", { timezone, probill });
  };

  public unsubscribeProbill = async (
    probill: number
  ): Promise<ApiResponse<any>> => {
    return await api.delete("/notifications/probill", { data: { probill } });
  };

  public getClaimsDocuments = async (
    invoiceNumber: number
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/invoices/${invoiceNumber}/claim-documents`);
  };

  public addComment = async (
    invoiceNumber: number,
    note: string
  ): Promise<ApiResponse<any>> => {
    return await api.post(`/invoices/${invoiceNumber}/addNote`, { note });
  };
}

export default TrackingService;
