import React from "react";
import ContentContainer from "../../templates/content-container/contentContainer";
import BolList from "./common/list";

const CombinedList: React.FC<{}> = (props) => {
  return (
    <ContentContainer title="Bill of Lading">
      <BolList defaultTypes={["BOL", "RETURN"]} />
    </ContentContainer>
  );
};

export default CombinedList;
