import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import UserService from "../../app/data/user/userService";
import { initialSignupByInviteState } from "./SignupByInviteState";
import { SignupByInviteRequestModel, UserInvitationType } from "../../app/data/user/requestModels";

const userService = UserService.getInstance();

export const signupByInviteSlice = createSlice({
  name: "signupByInvite",
  initialState: initialSignupByInviteState,
  reducers: {
    resetSignupByInviteState: (state) => initialSignupByInviteState,
    checkInvite_started: (state) => {
      state.invite = null;
      state.confirmInvite_error = null;
      state.confirmInvite_failed = false;
      state.confirmInvite_succeed = false;
      state.confirmInvite_started = true;
    },
    checkInvite_completed: (state, { payload }) => {
      state.invite = payload;
      state.confirmInvite_failed = false;
      state.confirmInvite_succeed = true;
      state.confirmInvite_started = false;
    },
    checkInvite_failed: (state, { payload }) => {
      state.confirmInvite_error = payload;
      state.confirmInvite_failed = true;
      state.confirmInvite_succeed = false;
      state.confirmInvite_started = false;
    },
    resetSignup: (state) => {
      state.signup_started = false;
      state.signup_failed = false;
      state.signup_succeed = false;
      state.signup_error = null;
    },
    signup_started: (state) => {
      state.signup_started = true;
      state.signup_failed = false;
      state.signup_succeed = false;
      state.signup_error = null;
    },
    signup_completed: (state) => {
      state.signup_started = false;
      state.signup_failed = false;
      state.signup_succeed = true;
      state.signup_error = null;
    },    
    signup_failed: (state, { payload }) => {
      state.signup_started = false;
      state.signup_failed = true;
      state.signup_succeed = false;
      state.signup_error = payload;
    }
  }
});

export const {
  resetSignupByInviteState,
  checkInvite_started,
  checkInvite_completed,
  checkInvite_failed,
  signup_started,
  signup_completed,
  signup_failed
} = signupByInviteSlice.actions;

export const signupByInviteSelector = (state: IState) => state.signupByInvite;

export const checkInvite = (
  token: string,
  onSuccess?: (firstName: string, lastName: string, inviteType: UserInvitationType) => void
): AppThunk => async (dispatch) => {
  dispatch(checkInvite_started());
  const response = await userService.checkInvite(token);
  if (response.ok()) {
    dispatch(checkInvite_completed(response.data));
    onSuccess && onSuccess(response.data.firstName, response.data.lastName, response.data.type);
  } else {
    dispatch(checkInvite_failed(response.getError ? response.getError() : "Error"));
  }
};

export const signup = (
  request: SignupByInviteRequestModel,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(signup_started());
  const response = await userService.signupByInvite(request);
  if (response.ok()) {
    dispatch(signup_completed());
    onSuccess && onSuccess();
  } else {
    response.getError() && dispatch(signup_failed(response.getFullError()));
  }
};

const signupByInviteReducer = signupByInviteSlice.reducer;
export default signupByInviteReducer;
