import * as Yup from "yup";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import moment from "moment";
import { validationMessages } from "../common/validationMessages";
import { Contact } from "../contact/models";
import dateFormats from "../common/dateFormats";

export interface AppointmentRequest {
  probillNumber: string,
  date: string;
  earlyTime: string;
  lateTime: string;
  reason?: string;
  contactId?: string;
  notes: string;
}

export interface AppointmentDto {
  id: string;
  probillNumber: string,
  date: string;
  earlyTime: string;
  lateTime: string;
  reason: string;
  contact?: Contact;
  confirmedDateTime: string;
  confirmedUser: string;
  notes: string;
}

export const getAppointmentRequestModel = (eta: string) => {
  return Yup.object({
    probillNumber: Yup.string(),
    date: Yup.string().nullable().required(validationMessages.required),
    earlyTime: Yup.string().required(validationMessages.required),
    lateTime: Yup.string()
      .required(validationMessages.required)
      .test(
        "is-late-time-valid", 
        "Late time can't be less than or the same as early time.", 
        function (value) {
          const { lateTime, earlyTime } = this.parent;
          if (moment(earlyTime, "H:mm a").isSameOrAfter(moment(lateTime, "H:mm a"))) {
            return false;
          }
          return true;
        }
      ),
    reason: Yup.string().nullable().test(
      "is-reason-required",
      "Reason is required because appointment date is after ETA.",
      function (value) {
        const { date } = this.parent;
        if (moment(date, dateFormats.uiDate).isAfter(moment(eta, dateFormats.apiDate))) {
          return !!value;
        }
        return true;
      }
    ),
    notes: Yup.string(),
    contactId: Yup.string()
  })

}

export const appointmentLateReasons = [
  { label: "Held at Origin", value: "HELD_AT_ORIGIN" },
  { label: "Customer Closed For Holiday", value: "CUSTOMER_HOLIDAY_CLOSED" },
  { label: "Customer Delayed Appointment", value: "CUSTOMER_DELAYED_APPOINTMENT" },
  { label: "Customer Postponed Appointment", value: "CUSTOMER_POSTPONED_APPOINTMENT" },
  { label: "Customer Requested Early Delivery", value: "CUSTOMER_REQUESTED_EARLY_DELIVERY" },
  { label: "Customer Requested Redelivery", value: "CUSTOMER_REQUESTED_REDELIVERY" },
  { label: "Customer Closed For Holiday", value: "CUSTOMER_HOLIDAY_SHUTDOWN" },
  { label: "Delayed From Previous Stop", value: "PREVIOUS_STOP_DELAY" },
  { label: "Destination Missed Delivery", value: "DESTINATION_MISSED_DELIVERY" },
  { label: "Destination S/C Damaged", value: "DESTINATION_DAMAGED" },
  { label: "Destination S/C Missed Appointment", value: "DESTINATION_MISSED_APPOINTMENT" },
  { label: "Destination S/C Shortage", value: "DESTINATION_SHORTAGE" },
  { label: "Destination Weather Delay", value: "DESTINATION_WEATHER_DELAY" },
  { label: "Linehaul Delay", value: "LINEHAUL_DELAY" },
  { label: "Linehaul Equipment Delay", value: "LINEHAUL_EQUIPMENT_DELAY" },
  { label: "Linehaul Shortage", value: "LINEHAUL_SHORTAGE" },
  { label: "Linehaul Weather Delay", value: "LINEHAUL_WEATHER_DELAY" },
  { label: "Origin Delay - Misload", value: "ORIGIN_MISLOAD_DELAY" },
  { label: "Origin Delayed Departure", value: "ORIGIN_DELAYED_DEPARTURE" },
  { label: "Origin Delay-Out Of Route", value: "ORIGIN_OUT_OF_ROUTE_DELAY" },
  { label: "State Of Emergency", value: "STATE_OF_EMERGENCY" }
];

export const getEarlyTimeOptions = () => {
  let options: XGSSelectOption[] = [];
  let startTime = moment().startOf("day").add(6, "hours");
  while(startTime.isSameOrBefore(moment().startOf("day").add(18, "h"))) {
    let time = startTime.format("h:mm a");
    options.push({
      label: time,
      value: time
    });
    startTime = startTime.add(30, "minutes"); 
  }
  return options;
};

export const getLateTimeOptions = (earlyTime: string) => {
  let options: XGSSelectOption[] = [];
  let startTime = earlyTime ? moment(earlyTime, "h:mm a").add(30, "m") : moment().startOf("D").add(6, "h");
  while(startTime.isSameOrBefore(moment().startOf("day").add(19, "h"))) {
    let time = startTime.format("h:mm a");
    options.push({
      label: time,
      value: time
    });
    startTime = startTime.add(30, "minutes"); 
  }
  return options;
}


