import React from "react";

import "./pageLoading.scss";

export interface PageLoadingProps {
  isLoading: boolean;
}

const PageLoading: React.FC<PageLoadingProps> = (props) => {
  return (
    <>
      {props.isLoading && (
        <div className="xgs-page-loading">
          <div className="xgs-page-loading__loading">Loading ...</div>
        </div>
      )}
    </>
  );
};

export default PageLoading;
