import { urlParams } from "../../../services/common/urlParams";
import api, { ApiResponse } from "../api";
import axios from "axios";
import { 
  CreateTeamRequestModel,
  SearchCustomersModel,
  UpdateTeamModel
} from "./models";
import { SubAccountMinimal } from "../account/SubAccount";

const REQUEST_ID = {
  GET_SHIPPERS_GLOBAL_SEARCH: 'get_shippers_global_search',
  GET_ACCOUNTS: 'get_accounts',
  GET_TEAM_USERS: 'get_team_users',
};

class CompanyService {
  private source: any;
  private static instance: CompanyService;
  private constructor() {
    this.source = {};
  }

  static getInstance(): CompanyService {
    if (!CompanyService.instance) {
      CompanyService.instance = new CompanyService();
    }
    return CompanyService.instance;
  }

  public getCustomers = async (
    lastIds: string,
    searchString: string,
    fullSearch: boolean,
    sorting?: any
  ): Promise<ApiResponse<any>> => {
    const params = new URLSearchParams();
    lastIds && params.append("lastIds", lastIds);
    searchString && params.append("search", searchString);
    sorting?.sortBy && params.append("sortBy", sorting.sortBy);
    sorting?.order && params.append("order", sorting.order);
    fullSearch && params.append("fullSearch", "true");
    return await api.get("/teams/customers", { params });
  };

  public getAgents = async (
    lastIds: string,
    searchString: string,
    fullSearch: boolean,
    sorting?: any
  ): Promise<ApiResponse<any>> => {
    const params = new URLSearchParams();
    lastIds && params.append("lastIds", lastIds);
    searchString && params.append("search", searchString);
    sorting?.sortBy && params.append("sortBy", sorting.sortBy);
    sorting?.order && params.append("order", sorting.order);
    fullSearch && params.append("fullSearch", "true");
    return await api.get("/teams/agents", { params });
  };

  public getAccounts = async (
    teamId: string,
    searchString: string | null,
    lastIds?: string
  ): Promise<ApiResponse<any>> => {
    if (!lastIds) {
      this.source[REQUEST_ID.GET_ACCOUNTS]?.cancel();
    }    
    this.source[REQUEST_ID.GET_ACCOUNTS] = axios.CancelToken.source(); 

    const params = new URLSearchParams();
    lastIds && params.append("lastIds", lastIds);
    searchString && params.append("search", searchString);
    return await api.get(`/teams/${teamId}/accounts`, { params, cancelToken: this.source[REQUEST_ID.GET_ACCOUNTS].token });
  };

  public getAllAccounts = async (
    teamId: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/teams/${teamId}/accounts?all=true`);
  };

  public getAccountsInfo = async (
    accountNumbers: number[]
  ): Promise<ApiResponse<any>> => {
    return await api.post("/accounts/legacy", accountNumbers);
  };

  public getExtraShippers = async (
    teamId: string,
    page?: number
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/teams/${teamId}/shippers/${page ? `?page=${page}` : ""}`);
  };

  public getAllExtraShippers = async (
    teamId: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/teams/${teamId}/shippers?all=true`);
  };  

  public getShippersGlobalSearch = async (
    query: string,
    paypointNumber: number,
  ): Promise<SubAccountMinimal[]> => {
    if (query.length < 2) return [];
    
    this.source[REQUEST_ID.GET_SHIPPERS_GLOBAL_SEARCH]?.cancel();
    this.source[REQUEST_ID.GET_SHIPPERS_GLOBAL_SEARCH] = axios.CancelToken.source(); 

    const params = new URLSearchParams();    
    params.append("query", query);
    params.append("paypointNumber", paypointNumber.toString());    

    const response: ApiResponse<any> = await api.get("/teams/shippers/search", {
      params,
      cancelToken: this.source[REQUEST_ID.GET_SHIPPERS_GLOBAL_SEARCH].token
    });

    if (response.ok() && response.data?.length) {
      return response.data;
    } else {
       return [];
    } 
  };

  public addShippers = async (
    teamId: string,
    accounts: number[]
  ): Promise<ApiResponse<any>> => {
    return await api.post(`/teams/${teamId}/shippers`, accounts);
  };

  public removeShipperFromTeam = async (
    teamId: string,
    accountId: string
  ): Promise<ApiResponse<any>> => {
    return await api.delete(`/teams/${teamId}/shippers/${accountId}`);
  };

  public createTeam = async (
    request: CreateTeamRequestModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/teams", request);
  };

  public updateTeam = async (
    request: UpdateTeamModel
  ): Promise<ApiResponse<any>> => {
    return await api.put("/teams", request);
  };

  public removeAccountFromTeam = async (
    teamId: string,
    accountId: string
  ): Promise<ApiResponse<any>> => {
    return await api.delete(`/teams/${teamId}/accounts/${accountId}`);
  };

  public getTeamUsers = async (
    teamId: string,
    all: boolean,
    searchString: string | undefined,
    lastIds?: string
  ): Promise<ApiResponse<any>> => {
    if (!lastIds) {
      this.source[REQUEST_ID.GET_TEAM_USERS]?.cancel();
    }    
    this.source[REQUEST_ID.GET_TEAM_USERS] = axios.CancelToken.source(); 

    const params = new URLSearchParams();
    all && params.append("all", "true");
    lastIds && params.append("lastIds", lastIds);
    searchString && params.append("search", searchString);
    return await api.get(`/teams/${teamId}/users/`, { params, cancelToken: this.source[REQUEST_ID.GET_TEAM_USERS].token });
  };

  public getCurrentTeamUsers = async (
    all: boolean,
    searchString: string | undefined,
    lastIds?: string
  ): Promise<ApiResponse<any>> => {
    const params = new URLSearchParams();
    all && params.append("all", "true");
    lastIds && params.append("lastIds", lastIds);
    searchString && params.append("search", searchString);
    return await api.get("/accounts/users", { params });
  };

  public getAllUsers = async (
    lastIds?: string,
    search?: SearchCustomersModel | undefined,
  ): Promise<ApiResponse<any>> => {    
    return await api.get("/customers", { params: urlParams({ lastIds, ...search }) });
  };

  public updateUser = async (
    userId: string,
    request: any
  ): Promise<ApiResponse<any>> => {
    return await api.put(`/accounts/users/${userId}`, request);
  };

  public deleteUser = async (
    userId: string
  ): Promise<ApiResponse<any>> => {
    return await api.delete(`/users/${userId}`);
  };

  public getUserPermissions = async (
    userId: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/customers/permissions/${userId}`);
  };

  public searchTeamAccounts = async (
    teamId: string,
    query: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/teams/${teamId}/accounts/search?search=${encodeURIComponent(query)}`);
  };

  public searchCurrentTeamAccounts = async (
    query: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/teams/accounts/search?search=${encodeURIComponent(query)}`);
  };

  public searchAccounts = async (
    name: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/accounts/search?name=${encodeURIComponent(name)}`);
  };

  public getUsersCSV = async (
    search?: SearchCustomersModel | undefined,
  ): Promise<ApiResponse<any>> => {    
    return await api.get("/users/csv", { params: urlParams(search) });
  };
}

export default CompanyService;
