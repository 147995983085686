import React from "react";

export interface CombinedCellProps {
  values: any[];
}

const CombinedCell: React.FC<CombinedCellProps> = (props) => {
  return (
    <div className="xgs-table-combined-cell" style={{display: "flex", flexDirection: "column"}}>
      {props.values?.map((value, i) => (
        <span  key={`xgs-table-combined-cell-${i}`}>{value}</span>
      ))}
    </div>
  );
};

export default CombinedCell;
