export default interface GeneralSettingsState {
  requestStarted: boolean;
  requestSucceed: boolean;
  requestCreator: string | null;
  requestFailed: boolean;
  requestError?: string | null;
  shipmentSearchSettings: {
    filterValue?: string
  };
}

export const initialGeneralSettingsState: GeneralSettingsState = {
  requestStarted: false,
  requestSucceed: false,
  requestCreator: null,
  requestFailed: false,
  requestError: null,
  shipmentSearchSettings: {},
};
