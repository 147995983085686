import moment from "moment";
import { currentOrClosestBusinessDay } from "../../hooks/utils";
import { weekDays } from "../../ui-components/xgs-date/xgs-date/xgsDate";

// commonState.pickupDays
export const firstServiceCenterWorkDay = (pickupDays) => {
  if (!pickupDays || Object.keys(pickupDays).length === 0) return currentOrClosestBusinessDay();
  let day = moment(); // default value to avoid TS error (possible undefined)
  for (let i = 0; i < 8; i++) {  // set i = 1 for the next day
    day = moment().add(i, "day");
    let dayOfWeek = day.format("dd").toLowerCase();
    if (pickupDays[dayOfWeek as keyof weekDays] === true) break;
  }
  return day.format("MM/DD/YYYY");
};
