import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import moment from "moment";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import Loading from "../../../../ui-components/loading/loading";
import XGSIcons from "../../../../ui-components/icon/xgsIcons";
import XGSIcon from "../../../../ui-components/icon/xgsIcon";
import XGSFormInput from "../../../../ui-components/form/input/xgsFormInput";
import XGSFormTextarea from "../../../../ui-components/form/textarea/xgsFormTextarea";
import { XGSSelectOption } from "../../../../ui-components/xgs-select/xgsSelect";
import { LabelModes } from "../../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledSelectInput from "../../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import XGSFormDate from "../../../../ui-components/form/date/xgsFormDate";
import XGSFormCheckbox from "../../../../ui-components/form/checkbox/xgsFormCheckbox";
import
  Exclamation,
  { ExclamationTypes }
from "../../../../ui-components/molecules/exclamation/exclamation";
import {
  BolShipperModel,
  BolShipperSchema
} from "../../../../app/data/bol/models";
import { StepProps } from "../../../../app/data/common/models";
import UserState from "../../../../slices/user/UserState";
import { userSelector } from "../../../../slices/user/userSlice";
import BolState from "../../../../slices/bol/BolState";
import {
  bolSelector,
  checkPickup,
  clearItems,
  getShippers,
  removeBolFromRequestRatesFlag,
  resetBolState,
  setBolShipper
} from "../../../../slices/bol/bolSlice";
import CommonState from "../../../../slices/common/CommonState";
import {
  commonSelector,
  getFreightClasses,
  getPickupDays
} from "../../../../slices/common/commonSlice";
import { firstServiceCenterWorkDay } from "../../../../services/common/date";
import { PAY_TYPES } from "../../constants";
import Address from "../../../../app/data/account/Address";
import { fromTimeOptions, toTimeOptions } from "../../../../services/common/time";
import exclamationIcon from "../../../../images/exclamation-triangle__gray.svg";
import "../../bol.scss";

let initialValues: BolShipperModel = {
  shipper: null,
  shipperName: "",
  shipperAddress: {
    address1: "",
    city: "",
    postalCode: "",
    state: ""
  },
  bolNumber: "",
  poNumber: "",
  payType: "",
  specialInstructions: "",
  pickupRequest: false,
  pickupDate: "",
  pickupReadyTime: undefined,
  pickupCloseTime: undefined,
  willCall: false
}

const BolShipperStep: React.FC<StepProps> = (props) => {
  const { next } = props;
  const userState: UserState = useSelector(userSelector);
  const bolState: BolState = useSelector(bolSelector);
  const commonState: CommonState = useSelector(commonSelector);
  const dispatch = useDispatch();
  const shipperFormRef = useRef<any>(null);
  const [shipperFormValue, setShipperFormValue] = useState<XGSSelectOption | null>();
  const [payTypeFormValue, setPayTypeFormValue] = useState<XGSSelectOption | null>();
  const [pickupDateFormValue, setPickupDateFormValue] = useState<string | undefined>();
  const [pickupReadyTimeFormValue, setPickupReadyTimeFormValue] = useState<XGSSelectOption | null>();
  const [pickupCloseTimeFormValue, setPickupCloseTimeFormValue] = useState<XGSSelectOption | null>();
  const [pickupRequestFormValue, setPickupRequestFormValue] = useState<boolean>(false);
  const [showPickupZipNotice, setShowPickupZipNotice] = useState<boolean>(false);

   const formatShipperAddress = (address: Address | undefined) => {
    if (!address) return "";

    const elements = [address.line1, address.city, address.state].filter(el => (el && el !== "-"));

    return elements.length ? `${elements.join(", ")} ${address.zip}` : "";
  };

  const shipperOptions = useMemo(() => {
    let options: XGSSelectOption[] = [];
    if (userState.profile) {
      options = userState.profile.subAccounts
        .filter(account => account.shipper)
        .filter(account => bolState.fromRequestRates ? (bolState.requestRatesPickupZip === account.address.zip) : true)
        .map((account): XGSSelectOption =>
          ({ 
            label: account.name,
            subtitle: formatShipperAddress(account.address),
            value: `${account.accountNumber}`,
            valueForSearch: `${account.name}|${account.address.line1}|${account.address.city}|${account.address.state}|${account.address.zip}`,
          })
        );
    }
    if (bolState.shippers && bolState.shippers.length > 0) {
      for (let i = 0; i < bolState.shippers.length; i++) {
        if (bolState.fromRequestRates && (bolState.requestRatesPickupZip !== bolState.shippers[i].address?.zip)) continue;
        options.push({
          label: bolState.shippers[i].name || "",
          subtitle: formatShipperAddress(bolState.shippers[i].address),
          value: `${bolState.shippers[i].accountNumber}`,
          valueForSearch: `${bolState.shippers[i].name}|${bolState.shippers[i].address?.line1}|${bolState.shippers[i].address?.city}|${bolState.shippers[i].address?.state}|${bolState.shippers[i].address?.zip}`,
        });
      }
    }
    if (options.length > 0) {
      options = uniqBy(options, "value");
      options = sortBy(options, ["label"]);
    }
    return options;
  }, [userState.profile, bolState.shippers, bolState.requestRatesPickupZip, bolState.fromRequestRates]);

  const onChangeShipper = (shipperOption: XGSSelectOption | null | undefined) => {
    if (!shipperOption) return;
    setShipperFormValue(shipperOption);
    shipperFormRef.current?.setFieldValue("shipper", shipperOption.value);
    checkPickupAvailability(Number(shipperOption.value));
    if (shipperFormValue?.value !== shipperOption.value && !bolState.fromRequestRates) {
      dispatch(clearItems());
    }
    let shipper;
    if (shipperOption) {
      const accountNumber = Number(shipperOption.value);
      dispatch(getPickupDays(getZipFromAccount(accountNumber)));
      shipper = bolState.shippers ? bolState.shippers.find(obj => obj.accountNumber === accountNumber) : null;
      if (!shipper) {
        shipper = userState.profile.subAccounts.filter(obj => obj.shipper).find(obj => obj.accountNumber === accountNumber);
      }
    }
    shipperFormRef.current?.setFieldValue("shipperName", shipper?.name || undefined);
    shipperFormRef.current?.setFieldValue("shipperAddress.address1",  shipper?.address?.line1 || undefined);
    shipperFormRef.current?.setFieldValue("shipperAddress.city",  shipper?.address?.city || undefined);
    shipperFormRef.current?.setFieldValue("shipperAddress.postalCode",  shipper?.address?.zip || undefined);
    shipperFormRef.current?.setFieldValue("shipperAddress.state",  shipper?.address?.state || undefined);
    shipperFormRef.current?.setFieldValue("pickupDate", "");
    setPickupDateFormValue(undefined);
  };

  const onClickNext = (data: BolShipperModel) => {
    if (userState.profile) {
      let shipperZip: any = userState.profile.subAccounts.filter(account => account.shipper).find(account => account.accountNumber === Number(data.shipper));
      if (!shipperZip && bolState.shippers) {
        shipperZip = bolState.shippers.find(account => account.accountNumber === Number(data.shipper));
      }
      dispatch(getFreightClasses(shipperZip.address?.zip));
    }
    dispatch(setBolShipper(data));
    next && next();
  };

  const getZipFromAccount = (accountNumber: number) => {
    let zip: string | undefined = "";
    if (userState.profile) {
      let account = userState.profile.subAccounts.find(obj => obj.accountNumber === accountNumber);
      zip = account ? account.address.zip : "";
    }
    if (!zip && bolState.shippers && bolState.shippers.length > 0) {
      let account = bolState.shippers.find(obj => obj.accountNumber === accountNumber);
      zip = account ? account.address?.zip : "";
    }
    return zip || "";
  };

  const checkPickupAvailability = (accountNumber: number) => {
    let zip = getZipFromAccount(accountNumber);
    zip && dispatch(checkPickup(zip, () => {
      shipperFormRef.current?.setFieldValue("pickupRequest", false);
      setPickupRequestFormValue(false);
    }));
  };

  const clearBol = () => {
    if (bolState.fromRequestRates) dispatch(removeBolFromRequestRatesFlag());
    dispatch(resetBolState());
    dispatch(getShippers());
    setShowPickupZipNotice(false);
  };

  useEffect(() => {
    initialValues.shipper = bolState.shipper;
    initialValues.shipperName = bolState.shipperName;
    initialValues.shipperAddress.address1 = bolState.shipperAddress.address1;
    initialValues.shipperAddress.city = bolState.shipperAddress.city;
    initialValues.shipperAddress.postalCode = bolState.shipperAddress.postalCode;
    initialValues.shipperAddress.state = bolState.shipperAddress.state;
    initialValues.bolNumber = bolState.bolNumber;
    initialValues.poNumber = bolState.poNumber;
    initialValues.payType = bolState.payType;
    initialValues.specialInstructions = bolState.specialInstructions;
    shipperFormRef.current?.setFieldValue("specialInstructions", bolState.specialInstructions);
    initialValues.pickupRequest = bolState.pickupRequest;
    initialValues.pickupDate = bolState.pickupDate;
    initialValues.pickupReadyTime = bolState.pickupReadyTime;
    initialValues.pickupCloseTime = bolState.pickupCloseTime;
    initialValues.willCall = bolState.willCall;
    if (bolState.shipper) {
      shipperFormRef.current?.setFieldValue("shipper", bolState.shipper);
      let shipper = shipperOptions.find(obj => obj.value === `${bolState.shipper}`);
      shipper && setShipperFormValue(shipper);
    }
    if (bolState.payType) {
      shipperFormRef.current?.setFieldValue("payType", bolState.payType);
      let payType = PAY_TYPES.find(type => type.value === bolState.payType);
      payType && setPayTypeFormValue(payType);
    }
    if (bolState.pickupRequest) {
      shipperFormRef.current?.setFieldValue("pickupRequest", true);
      setPickupRequestFormValue(true);
      if (bolState.pickupDate) {
        shipperFormRef.current?.setFieldValue("pickupDate", bolState.pickupDate);
        setPickupDateFormValue(bolState.pickupDate);
      }
      if (bolState.pickupReadyTime) {
        setPickupReadyTimeFormValue({
          value: bolState.pickupReadyTime,
          label: bolState.pickupReadyTime
        });
      }
      if (bolState.pickupCloseTime) {
        setPickupCloseTimeFormValue({
          value: bolState.pickupCloseTime,
          label: bolState.pickupCloseTime
        });
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (bolState.shippers) return;
    dispatch(getShippers());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // preselect shipper if it is alone in dropdown
    if (bolState.shippers === null || bolState.shipper) return;
    if (shipperOptions.length === 1) {
      shipperFormRef.current?.setFieldValue("shipper", shipperOptions[0].value);
      setShipperFormValue(shipperOptions[0]);
      checkPickupAvailability(Number(shipperOptions[0].value));
      let shipper;
      if (shipperOptions[0].value) {
        const accountNumber = Number(shipperOptions[0].value);
        dispatch(getPickupDays(getZipFromAccount(accountNumber)));
        shipper = bolState.shippers ? bolState.shippers.find(obj => obj.accountNumber === accountNumber) : null;
        if (!shipper) {
          shipper = userState.profile.subAccounts.filter(obj => obj.shipper).find(obj => obj.accountNumber === accountNumber);
        }
      }
      shipperFormRef.current?.setFieldValue("shipperName", shipper?.name || undefined);
      shipperFormRef.current?.setFieldValue("shipperAddress.address1",  shipper?.address?.line1 || undefined);
      shipperFormRef.current?.setFieldValue("shipperAddress.city",  shipper?.address?.city || undefined);
      shipperFormRef.current?.setFieldValue("shipperAddress.postalCode",  shipper?.address?.zip || undefined);
      shipperFormRef.current?.setFieldValue("shipperAddress.state",  shipper?.address?.state || undefined);
    }
    // eslint-disable-next-line
  }, [bolState.shippers, bolState.shipper, shipperOptions]);

  useEffect(() => {
    // show warning if there are no available shippers ("from Request Rates" flow)
    if (bolState.shippers === null || bolState.shipper || !bolState.fromRequestRates) return;
    setShowPickupZipNotice(shipperOptions.length === 0);
  }, [bolState.shippers, bolState.shipper, shipperOptions, bolState.fromRequestRates]);

  useEffect(() => {
    if (!pickupCloseTimeFormValue) return;
    const options = toTimeOptions(pickupReadyTimeFormValue?.value);
    const toIndex = options.findIndex((obj: XGSSelectOption) => obj.value === pickupCloseTimeFormValue?.value);
    if (toIndex === -1) {
      // if current "To" time is out of allowed time options
      setPickupCloseTimeFormValue(options[0]);
      shipperFormRef.current?.setFieldValue("pickupCloseTime", options[0].value);
    }
  }, [pickupReadyTimeFormValue, pickupCloseTimeFormValue]);

  return (
    <div className="xgs-bol__shipper">
      {!showPickupZipNotice && (
        <Formik
          onSubmit={onClickNext}
          initialValues={initialValues}
          validationSchema={BolShipperSchema}
          innerRef={shipperFormRef}
          enableReinitialize
        >
          {(props: FormikProps<BolShipperModel>) => (
            <Form>
              <div className="xgs-bol__section">Shipper Information</div>
              <LabeledSelectInput
                name="shipper"
                label="Shipper:"
                async
                labelMode={LabelModes.column}
                value={shipperFormValue}
                onValueChange={onChangeShipper}
                options={shipperOptions}
                required={true}
                requiredAsteriskDisabled={false}
                formik={true}
                className="xgs-bol__field"
                isLoading={bolState.requestStarted && bolState.requestCreator === "shippers"}
              />
              {shipperFormValue && !bolState.pickupAvailable && !(bolState.requestStarted && bolState.requestCreator === "CHECK_PICKUP") && (
                <div className="xgs-bol__no-pickup">
                  <XGSIcon
                    icon={XGSIcons.faExclamationTriangle}
                    className="xgs-bol__no-pickup__icon"
                  />
                  <div className="xgs-bol__no-pickup__text">
                    Note: the pickup request option is not available for the current shipper.
                  </div>
                </div>
              )}
              <XGSFormInput
                type="text"
                name="bolNumber"
                label="BOL #:"
                required={true}
                requiredAsteriskDisabled={false}
                labelMode={LabelModes.column}
                className="xgs-bol__field xgs-bol__field--short"
              />
              <XGSFormInput
                type="text"
                name="poNumber"
                label="PO #:"
                required={false}
                requiredAsteriskDisabled={true}
                labelMode={LabelModes.column}
                className="xgs-bol__field xgs-bol__field--short"
              />
              <LabeledSelectInput
                name="payType"
                label="Pay Type:"
                isSearchable={false}
                labelMode={LabelModes.column}
                value={payTypeFormValue}
                onValueChange={(v) => {
                  props.setFieldValue("payType", v?.value);
                  setPayTypeFormValue(v);
                }}
                options={PAY_TYPES}
                required={true}
                requiredAsteriskDisabled={false}
                formik={true}
                className="xgs-bol__field xgs-bol__field--short"
              />
              <div className="xgs-bol__field">
                <XGSFormTextarea
                  name="specialInstructions"
                  value={props.values.specialInstructions}
                  label="Special Instructions:"
                  required={false}
                  rows={4}
                  counter={150}
                />
              </div>
              {bolState.requestStarted && bolState.requestCreator === "CHECK_PICKUP" && (
                <div className="xgs-bol__pickup-request xgs-bol__field xgs-bol__pickup-request--loading">
                  <Loading isLoading={true} />&nbsp;&nbsp;checking pickup availability...
                </div>
              )}
              {bolState.pickupAvailable && (
                <>
                  <div className="xgs-bol__pickup-request xgs-pickup__row">
                    <XGSFormCheckbox
                      mix="xgs-bol__checkbox"
                      mods={{ reverse: true }}
                      name="pickupRequest"
                      onChange={() => {
                        shipperFormRef.current?.setFieldValue("pickupRequest", !pickupRequestFormValue);
                        setPickupRequestFormValue(!pickupRequestFormValue);
                        shipperFormRef.current?.setFieldValue("pickupDate", "");
                        setPickupDateFormValue(undefined);
                      }}
                      checked={pickupRequestFormValue}                      
                    >
                      Pickup Request?
                    </XGSFormCheckbox>
                  </div>
                  {pickupRequestFormValue && (
                    <>
                      <div className="xgs-pickup__row">
                        <div className="xgs-pickup__date">
                          <XGSFormDate
                            name="pickupDate"
                            label="Pickup Date:"
                            placeholder="Select date..."
                            onDateChange={(v) => {
                              props.setFieldValue("pickupDate", v || "");
                              setPickupDateFormValue(v);
                            }}
                            value={pickupDateFormValue}
                            onChange={() => null}
                            minDate={new Date(firstServiceCenterWorkDay(commonState.pickupDays))}
                            required={true}
                            labelMode={LabelModes.column}
                            disableWeekends={true}
                            disabledWeekDays={commonState.pickupDays}
                            disabled={!shipperFormValue || (commonState.requestStarted && (commonState.requestCreator === "GET_SERVICE_CENTER_DAYS"))}
                          />
                        </div>
                        <div className="xgs-pickup__time xgs-bol__return__availability">
                          <div className="xgs-bol__return__availability__label">Hours of Availability: <span>*</span></div>
                          <div className="xgs-bol__return__availability__controls">
                            <LabeledSelectInput
                              name="pickupReadyTime"
                              value={pickupReadyTimeFormValue}
                              label=""
                              isSearchable={false}
                              labelMode={LabelModes.column}
                              onValueChange={(v) => {
                                props.setFieldValue("pickupReadyTime", v?.value);
                                setPickupReadyTimeFormValue(v);
                              }}
                              options={fromTimeOptions()}
                              required={true}
                              requiredAsteriskDisabled={true}
                              formik={true}
                              placeholder="Ready..."
                              menuPlacement="top"
                            />
                            <div className="xgs-bol__return__availability__controls__delimiter">-</div>
                            <LabeledSelectInput
                              name="pickupCloseTime"
                              value={pickupCloseTimeFormValue}
                              label=""
                              isSearchable={false}
                              labelMode={LabelModes.column}
                              onValueChange={(v) => {
                                props.setFieldValue("pickupCloseTime", v?.value);
                                setPickupCloseTimeFormValue(v);
                              }}
                              options={toTimeOptions(pickupReadyTimeFormValue?.value)}
                              required={true}
                              requiredAsteriskDisabled={true}
                              formik={true}
                              placeholder="Close..."
                              menuPlacement="top"
                            />
                          </div>
                        </div>
                      </div>
                      {pickupDateFormValue && moment(pickupDateFormValue).isSame(moment(), "day") && (
                        <div className="xgs-notification-block">
                          <img src={exclamationIcon} alt="Exclamation" />
                          <div className="xgs-notification-block__text">
                            Same-day pickup requests are not guaranteed and will be made on a best-effort basis.
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
              <XGSFormCheckbox 
              mix="xgs-bol__checkbox xgs-bol__field"
              mods={{ reverse: true }}
              name="willCall"
              >
                Will Call?
              </XGSFormCheckbox>
              <div className="xgs-bol__buttons">
                <Button
                  theme={ButtonThemes.blue}
                  disabled={!props.isValid || !props.values.bolNumber}
                  className="xgs-bol__nav-button">
                  Next
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {showPickupZipNotice && (
        <Exclamation type={ExclamationTypes.error}>
          You are trying to create a BOL based on an existing rate quote. Unfortunately, the pickup location from the rate quote doesn't match any existing shipper’s address.<br /><br />
          Contact your administrator if you need to add a new shipper. You can also <span className="blue-link" onClick={clearBol}>create BOL from scratch</span>.
        </Exclamation>
      )}
    </div>
  );
};

export default BolShipperStep;
