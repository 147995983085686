export enum StopStatus {
  COMPLETED = "COMPLETED",
  ACTIVE = "ACTIVE",
  UPCOMING = "UPCOMING"
};

export enum PickupStatus {
  ASSIGNED = "ASSIGNED",
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  MISSED = "MISSED",
  UNASSIGNED = "UNASSIGNED"
};

export enum StopType {
  STOP = "STOP",
  PICKUP = "PICKUP",
  SERVICE_CENTER = "SC"
};

export const StopColors = {
  [StopStatus.COMPLETED]: "#14870A",
  [StopStatus.ACTIVE]: "#2F80ED",
  [StopStatus.UPCOMING]: "#E0E0E0"
};

export const PickupColors = {
  [PickupStatus.COMPLETED]: "#14870A",
  [PickupStatus.ASSIGNED]: "#2F80ED",
  [PickupStatus.UNASSIGNED]: "#E82A2B"
};

export const LocationColors = {
  [StopType.SERVICE_CENTER]: "#828282"
};
