import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Form, Formik, FormikProps } from "formik";
import { toast } from "react-toastify";
import { userSelector, getCurrentUser } from "../../slices/user/userSlice";
import UserState from "../../slices/user/UserState";
import ProfileState from "../../slices/user/profile/ProfileState";
import {
  cancelEmailChanging,
  profileSelector,
  resetErrors,
  updateProfile,
  updatePassword
} from "../../slices/user/profile/profileSlice";
import XGSFormInput from "../../ui-components/form/input/xgsFormInput";
import XGSFormPhoneInput from "../../ui-components/form/phoneInput/xgsFormPhoneInput";
import { LabelModes } from "../../ui-components/molecules/labeled-inputs/labeledInput";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import ContentContainer from "../../templates/content-container/contentContainer";
import {
  ProfileSettingsRequestModel,
  ProfileSettingsRequestSchema,
  ProfilePasswordRequestModel,
  ProfilePasswordRequestSchema
} from "../../app/data/user/requestModels";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import Notifications from "./notifications/notifications";
import Subscriptions from "./subscriptions/subscriptions"
import GeneralSettings from "./general-settings/generalSettings";
import { UserUtils } from "../../app/data/user/userUtils";
import { Routes } from "../../app/route/RoutesConfig";
import { PathParams, SettingsPath } from "./route";
import "../../ui-components/tabs/tabs.scss";
import "./settings.scss";

const Settings: React.FC<{}> = (props) => {
  const userState: UserState = useSelector(userSelector);
  const profileState: ProfileState = useSelector(profileSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const [phoneValue, setPhoneValue] = useState<string>("");
  const settingsFormRef = useRef<any>(null);
  const passwordFormRef = useRef<any>(null);
  const params = useParams() as PathParams;

  const tabRoutes: string[] = UserUtils.isCustomer(userState.profile) ?
    [
      "",
      "/general",
      "/notifications",
      "/subscriptions"
    ] :
    [
      ""
    ];

  const settingsInitialValues: ProfileSettingsRequestModel = {
    firstName: userState.profile?.firstName || "",
    lastName: userState.profile?.lastName || "",
    title: userState.profile?.title || "",
    email: userState.profile?.email || "",
    phoneNumber: userState.profile?.phoneNumber || ""
  };

  const passwordInitialValues: ProfilePasswordRequestModel = {
    currentPassword: "",
    newPassword: "",
    newPasswordRepeat: ""
  };

  const onProfileSubmit = (request: ProfileSettingsRequestModel) => {
    if (!userState.profile?.id) return;
    dispatch(updateProfile(request, userState.profile.id, () => {
      toast.info("The profile was updated!");
      dispatch(getCurrentUser(""));
    }, userState.profile?.impersonated));
  };

  const onPasswordSubmit = (request: ProfilePasswordRequestModel, { resetForm }: any) => {
    dispatch(updatePassword(request, () => {
      toast.info("Password changed!");
      resetForm();
    }));
  };

  const getInitialTab = () => {
    return params[SettingsPath.activeTab] ? tabRoutes.findIndex(item => item === `/${params[SettingsPath.activeTab]}`) : 0
  };

  const changeRoute = (index: number) => {
    history.push(`${Routes.settings}${tabRoutes[index]}`);
  };

  const isProfileEditable = !UserUtils.isSSO(userState.profile);

  useEffect(() => {
    dispatch(resetErrors());
  }, [dispatch]);

  useEffect(() => {
    setPhoneValue(userState.profile?.phoneNumber || "");
  }, [userState.profile]);

  return (
    <ContentContainer title="Settings">
      <div className="xgs-profile">
        <Tabs defaultIndex={getInitialTab()} onSelect={index => changeRoute(index)}>
          <TabList>
            <Tab>Profile</Tab>
            {UserUtils.isCustomer(userState.profile) && (
              <>
                <Tab>General</Tab>
                <Tab>Notifications</Tab>
                <Tab>Subscriptions</Tab>
              </>
            )}
          </TabList>
          <TabPanel>
            <div className="xgs-profile__columns">
              <div className="xgs-profile__form xgs-profile__columns__item">
                {profileState.update_was_failed && (
                  <XGSErrorMessage>{profileState.update_fail_reason}</XGSErrorMessage>
                )}
                <Formik
                  onSubmit={onProfileSubmit}
                  initialValues={settingsInitialValues}
                  validationSchema={ProfileSettingsRequestSchema}
                  innerRef={settingsFormRef}
                >
                  {(props: FormikProps<ProfileSettingsRequestModel>) => (
                    <Form className="form-items">
                      <XGSFormInput
                        type="text"
                        name="firstName"
                        label="First Name:"
                        required={true}
                        requiredAsteriskDisabled={true}
                        labelMode={LabelModes.column}
                        disabled={!isProfileEditable}
                      />
                      <XGSFormInput
                        type="text"
                        name="lastName"
                        label="Last Name:"
                        required={true}
                        requiredAsteriskDisabled={true}
                        labelMode={LabelModes.column}
                        disabled={!isProfileEditable}
                      />
                      <XGSFormInput
                        type="text"
                        name="title"
                        label="Title:"
                        required={true}
                        requiredAsteriskDisabled={true}
                        labelMode={LabelModes.column}
                        disabled={!isProfileEditable}
                      />
                      <XGSFormPhoneInput
                        name="phoneNumber"
                        label="Phone:"
                        labelMode={LabelModes.column}
                        onValueChange={(value) => {
                          props.setFieldValue("phoneNumber", value);
                          setPhoneValue(value);
                        }}
                        onBlur={props.handleBlur}
                        value={phoneValue}
                        required={true}
                        requiredAsteriskDisabled={true}
                        disabled={!isProfileEditable}
                      />
                      <div className="xgs-form__field__notes">
                        <strong>Note:</strong> by providing a telephone number you are consenting to be contacted by SMS text message.
                        Message &amp; data rates may apply. You can reply STOP to opt-out of further messaging.
                      </div>
                      <div className="xgs-profile__form__additional">
                        <XGSFormInput
                          type="text"
                          name="email"
                          label="Email:"
                          required={true}
                          requiredAsteriskDisabled={true}
                          labelMode={LabelModes.column}
                          disabled={!isProfileEditable}
                        />
                        {userState.profile?.pendingEmail && (
                          <div className="xgs-profile__form__additional__info">
                            <div className="xgs-profile__form__additional__info__email-block">You have requested an email address change to <strong>{userState.profile.pendingEmail}</strong>.</div>
                            Please follow the confirmation link in the corresponding email we sent you to activate your new email address.
                            <div className="xgs-profile__form__additional__info__cancel">
                              <span
                                className="blue-link"
                                onClick={() => {
                                  dispatch(cancelEmailChanging(() => {
                                    toast.info("Change of email address has been canceled!");
                                    dispatch(getCurrentUser(""));
                                  }));
                                }}
                              >
                                Cancel email address change
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      {isProfileEditable && (<Button
                        theme={ButtonThemes.blue}
                        style={{
                          marginTop: 38,
                          marginBottom: 42
                        }}
                        disabled={!props.isValid || !props.dirty}
                        spinner={profileState.update_was_started}
                      >
                        {profileState.update_was_started ? "Saving" : "Save Changes"}
                      </Button>)}
                    </Form>
                  )}
                </Formik>
              </div>
              {isProfileEditable && (
                <div className="xgs-profile__form xgs-profile__form--gray xgs-profile__columns__item">
                  <div className="xgs-profile__gray-area">
                    {profileState.password_change_was_failed && (
                      <XGSErrorMessage>{profileState.password_change_fail_reason}</XGSErrorMessage>
                    )}
                    <Formik
                      onSubmit={onPasswordSubmit}
                      initialValues={passwordInitialValues}
                      validationSchema={ProfilePasswordRequestSchema}
                      innerRef={passwordFormRef}
                    >
                      {(props: FormikProps<ProfilePasswordRequestModel>) => (
                        <Form className="form-items">
                          <XGSFormInput
                            type="password"
                            name="currentPassword"
                            label="Current password:"
                            required={true}
                            requiredAsteriskDisabled={true}
                            labelMode={LabelModes.column}
                          />
                          <XGSFormInput
                            type="password"
                            name="newPassword"
                            label="New password:"
                            required={true}
                            requiredAsteriskDisabled={true}
                            labelMode={LabelModes.column}
                          />
                          <XGSFormInput
                            type="password"
                            name="newPasswordRepeat"
                            label="Repeat new password:"
                            required={true}
                            requiredAsteriskDisabled={true}
                            labelMode={LabelModes.column}
                          />
                          <Button
                            theme={ButtonThemes.blue}
                            style={{ marginTop: 38 }}
                            disabled={!props.isValid || !props.touched.currentPassword}
                            spinner={profileState.password_change_was_started}
                            type="submit"
                          >
                            {profileState.password_change_was_started ? "Updating" : "Update password"}
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              )}
            </div>
          </TabPanel>
          {UserUtils.isCustomer(userState.profile) && (
            <>
              <TabPanel>
                <GeneralSettings/>
              </TabPanel>
              <TabPanel>
                <Notifications />
              </TabPanel>
              <TabPanel>
                <Subscriptions />
              </TabPanel>
            </>
          )}
        </Tabs>
      </div>      
    </ContentContainer>
  );
};

export default Settings;
