import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Wizard, Steps, Step } from "react-albus";
import ContentContainer from "../../templates/content-container/contentContainer";
import StepsMilestones, { stepsMilestonesDataType } from "../../ui-components/steps-milestones/stepsMilestones";
import ClaimGeneral from "./steps/claimGeneral";
import ClaimContact from "./steps/claimContact";
import ClaimRemitTo from "./steps/claimRemitTo";
import ClaimItems from "./steps/claimItems";
import ClaimDocuments from "./steps/claimDocuments";
import ClaimsState from "../../slices/claims/ClaimsState";
import {
  claimsSelector,
  resetClaimsState
} from "../../slices/claims/claimsSlice";
import { CLAIM_REASON_TYPES } from "./constants";
import "./claims.scss";

const initialStepsData: stepsMilestonesDataType[] = [
  {
    id: "claims-general-step",
    name: "General Information",
    data: null
  },
  {
    id: "claims-contact-step",
    name: "Contact Information",
    data: null
  },
  {
    id: "claims-remit-to-step",
    name: "Remit-To Information",
    data: null
  },
  {
    id: "claims-items-step",
    name: "Item Information",
    data: null
  },
  {
    id: "claims-documents-step",
    name: "Documents",
    data: null
  }
];

const ClaimCreate: React.FC<{}> = (props) => {
  const claimsState: ClaimsState = useSelector(claimsSelector);
  const dispatch = useDispatch();
  const [stepsData, setStepsData] = useState<stepsMilestonesDataType[]>(initialStepsData);

  useEffect(() => {
    if (!stepsData) return;
    let newStepsData = [...stepsData];
    let index = newStepsData.findIndex(step => step.id === "claims-general-step");
    if (index === -1) return;
    newStepsData[index].data = claimsState.general?.probillNumber
      ? (
          <>
            <div><span>Probill #:</span> {claimsState.general.probillNumber}</div>
            {claimsState.general.bolNumber && (<div><span>BOL #:</span> {claimsState.general.bolNumber}</div>)}
            {claimsState.general.originalClaimNumber && (<div><span>Original Claim #:</span> {claimsState.general.originalClaimNumber}</div>)}
            <div><span>Claim Reason:</span> {CLAIM_REASON_TYPES.find(reason => reason.value === claimsState.general?.reason)?.label}</div>
            {claimsState.general.comments && (<div><span>Comments:</span> {claimsState.general.comments}</div>)}
          </>
        )
      : null;
    setStepsData([...newStepsData]);
    // eslint-disable-next-line
  }, [claimsState.general]);

  useEffect(() => {
    if (!stepsData) return;
    let newStepsData = [...stepsData];
    let index = newStepsData.findIndex(step => step.id === "claims-contact-step");
    if (index === -1) return;
    newStepsData[index].data = claimsState.contact?.company
      ? (
          <>
            <div><span>Company:</span> {claimsState.contact.company}</div>
            <div><span>Contact:</span> {claimsState.contact.contact}</div>
            <div><span>Tax/SS#:</span> {claimsState.contact.taxSSN}</div>
            <div>{claimsState.contact.address.address1}, {claimsState.contact.address.city}, {claimsState.contact.address.state}, {claimsState.contact.address.postalCode}</div>
            <div>{claimsState.contact.email}</div>
            <div>{claimsState.contact.phone}</div>
          </>
        )
      : null;
    setStepsData([...newStepsData]);
    // eslint-disable-next-line
  }, [claimsState.contact]);

  useEffect(() => {
    if (!stepsData) return;
    let newStepsData = [...stepsData];
    let index = newStepsData.findIndex(step => step.id === "claims-remit-to-step");
    if (index === -1) return;
    newStepsData[index].data = claimsState.remitTo?.company
      ? (
          <>
            <div><span>Company:</span> {claimsState.remitTo.company}</div>
            <div>{claimsState.remitTo.address.address1}, {claimsState.remitTo.address.city}, {claimsState.remitTo.address.state}, {claimsState.remitTo.address.postalCode}</div>
          </>
        )
      : null;
    setStepsData([...newStepsData]);
    // eslint-disable-next-line
  }, [claimsState.remitTo]);

  useEffect(() => {
    if (!stepsData) return;
    let newStepsData = [...stepsData];
    let index = newStepsData.findIndex(step => step.id === "claims-items-step");
    if (index === -1) return;
    const itemsCount = claimsState.items.filter(item => !item.deleted).length;
    newStepsData[index].data = (claimsState.freightChargesOnly || claimsState.claimAllItems)
      ? (
          <>
            {claimsState.freightChargesOnly?.value === "FILE_ON_ITEMS" && (
              <>
                {claimsState.claimAllItems?.value === "ALL_ITEMS" && (
                  <>
                    {claimsState.totalClaimAmount && (<div><span>Requested claim amount:</span> ${Number(claimsState.totalClaimAmount).toFixed(2)}</div>)}
                    {claimsState.areFreightChargesIncluded && (<div>Freight charges filed on</div>)}
                  </>
                )}
                {claimsState.claimAllItems?.value === "SPECIFIC_ITEMS" && (
                  <>
                    {itemsCount > 0 && (
                      <div>{itemsCount} item{itemsCount !== 1 ? "s" : ""}</div>
                    )}
                  </>
                )}
              </>
            )}
            {claimsState.freightChargesOnly?.value === "FILE_ON_FREIGHT_CHARGES" && claimsState.freightCharges && (
              <>
                {claimsState.freightCharges.map(freightCharge => (
                  <div>
                    {freightCharge.description} (${freightCharge.price})
                  </div>
                ))}
              </>
            )}
          </>
        )
      : null;
    setStepsData([...newStepsData]);
    // eslint-disable-next-line
  }, [claimsState.freightChargesOnly, claimsState.freightCharges, claimsState.claimAllItems, claimsState.totalClaimAmount, claimsState.items, claimsState.areFreightChargesIncluded]);

  useEffect(() => {
    if (!stepsData) return;
    let newStepsData = [...stepsData];
    let index = newStepsData.findIndex(step => step.id === "claims-documents-step");
    if (index === -1) return;
    const documentsCount = claimsState.documentsData.length;
    newStepsData[index].data = documentsCount > 0
      ? (
          <div>{documentsCount} document{documentsCount !== 1 ? "s" : ""}</div>
        )
      : null;
    setStepsData([...newStepsData]);
    // eslint-disable-next-line
  }, [claimsState.documentsData]);

  useEffect(() => {
    return () => {
      dispatch(resetClaimsState());
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ContentContainer title="Create New Claim">
      <div className="xgs-wizard">
        <Wizard
          render={({ step, push }) => (
            <>
              <Steps>
                <Step
                  id="claims-general-step"
                  render={({ next }) => (
                    <ClaimGeneral next={next} />
                  )}
                />
                <Step
                  id="claims-contact-step"
                  render={({ previous, next }) => (
                    <ClaimContact previous={previous} next={next} />
                  )}
                />
                <Step
                  id="claims-remit-to-step"
                  render={({ previous, next }) => (
                    <ClaimRemitTo previous={previous} next={next} />
                  )}
                />
                <Step
                  id="claims-items-step"
                  render={({ previous, next }) => (
                    <ClaimItems previous={previous} next={next} />
                  )}
                />
                <Step
                  id="claims-documents-step"
                  render={({ previous, next }) => (
                    <ClaimDocuments previous={previous} />
                  )}
                />
              </Steps>
              <StepsMilestones steps={stepsData} step={step} push={push} />
            </>
        )} />
      </div>
    </ContentContainer>
  );
};

export default ClaimCreate;
