import React, { useEffect, useState } from "react";
import LogRocket from "logrocket";
import mixpanel from "mixpanel-browser";
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
  matchPath
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Helmet from "react-helmet";
import Header from "../features/header/header";
import Menu from "../features/menu/menu";
import Announcement from "../features/announcement/announcement";
import { useSelector, useDispatch } from "react-redux";
import {
  userSelector,
  getCurrentUser
} from "../slices/user/userSlice";
import PageLoading from "../features/page-loading/pageLoading";
import Breadcrumbs from "../ui-components/breadcrumbs/breadcrumbs";
import XGSErrorMessage from "../ui-components/error-message/errorMessage";
import DocumentTitle from "../ui-components/document-title/documentTitle";
import UserState from "../slices/user/UserState";
import {
  getRouteConfig,
  Routes,
  publicRoutesComponents
} from "./route/RoutesConfig";
import AccountRouting from "./route/AccountRouting";
import TerminalRouting from "./route/TerminalRouting";
import ProtectedRoute from "./route/ProtectedRoute";
import StateFetcher from "./data/common/StateFetcher";
import initExtenstions from "../extenstions/initExtenstions";
import { UserUtils } from "../app/data/user/userUtils";
import "react-toastify/dist/ReactToastify.css";
import "./app.scss";

let appInited = false;

initExtenstions();

process.env.REACT_APP_MIXPANEL_TOKEN && mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  debug: process.env.REACT_APP_XGS_ENV !== "production"
}); 

// eslint-disable-next-line
if (window.location.pathname !== "/" && process.env.REACT_APP_XGS_ENV === "production") {
  LogRocket.init("isggxn/xgs-customer-portal");
  // eslint-disable-next-line
  window.addEventListener("unhandledrejection", e => {
    LogRocket.captureException(e.reason);
  });
}

declare const window: Window & typeof globalThis & {
  $crisp: any,
  CRISP_RUNTIME_CONFIG: any,
  CRISP_TOKEN_ID: string,
  CRISP_WEBSITE_ID: string
};

const App: React.FC = () => {
  const userState: UserState = useSelector(userSelector);
  const dispatch = useDispatch();
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (!process.env.REACT_APP_CRISP_ID) return;
    window.$crisp = [];
    window.CRISP_RUNTIME_CONFIG = {
      session_merge: true
    };
    window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_ID;
    (() => {
      let d = document;
      let s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = true;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setError(false);
    if ((appInited && userState.loggedIn) ||
      publicRoutesComponents.find(publicRoute => matchPath(window.location.pathname, publicRoute)) ||
      !process.env.REACT_APP_AUTH_ENDPOINT  ||
      !process.env.REACT_APP_SSO_CLIENT_ID) return;
    const originHref = window.location.href;
    const originPathname = window.location.pathname;
    const originSearch = window.location.search;
    appInited = true;
    dispatch(getCurrentUser("", () => {}, (statusCode: number) => {
      if (statusCode === 401) {
        // store origin href only in case it isn't the root
        if (!localStorage.getItem("xgs-origin-href") && ((originPathname && originPathname !== "/") || originSearch)) localStorage.setItem("xgs-origin-href", originHref);
        const redirectUrl = process.env.REACT_APP_DOMAIN + `/auth-callback?originUrl=${process.env.REACT_APP_DOMAIN + "/"}`;
        const authUrl = process.env.REACT_APP_AUTH_ENDPOINT + `?client_id=${process.env.REACT_APP_SSO_CLIENT_ID}&response_type=code&scope=openid&redirect_uri=${redirectUrl}`;
        window.location.replace(authUrl);
      } else {
        setError(true);
      }
    }));
  }, [dispatch, userState.loggedIn]);

  return (
    <div className="xgs-site">
      <Helmet
        defaultTitle="XGS Portal"
        titleTemplate="%s - XGS Portal"
      />
      <Router>
        <Header />
        <div className="xgs-site__wrapper">
          <Switch>
            {publicRoutesComponents.map((route, i) => (
              <Route key={i} exact={route.exact} path={route.path}>
                <div className="xgs-site__content">
                  <route.component />
                </div>
              </Route>
            ))}
            <Route>
              <PageLoading
                isLoading={
                  userState.loginProcess ||
                  userState.logoutProcess ||
                  userState.getCurrentUser_is_Started
                }
              />
              {!userState.loginProcess &&
                !userState.logoutProcess &&
                !userState.getCurrentUser_is_Started &&
                userState.loginFirstAttemptFinished && (
                  <>
                    {userState.loggedIn && <Menu />}
                    <div className={`xgs-site__content${UserUtils.isXGSDriver(userState.profile) ? " xgs-site__content--driver" : ""}`}>
                      {userState.loggedIn && <Breadcrumbs />}
                      <Switch>
                        {getRouteConfig(userState.profile, userState.activeSubAccount?.id).map((route, i) => (
                          <ProtectedRoute key={i} exact={route.exact} path={route.path} feature={route.feature}>
                            <DocumentTitle />
                            {route.includeAccountNumberInUrl && (
                              <AccountRouting>
                                <route.component />
                              </AccountRouting>
                            )}
                            {!route.includeAccountNumberInUrl && (
                              <>
                                {UserUtils.isEmployee(userState.profile) && (
                                  <>
                                    {userState.profile.sso && (
                                      <TerminalRouting>
                                        <StateFetcher>
                                          <route.component />
                                        </StateFetcher>
                                      </TerminalRouting>
                                    )}
                                    {!userState.profile.sso && (
                                      <StateFetcher>
                                        <route.component />
                                      </StateFetcher>
                                    )}
                                  </>
                                )}
                                {UserUtils.isCustomer(userState.profile) && (
                                  <route.component />
                                )}
                              </>
                            )}
                          </ProtectedRoute>
                        ))}
                        <Redirect to={Routes.home} />
                      </Switch>
                      {error && (
                        <XGSErrorMessage className="block-center xgs-space__top__48">
                          {userState.failReason || "Error. Please try again later."}
                        </XGSErrorMessage>
                      )}
                    </div>
                  </>
                )}
            </Route>
          </Switch>
        </div>        
        <ToastContainer />
        {(UserUtils.isCustomer(userState.profile) || UserUtils.isEmployee(userState.profile)) && <Announcement />}
      </Router>
    </div>
  );
};

export default App;
