// import UserProfile from "../../../app/data/user/UserProfile";

export default interface PendingRequestsState {
  fetch_was_started: boolean;
  fetchPortion_was_started: boolean;
  fetch_was_succeed: boolean;
  fetch_was_failed: boolean;
  fetch_fail_reason?: string | null;  
  request_was_started: boolean;
  request_creator?: string;
  request_was_succeed: boolean;
  request_was_failed: boolean;
  request_fail_reason?: string | null;
  requestCreator: string;
  pendingRequests: any; // UserProfile[];
  pendingRequestsScrollRequest: any;
  pendingRequestsFetchedAll: boolean;
  teamAccounts: any[];
  teamAccountIndex: number | undefined;
  accounts: any[];
}

export const initialPendingRequestsState: PendingRequestsState = {
  fetch_was_started: false,
  fetchPortion_was_started: false,
  fetch_was_succeed: false,
  fetch_was_failed: false,
  fetch_fail_reason: null,
  request_was_started: false,
  request_creator: undefined,
  request_was_succeed: false,
  request_was_failed: false,
  request_fail_reason: null,
  requestCreator: "",
  pendingRequests: [],
  pendingRequestsScrollRequest: null,
  pendingRequestsFetchedAll: false,
  teamAccounts: [],
  teamAccountIndex: undefined,
  accounts: []
};
