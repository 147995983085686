import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export interface PodRecognitionResponseModel {
  id: string;
  file: string;
  filename: string;
  dateCreated: string;
  fromAgent: string;
  successRecognition: boolean;
  errorMessage: string;
  probillNumber: number | null;
  blobURI: string;
  isMaster: boolean;
};

export interface PodRecognitionFilterModel {
  probill: number | undefined;
  from: string;
  to: string;
  agent: string;
  success: boolean;
};

export const PodRecognitionFilterSchema: Yup.ObjectSchema<PodRecognitionFilterModel> = Yup.object().shape({
  probill: Yup.number()
    .min(0, "The value must be no less than 0")
    .max(999999999, "The value must be no more than 999999999")
    .integer("The value must be an integer")
    .typeError(validationMessages.notValid),
  from: Yup.string(),
  to: Yup.string(),
  agent: Yup.string().max(60, "The value must be no more than 60 characters"),
  success: Yup.boolean()
}).defined();

export interface RecognitionLogsResponseModel {

};

export interface InvoiceResponseModel {
  invoiceNumber: number;
  invoice: {
    paid: boolean;
    invoiceAmount: number;
    dueDate: string;
    paymentDate: string;
  },
  payor: {
    info: string;
  },
  status: {
    delivered: boolean;
  }
};

export enum InvoicePaymentStatus {
  paid = "paid",
  pending = "pending",
  overdue = "overdue",
}

export enum InvoiceDeliveryStatus {
  delivered = "delivered",
  pending = "pending",
}

export interface InvoicesFilterModel {
  probill?: number;
  customer?: string;
  fromDate?: string;
  toDate?: string;
  deliveryApproach?: string,
  missed?: string | boolean,
  missedPeriod?: string,
};

export const InvoicesFilterSchema: Yup.ObjectSchema<InvoicesFilterModel> = Yup.object().shape({
  probill: Yup.number()
    .min(0, "The value must be no less than 0")
    .max(999999999, "The value must be no more than 999999999")
    .integer("The value must be an integer")
    .typeError(validationMessages.notValid),
  customer: Yup.string(),
  from: Yup.string(),
  to: Yup.string(),
}).defined();