import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { initialFiltersToggleState } from "./FiltersToggleState";

export const filtersToggleSlice = createSlice({
  name: "filtersToggle",
  initialState: initialFiltersToggleState,
  reducers: {
    toggleFilters: (state) => {
      state.showFilters = !state.showFilters;
    }
  }
});

export const {
  toggleFilters
} = filtersToggleSlice.actions;

export const filtersToggleSelector = (state: IState) => state.filtersToggle;

const filtersToggleReducer = filtersToggleSlice.reducer;
export default filtersToggleReducer;
