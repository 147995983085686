import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import debounce from "lodash/debounce";
import LabeledSelectInput from "../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import {
  DestinationLocationModel,
  DestinationLocationSchema
} from "../../../app/data/rates/models";
import { StepProps } from "../../../app/data/common/models";
import RequestRatesState from "../../../slices/rates/RequestRatesState";
import {
  requestRatesSelector,
  searchLocations,
  setDestinationLocation,
  setDirectDestination
} from "../../../slices/rates/requestRatesSlice";

const initialValues: DestinationLocationModel = {
  location: null
};

const DestinationLocation: React.FC<StepProps> = (props) => {
  const dispatch = useDispatch();
  const { next, previous } = props;
  const requestRatesState: RequestRatesState = useSelector(requestRatesSelector);
  const [locationFormValue, setLocationFormValue] = useState<{ value: string, label: string, subtitle?: string } | null>();

  const onClickBack = (data: DestinationLocationModel) => {
    dispatch(setDestinationLocation(data));
    previous && previous();
  };

  const onSubmit = (data: DestinationLocationModel) => {
    dispatch(setDestinationLocation(data));
    next && next();
  };

  let locationSearch = (e: string) => {
    e?.length > 1 && dispatch(searchLocations("DESTINATION", e));
  }
  locationSearch = debounce(locationSearch, 300);

  useEffect(() => {
    initialValues.location = requestRatesState.destinationLocation;
    requestRatesState.destinationLocation && setLocationFormValue({
      label: requestRatesState.destinationLocation.label,
      value: requestRatesState.destinationLocation.value
    });
  }, [requestRatesState.destinationLocation]);

  return (
    <div className="xgs-request-rates__destination-location">
      <div className="xgs-request-rates__step__header">Destination Location</div>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={DestinationLocationSchema}
        enableReinitialize
      >
        {(props: FormikProps<DestinationLocationModel>) => (
          <Form className="form-items">
            <div className="xgs-request-rates__destination-location__row">
              <LabeledSelectInput
                className="xgs-request-rates__location-field"
                isDisabled={false}
                isLoading={requestRatesState.requestStarted}
                label="Destination Location:"
                labelMode={LabelModes.column}
                name="location"
                onInputChange={locationSearch}
                onValueChange={(v) => {
                  const obj: any = v;
                  if (!obj) return;
                  props.setFieldValue("location", {
                    label: obj.label,
                    value: obj.value.zip
                  });
                  setLocationFormValue({
                    label: obj.label,
                    value: obj.value.zip
                  });
                  dispatch(setDirectDestination(obj.value.direct));
                }}
                openMenuOnClick={requestRatesState.destinationLocations.length > 0}
                options={requestRatesState.destinationLocations}
                placeholder="Enter Zip or City..."
                value={locationFormValue}
              />
              {!requestRatesState.directDestination && (
                <div className="xgs-request-rates__indirect xgs-request-rates__indirect--guided">
                  Indirect Point!
                </div>
              )}
            </div>
            <div style={{ marginTop: 38 }}>
              <Button
                theme={ButtonThemes.gray}
                onClick={ () => { onClickBack(props.values) } }
                className="xgs-request-rates__step__nav-button"
              >
                Back
              </Button>
              <Button
                type="submit"
                theme={ButtonThemes.blue}
                className="xgs-request-rates__step__nav-button"
                disabled={!props.isValid || !props.values.location}
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DestinationLocation;
