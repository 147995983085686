import { ChatMessageModel } from "../../app/data/chat/models";

export default interface ChatState {
  requestStarted: boolean;  
  requestCreator?: string;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError?: string | null;
  messages: ChatMessageModel[],
  probillNumber: number | null;
  chatId: string | null;
  animation: boolean;
};

export const initialChatState: ChatState = {
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestError: null,
  messages: [],
  probillNumber: null,
  chatId: null,
  animation: true,
};
