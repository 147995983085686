import { toast } from "react-toastify";
import moment from "moment";
import {capitalize} from "lodash";

export const getProperty = <T, K extends keyof T>(obj: T, key: K) => {
  return obj[key];
};

export const pxToNumber = (value: string) => {
  if (value) {
    return parseInt(value.replace("px", ""), 10);
  }

  return 0;
};

export const getHeightBeforeEndOfPage = (
  selector: string,
  defaultHeight: number,
  windowHeight: number,
  extraHeight?: number
) => {
  const element = document.querySelector(selector) as HTMLElement;
  const bottomPadding = 16;
  if (element) {
    return (
      windowHeight -
      element.getBoundingClientRect().top -
      (extraHeight ? extraHeight : 0) -
      bottomPadding
    );
  }

  return defaultHeight;
};

export const getQueryStringFromModel = (model: any) => {
  let queryString = "";
  for (const param of Object.keys(model)) {
    if (
      model[param] !== null &&
      typeof model[param] !== typeof undefined &&
      model[param] !== ""
    ) {
      if (queryString !== "") {
        queryString = `${queryString}&`;
      }
      queryString = `${queryString}${param}=${model[param]}`;
    }
  }

  return queryString;
};

export const makeId = (length: number) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const arrangeName = (name: string): string => {
  if (name.includes(", ")) {
    // Split the name into parts
    const nameParts = name.split(", ");
    // Rearrange the parts
    return nameParts[1] + " " + nameParts[0];
  } else if (name.includes(",")) {
    // Split the name into parts
    const nameParts = name.split(",");
    // Rearrange the parts
    return  nameParts[1] + " " + nameParts[0];
  }

  return capitalize(name);
}

export const formatDate = (inputDate: string): string => {
  const year = inputDate.slice(0, 4);
  const month = inputDate.slice(4, 6);
  const day = inputDate.slice(6, 8);
  return `${month}/${day}/${year}`;
}

export const formatTime = (input: string) => {
const minutes = input.slice(-4, -2);
const hours = input.slice(0, -4);

return `${hours.length > 1 ? hours : "0" + hours}:${minutes}`;
}

// copy to clipboard
const fallbackCopyTextToClipboard = (text: string) => {
  var textArea = document.createElement("textarea");
  textArea.value = text;  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    const successful = document.execCommand("copy");
    if (successful) {
      toast.info("Copied to clipboard!", { autoClose: 3000 });
    } else {
      toast.error("Failed to copy to clipboard!");
    }
  } catch (err) {
    toast.error(err as any || "Error while copying to clipboard!");
  }
  document.body.removeChild(textArea);
};

export const copyTextToClipboard = (text: string) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
  () => {
    toast.info("Copied to clipboard!", { autoClose: 3000 });
  }, () => {
    toast.error("Failed to copy to clipboard!");
  });
};
// /copy to clipboard

export const nextBusinessDay = () => {
  let nextDay = moment().add(1, "day");
  while (nextDay.day() === 0 || nextDay.day() === 6) {
    nextDay.add(1, "day");
  }
  return nextDay.format("MM/DD/YYYY");
};

export const currentOrClosestBusinessDay = () => {
  let currentDay = moment();
  while (currentDay.day() === 0 || currentDay.day() === 6) {
    currentDay.add(1, "day");
  }
  return currentDay.format("MM/DD/YYYY");
};
