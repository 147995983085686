import api, { ApiResponse } from "../api";

export interface fuelSurchargeResponse {
  us: {
    price?: number,
    rate: number
  },
  ca: {
    price?: number,
    rate: number    
  }
}

class FuelSurchargeService {
  private static instance: FuelSurchargeService;
  private constructor() {}

  static getInstance(): FuelSurchargeService {
    if (!FuelSurchargeService.instance) {
      FuelSurchargeService.instance = new FuelSurchargeService();
    }
    return FuelSurchargeService.instance;
  }

  public getFuelSurcharge = async (): Promise<ApiResponse<fuelSurchargeResponse>> => {
    return await api.get("/fuelSurcharge");
  };
}

export default FuelSurchargeService;
