import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { FeatureBannerFormModel } from "../../app/data/feature-banner/models";
import FeatureBannerService from "../../app/data/feature-banner/featureBannerService";
import { initialFeatureBannerState } from "./FeatureBannerState";

const featureBannerService = FeatureBannerService.getInstance();

export const featureBannerSlice = createSlice({
  name: "featureBanner",
  initialState: initialFeatureBannerState,
  reducers: {
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestError = "";
      state.requestCreator = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestError = payload;
    },
    resetErrors: (state) => {
      state.requestFailed = false;
      state.requestError = "";
    },
    storeBanner: (state, { payload }) => {
      state.banner = payload;
    }
  }
});

export const {
  requestStarted,
  requestSucceed,
  requestFailed,
  resetErrors,
  storeBanner
} = featureBannerSlice.actions;

export const featureBannerSelector = (state: IState) => state.featureBanner;

export const getFeatureBanners = (
  onSuccess?: (response: any) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET"));
  const response = await featureBannerService.getFeatureBanners();
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess && onSuccess(response.data);
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const getUserFeatureBanner = (
  onSuccess?: (response: any) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET"));
  const response = await featureBannerService.getUserFeatureBanner();
  if (response.ok()) {
    dispatch(storeBanner(response.data));
    dispatch(requestSucceed());
    onSuccess && onSuccess(response.data);
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const saveFeatureBanner = (
  request: FeatureBannerFormModel,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("SAVE"));
  const response = await featureBannerService.saveFeatureBanner(request);
  if (response.ok()) {
    if (request.recipientType === "EMPLOYEE") dispatch(storeBanner(response.data));
    dispatch(requestSucceed());
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const clearFeatureBanner = (
  recipientType: string,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("CLEAR"));
  const response = await featureBannerService.saveFeatureBanner({
    message: "",
    buttonLabel: "",
    type: "",
    url: "",
    popupText: "",
    recipientType
  });
  if (response.ok()) {
    if (recipientType === "EMPLOYEE") dispatch(storeBanner(response.data));
    dispatch(requestSucceed());
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const uploadImage = (
  data: any,
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("UPLOAD_IMAGE"));
  const response = await featureBannerService.uploadFeatureBannerImage(data);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess(response.data);
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};


const featureBannerReducer = featureBannerSlice.reducer;
export default featureBannerReducer;
