import React, { useMemo, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userSelector } from "../../slices/user/userSlice";
import UserState from "../../slices/user/UserState";
import { CoreAccountPathParams, accountNumberParamName } from "./RoutesConfig";
import AccountRoutingProvider from "./AccountRoutingProvider";

let checksCompleted: boolean = false;

const AccountRouting: React.FC<any> = ({ children, ...rest }) => {
  const user: UserState = useSelector(userSelector);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams() as CoreAccountPathParams;

  const urlSubAccountNumber = useMemo(() => {
    return parseInt(params[accountNumberParamName], 10);
  }, [params]);

  const doChecks = React.useCallback((_history: any, _location: any, _dispatch: any, _user: any, _urlSubAccountNumber: number) => {
    if (_user.loggedIn) {
      const accountProvider = new AccountRoutingProvider(
        _history,
        _dispatch,
        _location,
        _user
      );
      accountProvider
        .setUrlSubAccountNumber(_urlSubAccountNumber)
        .checkUrlSubAccountNumberExist()
        ?.checkUrlSubAccountNumberAccess()
        ?.checkUrlSubAccountNumberTheSameAsActiveSubAccount();
    }
    checksCompleted = true;
  }, []);

  useEffect(() => {
    doChecks(history, location, dispatch, user, urlSubAccountNumber);
  }, [history, location, dispatch, user, urlSubAccountNumber, doChecks]);

  doChecks(history, location, dispatch, user, urlSubAccountNumber);

  return checksCompleted ? children : (<></>);
};

export default AccountRouting;
