import { createSlice } from "@reduxjs/toolkit";
import { IState } from "../..";
import { AppThunk } from "../../../app/store";
import RoutesService from "../../../app/data/plan-probills/routes/routesService";
import { AddStopsToRouteState } from "./routesState";
import { getActiveRouteAndStops } from "./activeRoutesSlice";

const initialState: AddStopsToRouteState = {
  loading: false,
  loadingFailed: false,
  loaded: false,
  error: ""
};

const routesService = RoutesService.getInstance();

export const addStopsToRouteSlice = createSlice({
  name: "addStopsToRoute",
  initialState,
  reducers: {
    addStopsToRouteResetState: (state) => initialState,
    addStopsToRouteLoadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
    },
    addStopsToRouteLoadingSucceed: (state) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
    },
    addStopsToRouteLoadingFailed: (state, { payload }) => {
      state.loading = false;
      state.loadingFailed = true;
      state.error = payload;
    },

  },
});

export const {
  addStopsToRouteResetState,
  addStopsToRouteLoadingStarted,
  addStopsToRouteLoadingSucceed,
  addStopsToRouteLoadingFailed,

} = addStopsToRouteSlice.actions;

export const addStopsToRouteSelector = (state: IState) => state.addStopsToRoute;

export const addStopsToRoute = (
  reqData: any,
  onSuccess?: () => void,
  onError?: (error: string) => void,
  onClose?: () => void
): AppThunk => async (dispatch) => {
  dispatch(addStopsToRouteLoadingStarted());
  const response = await routesService.addStopsToRoute({ ...reqData });
  if (response.ok() && response.data && response.data.result) {
    dispatch(addStopsToRouteLoadingSucceed());
    dispatch(getActiveRouteAndStops({ ...reqData}));
    onSuccess && onSuccess();
    onClose && onClose()
    
  } else {
    let error = "Internal Server Error, re-try the action or contact support";
    if (response && response.data && response.data.error) error = response.data.error;
    dispatch(addStopsToRouteLoadingFailed(error));
    onError && onError(error)
  }
};

const addStopsToRouteReducer = addStopsToRouteSlice.reducer;
export default addStopsToRouteReducer;