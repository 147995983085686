
export const REQUEST_TYPES = [
  {
    label: "Registration",
    value: "REGISTRATION"
  },
  {
    label: "Shipper",
    value: "SHIPPER"
  }
];

export const REGISTRATION_REJECT_REASONS = [
  {
    label: "Invalid Information",
    value: "INVALID_INFORMATION"
  },
  {
    label: "Invalid Account",
    value: "INVALID_ACCOUNT"
  },
  {
    label: "Other",
    value: "OTHER"
  }
];

export const SHIPPER_REJECT_REASONS = [
  {
    label: "Non-service Point",
    value: "NON_SERVICE_POINT"
  },
  {
    label: "Invalid Address",
    value: "INCOMPLETE_ADDRESS"
  },
  {
    label: "Other",
    value: "OTHER"
  }
];
