import React from "react";
import { useFormikContext } from "formik";
import AddressLookup from "../../../ui-components/address-lookup/addressLookup";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import XGSFormSelect from "../../../ui-components/form/select/xgsFormSelect";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import { XGSSelectOption } from "../../xgs-select/xgsSelect";
import XGSFormTextarea from "../textarea/xgsFormTextarea";
import usaStates from "../../../app/data/common/usaStates";
import { formatAddress } from "../../../services/common/address";
import { AddressModel } from "../../../app/data/common/address";
import { ReactComponent as InfoIcon } from "../../../images/info_dark.svg";

import "./formAddressSelector.scss";

interface FormAddressSelectorProps {
  addressLookupValue: XGSSelectOption | null;
  setAddressLookupValue: (value: any) => void;
  onZipChange?: (zip: string) => void;  
  isLoading?: boolean;
  editable?: boolean;
  error?: string;
  required?: boolean;
}

interface AddressFormValues {
  address: AddressModel,
};

const FormAddressSelector: React.FC<FormAddressSelectorProps> = (props) => {  
  const { setFieldValue, setFieldTouched, values, errors, touched } = useFormikContext<AddressFormValues>();

  const { addressLookupValue } = props;

  const onAddressValueChange = (val: any) => {
    setFieldValue("address.address1", val.address);
    setFieldValue("address.city", val.city);
    setFieldValue("address.state", val.state);
    setFieldValue("address.postalCode", val.zip);    

    props.setAddressLookupValue({
      label: `${val.address}, ${val.city}, ${val.state}, ${val.zip}`,
      value: {
        address: val.address,
        city: val.city,
        state: val.state,
        zip: val.zip
      }
    });

    setTimeout(() => {
      setFieldTouched("address.address1", true, true);
    }, 100);

    props.onZipChange?.(val.zip);
  };
 
  return (
    <div className="form-address-selector">      
      <AddressLookup
        defaultValue={addressLookupValue}
        onValueChange={onAddressValueChange}
        onBlur={() => {
          setFieldTouched("address.address1", true, true);
        }}
        allowReset={true}
        error={props.error || (!props.editable && touched.address?.address1 && errors.address?.address1)}
        isLoading={props.isLoading}
        required={props.required}
      />
      {props.editable && (
        <div className="xgs-form__address-block">
          <XGSFormInput
            type="text"
            name="address.address1"
            label="Address:"
            required={true}
            requiredAsteriskDisabled={false}
            labelMode={LabelModes.column}
            onChange={(e) => {
              setFieldValue("address.address1", e.currentTarget.value);
              props.setAddressLookupValue(null);
            }}
          />
          <XGSFormInput
            type="text"
            name="address.city"
            label="City:"
            required={true}
            requiredAsteriskDisabled={false}
            labelMode={LabelModes.column}
            onChange={(e) => {
              setFieldValue("address.city", e.currentTarget.value);
              props.setAddressLookupValue(null);
            }}
          />

          <div className="form-address-selector__row">
            <XGSFormSelect
              className="form-address-selector__field--state"
              isSearchable={false}
              label="State:"
              labelMode={LabelModes.column}
              name="address.state"
              options={usaStates}
              required={true}
              requiredAsteriskDisabled={false}
              onChange={(value) => {
                setFieldValue("address.state", value);
                props.setAddressLookupValue(null);
              }}
              controlled={true}
            />

            <XGSFormInput
              className="form-address-selector__field--zip"
              type="text"
              name="address.postalCode"
              label="Zip:"
              required={true}
              labelMode={LabelModes.column}
              onChange={(e) => {
                props.onZipChange?.(e.currentTarget.value);
                setFieldValue("address.postalCode", e.currentTarget.value);
                props.setAddressLookupValue(null);
              }}
              isLoading={props.isLoading}
            />
          </div>

          <XGSFormTextarea
            name="address.additionalAddressLine"
            label="Additional address line:"
            required={false}
            rows={2}
            counter={150}
          />
        </div>
      )}

      {!props.editable && (addressLookupValue ? (<div className="xgs-form__address-block">   
        <div className="xgs-gray-area__label">Address:</div>

        <div className="xgs-gray-area__content">        
          {formatAddress(values.address)}
        </div>    

        <XGSFormTextarea
          name="address.additionalAddressLine"          
          label="Additional address line:"
          required={false}
          rows={2}
          counter={150}
        />
      </div>) : (<div className="form-address-selector__info">
        <div className="form-address-selector__info-icon">
          <InfoIcon />
        </div>
        <div>
          If you haven't found your address, select the one closest to you from the list. After that, you can add additional address information below.
        </div>
      </div>))}       
    </div>
  );
};

export default FormAddressSelector;
