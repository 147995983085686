import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";
import {
  subscriptionsSelector,
  subscribeProbillStatus,
  unsubscribeProbillStatus
} from "../../../slices/subscriptions/subscriptionsSlice";
import SubscriptionsState from "../../../slices/subscriptions/SubscriptionsState";
import ConfirmationModal from "../../../ui-components/confirmation-modal/confirmationModal";
import Loading from "../../../ui-components/loading/loading";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";

export interface ShipmentStatusSubscribeProps {
  invoiceNumber: number;
  subscribed: boolean;
  userTimezone: string | null;
}

const ShipmentStatusSubscribe: React.FC<ShipmentStatusSubscribeProps> = (props) => {
  const dispatch = useDispatch();
  const subscriptionsState: SubscriptionsState = useSelector(subscriptionsSelector);
  const [showSubscribeConfirmation, setShowSubscribeConfirmation] = useState<boolean>(false);

  const subscribe = () => {
    dispatch(subscribeProbillStatus(props.invoiceNumber, props.userTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone, () => {
      setShowSubscribeConfirmation(false);
      toast.info("You subscribed to status updates", { autoClose: 3000 });
      mixpanel.track("Subscribed to probill status updates", {
        "Probill": props.invoiceNumber
      });
    }));
  };

  const unsubscribe = () => {
    dispatch(unsubscribeProbillStatus(props.invoiceNumber, () => {
      toast.info("You unsubscribed from status updates", { autoClose: 3000 });
      mixpanel.track("Unsubscribed from probill status updates", {
        "Probill": props.invoiceNumber
      });
    }));
  };

  return (
    <>
      <span className="xgs-shipment-details__subscribe__button">
        {!subscriptionsState.subscribeStarted && (
          <>
            {!props.subscribed && (
              <div
                className="xgs-shipment-details__subscribe__button xgs-site__content-container__toolbar__buttons__item"
                onClick={() => setShowSubscribeConfirmation(true)}
              >
                <XGSIcon
                  className="xgs-shipment-details__subscribe__button__icon"
                  icon={XGSIcons.faBell}
                  size="lg"
                  title="Subscribe to status updates"                  
                />
                <div className="xgs-shipment-details__subscribe__button__label">Subscribe</div>
              </div>
            )}
            {props.subscribed && (
              <div
                className="xgs-shipment-details__subscribe__button xgs-shipment-details__subscribe__button--unsubscribe xgs-site__content-container__toolbar__buttons__item"
                onClick={unsubscribe}
              >
                <XGSIcon
                  className="xgs-shipment-details__subscribe__button__icon"
                  icon={XGSIcons.faBellSlash}
                  size="lg"
                  title="Unsubscribe from status updates"                  
                />
                <div className="xgs-shipment-details__subscribe__button__label">Unsubscribe</div>
              </div>
            )}
          </>
        )}
        {subscriptionsState.subscribeStarted && (
          <div style={{ marginTop: 8, marginRight: 12 }}>
            <Loading isLoading={true} />
          </div>
        )}
      </span>
      <ConfirmationModal
        opened={showSubscribeConfirmation}
        header="Subscribe to status updates"
        confirmButtonText="Subscribe"
        spinner={subscriptionsState.subscribeStarted}
        onCancel={() => setShowSubscribeConfirmation(false)}
        onConfirm={subscribe}
      >        
        <div className="xgs-shipment-details__subscribe__confirmation__text">
          You are about to subscribe to shipment status updates. You can then unsubscribe from these notifications on the shipment page or on the <Link className="blue-link" to="/settings/subscriptions" target="_blank">dedicated subscriptions page</Link>.
        </div>
      </ConfirmationModal>
    </>
  );
};

export default ShipmentStatusSubscribe;
