import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationModal from "../../../ui-components/confirmation-modal/confirmationModal";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import { StepProps } from "../../../app/data/common/models";
import RequestRatesState from "../../../slices/rates/RequestRatesState";
import {
  requestRatesSelector,
  addShippingItem,
  updateShippingItem,
  deleteShippingItem,
  clearDeletedShippingItems
} from "../../../slices/rates/requestRatesSlice";
import ShippingItemForm from "./shippingItemForm";
import CommonState from "../../../slices/common/CommonState";
import { commonSelector } from "../../../slices/common/commonSlice";

const ShippingItems: React.FC<StepProps> = (props) => {
  const dispatch = useDispatch();
  const { next, previous } = props;
  const requestRatesState: RequestRatesState = useSelector(requestRatesSelector);
  const commonState: CommonState = useSelector(commonSelector);
  const [showAddShippingItemForm, setAddShippingItemForm] = useState<boolean>(false);
  const [removeItemIndex, setRemoveItemIndex] = useState<number | undefined>(undefined);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState<boolean>(false);
  const [editShippingItemIndex, setEditShippingItemIndex] = useState<number | undefined>(undefined);

  const onClickBack = () => {
    dispatch(clearDeletedShippingItems());
    previous && previous();
  }
;
  const onClickNext = () => {
    dispatch(clearDeletedShippingItems());
    next && next();
  };

  const getClassLabel = (freightClassValue: string) => {
    let freightClass = commonState.freightClasses.find(obj => obj.value === freightClassValue);
    return freightClass ? freightClass.label : freightClassValue;
  };

  return (
    <div className="xgs-request-rates__shipping-items">
      <div className="xgs-request-rates__step__header">List all items you are shipping</div>
      {requestRatesState.items.map((item, index) => (
        <div key={index}>
          {(index === editShippingItemIndex) && (
            <ShippingItemForm
              itemIndex={index}
              onSubmit={(data) => {
                dispatch(updateShippingItem({ index, data }));
                setEditShippingItemIndex(undefined);
              }}
              onCancel={() => {
                setEditShippingItemIndex(undefined);
              }}
            />
          )}
          {(editShippingItemIndex === undefined || (index !== editShippingItemIndex)) && !item.deleted && (
            <div className="xgs-request-rates__shipping-items__item">
              <div className="xgs-request-rates__shipping-items__item__columns">
                <div>
                  {item.rolls && <div className="xgs-request-rates__shipping-items__item__property">Rolls</div> }
                  <div className="xgs-request-rates__shipping-items__item__property">{item.pieces} piece{(item.pieces && item.pieces > 1) ? "s" : ""}, 
                  {item.yards ? " " + item.yards + " yards, " : ""} {item.weight} pounds</div>
                  <div className="xgs-request-rates__shipping-items__item__property">Class: {getClassLabel(item.freightClass)}</div>
                  {item.description && <div className="xgs-request-rates__shipping-items__item__property">Description: {item.description}</div>}
                </div>
                <div>
                  <div className="xgs-request-rates__shipping-items__item__action">
                    <span
                      className="xgs-request-rates__shipping-items__link"
                      onClick={() => {
                        setAddShippingItemForm(false);
                        setEditShippingItemIndex(index);
                      }}
                    >
                      Edit
                    </span>
                  </div>
                  <div className="xgs-request-rates__shipping-items__item__action">
                    <span
                      className="xgs-request-rates__shipping-items__link"
                      onClick={() => {
                        setRemoveItemIndex(index);
                        setShowRemoveConfirmation(true);
                      }}
                    >
                      Remove
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
      {(showAddShippingItemForm || requestRatesState.items.length === 0) && <ShippingItemForm onSubmit={(data) => {
        dispatch(addShippingItem(data));
        setAddShippingItemForm(false);
      }} />}
      {!showAddShippingItemForm && (editShippingItemIndex === undefined) && (requestRatesState.items.length > 0) && (requestRatesState.items.filter(item => !item.deleted).length < 5) && (
        <div className="xgs-request-rates__shipping-items__add">
          <span
            className="xgs-request-rates__shipping-items__link"
            onClick={() => { setAddShippingItemForm(true); }}
          >
            + Add new item
          </span>
        </div>
      )}
      <div style={{ marginTop: 34 }}>
        <Button
          theme={ButtonThemes.gray}
          onClick={onClickBack}
          className="xgs-request-rates__step__nav-button"
        >
          Back
        </Button>
        <Button
          theme={ButtonThemes.blue}
          onClick={onClickNext}
          className="xgs-request-rates__step__nav-button"
          disabled={requestRatesState.items.length === 0 || requestRatesState.items.filter(item => !item.deleted).length === 0 || (editShippingItemIndex !== undefined)}
        >
          Get rates
        </Button>
      </div>
      <ConfirmationModal
        opened={showRemoveConfirmation}
        confirmButtonText="Remove"
        onCancel={() => {
          setShowRemoveConfirmation(false);
        }}
        onConfirm={() => {
          dispatch(deleteShippingItem(removeItemIndex));
          setShowRemoveConfirmation(false);
          setRemoveItemIndex(undefined);
        }}>
        The shipping item will be removed from the list.
      </ConfirmationModal>
    </div>
  );
};

export default ShippingItems;
