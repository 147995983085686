import React from "react"

interface FilterPros {
    column: any
}

export const Filter: React.FC<FilterPros> = ({ column }) => {
    return (
        <div style={{ marginTop: 5 }}>
            {column.canFilter && column.render("Filter")}
        </div>
    )
}