import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import debounce from "lodash/debounce";
import LabeledSelectInput from "../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import XGSFormTextarea from "../../../ui-components/form/textarea/xgsFormTextarea";
import XGSFormSelect from "../../../ui-components/form/select/xgsFormSelect";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import {
  ClaimGeneralModel,
  ClaimGeneralSchema
} from "../../../app/data/claims/models";
import ClaimsState from "../../../slices/claims/ClaimsState";
import {
  claimsSelector,
  deleteAllItems,
  getProbillDetails,
  resetOptionalDocuments,
  resetDocumentData,
  setClaimAllItems,
  setClaimAllItemsValues,
  setClaimGeneral,
  setFreightCharges,
  setFreightChargesOnly,
  setPoNumber
} from "../../../slices/claims/claimsSlice";
import { resetShipmentDetails } from "../../../slices/shipment-details/shipmentDetailsSlice";
import { StepProps } from "../../../app/data/common/models";
import { CLAIM_REASON_TYPES } from "../constants";
import "../claims.scss";

let initialValues: ClaimGeneralModel = {
  reason: "",
  comments: undefined,
  probillNumber: undefined,
  bolNumber: undefined,
  originalClaimNumber: undefined
};

interface XGSSelectOptionExt extends Omit<XGSSelectOption, "value"> {
  value: any;
}

const ClaimGeneral: React.FC<StepProps> = (props) => {
  const { next } = props;
  const claimsState: ClaimsState = useSelector(claimsSelector);
  const [reasonFormValue, setReasonFormValue] = useState<XGSSelectOption | null>();
  const [probill, setProbill] = useState<XGSSelectOptionExt | null>();
  const [bol, setBol] = useState<XGSSelectOptionExt | null>();
  const dispatch = useDispatch();
  const claimsFormRef = useRef<any>(null);

  let probillDataFetch = (type: string, query: string) => {
    if (query?.length < 3) return;
    if (type === "PROBILL" && !/^\d{1,48}$/.test(query)) return;
    dispatch(getProbillDetails(type, query));
  }
  probillDataFetch = debounce(probillDataFetch, 700);

  const onClickNext = (data: ClaimGeneralModel) => {
    dispatch(setClaimGeneral(data));
    next && next();
  };

  const clearClaimValues = () => {
    dispatch(setFreightCharges([]));
    dispatch(setClaimAllItems(undefined));
    dispatch(setFreightChargesOnly(undefined));
    dispatch(deleteAllItems());
    dispatch(setClaimAllItemsValues({
      totalClaimAmount: undefined,
      areFreightChargesIncluded: false
    }));
    dispatch(resetShipmentDetails());
    dispatch(resetDocumentData());
  };

  const onChangeProbill = (v?: XGSSelectOptionExt | null) => {
    const value = v?.value as any;
    claimsFormRef.current.setFieldValue("probillNumber", (value as any).probill || "");
    setProbill(v);
    claimsFormRef.current.setFieldValue("bolNumber", (value as any).bolNumber || "");
    setBol({
      label: (value as any).bolNumber,
      value: (value as any).bolNumber
    });
    dispatch(setPoNumber(value.poNumber));
    clearClaimValues();
  };

  const onChangeBol = (v?: XGSSelectOptionExt | null) => {
    const value = v?.value as any;
    claimsFormRef.current.setFieldValue("bolNumber", value.bolNumber || "");
    setBol(v);
    claimsFormRef.current.setFieldValue("probillNumber", value.probill || "");
    setProbill({
      label: value.probill,
      value: value.probill
    });
    dispatch(setPoNumber(value.poNumber));
    clearClaimValues();
  };

  useEffect(() => {
    if (!claimsState.general) return;
    initialValues = {
      reason: claimsState.general.reason,
      comments: claimsState.general.comments,
      probillNumber: claimsState.general.probillNumber,
      bolNumber: claimsState.general.bolNumber,
      originalClaimNumber: claimsState.general.originalClaimNumber
    }
    setProbill({
      value: claimsState.general.probillNumber || "",
      label: claimsState.general.probillNumber || ""
    });
    setBol({
      value: claimsState.general.bolNumber || "",
      label: claimsState.general.bolNumber || ""
    });
    const reason = CLAIM_REASON_TYPES.find(obj => obj.value === claimsState.general?.reason);
    reason && setReasonFormValue({
      value: claimsState.general.reason,
      label: reason.label
    });
  }, [claimsState.general]);

  useEffect(() => {
    if (claimsState.general) return;
    claimsFormRef.current.setFieldValue("reason", "");
    claimsFormRef.current.setFieldValue("comments", "");
    claimsFormRef.current.setFieldValue("probillNumber", "");
    claimsFormRef.current.setFieldValue("bolNumber", "");
    claimsFormRef.current.setFieldValue("originalClaimNumber", "");
  }, [claimsState.general]);

  return (
    <div className="xgs-claims__form">
      <Formik
        onSubmit={onClickNext}
        initialValues={initialValues}
        validationSchema={ClaimGeneralSchema}
        innerRef={claimsFormRef}
        enableReinitialize
      >
        {(props: FormikProps<ClaimGeneralModel>) => (
          <Form>
            <div className="xgs-claims__row">
              <div className="xgs-claims__row__column-50">
                <div className="xgs-wizard__step__header">General Information</div>
              </div>
              <div className="xgs-claims__row__column-50 text-right">
                <Button
                  type="button"
                  theme={ButtonThemes.blue}
                  className="xgs-claims__public-form-button"
                  onClick={() => window.open("https://portal.xgsi.com/secure/claims/defaultpublic.aspx", "_blank")}
                >
                  Submit a claim on behalf of another payor
                </Button>
              </div>
            </div>
            <div className="xgs-claims__section">
              <div className="xgs-claims__row">
                <div className="xgs-claims__row__column-50">
                  <div className="xgs-form__field">
                    <LabeledSelectInput
                      className="xgs-request-rates__location-field"
                      isDisabled={false}
                      isLoading={claimsState.requestStarted && claimsState.requestCreator === "GET_PROBILL_SUGGESTIONS"}
                      label="Probill #:"
                      labelMode={LabelModes.column}
                      name="probillNumber"
                      onInputChange={(query) => probillDataFetch("PROBILL", query)}
                      onValueChange={(v) => onChangeProbill(v)}
                      openMenuOnClick={claimsState.probillSuggestions?.length > 0}
                      options={claimsState.probillSuggestions}
                      placeholder="Start typing..."
                      required={true}
                      requiredAsteriskDisabled={false}
                      value={probill}
                    />
                  </div>
                </div>
                <div className="xgs-claims__row__column-50">
                  {claimsState.poNumber && (
                    <div className="xgs-claims__po-number">
                      <div className="xgs-form__label">PO #:</div>
                      {claimsState.poNumber}
                    </div>
                  )}
                </div>
              </div>
              <div className="xgs-claims__row">
                <div className="xgs-claims__row__column-50">
                  <div className="xgs-form__field">
                    <LabeledSelectInput
                      className="xgs-request-rates__location-field"
                      isDisabled={false}
                      isLoading={claimsState.requestStarted && claimsState.requestCreator === "GET_BOL_SUGGESTIONS"}
                      label="Bill of Lading #:"
                      labelMode={LabelModes.column}
                      name="bolNumber"
                      onInputChange={(query) => probillDataFetch("BOL", query)}
                      onValueChange={(v) => onChangeBol(v)}
                      openMenuOnClick={claimsState.bolSuggestions?.length > 0}
                      options={claimsState.bolSuggestions}
                      placeholder="Start typing..."
                      required={true}
                      requiredAsteriskDisabled={false}
                      value={bol}
                    />
                  </div>
                </div>
                <div className="xgs-claims__row__column-50">
                  <div className="xgs-form__field">
                    <XGSFormInput
                      type="text"
                      name="originalClaimNumber"
                      label="Claimants Claim #:"
                      required={true}
                      requiredAsteriskDisabled={true}
                      labelMode={LabelModes.column}
                    />
                  </div>
                </div>
              </div>
              <div className="xgs-claims__row">
                <div className="xgs-claims__row__column-50">
                  <div className="xgs-form__field">
                    <XGSFormSelect
                      isSearchable={false}
                      label="Claim Reason:"
                      labelMode={LabelModes.column}
                      name="reason"
                      onValueChange={(v: any) => {
                        props.setFieldValue("reason", v?.value);
                        setReasonFormValue(v);
                        dispatch(resetOptionalDocuments(v?.value));
                      }}
                      options={CLAIM_REASON_TYPES}
                      required={true}
                      requiredAsteriskDisabled={false}
                      formik={true}
                      value={reasonFormValue}
                    />
                  </div>
                </div>
                <div className="xgs-claims__row__column-50 xgs-claims__row__column-50--mobile-hidden">
                  &nbsp;
                </div>
              </div>
              <div className="xgs-claims__row">
                <div className="xgs-form__field" style={{paddingTop: 4}}>
                  <XGSFormTextarea
                    name="comments"
                    label="Comments:"
                    required={false}
                    className="xgs-claims__form__textarea"
                    counter={255}
                  />
                </div>
              </div>
            </div>
            <div className="xgs-wizard__step__buttons">
              <Button
                type="submit"
                theme={ButtonThemes.blue}
                disabled={!props.isValid || !props.values.reason || claimsState.requestStarted}
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ClaimGeneral;
