import { useSelector } from "react-redux"
import { appointmentSelector } from "../../../slices/appointments/appointmentsSlice"
import React from "react";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import { ContactCard } from "../../company/account-contact/contactCard";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import { StepProps } from "../../../app/data/common/models";
import scheduleAvailableBlue from "../../../images/schedule-available_blue.svg";
import note2Blue from "../../../images/note-2_blue.svg";
import { ReactComponent as Info } from "../../../images/info.svg";
import styles from "../../../sass/variables.module.scss";

export interface AppointmentViewStepProps extends StepProps {
  stepCount: number;
  stepIdx: number;
}

export const AppointmentViewStep: React.FC<AppointmentViewStepProps> = (props) => {
  const appointmentState = useSelector(appointmentSelector);

  return (
    <div>
      <div className="xgs-create-appt__wizard">
        <div className="xgs-create-appt__wizard__check">
          <XGSIcon
            className="xgs-create-appt__wizard__check__tick"
            icon={XGSIcons.faCheck}
          />
        </div>
        <div className="xgs-create-appt__wizard__heading">Scheduled</div>
      </div>
      {appointmentState.existingAppointment?.contact && <ContactCard hideButtons contact={appointmentState.existingAppointment?.contact} />}
      <div className="xgs-create-appt__confirm-tile">
        <div>
          <img src={scheduleAvailableBlue} alt="calendar" />
          {appointmentState.existingAppointment?.date.toUiDateFormat()}&nbsp;&nbsp;
          {!!appointmentState.existingAppointment?.earlyTime && !!appointmentState.existingAppointment?.lateTime && (
            <>
              {appointmentState.existingAppointment?.earlyTime.toUiTimeFormat()} -{" "}
              {appointmentState.existingAppointment?.lateTime.toUiTimeFormat()}
            </>
          )}
        </div>
        {appointmentState.existingAppointment?.notes && <div>
          <img src={note2Blue} alt="note" />
          {appointmentState.existingAppointment.notes}
        </div>}
      </div>
      {!!appointmentState.existingAppointment && <div className="xgs-create-appt__confirm-tile">
        <div className="xgs-create-appt__important">
          <Info color={styles.blue1}/>
          <span>
            <b>Confirmed by:</b> <br />
            {`${appointmentState.existingAppointment?.confirmedDateTime?.toUiDateTimeFormat()} by ${appointmentState.existingAppointment?.confirmedUser}`}
          </span>
        </div>
      </div>}
      <Button style={{marginTop: 8}} onClick={() => props.next && props.next()} className="xgs-create-appt__button" theme={ButtonThemes.gray}>Reschedule</Button>
    </div>
  )
}