import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import mixpanel from "mixpanel-browser";
import Modal from "react-modal";
import modalStyle from "../../app/data/common/modalStyle"
import Button, { ButtonThemes } from "../../ui-components/button/button";
import XGSIcon from "../../ui-components/icon/xgsIcon";
import XGSIcons from "../../ui-components/icon/xgsIcons";
import {
  getUserFeatureBanner,
  featureBannerSelector
} from "../../slices/feature-banner/featureBannerSlice";
import { cn } from "../../services/common/className";
import infoIcon from "../../images/info_blue.svg";
import closeIcon from "../../images/close_gray.svg";
import { BannerTypes } from "./constants";
import "./featureBanner.scss";

interface FeatureBannerProps {
  employee?: boolean;
};

Modal.setAppElement("#root");

const FeatureBanner: React.FC<FeatureBannerProps> = (props) => {
  const dispatch = useDispatch();
  const featureBannerState = useSelector(featureBannerSelector);
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const closeBanner = () => {
    if (!featureBannerState.banner?.date) return;
    setShowBanner(false);
    localStorage.setItem("xgs-feature-banner", featureBannerState.banner.date);
  };

  const onBannerButtonClick = () => {
    mixpanel.track(`Feature banner was clicked${props.employee ? " by employee" : " by customer"}`);
    if (featureBannerState.banner.type === BannerTypes.LINK_TO_PAGE) {
      if (!featureBannerState.banner?.url) return;
      window.open(featureBannerState.banner.url, "_blank");
    } else if (featureBannerState.banner.type === BannerTypes.POPUP) {
      setShowPopup(true);
    }
  };

  useEffect(() => {
    dispatch(getUserFeatureBanner());
  }, [dispatch]);

  useEffect(() => {
    if (!featureBannerState.banner) return;
    const date = localStorage.getItem("xgs-feature-banner");
    if (!date || date !== featureBannerState.banner.date) setShowBanner(true);
  }, [featureBannerState.banner]);

  return (
    <>
      {featureBannerState.banner && featureBannerState.banner.message && (featureBannerState.banner.url || featureBannerState.banner.popupText) && showBanner && (
        <>
          <div className={`xgs-animation__shaking ${cn("xgs-feature-banner")({employee: props.employee})}`}>
            {props.employee && <img src={infoIcon} className="xgs-feature-banner__info-icon" alt="info" />
            }
            <div className="xgs-feature-banner__text">{featureBannerState.banner.message}</div>
            <Button
              theme={props.employee ? ButtonThemes.blue : ButtonThemes.white}
              type="button"
              className="xgs-feature-banner__button"
              onClick={onBannerButtonClick}
            >
              {featureBannerState.banner?.buttonLabel}
            </Button>
            {props.employee ? (
              <img
                src={closeIcon}
                className="xgs-feature-banner__close"
                onClick={closeBanner}
                alt="close"
              />
            ) : (
              <XGSIcon
                icon={XGSIcons.faTimes}
                className="xgs-feature-banner__close"
                onClick={closeBanner}
              />
            )}
          </div>
          <Modal
            isOpen={showPopup}
            style={modalStyle}
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
            onRequestClose={() => setShowPopup(false)}
          >
            <div className="xgs-modal__content">
              <div className="ql-editor" dangerouslySetInnerHTML={{__html: featureBannerState.banner.popupText}}></div>
            </div>
            <div className="xgs-modal__buttons text-center">
              <Button
                theme={ButtonThemes.blue}
                onClick={() => setShowPopup(false)}
                className="xgs-modal__button"
                type="button"
              >
                Close
              </Button>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default FeatureBanner;
