import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import { ActiveRoutesState } from "./routesState";
import RoutesService from "../../../app/data/plan-probills/routes/routesService";
import { getRouteStops, resetRouteStopsState } from "./routeStopsSlice";

const initialState: ActiveRoutesState = {
  loading: false,
  loadingFailed: false,
  loaded: false,
  activeRoutes: [],
  selectedRouteId: ""
};

const routesService = RoutesService.getInstance();

export const activeRoutesSlice = createSlice({
  name: "activeRoutes",
  initialState,
  reducers: {
    resetState: (state) => initialState,
    loadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
      state.selectedRouteId = "";
    },
    loadingSucceed: (state, { payload }) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
      state.activeRoutes = payload;
    },
    loadingFailed: (state) => {
      state.loading = false;
      state.loadingFailed = true;
      state.activeRoutes = [];
    },
    setSelectedRouteId: (state, { payload }) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
      state.selectedRouteId = payload;
    },
  },
});

export const {
  resetState,
  loadingStarted,
  loadingSucceed,
  loadingFailed,
  setSelectedRouteId
} = activeRoutesSlice.actions;

export const activeRoutesSelector = (state: IState) => state.activeRoutes;

export const getActiveRoutes = (
  routeRequest: any
): AppThunk => async (dispatch) => {
  dispatch(loadingStarted());
  const response = await routesService.getActiveRoutes({ ...routeRequest });
  dispatch(resetRouteStopsState());
  if (response.ok() && response.data && response.data.result) {
    dispatch(loadingSucceed(response.data.results));
  } else {
    dispatch(loadingFailed());
  }
};

export const getActiveRouteAndStops = (
  routeRequest: any
): AppThunk => async (dispatch) => {
  dispatch(loadingStarted());
  const response = await routesService.getActiveRoutes({ ...routeRequest });
  dispatch(resetRouteStopsState());
  if (response.ok() && response.data && response.data.result) {
    dispatch(loadingSucceed(response.data.results));
    dispatch(getRouteStops({...routeRequest}));
  } else {
    dispatch(loadingFailed());
  }
};

const activeRoutesReducer = activeRoutesSlice.reducer;
export default activeRoutesReducer;