import { IState} from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { DispatchPlanProbillsState } from "./dispatchProbillsState";
import PlanProbillsService from "../../app/data/plan-probills/planProbillsService";

const initialState: DispatchPlanProbillsState = {
  loading: false,
  loadingFailed: false,
  loaded: false,
  plannableProbills: []
};

const planProbillsService = PlanProbillsService.getInstance();

export const dispatchPlanProbillsSlice = createSlice({
  name: "dispatchPlanProbills",
  initialState,
  reducers: {
    resetDispatchPlanProbills: (state) => initialState,
    dispatchPlanProbillsLoadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
    },
    dispatchPlanProbillsLoadingSucceed: (state, { payload }) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
      state.plannableProbills = payload.results;
    },
    dispatchPlanProbillsLoadingFailed: (state) => {
      state.loading = false;
      state.loadingFailed = true;
      state.plannableProbills = [];
    },
  },
});

export const {
  resetDispatchPlanProbills,
  dispatchPlanProbillsLoadingStarted,
  dispatchPlanProbillsLoadingSucceed,
  dispatchPlanProbillsLoadingFailed
} = dispatchPlanProbillsSlice.actions;

export const dispatchPlanProbillsSelector = (state: IState) => state.dispatchPlanProbills;

export const getPlannableProbills = (
  terminalNumber: string | undefined, sortObj?: any
): AppThunk => async (dispatch) => {
  if (!terminalNumber) {
    dispatch(dispatchPlanProbillsLoadingFailed());
    return;
  }
  dispatch(dispatchPlanProbillsLoadingStarted());
  let req = {terminalNumber};
  if(sortObj && sortObj.sortBy){
    req = {...req, ...sortObj}
  }
  const response = await planProbillsService.getPlannableProbills(req);
  if (response.ok() && response.data && response.data.result) {
    dispatch(dispatchPlanProbillsLoadingSucceed(response.data));
  } else {
    dispatch(dispatchPlanProbillsLoadingFailed());
  }
};

const dispatchPlanProbillsReducer = dispatchPlanProbillsSlice.reducer;
export default dispatchPlanProbillsReducer;