import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Form, Formik, FormikProps } from "formik";
import { AddTeamAccountModel, AddTeamAccountSchema } from "../../../app/data/company/models";
import XGSPopup from "../../../ui-components/xgs-popup/xgsPopup";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import Loading from "../../../ui-components/loading/loading";
import CreateTeamState from "../../../slices/customers/teams/CreateTeamState";
import {
  createTeamSelector,
  clearAccountsInfo,
  getAccountsInfo
} from "../../../slices/customers/teams/createTeamSlice";
import TeamAccountState from "../../../slices/customers/teams/TeamAccountState";
import {
  teamAccountSelector,
  updateTeam
} from "../../../slices/customers/teams/teamAccountSlice";
import "./addAccount.scss";

export interface AddAccountProps {
  teamId: string;
  show: boolean;
  type: "CUSTOMER" | "AGENT";
  onAccountAdded?: () => void;
  onClose: () => void;
}

let initialValues: AddTeamAccountModel = {
  account: ""
};

const AddAccount: React.FC<AddAccountProps> = (props) => {
  const [inputTimeout, setInputTimeout] = useState(0);
  const addAccountFormRef = useRef<any>(null);
  const createTeamState: CreateTeamState = useSelector(createTeamSelector);
  const teamAccountState: TeamAccountState = useSelector(teamAccountSelector);
  const dispatch = useDispatch();

  const onAccountNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    if (Number(target.value) >= 100 && Number(target.value) <= 99999999) {
      const accountNumber = Number(target.value);
      if (inputTimeout) {
        clearTimeout(inputTimeout);
      }
      setInputTimeout(setTimeout(() => {
        dispatch(getAccountsInfo([accountNumber]));
      }, 500));
    }
    addAccountFormRef.current?.handleChange(e);
  }

  const onSubmitTeamAccount = (data: AddTeamAccountModel) => {
    let request = {
      teamId: props.teamId,
      accounts: [Number(data.account)]
    };
    dispatch(updateTeam(request, () => {
      toast.info("The account was added!");
      props.onAccountAdded && props.onAccountAdded();
      props.onClose();
    }));
  };

  useEffect(() => {
    props.show && dispatch(clearAccountsInfo());
  }, [dispatch, props.show]);

  return (
    <XGSPopup
      title=""
      opened={props.show}
      onClose={props.onClose}
      className="xgs-teams__add-account__popup"
    >
      <div>
        <div className="xgs-teams__add-account__popup__header">Add account to the {props.type === "CUSTOMER" ? "customer" : ""}{props.type === "AGENT" ? "agent" : ""}</div>
        <Formik
          onSubmit={onSubmitTeamAccount}
          initialValues={initialValues}
          validationSchema={AddTeamAccountSchema}
          innerRef={addAccountFormRef}
        >
          {(props: FormikProps<AddTeamAccountModel>) => (
            <Form>
              <div className="xgs-teams__add-account__popup__field">
                <XGSFormInput
                  type="text"
                  name="account"
                  label="Account number:"
                  required={true}
                  requiredAsteriskDisabled={true}
                  labelMode={LabelModes.column}
                  onChange={onAccountNumberChange}
                />
              </div>
              {(createTeamState.accountsInfo_was_started || createTeamState.accountsInfo || createTeamState.accountsInfo_was_failed) && !props.errors.account && (
                <div className="xgs-teams__add-account__account__area">
                    {((createTeamState.accountsInfo && (createTeamState.accountsInfo.length > 0)) || createTeamState.accountsInfo_was_started || createTeamState.accountsInfo_was_failed) && (
                      <>
                        <div className="xgs-teams__add-account__account__title">Account data:</div>
                        <div className="xgs-teams__add-account__account__data">
                          <Loading isLoading={createTeamState.accountsInfo_was_started} />
                          {createTeamState.accountsInfo_was_failed && (
                            <div className="xgs-teams__add-account__account__error">
                              {createTeamState.accountsInfo_fail_reason || "Error"}
                            </div>
                          )}
                          {createTeamState.accountsInfo && (createTeamState.accountsInfo.length > 0) && (
                            <>
                              <strong>{createTeamState.accountsInfo[0].accountName}</strong><br />
                              {createTeamState.accountsInfo[0].address ? `${createTeamState.accountsInfo[0].address}, ` : ""}{createTeamState.accountsInfo[0].city ? `${createTeamState.accountsInfo[0].city}, ` : ""}{createTeamState.accountsInfo[0].state ? `${createTeamState.accountsInfo[0].state}, ` : ""}{createTeamState.accountsInfo[0].zip}
                            </>
                          )}
                        </div>
                      </>
                    )}
                </div>
              )}
              <Button
                theme={ButtonThemes.blue}
                type="submit"
                className="xgs-teams__add-account__popup__button"
                disabled={!props.isValid || !props.dirty || !createTeamState.accountsInfo || createTeamState.accountsInfo.length === 0}
                spinner={teamAccountState.request_was_started}
              >
                Add&nbsp;account
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </XGSPopup>
  );
};

export default AddAccount;
