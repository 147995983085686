import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export interface PlannableProbills {
    "probillNumber": number,
    "probillStatus": string,
    "consigneeNumber": number,
    "consigneeName": string,
    "consigneeAddress": string,
    "consigneeCity": string,
    "consigneeState": string,
    "consigneeZip": 0,
    "consigneeLattitude": string,
    "consigneeLongitude": string,
    "probillWeight": number,
    "probillItems": number,
    "isAgentHandoff": boolean,
    "details": string,
    "etaDate": number
};
export const TerminalOptions: any = [
    { label: '1: TUNNEL HILL', value: '1' },
    { label: '4: DALLAS', value: '4' },
    { label: '17: SAN ANTONIO', value: '17' },
    { label: '20: PITTSBURGH', value: '20' },
    { label: '26: SAN DIEGO', value: '26' },
    { label: '47: SALT LAKE CITY', value: '47' },
    { label: '51: SPOKANE', value: '51' },
    { label: '75: PHOENIX', value: '75' },
    { label: '77: CHICAGO', value: '77' },
    { label: '106: DENVER', value: '106' },
    { label: '109: ANAHEIM', value: '109' },
    { label: '119: JACKSONVILLE', value: '119' },
    { label: '145: ROCHESTER', value: '145' },
    { label: '150: HOUSTON', value: '150' },
    { label: '153: SAINT LOUIS', value: '153' },
    { label: '159: PORTLAND', value: '159' },
    { label: '170: ALBUQUERQUE', value: '170' },
    { label: '188: OKLAHOMA CITY', value: '188' },
    { label: '189: BALTIMORE', value: '189' },
    { label: '192: MILWAUKEE', value: '192' },
    { label: '197: MINNEAPOLIS', value: '197' },
    { label: '209: TRACY', value: '209' },
    { label: '305: MIAMI', value: '305' },
    { label: '351: CLINTON', value: '351' },
    { label: '384: KENT', value: '384' },
    { label: '463: INDIANAPOLIS', value: '463' },
    { label: '611: SPRINGFIELD', value: '611' },
    { label: '620: ALLENTOWN', value: '620' },
    { label: '624: INDIANAPOLIS', value: '624' },
    { label: '625: DETROIT', value: '625' },
    { label: '642: GRAND RAPIDS', value: '642' },
    { label: '661: DETROIT', value: '661' },
    { label: '704: CHARLOTTE', value: '704' },
    { label: '863: LAKELAND', value: '863' },
    { label: '944: DAYTON', value: '944' },
    { label: '946: CLEVELAND', value: '946' },
];
export const TerminalOptionsByNumber: any = {
    '1': { label: '1: TUNNEL HILL', value: '1' },
    '4': { label: '4: DALLAS', value: '4' },
    '17': { label: '17: SAN ANTONIO', value: '17' },
    '20': { label: '20: PITTSBURGH', value: '20' },
    '26': { label: '26: SAN DIEGO', value: '26' },
    '47': { label: '47: SALT LAKE CITY', value: '47' },
    '51': { label: '51: SPOKANE', value: '51' },
    '75': { label: '75: PHOENIX', value: '75' },
    '77': { label: '77: CHICAGO', value: '77' },
    '106': { label: '106: DENVER', value: '106' },
    '109': { label: '109: ANAHEIM', value: '109' },
    '119': { label: '119: JACKSONVILLE', value: '119' },
    '145': { label: '145: ROCHESTER', value: '145' },
    '150': { label: '150: HOUSTON', value: '150' },
    '153': { label: '153: SAINT LOUIS', value: '153' },
    '159': { label: '159: PORTLAND', value: '159' },
    '170': { label: '170: ALBUQUERQUE', value: '170' },
    '188': { label: '188: OKLAHOMA CITY', value: '188' },
    '189': { label: '189: BALTIMORE', value: '189' },
    '192': { label: '192: MILWAUKEE', value: '192' },
    '197': { label: '197: MINNEAPOLIS', value: '197' },
    '209': { label: '209: TRACY', value: '209' },
    '305': { label: '305: MIAMI', value: '305' },
    '351': { label: '351: CLINTON', value: '351' },
    '384': { label: '384: KENT', value: '384' },
    '463': { label: '463: INDIANAPOLIS', value: '463' },
    '611': { label: '611: SPRINGFIELD', value: '611' },
    '620': { label: '620: ALLENTOWN', value: '620' },
    '624': { label: '624: INDIANAPOLIS', value: '624' },
    '625': { label: '625: DETROIT', value: '625' },
    '642': { label: '642: GRAND RAPIDS', value: '642' },
    '661': { label: '661: DETROIT', value: '661' },
    '704': { label: '704: CHARLOTTE', value: '704' },
    '863': { label: '863: LAKELAND', value: '863' },
    '944': { label: '944: DAYTON', value: '944' },
    '946': { label: '946: CLEVELAND', value: '946' }
};
export interface ProbillsRouteModel {
    startDate: string,
    startTime: string,
    routeDuration: string,
    trailerNumber: number,
    trailerCapacity: string,
    trailerType?: string,
    defaultStopTime?: string,
    routeName?: string
};

export const timeOptions = [
    { label: "0:00 AM", value: "000000" },
    { label: "0:30 AM", value: "003000" },
    { label: "1:00 AM", value: "010000" },
    { label: "1:30 AM", value: "013000" },
    { label: "2:00 AM", value: "020000" },
    { label: "2:30 AM", value: "023000" },
    { label: "3:00 AM", value: "030000" },
    { label: "3:30 AM", value: "033000" },
    { label: "4:00 AM", value: "040000" },
    { label: "4:30 AM", value: "043000" },
    { label: "5:00 AM", value: "050000" },
    { label: "5:30 AM", value: "053000" },
    { label: "6:00 AM", value: "060000" },
    { label: "6:30 AM", value: "063000" },
    { label: "7:00 AM", value: "070000" },
    { label: "7:30 AM", value: "073000" },
    { label: "8:00 AM", value: "080000" },
    { label: "8:30 AM", value: "083000" },
    { label: "9:00 AM", value: "090000" },
    { label: "9:30 AM", value: "093000" },
    { label: "10:00 AM", value: "100000" },
    { label: "10:30 AM", value: "103000" },
    { label: "11:00 AM", value: "110000" },
    { label: "11:30 AM", value: "113000" },
    { label: "12:00 AM", value: "120000" },
    { label: "12:30 PM", value: "123000" },
    { label: "1:00 PM", value: "130000" },
    { label: "1:30 PM", value: "133000" },
    { label: "2:00 PM", value: "140000" },
    { label: "2:30 PM", value: "143000" },
    { label: "3:00 PM", value: "150000" },
    { label: "3:30 PM", value: "153000" },
    { label: "4:00 PM", value: "160000" },
    { label: "4:30 PM", value: "163000" },
    { label: "5:00 PM", value: "170000" },
    { label: "5:30 PM", value: "173000" },
    { label: "6:00 PM", value: "180000" },
    { label: "6:30 PM", value: "183000" },
    { label: "7:00 PM", value: "190000" },
    { label: "7:30 PM", value: "193000" },
    { label: "8:00 PM", value: "200000" },
    { label: "8:30 PM", value: "203000" },
    { label: "9:00 PM", value: "210000" },
    { label: "9:30 PM", value: "213000" },
    { label: "10:00 PM", value: "220000" },
    { label: "10:30 PM", value: "223000" },
    { label: "11:00 PM", value: "230000" },
    { label: "11:30 PM", value: "233000" }
];
export const trailerCapacityOptions = [
    { label: "20,000", value: "20000" },
    { label: "40,000", value: "40000" },
    { label: "44,000", value: "44000" }
];
export const defaultStopTimeOptions = [
    { label: "10 Mins", value: "000600" },
    { label: "15 Mins", value: "000900" },
    { label: "20 Mins", value: "001200" },
    { label: "25 Mins", value: "001500" },
    { label: "30 Mins", value: "001800" }
];
export const routeDurationOptions = [
    { label: "6 hours", value: "060000" },
    { label: "8 hours", value: "080000" },
    { label: "10 hours", value: "100000" },
    { label: "12 hours", value: "120000" },
    { label: "14 hours", value: "140000" }
];
export const initialFormValues: any = {
    startDate: "",
    startTime: "",
    trailerNumber: "",
    trailerCapacity: "",
    trailerType: "",
    defaultStopTime: ""
}

export const ProbillsRouteSchema: Yup.ObjectSchema<ProbillsRouteModel> = Yup.object().shape({
    routeName: Yup.string()
        .max(20, "The value must be less then 20 characters"),
    startDate: Yup.string().required(validationMessages.required),
    startTime: Yup.string().required(validationMessages.required),
    routeDuration: Yup.string().required(validationMessages.required),
    trailerNumber: Yup.number()
        .max(999999, "The value must be less then 6 digits")
        .required(validationMessages.required),
    trailerCapacity: Yup.string()
        .max(999999, "The value must be less then 6 digits")
        .required(validationMessages.required),
    trailerType: Yup.string()
        .max(25, "The value must be less then 25 characters"),
    defaultStopTime: Yup.string().required(validationMessages.required)
}).defined();
