import React, { HTMLProps, memo } from "react";
import { FieldInputProps } from "formik";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Loading from "../loading/loading";
import XGSIcon from "../icon/xgsIcon";
import XGSRegularIcons from "../icon/xgsRegularIcons";
import "./xgsInput.scss";

export interface XGSInputBaseProps {
  fields?: FieldInputProps<any>;
  clearable?: boolean;
  onClear?: () => void;
  isFailed?: () => boolean;
  appendIcon?: IconProp;
  appendIconExtraClassName?: string;
  appendAction?: () => void;
  appendCustomIcon?: React.Node;
  prependText?: string;
  isLoading?: boolean;
}

export interface XGSInputProps
  extends XGSInputBaseProps, HTMLProps<HTMLInputElement> {
}

const XGSInput: React.FC<XGSInputProps> = memo(({
  fields,
  isFailed,
  clearable,
  onClear,
  appendIcon,
  appendIconExtraClassName,
  appendAction,
  appendCustomIcon,
  isLoading,
  ...props
}) => {
  return (
    <div className={`xgs-input${props.prependText ? " xgs-input--w-prepend-text" : ""}`}>
      {props.prependText && (
        <div className="xgs-input__prepend-text">
          {props.prependText}
        </div>
      )}
      <input
        {...fields}
        {...props}
        className={`xgs-input__field ${isFailed && isFailed() ? "not-valid" : ""} ${(!clearable && !appendIcon && !appendCustomIcon) ? "" : "xgs-input__field--w-icon"}`}
      />
      {isLoading && (
        <div className="xgs-input__field__spinner">
          <Loading isLoading={true} />
        </div>
      )}
      {clearable && props.value && (
        <XGSIcon
          icon={XGSRegularIcons.faTimesCircle}
          className="xgs-input__append-button"
          onClick={onClear}
        />
      )}
      {!clearable && appendIcon && !appendCustomIcon && (
        <XGSIcon
          icon={appendIcon}
          className={`xgs-input__append-button ${appendIconExtraClassName ? appendIconExtraClassName : ""}`}
          onClick={() => {
            appendAction && appendAction();
          }}
        />
      )}
      {!clearable && !appendIcon && appendCustomIcon && (
        <span className="xgs-input__append-custom-icon">
          {appendCustomIcon}
        </span>
      )}
    </div>
  );
});

export default XGSInput;
