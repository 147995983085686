
export default interface CompanySettingShippersState {
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError?: string | null;
  requestCreator: string | null;
  shippers: any[];
}

export const initialCompanySettingsShippersState: CompanySettingShippersState = {
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestError: null,
  requestCreator: null,
  shippers: []
};
