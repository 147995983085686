import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import PlanProbillsService from "../../app/data/plan-probills/planProbillsService";
import {  DispatchEditRouteDetailsState } from "./dispatchProbillsState";
import { getActiveRoutes } from "./routes/activeRoutesSlice";

const initialState: DispatchEditRouteDetailsState = {
  loading: false,
  loadingFailed: false,
  loaded: false,
  error: ""
};

const planProbillsService = PlanProbillsService.getInstance();

export const dispatchEditRouteDetailsSlice = createSlice({
  name: "dispatchEditRouteDetails",
  initialState,
  reducers: {
    resetState: (state) => initialState,
    loadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
    },
    loadingSucceed: (state) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
    },
    loadingFailed: (state, {payload}) => {
      state.loading = false;
      state.loadingFailed = true;
      state.error = payload;
    },
  },
});

export const {
  resetState,
  loadingStarted,
  loadingSucceed,
  loadingFailed
} = dispatchEditRouteDetailsSlice.actions;

export const dispatchEditRouteDetailsSelector = (state: IState) => state.dispatchEditRouteDetails;

export const editRouteDetails = (
  routeRequest: any,
  onSuccess?: () => void,
  onError?: (error: string) => void,
  onClose?: () => void
): AppThunk => async (dispatch) => {
  dispatch(loadingStarted());
  const response = await planProbillsService.editRouteDetails({ ...routeRequest });
  if (response.ok() && response.data && response.data.result) {
    dispatch(loadingSucceed());
    dispatch(getActiveRoutes({ ...routeRequest}));
    onSuccess && onSuccess();
    // onClose && onClose()
  } else {
    let error = "Internal Server Error, re-try the action or contact support";
    if(response && response.data && response.data.error) error = response.data.error;
    dispatch(loadingFailed(error));
    onError && onError(error)
  }
};

const dispatchEditRouteDetailsReducer = dispatchEditRouteDetailsSlice.reducer;
export default dispatchEditRouteDetailsReducer;