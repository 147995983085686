import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Lightbox from "react-spring-lightbox";
import InvoiceRecordsState from "../../../../slices/invoice-records/InvoiceRecordsState";
import {
  getInvoiceRecords,
  invoiceRecordsSelector,
  resetInvoiceRecords
} from "../../../../slices/invoice-records/invoiceRecordsSlice";
import ConfirmationModal from "../../../../ui-components/confirmation-modal/confirmationModal";
import Loading from "../../../../ui-components/loading/loading";
import XGSIcon from "../../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../../ui-components/icon/xgsIcons";
import "./invoiceDetailsRecords.scss";

export interface InvoiceDetailsRecordsProps {
  invoiceNumber: number;
};

const InvoiceDetailsRecords: React.FC<InvoiceDetailsRecordsProps> = (props) => {
  const [gridComment, setGridComment] = useState<string>("");
  const [gridCommentOpen, setGridCommentOpen] = useState<boolean>(false);
  const [galleryImages, setGalleryImages] = useState<any>([]);
  const [showGallery, setShowGallery] = useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const invoiceRecordsState: InvoiceRecordsState = useSelector(invoiceRecordsSelector);
  const dispatch = useDispatch();

  const gotoPreviousImage = () => {
    currentImageIndex > 0 && setCurrentImageIndex(currentImageIndex - 1);
  };

  const gotoNextImage = () => {
    (currentImageIndex + 1 < galleryImages.length) && setCurrentImageIndex(currentImageIndex + 1);
  };

  useEffect(() => {
    if (props.invoiceNumber) dispatch(getInvoiceRecords(props.invoiceNumber));
  }, [dispatch, props.invoiceNumber]);

  useEffect(() => {
    return () => {
      dispatch(resetInvoiceRecords());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!props.invoiceNumber || !invoiceRecordsState.records) return;
    let galleryImagesArr: any = [];
    for (const record of invoiceRecordsState.records) {
      if (record.items) {
        const itemsArr = record.items.map((item: any) => {
          return {
            id: item.id,
            src: item.storageUrl,
            date: record.dateCreated
          }
        });
        galleryImagesArr.push(...itemsArr);
      }
    }
    setGalleryImages(galleryImagesArr);
  }, [props.invoiceNumber, invoiceRecordsState.records]);
  
  return (
    <>
      {!(invoiceRecordsState.requestFailed && invoiceRecordsState.requestCreator === "list" && invoiceRecordsState.requestError === "Access is denied") && (
        <div className="xgs-invoice-records">
          {invoiceRecordsState.requestStarted && (
            <div className="xgs-invoice-records__loading">
              <Loading isLoading={true} />
            </div>
          )}
          {(invoiceRecordsState.requestSucceed || invoiceRecordsState.records) && (
            <>
              {(!invoiceRecordsState.records || invoiceRecordsState.records.length === 0) && (
                <div className="xgs-invoice-records__no-items">There are no images</div>
              )}
              {(invoiceRecordsState.records && invoiceRecordsState.records.length > 0) && (
                <div className="xgs-invoice-records__items">
                  {invoiceRecordsState.records && invoiceRecordsState.records.length > 0 && invoiceRecordsState.records.map((record: any, recordIndex: number) =>
                    <div className="xgs-invoice-records__item" key={record.id}>
                      <div className="xgs-invoice-records__item__row">
                        <div className="xgs-invoice-records__item__row__header">Date:</div>
                        <div className="xgs-invoice-records__item__row__value">{record.dateCreated.toUiDateFormat()} {record.dateCreated.split("T")[1]}</div>
                      </div>
                      {record.summary && (
                        <>
                          <div className="xgs-invoice-records__item__row">
                            <div className="xgs-invoice-records__item__row__header">Comments:</div>
                            <div className="xgs-invoice-records__item__row__value">{record.summary}</div>
                          </div>
                        </>
                      )}
                      <div className="xgs-invoice-records__item__row">
                        <div className="xgs-invoice-records__item__row__header">Photos:</div>
                        <div className="xgs-invoice-records__item__photos">
                          {record.items.map((photo: any, i: number) =>
                            <div
                              className="xgs-invoice-records__photo"
                              key={`photo.name-${i}`}
                            >
                              <div
                                className="xgs-invoice-records__photo__preview"
                                onClick={() => {
                                  setCurrentImageIndex(galleryImages.findIndex((imageObj: any) => imageObj.id === photo.id));
                                  setShowGallery(true);
                                }}
                              >
                                <img
                                  src={`data:image/jpeg;base64,${photo.preview}`}
                                  className="xgs-invoice-records__photo__preview__image"
                                  alt="Preview"
                                  key={photo.id}
                                />
                                {photo.comment && (
                                  <div
                                    className="xgs-invoice-records__photo__preview__grid-comments"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setGridComment(photo.comment);
                                      setGridCommentOpen(true);
                                    }}
                                  >
                                    <XGSIcon
                                      icon={XGSIcons.faCommentAlt}
                                      className="xgs-invoice-records__photo__preview__grid-comments__icon"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <ConfirmationModal
                    opened={gridCommentOpen}
                    header="Comments"
                    confirmButtonText="Close"
                    onConfirm={() => {
                      setGridCommentOpen(false);
                      setGridComment("");
                    }}
                  >
                    <div>{gridComment}</div>
                  </ConfirmationModal>
                </div>
              )}
            </>
          )}
          {invoiceRecordsState.requestFailed && invoiceRecordsState.requestCreator === "list" && (
            <div className="xgs-invoice-records__error">
              {invoiceRecordsState.requestError || "Error while loading images."}
            </div>
          )}
          <Lightbox
            isOpen={showGallery}
            onPrev={gotoPreviousImage}
            onNext={gotoNextImage}
            images={galleryImages}
            currentIndex={currentImageIndex}
            className="xgs-gallery"
            renderHeader={() => (
              <div className="xgs-gallery__header">
                <div className="xgs-gallery__header__text">{galleryImages[currentImageIndex] ? galleryImages[currentImageIndex].date.toUiDateTimeFormat() : ""}</div>
                <XGSIcon
                  icon={XGSIcons.faTimes}
                  size="2x"
                  className="xgs-gallery__header__close-icon"
                  onClick={() => setShowGallery(false)}
                />
              </div>
            )}
            renderPrevButton={() => (
              <>
                {currentImageIndex > 0 && (
                  <div className="xgs-gallery__prev">
                    <XGSIcon
                      icon={XGSIcons.faChevronLeft}
                      size="2x"
                      className="xgs-gallery__prev__icon"
                      onClick={() => gotoPreviousImage()}
                    />
                  </div>
                )}
              </>
            )}
            renderNextButton={() => (
              <>
                {(currentImageIndex + 1 < galleryImages.length) && (
                  <div className="xgs-gallery__next">
                    <XGSIcon
                      icon={XGSIcons.faChevronRight}
                      size="2x"
                      className="xgs-gallery__next__icon"
                      onClick={() => gotoNextImage()}
                    />
                  </div>
                )}
              </>
            )}
            singleClickToZoom
          />
        </div>
      )}
    </>
  );
};

export default InvoiceDetailsRecords;
