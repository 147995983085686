import React, { useEffect, useState } from "react";
import SlideOutSidebar from "../../ui-components/slide-out-sidebar/slideOutSidebar";
import Table from "../../ui-components/table/table";
import { getLaneColumns } from "./getLaneColumns";
import { useDispatch, useSelector } from "react-redux";
import { assignLaneToTripLocally, assignLinehaulLane, linehaulMileageSelector } from "../../slices/linehaul-mileage/linehaulMileageSlice";
import { LinehaulLane, LinehaulTrip } from "../../app/data/linehaul-mileage/models";
import { toast } from "react-toastify";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import { AddLane } from "./addLane";

export interface LaneAssignmentProps {
  show: boolean;
  closeModal: any;
  trip?: LinehaulTrip;
  originTerminalNumber: number;
  destinationTerminalNumber: number;
}

export const LaneAssignment: React.FC<LaneAssignmentProps> = (props) => {
  const linehaulMileageState = useSelector(linehaulMileageSelector);
  const [selectedLaneId, setSelectedLaneId] = useState<string | null>();
  const [addNewLane, setAddNewLane] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedLaneId(null);
    setAddNewLane(false);
  }, [props.show]);

  useEffect(() => {
    if (!props.trip) return;
    setSelectedLaneId(props.trip.lane?.laneId)
  }, [props.trip])

  const onClickAssign = () => {
    dispatch(
      assignLinehaulLane(
        { laneId: selectedLaneId!, tripId: props.trip?.tripId!},
        () => {
          toast.info("Lane assigned.");
          dispatch(assignLaneToTripLocally({ lane: linehaulMileageState.lanes.find(lane => lane.laneId === selectedLaneId), tripId: props.trip?.tripId!}))
          props.closeModal();
        },
        (error: any) => toast.error(error || "Something went wrong")
      ))
  }

  return (
    <SlideOutSidebar header="Assign Lane" show={props.show} onClose={props.closeModal}>
      <div className="xgs-lmi__details">
        <div className="xgs-lmi__title">
          Assign Lane
          <Button style={{ marginLeft: "auto" }} onClick={() => setAddNewLane(true)} theme={ButtonThemes.gray}>Add New Lane</Button>
        </div>
        <div className="xgs-lmi__details__assign-lane__table">
          <Button 
            disabled={linehaulMileageState.loadingLanes || !selectedLaneId} 
            spinner={linehaulMileageState.requestStarted} 
            theme={ButtonThemes.blue} 
            onClick={onClickAssign}
          >
            Assign Lane
          </Button>
          <Table
            isLoading={linehaulMileageState.loadingLanes}
            columns={getLaneColumns(selectedLaneId)}
            // responsive
            data={linehaulMileageState.lanes}
            onRowClicked={(row: LinehaulLane) => setSelectedLaneId(row.laneId)}
            cursorPointer
            infiniteScrollHasNext={false}
            infiniteScroll
            rowHeight={60}
          />
        </div>
      </div>
      {!!addNewLane && (
        <AddLane
          show={addNewLane}
          closeModal={() => setAddNewLane(false)}
          savedValues={
            {
              originTerminalNumber: props.originTerminalNumber,
              destinationTerminalNumber: props.destinationTerminalNumber
            }
          }
        />)
      }
    </SlideOutSidebar>
  )
}