import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UserState from "../slices/user/UserState";
import { useSelector } from "react-redux";
import { userSelector } from "../slices/user/userSlice";

export const useSearchParam = <Key extends string>(path: string, paramName: Key) => {
  const params = useParams() as { [x in Key]: string };
  const [param, setParam] = useState<number | string>();
  const userState: UserState = useSelector(userSelector);

  const setSearchParam = (id: number | string) => {
    setParam(id);
    window.history.replaceState("", "", "/" + userState.activeSubAccount?.accountNumber + path + "/" + id);
  };

  const resetSearchParam = () => {
    setParam(undefined);
    window.history.replaceState("", "", "/" + userState.activeSubAccount?.accountNumber + path);
  };

  useEffect(() => {
    if (!params[paramName]) return;
    setParam(params[paramName]);
  }, [params, paramName]);

  return { param, setSearchParam, resetSearchParam };
}