import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Wizard, Steps, Step } from "react-albus";
import SelectAddressStep from "./steps/selectAddressStep";
import ConfirmShipperStep from "./steps/confirmShipperStep";
import { resetShipper } from "../../slices/shipper/shipperSlice";
import { CreateShipperModel } from "../../app/data/shipper/models";

import "./shipperCreate.scss";
import "../xgs-wizard/wizard.scss";

export interface ShipperCreateProps {
  onCreate: (shipper: CreateShipperModel) => void;
  onZipChange?: (zip: string) => void;  
  isLoading?: boolean;
  error?: string;
};

const ShipperCreate: React.FC<ShipperCreateProps> = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => { dispatch(resetShipper()); };
  }, [dispatch]);

  return (
    <div className="shipper-create wizard">
      <Wizard
        render={({ steps, }) => (
          <Steps>
            <Step
              id="pickup-shipper-step"
              render={({ next }) => (
                <SelectAddressStep next={next} steps={steps} {...props}/>
              )}
            />            
            <Step
              id="pickup-items-step"
              render={({ previous }) => (
                <ConfirmShipperStep previous={previous} steps={steps} onCreate={props.onCreate}/>
              )}
            />
          </Steps>
        )}
      />
    </div>
  );
};

export default ShipperCreate;
