import React, { useState } from "react"
import { cn } from "../../../../services/common/className"
import Tag from "../../../../ui-components/molecules/tag/tag";
import { PicklistDetailItem } from "../../../../app/data/picklists/models";
import XGSIcon from "../../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../../ui-components/icon/xgsIcons";
import moment from "moment";

export interface PicklistItemProps {
  picklistItem: PicklistDetailItem
}

const PicklistItem: React.FC<PicklistItemProps> = ({ picklistItem }) => {
  const [showMoreDetails, setShowMoreDetails] = useState<boolean>(false);

  const formatLastChangedAt = (updatedAt: string) => {
    if (!updatedAt) return "";
    let lastChangedAt = moment(updatedAt, "MMM D YYYY hh:mm");
    return `${lastChangedAt.format("MMM D, YYYY")} ${lastChangedAt.format("h:mm A")}`;
  }

  return (
    <div className="picklist-details__loading-task__picklist-item">
      <div className="picklist-details__loading-task__picklist-item__title">
        {(picklistItem.itemSequenceNumber === null) ? <Tag mix={cn("picklist-details__loading-task__header__identifiers__item__status")("missing-item")}>
          NO LINE ITEM
        </Tag> :
          <span className="picklist-details__loading-task__picklist-item__title__seq-no">{picklistItem.itemSequenceNumber}</span>
        }
        {picklistItem.itemDesc} - <span className="picklist-details__loading-task__picklist-item--text-light">{picklistItem.itemNumberType}</span>{picklistItem.itemNumber}
        {picklistItem.isLoaded && <Tag mix={cn("picklist-details__loading-task__header__identifiers__item__status")("loaded")}>
          LOADED
        </Tag>}
      </div>
      <div className="picklist-details__loading-task__picklist-item__base-properties">
        <div className="picklist-details__loading-task__picklist-item__base-properties__item">
          <span className="picklist-details__loading-task__picklist-item--text-light">
            Location:
          </span>
          {picklistItem.location}
        </div>
        <div className="picklist-details__loading-task__picklist-item__base-properties__item">
          <span className="picklist-details__loading-task__picklist-item--text-light">
            Status:
          </span>
          {picklistItem.status}
        </div>
        <div className="picklist-details__loading-task__picklist-item__base-properties__item">
          <span className="picklist-details__loading-task__picklist-item--text-light">
            Last Changed at:
          </span>
          {formatLastChangedAt(picklistItem.updatedAt)}
        </div>
        <div className="picklist-details__loading-task__picklist-item__base-properties__item">
          <span className="picklist-details__loading-task__picklist-item--text-light">
            Last Changed by:
          </span>
          {picklistItem.updatedBy}
        </div>
      </div>
      <div
        className="picklist-details__loading-task__picklist-item__is-show-details"
        onClick={() => setShowMoreDetails(!showMoreDetails)}>
        Show {(showMoreDetails) ? " less" : " more"}
      </div>
      {showMoreDetails &&
        <ul className="picklist-details__loading-task__picklist-item__additional-details">
          <li className="picklist-details__loading-task__picklist-item__additional-details__ele">
            <div className="picklist-details__loading-task__picklist-item__additional-details__ele__title">
              <XGSIcon
                className="picklist-details__loading-task__picklist-item__additional-details__ele__title__icon"
                icon={XGSIcons.faCircle}
              />Item Identifiers
            </div>
            <div className="picklist-details__loading-task__picklist-item__additional-details__ele__content">
              <div className="picklist-details__loading-task__picklist-item__additional-details__ele__content__pair">
                <span className="picklist-details__loading-task__picklist-item--text-light">
                  SKU:
                </span>
                {picklistItem.sku}
              </div>
              <div className="picklist-details__loading-task__picklist-item__additional-details__ele__content__pair">
                <span className="picklist-details__loading-task__picklist-item--text-light">
                  Roll Number:
                </span>
                {picklistItem.rollNumber}
              </div>
              <div className="picklist-details__loading-task__picklist-item__additional-details__ele__content__pair">
                <span className="picklist-details__loading-task__picklist-item--text-light">
                  Serial Number:
                </span>
                {picklistItem.serialNumber}
              </div>
              <div className="picklist-details__loading-task__picklist-item__additional-details__ele__content__pair">
                <span className="picklist-details__loading-task__picklist-item--text-light">
                  Item Description:
                </span>
                {picklistItem.itemDesc}
              </div>
              <div className="picklist-details__loading-task__picklist-item__additional-details__ele__content__pair">
                <span className="picklist-details__loading-task__picklist-item--text-light">
                  Commodity Code:
                </span>
                {picklistItem.commodityCode}
              </div>
              <div className="picklist-details__loading-task__picklist-item__additional-details__ele__content__pair">
                <span className="picklist-details__loading-task__picklist-item--text-light">
                  Color:
                </span>
                {picklistItem.color}
              </div>
              <div className="picklist-details__loading-task__picklist-item__additional-details__ele__content__pair">
                <span className="picklist-details__loading-task__picklist-item--text-light">
                  Style:
                </span>
                {picklistItem.style}
              </div>
              <div className="picklist-details__loading-task__picklist-item__additional-details__ele__content__pair">
                <span className="picklist-details__loading-task__picklist-item--text-light">
                  Dyelot:
                </span>
                {picklistItem.dyelot}
              </div>
              <div className="picklist-details__loading-task__picklist-item__additional-details__ele__content__pair">
                <span className="picklist-details__loading-task__picklist-item--text-light">
                  PO #:
                </span>
                {picklistItem.itemPoNumber}
              </div>
              <div className="picklist-details__loading-task__picklist-item__additional-details__ele__content__pair">
                <span className="picklist-details__loading-task__picklist-item--text-light">
                  Part Number:
                </span>
                {picklistItem.partNumber}
              </div>
              <div className="picklist-details__loading-task__picklist-item__additional-details__ele__content__pair">
                <span className="picklist-details__loading-task__picklist-item--text-light">
                  Sidemark:
                </span>
                {picklistItem.sidemark}
              </div>
            </div>
          </li >
          <li className="picklist-details__loading-task__picklist-item__additional-details__ele">
            <div className="picklist-details__loading-task__picklist-item__additional-details__ele__title">
              <XGSIcon
                className="picklist-details__loading-task__picklist-item__additional-details__ele__title__icon"
                icon={XGSIcons.faCircle}
              />Physical Properties
            </div>
            <div className="picklist-details__loading-task__picklist-item__additional-details__ele__content">
              <div className="picklist-details__loading-task__picklist-item__additional-details__ele__content__pair">
                <span className="picklist-details__loading-task__picklist-item--text-light">
                  Type:
                </span>
                {picklistItem.type}
              </div>
              <div className="picklist-details__loading-task__picklist-item__additional-details__ele__content__pair">
                <span className="picklist-details__loading-task__picklist-item--text-light">
                  Pieces:
                </span>
                {picklistItem.pieces}
              </div>
              <div className="picklist-details__loading-task__picklist-item__additional-details__ele__content__pair">
                <span className="picklist-details__loading-task__picklist-item--text-light">
                  Weight:
                </span>
                {picklistItem.weight}
              </div>
              <div className="picklist-details__loading-task__picklist-item__additional-details__ele__content__pair">
                <span className="picklist-details__loading-task__picklist-item--text-light">
                  Square Yards:
                </span>
                {picklistItem.squareYards}
              </div>
              <div className="picklist-details__loading-task__picklist-item__additional-details__ele__content__pair">
                <span className="picklist-details__loading-task__picklist-item--text-light">
                  Length:
                </span>
                {picklistItem.length}
              </div>
              <div className="picklist-details__loading-task__picklist-item__additional-details__ele__content__pair">
                <span className="picklist-details__loading-task__picklist-item--text-light">
                  Width:
                </span>
                {picklistItem.width}
              </div>
              <div className="picklist-details__loading-task__picklist-item__additional-details__ele__content__pair">
                <span className="picklist-details__loading-task__picklist-item--text-light">
                  Height:
                </span>
                {picklistItem.height}
              </div>

            </div>
          </li>
        </ul>
      }
    </div >
  )
}

export default PicklistItem