import React from "react";
import XGSPolyline from "../../../ui-components/map/polyline/polyline";
import XGSCustomMarker from "../../../ui-components/map/custom-marker/customMarker";
import { DriversResponseContentModel } from "../../../app/data/pickup/models";
import { TRUCK_MARKER, getStopFillColor, getStopLabelProps, } from "../../../services/map";
import { PickupStatus, StopType } from "../../../app/data/common/route";
import XGSMarkerTooltip from "../../../ui-components/map/marker-tooltip/markerTooltip";
import StopMarkerInfo from "../../../ui-components/map/stop-marker-info/stopMarkerInfo";
import { formatAddress } from "../../../services/common/address";

export interface DriverRouteProps {
  map?: google.maps.Map;
  driver: DriversResponseContentModel;
  showAllRoutes?: boolean;
  onClick?: () => void
};

const DriverRoute: React.FC<DriverRouteProps> = ({ driver, onClick, map, showAllRoutes, ...props }) => {
  return (
    <>
      {driver.polyline && <XGSPolyline map={map} polylineData={driver.polyline} onClick={onClick}/>}
      
      {driver?.stops && driver.stops.map((stop, i) => (
        <XGSCustomMarker
          key={`m-${driver.driverId}-${i}`}
          map={map}
          position={{
            lat: Number(stop.address.lat),
            lng: Number(stop.address.lng)
          }}
          label={{
            text: `${stop.type === "DELIVERY" ? stop.order : "SC"}`,
            ...getStopLabelProps(stop.type === "DELIVERY" ? stop.status : StopType.SERVICE_CENTER),
          }}
          fillColor={getStopFillColor(stop.type === "DELIVERY" ? StopType.STOP : StopType.SERVICE_CENTER, stop.status)}
          tooltip={<XGSMarkerTooltip><StopMarkerInfo info={{ address: formatAddress(stop.address), driver: showAllRoutes ? driver.driverName : "" }} /></XGSMarkerTooltip>}
          onClick={onClick}
        />
      ))}

      {!!driver?.pickups?.length && driver.pickups.map((pickup, i) => (
        <XGSCustomMarker
          key={pickup.pickupNumber}
          map={map}
          position={{
            lat: pickup.shipper.address.geolocation.lat,
            lng: pickup.shipper.address.geolocation.lng,
          }}
          label={{
            text: "PU",
            ...getStopLabelProps(StopType.PICKUP),
          }}
          fillColor={getStopFillColor(StopType.PICKUP, PickupStatus[pickup.pickupStatus])}
          tooltip={<XGSMarkerTooltip><StopMarkerInfo info={{ address: formatAddress(pickup.shipper.address), driver: showAllRoutes ? driver.driverName : "" }} /></XGSMarkerTooltip>}
          onClick={onClick}
        />
      ))}

      {driver?.driverLocation && (
        <XGSCustomMarker   
          map={map}       
          position={{
            lat: driver.driverLocation.lat,
            lng: driver.driverLocation.lng
          }}
          path={TRUCK_MARKER.PATH}
          fillColor={TRUCK_MARKER.COLOR}
          tooltip={<XGSMarkerTooltip><StopMarkerInfo info={{ address: driver.driverLocation.formattedAddress, driver: showAllRoutes ? driver.driverName : "" }} /></XGSMarkerTooltip>}
          onClick={onClick}
        />
      )}
    </>
  );
};

export default DriverRoute;
