import axios from "axios";
import api, { ApiResponse } from "./../api";
import { 
  CustomerPickupsFilterModel,
  CustomerPickupsResponseModel,
} from "./models";

export const REQUEST_ID: {
  [key: string]: string;
} = {
  GET_PICKUPS: "get_pickups",
};

class CustomerPickupService {
  private source: any;
  private static instance: CustomerPickupService;
  private constructor() {
    this.source = {};
  }
  static getInstance(): CustomerPickupService {
    if (!CustomerPickupService.instance) {
      CustomerPickupService.instance = new CustomerPickupService();
    }
    return CustomerPickupService.instance;
  };

  public cancelRequest = (requestId: string) => {
    this.source[requestId] && this.source[requestId].cancel();
  }  

  public getPickups = async (filter?: CustomerPickupsFilterModel, page?: number): Promise<ApiResponse<CustomerPickupsResponseModel>> => {
    const params = new URLSearchParams();
    filter?.pickupNumber && params.append("pickupNumber", filter.pickupNumber);
    filter?.dateFrom && params.append("dateFrom", filter.dateFrom.toApiDateFormat());
    filter?.dateTo && params.append("dateTo",filter.dateTo.toApiDateFormat());
    filter?.statuses && params.append("statuses", filter.statuses);
    page && params.append("page", page);
    this.cancelRequest(REQUEST_ID.GET_PICKUPS);
    this.source[REQUEST_ID.GET_PICKUPS] = axios.CancelToken.source();
    return await api.get("/pickups/customer", { params, cancelToken: this.source[REQUEST_ID.GET_PICKUPS].token });
  };
};

export default CustomerPickupService;