import api, { ApiResponse } from "../api";
import StatusCheckSubAccount from "./StatusCheckSubAccount";

class AccountService {
  private static instance: AccountService;

  private constructor() {}

  static getInstance(): AccountService {
    if (!AccountService.instance) {
      AccountService.instance = new AccountService();
    }

    return AccountService.instance;
  }

  public getStatusCheckAccounts = async (): Promise<
    ApiResponse<StatusCheckSubAccount>
  > => {
    return await api.get("/accounts/status");
  };
}

export default AccountService;
