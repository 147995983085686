import React from "react";
import Helmet from "react-helmet";
import { matchPath } from "react-router-dom";
import { breadcrumbsRoutes } from "../../app/data/common/titles";

const DocumentTitle: React.FC<{}> = () => {
  const title = () => {
    const currentPath = window.location.pathname;
    return breadcrumbsRoutes.find(breadcrumb =>
      matchPath(currentPath, {
        path: breadcrumb.path,
        exact: true,
        strict: false
      })
    )?.breadcrumb || "";
  };

  return (
    <Helmet title={title()} />
  );
}

export default DocumentTitle;
