import api, { ApiResponse } from "../api";
import {
  InviteSendModel
} from "./models";

class InviteManagementService {
  private static instance: InviteManagementService;
  private constructor() {}

  static getInstance(): InviteManagementService {
    if (!InviteManagementService.instance) {
      InviteManagementService.instance = new InviteManagementService();
    }
    return InviteManagementService.instance;
  }

  public sendUserInvite = async (
    request: InviteSendModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/users/invite", request);
  }; 

  public resendInvite = async (
    email: string
  ): Promise<ApiResponse<any>> => {
    return await api.post("/users/invite/resend", { email });
  };

  public cancelInvite = async (
    email: string
  ): Promise<ApiResponse<any>> => {
    return await api.post("/users/invite/cancel", { email });
  };
}

export default InviteManagementService;
