import api, { ApiResponse } from "./../api";
import { SubscriptionModel } from "./models"

class SubscriptionsService {
  private static instance: SubscriptionsService;
  private constructor() {}
  static getInstance(): SubscriptionsService {
    if (!SubscriptionsService.instance) {
      SubscriptionsService.instance = new SubscriptionsService();
    }
    return SubscriptionsService.instance;
  }

  public getSubscriptions = async (): Promise<ApiResponse<SubscriptionModel[]>> => {
    return await api.get("/notifications/probill/subscriptions");
  };

  public subscribeProbill = async (
    probill: number,
    timezone: string
  ): Promise<ApiResponse<any>> => {
    return await api.post("/notifications/probill", { timezone, probill });
  };

  public unsubscribeProbill = async (
    probill: number
  ): Promise<ApiResponse<any>> => {
    return await api.delete("/notifications/probill", { data: { probill } });
  };
}

export default SubscriptionsService;
