import moment from "moment";
import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { initialCustomerPickupState } from "./customerPickupState";
import { AppThunk } from "../../app/store";
import CustomerPickupService from "../../app/data/customer-pickup/customerPickupService";
import { CustomerPickupsFilterModel } from "../../app/data/customer-pickup/models";

const customerPickupService = CustomerPickupService.getInstance();

export const customerPickupSlice = createSlice({
  name: "customerPickup",
  initialState: initialCustomerPickupState,
  reducers: {
    resetCustomerPickupState: () => initialCustomerPickupState,
    requestStarted: (state, { payload }) => {
      if (payload === "GET_PICKUPS_PORTION") {
        state.fetchPortionStarted = true;
      } else {
        state.requestStarted = true;
      }
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestCreator = payload;
      state.requestError = "";
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.fetchPortionStarted = false;
      state.requestSucceed = true;
      state.requestFailed = false;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestFailStatus = payload.status;
      state.requestError = payload.error;
    },
    setPickups: (state, { payload }) => {
      state.pickups = payload.content;
    },
    addPickups: (state, { payload }) => {
      state.pickups = [...state.pickups, ...payload.content];      
    },
    setPickupsRequest: (state, { payload }) => {
      state.pickupsRequest = {
        page: payload.page,
        last: payload.last
      }
    },
    updateCustomerPickup: (state, { payload }) => {
      const pickups = [...state.pickups];
      const pickupIndex = pickups.findIndex((pickup) => pickup.id === payload.id);
      if (pickupIndex === -1) return;
      pickups[pickupIndex].id = payload.newPickupId;
      pickups[pickupIndex].pickupId = payload.newPickupId;
      pickups[pickupIndex].pickupNumber = payload.pickupNumber;
      pickups[pickupIndex].pickupDate = moment(`${payload.pickupDate} ${payload.readyTime}`, "MM/DD/YYYY h:mm a").format("YYYY-MM-DDTHH:mm:ss");
      pickups[pickupIndex].pickupReadyTime = moment(payload.readyTime, "h:mm a").format("HH:mm:ss");
      pickups[pickupIndex].pickupCloseTime = moment(payload.closeTime, "h:mm a").format("HH:mm:ss");
      state.pickups  = [...pickups];
    },
    updateCustomerPickupStatus: (state, { payload }) => {
      const pickups = [...state.pickups];
      const pickupIndex = pickups.findIndex((pickup) => pickup.id === payload.id);
      if (pickupIndex === -1) return;
      pickups[pickupIndex].pickupStatus = payload.status;
      state.pickups  = [...pickups];
    }
  }
});

export const {
  resetCustomerPickupState,
  requestStarted,
  requestSucceed,
  requestFailed,
  setPickupsRequest,
  addPickups,
  setPickups,
  updateCustomerPickup,
  updateCustomerPickupStatus,
} = customerPickupSlice.actions;

export const customerPickupSelector = (state: IState) => {
  return state.customerPickup;
};

export const getPickups = (filter?: CustomerPickupsFilterModel, page?: number): AppThunk => async (dispatch) => {
  dispatch(requestStarted(page ? "GET_PICKUPS_PORTION" : "GET_PICKUPS"));
  const response = await customerPickupService.getPickups(filter, page);

  if (response.isCancel?.()) return;
  
  if (response.ok()) {
    dispatch(page ? addPickups(response.data) : setPickups(response.data));
    dispatch(setPickupsRequest({
      page: response.data?.pageable.pageNumber,
      last: response.data?.last
    }));
    dispatch(requestSucceed());
  } else {
    dispatch(requestFailed({
      status: response.status,
      error: response.getError ? response.getError() : "Error"
    }));
  }
};

const customerPickupReducer = customerPickupSlice.reducer;
export default customerPickupReducer;