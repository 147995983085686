import React, { useState } from 'react';
import {ExceptionProfile} from "../../../app/data/manifest/manifestProfiles";
import SlideOutSidebar from "../../../ui-components/slide-out-sidebar/slideOutSidebar";
import ImageThumbnail from '../../../ui-components/image-thumbnail/imageThumbnail';
import XGSPopup from '../../../ui-components/xgs-popup/xgsPopup';
import { formatTime } from '../../../hooks/utils';
import Tag from '../../../ui-components/molecules/tag/tag';
import { TagColor } from '../../../app/data/common/tagColor';
import { useSelector } from 'react-redux';
import { manifestsSelector } from '../../../slices/manifest/manifestSlice';
import ManifestsState from '../../../slices/manifest/ManifestsState';
import Loading from '../../../ui-components/loading/loading';
import XGSErrorMessage from '../../../ui-components/error-message/errorMessage';

interface ExceptionsProps {
  probillNumber: number
  exceptions: ExceptionProfile[]
  show: boolean
  onClose: () => void
}

const Exceptions: React.FC<ExceptionsProps>  = (props) => {
  const [displayedImage, setDisplayedImage] = useState<string>('');
  const manifestsState: ManifestsState = useSelector(manifestsSelector);

  const formatReason = (input: string): string => input.replace(/_/g, ' ')
  
  return (
      <SlideOutSidebar
        header={`Exceptions for Probill #${props.probillNumber}`}
        show={props.show}
        size="small"
        onClose={props.onClose}
      >
      {manifestsState.fetch_was_started && manifestsState.requestCreator === "GET_EXCEPTIONS" && <Loading isLoading={true} />}

      {manifestsState.fetch_was_failed && <XGSErrorMessage>{manifestsState.fetch_fail_reason}</XGSErrorMessage>}

      {manifestsState.fetch_was_succeed && (
        <>
          <h4 className="xgs-manifest__exceptions-header">Probill number: {props.probillNumber}</h4>
          {props.exceptions && props.exceptions.length > 0 && (
            props.exceptions.map(exc => (
              <article className="xgs-manifest__exception-details" key={exc.itemCode}>
                <div className="xgs-manifest__exception-details__col">
                  <div className="exc__item">
                    <Tag mods={{ color: TagColor.RED }}>{formatReason(exc.reason)}</Tag>
                  </div>
                  <span className="exc__item exc__item-code">Item code: <b>{exc.itemCode}</b></span>
                  <div className="exc__item">
                    <time className="exc__date">Date: <b>{exc.exceptionDate.toUiDateFormat()}</b></time>
                    <time className="exc__time">Time: <b>{formatTime(exc.exceptionTime)}</b></time>
                  </div>
                  {exc.note && (<p className="exc__item exc__note">Note: {exc.note}</p>)}
                </div>
                {exc.imageBase64 && (
                  <div className="xgs-manifest__exception-details__col">
                    <ImageThumbnail src={exc.imageBase64} onClick={setDisplayedImage} />
                  </div>
                )}
              </article>
            ))
          )}

          <XGSPopup
            opened={!!displayedImage}
            onClose={() => setDisplayedImage("")}
          >
            <div className="xgs-manifest__exception-popup-content">
              <img src={displayedImage} alt="exception" />
            </div>
          </XGSPopup>
        </>
      )}
      </SlideOutSidebar>
  );
}

export default Exceptions;