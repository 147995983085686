import React, { memo, useEffect } from "react";
import { PlanningTipsState } from "../../../../../../slices/dispatch-planning/planningTips/planningTipsState";
import { planningTipsSelector, setStartDate } from "../../../../../../slices/dispatch-planning/planningTips/planningTipsSlice";
import { useDispatch, useSelector } from "react-redux";
import PlanningTip from "../planningTip";
import { PlanningTipTypes } from "../planningTipTypes";
import { dispatchSelectedProbillsSelector } from "../../../../../../slices/dispatch-planning/dispatchSelectedProbillsSlice";
import { DispatchSelectedProbillsState } from "../../../../../../slices/dispatch-planning/dispatchProbillsState";


const RouteStartDateTip: React.FC = memo(() => {
  const dispatch = useDispatch();
  const planningTipsState: PlanningTipsState = useSelector(
    planningTipsSelector
  );
  const dispatchSelectedProbillsState: DispatchSelectedProbillsState = useSelector(
    dispatchSelectedProbillsSelector
  );
  useEffect(() => {
    if (!dispatchSelectedProbillsState.selectedProbills || !dispatchSelectedProbillsState.selectedProbills.length) return;
    let smallestEta = `${dispatchSelectedProbillsState.selectedProbills[0].etaDate}`;
    dispatchSelectedProbillsState.selectedProbills.forEach(probill => {
      if (probill.etaDate < +smallestEta) {
        smallestEta = `${probill.etaDate}`;
      }
    });
    dispatch(setStartDate(smallestEta));
  }, [dispatchSelectedProbillsState.selectedProbills, dispatch])

  const getFormattedDate = (etaDate: string) => {
    if (!etaDate) return "";
    let dateStr = `${etaDate}`;
    return `${dateStr.slice(4, 6)}/${dateStr.slice(6)}/${dateStr.slice(0, 4)}`;
  }

  return (<>
    {(planningTipsState && planningTipsState.startDate) && <PlanningTip
      tipType = {PlanningTipTypes["attention"]}
      tipText={`Earliest ETA date from selected probills is: ${getFormattedDate(planningTipsState.startDate)}`}
    />
    }
  </>
  );
}
);

export default RouteStartDateTip;
