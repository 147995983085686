
export default interface InviteState {
  request_was_started: boolean;
  request_was_succeed: boolean;
  request_was_failed: boolean;
  request_fail_reason?: string | null;
}

export const initialInviteManagementState: InviteState = {
  request_was_started: false,
  request_was_succeed: false,
  request_was_failed: false,
  request_fail_reason: null
};
