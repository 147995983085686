import { AddressModel } from "../../app/data/common/address";
import {
  PickupConsigneeModel,
  PickupItemModel,
  PickupShipperContacts
} from "../../app/data/pickup/models";
import { SubAccountMinimal } from "../../app/data/account/SubAccount";

export default interface PickupState {
  companies: any[];
  company: any;
  shippers: SubAccountMinimal[] | null;
  shippersSearch: SubAccountMinimal[] | null;
  teamId: string;
  teamName: string;
  shipper: {
    id: string | null;
    accountNumber: number | null;
    name: string;
    address: AddressModel;
    contacts: PickupShipperContacts;
  };
  bolNumber: string;
  poNumber: string;
  additionalReference: string;
  specialInstructions: string | undefined;
  pickupDate: string;
  pickupReadyTime: string | undefined;
  pickupCloseTime: string | undefined;
  consignee: PickupConsigneeModel;
  consignees: PickupConsigneeModel[] | null;
  items: PickupItemModel[];
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestCreator: string;
  requestError: string;
  consigneeCheckResult: boolean;
  consigneeZipChecked: string;
  pickupNumber: string;
  paypointNumber: number | null,
};

export const initialPickupState: PickupState = {
  companies: [],
  company: null,
  shippers: null,
  shippersSearch: null,
  teamId: "",
  teamName: "",
  shipper: {
    id: null,
    accountNumber: null,
    name: "",
    address: {
      address1: "",
      city: "",
      postalCode: "",
      state: "",
      additionalAddressLine: "",
    },
    contacts: {
      email: ""
    },
  },
  bolNumber: "",
  poNumber: "",
  additionalReference: "",
  specialInstructions: "",
  pickupDate: "",
  pickupReadyTime: undefined,
  pickupCloseTime: undefined,
  consignee: {
    name: "",
    address: {
      address1: "",
      city: "",
      postalCode: "",
      state: "",
      additionalAddressLine: "",
    },
    phone: "",
    email: ""
  },
  consignees: null,
  items: [],
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestCreator: "",
  requestError: "",
  consigneeCheckResult: false,
  consigneeZipChecked: "",
  pickupNumber: "",
  paypointNumber: null,
};
