import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";
import { Routes } from "../../../../app/route/RoutesConfig";
import ConfirmationModal from "../../../../ui-components/confirmation-modal/confirmationModal";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import BolItemForm from "./bolItemForm";
import BolState from "../../../../slices/bol/BolState";
import {
  bolSelector,
  addItem,
  updateItem,
  deleteItem,
  clearDeletedItems,
  createBol
} from "../../../../slices/bol/bolSlice";
import { BolRequestModel } from "../../../../app/data/bol/models";
import { StepProps } from "../../../../app/data/common/models";
import { userSelector } from "../../../../slices/user/userSlice";
import UserState from "../../../../slices/user/UserState";
import CommonState from "../../../../slices/common/CommonState";
import { commonSelector } from "../../../../slices/common/commonSlice";
import rollIcon from "../../../../images/roll_blue.svg";
import palleteIcon from "../../../../images/pallete_blue.svg";
import editIcon from "../../../../images/edit_blue.svg";
import removeIcon from "../../../../images/trash_blue.svg";
import "../../bol.scss";
import "../../../../sass/blocks.scss";

const BolItemsStep: React.FC<StepProps> = (props) => {
  const { previous } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const bolState: BolState = useSelector(bolSelector);
  const userState: UserState = useSelector(userSelector);
  const commonState: CommonState = useSelector(commonSelector);
  const [showAddItemForm, setAddItemForm] = useState<boolean>(false);
  const [removeItemIndex, setRemoveItemIndex] = useState<number | undefined>(undefined);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState<boolean>(false);
  const [editItemIndex, setEditItemIndex] = useState<number | undefined>(undefined);

  const onClickBack = () => {
    dispatch(clearDeletedItems());
    previous && previous();
  };

  const onSubmit = () => {
    if (!bolState.shipper) return;
    dispatch(clearDeletedItems());
    let items = bolState.items.filter(item => !item.deleted);
    let request: BolRequestModel = {
      shipper: bolState.shipper,
      shipperName: bolState.shipperName,
      shipperAddress: bolState.shipperAddress,
      bolNumber: bolState.bolNumber,
      poNumber: bolState.poNumber,
      payType: bolState.payType,
      specialInstructions: bolState.specialInstructions,
      willCall: bolState.willCall,
      consignee: bolState.consignee,
      items: items
    };
    if (bolState.quoteNumber) request.quoteNumber = bolState.quoteNumber;
    if (bolState.payor) request.payor = bolState.payor;
    if (bolState.pickupRequest && bolState.pickupDate && bolState.pickupReadyTime && bolState.pickupCloseTime) {
      request.pickupDate = bolState.pickupDate.toApiDateFormat();
      request.pickupReadyTime = bolState.pickupReadyTime;
      request.pickupCloseTime = bolState.pickupCloseTime;
    }
    dispatch(createBol(request, () => {
      toast.info("BOL was successfully created!");
      mixpanel.track(`Bill of Lading was created ${request.pickupDate ? "(w/ pickup)" : "(w/o pickup)"}`);
      history.push(`/${userState.activeSubAccount?.accountNumber}${Routes.bol.list}`);
    }));
  };

  const getClassLabel = (ratingClassValue: string) => {
    let ratingClass = commonState.freightClasses.find(obj => obj.value === ratingClassValue);
    return ratingClass ? ratingClass.label : ratingClassValue;
  };

  useEffect(() => {
    if (!bolState.requestFailed || bolState.requestCreator === "CHECK_PICKUP") return;
    toast.error(bolState.requestError || "Error", { autoClose: 4000 });
  }, [bolState.requestFailed, bolState.requestError, bolState.requestCreator]);

  return (
    <div className="xgs-bol__consignee">
      <div className="xgs-bol__section">Detail Information</div>
      {bolState.items.map((item, index) => (
        <div key={index}>
          {(index === editItemIndex) && (
            <BolItemForm
              itemIndex={index}
              onSubmit={(data) => {
                dispatch(updateItem({ index, data }));
                setEditItemIndex(undefined);
              }}
              onCancel={() => {
                setEditItemIndex(undefined);
              }}
            />
          )}
          {(editItemIndex === undefined || (index !== editItemIndex)) && !item.deleted && (
            <div className="xgs-items__item">
              <div className="xgs-items__item__columns xgs-items__item__columns--space-between">
                <div className="xgs-items__item__columns xgs-items__item__columns--no-margin xgs-items__item__columns--stretch">
                  <div className="xgs-items__item__icon">
                    {item.packageType === "ROLL" && (
                      <img
                        src={rollIcon}
                        alt="roll"
                      />
                    )}
                    {item.packageType === "PALLET" && (
                      <img
                        src={palleteIcon}
                        alt="pallet"
                      />
                    )}
                  </div>
                  <div className="xgs-items__item__flex-center-area">
                    <div className="xgs-items__item__type">
                      {item.packageType === "ROLL" && "Roll"}
                      {item.packageType === "PALLET" && "Pallet"}
                    </div>
                    {item.ratingClass && (<div className="xgs-items__item__property xgs-items__item__class">Class: <span>{getClassLabel(item.ratingClass)}</span></div>)}
                  </div>
                </div>
                <div className="xgs-items__item__columns xgs-items__item__columns--no-margin xgs-items__item__columns--actions">
                  <div
                    className="xgs-items__item__action"
                    onClick={() => {
                      setAddItemForm(false);
                      setEditItemIndex(index);
                    }}
                  >
                    <img
                      src={editIcon}
                      alt="edit"
                    />
                    Edit
                  </div>
                  <div
                    className="xgs-items__item__action"
                    onClick={() => {
                      setRemoveItemIndex(index);
                      setShowRemoveConfirmation(true);
                    }}
                  >
                    <img
                      src={removeIcon}
                      alt="remove"
                    />
                    Remove
                  </div>
                </div>
              </div>
              <div className="xgs-items__item__columns xgs-items__item__columns--allow-wrap">
                <div className="xgs-items__item__property">Units: <span>{item.units}</span></div>
                {item.yards && (<div className="xgs-items__item__property">Sq. Yards: <span>{item.yards}</span></div>)}
                <div className="xgs-items__item__property">Pounds: <span>{item.weight}</span></div>
                {!!(item.length && item.width && item.height) && (
                  <div className="xgs-items__item__property">Size: <span>{item.length}</span> x <span>{item.width}</span> x <span>{item.height}</span> in</div>
                )}
                {(item.packageType === "ROLL" && item.length) && (
                  <div className="xgs-items__item__property">Length: <span>{item.length}</span> in</div>
                )}
              </div>
              {item.description && (
                <div className="xgs-items__item__columns xgs-items__item__columns--allow-wrap">
                  <div className="xgs-items__item__property">Description: <span>{item.description}</span></div>
                </div>
              )}
              {item.sidemark && (
                <div className="xgs-items__item__columns xgs-items__item__columns--allow-wrap">
                  <div className="xgs-items__item__property">Sidemark: <span>{item.sidemark}</span></div>
                </div>
              )}
              {item.notes && (
                <div className="xgs-items__item__columns xgs-items__item__columns--allow-wrap">
                  <div className="xgs-items__item__property">Notes: <span>{item.notes}</span></div>
                </div>
              )}
            </div>
          )}
        </div>
      ))}
      {(showAddItemForm || bolState.items.length === 0) && !bolState.quoteNumber && <BolItemForm onSubmit={(data) => {
        dispatch(addItem(data));
        setAddItemForm(false);
      }} />}
      {!showAddItemForm &&
        !bolState.quoteNumber &&
        (editItemIndex === undefined) &&
        (bolState.items.length > 0) &&
        (bolState.items.filter(item => !item.deleted).length < 10) && (
        <div className="xgs-bol__items__add">
          <span
            className="xgs-bol__items__link"
            onClick={() => { setAddItemForm(true); }}
          >
            + Add new item
          </span>
        </div>
      )}
      {!bolState.quoteNumber && (
        <div className="xgs-bol__items__limitation">
          {bolState.items.filter(item => !item.deleted).length === 0
            ? (
              <span>Up to 10 items are allowed.</span>
            )
            : (
              <span>{bolState.items.filter(item => !item.deleted).length} of 10 allowed items have been entered.</span>
            )
          }
        </div>
      )}
      <div className="xgs-bol__buttons">
        <Button
          type="button"
          theme={ButtonThemes.gray}
          className="xgs-bol__nav-button"
          onClick={onClickBack}
        >
          Back
        </Button>
        <Button
          type="button"
          theme={ButtonThemes.blue}
          disabled={
            bolState.items.length === 0
            || bolState.items.filter(item => !item.deleted).length === 0
            || (editItemIndex !== undefined)
          }
          className="xgs-bol__nav-button"
          spinner={bolState.requestStarted}
          onClick={onSubmit}
        >
          Submit
        </Button>
      </div>
      <ConfirmationModal
        opened={showRemoveConfirmation}
        confirmButtonText="Remove"
        onCancel={() => {
          setShowRemoveConfirmation(false);
        }}
        onConfirm={() => {
          dispatch(deleteItem(removeItemIndex));
          setShowRemoveConfirmation(false);
          setRemoveItemIndex(undefined);
        }}>
        The item will be removed from the list.
      </ConfirmationModal>
    </div>
  );
};

export default BolItemsStep;
