import React from "react";
import { UnplannableProbillsSummaryData, UnplannableProbillsSummaryTypes } from "../../../../app/data/unplannable-probills/models";
import { UnplannableProbillsSummaryTile } from "./unplannableProbillsSummaryTile";
import "./unplannableProbills.scss";

interface UnplannableProbillsSummaryProps {
    summaryData: UnplannableProbillsSummaryData,
    isLoading: boolean
}

export const UnplannableProbillsSummary: React.FC<UnplannableProbillsSummaryProps> = ({ summaryData, isLoading }) => {

    return (<div className="xgs-unplannable-probills-summary">
        <UnplannableProbillsSummaryTile
            type={UnplannableProbillsSummaryTypes.total}
            isLoading={isLoading}
            title="Total"
            value={(summaryData && summaryData.total) ? `${summaryData.total}` : "0"}
        />
        <UnplannableProbillsSummaryTile
            type={UnplannableProbillsSummaryTypes.atRisk}
            isLoading={isLoading}
            title="At Risk Probills"
            value={(summaryData && summaryData.atRisk) ? `${summaryData.atRisk}` : "0"}
        />
        <UnplannableProbillsSummaryTile
            type={UnplannableProbillsSummaryTypes.catchUp}
            isLoading={isLoading}
            title="Catch-up Probills"
            value={(summaryData && summaryData.catchUp) ? `${summaryData.catchUp}` : "0"}
        />
        <UnplannableProbillsSummaryTile
            type={UnplannableProbillsSummaryTypes.late}
            isLoading={isLoading}
            title="Late Probills"
            value={(summaryData && summaryData.late) ? `${summaryData.late}` : "0"}
        />
         <UnplannableProbillsSummaryTile
            type={UnplannableProbillsSummaryTypes.missed}
            isLoading={isLoading}
            title="Missed Probills"
            value={(summaryData && summaryData.missed) ? `${summaryData.missed}` : "0"}
        />
    </div>
    )
};