import React, { useState, DetailedHTMLProps, memo } from "react";
import { makeId, getHeightBeforeEndOfPage } from "../../hooks/utils";
import useWindowSize from "../../hooks/useWindowsSize";

import "./fitToWindowContainer.scss";

export interface FitToWindowContainerProps
  extends DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {}

const FitToWindowContainer: React.FC<FitToWindowContainerProps> = memo((props) => {
  const [tableId] = useState(`xgs-fit-to-window-container-${makeId(5)}`);
  const windowHeight = useWindowSize()[1];

  const getContainerHeight = () => {
    return getHeightBeforeEndOfPage(`.${tableId}`, 500, windowHeight);
  };

  return (
    <div
      className={`xgs-fit-to-window-container ${tableId}`}
      style={{
        height: getContainerHeight()
      }}
      {...props}
    >
      {props.children}
    </div>
  );
});

export default FitToWindowContainer;
