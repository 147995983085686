import { Contact } from "../../../app/data/contact/models";
import React from "react";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import personBlueIcon from "../../../images/person_blue.svg";

export interface ContactCardProps {
  contact: Contact;
  onClickEdit?: any;
  handleDelete?: any;
  selectable?: boolean;
  onSelect?: any;
  selected?: boolean;
  hideButtons?: boolean;
}

export const ContactCard: React.FC<ContactCardProps> = (props) => {
  return (
    <>
      <div className="xgs-contact-card">
        <div className="xgs-contact-card__user">
          <div className="xgs-contact-card__user__icon">
            <img src={personBlueIcon} alt="person"/>
          </div>
          <div className="xgs-contact-card__user__info">
            <div
              title={props.contact.firstName + " " + props.contact.lastName}
              className="xgs-contact-card__user__info__name"
            >
              {props.contact.firstName + " " + props.contact.lastName}
            </div>
            <div title={props.contact.title} className="xgs-contact-card__user__info__title">
              {props.contact.title}
            </div>
          </div>
          {!props.hideButtons && (
            <div className="xgs-contact-card__user__buttons">
              {!props.selectable && (
                <>
                  <div
                    onClick={() => props.onClickEdit(props.contact.id)}
                    className="xgs-contact-card__user__buttons__edit"
                  >
                    <XGSIcon icon={XGSIcons.faEdit} />{" "}
                    <span className="xgs-contact-card__user__buttons--mobile-hidden">Edit</span>
                  </div>
                  <div
                    onClick={() => props.handleDelete(props.contact)}
                    className="xgs-contact-card__user__buttons__edit"
                  >
                    <XGSIcon icon={XGSIcons.faTrash} />{" "}
                    <span className="xgs-contact-card__user__buttons--mobile-hidden">Remove</span>
                  </div>
                </>
              )}
              {props.selectable && (
                <input
                  type="radio"
                  name="contactSelect"
                  checked={props.selected}
                  onChange={() => props.onSelect(props.contact.id)}
                  className="xgs-contact-card__radio"
                />
              )}
            </div>
          )}
        </div>
        <div className="xgs-contact-card__info">
          <div className="xgs-contact-card__info__item">
            <XGSIcon icon={XGSIcons.faPhoneAlt} />
            <div>{props.contact.phone}</div>
          </div>
          <div className="xgs-contact-card__info__item">
            <XGSIcon className="xgs-contact-card__info__item" icon={XGSIcons.faEnvelope} />
            <div>
              <a
                title={props.contact.email}
                className="blue-link"
                href={`mailto:${props.contact.email}`}
              >
                {props.contact.email}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
