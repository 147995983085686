import styled from "styled-components";

const LoginWrapper = styled.div`
  background-color: #efefef;
  width: calc(100% + 24px);
  height: calc(100% + 24px);
  margin-left: -24px;
  margin-top: -24px;
  min-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;
`;

export default LoginWrapper;
