import { FeatureBannerResponseModel } from "../../app/data/feature-banner/models";

export default interface FeatureBannerState {
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError: string;
  requestCreator: string;
  banner: FeatureBannerResponseModel | null;
}

export const initialFeatureBannerState: FeatureBannerState = {
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestError: "",
  requestCreator: "",
  banner: null
};
