import { Contact } from "../../../app/data/contact/models";

export default interface TeamAccountContactState {
  request_was_started: boolean;
  request_was_succeed: boolean;
  request_was_failed: boolean;
  request_fail_reason?: string | null;
  request_creator?: string;
  contacts: Contact[];
}

export const initialTeamAccountContactState: TeamAccountContactState = {
  request_was_started: false,
  request_was_succeed: false,
  request_was_failed: false,
  request_fail_reason: null,
  contacts: []
};
