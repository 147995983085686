import React, { ReactElement } from "react"
import Tag from "../../../ui-components/molecules/tag/tag";
import { TagColor } from "../../../app/data/common/tagColor";

export interface StopProps {
  name: string;
  address: string | ReactElement;
  status: string | ReactElement;
  mods: {
    color: TagColor;
  }
}

const Stop: React.FC<StopProps> = ({ name, address, mods, status, children }) => {
  return (
    <article className="manifest-details__stop">
      <header className="manifest-details__stop__head">
        <h4 className="manifest-details__stop__address">
          <b>{name}</b> {address}
        </h4>
        <span className="manifest-details__stop__head--line"></span>
        <Tag mods={{ ...mods, uppercase: true }} mix="manifest-details__stop__status">
          {status}
        </Tag>
      </header>

      <ul className="manifest-details__stop__body">
        {children}
      </ul>
    </article>
  )
}

export default Stop