import React from "react";
import Tag from "../../ui-components/molecules/tag/tag";
import { TagColor } from "../../app/data/common/tagColor";
import dateFormats from "../../app/data/common/dateFormats";
import moment from "moment";

export const getTripsColumns = () => (
  [
    {
      Header: (
        <div style={{  }}>
          Manifest # / <br/> Dispatch Date
        </div>
      ),
      accessor: "dispatchDate",
      Cell: (cellProps: any) => (
        <div className="xgs-lmi__trips__date-cell">
          <span>{cellProps.row.original.manifestNumber} </span><br/>
          {moment(cellProps.row.original.dispatchedDate, "YYYYMMDD").format(dateFormats.uiDate)}
        </div>
      ),
      width: 110
    },
    {
      Header: "Driver",
      accessor: "driverId",
      width: 110,
      Cell: (cellProps: any) => (
        cellProps.row.original.driver ? <div className="xgs-lmi__trips__driver-cell">
          {cellProps.row.original.driver?.firstName + " " + cellProps.row.original.driver?.lastName} <br/>
          <span>{cellProps.row.original.driver?.driverId}</span>
        </div> : <></>
      )
    },
    {
      Header: "Lane Name",
      width: 110,
      accessor: "lane.laneName"
    },
    {
      Header: "Lane Miles",
      width: 63,
      accessor: "lane.miles"
    },
    {
      Header: "Origin",
      accessor: "origin",
      width: 140,
      Cell: (cellProps: any) => (
        <div className="xgs-lmi__trips__address-cell">
          {cellProps.row.original.originTerminalAirportcode} - {cellProps.row.original.originTerminalName} <span>{cellProps.row.original.originTerminalNumber}</span>
        </div>
      )
    },
    {
      Header: "Destination",
      accessor: "destination",
      width: 140,
      Cell: (cellProps: any) => (
        <div className="xgs-lmi__trips__address-cell">
          {cellProps.row.original.destinationTerminalAirportcode} - {cellProps.row.original.destinationTerminalName} <span>{cellProps.row.original.destinationTerminalNumber}</span>
        </div>
      )
    },
    {
      Header: "Relay Location",
      accessor: "relayCity",
      width: 106,
      Cell: (cellProps: any) => (
        cellProps.row.original.lane ? <>
          {
            [cellProps.row.original.lane.relayCity, cellProps.row.original.lane.relayState, cellProps.row.original.lane.relayZipcode]
              .filter(val => !!val)
              .join(", ")
          }
        </> : <></>
      )
    },
    {
      Header: "Status",
      accessor: "isReviewed",
      width: 120,
      Cell: (cellProps: any) => (
        cellProps.value ? <Tag mods={{ color: TagColor.GREEN }}>REVIEWED</Tag> : <Tag mods={{ color: TagColor.RED}}>NOT REVIEWED</Tag>
      )
    },
    {
      Header: "Exceptions",
      accessor: "exceptions",
      width: 174,
      Cell: (cellProps: any) => (
        cellProps.value.map((exc: any) => exc.type.toLowerCase().capitalize()).join(", ")
      )
    }
  ]
)