import React from "react";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

export interface IconProps extends FontAwesomeIconProps {}

const XGSIcon: React.FC<IconProps> = (props) => {
  return (
    <FontAwesomeIcon className="xgs-icon" {...props} />
  );
}

export default XGSIcon;