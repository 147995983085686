import React, { ChangeEvent, memo, useState } from "react";
import { cn } from "../../services/common/className";
import { ReactComponent as StarIcon } from "../../images/star.svg";

import "./rating.scss";

export interface RatingProps {
  mix?: string;
  value?: string;
  onChange: (e: ChangeEvent) => void;  
}

const config: [string, string][] = [
  ["Great!", "5"],
  ["Good", "4"],
  ["Acceptable", "3"],
  ["Poor", "2"],
  ["Improve it!", "1"],
];

const Rating: React.FC<RatingProps> = memo((props) => {
  const [hoveredValue, setHoveredValue] = useState<string>("");

  const displayedValue = hoveredValue || props.value;

  return (
    <div className={cn("rating")(null, props.mix)}>
      <fieldset className="rating__options" onMouseOut={() => setHoveredValue("")}>
      {config.map(([, ratingValue]) => {
        return (
          <React.Fragment key={ratingValue}>
            <input
              type="radio"
              name="rating"
              id={ratingValue}
              value={ratingValue}
              checked={props.value === ratingValue}
              onChange={props.onChange}
            />
            <label htmlFor={ratingValue} className={cn("rating__label")({active: (ratingValue === props.value)})} onMouseOver={() => setHoveredValue(ratingValue)}>
              <StarIcon style={{ stroke: "#B89311" }}/>
            </label>
          </React.Fragment>
        )
      })}
    </fieldset>
    
    <div>{Number(displayedValue) > 0 ? config.find(([,value]) => (value === displayedValue))?.[0] : ""}</div>
    </div>    
  );
});

export default Rating;
