import React from "react";
import { cn } from "../../services/common/className";

export const WizardStepIndicator: React.FC<{ step: number, stepCount: number }> = (props) => {
  return (    
    <div className="wizard__step-indicator">
      {Array.from({ length: props.stepCount }, (_, idx) => (
        <div
          key={idx}
          className={cn("wizard__step-indicator__dot")({completed: props.step >= idx, current: props.step === idx})}
        />
      ))}
    </div>
  );
};
