import React from "react";
import "./errorMessage.scss";

export interface ErrorMessageProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
}

const XGSErrorMessage: React.FC<ErrorMessageProps> = (props) => {
  return (
    <div className={`xgs-error-message ${props.className ? props.className : ""}`}>
      {props.children}
    </div>
  )
}

export default XGSErrorMessage;