import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import debounce from "lodash/debounce";
import moment from "moment";
import LabeledSelectInput from "../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import XGSFormDate from "../../../ui-components/form/date/xgsFormDate";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import { weekDays } from "../../../ui-components/xgs-date/xgs-date/xgsDate";
import {
  PickupLocationModel,
  PickupLocationSchema
} from "../../../app/data/rates/models";
import { StepProps } from "../../../app/data/common/models";
import RequestRatesState from "../../../slices/rates/RequestRatesState";
import {
  clearShippingItems,
  requestRatesSelector,
  setPickupLocation,
  searchLocations
} from "../../../slices/rates/requestRatesSlice";
import { getFreightClasses } from "../../../slices/common/commonSlice";
import CommonState from "../../../slices/common/CommonState";
import {
  commonSelector,
  getPickupDays
} from "../../../slices/common/commonSlice";
import { nextBusinessDay } from "../../../hooks/utils";

const initialValues: PickupLocationModel = {
  location: null,
  date: ""
};

const PickupLocation: React.FC<StepProps> = (props) => {
  const dispatch = useDispatch();
  const { next } = props;
  const requestRatesState: RequestRatesState = useSelector(requestRatesSelector);
  const commonState: CommonState = useSelector(commonSelector);
  const [locationFormValue, setLocationFormValue] = useState<{ value: string, label: string, subtitle?: string } | null>();

  const onSubmit = (data: PickupLocationModel) => {
    dispatch(setPickupLocation(data));
    if (data.location) {
      dispatch(getFreightClasses(data.location.value));
    }
    next && next();
  };

  let locationSearch = (e: string) => {
    e?.length > 1 && dispatch(searchLocations("PICKUP", e));
  }
  locationSearch = debounce(locationSearch, 300);

  const nextServiceCenterWorkDay = () => {
    if (!commonState.pickupDays || Object.keys(commonState.pickupDays).length === 0) return nextBusinessDay();
    let day = moment().add(1, "day"); // default value to avoid TS error (possible undefined)
    for (let i = 1; i < 8; i++) {
      day = moment().add(i, "day");
      let dayOfWeek = day.format("dd").toLowerCase();
      if (commonState.pickupDays[dayOfWeek as keyof weekDays] === true) break;
    }
    return day.format("MM/DD/YYYY");
  };

  useEffect(() => {
    initialValues.location = requestRatesState.pickupLocation;
    initialValues.date = requestRatesState.pickupDate;
    requestRatesState.pickupLocation && setLocationFormValue({
      label: requestRatesState.pickupLocation.label,
      value: requestRatesState.pickupLocation.value
    });
  }, [requestRatesState.pickupLocation, requestRatesState.pickupDate]);

  return (
    <div className="xgs-request-rates__pickup-location">
      <div className="xgs-request-rates__step__header">Pickup Location</div>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={PickupLocationSchema}
        enableReinitialize
      >
        {(props: FormikProps<PickupLocationModel>) => (
          <Form className="form-items">
            <LabeledSelectInput
              className="xgs-request-rates__location-field"
              isDisabled={false}
              isLoading={requestRatesState.requestStarted}
              label="Pickup Location:"
              labelMode={LabelModes.column}
              name="location"
              onInputChange={locationSearch}
              onValueChange={(v) => {
                props.setFieldValue("location", {
                  label: v?.label,
                  value: v?.value
                });
                setLocationFormValue(v);
                if (locationFormValue?.value !== v?.value) {
                  dispatch(clearShippingItems());
                }
                if (v?.value && /^\d{5}$/.test(v.value)) {
                  dispatch(getPickupDays(v.value));
                }
                props.setFieldValue("date", "")
              }}
              openMenuOnClick={requestRatesState.pickupLocations.length > 0}
              options={requestRatesState.pickupLocations}
              placeholder="Enter Zip or City..."
              value={locationFormValue}
            />            
            <XGSFormDate
              name="date"
              label="Tender Date:"
              onDateChange={(v) => {
                props.setFieldValue("date", v);
              }}
              onChange={() => null}
              minDate={new Date(nextServiceCenterWorkDay())}
              required={true}
              requiredAsteriskDisabled={true}
              labelMode={LabelModes.column}
              className="xgs-request-rates__pickup-location__date-field"
              disableWeekends={true}
              disabledWeekDays={commonState.pickupDays}
              disabled={!locationFormValue}
            />
            <Button
              type="submit"
              theme={ButtonThemes.blue}
              style={{ marginTop: 38 }}
              disabled={!props.isValid || !props.values.location}
              className="xgs-request-rates__step__nav-button"
            >
              Next
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PickupLocation;
