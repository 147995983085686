import api, { ApiResponse } from "./../api";

class JWTService {
  private static instance: JWTService;
  private constructor() {}

  static getInstance(): JWTService {
    if (!JWTService.instance) {
      JWTService.instance = new JWTService();
    }
    return JWTService.instance;
  }

  public getToken = async (
    subAccountId: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/accounts/${subAccountId}/tokens`);
  };

  public requestToken = async (
    subAccountId: string
  ): Promise<ApiResponse<any>> => {
    return await api.post(`/accounts/${subAccountId}/tokens`);
  };
}

export default JWTService;
