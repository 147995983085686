export interface ExceptionModel {
  incidentNumber: string;
  type: string;
  startedDate: string;
  resolvedDate: string;
  terminalNumber: string;
  description: string;
  itemSerial: string;
  probillNumber: number;
}

export interface CreateExceptionRequest {
  probillNumber?: number;
  incidentType?: string;
  itemSerialNumbers?: string[];
  description?: string;
  terminalNumber?: number;
}

export const ExceptionIncidentOptions = [
  { label: "Damaged freight", value: "3" },
  { label: "Misloaded freight", value: "M" },
  { label: "Overaged freight", value: "O" },
  { label: "Refused-72 hour", value: "Z" },
  { label: "Refused-on hand", value: "R" },
  { label: "Shortage freight", value: "S" },
  { label: "Undeliverable as is", value: "U" }
];
