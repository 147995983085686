import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const getFormattedDate = (date: number) => {
  if (!date || !(+date)) return "";
  let dateStr = date.toString();
  let formattedDate = moment(dateStr, "YYYY-MM-DD");
  return formattedDate.format("MMM D, YYYY");
}

const getIssueValue = (planningIssues: any) => {
  if (planningIssues && planningIssues.length) {
    let issuesValue = planningIssues[0].type;
    if (planningIssues.length > 1) {
      return <span>{issuesValue} and <span className="xgs-table-link-cell">{` ${planningIssues.length - 1}`} more</span></span>;
    }
    return issuesValue || "";
  } else return "No Planning Issue";
}

export const getunplanProbillsColumns = () => [
  {
    Header: "Probill",
    accessor: "probillNumber",
    width: 70,
    Cell: (cellProps: any) => (
      <Link
        className="xgs-table-link-cell"
        to={`/shipments/${cellProps.value}`}
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        {cellProps.value}
      </Link>
    )
  },
  {
    Header: "Issue",
    accessor: "planningIssues",
    width: 120,
    Cell: (cellProps: any) => (
      <>
        {getIssueValue(cellProps.value)}
      </>
    )
  },
  {
    Header: "ETA",
    accessor: "etaDate",
    width: 80,
    Cell: (cellProps: any) => (
      <>
        {getFormattedDate(cellProps.value)}
      </>
    )
  },
  {
    Header: "Bill Date",
    accessor: "billDate",
    width: 80,
    Cell: (cellProps: any) => (
      <>
        {getFormattedDate(cellProps.value)}
      </>
    )
  },
  {
    Header: "Status",
    accessor: "probillStatus",
    width: 130,
    Cell: (cellProps: any) => (
      <>
        {cellProps.value}
      </>
    )
  },
  {
    Header: "Shipper",
    accessor: "shipperName",
    width: 150,
    Cell: (cellProps: any) => (
      <>{cellProps.value} ({cellProps.row.original.shipperNumber})</>
    )
  },
  {
    Header: "Consignee",
    accessor: "consigneeName",
    width: 150,
    Cell: (cellProps: any) => (
      <>{cellProps.value} ({cellProps.row.original.consigneeNumber})</>
    )
  },
  {
    Header: "Weight",
    accessor: "probillWeight",
    width: 70,
    Cell: (cellProps: any) => (
      <>
        {(cellProps.value && +cellProps.value) ? cellProps.value.toLocaleString('en', { useGrouping: true }) : cellProps.value}
      </>
    )
  },
  {
    Header: "Pieces",
    accessor: "probillItems",
    width: 70,
    Cell: (cellProps: any) => (
      <>
        {(cellProps.value && +cellProps.value) ? cellProps.value.toLocaleString('en', { useGrouping: true }) : cellProps.value}
      </>
    )
  }
];
