import React from "react";
import { useSelector } from "react-redux";
import { userSelector } from "../../slices/user/userSlice";
import UserState from "../../slices/user/UserState";

export interface NotificationDotProps {
  id: string | undefined;
}

const NotificationDot: React.FC<NotificationDotProps> = (props) => {
  const userState: UserState = useSelector(userSelector);

  return props.id === "pending-requests" && userState.portalState?.pendingRequests && userState.portalState.pendingRequests > 0 ? (
    <div className="xgs-red-dot__wrapper"><div className="xgs-red-dot__dot"></div></div>
  ) : <></>;
};

export default NotificationDot;
