import React, { useRef, useState } from "react";import SlideOutSidebar from "../../ui-components/slide-out-sidebar/slideOutSidebar";
import { Form, Formik, FormikProps } from "formik";
import { getExceptionValidationModel, LinehaulTripException } from "../../app/data/linehaul-mileage/models";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import { LabelModes } from "../../ui-components/molecules/labeled-inputs/labeledInput";
import XGSFormInput from "../../ui-components/form/input/xgsFormInput";
import { useDispatch, useSelector } from "react-redux";
import { createLinehaulTripException, linehaulMileageSelector } from "../../slices/linehaul-mileage/linehaulMileageSlice";
import check from "../../images/check_white.svg"
import { toast } from "react-toastify";
import XGSFormTextarea from "../../ui-components/form/textarea/xgsFormTextarea";
import XGSFormSelect from "../../ui-components/form/select/xgsFormSelect";
import { XGSSelectOption } from "../../ui-components/xgs-select/xgsSelect";


export interface AddAccessorialProps {
  show: boolean;
  closeModal: any;
  tripId: string;
}

const getExceptionOptions = () => [
  {
    label :"Delay",
    value: "DELAY"
  },
  {
    label: "Overnight",
    value: "OVERNIGHT"
  },
  {
    label: "Reimbursement",
    value: "REIMBURSEMENT"
  },
  {
    label: "Breakdown",
    value: "BREAKDOWN"
  }
]

export const AddAccessorial: React.FC<AddAccessorialProps> = (props) => {
  const formRef = useRef<FormikProps<LinehaulTripException>>(null);
  const linehaulMileageState = useSelector(linehaulMileageSelector);
  const dispatch = useDispatch();
  const [type, setType] = useState<XGSSelectOption | null>(null);

  const initialValues: LinehaulTripException = {
    type: "",
    duration: "",
    reason: "",
    notes: "",
    tripId: props.tripId
  }

  const onSubmit = (values: LinehaulTripException) => {
    dispatch(
      createLinehaulTripException(
        { ...values, tripId: props.tripId },
        () => {
          toast.info("Created Exception");
          props.closeModal();
        },
        (error: any) => toast.error(error)
      )
    );
  }

  const onClickCancel = () => {
    formRef.current?.resetForm();
    props.closeModal();
  }

  return (
    <SlideOutSidebar size={"small"} header="Add Exception" show={props.show} onClose={props.closeModal}>
      <div className="xgs-lmi__title">Add Exception</div>
      <Formik
        validateOnBlur
        initialValues={initialValues}
        innerRef={formRef}
        onSubmit={onSubmit}
        validationSchema={getExceptionValidationModel()}
      >
        {(props: FormikProps<LinehaulTripException>) => (
          <Form className="xgs-lmi__form">
            <XGSFormSelect
              label="Exception Type:"
              name="type"
              required
              value={type}
              labelMode={LabelModes.column}
              formik
              onValueChange={(option) => {
                formRef.current?.setFieldValue("duration", "")
                formRef.current?.setFieldValue("amount", "")
                if (option?.value === "OVERNIGHT") {
                  formRef.current?.setFieldValue("amount", 51.50);
                }
                setType(option!)
                setTimeout(() => formRef.current?.validateField("type"));
              }}
              controlled
              className="xgs-lmi__form__input"
              type="text"
              placeholder="Type"
              options={getExceptionOptions()}
            />
            {(["DELAY", "BREAKDOWN"].includes(props.values.type)) && <XGSFormInput
              label="Duration (hrs):"
              name="duration"
              required
              labelMode={LabelModes.column}
              className="xgs-lmi__form__input"
              type="number"
              placeholder="Duration(hrs)"
            />}
            {["REIMBURSEMENT", "OVERNIGHT"].includes(props.values.type) && <XGSFormInput
              label="Amount ($):"
              name="amount"
              required
              labelMode={LabelModes.column}
              className="xgs-lmi__form__input"
              type="number"
              placeholder="Amount"
            />}
            <XGSFormInput
              label="Reason:"
              name="reason"
              required
              labelMode={LabelModes.column}
              className="xgs-lmi__form__input"
              type="text"
              placeholder="Reason"
            />
            <XGSFormTextarea
              label="Additional Notes:"
              name="notes"
              className="xgs-lmi__form__input"
              type="text"
              placeholder="Notes"
              counter={60}
              maxLength={200}
            />
            <div style={{ marginBottom: 140 }} className="xgs-lmi__form__buttons">
              <Button theme={ButtonThemes.gray} type="button" onClick={onClickCancel}>
                Cancel
              </Button>
              <Button spinner={linehaulMileageState.requestStarted} theme={ButtonThemes.blue} type="submit">
                <img alt="Checkbox" src={check}/> &nbsp;Add
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </SlideOutSidebar>
  )
}