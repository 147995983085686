import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import HolidayCalendarService from "../../app/data/holiday-calendar/holidayCalendarService";
import { initialHolidayCalendarState } from "./holidayCalendarState";
import { AppThunk } from "../../app/store";
import { IState } from "..";
import moment from "moment";

const holidayCalendarService = HolidayCalendarService.getInstance();

export const holidayCalendarSlice = createSlice({
  name: "holidayCalendar",
  initialState: initialHolidayCalendarState,
  reducers: {
    resetHolidayCalendar: state => state = initialHolidayCalendarState,  
    requestStarted: (state, action: PayloadAction<string>) => {
      state.requestCreator = action.payload;
      state.requestStarted = true;
      state.needsToReload = false;
      state.requestFailed = false;
    },
    requestSuccess: (state) => {
      state.requestStarted = false;
      state.requestCreator = "";
      state.requestFailed = false;
    },
    requestFailed: (state, {payload}) => {
      state.requestError = payload;
      state.requestFailed = true;
      state.requestStarted = false;
    },
    loadedSuggestions: (state, { payload }) => {
      state.holidaySuggestions = payload;
    },
    startLoadingHolidays: (state) => {
      state.loaded = false;
      state.loading = true;
      state.needsToReload = false;
      state.loadingFailed = false;
    },
    loadedHolidays: (state, { payload }) => {
      state.loaded = true;
      state.loadingFailed = false;
      state.loading = false;
      state.holidays = payload;
    },
    loadingHolidaysFailed: (state, { payload }) => {
      state.loaded = false;
      state.loadingFailed = true;
      state.loadingError = payload;
    },
    reloadHolidays: (state) => {
      if (!state.loading) state.needsToReload = true;
    },
    addHolidayLocally: (state, {payload}) => {
      state.holidays.push(payload)
    },
    removeHolidayLocally: (state, {payload}) => {
      state.holidays = state.holidays.filter(holiday => {
        return !moment(holiday.date).isSame(payload)
      });
    },
    startLoadingHolidayLogs: (state) => {
      state.loadingLogs = true;
      state.loadedLogs = false;
      state.loadingFailed = false;
    },
    loadedHolidayLogs: (state, { payload }) => {
      state.loadingLogs = false;
      state.loadedLogs = true;
      state.loadingFailed = false;
      state.logs = payload;
    },
    loadingHolidayLogsFailed: (state, { payload }) => {
      state.loadingLogs = false;
      state.loadedLogs = false;
      state.loadingFailed = true;
      state.loadingError = payload;
    }
  }
});

export const {
  loadedHolidays,
  loadedSuggestions,
  requestStarted,
  requestFailed,
  reloadHolidays,
  resetHolidayCalendar,
  requestSuccess,
  startLoadingHolidays,
  loadingHolidaysFailed,
  removeHolidayLocally,
  addHolidayLocally,
  loadedHolidayLogs,
  loadingHolidayLogsFailed,
  startLoadingHolidayLogs
} = holidayCalendarSlice.actions;

export const holidayCalendarSelector = (state: IState) => state.holidayCalendar;

export const getHolidaySuggestions = (): AppThunk => async (dispatch) => {
  const response = await holidayCalendarService.getHolidaySuggestions();
  if (response.ok()) {
    dispatch(loadedSuggestions(response.data));
  }
};

export const getHolidays = (): AppThunk => async (dispatch) => {
  dispatch(startLoadingHolidays());
  const response = await holidayCalendarService.getHolidays();
  if (response.ok()) {
    dispatch(loadedHolidays(response.data));
  } else {
    response.getError && dispatch(loadingHolidaysFailed(response.getError()));
  }
};

export const createHoliday = (date: string, name: string, onSuccess: () => void, onError: () => void): AppThunk => async (dispatch) => {
  dispatch(requestStarted("CREATE_HOLIDAY"));
  const response = await holidayCalendarService.createHoliday(date, name);
  if (response.ok()) {
    dispatch(requestSuccess());
    onSuccess();
  } else {
    response.getError && dispatch(requestFailed(response.getError()));
    onError();
  }
}

export const removeHoliday = (date: string, onSuccess: () => void, onError: () => void): AppThunk => async (dispatch) => {
  dispatch(requestStarted("REMOVE_HOLIDAY"));
  const response = await holidayCalendarService.removeHoliday(date);
  if (response.ok()) {
    dispatch(requestSuccess());
    onSuccess();
  } else {
    response.getError && dispatch(requestFailed(response.getError()));
    onError();
  }
}

export const getCustomerHolidays = (accountId: string): AppThunk => async (dispatch) => {
  dispatch(startLoadingHolidays());
  const response = await holidayCalendarService.getCustomerHolidays(accountId);
  if (response.ok()) {
    dispatch(loadedHolidays(response.data));
  } else {
    response.getError && dispatch(loadingHolidaysFailed(response.getError()));
  }
};


export const createCustomerHoliday = (accountId: string, date: string, name: string, onSuccess: () => void, onError: () => void): AppThunk => async (dispatch) => {
  dispatch(requestStarted("CREATE_HOLIDAY"));
  const response = await holidayCalendarService.createCustomerHoliday(accountId, date, name);
  if (response.ok()) {
    dispatch(requestSuccess());
    onSuccess();
  } else {
    response.getError && dispatch(requestFailed(response.getError()));
    onError();
  }
}

export const removeCustomerHoliday = (accountId: string, date: string, onSuccess: () => void, onError: () => void): AppThunk => async (dispatch) => {
  dispatch(requestStarted("REMOVE_HOLIDAY"));
  const response = await holidayCalendarService.removeCustomerHoliday(accountId, date);
  if (response.ok()) {
    dispatch(requestSuccess());
    onSuccess();
  } else {
    response.getError && dispatch(requestFailed(response.getError()));
    onError();
  }
}


export const getHolidayLogs = (): AppThunk => async (dispatch) => {
  dispatch(startLoadingHolidayLogs())
  const response = await holidayCalendarService.getHolidayLogs();
  if (response.ok()) {
    dispatch(loadedHolidayLogs(response.data))
  } else {
    response.getError && dispatch(loadingHolidayLogsFailed(response.getError()));
  }
}

export const getCustomerHolidayLogs = (accountId: string): AppThunk => async (dispatch) => {
  dispatch(startLoadingHolidayLogs())
  const response = await holidayCalendarService.getCustomerHolidayLogs(accountId);
  if (response.ok()) {
    dispatch(loadedHolidayLogs(response.data))
  } else {
    response.getError && dispatch(loadingHolidayLogsFailed(response.getError()));
  }
}

export const holidayCalendarReducer = holidayCalendarSlice.reducer;
