import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../../../ui-components/loading/loading";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import ShipmentDetailsState from "../../../slices/shipment-details/ShipmentDetailsState";
import {
  getClaimsDocuments,
  shipmentDetailsSelector
} from "../../../slices/shipment-details/shipmentDetailsSlice";
import { downloadClaimDocument } from "../../../slices/claims/claimsSlice";
import { ClaimDocumentModel } from "../../../app/data/claims/models";
import { UserUtils } from "../../../app/data/user/userUtils";
import { DOCUMENTS_TYPES } from "../../claims/constants";
import "./shipmentClaimsDocuments.scss";

export interface ShipmentClaimsDocumentsProps {
  invoiceNumber: number;
}

const ShipmentClaimsDocuments: React.FC<ShipmentClaimsDocumentsProps> = (props) => {
  const userState: UserState = useSelector(userSelector);
  const dispatch = useDispatch();
  const shipmentDetailsState: ShipmentDetailsState = useSelector(shipmentDetailsSelector);
  const [downloadId, setDownloadId] = useState<string>("");

  const getNormalizedDocuments = (claimDocuments: any) => {
    let normalizedDocuments = [];
    for (const documentTypeObj of DOCUMENTS_TYPES) {
      const filteredDocuments = claimDocuments.filter((obj: ClaimDocumentModel) => obj.type === documentTypeObj.value);
      let i = 0;
      for (const documentObj of filteredDocuments) {
        i++;
        const docType = DOCUMENTS_TYPES.find(type => type.value === documentObj.type)?.label;
        normalizedDocuments.push({
          claimNumber: documentObj.claimNumber,
          name: filteredDocuments.length > 1 ? `${docType} (${i})` : docType,
          id: documentObj.id,
          type: documentObj.type
        })
      }
    }
    return normalizedDocuments;
  };

  const onClickDownloadDocument = (claimNumber: number, id: string, name: string) => {
    setDownloadId(id);
    dispatch(downloadClaimDocument(claimNumber, id, (file: Blob, fileType: string) => {
      let documentfileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = documentfileURL;
      link.download = `${claimNumber}_${name}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloadId("");
    }));
  };

  useEffect(() => {
    props.invoiceNumber && dispatch(getClaimsDocuments(props.invoiceNumber));
  }, [dispatch, props.invoiceNumber]);

  return (
    <>
      {shipmentDetailsState.claimsDocumentsLoading && (
        <div className="xgs-invoice-documents__loading">
          <Loading isLoading={true} />
        </div>
      )}
      {shipmentDetailsState.claimsDocumentsLoaded && (
        <div className="xgs-shipment-details__claims-documents">
          {(!shipmentDetailsState.claimsDocuments || Object.keys(shipmentDetailsState.claimsDocuments).length === 0) && (
            <>There are no claims.</>
          )}
          {shipmentDetailsState.claimsDocuments && Object.keys(shipmentDetailsState.claimsDocuments).length > 0 && (
            <>
              {Object.keys(shipmentDetailsState.claimsDocuments).map((key: string) => (
                <div className="xgs-shipment-details__claims-documents__item">
                  <div className="xgs-shipment-details__claims-documents__claim-number">
                    {UserUtils.isCustomer(userState.profile)
                    ? (
                      <>
                        Claim # <Link to={`${userState.activeSubAccount?.accountNumber ? "/" + userState.activeSubAccount?.accountNumber : ""}/claims/${key}`} target="_blank" className="blue-link">{key}</Link>:
                      </>
                    )
                    : <>Claim # {key}:</>}
                  </div>
                  <div className="xgs-shipment-details__claims-documents__documents">
                    {getNormalizedDocuments(shipmentDetailsState.claimsDocuments[key]).map((claimDocument: any, index: number) => (
                      <div
                        className={`xgs-claims__details__documents__item${downloadId ? " xgs-claims__details__documents__item--loading" : ""}`}
                        key={"claim-document-" + index}
                        onClick={() => !downloadId && onClickDownloadDocument(claimDocument.claimNumber, claimDocument.id, claimDocument.name)}
                      >
                        <XGSIcon
                          icon={XGSIcons.faFile}
                          className="xgs-claims__details__documents__item__icon"
                        />
                        {downloadId === claimDocument.id && (
                          <div className="xgs-claims__details__documents__item__spinner">
                            <Loading isLoading={true} />
                          </div>
                        )}
                        <div className="xgs-claims__details__documents__item__header">{claimDocument.name}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ShipmentClaimsDocuments;
