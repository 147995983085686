import React from "react";
import AgingItem from "../item/agingItem";
import { Aging, agingPeriods } from "../../../app/data/aging/models";

import "./agingItemList.scss";

export interface AgingItemListProps {
  data?: Aging | null;
}

const AgingItemList: React.FC<AgingItemListProps> = (props) => {
  return (
    <div className="xgs-aging-list">
      <AgingItem
        value={props.data?.total}
        period={agingPeriods.total.period}
      />
      <AgingItem
        value={props.data?.aging1}
        period={agingPeriods._1_30.period}
      />
      <AgingItem
        value={props.data?.aging31}
        period={agingPeriods._31_60.period}
      />
      <AgingItem
        value={props.data?.aging61}
        period={agingPeriods._61_90.period}
      />
      <AgingItem
        value={props.data?.aging91}
        period={agingPeriods._91_120.period}
      />
      <AgingItem
        value={props.data?.aging120}
        period={agingPeriods._120_plus.period}
      />
    </div>
  );
};

export default AgingItemList;
