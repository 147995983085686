import React, { useEffect, useState, memo } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import moment from "moment";
import XGSDateInput from "./xgsDateInput";
import { XGSInputBaseProps } from "../../xgs-input/xgsInput";
import dateFormats from "../../../app/data/common/dateFormats";
import "./../../../../node_modules/react-datepicker/dist/react-datepicker.css";
import "./xgsDate.scss";

export interface weekDays {
  mo: boolean;
  tu: boolean;
  we: boolean;
  th: boolean;
  fr: boolean;
  sa: boolean;
  su: boolean;
};

export interface XGSDateProps extends XGSInputBaseProps, ReactDatePickerProps {
  onDateChange: (date: string) => void;
  disableWeekends?: boolean;
  disabledWeekDays?: weekDays | null;
  placeholder?: string;
};

const XGSDate: React.FC<XGSDateProps> = memo(({
  fields,
  isFailed,
  onDateChange,
  value,
  ...props
}) => {
  const [datePickerValue, setDatePickerValue] = useState<Date>(value ? moment(value).toDate() : new Date());

  const onChange = (d: Date) => {
    if (!d) {
      setDatePickerValue(d);
      onDateChange(d);
    } else {
      const momentDate = moment(d);
      setDatePickerValue(momentDate.toDate());
      onDateChange(momentDate.format(dateFormats.uiDate));
    }
  };

  const disableDates = (date: Date) => {
    if (!props.disableWeekends && !props.disabledWeekDays) return true;
    const day = date.getDay();
    if (props.disableWeekends && (!props.disabledWeekDays || Object.keys(props.disabledWeekDays).length === 0)) {
      return day !== 0 && day !== 6;
    }
    if (props.disabledWeekDays) {
      const weekDays = ["su", "mo", "tu", "we", "th", "fr", "sa"];
      return props.disabledWeekDays[weekDays[day] as keyof weekDays];
    }
    return true;
  };

  useEffect(() => {
    setDatePickerValue(value ? moment(value).toDate() : new Date());
  }, [value]);

  return (
    <DatePicker
      {...props}
      onChange={onChange}
      selected={value ? datePickerValue : undefined}
      dateFormat="MM/dd/yyyy"
      filterDate={disableDates}
      calendarStartDay={1}
      autoComplete="off"
      customInput={
        <XGSDateInput          
          placeholderText={props.placeholderText}
        />
      }
      placeholderText={props.placeholder || ""}
    />
  );
});

export default XGSDate;
