import React, { memo, useRef, useState } from "react";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import { useSelector, useDispatch } from "react-redux";
import { dispatchSelectedProbillsSelector } from "../../../../slices/dispatch-planning/dispatchSelectedProbillsSlice";
import SlideOutSidebar from "../../../../ui-components/slide-out-sidebar/slideOutSidebar";
import { Form, Formik } from "formik";
import { ProbillsRouteSchema, defaultStopTimeOptions, initialFormValues, routeDurationOptions, timeOptions, trailerCapacityOptions } from "../../../../app/data/plan-probills/models";
import XGSFormSelect from "../../../../ui-components/form/select/xgsFormSelect";
import { LabelModes } from "../../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledDateInput from "../../../../ui-components/form/date/xgsFormDate";
import XGSFormInput from "../../../../ui-components/form/input/xgsFormInput";
import { DispatchCreateRouteState, DispatchSelectedProbillsState } from "../../../../slices/dispatch-planning/dispatchProbillsState";
import { createRoute, dispatchCreateRouteSlector, resetState } from "../../../../slices/dispatch-planning/dispatchCreateRouteSlice";
import { Link } from "react-router-dom";
import "./planProbillsHeader.scss";
import { toast } from "react-toastify";
import { PlanningTipsState } from "../../../../slices/dispatch-planning/planningTips/planningTipsState";
import { planningTipsSelector } from "../../../../slices/dispatch-planning/planningTips/planningTipsSlice";
import RouteStartDateTip from "./planningTips/routeStartDate/routeStartDateTip";

const PlanProbillsHeader: React.FC = memo(
  () => {
    const dispatch = useDispatch();
    const dispatchSelectedProbillsState: DispatchSelectedProbillsState = useSelector(
      dispatchSelectedProbillsSelector
    );
    const dispatchCreateRouteState: DispatchCreateRouteState = useSelector(
      dispatchCreateRouteSlector
    );
    const planningTipsState: PlanningTipsState = useSelector(
      planningTipsSelector
    );
    const [showSlideOut, setShowSlidOut] = useState(false);
    // const formRef = useRef<FormikProps<ProbillsRouteModel>>(null);
    const formRef = useRef<any>(null);

    const showCreateRoute = () => {
      dispatch(resetState());
      preFillFormValues();
      setShowSlidOut(true);
    }

    const getCurrentDate = () => {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let monthStr = (month < 10 ? "0" : "") + month;
      let day = date.getDate();
      let dayStr = (day < 10 ? "0" : "") + day;
      return `${year}${monthStr}${dayStr}`;
    }

    const preFillFormValues = () => {
      formRef.current?.resetForm();
      let currentDate = getCurrentDate();
      let startDateByEta = planningTipsState.startDate
      if(!startDateByEta || startDateByEta < currentDate){
        startDateByEta = currentDate;
      }
      formRef.current?.setFieldValue("startDate", startDateByEta);
    };
    const getRouteEndTs = (startDate: string, startTime: string, routeDuration: string): Object => {
      let startDateTime = new Date(`${startDate.slice(0, 4)}-${startDate.slice(4, 6)}-${startDate.slice(6, 8)}T${startTime.slice(0, 2)}:${startTime.slice(2, 4)}:${startTime.slice(4, 6)}.000+00:00`);
      startDateTime.setHours(startDateTime.getHours() + (+routeDuration.slice(0, 2)));
      let startTs = startDateTime.toISOString();
      return { endDate: `${startTs.slice(0, 4)}${startTs.slice(5, 7)}${startTs.slice(8, 10)}`, endTime: `${startTs.slice(11, 13)}${startTs.slice(14, 16)}${startTs.slice(17, 19)}` };
    }
    const onSubmit = (values: any) => {
      let selectedProbillsWeight = dispatchSelectedProbillsState.selectedProbills?.reduce((sum, ele) => { return sum += ele.probillWeight }, 0) || 0;
      let trailerCapacity = +values.trailerCapacity;
      if (selectedProbillsWeight > trailerCapacity) {
        toast.error(`Selected Weight(${selectedProbillsWeight}) is more than the Trailer Capacity(${trailerCapacity})`);
        return;
      }
      dispatch(createRoute({
        terminalNumber: dispatchSelectedProbillsState.selectedTerminal,
        ...values,
        ...getRouteEndTs(values.startDate, values.startTime, values.routeDuration),
        startTerminal: dispatchSelectedProbillsState.selectedTerminal,
        endTerminal: dispatchSelectedProbillsState.selectedTerminal,
        routeProbills: dispatchSelectedProbillsState.selectedProbills
      }))
    };

    const getFormattedDate = (date: string) => {
      if (!date || date.length !== 10) return "";
      else return `${date.slice(6, 10)}${date.slice(0, 2)}${date.slice(3, 5)}`;
    }

    return (
      <div>
        {!((!dispatchSelectedProbillsState.selectedProbills === undefined) || (Array.isArray(dispatchSelectedProbillsState.selectedProbills) && dispatchSelectedProbillsState.selectedProbills.length === 0)) &&
          <div className="route-summary-container">
            <div>Route Summary:</div>
            <div><span className="route-summary-titile">Selected Probills: </span>{dispatchSelectedProbillsState.selectedProbills?.length}</div>
            <div><span className="route-summary-titile">Total Weight: </span>{dispatchSelectedProbillsState.selectedProbills?.reduce((sum, ele) => { return sum += ele.probillWeight }, 0).toLocaleString('en', { useGrouping: true })}</div>
            <div><Button
              theme={ButtonThemes.blue}
              onClick={showCreateRoute}
              disabled={(!dispatchSelectedProbillsState.selectedProbills === undefined) || (Array.isArray(dispatchSelectedProbillsState.selectedProbills) && dispatchSelectedProbillsState.selectedProbills.length === 0)}
            >
              {"Create Route  >"}
            </Button>
            </div>
          </div>
        }
        <div>
          <SlideOutSidebar
            header="Create a new Route"
            onClose={() => {
              setShowSlidOut(false);
              dispatch(resetState());
            }}
            show={showSlideOut}
            spinner={false}
            size="small"
          >
            {dispatchCreateRouteState.loaded && <div>
              Created Route- {dispatchCreateRouteState.routeId}, visit <Link
                // to={`/dispatch/active-routes?terminal=${dispatchSelectedProbillsState.selectedTerminal}&routeId=${dispatchCreateRouteState.routeId}`}
                to={`/dispatch/active-routes`}
              >
                Active Routes
              </Link> to edit/view the route
            </div>}
            {dispatchCreateRouteState.loadingFailed && <div>
              Error in creating Route: <span className="create-route-error">{dispatchCreateRouteState.error}</span>
            </div>}
            {(!dispatchCreateRouteState.loaded && !dispatchCreateRouteState.loadingFailed) &&
              <Formik
                validationSchema={ProbillsRouteSchema}
                onSubmit={onSubmit}
                initialValues={initialFormValues}
                enableReinitialize
                innerRef={formRef}
              >
                {(props) => (
                  <Form>
                    <XGSFormInput
                      required={false}
                      name={"routeName"}
                      label="Route Name"
                      placeholder="ex: My Route"
                      labelMode={LabelModes.column}
                    />
                    <div className="create-route-input-container">
                      <LabeledDateInput
                        className="create-route-input"
                        required={true}
                        placeholderText="Select Date"
                        name={"startDate"}
                        disabled={false}
                        labelMode={LabelModes.column}
                        label="Start Date"
                        onDateChange={(value) => {
                          props.setFieldValue("startDate", getFormattedDate(value));
                        }}
                        onChange={() => null}
                      // value={planningTipsState.startDate}
                      />
                      <RouteStartDateTip />
                    </div>
                    <XGSFormSelect
                      required={true}
                      name={"startTime"}
                      options={timeOptions}
                      formik
                      disabled={false}
                      placeholder="Start Time"
                      labelMode={LabelModes.column}
                      label="Start Time"
                      onValueChange={(value) => {
                        props.setFieldValue("startTime", value?.value);
                      }}
                    />
                    <XGSFormSelect
                      required={true}
                      name={"routeDuration"}
                      options={routeDurationOptions}
                      formik
                      disabled={false}
                      placeholder="Route Duration"
                      labelMode={LabelModes.column}
                      label="Route Duration"
                      onValueChange={(value) => {
                        props.setFieldValue("routeDuration", value?.value);
                      }}
                    />
                    <XGSFormInput
                      required={true}
                      name={"trailerNumber"}
                      label="Trailer Number"
                      labelMode={LabelModes.column}
                    />
                    <XGSFormSelect
                      required={true}
                      name={"trailerCapacity"}
                      options={trailerCapacityOptions}
                      formik
                      disabled={false}
                      placeholder="Trailer Capacity"
                      labelMode={LabelModes.column}
                      label="Trailer Capacity"
                      onValueChange={(value) => {
                        props.setFieldValue("trailerCapacity", value?.value);
                      }}
                    />
                    <XGSFormSelect
                      required={true}
                      name={"defaultStopTime"}
                      options={defaultStopTimeOptions}
                      formik
                      disabled={false}
                      placeholder="Default Stop Time"
                      labelMode={LabelModes.column}
                      label="Default Stop Time"
                      onValueChange={(value) => {
                        props.setFieldValue("defaultStopTime", value?.value);
                      }}
                    />
                    <Button
                      className="plan-probills-header-create-route-button"
                      spinner={dispatchCreateRouteState.loading}
                      disabled={!props.isValid || dispatchCreateRouteState.loading}
                      type="submit"
                      theme={ButtonThemes.blue}
                    >
                      Save
                    </Button>
                  </Form>
                )}
              </Formik>
            }
          </SlideOutSidebar>
        </div>
      </div>
    );
  }
);

export default PlanProbillsHeader;
