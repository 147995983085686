import {
  ShippingItemFormModel,
  OptionFormModel
} from "../../app/data/rates/models";
import { XGSSelectOption } from "../../ui-components/xgs-select/xgsSelect";

export interface commodityCode {
  code: string;
  description: string;
}

export default interface RequestRatesState {
  pickupLocation: OptionFormModel | null;
  pickupLocations: XGSSelectOption[];
  pickupDate: string;
  destinationLocation: OptionFormModel | null;
  destinationLocations: XGSSelectOption[];
  directDestination: boolean;
  services: string[];
  items: ShippingItemFormModel[];
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestCreator: string;
  requestError: string;
  cc: commodityCode[];
  result: any;
}

export const initialRequestRatesState: RequestRatesState = {
  pickupLocation: null,
  pickupLocations: [],
  pickupDate: "",
  destinationLocation: null,
  destinationLocations: [],
  directDestination: true,
  services: [],
  items: [],
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestCreator: "",
  requestError: "",
  cc: [],
  result: null
};
