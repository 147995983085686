import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import mixpanel from "mixpanel-browser";
import { AppThunk } from "../../app/store";
import PaymentService from "../../app/data/payment/paymentService";
import { PaymentResult } from "../../app/data/payment/PaymentResult";
import { PaymentUtils } from "../../app/data/payment/paymentUtils";
import { reloadInvoiceDetails } from "../invoice/invoiceSlice";
import { reloadShipmentDetails } from "../shipment-details/shipmentDetailsSlice";
import { TokenRequest } from "../../app/data/payment/TokenRequest";
import { initialPaymentState } from "./PaymentState";
import { reloadAgingInvoices } from "../aging/agingInvoicesSlice";

const paymentService = PaymentService.getInstance();

export const paymentSlice = createSlice({
  name: "payment",
  initialState: initialPaymentState,
  reducers: {
    resetPayment: (state) => initialPaymentState,
    paymentLoadingStarted: (state, {payload}) => {
      state.loading = true;
      state.showPopup = true;
      state.loadingFailed = false;
      state.loaded = false;
      state.error = null;
      if (payload && payload !== 0) {
        state.paymentSum = payload;
      }
    },
    paymentLoadingSucceed: (state, { payload }) => {
      state.loading = false;
      state.loaded = true;
      state.tokenInfo = payload;
    },
    paymentLoadingFailed: (state, {payload}) => {
      state.loading = false;
      state.loadingFailed = true;
      state.tokenInfo = null;
      state.error = payload;
    },
    setPaymentInfo: (state, { payload }) => {
      PaymentUtils.setIsSuccess(payload);
      state.paymentResult = payload;
    },
    setPaymentInitiator: (state, { payload }) => {
      state.paymentInitiator = payload;
    },
    setPaymentHiddenFormSubmitted: (state) => {
      state.hiddenFormSubmitted = true;
    }
  }
});

export const {
  resetPayment,
  paymentLoadingStarted,
  paymentLoadingSucceed,
  paymentLoadingFailed,
  setPaymentInfo,
  setPaymentInitiator,
  setPaymentHiddenFormSubmitted
} = paymentSlice.actions;

export const paymentSelector = (state: IState) => state.payment;

export const getPaymentInfo = (
  invoiceNumber: number,
  subAccountId?: string
): AppThunk => async (dispatch) => {
  dispatch(getMultiplePaymentInfo({
    subAccountId,
    invoiceNumber
  }));
  dispatch(setPaymentInitiator("details"));
};

export const getMultiplePaymentInfo = (
  request: TokenRequest,
  paymentSum?: number
): AppThunk => async (dispatch) => {
  if (!request.invoiceNumbers && !request.invoiceNumber) {
    return;
  }
  dispatch(setPaymentInitiator("list"));
  dispatch(paymentLoadingStarted(paymentSum));
  const response = await paymentService.getPaymentInfo(request);
  if (response.ok()) {
    dispatch(paymentLoadingSucceed(response.data));
  } else {
    dispatch(paymentLoadingFailed(response.getError()));
  }
};

export const savePayment = (
  requestModel: PaymentResult
): AppThunk => async (dispatch) => {
  let retryLimit = 10;
  dispatch(setPaymentInfo(requestModel));
  mixpanel.track("Paid an invoice", {
    "Invoice": requestModel.orderDescription,
    "Amount": requestModel.totalAmount
  });
  while (retryLimit > 0) {
    const response = await paymentService.savePayment(requestModel);
    if (response.ok()) {
      if (requestModel.isSuccess) {
        dispatch(reloadInvoiceDetails());
        dispatch(reloadShipmentDetails());
        dispatch(reloadAgingInvoices());
      }
      break;
    }
    console.error("Couldn't save. Trying again after 3 seconds.");
    await new Promise((r) => setTimeout(r, 3000));
    retryLimit--;
  }
};

const paymentReducer = paymentSlice.reducer;
export default paymentReducer;
