import { TagColor } from "../../app/data/common/tagColor"
import Tag from "../../ui-components/molecules/tag/tag"
import React from "react";

export const getUnitTag = (unit: string) => {
  let color;
  let label;

  switch (unit.toLowerCase()) {
    case "lbs":
      color = TagColor.PURPLE;
      label = "POUND";
      break;
    case "case":
      color = TagColor.GREEN;
      label = "CASE";
      break;
    case "pcs":
      color = TagColor.YELLOW;
      label = "PIECES";
      break;
    case "ft":
      color = TagColor.BLUE;
      label = "FEET";
      break;
    default:
      color = TagColor.GREY;
      label = unit;
      break;
  }

  return <Tag mods={{ color }}>{label}</Tag>;
};
