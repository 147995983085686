import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Wizard, Steps, Step } from "react-albus";
import ContentContainer from "../../../templates/content-container/contentContainer";
import BolShipperStep from "./steps/bolShipper";
import BolConsigneeStep from "./steps/bolConsignee";
import BolPayorStep from "./steps/bolPayor";
import BolItemsStep from "./steps/bolItems";
import BolState from "../../../slices/bol/BolState";
import {
  bolSelector,
  removeBolFromRequestRatesFlag,
  resetBolState
} from "../../../slices/bol/bolSlice";
import "../bol.scss";

const BolCreate: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const bolState: BolState = useSelector(bolSelector);

  useEffect(() => {
    if (!bolState.fromRequestRates) {
      dispatch(resetBolState());
    }

    return () => {
      if (bolState.fromRequestRates) dispatch(removeBolFromRequestRatesFlag());
      dispatch(resetBolState());
    };
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <ContentContainer title="Create Bill of Lading">
      <div className="xgs-bol__create-wizard">
        <Wizard>
          <Steps>
            <Step
              id="bol-shipper-step"
              render={({ next }) => (
                <BolShipperStep next={next} />
              )}
            />
            <Step
              id="bol-consignee-step"
              render={({ next, previous, push }) => (
                <BolConsigneeStep next={next} previous={previous} push={push} />
              )}
            />
            <Step
              id="bol-payor-step"
              render={({ next, previous }) => (
                <BolPayorStep next={next} previous={previous} />
              )}
            />
            <Step
              id="bol-items-step"
              render={({ previous }) => (
                <BolItemsStep previous={previous} />
              )}
            />
          </Steps>
        </Wizard>
      </div>
    </ContentContainer>
  );
};

export default BolCreate;
