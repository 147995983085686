import React from "react";
import { cn } from "../../../../services/common/className";
import Collapsible from "../../../../ui-components/collapsible/collapsible";
import { PicklistDetailItem, PicklistDetailProbill } from "../../../../app/data/picklists/models";
import LoadingTask from "./loadingTask";
import Tag from "../../../../ui-components/molecules/tag/tag";
import PicklistItem from "./picklistItem";
import "./picklistDetails.scss";

export interface LoadGroupProps {
  loadSequence: number;
  probills: PicklistDetailProbill[];
}

const LoadGroup: React.FC<LoadGroupProps> = ({ loadSequence, probills }) => {

  const getProbillLoadingStatus = (probill: PicklistDetailProbill) => {
    if (probill.loadingStatus) {
      if (probill.loadingStatus === "LOADED") return "loaded";
      else if (probill.loadingStatus === "IN_PROGRESS") return "in-progress";
      else return "pending";
    } else {
      return "pending";
    }
  }

  const getProbillStatusTag = (probill: PicklistDetailProbill) => {
    let probillLoadingStatus = getProbillLoadingStatus(probill);
    if (!probillLoadingStatus) return;
    return <Tag mix={cn("picklist-details__loading-task__header__identifiers__item__status")(probillLoadingStatus)}>
      {probillLoadingStatus.toUpperCase()}
    </Tag>
  }
  return (
    <LoadingTask
      name={`Load Sequence ${loadSequence}`}
    >
      {(probills && probills.length) ?
        probills.map((probill: PicklistDetailProbill, probillIdx) => (
          <Collapsible
            key={`pro_${probillIdx}`}
            mix={cn("picklist-details__loading-task__collapsible")({ status: getProbillLoadingStatus(probill) })}
            header={(
              <div className="picklist-details__loading-task__header">
                <div className="picklist-details__loading-task__header__identifiers">
                  <div className="picklist-details__loading-task__header__identifiers__item">
                    Probill:<a
                      className="probill-number"
                      href={"/shipments/" + probill.probillNumber}
                      target="_blank"
                      rel="noopener noreferrer"
                    >{probill.probillNumber}</a>
                    {getProbillStatusTag(probill)}
                  </div>
                  <div className="picklist-details__loading-task__header__identifiers__item">
                    BOL: {probill.bolNumber}
                  </div>
                  <div className="picklist-details__loading-task__header__identifiers__item">
                    PO: {probill.poNumber}
                  </div>
                </div>

                <div className="picklist-details__loading-task__header__info-group">
                  <div className="picklist-details__loading-task__header__info-group__item--col1">
                    <span className="picklist-details__loading-task__header__info-group__item--text-light">
                      Shipper:</span> {`${probill.shipperName} (${probill.shipperNumber})`}
                  </div>
                  <div className="picklist-details__loading-task__header__info-group__item--col2">
                    <span className="picklist-details__loading-task__header__info-group__item--text-light">
                      Consignee:</span> {`${probill.consigneeName} (${probill.consigneeNumber})`}
                  </div>
                </div>
                <div className="picklist-details__loading-task__header__info-group">
                  <div className="picklist-details__loading-task__header__info-group__item--col1">
                    <span className="picklist-details__loading-task__header__info-group__item--text-light">
                      Route:</span> {`${probill.routeDesc} (${probill.routeNumber})`}
                  </div>
                  <div className="picklist-details__loading-task__header__info-group__item--col2">
                    <span className="picklist-details__loading-task__header__info-group__item--text-light">
                      Cons Address:</span> {probill.consigneeAddress}
                  </div>
                </div>
              </div>
            )}
          >
            <div className="picklist-details__loading-task__list">
              {probill.items && probill.items.map((item: PicklistDetailItem, itemIdx) => (
                <li
                  className="picklist-details__loading-task__list-item"
                  key={`${probill.probillNumber}_${itemIdx}`}>
                  <PicklistItem
                    picklistItem={item}
                  />
                </li>
              ))}
            </div>
          </Collapsible>
        )
        ) : ""}
    </LoadingTask>
  )
}

export default LoadGroup;