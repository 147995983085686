import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import XGSIcon from "../icon/xgsIcon";
import XGSRegularIcons from "../icon/xgsRegularIcons";
import XGSIcons from "../icon/xgsIcons";
import Button, { ButtonThemes } from "../button/button";
import XgsInlineSwitchState from "../../slices/xgs-inline-switch/XgsInlineSwitchState";
import {
  setActiveInline,
  xgsInlineSwitchSelector
} from "../../slices/xgs-inline-switch/xgsInlineSwitchSlice";
import useWindowSize from "../../hooks/useWindowsSize";
import "./xgsInlineSwitch.scss";

export interface XGSInlineSwitchProps {
  name: string;
  staticContent: ReactNode;
  formElement: ReactNode;
  onCancel: () => void;
  onSubmit: () => void;
  disabled?: boolean;
  notes?: ReactNode;
}

const XGSInlineSwitch: React.FC<XGSInlineSwitchProps> = (props) => {
  const dispatch = useDispatch();
  const windowWidth = useWindowSize()[0];
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const xgsInlineSwitchState: XgsInlineSwitchState = useSelector(xgsInlineSwitchSelector);

  const onCancel = () => {
    setEditMode(false);
    (windowWidth > 768) && setShowEdit(false);
    props.onCancel();
  };

  useEffect(() => {
    if (!editMode) return;
    setTimeout(() => {
      const formField = document.querySelectorAll(".xgs-inline-switch__content--form-js .xgs-input__field")[0] as HTMLInputElement;
      if (formField) formField.focus();
    }, 200);
  }, [editMode]);

  useEffect(() => {
    if (props.name === xgsInlineSwitchState.activeInlineName) return;
    onCancel();
  // eslint-disable-next-line
  }, [xgsInlineSwitchState.activeInlineName]);

  useEffect(() => {
    !props.disabled && setShowEdit(!(windowWidth > 768));
  }, [props.disabled, windowWidth]);

  return (
    <>
      {!editMode && (
        <div
          className="xgs-inline-switch__content"
          onMouseEnter={() => {
            !props.disabled && setShowEdit(true);
          }}
          onMouseLeave={() => (windowWidth > 768) && setShowEdit(false)}
        >
          {props.staticContent}
          <div className="xgs-inline-switch__content__control">
            {showEdit && (
              <XGSIcon
                icon={XGSRegularIcons.faEdit}
                onClick={() => {
                  dispatch(setActiveInline(props.name));
                  setEditMode(true)
                }}
                className="xgs-inline-switch__content__control__edit-icon"
              />
            )}
          </div>
        </div>
      )}
      {editMode && (
        <>
          <div className="xgs-inline-switch__content xgs-inline-switch__content--form-js">
            {props.formElement}
            <div className="xgs-inline-switch__content__control">
              <Button
                className="xgs-inline-switch__content__control__small-button"
                theme={ButtonThemes.blue}
                spinner={false}
                onClick={() => {
                  setEditMode(false);
                  (windowWidth > 768) && setShowEdit(false);
                  props.onSubmit();
                }}>
                <XGSIcon
                  icon={XGSIcons.faCheck}
                  size="sm"
                />
              </Button>
              <Button
                className="xgs-inline-switch__content__control__small-button"
                type="button"
                theme={ButtonThemes.gray}
                spinner={false}
                onClick={onCancel}
              >
                <XGSIcon
                  icon={XGSIcons.faTimes}
                  size="sm"
                />
              </Button>
            </div>
          </div>
          {props.notes}
        </>
      )}
    </>
  );
};

export default XGSInlineSwitch;
