import React, { memo } from "react";

export interface XGSRadioProps {
  name: string;
  options: {
    key: string;
    label: string;
  }[];
  value?: string;
  onChange: (value: string) => void;
}

const XGSRadio: React.FC<XGSRadioProps> = memo(({
  name,
  options,
  value,
  onChange,
}) => {
  return (
    <div className="xgs-form__radio">
      {options.map(option => (
        <label key={option.key}>
          <input            
            type="radio"
            name={name}
            value={option.key}
            checked={value === option.key}
            onChange={(e) => onChange(e.currentTarget.value)}
          />
          {option.label}
        </label>
      ))}
    </div>
  );
});

export default XGSRadio;
