import React, { createRef } from "react";
import { InvoiceDetailsState } from "../../../slices";
import { useSelector, useDispatch } from "react-redux";
import {
  paymentSelector,
  resetPayment,
} from "../../../slices/payment/paymentSlice";
import XGSPopup from "../../../ui-components/xgs-popup/xgsPopup";
import Loading from "../../../ui-components/loading/loading";
import PaymentReceipt from "./paymentReceipt";
import {
  invoiceDetailsSelector,
} from "../../../slices/invoice/invoiceSlice";
import Amount from "../../../ui-components/molecules/amount/amount";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import PaymentState from "../../../slices/payment/PaymentState";
import ShipmentDetailsState from "../../../slices/shipment-details/ShipmentDetailsState";
import { shipmentDetailsSelector } from "../../../slices/shipment-details/shipmentDetailsSlice";

export interface PaymentCallbackProps {}

const PaymentPopup: React.FC<PaymentCallbackProps> = (props) => {
  const payment: PaymentState = useSelector(paymentSelector);
  const invoiceState: InvoiceDetailsState = useSelector(invoiceDetailsSelector);
  const shipmentState: ShipmentDetailsState = useSelector(shipmentDetailsSelector);
  const dispatch = useDispatch();
  const iframeRef = createRef<HTMLIFrameElement>();

  const closeDropdown = () => {
    if (iframeRef.current) {
      iframeRef.current.innerHTML = "";
    }
    dispatch(resetPayment());
  };

  const getInvoice = () => {
    return invoiceState.invoiceDetails?.invoice || shipmentState.shipment?.invoice
  }

  return (
    <XGSPopup
      title={
        <>
          {!payment.paymentResult && (
            <>
              <span style={{ marginRight: 10 }}>
                Invoice payment {(payment.paymentInitiator === "details") && getInvoice()?.invoiceNumber}:
              </span>
              <Amount amount={payment.paymentInitiator === "details" ? getInvoice()?.openAmount : payment.paymentSum} />
            </>
          )}
        </>
      }
      opened={payment.showPopup}
      onClose={closeDropdown}
      className="payment-popup"
    >
      <Loading isLoading={payment.loading} />
      {payment.loadingFailed && payment.error && (
        <XGSErrorMessage>{payment.error}</XGSErrorMessage>
      )}

      {!payment.paymentResult && (
        <iframe
          ref={iframeRef}
          title="AuthorizeNetIFrame"
          name="iframe-authorize-net"
          id="iframe-authorize-net"
          src=""
          frameBorder="0"
        ></iframe>
      )}
      <PaymentReceipt />
    </XGSPopup>
  );
};

export default PaymentPopup;
