import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { DispatchSelectedProbillsState } from "./dispatchProbillsState";

const initialState: DispatchSelectedProbillsState = {
  selectedTerminal: "",
  selectedProbills: [],
};

export const dispatchSelectedProbillsSlice = createSlice({
  name: "dispatchSelectedProbills",
  initialState,
  reducers: {
    resetSelectedProbillNumbers: (state) => {
      state.selectedProbills = [];
    },
    setSelectedTerminal: (state, { payload }) => {
      state.selectedTerminal = payload;
    },
    setSelectedProbillNumbers: (state, { payload }) => {
      state.selectedProbills = payload;
    },
  },
});

export const { 
  resetSelectedProbillNumbers,
  setSelectedTerminal,
  setSelectedProbillNumbers
} = dispatchSelectedProbillsSlice.actions;

export const dispatchSelectedProbillsSelector = (state: IState) =>
  state.dispatchSelectedProbills;
  
const dispatchSelectedProbillsReducer = dispatchSelectedProbillsSlice.reducer;
export default dispatchSelectedProbillsReducer;
