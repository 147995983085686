import { AgingState, IState } from "..";
import AgingService from "../../app/data/aging/agingService";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";

const initialState: AgingState = {
  loading: false,
  loadingFailed: false,
  loaded: false,
  data: null,
};

const agingService = AgingService.getInstance();

export const agingSlice = createSlice({
  name: "aging",
  initialState,
  reducers: {
    resetAging: state => initialState,
    agingLoadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
    },
    agingLoadingSucceed: (state, { payload }) => {
      state.loading = false;
      state.loaded = true;
      state.data = payload;
    },
    agingLoadingFailed: (state) => {
      state.loading = false;
      state.loadingFailed = true;
      state.data = null;
    }
  }
});

export const {
  resetAging,
  agingLoadingStarted,
  agingLoadingSucceed,
  agingLoadingFailed
} = agingSlice.actions;

export const agingSelector = (state: IState) => state.aging;

export const getAging = (subAccountId?: string): AppThunk => async (
  dispatch
) => {

  if (!subAccountId) {
    console.error("getAging accountId must have value");
    dispatch(agingLoadingFailed());
    return;
  }

  dispatch(agingLoadingStarted());

  const response = await agingService.get(subAccountId);

  if (response.ok()) {
    dispatch(agingLoadingSucceed(response.data));
  } else {
    dispatch(agingLoadingFailed());
  }
};

const agingReducer = agingSlice.reducer;
export default agingReducer;