import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createException, exceptionSelector, getExceptions, getRollPalletDetails, setShowExceptionModal } from "../../slices/exceptions/exceptionsSlice";
import SlideOutSidebar from "../../ui-components/slide-out-sidebar/slideOutSidebar";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import { ACCEPTED_FORMATS } from "../shipments/delivery-records/constants";
import { useDropzone } from "react-dropzone";
import { Form, Formik, FormikProps } from "formik";
import { CreateExceptionRequest, ExceptionIncidentOptions } from "../../app/data/exceptions/models";
import { toast } from "react-toastify";
import XGSFormSelect from "../../ui-components/form/select/xgsFormSelect";
import { LabelModes } from "../../ui-components/molecules/labeled-inputs/labeledInput";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import XGSFormTextarea from "../../ui-components/form/textarea/xgsFormTextarea";
import XGSIcon from "../../ui-components/icon/xgsIcon";
import XGSIcons from "../../ui-components/icon/xgsIcons";
import styles from "../../sass/variables.module.scss";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import Table from "../../ui-components/table/table";
import { getExceptionItemsColumns } from "./getItemColumns";
import ShipmentExceptions from "../shipments/shipment-details/shipmentExceptions";
import "./createException.scss";
import Loading from "../../ui-components/loading/loading";
import { userSelector } from "../../slices/user/userSlice";
import { XGSSelectOption } from "../../ui-components/xgs-select/xgsSelect";

export interface CreateExceptionProps {
  probillNumber: number;
}

const addExceptionFormValues: CreateExceptionRequest = { description: "", incidentType: "" };

const TextError: React.FC<any> = (props) => {
  return <div className="xgs-labeled-input__validation-error">{props.children}</div>;
};


export const CreateException: React.FC<CreateExceptionProps> = (props) => {
  const exceptionState = useSelector(exceptionSelector);
  const dispatch = useDispatch();
  const formRef = useRef<FormikProps<CreateExceptionRequest>>(null);
  const [selectedItems, setSelectedItems] = useState<Set<number>>(new Set<number>());
  const [incidentType, setIncidentType] = useState<XGSSelectOption | null>();
  const userState = useSelector(userSelector);

  useEffect(() => {
    resetForm();
    if (exceptionState.showModal) {
      dispatch(getRollPalletDetails(props.probillNumber));
    }
  }, [dispatch, props.probillNumber, exceptionState.showModal])

  const onClickSelect = (idx: number) => {
    setSelectedItems((currentSet) => {
      const newSet = new Set(currentSet);
      if (newSet.has(idx)) {
        newSet.delete(idx);
      } else {
        newSet.add(idx);
      }
      return newSet;
    });
  };

  const onSubmit = (exceptionFormObject: CreateExceptionRequest) => {
    if (!Array.from(selectedItems?.values()).length) {
      return;
    }

    if (!exceptionFormObject.incidentType) {
      return;
    }

    if (exceptionState.rollPalletDetails === null) return;
    let selectedSerialNumbers = Array.from(selectedItems.values()).map(idx => exceptionState.rollPalletDetails![idx].serialNumber) as string[];
    const fd = new FormData();
    for (let photo of photos) {
      fd.append("files", photo);
    }
    const preparedData: CreateExceptionRequest = {
      probillNumber: props.probillNumber,
      description: exceptionFormObject.description,
      incidentType: exceptionFormObject.incidentType,
      itemSerialNumbers: selectedSerialNumbers,
      terminalNumber: userState.activeTerminal?.id
    };
    fd.append("data", JSON.stringify(preparedData));
    dispatch(
      createException(
        fd,
        () => {
          resetForm();
          toast.info("Exception(s) added succesfully!");
          dispatch(getExceptions(props.probillNumber));
        },
        (errorMessage: string | undefined) => {
          toast.error(errorMessage || "Something went wrong");
        }
      )
    );
  };

  const resetForm = () => {
    setPhotos([]);
    setSelectedItems(new Set());
    formRef.current?.resetForm();
    setIncidentType(null);
  };

  const MAX_SIZE = 4;
  const MAX_PHOTOS = 3;
  const [fileError, setFileError] = useState<string>("");
  const [photos, setPhotos] = useState<any[]>([]);
  const { getInputProps, getRootProps } = useDropzone({
    accept: ACCEPTED_FORMATS,
    maxSize: MAX_SIZE * 1048576,
    maxFiles: MAX_PHOTOS,
    onDrop: (acceptedFiles, fileRejections) => {
      setFileError("");
      if (fileRejections?.length > 0) {
        fileRejections[0].errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setFileError(`Files no larger than ${MAX_SIZE} MB are allowed!`);
          }
          if (err.code === "file-invalid-type") {
            setFileError("Only images of certain formats are allowed!");
          }
          if (err.code === "too-many-files") {
            setFileError(`Maximum ${MAX_PHOTOS} photos are allowed!`);
          }
        });
      }
      if (acceptedFiles.length === 0) return;
      setPhotos(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            deleted: false,
            comment: "",
            internalOnly: false
          })
        )
      );
    }
  });

  if (!userState.activeTerminal) {
    return <SlideOutSidebar header="Create Exception" show={exceptionState.showModal} onClose={() => dispatch(setShowExceptionModal(false))}>
      <XGSErrorMessage>You do not have assigned terminals. Email helpdesk@xgsi.com to request terminal assignment for your account.</XGSErrorMessage>
    </SlideOutSidebar>
  }
  
  return (
    <SlideOutSidebar header="Create Exception" show={exceptionState.showModal} onClose={() => dispatch(setShowExceptionModal(false))}>
      <Loading isLoading={exceptionState.loadingRollPalletDetails}/>
      {exceptionState.loadedRollPalletDetails && (
        <>
          {!exceptionState.rollPalletDetails?.length && <XGSErrorMessage>No valid probill items found</XGSErrorMessage>}
          {!!exceptionState.rollPalletDetails?.length && <div className="xgs-create-exc">
            <Tabs onSelect={() => resetForm()}>
              <TabList>
                <Tab>Add Exception</Tab>
                <Tab>Existing &nbsp;<Loading isLoading={exceptionState.loadingExceptions}/></Tab>
              </TabList>
              <TabPanel>
                <div className="xgs-create-exc__item-table">
                  <Table
                    columns={getExceptionItemsColumns(selectedItems, onClickSelect)}
                    data={exceptionState.rollPalletDetails}
                  />
                </div>
                <TextError>{formRef.current?.errors.itemSerialNumbers}</TextError>
                <Formik innerRef={formRef} onSubmit={onSubmit} initialValues={addExceptionFormValues}>
                  {(props: FormikProps<CreateExceptionRequest>) => (
                    <Form className="xgs-create-exc__form">
                      <XGSFormSelect
                        className="xgs-create-exc__form__item"
                        name="incidentType"
                        label="Incident Type:"
                        labelMode={LabelModes.column}
                        onValueChange={(option) => {
                          props.setFieldValue(`incidentType`, option?.value);
                          setIncidentType(option);
                        }}
                        value={incidentType}
                        required
                        options={ExceptionIncidentOptions}
                        controlled
                      />
                      <div className="xgs-create-exc__form__item">
                        <XGSFormTextarea
                          required
                          maxLength={420}
                          counter={420}
                          name="description"
                          label="Description:"
                          onChange={(value: any) => {
                            props.setFieldValue(`description`, value.target.value);
                          }}
                        />
                      </div>
                      <div className="xgs-create-exc__form__upload">
                        <div className="xgs-create-exc__form__upload__header">Photos:</div>
                        <div {...getRootProps({ className: "xgs-create-exc__form__upload__area" })}>
                          <input {...getInputProps()} />
                          <span className="blue-link">tap, click or drag &amp; drop photos here</span>
                          <div className="xgs-upload__notes">
                            (<strong>JPEG</strong>, <strong>PNG</strong>, <strong>WebP</strong>, <strong>GIF</strong> and{" "}
                            <strong>BMP</strong> formats only)
                          </div>
                          {photos.length > 0 && (
                            <div className="xgs-create-exc__form__upload__message">
                              <span
                                style={{ color: "green" }}
                                className="common-text"
                              >{`${photos.length} photo(s) selected`}</span>
                              <XGSIcon
                                icon={XGSIcons.faTimesCircle}
                                color={styles.red}
                                cursor={"pointer"}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setPhotos([]);
                                }}
                                size="lg"
                              />
                            </div>
                          )}
                          {fileError && <TextError>{fileError}</TextError>}
                        </div>
                      </div>
                      <div className="xgs-create-exc__form__item">
                        <Button
                          type="submit"
                          disabled={Array.from(selectedItems).length === 0 || !formRef.current?.values.description?.trim() || !incidentType}
                          theme={ButtonThemes.blue}
                          spinner={exceptionState.requestStarted}
                        >
                          Add
                        </Button>

                      </div>
                    </Form>
                  )}
                </Formik>
              </TabPanel>
              <TabPanel>
                <ShipmentExceptions probillNumber={props.probillNumber}/>
              </TabPanel>
            </Tabs>
          </div>}
        </>
        )}
    </SlideOutSidebar>
  )
}