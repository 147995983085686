import { BaseStorage } from "./BaseStorage";

class SessionStorage extends BaseStorage {
  private static instance: SessionStorage;

  private constructor() {super(sessionStorage)}

  static getInstance(): SessionStorage {
    if (!SessionStorage.instance) {
      SessionStorage.instance = new SessionStorage();
    }

    return SessionStorage.instance;
  }
}

export default SessionStorage;