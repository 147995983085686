import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export interface SubAccount {
  accountName: string,
  address: string,
  city: string,
  country: string,
  phone?: string,
  state: string,
  zip: string
  contactPersonName?: string;
  accountNumber: number;
  generalEmail?: string;
  services: {
    insideDelivery: boolean;
    limitedAccess: boolean;
    liftGateRequired: boolean;
    detentionFacility: boolean;
    residential: boolean;
    appointmentRequired: boolean;
    sortOrSegregate: boolean;
    paperDriverReceiptRequired: boolean;
  }
};

export const SubAccountRequestSchema: Yup.ObjectSchema<SubAccount> = Yup.object({
  accountName: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(36, "The value must be no more than 36 characters").required(validationMessages.required),
  address: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(36, "The value must be no more than 36 characters").required(validationMessages.required),
  city: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(19, "The value must be no more than 19 characters").required(validationMessages.required),
  state: Yup.string().required(validationMessages.required),
  country: Yup.string().required(validationMessages.required),
  zip: Yup.string().matches(/^\d*$/, validationMessages.notValid).min(5, "The value must be no less than 5 characters").max(5, "The value must be no more than 5 characters").required(validationMessages.required),
  phone: Yup.string().matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, validationMessages.notValid),
  contactPersonName: Yup.string().max(20, "The values must be no more than 20 characters"),
  accountNumber: Yup.number().required(),
  generalEmail: Yup.string().email(validationMessages.emailNotValid).max(60, "The value must be no more than 60 characters"),
  services: Yup.object({
    insideDelivery: Yup.boolean().required(),
    limitedAccess: Yup.boolean().required(),
    liftGateRequired: Yup.boolean().required(),
    detentionFacility: Yup.boolean().required(),
    residential: Yup.boolean().required(),
    appointmentRequired: Yup.boolean().required(),
    sortOrSegregate: Yup.boolean().required()
  }).defined()
}).defined();

export interface ShipperRequestModel {
  name: string,
  address: {
    address1: string,
    city: string,
    state: string,
    postalCode: string
  }
}
export const ShipperRequestSchema: Yup.ObjectSchema<ShipperRequestModel> = Yup.object({
  name: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).required(validationMessages.required).max(25, "The value must be no more than 25 characters"),
  address: Yup.object().shape({
    address1: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).required(validationMessages.required).max(256, "The value must be no more than 256 characters"),
    city: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).required(validationMessages.required).max(64, "The value must be no more than 64 characters"),
    postalCode: Yup.string().matches(/^\d*$/, validationMessages.notValid).min(5, "The value must be no less than 5 digits").max(5, "The value must be no more than 5 digits").required(validationMessages.required),
    state: Yup.string().required(validationMessages.required)
  }).defined()
}).defined();

export interface AccountInformationChangeLog {
  action: "ADD" | "REMOVE" | "EDIT",
  diff: string;
  dateCreated: string;
  userCreated: string;
}