import React from "react";

import "./contentContainerHeader.scss";
import Loading from "../../loading/loading";

export interface ContentContainerHeaderProps {
  title?: string;
  isLoading?: boolean;
  className?: string;
}

const ContentContainerHeader: React.FC<ContentContainerHeaderProps> = (
  props
) => {
  return (
    <div className={`xgs-site__content-container-header ${props.className ? props.className : ""}`}>
      <div className="xgs-site__content-container-header__column xgs-site__content-container-header__column--mobile-alone" style={{ lineHeight: "16px" }}>
        {props.title}
        <Loading isLoading={props.isLoading} />
      </div>
      <div className="xgs-site__content-container-header__bar">
        {props.children}
      </div>
    </div>
  );
};

export default ContentContainerHeader;
