
export default interface ChangeUserPasswordState {
  started: boolean;
  failed: boolean;
  succeed: boolean;
  failReason: string | null;
}

export const initialChangeUserPasswordState: ChangeUserPasswordState = {
  started: false,
  failed: false,
  succeed: false,
  failReason: null
};
