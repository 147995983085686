import api, { ApiResponse } from "../api2";

class PlanProbillsService {
  private source: any;
  private static instance: PlanProbillsService;

  private constructor() { }

  static getInstance(): PlanProbillsService {
    if (!PlanProbillsService.instance) {
      PlanProbillsService.instance = new PlanProbillsService();
    }
    return PlanProbillsService.instance;
  }

  public getPlannableProbills = async (
    rawRequestModel: any
  ): Promise<ApiResponse<any>> => {
    return await api.post('/tms/route/optimization/get-probills', rawRequestModel, {
      
    });
  };

  public createRoute = async (
    rawRequestModel: any
  ): Promise<ApiResponse<any>> => {
    return await api.post('/tms/route/optimization/create', rawRequestModel, {
    });
  };

  public editRouteDetails = async (
    rawRequestModel: any
  ): Promise<ApiResponse<any>> => {
    return await api.post('/tms/route/optimization/edit/details', rawRequestModel, {
    });
  };
}

export default PlanProbillsService;