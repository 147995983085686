import axios, { AxiosResponse } from "axios";

class ServiceCentersStatusService {
  private static instance: ServiceCentersStatusService;
  private constructor() {}
  static getInstance(): ServiceCentersStatusService {
    if (!ServiceCentersStatusService.instance) {
      ServiceCentersStatusService.instance = new ServiceCentersStatusService();
    }
    return ServiceCentersStatusService.instance;
  }

  public getPageContent = async (): Promise<AxiosResponse<any>> => {
    axios.interceptors.response.use((response) => response, (error) => error);
    return await axios.get(process.env.REACT_APP_WEBSITE_URL + "/wp-json/wp/v2/pages?per_page=100");
  };
}

export default ServiceCentersStatusService;
