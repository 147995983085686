import React from "react";
import ContentContainer from "../../../templates/content-container/contentContainer";
import BolList from "../common/list";

const CombinedList: React.FC<{}> = (props) => {
  return (
    <ContentContainer title="BOL">
      <BolList defaultTypes={["BOL"]} />
    </ContentContainer>
  );
};

export default CombinedList;
