import React, { memo } from "react";
import XGSIcon from "../../ui-components/icon/xgsIcon";
import XGSIcons from "../../ui-components/icon/xgsIcons";
import Loading from "../../ui-components/loading/loading";
import "./slideOutSidebar.scss";

export interface SlideOutSidebarProps {
  className?: string;
  header: string;
  onClose?: () => void;
  show: boolean;
  spinner?: boolean;
  size?: "extrasmall" | "small" | "small-605" | "medium" | "large";
}

const SlideOutSidebar: React.FC<SlideOutSidebarProps> = memo(({
  header,
  onClose,
  show,
  ...props
}) => {
  return (
    <div className={`
      xgs-slide-out-sidebar
      ${show ? " xgs-slide-out-sidebar--opened" : " xgs-slide-out-sidebar--closed"}
      ${props.size ? ` xgs-slide-out-sidebar--${props.size}` : ""}
      ${props.className ? ` ${props.className}` : ""}`}>
      <div className="xgs-slide-out-sidebar__header">
        <div>
          {header}
          {props.spinner && (
            <div className="xgs-slide-out-sidebar__spinner">
              <Loading isLoading={true} />
            </div>
          )}
        </div>
        <div className="xgs-slide-out-sidebar__close">
          <XGSIcon
            icon={XGSIcons.faTimes}
            size="lg"
            onClick={onClose && onClose}
          />
        </div>
      </div>
      <div className="xgs-slide-out-sidebar__content">
        {props.children}
      </div>
    </div>
  );
});

export default SlideOutSidebar;
