import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import { toast } from "react-toastify";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import TeamShippersState from "../../../slices/customers/teams/TeamShippersState";
import {
  teamShippersSelector,
  removeShipperFromTeam
} from "../../../slices/customers/teams/teamShippersSlice";
import modalStyle from "../../../app/data/common/modalStyle"

Modal.setAppElement("#root");

export interface RemoveShipperProps {
  teamId: string;
  accountId: string;
  show: boolean;
  onClose: () => void;
}

const RemoveShipper: React.FC<RemoveShipperProps> = (props) => {
  const dispatch = useDispatch();
  const teamShippersState: TeamShippersState = useSelector(teamShippersSelector);

  const removeShipper = () => {
    if (!props.teamId || !props.accountId) return;
    dispatch(removeShipperFromTeam(props.teamId, props.accountId, () => {
      toast.info("The shipper was removed from the customer!");
      props.onClose();
    }));
  };

  return (
    <Modal
      isOpen={props.show}
      style={modalStyle}
    >
      <div className="xgs-teams__modal__header">
        Remove shipper from the customer
      </div>
      <div className="xgs-teams__modal__content">
        <strong>{teamShippersState.shippers.find((account: any) => account.id === props.accountId)?.name}</strong> with account number <strong>{teamShippersState.shippers.find((account: any) => account.id === props.accountId)?.accountNumber}</strong> will be removed from the customer shippers.
      </div>
      <div className="xgs-teams__modal__buttons">
        <Button
          theme={ButtonThemes.blue}
          className="xgs-teams__modal__button"
          onClick={props.onClose}
          type="button"
        >
          Cancel
        </Button>
        <Button
          theme={ButtonThemes.gray}
          className="xgs-teams__modal__button"
          spinner={teamShippersState.request_was_started}
          onClick={removeShipper}
          type="button"
        >
          Remove
        </Button>
      </div>
    </Modal>
  );
};

export default RemoveShipper;
