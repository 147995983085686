import { validationMessages } from "../common/validationMessages";
import * as Yup from "yup";

export interface LinehaulMileageResponse {
  trips: LinehaulTrip[];
}

export interface LinehaulMileageSearchRequest {

}

export interface Terminal {
  terminalNumber: number;
  terminalName: string;
  terminaAirportCode: string;
  city: string;
  state: string;
  zipcode: number;
}

export interface LinehaulLanesResponse {
  lanes: LinehaulLane[];
}

export interface LinehaulLane {
  laneId: string;
  miles: number;
  laneName: string;
  relayDescription: string;
  relayCity: string;
  relayState: string;
  relayZipcode: number;
  destinationTerminalNumber: number;
  destinationTerminalName: string;
  destinationTerminalAirportcode: string;
  originTerminalNumber: number;
  originTerminalName: string;
  originTerminalAirportcode: string;
}

export interface LinehaulActivity {
  event: string;
  note: string;
  createdAt: string;
  createdBy: string;
}

export interface LinehaulTripException {
  type: string;
  duration?: string;
  amount?: string;
  reason: string;
  notes?: string;
  tripId?: string;
  exceptionId?: string;
}

export interface LinehaulTrip {
  tripId: string;
  isReviewed: boolean;
  manifestNumber: number;
  dropNumber: number;
  dispatchedDate: number;
  trailerNumber: number;
  tractorNumber: number;
  carrierNumber: number;
  estimatedMiles: number;
  manifestType: string;
  originTerminalNumber: number;
  originTerminalName: string;
  originTerminalAirportcode: string;
  destinationTerminalNumber: number;
  destinationTerminalName: string;
  destinationTerminalAirportcode: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;

  driver: LinehaulLaneDriver | null;
  lane: LinehaulLane | null;
  exceptions: LinehaulTripException[];
}

export interface LinehaulLaneRequest {
  laneName: string;
  laneId?: string;
  miles: string | null;
  originTerminalNumber?: number | null;
  destinationTerminalNumber?: number | null;
  relay?: boolean;
  relayCity?: string;
  relayState?: string;
  relayZipcode?: string;
  laneNotes?: string;
}

export interface AssignLaneRequest {
  tripId: string;
  laneId: string;
}

export interface LinehaulLaneDriver {
  firstName: string;
  lastName: string;
  driverId: number;
}

export const getLinehaulLaneRequestModel = (isRelay: boolean): Yup.ObjectSchema<LinehaulLaneRequest> => {
  return Yup.object().shape({
    laneName: Yup.string().matches(/\S+/, validationMessages.notValid).max(20, "The value must be no more than 20 characters").required(validationMessages.required),
    miles: Yup.string()
      .matches(/^\d+$/, validationMessages.notValid)
      .max(4, "Value cannot be higher than 9999 miles")
      .test('not-zero', "The value must be greater than zero", value => value !== '0')
      .required(validationMessages.required),
    originTerminalNumber: Yup.number().required(),
    destinationTerminalNumber: Yup.number().required(),
    laneNotes: Yup.string().max(40, "The value must be no more than 40 characters"),
    relay: Yup.boolean(),
    relayCity: isRelay? Yup.string().matches(/\S+/, validationMessages.notValid).max(30, validationMessages.max30chars).required(validationMessages.required) : Yup.string(),
    relayState: isRelay ? Yup.string().max(2, "State code cannot be more than 2 characters").required(validationMessages.required) : Yup.string(),
    relayZipcode: isRelay ? Yup.string().matches(/^\d+$/, validationMessages.notValid).min(5, "The value must be no less than 5 characters").max(5, "The value must be no more than 5 characters").required(validationMessages.required) : Yup.string(),
  }).defined();
}

export const getExceptionValidationModel = (): Yup.ObjectSchema<LinehaulTripException> => {
  const baseValidation = {
    type: Yup.string().required(validationMessages.required),
    duration: Yup.string()
      .matches(/^\d+$/, validationMessages.notValid)
      .max(4, "Cannot be greater than 9999 hours")
      .test('not-zero', "The value must be greater than zero", value => value !== '0')
      .test("duration-required", validationMessages.required, function (value) {
        let type = this.parent.type;
        if (["DELAY", "BREAKDOWN"].includes(type)) {
          return !!value;
        } else {
          return true;
        }
      }),
    reason: Yup.string().matches(/\S+/, validationMessages.notValid).max(40, "Value should not be more than 40 characters").required(validationMessages.required),
    notes: Yup.string().max(60, "Value should not be more than 60 characters"),
    tripId: Yup.string(),
    amount: Yup.string()
      .matches(/^\d+(\.\d{1,2})?$/, validationMessages.notValid)
      .test("too-high", "Amount should be less than 9999", function (value) {
        return !value || Number(value) <= 9999;
      })
      .test("amount-required", validationMessages.required, function (value) {
        let type = this.parent.type;
        // return false; // false means invalid
        if (["REIMBURSEMENT", "OVERNIGHT"].includes(type)) {
          return !!value;
        } else {
          return true;
        }
      })
      
  }
  return Yup.object().shape(baseValidation).defined();
}


