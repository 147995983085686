import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useHref } from "react-router-dom";
import {
  Bar,
  getElementAtEvent,
  Doughnut
} from "react-chartjs-2";
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Decimation,
  Filler,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import XGSIcon from "../../ui-components/icon/xgsIcon";
import XGSRegularIcons from "../../ui-components/icon/xgsRegularIcons";
import SummaryBlock, { SummaryBlockTypes } from "../../ui-components/molecules/summary-block/summaryBlock";
import ConfirmationModal from "../../ui-components/confirmation-modal/confirmationModal";
import MetricsState from "../../slices/metrics/MetricsState";
import {
  metricsSelector,
  getMetrics
} from "../../slices/metrics/metricsSlice";
import { blocks, statuses } from "./constants";
import { Routes } from "../../app/route/RoutesConfig";
import * as styles from "../../sass/variables.module.scss";
import "./metricsBlocks.scss";

ChartJS.register(
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Decimation,
  Filler,
  Legend,
  LinearScale,
  Title,
  Tooltip
);

export interface MetricsBlocksProps {
  accountId: string;
  accountNumber: number;
}

const MetricsBlocks: React.FC<MetricsBlocksProps> = (props) => {
  const dispatch = useDispatch();
  const metricsBlocksState: MetricsState = useSelector(metricsSelector);
  const etaChartRef = useRef<any>(null);
  const loadsChartRef = useRef<any>(null);
  const [showNotes, setShowNotes] = useState<boolean>(false);

  const getBlockName = (type: string) => {
    const blockObj = blocks.find(block => type === block.value);
    return blockObj ? blockObj.label : type;
  };

  const getLabel = (type: string) => {
    const statusObj = statuses.find(status => type === status.value);
    return statusObj ? statusObj.label : type;
  };

  const getColor = (type: string, total: number) => {
    const statusObj = statuses.find(status => type === status.value);
    return (statusObj && statusObj.color) ? statusObj.color : styles.gray4;
  };

  const openShipments = (type: string) => {
    if (!type) return;    
    let href = `${Routes.shipments.filteredList}/${props.accountNumber}/${type}`;
    window.open(href, "_blank");
  };

  const etaChartData = () => {
    let chartDataObj: ChartData<"doughnut"> = {
      labels: [],
      datasets: [
        {
          label: "",
          data: [],
          backgroundColor: [
            "#F2994A",
            "#cc0000",
            "#219653"
          ],
          borderColor: [
            "#F2994A",
            "#cc0000",
            "#219653"
          ],
          borderWidth: 1,
        }
      ]
    };
    if (!metricsBlocksState.response) return chartDataObj;
    const etaData = metricsBlocksState.response.find((o: any) => o.type === "ETA");
    if (!etaData || !etaData.groups) return chartDataObj;

    for (let obj of etaData.groups) {
      if (!chartDataObj.labels) return chartDataObj;
      chartDataObj.labels.push(getLabel(obj.type) || "");
      chartDataObj.datasets[0].data.push(obj.value || 0);
    }

    return chartDataObj;
  };

  const etaChartOptions: ChartOptions<"doughnut"> = {
    responsive: true,
    onHover: (event, chartElement) => {
      const target = event.native?.target as HTMLElement;
      if (!target) return;
      target.style.cursor = chartElement[0] ? "pointer" : "default";
    },    
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: 40
  };

  const loadsChartData = () => {
    let chartDataObj: ChartData<"bar"> = {
      labels: [],
      datasets: [
        {
          label: "",
          data: [],
          backgroundColor: [
            "#424D73",
            "#424D73",
            "#424D73"
          ],
          borderColor: [
            "#424D73",
            "#424D73",
            "#424D73"
          ],
          borderWidth: 1,
          maxBarThickness: 64
        }
      ]
    };
    if (!metricsBlocksState.response) return chartDataObj;
    const loadsData = metricsBlocksState.response.find((o: any) => o.type === "LOADS_SNAPSHOT");
    if (!loadsData || !loadsData.groups) return chartDataObj;
    for (let obj of loadsData.groups.filter((group: any) => group.type !== "DELIVERED")) {
      if (!chartDataObj.labels) return chartDataObj;
      chartDataObj.labels.push(getLabel(obj.type) || "");
      chartDataObj.datasets[0].data.push(obj.value || 0);
    }
    return chartDataObj;
  };

  const loadsChartOptions: ChartOptions<"bar"> = {
    responsive: true,
    onHover: (event, chartElement) => {
      const target = event.native?.target as HTMLElement;
      if (!target) return;
      target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
    plugins: {
      datalabels: {
        color: "white",
        font: {
          weight: "bold"
        }
      },
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    scales: {
      x: {
        stacked: false,
        beginAtZero: true,        
        ticks: {
          display: false
        }
      }
    }
  };

  const onChartClick = (e: any, type: string) => {
    if (!(etaChartRef?.current || loadsChartRef.current) || !e) return;
    const el = getElementAtEvent(type === "ETA" ? etaChartRef.current : loadsChartRef.current, e);
    if (!el[0]) return;
    const i = el[0].index;
    if (i === undefined) return;
    const chartDataObj = type === "ETA" ? etaChartData() : loadsChartData();
    if (!chartDataObj || !chartDataObj.labels || !chartDataObj.labels[i]) return;
    const statusObj = metricsBlocksState.response.find((o: any) => o.type === type);
    openShipments(statusObj.groups[i].type);
  };

  useEffect(() => {
    dispatch(getMetrics(props.accountId));
  }, [dispatch, props.accountId]);

  return (
    <>
      {metricsBlocksState.requestStarted && (
        <SummaryBlock
          type={SummaryBlockTypes.custom}
          loading={true}
        >
          <div className="text-center">Loading metrics...</div>
        </SummaryBlock>
      )}
      {metricsBlocksState.requestSucceed && metricsBlocksState.response.map((block: any) => (
        <SummaryBlock
          type={SummaryBlockTypes.custom}
          key={block.type}
        >
          <div className="xgs-shipments-status">
            <div className="xgs-shipments-status__header">{getBlockName(block.type)}</div>
            {(block.total !== 0) && (
              <>
                {block.type === "ETA" && (
                  <div className="xgs-shipments-status__flex">
                    <div className="xgs-shipments-status__chart">
                      <Doughnut
                        data={etaChartData()}
                        options={etaChartOptions}
                        id="eta-chart"
                        ref={etaChartRef}
                        onClick={(e) => onChartClick(e, block.type)}
                      />
                      <div className="xgs-shipments-status__chart__total">
                        {block.total}
                      </div>
                    </div>
                    <div className="xgs-shipments-status__legend">
                      <>
                        {block.groups.map((group: any) => (
                          <div
                            className={`xgs-shipments-status__legend__item 
                              ${group.value > 0 ? " xgs-shipments-status__link"
                                : ""}
                            `}
                            onClick={() => { (group.value > 0) && openShipments(group.type) }}
                            key={group.type}
                          >
                            <div className="xgs-shipments-status__legend__label">
                              <div
                                className="xgs-shipments-status__legend__label__circle"
                                style={{
                                  backgroundColor: getColor(group.type, block.total)
                                }}
                              ></div>
                              {getLabel(group.type)}
                            </div>
                            <div className="xgs-shipments-status__legend__value">
                              {group.value}
                            </div>
                          </div>
                        ))}
                      </>
                    </div>
                  </div>
                )}
                {block.type === "LOADS_SNAPSHOT" && (
                  <div>
                    <div className="xgs-shipments-status__bar-chart">
                      <Bar
                        data={loadsChartData()}
                        options={loadsChartOptions}
                        plugins={[ChartDataLabels]}
                        ref={loadsChartRef}
                        onClick={(e) => onChartClick(e, block.type)}
                      />
                      <div className="xgs-shipments-status__bar-chart__labels">
                        {block.groups
                          .filter((group: any) => group.type !== "DELIVERED")
                          .map((group: any) => (
                          <div
                            className={`xgs-shipments-status__bar-chart__labels__value  
                              ${group.value > 0 ? " xgs-shipments-status__link"
                                : ""}
                            `}
                            onClick={() => {
                              if (group.value > 0) openShipments(group.type);
                            }}
                            key={group.type}
                          >
                            {getLabel(group.type)}
                          </div>
                        ))}
                      </div>
                      <div
                        className={`xgs-shipments-status__bar-chart__delivered ${block.groups.find((group: any) => group.type === "DELIVERED").value > 0 ? " xgs-shipments-status__link" : ""}`}
                        onClick={() => {
                          if (block.groups.find((group: any) => group.type === "DELIVERED").value > 0) openShipments("DELIVERED");
                        }}
                      >
                        Delivered (last 30 days): <strong>{block.groups.find((group: any) => group.type === "DELIVERED").value}</strong>
                      </div>
                    </div>
                    <div
                      className="xgs-shipments-status__notes-icon"
                      title="Click to view related notes"
                      onClick={() => { setShowNotes(true); }}
                    >
                      <XGSIcon
                        icon={XGSRegularIcons.faQuestionCircle}
                        size="1x"
                      />
                    </div>
                    <ConfirmationModal
                      opened={showNotes}
                      header="Notes"
                      confirmButtonText="Close"
                      onConfirm={() => { setShowNotes(false); }}
                    >
                      <div className="xgs-shipments-status__modal-content">
                        The <strong>DELIVERED</strong> group display data for the last 30 days. The Loads Snapshot does not include WILL CALL and shipments with possible exceptions.
                      </div>
                    </ConfirmationModal>
                  </div>
                )}
              </>
            )}
            {(block.total === 0) && (
              <div className="xgs-shipments-status__no-data">
                There is no data for the selected account. The&nbsp;account must have the payor status and receive shipments.
              </div>
            )}
          </div>
        </SummaryBlock>
      ))}
     </>
  );
};

export default MetricsBlocks;
