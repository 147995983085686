import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SlideOutSidebar from "../../../../ui-components/slide-out-sidebar/slideOutSidebar";
import { PicklistLoadingStatus } from "../../../../app/data/picklists/models";
import { PicklistDetailsState } from "../../../../slices/dispatch-planning/picklists/picklistsState";
import { getPicklistDetails, picklistDetailsSelector } from "../../../../slices/dispatch-planning/picklists/picklistDetailsSlice";
import Loading from "../../../../ui-components/loading/loading";
import LoadGroup from "./loadGroup";
import { toast } from "react-toastify";
import PicklistHeader from "./pickistHeader";
import "./picklistDetails.scss";

export interface PicklistDetailsProps {
  picklist: PicklistLoadingStatus;
  show: boolean;
  onClose: () => void;
}

const PicklistDetails: React.FC<PicklistDetailsProps> = (props) => {
  const dispatch = useDispatch();
  const picklistDetailsState: PicklistDetailsState = useSelector(picklistDetailsSelector);

  useEffect(() => {
    if (!props.picklist || !props.picklist.picklistNumber) return;
    dispatch(getPicklistDetails(
      props.picklist.picklistNumber,
      (error) => {
        toast.error(error);
        props.onClose();
      }
    ));
  },
    [dispatch, props])

  return (
    <SlideOutSidebar
      header={`Picklist details for #${props.picklist ? props.picklist.picklistNumber : ""}`}
      show={props.show}
      size="medium"
      onClose={props.onClose}
    >
      {picklistDetailsState.loading && <div><Loading isLoading={true} /> Loading Picklist details</div>}
      {picklistDetailsState.loaded && <div className="picklist-details">
        <PicklistHeader
          terminalNumber={props.picklist.terminalNumber}
          picklistNumber={props.picklist.picklistNumber}
          driverName={props.picklist.driverName}
          trailerNumber={props.picklist.trailerNumber}
          lastLoadedAt={props.picklist.lastLoadedAt}
          totalItemsCount={props.picklist.totalItemsCount}
          loadedItemsCount={props.picklist.loadedItemsCount}
          isLateLoading={props.picklist.isLateLoading}
          onClose={props.onClose}
        />
        {(picklistDetailsState.picklistDetails && picklistDetailsState.picklistDetails.length)
          ? picklistDetailsState.picklistDetails.map((loadDetail, loadDetailIdx) => {
            return (
              <LoadGroup
                key={`ld_${loadDetailIdx}`}
                loadSequence={loadDetail.loadSequence}
                probills={loadDetail.probills}
              />
            );
          }) : ""}
      </div>
      }
    </SlideOutSidebar>
  )
}

export default PicklistDetails;