import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import {
  AnnouncementModel,
  AnnouncementResponseModel
} from "../../app/data/announcement/models";
import AnnouncementService from "../../app/data/announcement/announcementService";
import { initialAnnouncementState } from "./AnnouncementState";

const announcementService = AnnouncementService.getInstance();

export const announcementSlice = createSlice({
  name: "announcement",
  initialState: initialAnnouncementState,
  reducers: {
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestError = "";
      state.requestCreator = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestError = payload;
    },
    resetAnnouncement: (state, { payload }) => {
      const i = state.announcements.findIndex(announcement => announcement.recipientType === payload);
      if (i !== -1) {
        state.announcements[i] = {
          id: undefined,
          header: "",
          body: "",
          needUserAcceptance: undefined,
          from: undefined,
          to: undefined,
          status: undefined,
          recipientType: payload
        }
      }
    },
    resetErrors: (state) => {
      state.requestFailed = false;
      state.requestError = "";
    },
    storeAnnouncements: (state, { payload }) => {
      state.announcements = payload;
    },
    storeAnnouncement: (state, { payload }) => {
      const i = state.announcements.findIndex(announcement => announcement.recipientType === payload.recipientType);
      if (i !== -1) {
        state.announcements[i] = payload;
      } else {
        state.announcements.push(payload);
      }
    },
    storeUserAnnouncement: (state, { payload }) => {
      state.announcement = payload;
    },
    setAnnouncementChecked: (state, { payload }) => {
      state.announcementChecked = payload;
    }
  }
});

export const {
  requestStarted,
  requestSucceed,
  requestFailed,
  resetAnnouncement,
  resetErrors,
  storeAnnouncements,
  storeAnnouncement,
  storeUserAnnouncement,
  setAnnouncementChecked
} = announcementSlice.actions;

export const announcementSelector = (state: IState) => state.announcement;

export const getAnnouncements = (
  onSuccess?: (response: AnnouncementResponseModel[]) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_DATA"));
  const response = await announcementService.getAnnouncements();
  if (response.ok()) {
    dispatch(storeAnnouncements(response.data));
    dispatch(requestSucceed());
    onSuccess && onSuccess(response.data);
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const createAnnouncement = (
  request: AnnouncementModel,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("SAVE"));
  const response = await announcementService.createAnnouncement(request);
  if (response.ok()) {
    dispatch(storeAnnouncement(response.data));
    dispatch(requestSucceed());
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const updateAnnouncement = (
  request: AnnouncementModel,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("SAVE"));
  const response = await announcementService.updateAnnouncement(request);
  if (response.ok()) {
    dispatch(storeAnnouncement(response.data));
    dispatch(requestSucceed());
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const getUserAnnouncement = (
  onSuccess?: (response: any) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("USER_GET"));
  const response = await announcementService.getUserAnnouncement();
  if (response.ok()) {
    dispatch(storeUserAnnouncement(response.data));
    dispatch(requestSucceed());
    onSuccess && onSuccess(response.data);
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const acceptUserAnnouncement = (
  id: string,
  onSuccess?: (response: any) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("USER_ACCEPT"));
  const response = await announcementService.acceptUserAnnouncement(id);
  if (response.ok()) {
    dispatch(storeUserAnnouncement(response.data));
    dispatch(requestSucceed());
    onSuccess && onSuccess(response.data);
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

const announcementReducer = announcementSlice.reducer;
export default announcementReducer;
