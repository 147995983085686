import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import GeneralSettingsService from "../../app/data/general-settings/generalSettingsService";
import { initialGeneralSettingsState } from "./GeneralSettingsState";

const generalSettingsService = GeneralSettingsService.getInstance();

export const generalSettingsSlice = createSlice({
  name: "generalSettings",
  initialState: initialGeneralSettingsState,
  reducers: {
    resetGeneralSettingsErrors: (state) => {
      state.requestFailed = false;
      state.requestError = null;
    },    
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestCreator = payload;
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestError = null;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = true;
      state.requestSucceed = false;
      state.requestError = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestFailed = false;
      state.requestSucceed = true;
      state.requestError = null;
    },
    setShipmentSearchSettings: (state, { payload }) => {
      state.shipmentSearchSettings = payload;
    },
  }
});

export const {
  resetGeneralSettingsErrors,
  requestStarted,
  requestFailed,
  requestSucceed,
  setShipmentSearchSettings,
} = generalSettingsSlice.actions;

export const generalSettingsSelector = (state: IState) => {
  return state.generalSettings;
};

export const shipmentSearchSettingsSelector = (state: IState) => {
  return state.generalSettings.shipmentSearchSettings;
};

export const getShipmentSearchSettings = (
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_SHIPMENT_SETTINGS"));
  const response = await generalSettingsService.getShipmentSearchSettings();
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setShipmentSearchSettings(response.data));
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const updateShipmentSearchSettings = (
  filterValue: string,
  onSuccess: () => void,
  onError: (error: any) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("UPDATE_SHIPMENT_SETTINGS"));
  const response = await generalSettingsService.updateShipmentSearchSettings(filterValue);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setShipmentSearchSettings({ filterValue }));
    onSuccess();
  } else {
    response.getError && dispatch(requestFailed(response.getError()));
    onError(response.getError ? response.getError() : null);
  }
};

const generalSettingsReducer = generalSettingsSlice.reducer;
export default generalSettingsReducer;
