import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Wizard, Steps, Step } from "react-albus";
import ContentContainer from "../../templates/content-container/contentContainer";
import PickupLocation from "./steps/pickupLocation";
import DestinationLocation from "./steps/destinationLocation";
import SpecialServices from "./steps/specialServices";
import ShippingItems from "./steps/shippingItems";
import ResultPreview from "./steps/resultPreview";
import StepsMilestones, { stepsMilestonesDataType } from "../../ui-components/steps-milestones/stepsMilestones";
import RequestRatesState from "../../slices/rates/RequestRatesState";
import {
  resetRequestRatesState,
  requestRatesSelector
} from "../../slices/rates/requestRatesSlice";
import { services } from "./constants";
import "./requestRates.scss";

const initialStepsData: stepsMilestonesDataType[] = [
  {
    id: "pickup-location-step",
    name: "Pickup Location",
    data: null
  },
  {
    id: "destination-location-step",
    name: "Destination Location",
    data: null
  },
  {
    id: "destination-services-step",
    name: "Destination Services",
    data: null
  },
  {
    id: "shipping-items-step",
    name: "Shipping Items",
    data: null
  },
  {
    id: "result-step",
    name: "Rates",
    data: null
  }
];

const GuidedRequestRates: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const requestRatesState: RequestRatesState = useSelector(requestRatesSelector);
  const [stepsData, setStepsData] = useState<stepsMilestonesDataType[]>(initialStepsData);  

  useEffect(() => {
    dispatch(resetRequestRatesState());
  }, [dispatch]);

  useEffect(() => {
    let index = initialStepsData.findIndex(step => step.id === "pickup-location-step");
    if (index === -1) return;
    initialStepsData[index].data = (requestRatesState.pickupLocation?.label && requestRatesState.pickupDate)
      ? (
          <>
            <div>{requestRatesState.pickupLocation.label}</div>
            <div>{requestRatesState.pickupDate}</div>
          </>
        )
      : null;
    setStepsData([...initialStepsData]);
  }, [requestRatesState.pickupLocation, requestRatesState.pickupDate]);

  useEffect(() => {
    let index = initialStepsData.findIndex(step => step.id === "destination-location-step");
    if (index === -1) return;
    initialStepsData[index].data = requestRatesState.destinationLocation?.label
      ? (
        <div>{requestRatesState.destinationLocation.label}</div>
      )
      : null;
    setStepsData([...initialStepsData]);
  }, [requestRatesState.destinationLocation]);

  useEffect(() => {
    let index = initialStepsData.findIndex(step => step.id === "destination-services-step");
    if (index === -1) return;
    initialStepsData[index].data = requestRatesState.services.length > 0
      ? (
          <>
            {requestRatesState.services.map(service => (
              <div>{services.find(serviceOption => serviceOption.value === service)?.label}</div>
            ))}
          </>
        )
      : null;
    setStepsData([...initialStepsData]);
  }, [requestRatesState.services]);

  useEffect(() => {
    let index = initialStepsData.findIndex(step => step.id === "shipping-items-step");
    if (index === -1) return;
    const itemsCount = requestRatesState.items.filter(item => !item.deleted).length;
    initialStepsData[index].data = itemsCount > 0
      ? <div>{itemsCount} item{itemsCount !== 1 ? "s" : ""}</div>
      : null;
    setStepsData([...initialStepsData]);
  }, [requestRatesState.items]);

  return (
    <ContentContainer
      title="Request Rates"
      header=""
    >
      <div className="xgs-request-rates">
        <Wizard
          render={({ step, push }) => (
            <>
              <Steps>
                <Step
                  id="pickup-location-step"
                  render={({ next }) => (
                    <PickupLocation next={next} />
                  )}
                />
                <Step
                  id="destination-location-step"
                  render={({ next, previous }) => (
                    <DestinationLocation next={next} previous={previous} />
                  )}
                />
                <Step
                  id="destination-services-step"
                  render={({ next, previous }) => (
                    <SpecialServices next={next} previous={previous} />
                  )}
                />
                <Step
                  id="shipping-items-step"
                  render={({ next, previous }) => (
                    <ShippingItems next={next} previous={previous} />
                  )}
                />
                <Step
                  id="result-step"
                  render={({ previous }) => (
                    <ResultPreview previous={previous} push={push} quickMode={false} />
                  )}
                />
              </Steps>
              {step.id !== "result-step" && <StepsMilestones steps={stepsData} step={step} push={push} />}
            </>
          )}
        />
      </div>
    </ContentContainer>
  );
};

export default GuidedRequestRates;
