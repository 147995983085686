import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPortalState } from "../../../slices/user/userSlice";

const StateFetcher: React.FC<any> = ({ children, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!location) return;
    dispatch(getPortalState());
  }, [location, dispatch])

  return children;
};

export default StateFetcher;
