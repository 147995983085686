
export const timezones = [
  {
    label: "United States",
    options: [
      {
        value: "Pacific/Honolulu",
        label: "(GMT-10:00) Honolulu (Hawaii)"
      },
      {
        value: "America/Anchorage",
        label: "(GMT-09:00) Anchorage (Alaska)"
      },
      {
        value: "America/Los_Angeles",
        label: "(GMT-08:00) Los Angeles (Pacific)"
      },
      {
        value: "America/Phoenix",
        label: "(GMT-07:00) Phoenix (MST)"
      },
      {
        value: "America/Denver",
        label: "(GMT-07:00) Denver (Mountain)"
      },
      {
        value: "America/Chicago",
        label: "(GMT-06:00) Chicago (Central)"
      },
      {
        value: "America/New_York",
        label: "(GMT-05:00) New York (Eastern)"
      },
      {
        value: "America/Puerto_Rico",
        label: "(GMT-04:00) Puerto Rico (Atlantic)"
      }
    ]
  },
  {
    label: "Misc",
    options: [
      {
        value: "Pacific/Niue",
        label: "(GMT-11:00) Niue"
      },
      {
        value: "Pacific/Pago_Pago",
        label: "(GMT-11:00) Pago Pago"
      },
      {
        value: "Pacific/Rarotonga",
        label: "(GMT-10:00) Rarotonga"
      },
      {
        value: "Pacific/Tahiti",
        label: "(GMT-10:00) Tahiti"
      },
      {
        value: "Pacific/Marquesas",
        label: "(GMT-09:30) Marquesas"
      },
      {
        value: "Pacific/Gambier",
        label: "(GMT-09:00) Gambier"
      },
      {
        value: "America/Tijuana",
        label: "(GMT-08:00) Pacific Time - Tijuana"
      },
      {
        value: "America/Vancouver",
        label: "(GMT-08:00) Pacific Time - Vancouver"
      },
      {
        value: "America/Whitehorse",
        label: "(GMT-08:00) Pacific Time - Whitehorse"
      },
      {
        value: "Pacific/Pitcairn",
        label: "(GMT-08:00) Pitcairn"
      },
      {
        value: "America/Dawson_Creek",
        label: "(GMT-07:00) Mountain Time - Dawson Creek"
      },
      {
        value: "America/Edmonton",
        label: "(GMT-07:00) Mountain Time - Edmonton"
      },
      {
        value: "America/Hermosillo",
        label: "(GMT-07:00) Mountain Time - Hermosillo"
      },
      {
        value: "America/Mazatlan",
        label: "(GMT-07:00) Mountain Time - Chihuahua, Mazatlan"
      },
      {
        value: "America/Yellowknife",
        label: "(GMT-07:00) Mountain Time - Yellowknife"
      },
      {
        value: "America/Belize",
        label: "(GMT-06:00) Belize"
      },
      {
        value: "America/Costa_Rica",
        label: "(GMT-06:00) Costa Rica"
      },
      {
        value: "America/El_Salvador",
        label: "(GMT-06:00) El Salvador"
      },
      {
        value: "America/Guatemala",
        label: "(GMT-06:00) Guatemala"
      },
      {
        value: "America/Managua",
        label: "(GMT-06:00) Managua"
      },
      {
        value: "America/Mexico_City",
        label: "(GMT-06:00) Central Time - Mexico City"
      },
      {
        value: "America/Regina",
        label: "(GMT-06:00) Central Time - Regina"
      },
      {
        value: "America/Tegucigalpa",
        label: "(GMT-06:00) Central Time - Tegucigalpa"
      },
      {
        value: "America/Winnipeg",
        label: "(GMT-06:00) Central Time - Winnipeg"
      },
      {
        value: "Pacific/Galapagos",
        label: "(GMT-06:00) Galapagos"
      },
      {
        value: "America/Bogota",
        label: "(GMT-05:00) Bogota"
      },
      {
        value: "America/Cancun",
        label: "(GMT-05:00) America Cancun"
      },
      {
        value: "America/Cayman",
        label: "(GMT-05:00) Cayman"
      },
      {
        value: "America/Guayaquil",
        label: "(GMT-05:00) Guayaquil"
      },
      {
        value: "America/Havana",
        label: "(GMT-05:00) Havana"
      },
      {
        value: "America/Iqaluit",
        label: "(GMT-05:00) Eastern Time - Iqaluit"
      },
      {
        value: "America/Jamaica",
        label: "(GMT-05:00) Jamaica"
      },
      {
        value: "America/Lima",
        label: "(GMT-05:00) Lima"
      },
      {
        value: "America/Nassau",
        label: "(GMT-05:00) Nassau"
      },
      {
        value: "America/Panama",
        label: "(GMT-05:00) Panama"
      },
      {
        value: "America/Port-au-Prince",
        label: "(GMT-05:00) Port-au-Prince"
      },
      {
        value: "America/Rio_Branco",
        label: "(GMT-05:00) Rio Branco"
      },
      {
        value: "America/Toronto",
        label: "(GMT-05:00) Eastern Time - Toronto"
      },
      {
        value: "Pacific/Easter",
        label: "(GMT-05:00) Easter Island"
      },
      {
        value: "America/Caracas",
        label: "(GMT-04:30) Caracas"
      },
      {
        value: "America/Asuncion",
        label: "(GMT-03:00) Asuncion"
      },
      {
        value: "America/Barbados",
        label: "(GMT-04:00) Barbados"
      },
      {
        value: "America/Boa_Vista",
        label: "(GMT-04:00) Boa Vista"
      },
      {
        value: "America/Campo_Grande",
        label: "(GMT-03:00) Campo Grande"
      },
      {
        value: "America/Cuiaba",
        label: "(GMT-03:00) Cuiaba"
      },
      {
        value: "America/Curacao",
        label: "(GMT-04:00) Curacao"
      },
      {
        value: "America/Grand_Turk",
        label: "(GMT-04:00) Grand Turk"
      },
      {
        value: "America/Guyana",
        label: "(GMT-04:00) Guyana"
      },
      {
        value: "America/Halifax",
        label: "(GMT-04:00) Atlantic Time - Halifax"
      },
      {
        value: "America/La_Paz",
        label: "(GMT-04:00) La Paz"
      },
      {
        value: "America/Manaus",
        label: "(GMT-04:00) Manaus"
      },
      {
        value: "America/Martinique",
        label: "(GMT-04:00) Martinique"
      },
      {
        value: "America/Port_of_Spain",
        label: "(GMT-04:00) Port of Spain"
      },
      {
        value: "America/Porto_Velho",
        label: "(GMT-04:00) Porto Velho"
      },
      {
        value: "America/Santo_Domingo",
        label: "(GMT-04:00) Santo Domingo"
      },
      {
        value: "America/Thule",
        label: "(GMT-04:00) Thule"
      },
      {
        value: "Atlantic/Bermuda",
        label: "(GMT-04:00) Bermuda"
      },
      {
        value: "America/St_Johns",
        label: "(GMT-03:30) Newfoundland Time - St. Johns"
      },
      {
        value: "America/Araguaina",
        label: "(GMT-03:00) Araguaina"
      },
      {
        value: "America/Argentina/Buenos_Aires",
        label: "(GMT-03:00) Buenos Aires"
      },
      {
        value: "America/Bahia",
        label: "(GMT-03:00) Salvador"
      },
      {
        value: "America/Belem",
        label: "(GMT-03:00) Belem"
      },
      {
        value: "America/Cayenne",
        label: "(GMT-03:00) Cayenne"
      },
      {
        value: "America/Fortaleza",
        label: "(GMT-03:00) Fortaleza"
      },
      {
        value: "America/Godthab",
        label: "(GMT-03:00) Godthab"
      },
      {
        value: "America/Maceio",
        label: "(GMT-03:00) Maceio"
      },
      {
        value: "America/Miquelon",
        label: "(GMT-03:00) Miquelon"
      },
      {
        value: "America/Montevideo",
        label: "(GMT-03:00) Montevideo"
      },
      {
        value: "America/Paramaribo",
        label: "(GMT-03:00) Paramaribo"
      },
      {
        value: "America/Recife",
        label: "(GMT-03:00) Recife"
      },
      {
        value: "America/Santiago",
        label: "(GMT-03:00) Santiago"
      },
      {
        value: "America/Sao_Paulo",
        label: "(GMT-02:00) Sao Paulo"
      },
      {
        value: "Antarctica/Palmer",
        label: "(GMT-03:00) Palmer"
      },
      {
        value: "Antarctica/Rothera",
        label: "(GMT-03:00) Rothera"
      },
      {
        value: "Atlantic/Stanley",
        label: "(GMT-03:00) Stanley"
      },
      {
        value: "America/Noronha",
        label: "(GMT-02:00) Noronha"
      },
      {
        value: "Atlantic/South_Georgia",
        label: "(GMT-02:00) South Georgia"
      },
      {
        value: "America/Scoresbysund",
        label: "(GMT-01:00) Scoresbysund"
      },
      {
        value: "Atlantic/Azores",
        label: "(GMT-01:00) Azores"
      },
      {
        value: "Atlantic/Cape_Verde",
        label: "(GMT-01:00) Cape Verde"
      },
      {
        value: "Africa/Abidjan",
        label: "(GMT+00:00) Abidjan"
      },
      {
        value: "Africa/Accra",
        label: "(GMT+00:00) Accra"
      },
      {
        value: "Africa/Bissau",
        label: "(GMT+00:00) Bissau"
      },
      {
        value: "Africa/Casablanca",
        label: "(GMT+00:00) Casablanca"
      },
      {
        value: "Africa/El_Aaiun",
        label: "(GMT+00:00) El Aaiun"
      },
      {
        value: "Africa/Monrovia",
        label: "(GMT+00:00) Monrovia"
      },
      {
        value: "America/Danmarkshavn",
        label: "(GMT+00:00) Danmarkshavn"
      },
      {
        value: "Atlantic/Canary",
        label: "(GMT+00:00) Canary Islands"
      },
      {
        value: "Atlantic/Faroe",
        label: "(GMT+00:00) Faeroe"
      },
      {
        value: "Atlantic/Reykjavik",
        label: "(GMT+00:00) Reykjavik"
      },
      {
        value: "Etc/GMT",
        label: "(GMT+00:00) GMT (no daylight saving)"
      },
      {
        value: "Europe/Dublin",
        label: "(GMT+00:00) Dublin"
      },
      {
        value: "Europe/Lisbon",
        label: "(GMT+00:00) Lisbon"
      },
      {
        value: "Europe/London",
        label: "(GMT+00:00) London"
      },
      {
        value: "Africa/Algiers",
        label: "(GMT+01:00) Algiers"
      },
      {
        value: "Africa/Ceuta",
        label: "(GMT+01:00) Ceuta"
      },
      {
        value: "Africa/Lagos",
        label: "(GMT+01:00) Lagos"
      },
      {
        value: "Africa/Ndjamena",
        label: "(GMT+01:00) Ndjamena"
      },
      {
        value: "Africa/Tunis",
        label: "(GMT+01:00) Tunis"
      },
      {
        value: "Africa/Windhoek",
        label: "(GMT+02:00) Windhoek"
      },
      {
        value: "Europe/Amsterdam",
        label: "(GMT+01:00) Amsterdam"
      },
      {
        value: "Europe/Andorra",
        label: "(GMT+01:00) Andorra"
      },
      {
        value: "Europe/Belgrade",
        label: "(GMT+01:00) Central European Time - Belgrade"
      },
      {
        value: "Europe/Berlin",
        label: "(GMT+01:00) Berlin"
      },
      {
        value: "Europe/Brussels",
        label: "(GMT+01:00) Brussels"
      },
      {
        value: "Europe/Budapest",
        label: "(GMT+01:00) Budapest"
      },
      {
        value: "Europe/Copenhagen",
        label: "(GMT+01:00) Copenhagen"
      },
      {
        value: "Europe/Gibraltar",
        label: "(GMT+01:00) Gibraltar"
      },
      {
        value: "Europe/Luxembourg",
        label: "(GMT+01:00) Luxembourg"
      },
      {
        value: "Europe/Madrid",
        label: "(GMT+01:00) Madrid"
      },
      {
        value: "Europe/Malta",
        label: "(GMT+01:00) Malta"
      },
      {
        value: "Europe/Monaco",
        label: "(GMT+01:00) Monaco"
      },
      {
        value: "Europe/Oslo",
        label: "(GMT+01:00) Oslo"
      },
      {
        value: "Europe/Paris",
        label: "(GMT+01:00) Paris"
      },
      {
        value: "Europe/Prague",
        label: "(GMT+01:00) Central European Time - Prague"
      },
      {
        value: "Europe/Rome",
        label: "(GMT+01:00) Rome"
      },
      {
        value: "Europe/Stockholm",
        label: "(GMT+01:00) Stockholm"
      },
      {
        value: "Europe/Tirane",
        label: "(GMT+01:00) Tirane"
      },
      {
        value: "Europe/Vienna",
        label: "(GMT+01:00) Vienna"
      },
      {
        value: "Europe/Warsaw",
        label: "(GMT+01:00) Warsaw"
      },
      {
        value: "Europe/Zurich",
        label: "(GMT+01:00) Zurich"
      },
      {
        value: "Africa/Cairo",
        label: "(GMT+02:00) Cairo"
      },
      {
        value: "Africa/Johannesburg",
        label: "(GMT+02:00) Johannesburg"
      },
      {
        value: "Africa/Maputo",
        label: "(GMT+02:00) Maputo"
      },
      {
        value: "Africa/Tripoli",
        label: "(GMT+02:00) Tripoli"
      },
      {
        value: "Asia/Amman",
        label: "(GMT+02:00) Amman"
      },
      {
        value: "Asia/Beirut",
        label: "(GMT+02:00) Beirut"
      },
      {
        value: "Asia/Damascus",
        label: "(GMT+02:00) Damascus"
      },
      {
        value: "Asia/Gaza",
        label: "(GMT+02:00) Gaza"
      },
      {
        value: "Asia/Jerusalem",
        label: "(GMT+02:00) Jerusalem"
      },
      {
        value: "Asia/Nicosia",
        label: "(GMT+02:00) Nicosia"
      },
      {
        value: "Europe/Athens",
        label: "(GMT+02:00) Athens"
      },
      {
        value: "Europe/Bucharest",
        label: "(GMT+02:00) Bucharest"
      },
      {
        value: "Europe/Chisinau",
        label: "(GMT+02:00) Chisinau"
      },
      {
        value: "Europe/Helsinki",
        label: "(GMT+02:00) Helsinki"
      },
      {
        value: "Europe/Istanbul",
        label: "(GMT+02:00) Istanbul"
      },
      {
        value: "Europe/Kaliningrad",
        label: "(GMT+02:00) Kaliningrad"
      },
      {
        value: "Europe/Kiev",
        label: "(GMT+02:00) Kiev"
      },
      {
        value: "Europe/Riga",
        label: "(GMT+02:00) Riga"
      },
      {
        value: "Europe/Sofia",
        label: "(GMT+02:00) Sofia"
      },
      {
        value: "Europe/Tallinn",
        label: "(GMT+02:00) Tallinn"
      },
      {
        value: "Europe/Vilnius",
        label: "(GMT+02:00) Vilnius"
      },
      {
        value: "Africa/Khartoum",
        label: "(GMT+03:00) Khartoum"
      },
      {
        value: "Africa/Nairobi",
        label: "(GMT+03:00) Nairobi"
      },
      {
        value: "Antarctica/Syowa",
        label: "(GMT+03:00) Syowa"
      },
      {
        value: "Asia/Baghdad",
        label: "(GMT+03:00) Baghdad"
      },
      {
        value: "Asia/Qatar",
        label: "(GMT+03:00) Qatar"
      },
      {
        value: "Asia/Riyadh",
        label: "(GMT+03:00) Riyadh"
      },
      {
        value: "Europe/Minsk",
        label: "(GMT+03:00) Minsk"
      },
      {
        value: "Europe/Moscow",
        label: "(GMT+03:00) Moscow"
      },
      {
        value: "Asia/Tehran",
        label: "(GMT+03:30) Tehran"
      },
      {
        value: "Asia/Baku",
        label: "(GMT+04:00) Baku"
      },
      {
        value: "Asia/Dubai",
        label: "(GMT+04:00) Dubai"
      },
      {
        value: "Asia/Tbilisi",
        label: "(GMT+04:00) Tbilisi"
      },
      {
        value: "Asia/Yerevan",
        label: "(GMT+04:00) Yerevan"
      },
      {
        value: "Europe/Samara",
        label: "(GMT+04:00) Samara"
      },
      {
        value: "Indian/Mahe",
        label: "(GMT+04:00) Mahe"
      },
      {
        value: "Indian/Mauritius",
        label: "(GMT+04:00) Mauritius"
      },
      {
        value: "Indian/Reunion",
        label: "(GMT+04:00) Reunion"
      },
      {
        value: "Asia/Kabul",
        label: "(GMT+04:30) Kabul"
      },
      {
        value: "Antarctica/Mawson",
        label: "(GMT+05:00) Mawson"
      },
      {
        value: "Asia/Aqtau",
        label: "(GMT+05:00) Aqtau"
      },
      {
        value: "Asia/Aqtobe",
        label: "(GMT+05:00) Aqtobe"
      },
      {
        value: "Asia/Ashgabat",
        label: "(GMT+05:00) Ashgabat"
      },
      {
        value: "Asia/Dushanbe",
        label: "(GMT+05:00) Dushanbe"
      },
      {
        value: "Asia/Karachi",
        label: "(GMT+05:00) Karachi"
      },
      {
        value: "Asia/Tashkent",
        label: "(GMT+05:00) Tashkent"
      },
      {
        value: "Asia/Yekaterinburg",
        label: "(GMT+05:00) Yekaterinburg"
      },
      {
        value: "Indian/Kerguelen",
        label: "(GMT+05:00) Kerguelen"
      },
      {
        value: "Indian/Maldives",
        label: "(GMT+05:00) Maldives"
      },
      {
        value: "Asia/Calcutta",
        label: "(GMT+05:30) India Standard Time"
      },
      {
        value: "Asia/Colombo",
        label: "(GMT+05:30) Colombo"
      },
      {
        value: "Asia/Katmandu",
        label: "(GMT+05:45) Katmandu"
      },
      {
        value: "Antarctica/Vostok",
        label: "(GMT+06:00) Vostok"
      },
      {
        value: "Asia/Almaty",
        label: "(GMT+06:00) Almaty"
      },
      {
        value: "Asia/Bishkek",
        label: "(GMT+06:00) Bishkek"
      },
      {
        value: "Asia/Dhaka",
        label: "(GMT+06:00) Dhaka"
      },
      {
        value: "Asia/Omsk",
        label: "(GMT+06:00) Omsk, Novosibirsk"
      },
      {
        value: "Asia/Thimphu",
        label: "(GMT+06:00) Thimphu"
      },
      {
        value: "Indian/Chagos",
        label: "(GMT+06:00) Chagos"
      },
      {
        value: "Asia/Rangoon",
        label: "(GMT+06:30) Rangoon"
      },
      {
        value: "Indian/Cocos",
        label: "(GMT+06:30) Cocos"
      },
      {
        value: "Antarctica/Davis",
        label: "(GMT+07:00) Davis"
      },
      {
        value: "Asia/Bangkok",
        label: "(GMT+07:00) Bangkok"
      },
      {
        value: "Asia/Hovd",
        label: "(GMT+07:00) Hovd"
      },
      {
        value: "Asia/Jakarta",
        label: "(GMT+07:00) Jakarta"
      },
      {
        value: "Asia/Krasnoyarsk",
        label: "(GMT+07:00) Krasnoyarsk"
      },
      {
        value: "Asia/Saigon",
        label: "(GMT+07:00) Hanoi"
      },
      {
        value: "Asia/Ho_Chi_Minh",
        label: "(GMT+07:00) Ho Chi Minh"
      },
      {
        value: "Indian/Christmas",
        label: "(GMT+07:00) Christmas"
      },
      {
        value: "Antarctica/Casey",
        label: "(GMT+08:00) Casey"
      },
      {
        value: "Asia/Brunei",
        label: "(GMT+08:00) Brunei"
      },
      {
        value: "Asia/Choibalsan",
        label: "(GMT+08:00) Choibalsan"
      },
      {
        value: "Asia/Hong_Kong",
        label: "(GMT+08:00) Hong Kong"
      },
      {
        value: "Asia/Irkutsk",
        label: "(GMT+08:00) Irkutsk"
      },
      {
        value: "Asia/Kuala_Lumpur",
        label: "(GMT+08:00) Kuala Lumpur"
      },
      {
        value: "Asia/Macau",
        label: "(GMT+08:00) Macau"
      },
      {
        value: "Asia/Makassar",
        label: "(GMT+08:00) Makassar"
      },
      {
        value: "Asia/Manila",
        label: "(GMT+08:00) Manila"
      },
      {
        value: "Asia/Shanghai",
        label: "(GMT+08:00) China Time - Beijing"
      },
      {
        value: "Asia/Singapore",
        label: "(GMT+08:00) Singapore"
      },
      {
        value: "Asia/Taipei",
        label: "(GMT+08:00) Taipei"
      },
      {
        value: "Asia/Ulaanbaatar",
        label: "(GMT+08:00) Ulaanbaatar"
      },
      {
        value: "Australia/Perth",
        label: "(GMT+08:00) Western Time - Perth"
      },
      {
        value: "Asia/Pyongyang",
        label: "(GMT+08:30) Pyongyang"
      },
      {
        value: "Asia/Dili",
        label: "(GMT+09:00) Dili"
      },
      {
        value: "Asia/Jayapura",
        label: "(GMT+09:00) Jayapura"
      },
      {
        value: "Asia/Seoul",
        label: "(GMT+09:00) Seoul"
      },
      {
        value: "Asia/Tokyo",
        label: "(GMT+09:00) Tokyo"
      },
      {
        value: "Asia/Yakutsk",
        label: "(GMT+09:00) Yakutsk"
      },
      {
        value: "Pacific/Palau",
        label: "(GMT+09:00) Palau"
      },
      {
        value: "Australia/Adelaide",
        label: "(GMT+10:30) Central Time - Adelaide"
      },
      {
        value: "Australia/Darwin",
        label: "(GMT+09:30) Central Time - Darwin"
      },
      {
        value: "Antarctica/DumontDUrville",
        label: "(GMT+10:00) Dumont D'Urville"
      },
      {
        value: "Asia/Magadan",
        label: "(GMT+10:00) Magadan"
      },
      {
        value: "Asia/Vladivostok",
        label: "(GMT+10:00) Yuzhno-Sakhalinsk"
      },
      {
        value: "Australia/Brisbane",
        label: "(GMT+10:00) Eastern Time - Brisbane"
      },
      {
        value: "Australia/Hobart",
        label: "(GMT+11:00) Eastern Time - Hobart"
      },
      {
        value: "Australia/Sydney",
        label: "(GMT+11:00) Eastern Time - Melbourne, Sydney"
      },
      {
        value: "Pacific/Chuuk",
        label: "(GMT+10:00) Truk"
      },
      {
        value: "Pacific/Guam",
        label: "(GMT+10:00) Guam"
      },
      {
        value: "Pacific/Port_Moresby",
        label: "(GMT+10:00) Port Moresby"
      },
      {
        value: "Pacific/Efate",
        label: "(GMT+11:00) Efate"
      },
      {
        value: "Pacific/Guadalcanal",
        label: "(GMT+11:00) Guadalcanal"
      },
      {
        value: "Pacific/Kosrae",
        label: "(GMT+11:00) Kosrae"
      },
      {
        value: "Pacific/Norfolk",
        label: "(GMT+11:00) Norfolk"
      },
      {
        value: "Pacific/Noumea",
        label: "(GMT+11:00) Noumea"
      },
      {
        value: "Pacific/Pohnpei",
        label: "(GMT+11:00) Ponape"
      },
      {
        value: "Asia/Kamchatka",
        label: "(GMT+12:00) Petropavlovsk-Kamchatskiy"
      },
      {
        value: "Pacific/Auckland",
        label: "(GMT+13:00) Auckland"
      },
      {
        value: "Pacific/Fiji",
        label: "(GMT+13:00) Fiji"
      },
      {
        value: "Pacific/Funafuti",
        label: "(GMT+12:00) Funafuti"
      },
      {
        value: "Pacific/Kwajalein",
        label: "(GMT+12:00) Kwajalein"
      },
      {
        value: "Pacific/Majuro",
        label: "(GMT+12:00) Majuro"
      },
      {
        value: "Pacific/Nauru",
        label: "(GMT+12:00) Nauru"
      },
      {
        value: "Pacific/Tarawa",
        label: "(GMT+12:00) Tarawa"
      },
      {
        value: "Pacific/Wake",
        label: "(GMT+12:00) Wake"
      },
      {
        value: "Pacific/Wallis",
        label: "(GMT+12:00) Wallis"
      },
      {
        value: "Pacific/Apia",
        label: "(GMT+14:00) Apia"
      },
      {
        value: "Pacific/Enderbury",
        label: "(GMT+13:00) Enderbury"
      },
      {
        value: "Pacific/Fakaofo",
        label: "(GMT+13:00) Fakaofo"
      },
      {
        value: "Pacific/Tongatapu",
        label: "(GMT+13:00) Tongatapu"
      },
      {
        value: "Pacific/Kiritimati",
        label: "(GMT+14:00) Kiritimati"
      }
    ]
  }
];
