
export default interface ProfileState {
  update_was_started: boolean;
  update_was_succeed: boolean;
  update_was_failed: boolean;
  update_fail_reason: string | null | undefined;
  password_change_was_started: boolean;
  password_change_was_succeed: boolean;
  password_change_was_failed: boolean;
  password_change_fail_reason: string | null | undefined;
  requestStarted: boolean;
  requestSucceed: boolean;
  requestCreator: string | null;
  requestFailed: boolean;
  requestError?: string | null;
  additionalSettings: any;
}

export const initialProfileState: ProfileState = {
  update_was_started: false,
  update_was_succeed: false,
  update_was_failed: false,
  update_fail_reason: null,
  password_change_was_started: false,
  password_change_was_succeed: false,
  password_change_was_failed: false,
  password_change_fail_reason: null,
  requestStarted: false,
  requestSucceed: false,
  requestCreator: null,
  requestFailed: false,
  requestError: null,
  additionalSettings: null
};
