import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form, Formik, FormikProps } from "formik";
import { toast } from "react-toastify";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";
import "react-quill/dist/quill.snow.css";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import XGSFormTextarea from "../../../ui-components/form/textarea/xgsFormTextarea";
import LabeledInput from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSRadio from "../../../ui-components/xgs-radio/xgs-radio";
import {
  FeatureBannerFormModel,
  FeatureBannerSchema
} from "../../../app/data/feature-banner/models";
import { UserUtils } from "../../../app/data/user/userUtils";
import { Routes } from "../../../app/route/RoutesConfig";
import {
  clearFeatureBanner,
  featureBannerSelector,
  getFeatureBanners,
  saveFeatureBanner,
  uploadImage
} from "../../../slices/feature-banner/featureBannerSlice";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import { BannerTypes } from "../constants";
import "../../../sass/forms.scss";
import "../featureBanner.scss";

const defaultType = "LINK_TO_PAGE";
const defaultButtonLabel = "Read More";

const BANNER_TYPES = [
  { key: BannerTypes.LINK_TO_PAGE, label: "Link to page" },
  { key: BannerTypes.POPUP, label: "Popup" }
];

const IMAGE_MAX_SIZE = 12 * 1024 * 1024; // 12 MB

let initialValues: FeatureBannerFormModel = {
  message: "",
  buttonLabel: defaultButtonLabel,
  type: defaultType,
  url: "",
  popupText: ""
};

interface FeatureBannerFormProps {
  type: "CUSTOMER" | "EMPLOYEE";
};

window.Quill = Quill;
Quill.register("modules/imageResize", ImageResize);

const FeatureBannerForm: React.FC<FeatureBannerFormProps> = ({ type, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const featureBannerFormRef = useRef<any>(null);
  const featureBannerState = useSelector(featureBannerSelector);
  const userState: UserState = useSelector(userSelector);
  const [featureBanner, setFeatureBanner] = useState<any>(undefined);
  const [bannerType, setBannerType] = useState<string>(defaultType);
  const [popupText, setPopupText] = useState<string>("");
  const quillRef = useRef();

  const editorImageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/gif, image/jpeg, image/png");
    input.click();
    input.onchange = () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        if (file.size >= IMAGE_MAX_SIZE) {
          toast.error("Insertion failed because image size must be less than 12 MB!");
          return;
        }
        let fd = new FormData();
        fd.append("file", file);
        let range = quillRef.current.editor.getSelection(true);
        const currentContent = quillRef.current.editor.getText();
        if (currentContent === " "
          || currentContent === ""
          || currentContent === null
          || JSON.stringify(currentContent) === "\"\\n\"") {
          quillRef.current.editor.insertText(0, " ");
          range = quillRef.current.editor.getSelection(true);
        }
        quillRef.current.editor.insertEmbed(range.index, "image", `${window.location.origin}/image-placeholder.gif`);
        quillRef.current.editor.setSelection(range.index + 1);
        dispatch(uploadImage(fd, (data) => {
          quillRef.current.editor.deleteText(range.index, 1);
          if (!data.uri) {
            toast.error("An error occurred while inserting an image!");
            return;
          }
          quillRef.current.editor.insertEmbed(range.index, "image", data.uri);
        }));
      } else {
        toast.error("Insertion failed because the object is not an image!");
      }
    };
  }, [dispatch]);

  const quillModules = useMemo(
    () => ({
      clipboard: {
        matchVisual: false
      },
      toolbar: {
        container: [
          ["bold", "italic", "underline"],
          [{"list": "bullet"}],
          ["image"],
          ["link"],
          ["clean"]
        ],
        handlers: {
          image: editorImageHandler
        }
      },
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize", "Toolbar"]
      }
    }), [editorImageHandler]
  );

  const onSubmit = (data: FeatureBannerFormModel) => {
    console.log(data);
    if (!data.recipientType) data.recipientType = type;
    if (data.type === BannerTypes.LINK_TO_PAGE) data.popupText = "";
    if (data.type === BannerTypes.POPUP) data.url = "";
    dispatch(saveFeatureBanner(data, () => {
      toast.info("The banner was updated!", { autoClose: 3000 });
    }));
  };

  const clearForm = () => {
    dispatch(clearFeatureBanner(type, () => {
      toast.info("The banner was cleared!", { autoClose: 3000 });
      featureBannerFormRef.current.resetForm();
      featureBannerFormRef.current.setFieldValue("message", "");
      featureBannerFormRef.current.setFieldValue("buttonLabel", defaultButtonLabel);
      featureBannerFormRef.current.setFieldValue("type", defaultType);
      setBannerType(defaultType);
      featureBannerFormRef.current.setFieldValue("url", "");
      featureBannerFormRef.current.setFieldValue("popupText", "");
      setPopupText("");
    }));
  };

  const onBannerTypeChange = (v: string) => {
    setBannerType(v);
    featureBannerFormRef.current.setFieldValue("type", v);
  };

  useEffect(() => {
    if (UserUtils.isXGSAdministrator(userState.profile)) {
      dispatch(getFeatureBanners((banners: any[]) => {
        setFeatureBanner(banners.find(banner => banner.recipientType === type));
      }));
    } else {
      history.push(Routes.home);
    }
  }, [dispatch, history, userState.profile, type]);

  useEffect(() => {
    if (!featureBanner) return;
    featureBannerFormRef.current.setFieldValue("message", featureBanner.message || "");
    featureBannerFormRef.current.setFieldValue("buttonLabel", featureBanner.buttonLabel || defaultButtonLabel);
    featureBannerFormRef.current.setFieldValue("type", featureBanner.type || defaultType);
    setBannerType(featureBanner.type || defaultType);
    featureBannerFormRef.current.setFieldValue("url", featureBanner.url || "");
    featureBannerFormRef.current.setFieldValue("popupText", featureBanner.popupText || "");
    setPopupText(featureBanner.popupText || "");
  }, [featureBanner]);

  return (
    <div className="xgs-feature-banner__management">
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={FeatureBannerSchema}
        innerRef={featureBannerFormRef}
      >
        {(props: FormikProps<FeatureBannerFormModel>) => (
          <Form className="form-items xgs-form__container">
            <XGSFormTextarea
              name="message"
              label="Message:"
              required={true}
              className="xgs-feature-banner__management__message"
              counter={150}
              placeholder="Plain text"
            />
            <div className="xgs-form__label" style={{ marginTop: 16 }}>Show details by:</div>
            <XGSRadio
              name="type"
              value={bannerType}
              onChange={onBannerTypeChange}
              options={BANNER_TYPES}
            />
            <XGSFormInput
              type="text"
              name="buttonLabel"
              label="Button Label:"
              required={true}
              labelMode={LabelModes.column}
              className=""
            />
            {bannerType === BannerTypes.LINK_TO_PAGE && (
              <XGSFormInput
                type="text"
                name="url"
                label="URL:"
                required={true}
                labelMode={LabelModes.column}
                className=""
                placeholder="https://somedomain.com/example"
              />
            )}
            {bannerType === BannerTypes.POPUP && (
              <LabeledInput
                label="Popup text:"
                labelMode={LabelModes.column}
                required={true}
                requiredAsteriskDisabled={false}
                isFailed={() => !!(props.touched.popupText && props.errors.popupText)}
                error={props.errors.popupText || ""}
                className="xgs-visual-editor"
              >
                <ReactQuill
                  theme="snow"
                  value={popupText}
                  modules={quillModules}
                  ref={quillRef}
                  onChange={(value, delta, source, editor) => {
                    setPopupText(value);
                    props.setFieldValue("popupText", value);
                    props.setFieldTouched("popupText", true, false);
                  }}
                  onBlur={() => {
                    props.setFieldTouched("popupText", true, false);
                  }}
                  bounds=".xgs-visual-editor"
                />
              </LabeledInput>
            )}
            {featureBannerState.requestFailed && (
              <div style={{ marginTop: 24 }}>
                <XGSErrorMessage>{featureBannerState.requestError}</XGSErrorMessage>
              </div>
            )}
            <div className="xgs-feature-banner__management__buttons">
              <Button
                theme={ButtonThemes.blue}
                className=""
                disabled={!props.isValid || !props.dirty}
                spinner={featureBannerState.requestStarted && featureBannerState.requestCreator === "SAVE"}
                type="button"
                onClick={props.handleSubmit}
              >
                Save
              </Button>
              <Button
                theme={ButtonThemes.gray}
                className=""
                onClick={clearForm}
                disabled={(featureBannerState.requestStarted && featureBannerState.requestCreator === "GET") || !(props.values.message || props.values.url)}
                spinner={featureBannerState.requestStarted && featureBannerState.requestCreator === "CLEAR"}
                type="button"
              >
                Clear
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FeatureBannerForm;
