import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { DataConfirmPath, PathParams } from "./route";
import useQuery from "../../../hooks/useQuery";
import Loading from "../../../ui-components/loading/loading";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import SuccessBlock, { LoginLinkTypes } from "../../../ui-components/success-block/successBlock";
import { getCurrentUser, logout } from "../../../slices/user/userSlice";
import LegacyUserState from "../../../slices/user/LegacyUserState";
import {
  checkToken,
  legacyUserSelector
} from "../../../slices/user/legacyUserSlice";

const ConfirmDataValidate: React.FC = () => {
  const dispatch = useDispatch();
  const legacyUserState: LegacyUserState = useSelector(legacyUserSelector);
  const params = useParams() as PathParams;
  const token = params[DataConfirmPath.token];
  const editProfile = useQuery().get("editProfile");

  useEffect(() => {
    if (legacyUserState.requestStarted
      || legacyUserState.requestSucceed
      || legacyUserState.requestFailed) return;
    mixpanel.reset();
    dispatch(logout("SILENT"));

    token && dispatch(checkToken(token, () => {
      dispatch(getCurrentUser("SIGN_IN", () => {
        if (localStorage.getItem("xgs-access-token")) localStorage.removeItem("xgs-access-token");
        if (localStorage.getItem("xgs-refresh-token")) localStorage.removeItem("xgs-refresh-token");
        if (localStorage.getItem("xgs-id-token")) localStorage.removeItem("xgs-id-token");
      }));
    }));
  }, [dispatch, params, legacyUserState, token, editProfile]);

  return (
    <div style={{
      textAlign: "center",
      marginTop: 32,
      marginLeft: -24
    }}>
      {legacyUserState.requestStarted && (
        <Loading isLoading={true} />
      )}
      {legacyUserState.requestFailed && (
        <XGSErrorMessage className="block-center">
          {legacyUserState.requestFailReason && (<>{legacyUserState.requestFailReason || "Something went wrong"}</>)}
        </XGSErrorMessage>
      )}
      {legacyUserState.requestSucceed && (
        <SuccessBlock loginLinkType={LoginLinkTypes.CUSTOMER} showLoginLink={true}>
          You have successfully verified your email address!
        </SuccessBlock>
      )}
    </div>
  );
};

export default ConfirmDataValidate;
