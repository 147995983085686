import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../ui-components/loading/loading";
import { UnplannableProbillsState } from "../../../../slices/dispatch-planning/unplannable-probills/unplannableProbillsState";
import { changeTheSummaryBucket, unplannableProbillsSelector } from "../../../../slices/dispatch-planning/unplannable-probills/unplannableProbillsSlice";
import { UnplannableProbill, UnplannableProbillsSummaryDesc, UnplannableProbillsSummaryTypes } from "../../../../app/data/unplannable-probills/models";
import Legend from "../../../../ui-components/molecules/legend/legend";
import { ReactComponent as FilePurple } from "../../../../images/file_purple.svg";
import { ReactComponent as ClockYellow } from "../../../../images/clock_yellow.svg";
import { ReactComponent as ClockOrange } from "../../../../images/clock_orange.svg";
import { ReactComponent as ClockCancelRed } from "../../../../images/clock_cancel_red.svg";
import { ReactComponent as ExclamationTriangleRed } from "../../../../images/exclamation-triangle_red.svg";

interface UnplannableProbillsSummaryTileProps {
    type: UnplannableProbillsSummaryTypes;
    isLoading: boolean;
    title: string;
    value: string | number;
}

const getTileIcon = (type: UnplannableProbillsSummaryTypes) => {
    if (type === "total") return <FilePurple />;
    else if (type === "atRisk") return <ClockYellow />;
    else if (type === "catchUp") return <ClockOrange />;
    else if (type === "late") return <ClockCancelRed />;
    else if ( type === "missed") return <ExclamationTriangleRed />;
    else return "";
}

export const UnplannableProbillsSummaryTile: React.FC<UnplannableProbillsSummaryTileProps> = (props) => {
    const dispatch = useDispatch();
    const unplannableProbillsState: UnplannableProbillsState = useSelector(
        unplannableProbillsSelector
    );

    const selectSummaryBucket = (type: UnplannableProbillsSummaryTypes) => {
        if (!type) return;
        if (!unplannableProbillsState || !unplannableProbillsState.unplannableProbills || !unplannableProbillsState.unplannableProbills.length) return;
        let selectedProbillsList: UnplannableProbill[] = [];
        if (type === UnplannableProbillsSummaryTypes.total) {
            selectedProbillsList = unplannableProbillsState.unplannableProbills;
        } else {
            unplannableProbillsState.unplannableProbills.forEach((probill: UnplannableProbill) => {
                if (probill.summaryBucketType === type) {
                    selectedProbillsList.push(probill);
                }
            });
        }
        dispatch(changeTheSummaryBucket({
            selectedSummaryBucket: type,
            selectedProbillsList: selectedProbillsList
        }));
    }

    return (<div className="xgs-unplannable-probills-summary__tile">
        <div className={`icon-container --type-${props.type}`}>
            {getTileIcon(props.type)}
        </div>
        <div className="content">
            <div
                className="values"
            >
                {props.isLoading ?
                    <Loading isLoading={true} /> :
                    <div className="info">{props.value}</div>
                }
                <Legend><>{UnplannableProbillsSummaryDesc[props.type]}</></Legend>
            </div>
            <div className="title">
                <div className="heading">{props.title}</div>

                <div
                    className="apply-btn"
                    onClick={() => selectSummaryBucket(props.type)}
                >Apply</div>
            </div>
        </div>
    </div>
    )
};