
export default interface TeamShippersState {
  request_was_started: boolean;
  request_was_succeed: boolean;
  request_was_failed: boolean;
  request_fail_reason?: string | null;
  request_fail_code?: number | null;

  shippers_fetch_was_started: boolean;
  shippers_fetchPortion_was_started: boolean;
  shippers_fetch_was_succeed: boolean;
  shippers_fetch_was_failed: boolean;
  shippers_fetch_fail_reason?: string | null;

  shippers: any;
  shippersRequest: any;
  shippersFetchedAll: boolean;
}

export const initialTeamShippersState: TeamShippersState = {
  request_was_started: false,
  request_was_succeed: false,
  request_was_failed: false,
  request_fail_reason: null,
  request_fail_code: null,

  shippers_fetch_was_started: false,
  shippers_fetchPortion_was_started: false,
  shippers_fetch_was_succeed: false,
  shippers_fetch_was_failed: false,
  shippers_fetch_fail_reason: null,

  shippers: [],
  shippersRequest: null,
  shippersFetchedAll: false  
};
