import React, { memo, useEffect, useState } from "react";
import "./addStops.scss";
import { useDispatch, useSelector } from "react-redux";
import Button, { ButtonThemes } from "../../../../../ui-components/button/button";
import {  ActiveRoutesState, AddStopsToRouteState, StopsToAddToRouteState } from "../../../../../slices/dispatch-planning/routes/routesState";
import { toast } from "react-toastify";
import { stopsToAddToRouteSelector } from "../../../../../slices/dispatch-planning/routes/stopsToAddToRouteSlice";
import { addStopsToRoute, addStopsToRouteSelector } from "../../../../../slices/dispatch-planning/routes/addStopsToRouteSlice";
import { activeRoutesSelector } from "../../../../../slices/dispatch-planning/routes/activeRoutesSlice";
import XGSIcon from "../../../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../../../ui-components/icon/xgsIcons";

interface AddStopsHeaderProps {
    show: boolean;
    routeId: string;
    onClose?: any;
    terminalNumber: string
}

const AddStopsHeader: React.FC<AddStopsHeaderProps> = memo((props) => {
    const dispatch = useDispatch();
    const stopsToAddToRouteState: StopsToAddToRouteState = useSelector(
        stopsToAddToRouteSelector
    );
    const addStopsToRouteState: AddStopsToRouteState = useSelector(
        addStopsToRouteSelector
    );
    const activeRoutesState: ActiveRoutesState = useSelector(
        activeRoutesSelector
    );
    const [availableCapacity, setAvailableCapacity] = useState<number>(0);

    const getSelectedRouteDetails = (routeId: any) => {
        if (activeRoutesState.activeRoutes && activeRoutesState.activeRoutes.length) {
            let selectedRouteDetails = null;
            activeRoutesState.activeRoutes.forEach(routeObj => {
                if (routeObj.routeId === routeId) selectedRouteDetails = { ...routeObj };
            });
            return selectedRouteDetails;
        } else { return null; }
    }
    useEffect(()=>{
        let routeDetails: any = getSelectedRouteDetails(props.routeId);
        if(!routeDetails) return;
        setAvailableCapacity(routeDetails.trailerCapacity - routeDetails.routeWeight);
        // eslint-disable-next-line
    }, [props.routeId, activeRoutesState.activeRoutes]);

    const addStopsToRouteTry = () => {
        dispatch(addStopsToRoute(
            {
                routeProbills: stopsToAddToRouteState.selectedProbills,
                routeId: props.routeId,
                terminalNumber: props.terminalNumber
            },
            () => { toast.info("Route Edits saved successfully!") },
            (error) => { toast.error(error) },
            () => { props.onClose() }
        ))
    }
    // const checkIfProbillsSelected = () =>{
    //     return !((!stopsToAddToRouteState.selectedProbills === undefined) || (Array.isArray(stopsToAddToRouteState.selectedProbills) && stopsToAddToRouteState.selectedProbills.length === 0));
    // }
    const checkIfProbillsOverweight = () =>{
        let currentWeight = stopsToAddToRouteState.selectedProbills?.reduce((sum, ele) => { return sum += ele.probillWeight }, 0) || 0;
        if(currentWeight > availableCapacity) return true;
        return false;
    }
    return (
        <>
            {!((!stopsToAddToRouteState.selectedProbills === undefined) || (Array.isArray(stopsToAddToRouteState.selectedProbills) && stopsToAddToRouteState.selectedProbills.length === 0)) &&
                <div className="route-summary-container">
                    <div>Selected Probills:</div>
                    <div><span className="route-summary-titile">Number of Probills: </span>{stopsToAddToRouteState.selectedProbills?.length}</div>
                    <div><span className="route-summary-titile">Total Weight: </span>{stopsToAddToRouteState.selectedProbills?.reduce((sum, ele) => { return sum += ele.probillWeight }, 0).toLocaleString('en', { useGrouping: true })} lbs</div>
                    <Button
                        theme={ButtonThemes.blue}
                        onClick={() => addStopsToRouteTry()}
                        spinner = {addStopsToRouteState.loading}
                        disabled={(checkIfProbillsOverweight())}
                    >
                       Add Probills
                    </Button>
                    <div><span className="route-summary-titile">Available Capacityt: </span> {availableCapacity} lbs</div>
                    {(checkIfProbillsOverweight()) ? <div
                    className="add-stops-error-msg"
                    >
                       <span className="add-stops-error-msg-icon"><XGSIcon icon={XGSIcons.faExclamationTriangle} /></span> Overweight
                    </div> : ""}
                </div>
            }
        </>
    );
});

export default AddStopsHeader;