import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import rootReducer from "../slices";
// import watch from "redux-watch";


const store = configureStore({
  reducer: rootReducer
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
/*
const w = watch(() => store.getState());
store.subscribe(
  w((newVal, oldVal) => {
    console.log("newVal", newVal);
    console.log("oldVal", oldVal);
  })
);
*/