import React from "react";

export const AppointmentStepIndicator: React.FC<{ step: number, stepCount: number }> = (props) => {
  return (
    <div className="xgs-create-appt__wizard__step-indicator">
      {Array.from({ length: props.stepCount }, (_, idx) => (
        <div
          key={idx}
          className={`xgs-create-appt__wizard__step-indicator__dot ${
            props.step >= idx
              ? "xgs-create-appt__wizard__step-indicator__dot--completed"
              : ""
          }`}
        />
      ))}
    </div>
  );
};
