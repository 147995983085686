import React, { memo } from "react";
import { useEffect, useRef } from "react";
import { useGoogleMaps } from "react-hook-google-maps";
import { RouteStopsState } from "../../../../slices/dispatch-planning/routes/routesState";
import { routeStopsSelector, routeStopsSetCurrentCost } from "../../../../slices/dispatch-planning/routes/routeStopsSlice";
import { useDispatch, useSelector } from "react-redux";

interface LatLng {
  lat: number,
  lng: number
}

interface RouteMapProps {
  stops: any;
  terminalLocation: any
}

const RouteMap: React.FC<RouteMapProps> = memo((props) => {
  const dispatch = useDispatch();
  const prevMarkersRef = useRef<any>([]);
  const routesStopsState: RouteStopsState = useSelector(
    routeStopsSelector
  );
  const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_KEY || "";

  const { ref, map, google } = useGoogleMaps(
    apiKey,
    {
      zoom: 6,
      center: props.terminalLocation
    }
  );
  useEffect(() => {
    if (map) {
      addMarker(props.terminalLocation, "SC", "XGS SC", []);
      clearMarkers(prevMarkersRef.current);
      map.setCenter(props.terminalLocation);
      let locations: any = [];
      props.stops.forEach((item: any) => {
        if ((!item.stopNumber) && (!routesStopsState.isRouteEditied)) {
          // skip the unassigned stops
          return;
        } else {
          locations.push({
            location: new google.maps.LatLng(item.lat, item.lng),
            stopover: true
          });
        }
      });
      let directionsService = new google.maps.DirectionsService();
      let directionsRenderer = new google.maps.DirectionsRenderer({
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: "#041183",
          strokeOpacity: 0.8,
          strokeWeight: 5,
        },
      });
      directionsRenderer.setMap(map);
      let trafficLayer = new google.maps.TrafficLayer();
      trafficLayer.setMap(map);
      calcRoute(directionsService, directionsRenderer, locations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, google, props]);

  const getProbills = (stop: any) => {
    if (!stop || !stop.probills) return [];
    let probills: any = [];
    stop.probills.forEach((probillObj: any) => {
      if (probillObj && probillObj.probillNumber) {
        probills.push(probillObj.probillNumber);
      }
    });
    return probills;
  }

  const addMarker = (position: LatLng, labelText: string, title: string, probills: Array<any>) => {
    const infowindow = new google.maps.InfoWindow({
      content: `
      <div>
      <div><b>Stop: </b>${labelText}</div>
  
      <div><b>Address: </b>${title}</div>

      <div><b>Probills: </b>${(probills && probills.length) ? probills.join(", ") : ""}</div>
      </div>`,
    });
    const marker = new window.google.maps.Marker({
      position: position,
      map: map,
      title: title,
      icon: {
        path: "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z",
        fillColor: "#041183",
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: "#FFFFFF",
        scale: 2,
        anchor: new google.maps.Point(12, 17),
        labelOrigin: new google.maps.Point(12, 9)
      },
      label: { color: 'white', fontWeight: 'bold', fontSize: '14px', text: labelText }
    });
    marker.addListener("click", () => {
      infowindow.open({
        anchor: marker,
        map,
      });
    });
    return marker
  }
  const clearMarkers = (markers: any) => {
    for (let m of markers) {
      m.setMap(null);
    }
  }
  const getMarkerLabel = (stop: any, index: number): string => {
    return (!routesStopsState.isRouteEditied) ? (!stop.stopNumber || stop.stopNumber === 0) ? "U" : `${stop.stopNumber}` : `${1 + index}`;
  }
  const calcRoute = (directionsService: any, directionsRenderer: any, locations: any) => {
    let request = {
      origin: props.terminalLocation,
      destination: props.terminalLocation,
      waypoints: locations,
      travelMode: "DRIVING"
    };
    directionsService.route(request, function (result: any, status: any) {
      if (status === "OK") {
        let totalDistance = 0;
        let totalDuration = 0;
        let legs = result.routes[0].legs;
        for (let i = 0; i < legs.length; ++i) {
          totalDistance += legs[i].distance.value;
          totalDuration += legs[i].duration.value;
        }
        let totalStopTime = 0;
        props.stops.forEach((stop: any)=>{
          if(stop && stop.details && stop.details.stopTime){
            totalStopTime += stop.details.stopTime.value;
          }
        });
        if(totalStopTime && +totalStopTime){
          totalDuration += totalStopTime;
        }
        directionsRenderer.setDirections(result);
        dispatch(routeStopsSetCurrentCost({totalDistance, totalDuration}));
        props.stops.forEach((markerLocation: any, markerIndex: any) => {
          let marker = addMarker(
            { lat: +markerLocation.lat, lng: +markerLocation.lng },
            getMarkerLabel(markerLocation, markerIndex),
            markerLocation.address,
            getProbills(markerLocation)
          );
          prevMarkersRef.current.push(marker);
        });

      } else {// to-do handle directions failure
      }
    });
  }

  return (
    <div>
      <div ref={ref} style={{ width: "100%", height: "100vh" }} />
    </div>
  );

});

export default RouteMap;
