import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { initialInvoicesState } from "./InvoicesState";
import { InvoicesFilterModel } from "../../app/data/documents/models";
import { AppThunk } from "../../app/store";
import InvoicesService from "../../app/data/documents/invoicesService";
import { SortParams } from "../../app/data/common/models";

const invoicesService = InvoicesService.getInstance();

export const invoicesSlice = createSlice({
  name: "invoices",
  initialState: initialInvoicesState,
  reducers: {
    requestStarted: (state, { payload }) => {
      state.request[payload.requester] = {
        requestStarted: true,
        requestSucceed: false,
        requestFailed: false,
        requestError: null,
      };
    },
    requestSucceed: (state, { payload }) => {
      state.request[payload.requester] = {
        requestStarted: false,
        requestSucceed: true,
        requestFailed: false,
      };      
    },
    requestFailed: (state, { payload }) => {
      state.request[payload.requester] = {
        requestStarted: false,
        requestSucceed: false,
        requestFailed: true,
        requestError: payload.error,
      };
    },
    setInvoicesRequest: (state, { payload }) => {
      state.invoicesRequest = {
        lastIds: payload.lastIds,
        loadedAll: payload.loadedAll,
      }
    },
    setInvoices: (state, { payload }) => {
      state.invoices = payload;
    },
    addInvoices: (state, { payload }) => {
      state.invoices = [...state.invoices, ...payload];
    },
    setInvoicesSummary: (state, { payload }) => {
      state.summary = payload;
    },
  }
});

export const {
  requestStarted,
  requestSucceed,
  requestFailed,
  setInvoicesRequest,
  setInvoices,
  addInvoices,
  setInvoicesSummary,
} = invoicesSlice.actions;

export const getInvoices = (
  filter: InvoicesFilterModel,
  sorting: SortParams | null,
  lastIds?: string | null,
): AppThunk => async (dispatch) => {
  dispatch(requestStarted({ requester: lastIds ? "GET_INVOICES_PORTION" : "GET_INVOICES" }));
  const response = await invoicesService.getInvoices(filter, sorting, lastIds);
  if (response.isCancel?.()) return;
  if (response.ok()) {
    dispatch(setInvoicesRequest({
      lastIds: response.data.scroll.lastIds,
      loadedAll: response.data.content.length < 50,
    }));
    dispatch(lastIds ? addInvoices(response.data.content) : setInvoices(response.data.content));
    dispatch(requestSucceed({ requester: lastIds ? "GET_INVOICES_PORTION" : "GET_INVOICES" }));
  } else {
    dispatch(requestFailed({ requester: lastIds ? "GET_INVOICES_PORTION" : "GET_INVOICES", error: response.getError ? response.getError() : "Error"}));
  }
};

export const getInvoicesSummary = (): AppThunk => async (dispatch) => {
  dispatch(requestStarted({ requester: "GET_INVOICES_SUMMARY"}));
  const response = await invoicesService.getInvoicesSummary();
  if (response.isCancel?.()) return;
  if (response.ok()) {    
    dispatch(setInvoicesSummary(response.data));
    dispatch(requestSucceed({ requester: "GET_INVOICES_SUMMARY"}));
  } else {
    dispatch(requestFailed({ requester: "GET_INVOICES_SUMMARY", error: response.getError ? response.getError() : "Error" }));
  }
};

export const invoicesSelector = (state: IState) => state.invoices;

const invoicesReducer = invoicesSlice.reducer;
export default invoicesReducer;
