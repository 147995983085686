
export default interface NotificationsState {
  requestStarted: boolean;
  requestSucceed: boolean;
  requestCreator: string | null;
  requestFailed: boolean;
  requestError?: string | null;
  settings: any;
}

export const initialNotificationsState: NotificationsState = {
  requestStarted: false,
  requestSucceed: false,
  requestCreator: null,
  requestFailed: false,
  requestError: null,
  settings: null
};
