import React, {
  ReactElement,
  useEffect,
  useRef,
  useState
} from "react";

import ReactDOMServer from 'react-dom/server'
import { LOCATION_MARKER, MARKER_STROKE_COLOR } from "../../../services/map";

interface XGSCustomMarkerProps extends google.maps.MarkerOptions {
  fillColor?: string;
  path?: string;
  onClick?: () => void;
  tooltip?:  ReactElement;
};

const XGSCustomMarker: React.FC<XGSCustomMarkerProps> = (props) => {
  const  { tooltip, map } = props;

  const [marker, setMarker] = useState<google.maps.Marker>();
  const [markerTooltip, setMarkerTooltip] = useState<google.maps.InfoWindow>();

  const openTooltipListener = useRef<google.maps.MapsEventListener>();
  const closeTooltipListener = useRef<google.maps.MapsEventListener>();  

  useEffect(() => {
    const svgMarker = {
      path: props.path || LOCATION_MARKER.PATH,
      fillColor: props.fillColor || LOCATION_MARKER.COLOR,
      fillOpacity: 1,
      strokeWeight: 2,
      strokeColor: MARKER_STROKE_COLOR,
      anchor: new google.maps.Point(12, 17),
      labelOrigin: new google.maps.Point(14, 14)
    };
    if (!marker) {
      const markerObj = new google.maps.Marker({
        icon: svgMarker
      });
      props.onClick && markerObj.addListener("click", props.onClick);
      setMarker(markerObj);
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker, props.label, props.onClick, props.fillColor, props.path]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(props);
    }
  }, [marker, props]);

  useEffect(() => {
    if (tooltip) {
      const content = ReactDOMServer.renderToString(tooltip);
      
      const infowindow = new google.maps.InfoWindow({
        maxWidth: 220,
        content,
      });

      setMarkerTooltip(infowindow);      
    } else {           
      setMarkerTooltip(undefined);
    }    
  }, [tooltip]);  

  useEffect(() => {
    if (marker && markerTooltip) {
      if (openTooltipListener.current && closeTooltipListener.current) {
        google.maps.event.removeListener(openTooltipListener.current);
        google.maps.event.removeListener(closeTooltipListener.current);
      }

      const canHover = window.matchMedia('(hover: hover)').matches;      

      if (canHover) {
        openTooltipListener.current = marker.addListener("mouseover", () => {
          markerTooltip.open({
            anchor: marker,
            map: map,
            shouldFocus: false,
          });
        });

        closeTooltipListener.current = marker.addListener("mouseout", () => {
          markerTooltip.close();
        });
      } else {
        openTooltipListener.current = marker.addListener("click", () => {
          markerTooltip.open({
            anchor: marker,
            map: map,
            shouldFocus: false,
          });
        });
      }      
    }
  }, [map, marker, markerTooltip]);

  return null;
};

export default XGSCustomMarker;
