
export default interface ForgotPasswordState {
  started: boolean;
  failed: boolean;
  succeed: boolean;
  failReason: string | null;
}

export const initialForgotPasswordState: ForgotPasswordState = {
  started: false,
  failed: false,
  succeed: false,
  failReason: null
};
