import React from "react";
import { useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import { InvoiceFilterModel, InvoiceFilterSchema } from "../../../app/data/invoice/models";
import { agingInvoicesSelector } from "../../../slices/aging/agingInvoicesSlice";
import { AgingInvoicesState } from "../../../slices";

export interface AgingInvoicesFilterProps {
  onSearch: (filter: InvoiceFilterModel) => void;
}

const initialValues = {
  invoiceNumber: null,
};

const AgingInvoicesFilter: React.FC<AgingInvoicesFilterProps> = (props) => {
  const agingInvoicesState: AgingInvoicesState = useSelector(agingInvoicesSelector);

  return (
    <div className="xgs-aging-invoices__filter">
      <Formik
        onSubmit={props.onSearch}
        initialValues={initialValues}
        validationSchema={InvoiceFilterSchema}
      >
        {(props: FormikProps<InvoiceFilterModel>) => (
          <Form className="xgs-list__controls__search">
            <XGSFormInput
              name="invoiceNumber"
              label="Invoice #:"
              labelMode={LabelModes.column}
              className="xgs-labeled-input"
              type="text"
              placeholder="12345678"
              maxLength={8}
            />

            <Button
              type="submit"
              className="xgs-list__controls__button"
              theme={ButtonThemes.blue}
              disabled={agingInvoicesState.loading}
            >
              Search
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AgingInvoicesFilter;
