import {
  TransactionsItemModel,
  TransactionsListRequestModel
} from "../../../app/data/warehouse/models";

export default interface TransactionsState {
  request: TransactionsListRequestModel;
  initialRequestStarted: boolean;
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError?: string | null;
  requestedAll: boolean;  
  list: TransactionsItemModel[];
}

export const initialTransactionsState: TransactionsState = {
  request: {
    from: "",
    to: ""
  },
  initialRequestStarted: false,
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestError: null,
  requestedAll: false,  
  list: []
};
