import React, { memo } from "react";
import "./addProbills.scss";
import { useDispatch, useSelector } from "react-redux";
import Button, { ButtonThemes } from "../../../../../ui-components/button/button";
import { probillsToAddToRouteSelector } from "../../../../../slices/dispatch-planning/routes/probillsToAddToRouteSlice";
import { AddProbillsToRouteState, ProbillsToAddToRouteState } from "../../../../../slices/dispatch-planning/routes/routesState";
import { addProbillsToRoute, addProbillsToRouteSelector } from "../../../../../slices/dispatch-planning/routes/addProbillsToRouteSlice";
import { toast } from "react-toastify";

interface AddProbillsHeaderProps {
    show: boolean;
    routeId: string;
    stopNumber: number;
    stopInternalId: number;
    onClose?: any;
    terminalNumber: string
}

const AddProbillsHeader: React.FC<AddProbillsHeaderProps> = memo((props) => {
    const dispatch = useDispatch();
    const probillsToAddToRouteState: ProbillsToAddToRouteState = useSelector(
        probillsToAddToRouteSelector
    );
    const addProbillsToRouteState: AddProbillsToRouteState = useSelector(
        addProbillsToRouteSelector
    );
    const addProbillsToRouteTry = () => {
        dispatch(addProbillsToRoute(
            {
                routeProbills: probillsToAddToRouteState.selectedProbills,
                routeId: props.routeId,
                stopNumber: props.stopNumber,
                stopInternalId: props.stopInternalId,
                terminalNumber: props.terminalNumber
            },
            () => { 
                toast.info("Route Edits saved successfully!")
            },
            (error) => { toast.error(error) },
            () => { props.onClose() }
        ))
    }
    return (
        <>
            {!((!probillsToAddToRouteState.selectedProbills === undefined) || (Array.isArray(probillsToAddToRouteState.selectedProbills) && probillsToAddToRouteState.selectedProbills.length === 0)) &&
                <div className="add-probills-summary-container">
                    <div>Selected Probills:</div>
                    <div><span className="add-probills-summary-titile">Number of Probills: </span>{probillsToAddToRouteState.selectedProbills?.length}</div>
                    <div><span className="add-probills-summary-titile">Total Weight: </span>{probillsToAddToRouteState.selectedProbills?.reduce((sum, ele) => { return sum += ele.probillWeight }, 0).toLocaleString('en', { useGrouping: true })}</div>
                    <Button
                        theme={ButtonThemes.blue}
                        onClick={() => addProbillsToRouteTry()}
                        spinner = {addProbillsToRouteState.loading}
                        disabled={(!probillsToAddToRouteState.selectedProbills === undefined) || (Array.isArray(probillsToAddToRouteState.selectedProbills) && probillsToAddToRouteState.selectedProbills.length === 0)}
                    >
                       Add Probills
                    </Button>
                </div>
            }
        </>
    );
});

export default AddProbillsHeader;