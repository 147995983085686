import React, { useState } from "react";
import ContentContainer from "../../templates/content-container/contentContainer";
import TrackShipmentState from "../../slices/track-shipment/TrackShipmentState";
import { useDispatch, useSelector } from "react-redux";
import { searchShipments, trackShipmentSelector } from "../../slices/track-shipment/trackShipmentSlice";
import ContentContainerToolbar from "../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import FiltersToggle from "../../ui-components/filters-toggle/filtersToggle";
import TrackingFilter from "../shipments/tracking/tracking-filter/trackingFilter";
import Table from "../../ui-components/table/table";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import getAppointmentSummaryColumns from "./appointmentSummaryColumns";
import { CreateAppointment } from "./createAppointment";
import ShipmentModel from "../../app/data/tracking/ShipmentModel";
import "./appointmentSummary.scss";

export const AppointmentSummary: React.FC<{}> = () => {
  const trackShipmentState: TrackShipmentState = useSelector(trackShipmentSelector);

  const [selectedProbill, setSelectedProbill] = useState<ShipmentModel | null>(null);
  const dispatch = useDispatch();

  const onInfiniteScroll = () => {
    dispatch(searchShipments(trackShipmentState.request, true));
  };

  return (
    <ContentContainer
      titleComponent={
        <ContentContainerToolbar title="Appointments">
          <FiltersToggle />
        </ContentContainerToolbar>
      }
      header={
        <TrackingFilter source="APPOINTMENTS"/>
      }
    >
      <div className="xgs-appointment-summary">
        {!trackShipmentState.loadingFailed && (
          <>
            <Table
              isLoading={trackShipmentState.loading}
              columns={getAppointmentSummaryColumns()}
              onRowClicked={(props: any) => setSelectedProbill(props)}
              cursorPointer
              data={trackShipmentState.shipments}
              infiniteScroll={true}
              infiniteScrollLoading={trackShipmentState.loadingPortion}
              highlightRow={!!selectedProbill ? trackShipmentState.shipments.findIndex((shipment) => shipment.invoice.invoiceNumber === selectedProbill?.invoice.invoiceNumber) : null}
              infiniteScrollHasNext={!trackShipmentState.loadedAll}
              onInfiniteScroll={onInfiniteScroll}
              minTableHeight={320}
              maxTableWidth={1110}
              rowHeight={64}                  
              responsive={true}
            />
          </>
        )}
        {trackShipmentState.loadingFailed && (
          <XGSErrorMessage>{trackShipmentState.error || "Error while obtaining the list of shipments"}</XGSErrorMessage>
        )}
      </div>
      {selectedProbill !== null && (
         <CreateAppointment
          onClose={() => setSelectedProbill(null)}
          probillNumber={selectedProbill.invoice.invoiceNumber}
          consigneeNumber={selectedProbill.consignee.number}
          eta={selectedProbill.estimatedDeliveryDate}
        />
      )}
    </ContentContainer>
  );
}