import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import {
  ShareReturnModel,
  ShareReturnSchema
} from "../../../app/data/return/models";
import ReturnState from "../../../slices/return/ReturnState";
import {
  returnSelector,
  shareReturn
} from "../../../slices/return/returnSlice";
import "../bol.scss";

interface ShareReturnProps {
  probill: number
}

let initialValues: ShareReturnModel = {
  email: ""
};

const ShareReturn: React.FC<ShareReturnProps> = (props) => {
  const dispatch = useDispatch();
  const returnState: ReturnState = useSelector(returnSelector);
  const shareReturnRef = useRef<any>(null);

  const onClickSubmit = (data: ShareReturnModel) => {
    dispatch(shareReturn(props.probill, data.email, () => {
      toast.info("You have successfully shared your return information!", { autoClose: 3000 });
      mixpanel.track("Return was shared", { "Probill": props.probill });
      shareReturnRef.current?.resetForm();
    }, (error) => {
      toast.error(error);
    }));
  };

  return (
    <div className="xgs-bol__return__share">
      <Formik
        onSubmit={onClickSubmit}
        initialValues={initialValues}
        validationSchema={ShareReturnSchema}
        innerRef={shareReturnRef}
        enableReinitialize
      >
        {(props: FormikProps<ShareReturnModel>) => (
          <Form>
            <div className="xgs-bol__return__share__row">
              <XGSFormInput
                type="text"
                name="email"
                required={true}
                requiredAsteriskDisabled={true}
                labelMode={LabelModes.column}
                className="xgs-bol__field"
                placeholder="someone@example.com"
              />
              <Button
                type="submit"
                theme={ButtonThemes.blue}
                disabled={!props.isValid || !props.dirty}
                spinner={returnState.requestStarted && returnState.requestCreator === "SHARE_RETURN"}
              >
                Share
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ShareReturn;
