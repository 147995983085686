import React from "react";

const parseProbills = (descriptionItem: string) => {
  if (!descriptionItem) return null;
  const probillRegex = /(\b\d{6,8}\b(\b|$))/gi;
  let descriptionItemBlocks = descriptionItem.split(probillRegex);
  let result = descriptionItemBlocks.map((block) => {
    if (block.match(probillRegex)) {
      return (
        <a
          href={"/shipments/" + block}
          target="_blank"
          className="xgs-invoice-details__table__link"
          rel="noopener noreferrer"
        >
          {block}
        </a>
      );
    } else {
      return block;
    }
  });
  return result;
};

const selectColumn = (selectedItems: Set<number>, onClickSelect: any) => ({
  Header: "Select",
  accessor: "checked",
  width: 80,
  Cell: (cellProps: any) => {
    return (
      <div className="xgs-form__checkbox">
        <input
          checked={selectedItems?.has(cellProps.row.index)}
          onChange={() => onClickSelect(cellProps.row.index)}
          type="checkbox"
        />

      </div>
    );
  }
});

export const getExceptionItemsColumns = (selectedItems: Set<number>, onClickSelect: any) => [
  selectColumn(selectedItems, onClickSelect),
  {
    Header: "Serial Number",
    accessor: "serialNumber",
    width: 220,
    minWidth: 220
  },
  {
    Header: "Weight",
    accessor: "weight",
    width: 100,
    minWidth: 100
  },
  {
    width: 288,
    Header: "Description",
    accessor: "description",
    Cell: (cellProps: any) => parseProbills(cellProps.value)
  }
];