import React, { useEffect, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./invoicePayment.scss";
import PaymentPopup from "./paymentPopup";
import {
  paymentSelector,
  resetPayment,
  savePayment,
  setPaymentHiddenFormSubmitted,
} from "../../../slices/payment/paymentSlice";
import { PaymentResult } from "../../../app/data/payment/PaymentResult";
import PaymentState from "../../../slices/payment/PaymentState";

declare global {
  interface Window {
    AuthorizeNetIFrame: any;
  }
}

export interface InvoicePaymentProps {}

let iFrameInited = false;

const InvoicePayment: React.FC<InvoicePaymentProps> = (props) => {
  const payment: PaymentState = useSelector(paymentSelector);
  const dispatch = useDispatch();

  const formRef = createRef<HTMLFormElement>();

  useEffect(() => {
    if (!payment.hiddenFormSubmitted && payment.tokenInfo && payment.tokenInfo?.token) {
      formRef.current?.submit();
      dispatch(setPaymentHiddenFormSubmitted());
    }

    if (!iFrameInited) {
      if (!window.AuthorizeNetIFrame) window.AuthorizeNetIFrame = {};
      window.AuthorizeNetIFrame.onReceiveCommunication = (
        querystr: string,
        parent: any
      ) => {
        const params = parseQueryString(querystr);
        console.log("onReceiveCommunication >>>> params", params);
        switch (params.action) {
          case "successfulSave":
            break;
          case "cancel":
            dispatch(resetPayment());
            break;
          case "resizeWindow":
            /*const ifrm = document.getElementById(
                "iframe-authorize-net"
              ) as HTMLIFrameElement;
              ifrm.style.width = `${parseInt(params.width, 10)}px`;
              ifrm.style.height = `${parseInt(params.height, 10)}px`;*/
            break;
          case "transactResponse": {
            if (params.response) {
              dispatch(savePayment(JSON.parse(params.response) as PaymentResult));
            }
          }
        }
      };

      iFrameInited = true;
    }
  }, [dispatch, formRef, payment.hiddenFormSubmitted, payment.tokenInfo]);

  const parseQueryString = (str: string): any => {
    const vars: any = [];
    const arr = str.split("&");
    let pair: string[];
    for (const item of arr) {
      pair = item.split("=");
      vars.push(pair[0]);
      vars[pair[0]] = unescape(pair[1]);
    }
    return vars;
  };

  return (
    <>
      <PaymentPopup />
      {payment.showPopup && (
        <form
          ref={formRef}
          method="post"
          action={process.env.REACT_APP_AUTHORIZE_FORM_POST_URL}
          id="formAuthorizeNetPopup"
          name="formAuthorizeNetPopup"
          target="iframe-authorize-net"
          style={{ display: "none" }}
        >
          <input
            type="hidden"
            id="popupToken"
            name="token"
            value={payment.tokenInfo?.token ?? ""}
          />
        </form>
      )}
    </>
  );
};

export default InvoicePayment;
