
export default interface TeamsState {
  teams_fetch_was_started: boolean;
  teams_fetchPortion_was_started: boolean;
  teams_fetch_was_succeed: boolean;
  teams_fetch_was_failed: boolean;
  teams_fetch_fail_reason?: string | null;

  accounts_fetch_was_started: boolean;
  accounts_fetchPortion_was_started: boolean;
  accounts_fetch_was_succeed: boolean;
  accounts_fetch_was_failed: boolean;
  accounts_fetch_fail_reason?: string | null;

  teams: any;
  teamsScrollRequest: any;
  teamsSearchString: string;
  teamsFetchedAll: boolean;
  teamsSorting: any;

  accounts: any;
  accountsRequest: any;
  accountsFetchedAll: boolean;
  accountsSearchString: string | null;

  as400Account: any;
}

export const initialTeamsState: TeamsState = {
  teams_fetch_was_started: false,
  teams_fetchPortion_was_started: false,
  teams_fetch_was_succeed: false,
  teams_fetch_was_failed: false,
  teams_fetch_fail_reason: null,

  accounts_fetch_was_started: false,
  accounts_fetchPortion_was_started: false,
  accounts_fetch_was_succeed: false,
  accounts_fetch_was_failed: false,
  accounts_fetch_fail_reason: null,

  teams: [],
  teamsScrollRequest: null,
  teamsSearchString: "",
  teamsFetchedAll: false,
  teamsSorting: null,

  accounts: [],
  accountsRequest: null,
  accountsFetchedAll: false,
  accountsSearchString: null,

  as400Account: null
};
