import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import CommonService from "../../app/data/common/commonService";
import RatesService from "../../app/data/rates/ratesService";
import { GetRatesRequestModel } from "../../app/data/rates/models";
import { LocationDataModel } from "../../app/data/common/models";
import { initialRequestRatesState } from "./RequestRatesState";

const commonService = CommonService.getInstance();
const requestRatesService = RatesService.getInstance();

export const requestRatesSlice = createSlice({
  name: "requestRates",
  initialState: initialRequestRatesState,
  reducers: {
    resetRequestRatesState: (state) => initialRequestRatesState,
    setPickupLocation: (state, { payload }) => {
      state.pickupLocation = payload.location;
      state.pickupDate = payload.date;
    },
    setServices: (state, { payload }) => {
      state.services = [...payload];
    },
    setDestinationLocation: (state, { payload }) => {
      state.destinationLocation = payload.location;
    },
    addShippingItem: (state, { payload }) => {
      state.items.push({
        freightClass: payload.freightClass,
        description: payload.description,
        pieces: parseFloat(payload.pieces),
        yards: payload.yards,
        weight: parseFloat(payload.weight),
        rolls: payload.rolls,
        deleted: false
      });
    },
    updateShippingItem: (state, { payload }) => {
      const i = payload.index;
      state.items[i].freightClass = payload.data.freightClass;
      state.items[i].description = payload.data.description;
      state.items[i].pieces = payload.data.pieces;
      state.items[i].yards = payload.data.yards;
      state.items[i].weight = payload.data.weight;
      state.items[i].rolls = payload.data.rolls;
    },
    deleteShippingItem: (state, { payload }) => {
      state.items[payload].deleted = true;
    },
    clearDeletedShippingItems: (state) => {
      state.items = state.items.filter(item => !item.deleted);
    },
    clearShippingItems: (state) => {
      state.items = [];
    },
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestCreator = payload;
      state.requestError = "";
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
    },
    storePickupLocations: (state, { payload }) => {
      state.pickupLocations = [];
      state.pickupLocations.push(...payload.map((location: LocationDataModel) => {
        return {
          label: `${location.zip} - ${location.city}, ${location.state}`,
          value: location.zip,
          valueForSearch: `${location.zip} ${location.city}`
        }
      }));
    },
    storeDestinationLocations: (state, { payload }) => {
      state.destinationLocations = [];
      state.destinationLocations.push(...payload.map((location: LocationDataModel) => {
        return {
          label: `${location.zip} - ${location.city}, ${location.state}`,
          value: {
            zip: location.zip,
            direct: location.direct
          },
          valueForSearch: `${location.zip} ${location.city}`
        }
      }));
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestError = payload;
    },
    storeCC: (state, { payload }) => {
      state.cc = [];
      state.cc.push(...payload);
    },
    storeResult: (state, { payload }) => {
      state.result = payload;
    },
    resetResult: (state) => {
      state.result = null;
    },
    setDirectDestination: (state, { payload }) => {
      state.directDestination = payload;
    }
  }
});

export const {
  resetRequestRatesState,
  setPickupLocation,
  setDestinationLocation,
  setServices,
  addShippingItem,
  updateShippingItem,
  deleteShippingItem,
  clearDeletedShippingItems,
  clearShippingItems,
  requestStarted,
  requestSucceed,
  requestFailed,
  storePickupLocations,
  storeDestinationLocations,
  storeCC,
  storeResult,
  resetResult,
  setDirectDestination
} = requestRatesSlice.actions;

export const requestRatesSelector = (state: IState) => {
  return state.requestRates;
};

export const searchLocations = (
  type: string,
  query: string,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted(type + "_LOOKUP"));
  const response = await commonService.searchLocations(query);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(type === "PICKUP" ? storePickupLocations(response.data) : storeDestinationLocations(response.data));
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const getRates = (
  request: GetRatesRequestModel,
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_RATES"));
  const response = await requestRatesService.getRates(request);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(storeResult(response.data));
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const submitRequest = (
  id: string
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("SUBMIT_RATES_REQUEST"));
  const response = await requestRatesService.submitRequest(id);
  if (response.ok()) {
    dispatch(requestSucceed());
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const requestVolume = (
  request: GetRatesRequestModel,
  type: string,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("ERROR_REQUEST"));
  const response = await requestRatesService.requestAfterError(request, type);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

const requestRatesReducer = requestRatesSlice.reducer;
export default requestRatesReducer;
