import debounce from "lodash/debounce";
import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import moment from "moment";

import { getManifests, manifestsSelector, resetErrors, resetManifests } from "../../slices/manifest/manifestSlice";
import ManifestsState from "../../slices/manifest/ManifestsState";
import { ManifestsRequest, ManifestsFilterSchema, ManifestsFilter } from "../../app/data/manifest/requestModels";
import {ManifestStatusProfile, ManifestTableRow, StopProfile} from "../../app/data/manifest/manifestProfiles";
import ManifestDetails from "./details/manifestDetails";
import {arrangeName} from "../../hooks/utils";
import ManifestsService from "../../app/data/manifest/manifestsService";
import { DriverSearch } from "../../app/data/manifest/models";
import { userSelector } from "../../slices/user/userSlice";
import UserState from "../../slices/user/UserState";

import ContentContainer from "../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";

import Table from "../../ui-components/table/table";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import XGSFormDate from "../../ui-components/form/date/xgsFormDate";
import { LabelModes } from "../../ui-components/molecules/labeled-inputs/labeledInput";
import XGSFormSelect from "../../ui-components/form/select/xgsFormSelect";
import XGSFormInput from "../../ui-components/form/input/xgsFormInput";
import CompliancePercentage from "./list/compliancePercentage";
import DownloadButton from "../../ui-components/download-button/downloadButton";
import Legend from "../../ui-components/molecules/legend/legend";
import CompliancePercentageLegend from "./list/compliancePercentageLegend";
import { useDownload } from "../../hooks/useDownload";

import "./manifestsView.scss";

const manifestService = ManifestsService.getInstance();

const initialValues = {
  dispatchedDate: new Date().toString(),
  manifestNumber: "",
  probillNumber: "",
  driverId: "",
};

export const ManifestsView: React.FC = () => {
  const dispatch = useDispatch();  
  const manifestsState: ManifestsState = useSelector(manifestsSelector);
  const userState: UserState = useSelector(userSelector);
  const [manifest , setManifest] = useState<ManifestTableRow | null>(null);
  const [lastFilter, setLastFilter] = useState<ManifestsRequest | null>(null);
  const [filterError, setFilterError] = useState<ReactElement | null>(null);
  const formRef = React.useRef<FormikProps<ManifestsFilter>>(null);

  const columns = [
    {
      Header: "Manifest",
      accessor: "manifestNumber",
      width: 96,
      Cell: (cellProps: any) => (<>{cellProps.value}</>)
    },
    {
      Header: "Driver",
      accessor: "driverName",
      width: 127,
      Cell: (cellProps: any) => {
        return (
          <div className="cell__driver">
            {arrangeName(cellProps.value)}
            <span>({cellProps.row.original.driverEmployeeNumber})</span>
          </div>
        )
      }
    },
    {
      Header: "Trailer",
      accessor: "trailerNumber",
      width: 87,
      Cell: (cellProps: any) => (<>{cellProps.value}</>)
    },
    {
      Header: "Dispatched At",
      accessor: "dispatchedTs",
      width: 87,
      Cell: (cellProps: any) => {
        const date = moment(cellProps.value, "MMM D YYYY hh:mm");
        return (
      <div className="cell__driver">
        {date.format("MMM D, YYYY")}
        <div className="text-light">{date.format("h:mm A")}</div>
      </div>
      )}
    },
    // Commented out if it will be needed in future
    // {
    //   Header: "Stops",
    //   accessor: "stops",
    //   width: 80,
    //   Cell: (cellProps: any) => {
    //     const thisStops = Object.values(manifestsState.manifestsStops[cellProps.row.original.manifestNumber])
    //     let completedStopsCount = 0;
    //     if (thisStops && thisStops.length > 0) {
    //       completedStopsCount = thisStops.findIndex(stop => stop.stopStatus === "pending")
    //     }
    //
    //     return (<>{completedStopsCount > -1 ? completedStopsCount : cellProps.value} / {cellProps.value}</>)}
    // },
    {
      Header: "Deliveries",
      accessor: "probills",
      width: 80,
      Cell: (cellProps: any) => (<>{cellProps.value}</>)
    },
    {
      Header: "Pickups",
      accessor: (row: any) => {
        if (!manifestsState?.manifestsPickups?.[row.manifestNumber]) return 0;        

        return manifestsState.manifestsPickups[row.manifestNumber].length;
      },
      width: 80,
      Cell: (cellProps: any) => (<>{cellProps.value}</>)
    },
    {
      Header: "Items",
      accessor: "items",
      width: 80,
      Cell: (cellProps: any) => (<>{cellProps.value}</>)
    },
    {
      Header: "Exceptions",
      accessor: "exceptions",
      width: 87,
      Cell: (cellProps: any) => (<span className={"cell__exc" + (cellProps.value > 0 ? " --alert" : "")}>{cellProps.value}</span>)
    },
    {
      Header: "Progress",
      accessor: "progress",
      width:147,
      Cell: (cellProps: any) => {
        let thisStops: StopProfile[] = []
        if (manifestsState.manifestsStops && manifestsState.manifestsStops[cellProps?.row?.original?.manifestNumber]) {
          thisStops = Object.values(manifestsState.manifestsStops[cellProps?.row?.original?.manifestNumber])
        }
        let completedStopsCount = 0;
        let progressFloatValue = 0;

        if (thisStops && thisStops.length > 0) {
          thisStops.forEach(stop => stop.stopStatus === "complete" && completedStopsCount++)
        }

        const stopsCompleted  = (completedStopsCount > -1 ? completedStopsCount : cellProps.row.original.stops) + "/" + cellProps.row.original.stops

        if (completedStopsCount === -1 ) {
          progressFloatValue = 100
        } else if (completedStopsCount > 0) {
          progressFloatValue =  Math.floor((completedStopsCount / cellProps.row.original.stops) * 100)
        }

        return (
        <div className="cell__progress-container">
          <span className="cell__progress-info">
            <span className="cell__progress-percentage">{progressFloatValue}%</span>
            <span className="cell__progress">{stopsCompleted} stops</span>
          </span>
          <div className={"cell__progress-bar" + (progressFloatValue === 100 ? " --completed" : "")}>
            <div style={{width:  progressFloatValue + "%"}}></div>
          </div>
        </div>
      )}
    },
    {
      id: "compliance",
      Header: () => (
        <div className="xgs-table__cell-content--centered">
          {"Compliance percentage"}
          <Legend mix="xgs-table__tooltip">
            <CompliancePercentageLegend />
          </Legend>
        </div>        
      ),
      accessor: (row: any) => {
        if (!manifestsState?.manifestsProbills?.[row.manifestNumber]) return 0;

        const probills = Object.values(manifestsState.manifestsProbills[row.manifestNumber]);

        const driverAck = probills.filter(probill => !!probill.driverAckDate);

        const percentage = (driverAck.length * 100) / probills.length;

        return percentage;
      },
      width: 96,
      Cell: (cellProps: any) => (
        <div className="xgs-table__cell-content--centered">
          <CompliancePercentage
            id={cellProps?.row?.original.manifestNumber}
            percentage={cellProps.value}
            isCompleted={cellProps?.row?.original.pending === 0} />
        </div>
      )
    },
  ];

  const terminalRequired = (data: ManifestsFilter) => (!data.manifestNumber?.trim() && !data.probillNumber?.trim() && !data.driverId);

  const searchParams = (data: ManifestsRequest): ManifestsRequest => {
    const { dispatchedDate, terminal, probillNumber, manifestNumber, driverId } = data;

    return {
      dispatchedDate: dispatchedDate && dispatchedDate.toCustomDateFormat("YYYYMMDD"),
      ...terminalRequired(data) && { terminal },
      probillNumber: probillNumber?.trim(),
      manifestNumber: manifestNumber?.trim(),
      driverId,
    };
  }

  const searchManifests = (data: ManifestsRequest) => {
    dispatch(getManifests(searchParams(data)));
    setLastFilter(data);
  };

  const onSubmit = async (data: ManifestsFilter) => {
    setFilterError(null);
    setManifest(null);
    resetDownload();

    const terminal = userState.activeTerminal?.id;

    if (!terminal && terminalRequired(data)) {
      setFilterError(
        <div>
          You do not have assigned terminals.
          Email <a className="white-link" href="mailto:helpdesk@xgsi.com">helpdesk@xgsi.com</a> to request terminal assignment for your account.
        </div>
      );
    } else {
      searchManifests({...data, terminal });
    } 
  };

  const fetchCSV = () => manifestService.exportManifestsCSV(searchParams(lastFilter || {}));

  const {
    download,
    resetDownload,
    loadingState
  } = useDownload(
    "MANIFESTS",
    fetchCSV,
  );

  useEffect(() => {
    return () => {
      dispatch(resetManifests())
      dispatch(resetErrors())
      setManifest(null)
    };    
  }, [dispatch]);

  useEffect(() => {
    if (userState.activeTerminal?.id) {
      formRef.current?.submitForm();
    } 
    // eslint-disable-next-line   
  }, [userState.activeTerminal?.id]); 

  // eslint-disable-next-line
  const loadDriverOptions = React.useCallback(
    debounce((query, callback) => {
      manifestService.getDriversSearch(query)
      .then(options => callback(options.map(({ driver, searchDetails }: DriverSearch) => ({
        value: driver.driverId,
        label: <span dangerouslySetInnerHTML={{ __html: searchDetails.matchedStr }} />
      }))))
    }, 300),
    []
  );
  
  const error = (manifestsState.fetch_was_failed && manifestsState.fetch_fail_reason) || filterError;

  return (
    <ContentContainer
      className="xgs-list xgs-list--full-width"
      mods={{ 'full-width': true }}
      titleComponent={
        <ContentContainerToolbar
          title="Manifests"
          mods={{ "full-width": true }}
        >
          <DownloadButton
            title="Download CSV"
            spinner={loadingState?.downloadStarted}
            disabled={!lastFilter || !manifestsState.manifestsStatuses.length}
            onClick={() => {
              !loadingState?.downloadStarted && download();
            }}
          />
        </ContentContainerToolbar>
      }
    >
      <div className="xgs-manifests-view">
        <div className="xgs-list__controls">
          <div className="xgs-list__controls__search">
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={ManifestsFilterSchema}
              innerRef={formRef}
            >
               {(props: FormikProps<ManifestsFilter>) => (
                <Form className="xgs-list__controls__search-form">
                  <XGSFormDate
                    name="dispatchedDate"
                    label="Dispatched Date"
                    className="xgs-item-form__field"
                    onChange={() => ''}
                    onDateChange={(v) => props.setFieldValue("dispatchedDate", v)}
                  />
                  <XGSFormInput
                    type="text"
                    name="manifestNumber"
                    label="Manifest"
                    labelMode={LabelModes.column}
                    className="xgs-item-form__field"
                  />
                  <XGSFormInput
                    type="text"
                    name="probillNumber"
                    label="Probill"
                    labelMode={LabelModes.column}
                    className="xgs-item-form__field"
                  />
                  <XGSFormSelect
                    name="driverId"
                    label="Driver"
                    labelMode={LabelModes.column}
                    placeholder="Driver name or number"
                    isClearable
                    async
                    loadOptions={loadDriverOptions}
                    options={[]} // default options    
                    onValueChange={(t) => props.setFieldValue("driverId", t?.value)}
                    className="xgs-item-form__field"
                  />
                  <Button
                    type="submit"
                    theme={ButtonThemes.blue}
                    className=""
                    spinner={false}
                  >
                    Search
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {!!error ? (
          <XGSErrorMessage>{error}</XGSErrorMessage>
        ) : (
          <div className="xgs-list__table-wrapper">
            <Table
              isLoading={manifestsState.fetch_was_started && manifestsState.requestCreator === "GET_MANIFESTS"}
              columns={columns}
              data={manifestsState.manifestsStatuses}
              cursorPointer={true}
              rowHeight={64}
              noResultsText="There are no matching manifests"
              onRowClicked={(row: ManifestStatusProfile) => {
                setManifest(row)
              }}
              responsive={true}
            />
          </div>
        )}

        {manifest && (
          <ManifestDetails
            manifest={manifest}
            show={!!manifest?.manifestNumber}
            onClose={() => setManifest(null)}
          />
        )}
      </div>
    </ContentContainer>
  )
};