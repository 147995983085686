import React from "react";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import DownloadPDF from "../../download-invoice-pdf/downloadPDF";

interface InvoicesSelectedBarProps {
  selectedInvoices: number[];
  onReset: () => void; 
}

const InvoicesSelectedBar: React.FC<InvoicesSelectedBarProps> = (props) => {
  return (
    <div className="xgs-documents__invoices__selected-bar">
      <div>
        <span className="xgs-documents__invoices__selected-bar__summary-label">Invoices Selected:</span>
        &nbsp;
        <span className="xgs-documents__invoices__selected-bar__summary-value">{props.selectedInvoices.length}</span>
      </div>
      <div className="xgs-documents__invoices__selected-bar__buttons">
        <Button theme={ButtonThemes.gray} onClick={props.onReset}>Clear Selection</Button>

        <DownloadPDF invoiceNumbers={props.selectedInvoices} type="multiple" />
      </div>
    </div>
  );
};

export default InvoicesSelectedBar;