import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import PublicShipmentDetailsService from "../../app/data/public-shipment-details/publicShipmentDetailsService";
import { initialPublicShipmentDetailsState } from "./PublicShipmentDetailsState";

const publicShipmentDetailsService = PublicShipmentDetailsService.getInstance();

export const publicShipmentDetailsSlice = createSlice({
  name: "publicShipmentDetails",
  initialState: initialPublicShipmentDetailsState,
  reducers: {
    resetPublicShipmentDetails: (state) => initialPublicShipmentDetailsState,
    startLoading: (state) => {
      state.loading = true;
      state.loaded = false;
      state.loadingFailed = false;
    },
    loadingSucceed: (state) => {
      state.loading = false;
      state.loaded = true;
      state.loadingFailed = false;
    },
    loadingFailed: (state, { payload }) => {
      state.loading = false;
      state.loaded = false;
      state.loadingFailed = true;
      state.loadingError = payload.text;
      state.loadingErrorStatus = payload.status;
    },
    setPublicShipmentDetails: (state, { payload }) => {
      state.shipment = payload;
    }
  }
});

export const {
  resetPublicShipmentDetails,
  startLoading,
  loadingSucceed,
  loadingFailed,
  setPublicShipmentDetails
} = publicShipmentDetailsSlice.actions;

export const publicShipmentDetailsSelector = (state: IState) => state.publicShipmentDetails;

export const getPublicShipmentDetails = (
  probill: number
): AppThunk => async (dispatch) => {
  dispatch(startLoading());
  const response = await publicShipmentDetailsService.getPublicShipmentDetails(probill);
  if (response.ok()) {
    dispatch(loadingSucceed());
    dispatch(setPublicShipmentDetails(response.data));
  } else {
    dispatch(loadingFailed({
      text: response.getError ? response.getError() : "Error",
      status: response.status
    }));
  }
};

export const shareShipment = (
  email: string,
  probill: number,
  onSuccess: () => void,
  onFailed: (error: any) => void
): AppThunk => async (dispatch) => {
  dispatch(startLoading());
  const response = await publicShipmentDetailsService.shareShipment(email, probill);
  if (response.ok()) {
    dispatch(loadingSucceed());
    onSuccess();
  } else {
    dispatch(loadingFailed({
      text: response.getError ? response.getError() : "Error",
      status: response.status
    }));
    onFailed(response.getError());
  }
};

const publicShipmentDetailsReducer = publicShipmentDetailsSlice.reducer;
export default publicShipmentDetailsReducer;
