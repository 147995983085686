import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import { UserUtils } from "../../app/data/user/userUtils";
import UserState from "../../slices/user/UserState";
import { userSelector } from "../../slices/user/userSlice";
import { breadcrumbsRoutes } from "../../app/data/common/titles";
import "./breadcrumbs.scss";

const isLastCrumb = (breadcrumbs: any, index: number) => {
  return index === breadcrumbs.length - 1;
};

const PureBreadcrumbs: React.FC<{}> = ({ breadcrumbs }: any) => {
  const userState: UserState = useSelector(userSelector);

  return (
    <div className="xgs-breadcrumbs">
      {breadcrumbs.map(({ breadcrumb, match }: any, index: number) => (
        <React.Fragment key={match.url}>
          {!((UserUtils.isXGSDriver(userState.profile) || UserUtils.isEmployee(userState.profile)) && (match.url === "/" || match.url.replace(/\d+$/, "") === "/")) && (
            <div>
              {!isLastCrumb(breadcrumbs, index)
                ? (
                    <>
                      <Link to={match.url || "/"} className="xgs-breadcrumbs__item">{breadcrumb}</Link><span className="xgs-breadcrumbs__separator">&gt;</span>
                    </>
                  )
                : (
                    <>
                      {breadcrumb}
                    </>
                  )
              }
            </div>
          )}
          {(UserUtils.isEmployee(userState.profile) && (match.url === "/" || match.url.replace(/\d+$/, "") === "/")) && (
            <div>
              {!isLastCrumb(breadcrumbs, index)
                ? (
                    <>
                      <Link to="/shipments" className="xgs-breadcrumbs__item">{breadcrumb}</Link><span className="xgs-breadcrumbs__separator">&gt;</span>
                    </>
                  )
                : (
                    <>
                      {breadcrumb}
                    </>
                  )
              }
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

export default withBreadcrumbs(breadcrumbsRoutes, { disableDefaults: true })(PureBreadcrumbs);
