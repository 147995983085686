import React from "react";
import "./blockPlaceholder.scss";

const BlockPlaceholder: React.FC<{}> = (props) => {
  return (
    <div className="xgs-block-placeholder">
      No information available
    </div>
  );
}

export default BlockPlaceholder;