import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import InvoiceService from "../../app/data/invoice/invoiceService";
import { initialInvoiceRecordsState } from "./InvoiceRecordsState";

const invoiceRecordsService = InvoiceService.getInstance();

export const invoiceRecordsSlice = createSlice({
  name: "invoiceRecords",
  initialState: initialInvoiceRecordsState,
  reducers: {
    resetInvoiceRecords: (state) => initialInvoiceRecordsState,
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestError = null;
      state.requestCreator = payload;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = true;
      state.requestSucceed = false;
      state.requestError = payload;
    },
    requestSucceed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = false;
      state.requestSucceed = true;
      state.requestError = null;
      state.records = payload;
    }
  }
});

export const {
  resetInvoiceRecords,
  requestStarted,
  requestFailed,
  requestSucceed
} = invoiceRecordsSlice.actions;

export const invoiceRecordsSelector = (state: IState) => {
  return state.invoiceRecords;
};

export const getInvoiceRecords = (
  probill: number,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted('list'));
  const response = await invoiceRecordsService.getInvoiceRecords(probill);
  if (response.ok()) {
    dispatch(requestSucceed(response.data));
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};


const invoiceRecordsReducer = invoiceRecordsSlice.reducer;
export default invoiceRecordsReducer;
