import { RecognitionLogsResponseModel } from "../../app/data/documents/models";

export default interface RecognitionLogsState {
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError: string;
  requestCreator: string;
  records: RecognitionLogsResponseModel[];
}

export const initialRecognitionLogsState: RecognitionLogsState = {
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestError: "",
  requestCreator: "",
  records: []
};
