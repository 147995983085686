import api, { ApiResponse } from "./../api";
import axios, { CancelTokenSource } from "axios";
import { InvoicesFilterModel, InvoiceResponseModel } from "./models";
import { urlParams } from "../../../services/common/urlParams";
import { InfiniteScrollCollectionContentResponse, SortParams } from "../common/models";

export const REQUEST_ID: {
  [key: string]: string;
} = {
  GET_INVOICES: "get_invoices",
  GET_SUMMARY: "get_invoices_summary",
};

class InvoicesService {
  private source: {
    [key: string]: CancelTokenSource;
  };

  private static instance: InvoicesService;

  private constructor() {
    this.source = {};
  };

  static getInstance(): InvoicesService {
    if (!InvoicesService.instance) {
      InvoicesService.instance = new InvoicesService();
    }
    return InvoicesService.instance;
  };

  public getInvoices = async (
    filter: InvoicesFilterModel,
    sorting: SortParams | null,
    lastIds?: string | null,
  ): Promise<ApiResponse<InfiniteScrollCollectionContentResponse<InvoiceResponseModel>>> => {
    this.source[REQUEST_ID.GET_INVOICES] && this.source[REQUEST_ID.GET_INVOICES].cancel();
    this.source[REQUEST_ID.GET_INVOICES] = axios.CancelToken.source();

     const request = { ...filter, ...sorting };

    return await api.get(
      "/employee/invoice-search",
      { params: urlParams(lastIds ? {...request, lastIds} : request), cancelToken: this.source[REQUEST_ID.GET_INVOICES].token },
    );
  };

  public getInvoicesSummary = async (): Promise<ApiResponse<any>> => {
    this.source[REQUEST_ID.GET_SUMMARY] && this.source[REQUEST_ID.GET_SUMMARY].cancel();
    this.source[REQUEST_ID.GET_SUMMARY] = axios.CancelToken.source();
    return await api.get("/employee/invoice-summary", { cancelToken: this.source[REQUEST_ID.GET_SUMMARY].token });
  };
};

export default InvoicesService;
