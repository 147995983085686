import React, { ChangeEventHandler, forwardRef, memo } from "react";
import { cn } from "../../services/common/className";

import "./xgsCheckbox.scss";

export interface XGSCheckboxProps {
  children?: any;
  name: string;
  checked?: boolean;
  defaultChecked?: boolean;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  mix?: string | string[];
  mods?: {
    reverse?: boolean,
  },
  input?: any;
  disabled?: boolean;
}

const XGSCheckbox = memo(forwardRef<any, XGSCheckboxProps>((props, ref) => {
  const {
    name,
    checked,
    defaultChecked,
    onChange,
    mix,
    mods,
    children,
    input,
    value,
    disabled,
  } = props;

  const InputTag = input || "input";

  const inputProps = {
    ...onChange !== undefined && { onChange },
    ...checked !== undefined && {checked},
    ...defaultChecked !== undefined && {defaultChecked},
    ...value !== undefined && { value },
  };

  return (
    <div className={cn("xgs-checkbox")(mods, mix)}>
      <label>
        <InputTag
          ref={ref}
          type="checkbox"
          name={name}
          disabled={disabled}
          {...inputProps}
        />
        {children && <div className="xgs-checkbox__label">
          {children}
        </div>
        }
      </label>
    </div>
  );
}));

export default XGSCheckbox;