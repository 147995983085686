import axios from "axios";
import api, { ApiResponse } from "./../api";
import { BolRequestModel } from "./models";

class BolService {
  private source: any;
  private static instance: BolService;
  private constructor() {}
  static getInstance(): BolService {
    if (!BolService.instance) {
      BolService.instance = new BolService();
    }
    return BolService.instance;
  };

  public getBols = async (
    types: string[],
    lastIds?: string
  ): Promise<ApiResponse<any>> => {
    let params = {};
    if (lastIds) {
      params = { lastIds };
    }
    params = {
      ...params,
      types: types.join(",")
    };
    this.source && this.source.cancel();
    this.source = axios.CancelToken.source();
    return await api.get("/bols", { params, cancelToken: this.source.token });
  };

  public getShippers = async (): Promise<ApiResponse<any>> => {
    return await api.get("/teams/shippers");
  };

  public getConsignees = async (): Promise<ApiResponse<any>> => {
    return await api.get("/bols/consignees");
  };

  public createBol = async (
    request: BolRequestModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/bols", request);
  };

  public downloadDocument = async (
    probill: string,
    type: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/bols/${probill}/documents?type=${type}`, {
      responseType: "blob"
    });
  };

  public checkPickup = async (
    zip: string
  ): Promise<ApiResponse<any>> => {
    return await api.post(`/service-centers/pickup?zipCode=${zip}`);
  };

  public checkConsignee = async (
    zip: string
  ): Promise<ApiResponse<any>> => {
    return await api.post(`/service-centers/consignee?zipCode=${zip}`);
  };

  public downloadCsv = async (
    types: string[]
  ): Promise<ApiResponse<any>> => {
    return await api.post("/bols/download", { types });
  };
};

export default BolService;
