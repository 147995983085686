import React, { ReactElement, useEffect } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { useDispatch, useSelector } from 'react-redux';

import ManifestsState from '../../../slices/manifest/ManifestsState';
import { StopStatus, StopType, PickupStatus } from "../../../app/data/common/route";
import { reset_manifest_map_data, getManifestMapData, manifestsSelector, stopsInfoSelector } from '../../../slices/manifest/manifestSlice';
import { IState } from "../../../slices";
import { getStopFillColor, getStopLabelProps } from "../../../services/map";
import { Coordinates } from "../../../app/data/map/models";

import XGSMap from "../../../ui-components/map/map";
import XGSPolyline from "../../../ui-components/map/polyline/polyline";
import XGSCustomMarker from "../../../ui-components/map/custom-marker/customMarker";
import XGSErrorMessage from '../../../ui-components/error-message/errorMessage';
import XGSMarkerTooltip from "../../../ui-components/map/marker-tooltip/markerTooltip";
import Loading from "../../../ui-components/loading/loading";
import MapHeader from "../../../ui-components/map/map-header/mapHeader";
import StopsLegend from "../../../ui-components/map/stops-legend/stopsLegend";
import StopMarkerInfo from "../../../ui-components/map/stop-marker-info/stopMarkerInfo";

import "../../../ui-components/map/map.scss";
import { formatAddress } from "../../../services/common/address";
import { getCoordinates } from "../../../app/data/map/utils";

export interface StopMarker {
  id: string,
  label: string;
  description?: string;
  lat: number;
  lng: number;
}

export interface ManifestMapProps {
  manifestNumber?: string;
  stopStatuses?: StopStatus[];
};

const ManifestMap: React.FC<ManifestMapProps> = ({ manifestNumber, stopStatuses }) => {

  const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_KEY || "";

  const dispatch = useDispatch();
  const manifestsState: ManifestsState = useSelector(manifestsSelector);
  const stopsInfo = useSelector((state: IState) => stopsInfoSelector(state, manifestNumber));

  const assignedPickups = (manifestNumber && manifestsState.manifestsPickups[manifestNumber]) || [];  

  useEffect(() => {
    if (!manifestNumber) return;
    
    dispatch(getManifestMapData(manifestNumber));    

    return () => { dispatch(reset_manifest_map_data()) };
    // eslint-disable-next-line
  }, [manifestNumber]);  

  const render = (): ReactElement => {
    return <Loading isLoading={true} />;
  };

  const { manifestMapData } = manifestsState;

  const polyline = manifestMapData?.polyline;
  
  const stops = manifestMapData?.markers || [];  

  const points = stops
    .map(({ lat, lng }: Coordinates) => ({ lat, lng }))
    .concat(assignedPickups.map((pickup) => (getCoordinates(pickup.shipper.address.geolocation))))

  const isError = manifestsState.map_fetch_was_failed;
  const isLoading = !manifestsState.map_fetch_was_failed && !manifestMapData;

  return (
    <div>
      <MapHeader
        title="Map of the Route"
        legend={<StopsLegend/>}
      />

      <div className="xgs-manifest-map__container">
        <div className="xgs-manifest-map__state">
          {isError && <XGSErrorMessage>{manifestsState.map_fetch_fail_reason}</XGSErrorMessage>}
          {isLoading && <Loading isLoading={true}/>}
        </div>

        <Wrapper
          apiKey={apiKey}
          render={render}
          libraries={["geometry", "marker"]}
        >
          <XGSMap
            fitBounds
            points={points}
          >
            {polyline && <XGSPolyline polylineData={polyline} />}

              {stops.map((stop: StopMarker) => {
              const type = stop.label === 'SC' ? StopType.SERVICE_CENTER : StopType.STOP;              
              const status = type === StopType.STOP ? stopStatuses?.[+stop.label] : null;              
              const info = stopsInfo?.[stop.label];

              return (
                <XGSCustomMarker
                  key={`m-${stop.id}`}
                  position={{
                    lat: stop.lat,
                    lng: stop.lng,
                  }}
                  label={{
                    text: stop.label,
                    ...getStopLabelProps(status),
                  }}
                  fillColor={getStopFillColor(type, status)}
                  {...info && { tooltip: <XGSMarkerTooltip><StopMarkerInfo info={info}/></XGSMarkerTooltip>}}
                  {...!info && !!stop.description && { tooltip: <XGSMarkerTooltip><div dangerouslySetInnerHTML={{__html: stop.description}}/></XGSMarkerTooltip>}}
                />
              )
            })}

            {!!assignedPickups.length && assignedPickups.map((pickup) => (
              <XGSCustomMarker
                key={`m-${pickup.pickupNumber}`}
                position={{
                  lat: pickup.shipper.address.geolocation.lat,
                  lng: pickup.shipper.address.geolocation.lng,
                }}
                label={{
                  text: "PU",
                  ...getStopLabelProps(StopType.PICKUP),
                }}
                fillColor={getStopFillColor(StopType.PICKUP, PickupStatus[pickup.pickupStatus])}
                tooltip={<XGSMarkerTooltip><StopMarkerInfo info={{ address: formatAddress(pickup.shipper.address), consignees: pickup.consignee.name }} /></XGSMarkerTooltip>}
              />
            ))}
          </XGSMap>
        </Wrapper>
      </div>
    </div>
  );
};

export default ManifestMap;
