import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { ForgotPasswordRequest } from "../../app/data/user/requestModels";
import { initialForgotPasswordState } from "./ForgotPasswordState";
import UserService from "../../app/data/user/userService";

const userService = UserService.getInstance();

export const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState: initialForgotPasswordState,
  reducers: {
    resetForgotPassword: state => initialForgotPasswordState,
    forgotPassword_was_started: (state) => {
      state.started = true;
      state.failed = false;
      state.succeed = false;
    },
    forgotPassword_was_succeed: (state) => {
      state.started = false;
      state.failed = false;
      state.succeed = true;
    },
    forgotPassword_was_failed: (state, { payload }) => {
      state.started = false;
      state.failed = true;
      state.succeed = false;
      state.failReason = payload;
    }
  }
});

export const {
  resetForgotPassword,
  forgotPassword_was_started,
  forgotPassword_was_succeed,
  forgotPassword_was_failed
} = forgotPasswordSlice.actions;

export const forgotPasswordSelector = (state: IState) => state.forgotPassword;

export const sendForgotPasswordRequest = (
  request: ForgotPasswordRequest
): AppThunk => async (dispatch) => {
  dispatch(forgotPassword_was_started());
  const response = await userService.sendForgotPasswordRequest(request);
  if (response.ok()) {
    dispatch(forgotPassword_was_succeed());
  } else {
    dispatch(forgotPassword_was_failed(response.getError ? response.getError() : "Error"));
  }
};

const forgotPasswordReducer = forgotPasswordSlice.reducer;
export default forgotPasswordReducer;
