import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import UserService from "../../app/data/user/userService";
import { initialResetPasswordState } from "./ResetPasswordState";
import { ResetPasswordRequestModel  } from "../../app/data/user/requestModels";

const userService = UserService.getInstance();

export const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState: initialResetPasswordState,
  reducers: {
    resetResetPasswordState: (state) => initialResetPasswordState,
    startSendingRequest: (state) => {
      state.sending_request = true;
      state.request_is_Failed = false;
      state.request_is_Succeed = false;
      state.resetedUser = null;
    },
    sendingRequest_has_Failed: (state) => {
      state.sending_request = false;
      state.request_is_Failed = true;
      state.request_is_Succeed = false;
    },
    sendingRequest_has_Succeed: (state, { payload }) => {
      state.sending_request = false;
      state.request_is_Failed = false;
      state.request_is_Succeed = true;
      state.resetedUser = payload;
    },
    resetRequestSucceeding: (state) => {
      state.request_is_Succeed = false;
    },
    loadingLegacyUser: (state) => {
      state.loadingLegacyUser = true;
      state.loadingLegacyUserError = null;
    },
    loadingLegacyUserFailed: (state, { payload }) => {
      state.loadingLegacyUser = false;
      state.loadingLegacyUserError = payload;
    },
    setLegacyUser: (state, { payload }) => {
      state.loadingLegacyUser = false;
      state.loadingLegacyUserError = null;
      state.legacyUser = payload;
    },
    startSubmitting: (state) => {
      state.submitting = true;
      state.submit_is_Failed = false;
      state.submit_is_Succeed = false;
      state.submit_error = null;
    },
    submit_has_Failed: (state, { payload }) => {
      state.submitting = false;
      state.submit_is_Failed = true;
      state.submit_is_Succeed = false;
      state.submit_error = payload;
    },
    submit_has_Succeed: (state) => {
      state.submitting = false;
      state.submit_is_Failed = false;
      state.submit_is_Succeed = true;
      state.submit_error = null;
    }
  }
});

export const {
  resetResetPasswordState,
  startSendingRequest,
  sendingRequest_has_Failed,
  sendingRequest_has_Succeed,
  resetRequestSucceeding,
  loadingLegacyUser,
  loadingLegacyUserFailed,
  setLegacyUser,
  startSubmitting,
  submit_has_Failed,
  submit_has_Succeed
} = resetPasswordSlice.actions;

export const resetPasswordSelector = (state: IState) => state.resetPassword;

export const getLegacyUser = (
  token: string
): AppThunk => async (dispatch) => {
  dispatch(loadingLegacyUser());

  const response = await userService.getLegacyUserByResetPasswordToken(token);

  if (response.ok()) {
    dispatch(setLegacyUser(response.data));
  } else {
    dispatch(loadingLegacyUserFailed(response.getError()));
  }
};

export const submitResetPassword = (
  request: ResetPasswordRequestModel
): AppThunk => async (dispatch) => {
  dispatch(startSubmitting());

  const response = await userService.submitResetPassword(request);

  if (response.ok()) {
    dispatch(submit_has_Succeed());
  } else {
    dispatch(submit_has_Failed(response.getError()));
  }
};

const resetPasswordReducer = resetPasswordSlice.reducer;
export default resetPasswordReducer;
