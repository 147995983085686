import React from "react";
import { getUnitTag } from "./getUnitTag";

export const customerInventoryColumns = [
  {
    Header: "SKU #",
    accessor: "sku",
    width: 162,
    Cell: (cellProps: any) => (
      <div className="xgs-warehouse-summary__item-cell">
        <span><b>{cellProps.row.original.sku}</b></span>
      </div>
    )
  },
  {
    Header: "Item Description",
    accessor: "description",
    width: 268,
    Cell: (cellProps: any) => (
      <span>{cellProps.row.original.description}</span>
    )
  },
  {
    Header: "Measure Unit",
    accessor: "unitOfMeasure",
    width: 108,
    Cell: (cellProps: any) => (
      getUnitTag(cellProps.value)
    )
  },
  {
    Header: "Quantity",
    accessor: "qtyAvailableToOrder",
    width: 90
  },
  {
    Header: "Roll #",
    accessor: "rollNumber",
    width: 110
  },
  {
    Header: "Pallet #",
    accessor: "palletLpNumber",
    width: 110
  },
  {
    Header: "Sub Part 1",
    accessor: "subPart1Number",
    width: 112
  },
  {
    Header: "Sub Part 2",
    accessor: "subPart2Number",
    width: 112
  },
  {
    Header: "Terminal",
    accessor: "terminal",
    width: 216,
    Cell: (cellProps: any) => (
      <div className="xgs-lmi__trips__address-cell">
        {cellProps.row.original.locationAirportCode} - {cellProps.row.original.locationTerminalName} <span style={{display: "block", float: "right", marginRight: 10}}>{cellProps.row.original.locationTerminalNumber}</span>
      </div>
    )
  }
];
