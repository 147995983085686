import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import FuelSurchargeService from "../../app/data/fuel-surcharge/fuelSurchargeService";
import { initialFuelSurchargeState } from "./FuelSurchargeState";

const fuelSurchargeService = FuelSurchargeService.getInstance();

export const fuelSurchargeSlice = createSlice({
  name: "fuelSurcharge",
  initialState: initialFuelSurchargeState,
  reducers: {
    resetFuelSurchargeState: (state) => initialFuelSurchargeState,
    requestStarted: (state) => {
      state.requestStarted = true;
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestError = null;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = true;
      state.requestSucceed = false;
      state.requestError = payload;
    },
    requestSucceed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = false;
      state.requestSucceed = true;
      state.requestError = null;
      state.usRate = payload.us?.rate || -1;
      state.caRate = payload.ca?.rate || -1;
    }
  }
});

export const {
  resetFuelSurchargeState,
  requestStarted,
  requestFailed,
  requestSucceed
} = fuelSurchargeSlice.actions;

export const fuelSurchargeSelector = (state: IState) => {
  return state.fuelSurcharge;
};

export const getFuelSurcharge = (
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted());
  const response = await fuelSurchargeService.getFuelSurcharge();
  if (response.ok()) {
    dispatch(requestSucceed(response.data));
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

const fuelSurchargeReducer = fuelSurchargeSlice.reducer;
export default fuelSurchargeReducer;
