import api, { ApiResponse } from "../api";

class MetricsService {
  private static instance: MetricsService;
  private constructor() {}

  static getInstance(): MetricsService {
    if (!MetricsService.instance) {
      MetricsService.instance = new MetricsService();
    }
    return MetricsService.instance;
  }

  public getShipmentsStatus = async (subAccountId: string): Promise<ApiResponse<any>> => {
    return await api.get(`/accounts/${subAccountId}/reports/shipment`);
  };
}

export default MetricsService;
