import React from "react";

import "./linkCell.scss";
import { Link } from "react-router-dom";

export interface LinkCellProps {
  url: string;
  text: string;
}

const LinkCell: React.FC<LinkCellProps> = (props) => {
  return (
    <>
      {props.url && (
        <Link to={props.url} className="xgs-table-link-cell">
          {props.text}
        </Link>
      )}
    </>
  );
};

export default LinkCell;
