import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { useDropzone } from "react-dropzone";
import mixpanel from "mixpanel-browser";
import debounce from "lodash/debounce";
import Lightbox from "react-spring-lightbox";
import SlideOutSidebar from "../../../ui-components/slide-out-sidebar/slideOutSidebar";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import ConfirmationModal from "../../../ui-components/confirmation-modal/confirmationModal";
import XGSInlineSwitch from "../../../ui-components/xgs-inline-switch/xgsInlineSwitch";
import XGSInput from "../../../ui-components/xgs-input/xgsInput";
import LabeledSelectInput from "../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import {
  recordsSelector,
  removePhoto,
  removeRecord,
  searchProbills,
  updatePhoto,
  updateRecord
} from "../../../slices/delivery-records/recordsSlice";
import RecordsState from "../../../slices/delivery-records/RecordsState";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import XGSCheckbox from "../../../ui-components/xgs-checkbox/xgsCheckbox";
import { UserUtils } from "../../../app/data/user/userUtils";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import {
  ACCEPTED_FORMATS,
  MAX_SIZE,
  MAX_PHOTOS
} from "./constants";
import { wideModalStyle } from "../../../app/data/common/modalStyle"
import "../../../sass/modal.scss";
import "./deliveryRecords.scss";

export interface RecordDetailsProps {
  id: string | undefined;
  show: boolean;
  onClose: () => void;
};

Modal.setAppElement("#root");

const RecordDetails: React.FC<RecordDetailsProps> = (props) => {
  const dispatch = useDispatch();
  const recordsState: RecordsState = useSelector(recordsSelector);
  const userState: UserState = useSelector(userSelector);
  const [recordData, setRecordData] = useState<any>();
  const [previewMode, setPreviewMode] = useState<string>("list");
  const [gridComment, setGridComment] = useState<string>("");
  const [gridCommentOpen, setGridCommentOpen] = useState<boolean>(false);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState<boolean>(false);
  const [showPhotoRemoveConfirmation, setShowPhotoRemoveConfirmation] = useState<number>(-1);
  const [showAddPhotos, setShowAddPhotos] = useState<boolean>(false);
  const [photos, setPhotos] = useState<any>([]);
  const [fileError, setFileError] = useState<string>("");
  const [probill, setProbill] = useState<XGSSelectOption | null>();
  const [manifest, setManifest] = useState<string>("");
  const [summary, setSummary] = useState<string>("");
  const [galleryImages, setGalleryImages] = useState<any>([]);
  const [showGallery, setShowGallery] = useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [photoNewComment, setPhotoNewComment] = useState<string>("");
  const [photoNewFlag, setPhotoNewFlag] = useState<boolean>(false);  

  const onDeleteRecord = () => {
    if (!props.id) return;
    dispatch(removeRecord(props.id, () => {
      setShowRemoveConfirmation(false);
      toast.info("The record was deleted!");
      props.onClose();
    }));
  };

  const onUpdateRecord = (obj: any, field: string) => {
    if (!props.id) return;
    const fd = new FormData();
    fd.append("data", JSON.stringify(obj));
    dispatch(updateRecord(props.id, fd, () => {
      toast.info("The record was updated!");
      mixpanel.track("Delivery record: data updated");      
    }, () => {
      if (field === "probill") {
        setProbill({
          value: recordData.probill,
          label: recordData.probill
        });
        setManifest(recordData.manifest);
      };
      if (field === "summary") setSummary(recordData.summary);
    }));
  };

  const {
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: ACCEPTED_FORMATS,
    maxSize: MAX_SIZE * 1048576,
    maxFiles: MAX_PHOTOS - photos.filter((obj: any) => !obj.deleted).length,
    onDrop: (acceptedFiles, fileRejections) => {
      setFileError("");
      if (fileRejections?.length > 0) {
        fileRejections[0].errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setFileError(`Files no larger than ${MAX_SIZE} MB are allowed!`);
          }
          if (err.code === "file-invalid-type") {
            setFileError("Only images of certain formats are allowed!");
          }
          if (err.code === "too-many-files") {
            setFileError(`Maximum ${MAX_PHOTOS} photos allowed!`);
          }
        });
      }
      if (acceptedFiles.length === 0) return;
      setPhotos([...photos, ...acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file),
        deleted: false,
        comment: "",
        internalOnly: true
      }))]);
    }
  });

  const photosBlock = photos.map((photo: any, i: number) => (
    <div
      className={`xgs-delivery-records__form__photos__item ${photo.deleted ? "xgs-delivery-records__form__photos__item--deleted" : ""}`}
      key={`photo.name-${i}`}
    >
      <div className="xgs-delivery-records__form__photos__item__image">
        <img
          src={photo.preview}
          alt="Preview"
        />
        <div
          className="xgs-delivery-records__form__photos__item__image__control"
          onClick={() => { removeNewPhoto(i) }}
        >
          <XGSIcon
            icon={XGSIcons.faTimes}
            className="xgs-delivery-records__form__photos__item__icon"
          />
        </div>
      </div>
      <div className="xgs-delivery-records__form__photos__item__fields">
        <div className="xgs-delivery-records__form__label">Comments:</div>
        <textarea
          name={`comments-${i}`}
          rows={3}
          className="xgs-form__textarea xgs-delivery-records__form__photos__item__fields__textarea"
          onChange={(e) => onCommentChange(i, e.currentTarget.value)}
        />
        {photos[i].comment.length > 255 && (
          <div className="xgs-labeled-input__validation-error">The value must be no more than 255 characters</div>
        )}
        <XGSCheckbox
          name={`internal-${i}`}
          onChange={() => onInternalFlagChange(i)}
          defaultChecked={true}
        >
          Internal only
        </XGSCheckbox>        
      </div>
    </div>
  ));

  const isPhotoCommentsValid = () => {
    let invalidPhotoComments = photos.find((obj: any) => obj.comment.length > 255);
    return !invalidPhotoComments;
  };

  const onCommentChange = (i: number, val: string) => {
    let newArr = [...photos];
    newArr[i].comment = val;
    setPhotos(newArr);
  };

  const onInternalFlagChange = (i: number) => {
    let newArr = [...photos];
    newArr[i].internalOnly = !newArr[i].internalOnly;
    setPhotos(newArr);
  };  

  const removeNewPhoto = (i: number) => {
    let newArr = [...photos];
    newArr[i].deleted = true;
    setPhotos(newArr);    
  };

  const onSubmitNewPhotos = () => {
    if (!props.id) return;
    const actualPhotos = photos.filter((obj: any) => !obj.deleted);
    const fd = new FormData();
    for (let photo of actualPhotos) {
      fd.append("files", photo);
    }
    const preparedData = {
      photos: actualPhotos.map((photo: any) => {
        return {
          internalOnly: photo.internalOnly,
          comment: photo.comment
        }
      })
    };
    fd.append("data", JSON.stringify(preparedData));
    dispatch(updateRecord(props.id, fd, () => {
      toast.info(`Photo${actualPhotos.length > 1 ? "s were " : " was "}added!`);
      mixpanel.track("Delivery record: photos were added");
      onCloseAddPhotos();
    }, () => {}));    
  };

  const onCloseAddPhotos = () => {
    setShowAddPhotos(false);
    setPhotos([]);
    setFileError("");
  };

  let probillSearch = (query: string) => {
    query?.length > 1 && dispatch(searchProbills(query));
  }
  probillSearch = debounce(probillSearch, 500);

  const gotoPreviousImage = () => {
    currentImageIndex > 0 && setCurrentImageIndex(currentImageIndex - 1);
  };

  const gotoNextImage = () => {
    (currentImageIndex + 1 < galleryImages.length) && setCurrentImageIndex(currentImageIndex + 1);
  };
  
  const onDeletePhoto = () => {
    if (!props.id || showPhotoRemoveConfirmation === -1) return;
    const photoId = recordData.items[showPhotoRemoveConfirmation].id;
    dispatch(removePhoto(props.id, photoId, () => {
      toast.info("The photo was deleted!");
      mixpanel.track("Images: photo was deleted");
      setShowPhotoRemoveConfirmation(-1);
    }));
  };

  const onChangePhotoInternalFlag = (i: number) => {
    if (!props.id || !photoNewFlag) return;
    const photoId = recordData.items[i].id;
    dispatch(updatePhoto(props.id, photoId, {
      internalOnly: !recordData.items[i].internalOnly
    }, () => {
      toast.info("The photo was updated!");
      mixpanel.track("Images: photo comment was updated");
      setPhotoNewComment("");
    }));    
  };

  const onChangePhotoComments = (i: number) => {
    if (!props.id) return;
    const photoId = recordData.items[i].id;
    dispatch(updatePhoto(props.id, photoId, {
      comment: photoNewComment.slice(0, 255)
    }, () => {
      toast.info("The photo was updated!");
      mixpanel.track("Images: photo comment was updated");
      setPhotoNewComment("");
    }));
  };

  useEffect(() => {
    if (!props.id) return;
    const record = recordsState.records?.find(recordObj => recordObj.id === props.id);
    if (!record) return;
    setRecordData(record);
    setPhotos([]);
    setFileError("");
    setProbill({
      value: record.probill,
      label: record.probill
    });
    setManifest(record.manifest);
    setSummary(record.summary);
    setPhotoNewFlag(false);
    setGalleryImages(record.items.map((obj: any) => {
      return {
        src: obj.storageUrl
      }
    }));
  }, [recordsState.records, props.id]);

  useEffect(() => {
    if (!recordsState.requestFailed || !(recordsState.requestCreator === "UPDATE_RECORD" || recordsState.requestCreator === "UPDATE_PHOTO")) return;
    toast.error(recordsState.requestError || "Error", { autoClose: 4000 });
  }, [recordsState.requestFailed, recordsState.requestError, recordsState.requestCreator]);

  return (
    <SlideOutSidebar
      header="Images Details"
      onClose={props.onClose}
      show={props.show}
      spinner={recordsState.requestStarted && (recordsState.requestCreator === "UPDATE_RECORD" || recordsState.requestCreator === "UPDATE_PHOTO")}
    >
      {recordData && (
        <div className="xgs-delivery-records__details" key={props.id}>
          {UserUtils.isXGSAdministrator(userState.profile) && (
            <div className="xgs-delivery-records__details__delete">
              <span
                onClick={() => setShowRemoveConfirmation(true)}
              >
                <XGSIcon
                  icon={XGSIcons.faTrash}
                  size="sm"
                />
                &nbsp;
                Delete Record
              </span>
            </div>
          )}
          <div className="xgs-delivery-records__details__content xgs-sidebar__details__rows">
            <div className="xgs-sidebar__details__row xgs-sidebar__details__row--fixed">
              <div className="xgs-sidebar__details__label xgs-sidebar__details__label--fixed">
                <div className="xgs-delivery-records__details__combo-edit__label">Probill:</div>
                <div className="xgs-delivery-records__details__combo-edit__label">Manifest:</div>
              </div>
              <div className="xgs-sidebar__details__value xgs-sidebar__details__value--fixed">
                <XGSInlineSwitch
                  name="probill"
                  staticContent={
                    <div>
                      <div className="xgs-delivery-records__details__combo-edit__value">{recordData.probill || "-"}</div>
                      <div className="xgs-delivery-records__details__combo-edit__value">{recordData.manifest || "-"}</div>
                    </div>
                  }
                  formElement={
                    <div>
                      <div className="xgs-delivery-records__details__combo-edit__field">
                        <LabeledSelectInput
                          isLoading={recordsState.requestStarted && recordsState.requestCreator === "SEARCH_PROBILL"}
                          label=""
                          labelMode={LabelModes.column}
                          name="probill"
                          onInputChange={(query) => probillSearch(query)}
                          onValueChange={(v) => {
                            setProbill(v);
                            v?.subtitle && setManifest(v.subtitle);
                          }}
                          openMenuOnClick={recordsState.probills.length > 0}
                          options={recordsState.probills}
                          placeholder="Start typing..."
                          value={probill}
                          className="xgs-delivery-records__details__probill-field"
                          isClearable={true}
                        />
                      </div>
                      <div className="xgs-delivery-records__details__combo-edit__field">
                        <XGSInput
                          type="text"
                          name="manifest"
                          value={manifest}
                          placeholder=""
                          onChange={(e) => setManifest(e.currentTarget.value)}
                        />
                      </div>
                    </div>
                  }
                  onCancel={() => {
                    setProbill({
                      value: recordData.probill,
                      label: recordData.probill
                    });
                    setManifest(recordData.manifest);
                  }}
                  onSubmit={() => {
                    if (probill?.value) {
                      onUpdateRecord({
                        probill: probill.value,
                        manifest: probill.subtitle || manifest || ""
                      }, "probill");
                    } else {
                      onUpdateRecord({
                        probill: "",
                        manifest: manifest
                      }, "probill");
                    }
                  }}
                  disabled={!UserUtils.isXGSAdministrator(userState.profile)}
                />
              </div>
            </div>
            {(recordData.payor || recordData.shipper || recordData.consignee) && (
              <div className="xgs-delivery-records__details__probill-info">
                {recordData.payor && (
                  <div className="xgs-sidebar__details__row">
                    <div className="xgs-sidebar__details__label">Payor:</div>
                    <div className="xgs-sidebar__details__value">
                      {recordData.payor.info} <span className="xgs-delivery-records__details__account-number">{recordData.payor.number}</span>
                    </div>
                  </div>
                )}
                {recordData.shipper && (
                  <div className="xgs-sidebar__details__row">
                    <div className="xgs-sidebar__details__label">Shipper:</div>
                    <div className="xgs-sidebar__details__value">
                      {recordData.shipper.info} <span className="xgs-delivery-records__details__account-number">{recordData.shipper.number}</span>
                    </div>
                  </div>
                )}
                {recordData.consignee && (
                  <div className="xgs-sidebar__details__row">
                    <div className="xgs-sidebar__details__label">Consignee:</div>
                    <div className="xgs-sidebar__details__value">
                      {recordData.consignee.info} <span className="xgs-delivery-records__details__account-number">{recordData.consignee.number}</span>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="xgs-sidebar__details__row xgs-delivery-records__details__row--extra-margin">
              <div className="xgs-sidebar__details__label">Comments:</div>
              <div className="xgs-sidebar__details__value">
                <XGSInlineSwitch
                  name="summary"
                  staticContent={recordData.summary || "-"}
                  formElement={
                    <div className="xgs-delivery-records__details__editable-textarea">
                      <textarea
                        name="summary"
                        rows={3}
                        className="xgs-form__textarea xgs-delivery-records__details__editable-textarea__field"
                        onChange={(e) => setSummary(e.currentTarget.value)}
                      >
                        {recordData.summary}
                      </textarea>
                      {summary.length > 255 && (
                        <div className="xgs-labeled-input__validation-error">The value must be no more than 255 characters</div>
                      )}
                    </div>
                  }
                  onCancel={() => {
                    setSummary(recordData.summary);
                  }}
                  onSubmit={() => onUpdateRecord({summary}, "summary")}
                  disabled={false}
                />
              </div>
            </div>
            <br /><br />
            <div className="xgs-sidebar__details__row">
              <div className="xgs-sidebar__details__label">Photos:</div>
              <div className={`xgs-sidebar__details__value xgs-delivery-records__preview ${previewMode === "list" ? "xgs-delivery-records__preview--list" : "xgs-delivery-records__preview--grid"}`}>
                {recordData.items.map((photo: any, i: number) => (
                  <div
                    className={`xgs-delivery-records__photo__item ${previewMode === "list" ? "xgs-delivery-records__photo__item--list" : "xgs-delivery-records__photo__item--grid"}`}
                    key={`photo.name-${i}`}
                  >
                    <div
                      className="xgs-delivery-records__photo__item__preview"
                      onClick={() => {
                        setCurrentImageIndex(i);
                        setShowGallery(true);
                      }}
                    >
                      <img
                        src={`data:image/jpeg;base64,${photo.preview}`}
                        className="xgs-delivery-records__photo__item__preview__image"
                        alt="Preview"
                        key={photo.id}
                      />
                      {previewMode === "grid" && (
                        <>
                          {photo.internalOnly && (
                            <div
                              className="xgs-delivery-records__photo__item__preview__grid-internal-only"
                              title="Internal Only"
                            >
                              <XGSIcon
                                icon={XGSIcons.faEyeSlash}
                                className="xgs-delivery-records__photo__item__preview__grid-internal-only__icon"
                              />
                            </div>
                          )}
                          {photo.comment && (
                            <div
                              className="xgs-delivery-records__photo__item__preview__grid-comments"
                              onClick={(e) => {
                                e.stopPropagation();
                                setGridComment(photo.comment);
                                setGridCommentOpen(true);
                              }}
                            >
                              <XGSIcon
                                icon={XGSIcons.faCommentAlt}
                                className="xgs-delivery-records__photo__item__preview__grid-comments__icon"
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    {previewMode === "list" && (
                      <>
                        <div className="xgs-delivery-records__photo__item__meta">
                          {UserUtils.isXGSAdministrator(userState.profile) && (
                            <div className="xgs-delivery-records__photo__item__meta__delete">
                              <span
                                onClick={() => setShowPhotoRemoveConfirmation(i)}
                              >
                                <XGSIcon
                                  icon={XGSIcons.faTrash}
                                  size="sm"
                                />
                              </span>
                            </div>
                          )}
                          <div className="xgs-delivery-records__photo__item__meta__row xgs-delivery-records__photo__item__meta__row--flex">
                            <div className="xgs-delivery-records__photo__item__meta__row__header">Visibility:</div>
                            <XGSInlineSwitch
                              name={`visibility-${i}`}
                              staticContent={photo.internalOnly ? "Internal Only" : "Everyone"}
                              formElement={
                                <XGSCheckbox
                                  mix="xgs-delivery-records__switch-checkbox"
                                  name={`internal-${i}`}
                                  onChange={() => setPhotoNewFlag(!photoNewFlag)}
                                  defaultChecked={photo.internalOnly}
                                >
                                  Internal only
                                </XGSCheckbox>
                              }
                              onCancel={() => {
                                setPhotoNewFlag(false);
                              }}
                              onSubmit={() => {
                                onChangePhotoInternalFlag(i);
                              }}
                              disabled={!UserUtils.isXGSAdministrator(userState.profile)}
                            />
                          </div>
                          <div className="xgs-delivery-records__photo__item__meta__row">
                            <div className="xgs-delivery-records__photo__item__meta__row__header">Comments:</div>
                            <XGSInlineSwitch
                              name={`comment-${i}`}
                              staticContent={photo.comment || "-"}
                              formElement={
                                <div className="xgs-delivery-records__details__editable-textarea">
                                  <textarea
                                    name="comment"
                                    rows={2}
                                    className="xgs-form__textarea xgs-delivery-records__details__editable-textarea"
                                    onChange={(e) => setPhotoNewComment(e.currentTarget.value)}
                                  >
                                    {photo.comment}
                                  </textarea>
                                  {photoNewComment.length > 255 && (
                                    <div className="xgs-labeled-input__validation-error">The value must be no more than 255 characters</div>
                                  )}
                                </div>
                              }
                              onCancel={() => {
                                setPhotoNewComment("");
                              }}
                              onSubmit={() => {
                                onChangePhotoComments(i)
                              }}
                              disabled={false}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
                <div className="xgs-delivery-records__preview__switch">
                  <div
                    className="xgs-delivery-records__preview__switch__button"
                    onClick={() => previewMode === "grid" ? setPreviewMode("list") : setPreviewMode("grid")}
                  >
                    <XGSIcon
                      icon={previewMode === "grid" ? XGSIcons.faList : XGSIcons.faTh}
                      className="xgs-delivery-records__preview__switch__icon"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="xgs-sidebar__details__row">
              <div className="xgs-sidebar__details__label"></div>
              <div className="xgs-sidebar__details__value">
                <Button
                  theme={ButtonThemes.blue}
                  type="button"
                  onClick={() => setShowAddPhotos(true)}
                  className="xgs-delivery-records__details__add-photos-button"
                >
                  Add Photos
                </Button>
              </div>
            </div>
          </div>
          <Lightbox
            isOpen={showGallery}
            onPrev={gotoPreviousImage}
            onNext={gotoNextImage}
            images={galleryImages}
            currentIndex={currentImageIndex}
            className="xgs-gallery"
            renderHeader={() => (
              <div className="xgs-gallery__header">
                <XGSIcon
                  icon={XGSIcons.faTimes}
                  size="2x"
                  className="xgs-gallery__header__close-icon"
                  onClick={() => setShowGallery(false)}
                />
              </div>
            )}
            renderPrevButton={() => (
              <>
                {currentImageIndex > 0 && (
                  <div className="xgs-gallery__prev">
                    <XGSIcon
                      icon={XGSIcons.faChevronLeft}
                      size="2x"
                      className="xgs-gallery__prev__icon"
                      onClick={() => gotoPreviousImage()}
                    />
                  </div>
                )}
              </>
            )}
            renderNextButton={() => (
              <>
                {(currentImageIndex + 1 < galleryImages.length) && (
                  <div className="xgs-gallery__next">
                    <XGSIcon
                      icon={XGSIcons.faChevronRight}
                      size="2x"
                      className="xgs-gallery__next__icon"
                      onClick={() => gotoNextImage()}
                    />
                  </div>
                )}
              </>
            )}
            singleClickToZoom
          />
          <ConfirmationModal
            opened={gridCommentOpen}
            header="Comments"
            confirmButtonText="Close"
            onConfirm={() => {
              setGridCommentOpen(false);
              setGridComment("");
            }}
          >
            <div>{gridComment}</div>
          </ConfirmationModal>
          <ConfirmationModal
            opened={showRemoveConfirmation}
            header="Delete permanently"
            confirmButtonText="Delete record"
            spinner={recordsState.requestStarted && recordsState.requestCreator === "REMOVE_RECORD"}
            onCancel={() => { setShowRemoveConfirmation(false); }}
            onConfirm={() => { onDeleteRecord(); }}
          >
            This operation cannot be undone,<br />
            the record will be removed permanently.
          </ConfirmationModal>
          <ConfirmationModal
            opened={showPhotoRemoveConfirmation !== -1}
            header="Delete permanently"
            confirmButtonText="Delete photo"
            spinner={recordsState.requestStarted && recordsState.requestCreator === "REMOVE_PHOTO"}
            onCancel={() => { setShowPhotoRemoveConfirmation(-1); }}
            onConfirm={() => { onDeletePhoto(); }}
          >
            This operation cannot be undone,<br />
            the photo will be removed permanently.
          </ConfirmationModal>
          <Modal
            isOpen={showAddPhotos}
            style={wideModalStyle}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
          >
            <div className="xgs-modal__header">
              Add photos to the record
            </div>
            <div className="xgs-modal__content xgs-delivery-records__details__add-photos-modal">
              {(photos.filter((obj: any) => !obj.deleted).length < MAX_PHOTOS) && (
                <div {...getRootProps({ className: "xgs-upload__area" })}>
                  <input {...getInputProps()} />
                  <span className="blue-link">tap, click or drag &amp; drop photos here</span>
                  <div className="xgs-upload__notes">(<strong>JPEG</strong>, <strong>PNG</strong>, <strong>WebP</strong>, <strong>GIF</strong> and <strong>BMP</strong> formats only)</div>
                  {fileError && (
                    <span className="xgs-upload__area__error">
                      {fileError}
                    </span>
                  )}
                </div>
              )}
              {(photos.length > 0) && (
                <div className="xgs-delivery-records__form__photos">
                  {photosBlock}
                </div>
              )}
            </div>
            <div className="xgs-modal__buttons text-center">
              <Button
                theme={ButtonThemes.blue}
                onClick={onSubmitNewPhotos}
                spinner={recordsState.requestStarted && recordsState.requestCreator === "UPDATE_RECORD"}
                disabled={(photos.filter((obj: any) => !obj.deleted).length === 0) || !isPhotoCommentsValid}
                className="xgs-modal__button"
                type="button"
              >
                Add Photos
              </Button>
              <Button
                theme={ButtonThemes.gray}
                onClick={onCloseAddPhotos}
                className="xgs-modal__button"
                type="button"
              >
                Cancel
              </Button>
            </div>
          </Modal>
        </div>
      )}
    </SlideOutSidebar>
  );
};

export default RecordDetails;
