import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../ui-components/table/table";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import LabeledTextInput from "../../../ui-components/molecules/labeled-inputs/labeled-text-input/labeledTextInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import UsersState from "../../../slices/customers/users/TeamUsersState";
import {
  getUsers,
  resetErrors,
  usersSelector
} from "../../../slices/customers/users/teamUsersSlice";
import UserDetails from "../../company/user-details/userDetails";
import InviteUser from "../../invite-user/inviteUser";
import UserProfile from "../../../app/data/user/UserProfile";
import { userStatuses } from "../../company/constants";
import { customerRoles } from "../../../app/data/common/customerRoles";
import "./companySettingsUsers.scss";

const CompanySettingsUsers: React.FC<{}> = () => {
  const [userId, setUserId] = useState<string | undefined>();
  const [showInviteUser, setShowInviteUser] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>("");
  const dispatch = useDispatch();
  const usersState: UsersState = useSelector(usersSelector);

  const columns = [
    {
      width: 120,
      Header: "Sign-up date",
      accessor: "dateCreated",
      Cell: (cellProps: any) => (
        <>
          { cellProps.value.toUiDateFormat() }
        </>
      )
    },
    {
      width: 200,
      Header: "Name",
      accessor: "name"
    },
    {
      width: 140,
      Header: "Title",
      accessor: "title"
    },
    {
      Header: "Email",
      accessor: "email",
      width: 200
    },
    {
      Header: "Phone",
      accessor: "phoneNumber",
      width: 200
    },
    {
      Header: "Role",
      accessor: "role",
      width: 120,
      Cell: (cellProps: any) => (
        <>
          { customerRoles.find(role => role.value === cellProps.value)?.label }
        </>
      )
    },
    {
      Header: (
        <div style={{ textAlign: "center" }}>
          Status
        </div>
      ),
      accessor: "status",
      width: 70,
      Cell: (cellProps: any) => (
        <div
          className="xgs-company__users__details__status__circle"
          style={{
            backgroundColor: userStatuses.find(status => status.value === cellProps.value)?.color,
            marginLeft: "auto",
            marginRight: "auto"
          }}
          title={cellProps.value.toLowerCase()}>
        </div>
      )
    }
  ];

  const onListInfiniteScroll = () => {
    dispatch(getUsers("current", false, undefined, usersState.usersRequest?.lastIds));
  };

  const onUserClick = (row: UserProfile) => {
    setUserId(row.id)
  };

  const onSearchStart = () => {
    dispatch(getUsers("current", false, searchString));
    setUserId(undefined);
  };

  const onSearchClear = () => {
    setSearchString("");
    dispatch(getUsers("current", false, undefined));
    setUserId(undefined);
  };

  useEffect(() => {
    dispatch(resetErrors());
    dispatch(getUsers("current", false, undefined));

    return () => {
      dispatch(resetErrors());
    }
  }, [dispatch]);

  return (
    <div className="xgs-company__users">
      <div className="xgs-list__controls xgs-company__users__controls">
        <div className="xgs-list__controls__search">
          <LabeledTextInput
            labelMode={LabelModes.column}
            className="xgs-list__controls__search__input"
            value={searchString}
            onChange={(e) => setSearchString(e.currentTarget.value)}
            onKeyDown={(e) => (e.key === "Enter") && !(usersState.fetch_was_started && usersState.requestCreator === "GET_USERS") && onSearchStart()}
            type="text"
            placeholder="First, last name, email"
          />
          <Button
            className="xgs-list__controls__button"
            theme={ButtonThemes.blue}
            disabled={usersState.fetch_was_started && usersState.requestCreator === "GET_USERS"}
            onClick={onSearchStart}>
            Search
          </Button>
          <Button
            className="xgs-list__controls__button"
            theme={ButtonThemes.gray}
            onClick={onSearchClear}>
            Clear
          </Button>
        </div>
        <Button
          className="xgs-company__users__invite-button"
          theme={ButtonThemes.blue}
          onClick={() => { setShowInviteUser(true); }}>
          Invite new user
        </Button>
        <InviteUser
          show={showInviteUser}
          onInviteSent={() => {
            dispatch(getUsers("current", false, undefined));
          }}
          onClose={() => {
            setShowInviteUser(false);
          }}
        />
      </div>
      {usersState.fetch_was_failed && usersState.requestCreator === "GET_USERS" && (
        <XGSErrorMessage>{usersState.fetch_fail_reason}</XGSErrorMessage>
      )}
      {!(usersState.fetch_was_failed && usersState.requestCreator === "GET_USERS") && (
        <Table
          isLoading={usersState.fetch_was_started && usersState.requestCreator === "GET_USERS"}
          columns={columns}
          data={usersState.users}
          onRowClicked={onUserClick}
          cursorPointer={true}
          rowHeight={66}
          minTableHeight={420}
          noResultsText="There are no users"
          infiniteScroll={true}
          infiniteScrollLoading={usersState.fetchPortion_was_started}
          infiniteScrollHasNext={!usersState.usersFetchedAll}
          onInfiniteScroll={onListInfiniteScroll}
        />
      )}
      <UserDetails
        id={userId}
        show={!!userId}
        onClose={() => {
          setUserId(undefined);
        }}
        onDelete={() => {
          dispatch(getUsers("current", false, undefined));
        }}
        customer={true}
      />
    </div>
  );
};

export default CompanySettingsUsers;
