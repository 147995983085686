import React, { memo } from "react";
import { PlanningIssue, UnplannableProbill } from "../../../../app/data/unplannable-probills/models";
import SlideOutSidebar from "../../../../ui-components/slide-out-sidebar/slideOutSidebar";
import "./unplannableProbillDetails.scss";

interface UnplannableProbillDetailsProps {
  probillDetails: UnplannableProbill | null,
  onClose: () => void
}
const UnplannableProbillDetails: React.FC<UnplannableProbillDetailsProps> = memo((props) => {

  return (
    <SlideOutSidebar
      header="Planning Details"
      onClose={props.onClose}
      size="extrasmall"
      show={!!(props.probillDetails && props.probillDetails.probillNumber)}
    >
      <div className="xgs-unplannable-probill-details">
        <div className="xgs-unplannable-probill-details__header">
        Planning details for Probill# {props.probillDetails?.probillNumber}
        </div>
        <div className="planning-details">
          <div className="planning-details__title">
          </div>
          <div className="planning-details__content">
            <div className="planning-details__content__properties">
              <div className="planning-details__content__properties__type">Total Items at Destination SC:</div>
              <div className="planning-details__content__properties__value">{props.probillDetails?.totalItems || 0}</div>
            </div>
            <div className="planning-details__content__properties">
              <div className="planning-details__content__properties__type">Plannable Items:</div>
              <div className="planning-details__content__properties__value">{props.probillDetails?.plannableItems || 0}</div>
            </div>
            <div className="planning-details__content__properties">
              <div className="planning-details__content__properties__type">Items already on a Picklist:</div>
              <div className="planning-details__content__properties__value">{props.probillDetails?.itemsOnPicklist || 0}</div>
            </div>
          </div>
          <div className="planning-details__issues">
            <div className="planning-details__issues__title">
              Planning Issues:
            </div>
            <ol className="planning-details__issues__content">
              {(props.probillDetails && props.probillDetails.planningIssues && props.probillDetails.planningIssues.length) ?
                props.probillDetails.planningIssues.map((
                  planningIssue: PlanningIssue,
                  planningIssueIdx: number
                ) =>
                  <li
                    key={`pi_${planningIssueIdx}`}
                    className="planning-details__issues__content_item"
                  >
                    <div className="planning-details__issues__content_item__type">
                      {planningIssue.type}
                    </div>
                    <div className="planning-details__issues__content__item__desc">
                      <span className="--text-light">
                        Descriptions:
                      </span>
                      {planningIssue.details}
                    </div>
                  </li>
                )
                : <span className="planning-details__issues__content--no-issue">
                  There is no planning related issue with the probill
                </span>
              }
            </ol>
          </div>
        </div>
      </div>
    </SlideOutSidebar>
  );
});

export default UnplannableProbillDetails;
