export enum UserRoles {
  USER = "USER",
  ADMINISTRATOR = "ADMINISTRATOR",
  XGS_USER = "XGS_USER",
  XGS_ADMINISTRATOR = "XGS_ADMINISTRATOR",
  XGS_DRIVER = "XGS_DRIVER",
  AGENT = "AGENT"
}

export enum UserStatuses {
  ACTIVE = "ACTIVE",
  APPROVED = "APPROVED",
  DISABLED = "DISABLED",
  INVITED = "INVITED",
  SIGNED_UP = "SIGNED_UP",
  TRANSFERRED = "TRANSFERRED"
}
