
export default interface FuelSurchargeState {
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError?: string | null;
  caRate: number;
  usRate: number;
}

export const initialFuelSurchargeState: FuelSurchargeState = {
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestError: null,
  usRate: 0,
  caRate: 0
};
