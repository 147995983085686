import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { Routes } from "../../app/route/RoutesConfig";
import { PathParams, RatesPath } from "./route";
import ContentContainer from "../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import Table from "../../ui-components/table/table";
import CustomCell, { CustomCellTypes } from "../../ui-components/table/custom-cells/customCell";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import UserState from "../../slices/user/UserState";
import { userSelector } from "../../slices/user/userSlice";
import RatesState from "../../slices/rates/RatesState";
import {
  getRatesDetails,
  ratesSelector  
} from "../../slices/rates/ratesSlice";
import { setBolFromRequestRates } from "../../slices/bol/bolSlice";
import { ShippingItemFormModel } from "../../app/data/rates/models"
import DownloadPDF from "./download-pdf/downloadPDF";
import ShareQuote from "./share-quote/shareQuote";
import "./rates.scss";

const RatesDetails: React.FC<{}> = (props) => {
  const params = useParams() as PathParams;
  const ratesId = params[RatesPath.id];
  const dispatch = useDispatch();
  const history = useHistory();
  const ratesState: RatesState = useSelector(ratesSelector);
  const userState: UserState = useSelector(userSelector);

  const columns = [
    {
      Header: (
        <div style={{ textAlign: "right" }}>
          Pieces
        </div>
      ),
      accessor: "pieces",
      width: 60,
      minWidth: 60,
      Cell: (cellProps: any) => <div style={{ textAlign: "right" }}>{cellProps.value || ""}</div>
    },
    {
      Header: (
        <div style={{ textAlign: "center" }}>
          <span>Class</span>
        </div>
      ),      
      accessor: "freightClass",
      width: 60,
      Cell: (cellProps: any) => <div style={{ textAlign: "center" }}>{cellProps.value}</div>
    },
    {
      width: 308,
      Header: "Description",
      accessor: "description",
      Cell: (cellProps: any) => <>{cellProps.value}{cellProps.row.original.commodityCode ? ` (${cellProps.row.original.commodityCode})` : ""}</>
    },
    {
      Header: (
        <div style={{ textAlign: "center" }}>
          <span>Units</span>
        </div>
      ),
      accessor: "units",
      Cell: (cellProps: any) => <div style={{ textAlign: "center" }}>{cellProps.value || ""}</div>,
      width: 60
    },
    {
      Header: (
        <div style={{ textAlign: "right" }}>
          <span>Sq. yards</span>
        </div>
      ),
      accessor: "yards",
      Cell: (cellProps: any) => <div style={{ textAlign: "right" }}>{cellProps.value || ""}</div>,
      width: 90
    },
    {
      Header: (
        <div style={{ textAlign: "right" }}>
          Weight
        </div>
      ),
      accessor: "weight",
      Cell: (cellProps: any) => <div style={{ textAlign: "right" }}>{cellProps.value || ""}</div>,
      width: 100
    },
    {
      Header: (
        <div style={{ textAlign: "right" }}>
          Rate
        </div>
      ),      
      accessor: "rate",
      Cell: (cellProps: any) => <div style={{ textAlign: "right" }}>{cellProps.value || ""}</div>,
      width: 100
    },
    {
      Header: (
        <div style={{ textAlign: "right" }}>
          Freight charges
        </div>
      ),       
      accessor: "freightCharges",
      Cell: (cellProps: any) => (
        <div style={{ textAlign: "right" }}>
          {cellProps.value ? <CustomCell
            cellType={CustomCellTypes.amount}
            selector="freightCharges"
            data={cellProps.row.original}
            value={cellProps.value}
          /> : ""}
        </div>
      ),
      width: 100
    }
  ];

  const onCreateBOL = () => {
    dispatch(setBolFromRequestRates({
      quoteNumber: ratesState.ratesDetails.quoteNumber,
      pickupZip: ratesState.ratesDetails.originZip,
      items: ratesState.ratesDetails.items.filter((item: ShippingItemFormModel) => item.freightClass)
    }));
    mixpanel.track("Started BOL creation from previously requested rates");
    history.push(`/${userState.activeSubAccount?.accountNumber}${Routes.bol.create}`);
  };

  useEffect(() => {
    dispatch(getRatesDetails(ratesId));
    // eslint-disable-next-line
  }, []);

  return (
    <ContentContainer
      titleComponent={(
        <ContentContainerToolbar
          title="Rating"
          isLoading={ratesState.requestStarted && ratesState.requestCreator === "GET_RATES_DETAILS"}
          className="xgs-rates__details__toolbar"
        >
          <>
            {ratesState.ratesDetails?.quoteNumber && <DownloadPDF quoteNumber={ratesState.ratesDetails.quoteNumber} />}
            {ratesState.ratesDetails?.id && <ShareQuote quoteId={ratesState.ratesDetails.id} />}
          </>
        </ContentContainerToolbar>
      )}
    >
      <div className="xgs-request-rates__result-preview">
        {ratesState.requestFailed && (
          <XGSErrorMessage>{ratesState.requestError}</XGSErrorMessage>
        )}
        {ratesState.ratesDetails && ratesState.ratesDetails.errorMessage && (
          <XGSErrorMessage>{ratesState.ratesDetails.errorMessage}</XGSErrorMessage>
        )}
        {ratesState.ratesDetails && !ratesState.ratesDetails.errorMessage && (
          <>
            <div className="xgs-request-rates__result-preview__details">
              <div className="xgs-request-rates__result-preview__details__name">Quote number:</div>
              <div className="xgs-request-rates__result-preview__details__value">{ratesState.ratesDetails.quoteNumber}</div>
            </div>
            <div className="xgs-request-rates__result-preview__details">
              <div className="xgs-request-rates__result-preview__details__name">Origin:</div>
              <div className="xgs-request-rates__result-preview__details__value">{ratesState.ratesDetails.origin}</div>
            </div>
            <div className="xgs-request-rates__result-preview__details">
              <div className="xgs-request-rates__result-preview__details__name">Destination:</div>
              <div className="xgs-request-rates__result-preview__details__value">{ratesState.ratesDetails.destination}</div>
            </div>
            <div className="xgs-request-rates__result-preview__details">
              <div className="xgs-request-rates__result-preview__details__name">Expiration date:</div>
              <div className="xgs-request-rates__result-preview__details__value">{ratesState.ratesDetails.expiresOn.toUiDateFormat()}</div>
            </div>
            <div className="xgs-request-rates__result-preview__summary__header">Shipment summary</div>
            <Table
              columns={columns}
              data={ratesState.ratesDetails.items}
              totals={[
                {
                  title: "Total",
                  type: CustomCellTypes.amount,
                  value: ratesState.ratesDetails.items.reduce((total: number, item: any) => total + item.freightCharges, 0) || "0"
                },
                {
                  title: `${ratesState.ratesDetails.fsc?.percent}% FSC`,
                  type: CustomCellTypes.amount,
                  value: ratesState.ratesDetails.fsc?.total || "0"
                },
                {
                  title: "Quote Total",
                  type: CustomCellTypes.amount,
                  value: ratesState.ratesDetails.total || "0"
                }
              ]}
            />
            <div className="xgs-request-rates__result-preview__explanations">
              RATES ARE BASED OFF CURRENT XGSI 3500 TARIFF UNLESS SPECIAL PRICING IS ASSIGNED TO YOUR ACCOUNT. 
              ALL QUOTES ARE SUBJECT TO PUBLISHED CUSTOMER PRICING AND THE TERMS OF ANY XGSI 3501 RULES TARIFF 
              ITEMS APPLICABLE AT TIME OF SHIPMENT (SEE ITEM # 720 FOR COMPLETE RULES). 
              QUOTES ARE VALID FOR 15 DAYS FROM ISSUE DATE ONLY.
            </div>
            <div>&nbsp;</div>
            {ratesState.ratesDetails.expiresOn && !ratesState.ratesDetails.expired && (
              <Button
                theme={ButtonThemes.blue}
                onClick={onCreateBOL}
                className="xgs-rates__details__create-bol"
              >
                Create Bill of Lading
              </Button>
            )}
          </>
        )}
      </div>
    </ContentContainer>
  );
};

export default RatesDetails;
