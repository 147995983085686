
export default interface RatesState {
  requestCreator: string;
  requestStarted: boolean;
  requestPortionStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError: string;
  rates: any[];
  ratesRequest: any;
  ratesFetchedAll: boolean;
  ratesDetails: any;
}

export const initialRatesState: RatesState = {
  requestCreator: "",
  requestStarted: false,
  requestPortionStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestError: "",
  rates: [],
  ratesRequest: null,
  ratesFetchedAll: false,
  ratesDetails: null
};
