import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import DropdownMenu, { DropdownMenuItem } from "../../../ui-components/dropdown-menu/dropdownMenu";
import UserState from "../../../slices/user/UserState";
import {
  closeMobileSideMenu,
  logout,
  userSelector
} from "../../../slices/user/userSlice";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import { Routes } from "../../../app/route/RoutesConfig";
import { UserUtils } from "../../../app/data/user/userUtils";
import ContactUs from "../../contact-us/contactUs";
import Userpick from "./userpick";
import { ReactComponent as GearIcon } from "../../../images/gear.svg";
import { ReactComponent as ContactIcon } from "../../../images/contact.svg";
import { ReactComponent as CompanyIcon } from "../../../images/company.svg";
import { ReactComponent as LogoutIcon } from "../../../images/logout.svg";
import "./userMenu.scss";

const UserMenu: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userState: UserState = useSelector(userSelector);
  const [opened, setOpened] = useState<boolean>(false);
  const [unblocked, setUnblocked] = useState<boolean>(true);
  const [contactUsPopup, setContactUsPopup] = useState<boolean>(false);

  const menuItems: DropdownMenuItem[] = [
    {
      text: "Settings",
      to: Routes.settings,
      onClick: () => {
        closeDropdown();
        dispatch(closeMobileSideMenu());
      },
      iconComponent: <GearIcon />,
    }
  ];

  if (UserUtils.isAdministrator(userState.profile)) {
    menuItems.push({
      text: "Company",
      to: Routes.company.general,
      onClick: () => {
        closeDropdown();
        dispatch(closeMobileSideMenu());
      },
      iconComponent: <CompanyIcon />,
    });
  }
  
  if (UserUtils.isAdministrator(userState.profile) || UserUtils.isUser(userState.profile)) {
    menuItems.push({
      text: "Contact Us",
      onClick: () => {
        closeDropdown();
        dispatch(closeMobileSideMenu());
        setContactUsPopup(true);
      },
      iconComponent: <ContactIcon />,
    });
  }

  menuItems.push({
    text: "Log out",
    onClick: () => {
      dispatch(closeMobileSideMenu());
      if (userState.profile?.impersonated) {
        localStorage.removeItem("xgs-impersonate-token");
        history.push(Routes.home);
        window.location.reload();
      } else {
        setTimeout(() => {
          mixpanel.track("Logged Out", {"Reason": "Click on the profile menu item"});
          mixpanel.reset();
          dispatch(logout());
        }, 100);
      }
    },
    iconComponent: <LogoutIcon />,
  });

  const openDropdown = () => {
    if (!unblocked) return;
    setUnblocked(false);
    setOpened(true);
  };

  const closeDropdown = () => {
    setOpened(false);
    setTimeout(() => setUnblocked(true), 150);
  };

  return (
    <div className="xgs-user-menu">
      <div className="xgs-user-menu__trigger" onClick={openDropdown}>
        <div className="xgs-user-menu__user xgs-user-menu__user--normal">
          <div className="xgs-user-menu__user__info">
            <Userpick profile={userState.profile} />
            <div>
              <div className="xgs-user-menu__user__info-item">
                {userState.profile?.firstName} {userState.profile?.lastName}
              </div>
              {userState.profile?.team?.name && (
                <div className="xgs-user-menu__user__info-item xgs-user-menu__user__team" title={userState.profile.team.name}>{userState.profile.team.name}</div>)}
            </div>
          </div>
        </div>
        <div className="xgs-user-menu__user xgs-user-menu__user--short">
          <Userpick profile={userState.profile} />
        </div>
        <span className="xgs-user-menu__arrow">
          <XGSIcon icon={opened ? XGSIcons.faChevronUp : XGSIcons.faChevronDown} />
        </span>
      </div>
      <DropdownMenu
        menuItems={menuItems}
        opened={opened}
        onClose={closeDropdown}
      />
      <ContactUs
        onClose={() => setContactUsPopup(false)}
        showPopup={contactUsPopup}
      />
    </div>
  );
}

export default UserMenu;