import { createSlice } from "@reduxjs/toolkit";
import { IState } from "../..";
import { AppThunk } from "../../../app/store";
import RoutesService from "../../../app/data/plan-probills/routes/routesService";
import { RemoveStopFromRouteState } from "./routesState";
import { getRouteStops } from "./routeStopsSlice";

const initialState: RemoveStopFromRouteState = {
  loading: false,
  loadingFailed: false,
  loaded: false,
  error: ""
};

const routesService = RoutesService.getInstance();

export const removeStopFromRouteSlice = createSlice({
  name: "removeStopFromRoute",
  initialState,
  reducers: {
    removeStopFromRouteResetState: (state) => initialState,
    removeStopFromRouteLoadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
    },
    removeStopFromRouteLoadingSucceed: (state) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
    },
    removeStopFromRouteLoadingFailed: (state, { payload }) => {
      state.loading = false;
      state.loadingFailed = true;
      state.error = payload;
    },

  },
});

export const {
  removeStopFromRouteResetState,
  removeStopFromRouteLoadingStarted,
  removeStopFromRouteLoadingSucceed,
  removeStopFromRouteLoadingFailed,

} = removeStopFromRouteSlice.actions;

export const removeStopFromRouteSelector = (state: IState) => state.removeStopFromRoute;

export const removeStopFromRoute = (
  reqData: any,
  onSuccess?: () => void,
  onError?: (error: string) => void,
  onClose?: () => void
): AppThunk => async (dispatch) => {
  dispatch(removeStopFromRouteLoadingStarted());
  const response = await routesService.removeStopFromRoute({ ...reqData });
  if (response.ok() && response.data && response.data.result) {
    dispatch(removeStopFromRouteLoadingSucceed());
    dispatch(getRouteStops({...reqData}));
    onSuccess && onSuccess();
  } else {
    let error = "Internal Server Error, re-try the action or contact support";
    if (response && response.data && response.data.error) error = response.data.error;
    dispatch(removeStopFromRouteLoadingFailed(error));
    onError && onError(error)
  }
  onClose && onClose()
};

const removeStopFromRouteReducer = removeStopFromRouteSlice.reducer;
export default removeStopFromRouteReducer;