import * as Yup from "yup"
import { validationMessages } from "../common/validationMessages"
import moment from "moment"

export interface ManifestsFilter {  
  dispatchedDate?: string | null;
  manifestNumber?: string | null;
  probillNumber?: string | null;
  driverId?: string | null;
}

export interface ManifestsRequest extends ManifestsFilter {
  terminal?: number | null;  
}

const oneOf = (args: any[]) => args.find(value => typeof value === 'string' ? !!value.trim() : !!value);

export const ManifestsFilterSchema: Yup.ObjectSchema<ManifestsFilter> = Yup.object().shape({  
  dispatchedDate: Yup.string().nullable()
    .when(["probillNumber", "manifestNumber", "driverId"], {
      is: (...args) => !oneOf(args),
      then: Yup.string()
        .required(validationMessages.atLeastOne)
        .test("is-within-2-years", "Date must be no older than 2 years from now", (value: string) => {
          const momentDate = moment(value);

          return momentDate.isValid() && momentDate.isAfter(moment().subtract(2, "y"));
        }),
    }),
  manifestNumber: Yup.string().nullable().matches(/(\w+|-+)/, validationMessages.notValid)
    .when(["dispatchedDate", "probillNumber", "driverId"], {
      is: (...args) => !oneOf(args),
      then: Yup.string().required(validationMessages.atLeastOne)
    }),
  probillNumber: Yup.string().nullable().matches(/(\w+|-+)/, validationMessages.notValid)
    .when(["dispatchedDate", "manifestNumber", "driverId"], {
      is: (...args) => !oneOf(args),
      then: Yup.string().required(validationMessages.atLeastOne),
    }),
  driverId: Yup.string().nullable()
    .when(["dispatchedDate", "manifestNumber", "probillNumber"], {
      is: (...args) => !oneOf(args),
      then: Yup.string().required(validationMessages.atLeastOne),
    }),
}, [  
  ["dispatchedDate", "probillNumber"],  
  ["dispatchedDate", "manifestNumber"],
  ["probillNumber", "manifestNumber"],  
  ["driverId", "manifestNumber"],
  ["driverId", "probillNumber"],
  ["driverId", "dispatchedDate"],
]).defined()