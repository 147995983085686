import { HolidayDateModel, HolidayLogModel } from "../../app/data/holiday-calendar/models";

export interface HolidayCalendarState{
  holidaySuggestions: HolidayDateModel[];
  holidays: HolidayDateModel[];
  logs: HolidayLogModel[];
  requestStarted: boolean;
  requestCreator: string;
  requestFailed: boolean;
  requestError: string;
  needsToReload: boolean;
  loaded: boolean;
  loading: boolean;
  loadingFailed: boolean;
  loadingError: string;
  loadingLogs: boolean;
  loadedLogs: boolean;
  loadingLogsFailed: boolean;
  loadingLogsError?: string;
}

export const initialHolidayCalendarState: HolidayCalendarState = {
  holidays: [],
  holidaySuggestions: [],
  logs: [],
  requestError: "",
  requestFailed: false,
  requestStarted: false,
  needsToReload: false,
  requestCreator: "",
  loaded: false,
  loading: false,
  loadingError: "",
  loadingFailed: false,
  loadingLogs: false,
  loadedLogs: false,
  loadingLogsFailed: false,
}