
export interface LegacyResponseModel {
  error: string | null;
  data: LegacyDataModel | null;
}

export interface LegacyDataModel {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  title: string;
}

export default interface LegacyUserState {
  username: string | null;
  password: string | null;
  data: LegacyDataModel | null;
  requestStarted: boolean;
  requestFailed: boolean;
  requestSucceed: boolean;
  requestFailReason: string | null;
  requestCreator: string;
}

export const initialLegacyUserState: LegacyUserState = {
  username: null,
  password: null,
  data: null,
  requestStarted: false,
  requestFailed: false,
  requestSucceed: false,
  requestFailReason: null,
  requestCreator: ""
};
