import React from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "./dropdownPopup.scss";

export interface DropdownPopupProps {
  opened: boolean;
  triggerElement?: JSX.Element;
  onClose: () => void;
  className?: string;
  position?: any;
}

const DropdownPopup: React.FC<DropdownPopupProps> = (props) => {
  const position:any = props.position ? props.position : "bottom left";
  return (
    <Popup
      className={props.className}
      trigger={props.triggerElement ? props.triggerElement : <div></div>}
      open={props.opened}
      position={position}
      closeOnDocumentClick
      closeOnEscape
      onClose={props.onClose}
      arrow={false}
      offsetY={10}
      contentStyle={{
        borderRadius: 4,
        border: "1px solid #E0E0E0",
        boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.15)",
        padding: "16px 24px",
        zIndex: 22
      }}
      overlayStyle={{
        zIndex: 21,
        backgroundColor: "#efefef",
        opacity: "10%",
      }}
    >
      <div>{props.children}</div>
    </Popup>
  );
};

export default DropdownPopup;
