import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";

import { getShipmentSearchSettings, generalSettingsSelector, updateShipmentSearchSettings, resetGeneralSettingsErrors } from "../../../slices/general-settings/generalSettingsSlice";
import GeneralSettingsState from "../../../slices/general-settings/GeneralSettingsState";
import { SEARCH_LEVEL_OPTIONS } from "../../../app/data/common/searchLevelOptions";

import Loading from "../../../ui-components/loading/loading";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import XGSRadio from "../../../ui-components/xgs-radio/xgs-radio";
import Help from "../../../ui-components/help/help";

import "./generalSettings.scss";

const GeneralSettings: React.FC = () => {
  const dispatch = useDispatch();
  const generalSettingsState: GeneralSettingsState = useSelector(generalSettingsSelector);   

  useEffect(() => {
    dispatch(getShipmentSearchSettings());

    return () => {
      dispatch(resetGeneralSettingsErrors());
    }
    //eslint-disable-next-line
  }, []);

  const onSearchLevelChanged = (searchLevel: string) => {
    dispatch(updateShipmentSearchSettings(
      searchLevel,
      () => { toast.info("Search level has been saved!", { autoClose: 2000 }); },
      (error) => { toast.error(error || "Error! Please try again later...", { autoClose: 3000 }); }));
  };

  const searchLevel = generalSettingsState.shipmentSearchSettings.filterValue;

  return (
    <div className="general-settings">
      <div className="general-settings__row">        
        <div className="general-settings__item">
          <div className="xgs-form__label">Default Search Level:</div>

          <XGSRadio
            name="searchLevel"
            value={searchLevel}
            onChange={onSearchLevelChanged}
            options={SEARCH_LEVEL_OPTIONS}
          />

          <div className="xgs-form__loading">
            <Loading isLoading={generalSettingsState.requestStarted && (generalSettingsState.requestCreator === "GET_SHIPMENT_SETTINGS" || generalSettingsState.requestCreator === "UPDATE_SHIPMENT_SETTINGS")} />
          </div>
        </div>

        <div className="general-settings__item">
          <Help header="Search Level">
            <div className="help__description">This option allows you to select the default setting for Search Level filter on Track Shipments page.</div>
            <div className="help__item"><strong>Account:</strong> show probills for current account only.</div>
            <div className="help__item"><strong>Company:</strong> show probills for all company accounts.</div>
            <div className="help__item"><strong>Global:</strong> show all probills existing in the system.</div>
          </Help>
        </div>
      </div>     

      {generalSettingsState.requestError && (
        <XGSErrorMessage>
          {generalSettingsState.requestError}
        </XGSErrorMessage>
      )}      
    </div>
  );
};

export default GeneralSettings;
