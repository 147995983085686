import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import ClaimsService from "../../app/data/claims/claimsService";
import CommonService from "../../app/data/common/commonService";
import { ClaimSearchModel, EditClaimFormModel } from "../../app/data/claims/models";
import { initialClaimsState } from "./ClaimsState";

const claimsService = ClaimsService.getInstance();
const commonService = CommonService.getInstance();

export const claimsSlice = createSlice({
  name: "claims",
  initialState: initialClaimsState,
  reducers: {
    resetClaimsState: (state) => initialClaimsState,
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestError = null;
      state.requestCreator = payload;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = true;
      state.requestSucceed = false;
      state.requestError = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestFailed = false;
      state.requestSucceed = true;
      state.requestError = null;
    },
    setClaims: (state, { payload }) => {
      state.claims = payload.content;
      state.claimsRequest = payload.scroll;
      state.claimsFetchedAll = !!(payload.content.length < 50);
    },
    addClaims: (state, { payload }) => {
      state.claims = [...state.claims, ...payload.content];
      state.claimsRequest = payload.scroll;
      state.claimsFetchedAll = !!(payload.content.length < 50);
    },
    updateClaim: (state, { payload }) => {
      const index = state.claims.findIndex(claim => (claim.claimNumber === payload.id));
      if (index < 0) return;
      state.claims[index] = { ...state.claims[index], ...payload.data };      
    },
    setClaimGeneral: (state, { payload }) => {
      state.general = {
        reason: payload.reason,
        comments: payload.comments,
        probillNumber: payload.probillNumber,
        bolNumber: payload.bolNumber,
        originalClaimNumber: payload.originalClaimNumber
      };
    },
    setClaimContact: (state, { payload }) => {
      state.contact = {
        company: payload.company,
        contact: payload.contact,
        taxSSN: payload.taxSSN,
        phone: payload.phone,
        phoneAllowSms: payload.phoneAllowSms,
        email: payload.email,
        address: payload.address
      };
    },
    setClaimRemitTo: (state, { payload }) => {
      state.remitTo = {
        company: payload.company,
        address: payload.address
      };
    },
    setClaimRemitToSameAsContact: (state, { payload }) => {
      state.remitToSameAsContact = payload;
    },
    addItem: (state, { payload }) => {
      state.items.push({
        description: payload.description,
        claimedPiecesPrice: payload.claimedPiecesPrice,
        claimedPiecesWeight: payload.claimedPiecesWeight,
        claimedPiecesQty: payload.claimedPiecesQty,
        freightCharges: payload.freightCharges,
        deleted: false
      });
    },
    updateItem: (state, { payload }) => {
      const i = payload.index;
      state.items[i].claimedPiecesPrice = payload.data.claimedPiecesPrice;
      state.items[i].claimedPiecesWeight = payload.data.claimedPiecesWeight;
      state.items[i].claimedPiecesQty = payload.data.claimedPiecesQty;
      state.items[i].freightCharges = payload.data.freightCharges;
    },
    deleteItem: (state, { payload }) => {
      state.items[payload].deleted = true;
    },
    deleteAllItems: (state) => {
      state.items = [];
    },
    setFreightChargesOnly: (state, { payload }) => {
      state.freightChargesOnly = payload;
    },
    setClaimAllItems: (state, { payload }) => {
      state.claimAllItems = payload;
    },
    setClaimAllItemsValues: (state, { payload }) => {
      state.totalClaimAmount = payload.totalClaimAmount;
      state.areFreightChargesIncluded = payload.areFreightChargesIncluded;
    },
    setFreightCharges: (state, { payload }) => {
      state.freightCharges = payload;
    },
    storeDocumentData: (state, { payload }) => {
      state.documentsData.push(payload);
    },
    removeDocumentData: (state, { payload }) => {
      let documentsData = [...state.documentsData];
      const documentIndex = documentsData.findIndex(obj => obj.id === payload);
      if (documentIndex !== -1) {
        documentsData.splice(documentIndex, 1);
      }
      state.documentsData = [...documentsData];
    },
    resetOptionalDocuments: (state, { payload }) => {
      if (!payload || state.documentsData.length === 0) return;
      let newDocumentsData = [...state.documentsData];
      if (payload !== "CONCEALED" && payload !== "VISIBLE") {
        for (const [documentIndex, documentData] of newDocumentsData.entries()) {
          if (documentData.type === "DAMAGE") {
            newDocumentsData.splice(documentIndex, 1);
          }
        }
        state.documentsData = [...newDocumentsData];
      }
    },
    resetDocumentData: (state) => {
      state.documentsData = [];
    },
    storeProbillSuggestions: (state, { payload }) => {
      let suggestions = [];
      for (const item of payload) {
        suggestions.push({
          label: item.probill,
          value: item
        });
      }
      state.probillSuggestions = suggestions;
    },
    storeBolSuggestions: (state, { payload }) => {
      let suggestions = [];
      for (const item of payload) {
        suggestions.push({
          label: item.bolNumber,
          value: item
        });
      }
      state.bolSuggestions = suggestions;
    },
    setPoNumber: (state, { payload }) => {
      state.poNumber = payload;
    },
    storeClaimDetails: (state, { payload }) => {
      state.claimDetails = payload;
    },
    updateClaimDetails: (state, { payload }) => {
      state.claimDetails = { ...state.claimDetails, ...payload };
    },
  }
});

export const {
  resetClaimsState,
  requestStarted,
  requestFailed,
  requestSucceed,
  setClaims,
  addClaims,
  updateClaim,
  setClaimGeneral,
  setClaimContact,
  setClaimRemitTo,
  setClaimRemitToSameAsContact,
  addItem,
  updateItem,
  deleteItem,
  deleteAllItems,
  setFreightChargesOnly,
  setClaimAllItems,
  setClaimAllItemsValues,
  setFreightCharges,
  storeDocumentData,
  removeDocumentData,
  resetOptionalDocuments,
  resetDocumentData,
  storeProbillSuggestions,
  storeBolSuggestions,
  setPoNumber,
  storeClaimDetails,
  updateClaimDetails,
} = claimsSlice.actions;

export const claimsSelector = (state: IState) => {
  return state.claims;
};

export const uploadClaimDocument = (
  type: string,
  files: any,
  onError: (error: any) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted(type));
  const fd = new FormData();
  for (let file of files) {
    fd.append("files", file);
  }
  const response = await claimsService.uploadClaimDocument(fd);
  if (response.ok()) {
    for (const fileDataObj of response.data.files) {
      dispatch(storeDocumentData({
        type,
        id: fileDataObj.id,
        originalFilename: fileDataObj.originalFilename
      }));
    }
    dispatch(requestSucceed());
  } else {
    onError(response.getError ? response.getError() : "Error");
  }
};

export const submitClaim = (
  data: any,
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("SUBMIT_CLAIM"));
  const response = await claimsService.submitClaim(data);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const getClaimsList = (
  searchParams: ClaimSearchModel | null,
  lastIds?: string
): AppThunk => async (dispatch) => {
  dispatch(requestStarted(lastIds ? "GET_CLAIMS_PORTION" : "GET_CLAIMS"));
  const response = await claimsService.getClaimsList(searchParams, lastIds && lastIds);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(lastIds ? addClaims(response.data) : setClaims(response.data));
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const getProbillDetails = (
  type: string,
  search: string
): AppThunk => async (dispatch) => {
  dispatch(requestStarted(type === "PROBILL" ? "GET_PROBILL_SUGGESTIONS" : "GET_BOL_SUGGESTIONS"));
  const response = await commonService.getProbillDetails(search, type);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(type === "PROBILL" ? storeProbillSuggestions(response.data) : storeBolSuggestions(response.data));
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const getClaimDetails = (
  claimNumber: number
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("CLAIM_DETAILS"));
  const response = await claimsService.getClaimDetails(claimNumber);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(storeClaimDetails(response.data));
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const downloadClaimDocument = (
  claimNumber: number,
  id: string,
  onSuccess: (arg1: Blob, arg2: string) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("DOWNLOAD_DOCUMENT"));
  const response = await claimsService.downloadDocument(claimNumber, id);
  if (response.ok()) {
    dispatch(requestSucceed());
    let blob: Blob = new Blob([response.data], {type: response.data.type});
    onSuccess(blob, response.data.type);
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const editClaim = (
  id: string,
  data: EditClaimFormModel,
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("EDIT_CLAIM"));
  const response = await claimsService.editClaim(id, data);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(updateClaimDetails(data));
    dispatch(updateClaim({ id, data }));
    onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

const claimsReducer = claimsSlice.reducer;
export default claimsReducer;
