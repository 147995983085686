import { ReportModel } from "../../app/data/reports/models";

export default interface ReportsState {
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError?: string | null;
  requestCreator?: string | null;
  reports?: ReportModel[] | null;
}

export const initialReportsState: ReportsState = {
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestError: null,
  requestCreator: null,
  reports: null
};
