import SubAccount from "../../app/data/account/SubAccount";
import UserProfile from "../../app/data/user/UserProfile";
import { Terminal } from "../../app/data/common/models";

export default interface UserState {
  loggedIn: boolean;
  loginProcess: boolean;
  logoutProcess: boolean;
  loginFailed: boolean;
  loginFirstAttemptFinished: boolean;
  profile: UserProfile | null | undefined;
  activeSubAccount: SubAccount | null | undefined;
  isOnCreditHold: boolean;
  failReason: string | null | undefined;
  getCurrentUser_is_Started: boolean;
  getCurrentUser_is_Succeed: boolean;
  getCurrentUser_is_Failed: boolean;
  showMobileSideMenu: boolean;
  portalState?: {
    pendingRequests: number;
  };
  status: string | undefined;
  activeTerminal: Terminal | undefined;
};

export const initialUserState: UserState = {
  loggedIn: false,
  loginProcess: false,
  logoutProcess: false,
  loginFailed: false,
  loginFirstAttemptFinished: false,
  profile: null,
  activeSubAccount: null,
  isOnCreditHold: false,
  failReason: null,
  getCurrentUser_is_Started: false,
  getCurrentUser_is_Succeed: false,
  getCurrentUser_is_Failed: false,
  showMobileSideMenu: false,
  portalState: undefined,
  status: undefined,
  activeTerminal: undefined
};
