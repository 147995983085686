import api, { ApiResponse } from "../api";
import axios from "axios";
import { ManifestsRequest } from "./requestModels";
import { DriverSearchResponseModel } from "./models";
import { urlParams } from "../../../services/common/urlParams";

const REQUEST_ID = {
  GET_MANIFESTS: 'get_manifests',
  GET_MAP_DATA: 'get_map_data',
  GET_DRIVERS_SEARCH: 'get_drivers_search',
};

class ManifestsService {
  private source: any;
  private static instance: ManifestsService;

  private constructor() {
    this.source = {};
  }

  static getInstance(): ManifestsService {
    if (!ManifestsService.instance) {
      ManifestsService.instance = new ManifestsService();
    }

    return ManifestsService.instance;
  }

  public getAllManifests = async (
    request: ManifestsRequest,
  ): Promise<ApiResponse<any>> => {
    this.source[REQUEST_ID.GET_MANIFESTS]?.cancel();
    this.source[REQUEST_ID.GET_MANIFESTS] = axios.CancelToken.source();
    
    const params = Object.entries(request).reduce((result, [key, value]) => (!!value ? {...result, [key]: value} : result), {});    

    return await api.get("/manifest-tracking", { params, cancelToken: this.source[REQUEST_ID.GET_MANIFESTS].token })
  }

  public getManifestMapData = async (
    manifestNumber?: number|string,
  ): Promise<ApiResponse<any>> => {
    this.source[REQUEST_ID.GET_MAP_DATA]?.cancel();
    this.source[REQUEST_ID.GET_MAP_DATA] = axios.CancelToken.source();

    const params = {
      ...manifestNumber && { routeId: manifestNumber }
    };

    return await api.get("/proxy/getMapData",{ params, cancelToken: this.source[REQUEST_ID.GET_MAP_DATA].token })
  }

  public static getExceptionsInfo = async (
    manifestNumber?: number|string,
    probillNumber?: number|string,
  ): Promise<ApiResponse<any>> => {
    const params = {
      ...manifestNumber && { manifestNumber },
      ...probillNumber && { probillNumber },
    };    

    return await api.get("/proxy/tms/driver/exceptions/get", { params })
  }

  public getDriversSearch = async (
    query: string,
  ): Promise<DriverSearchResponseModel | []> => {
    if (query.length < 2) return [];
    
    this.source[REQUEST_ID.GET_DRIVERS_SEARCH]?.cancel();
    this.source[REQUEST_ID.GET_DRIVERS_SEARCH] = axios.CancelToken.source();    

    const response: ApiResponse<any> = await api.get("/proxy/drivers/list/search", {
      params: { query },
      cancelToken: this.source[REQUEST_ID.GET_DRIVERS_SEARCH].token,
    });

    if (response.ok() && response.data.result && response.data.results?.length) {
      return response.data.results;
    } else {
       return [];
    } 
  }

  public exportManifestsCSV = async (
    request: ManifestsRequest,
  ): Promise<ApiResponse<any>> => {
    return await api.get("/manifest-tracking/download/csv", { params: urlParams(request) });
  }
}

export default ManifestsService