import { Contact } from "../../app/data/contact/models";
import SubAccount from "../../app/data/account/SubAccount";
import { AppointmentDto, AppointmentRequest } from "../../app/data/appointments/models";

export interface AppointmentState {
  // currentAppointment: Appointment | null;
  // appointments: Appointment[];
  existingAppointment?: AppointmentDto;
  currentAppointment?: AppointmentRequest; 
  currentContact?: Contact;
  contacts: Contact[];
  consigneeInfo: SubAccount | null;
  loading: boolean;
  error: string | null;
  fetchConsigneeError: string | null;
  fetchAppointmentError: string | null;
  requestCreator: string | null;
  fetchAppointmentStarted: boolean;
  fetchConsigneeStarted: boolean;
  fetchAppointmentSucceed: boolean;
  fetchConsigneeSucceed: boolean;
  fetchAppointmentFailed: boolean;
  fetchConsigneeFailed: boolean;
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
}

export const initialAppointmentState: AppointmentState = {
  consigneeInfo: null,
  contacts: [],
  loading: false,
  error: null,
  fetchConsigneeError: null,
  fetchAppointmentError: null,
  requestCreator: null,
  fetchConsigneeStarted: false,
  fetchAppointmentStarted: false,
  fetchConsigneeSucceed: false,
  fetchAppointmentSucceed: false,
  fetchConsigneeFailed: false,
  fetchAppointmentFailed: false,
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false
};
