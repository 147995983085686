import React from "react";
import { useSelector } from "react-redux";
import "./appointmentStats.scss";
import { trackShipmentSelector } from "../../../../../slices/track-shipment/trackShipmentSlice";
import Loading from "../../../../../ui-components/loading/loading";

export const AppointmentStats: React.FC<{onAppointmentPendingChanged: any}> = (props) => {
  const trackShipmentState = useSelector(trackShipmentSelector);

  return (
    <div className="appointment-stats">
      {trackShipmentState.loadingStats && <Loading isLoading/>}
      {trackShipmentState.loadingStatsFailed && (
        <div>{trackShipmentState.loadingStatsError}</div>
      )}
      {trackShipmentState.loadedStats && trackShipmentState.stats && <>
        <div className="appointment-stats__block">
          <div onClick={() => props.onAppointmentPendingChanged(false)} className="appointment-stats__number appointment-stats__number--blue">
            {trackShipmentState.stats.appointmentRequired}
          </div>
          <div className="appointment-stats__text">
            Probills with appointment required
          </div>
        </div>
        <div className="appointment-stats__block">
          <div onClick={() => props.onAppointmentPendingChanged(true)} className="appointment-stats__number appointment-stats__number--red">
            {trackShipmentState.stats.appointmentPending}
          </div>
          <div className="appointment-stats__text">
            Appointment needs to be scheduled
          </div>
        </div>
      </>}
    </div>
  )
}