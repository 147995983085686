import { createSlice } from "@reduxjs/toolkit";
import { IState } from "../..";
import { StopsToAddToRouteState } from "./routesState";

const initialState: StopsToAddToRouteState = {
  selectedProbills: [],
};

export const stopsToAddToRouteSlice = createSlice({
  name: "stopsToAddToRoute",
  initialState,
  reducers: {
    resetStopsToAddToRoute: (state) => {
      state.selectedProbills = [];
    },
    setStopsToAddToRoute: (state, { payload }) => {
      state.selectedProbills = payload;
    },
  },
});

export const { 
  resetStopsToAddToRoute,
  setStopsToAddToRoute
} = stopsToAddToRouteSlice.actions;

export const stopsToAddToRouteSelector = (state: IState) =>
  state.stopsToAddToRoute;
  
const stopsToAddToRouteReducer = stopsToAddToRouteSlice.reducer;
export default stopsToAddToRouteReducer;
