import api, { ApiResponse } from "./../api";
import {
  InventoryItemModel,
  InventoryListRequestModel,
  ReceiptsItemModel,
  ReceiptsListRequestModel,
  TransactionsItemModel,
  TransactionsListRequestModel
} from "./models";

class WarehouseService {
  private static instance: WarehouseService;
  private constructor() {}

  static getInstance(): WarehouseService {
    if (!WarehouseService.instance) {
      WarehouseService.instance = new WarehouseService();
    }
    return WarehouseService.instance;
  }

  public getInventoryList = async (
    subAccountId: string,
    request: InventoryListRequestModel
  ): Promise<ApiResponse<InventoryItemModel[]>> => {
    return await api.get(`/accounts/${subAccountId}/wh/inventory?${
      request.item
        ? "item=" + encodeURIComponent(request.item)
        : ""
    }${
      request.invoiceNumber
        ? "&invoiceNumber=" + request.invoiceNumber
        : ""
    }${request.lastIds ? "&lastIds=" + request.lastIds : ""}`);
  };

  public getInventoryTransactions = async (
    subAccountId: string,
    item: string,
    lastIds: string
  ): Promise<ApiResponse<InventoryItemModel[]>> => {
    return await api.get(`/accounts/${subAccountId}/wh/inventory/transactions?item=${encodeURIComponent(item)}${lastIds ? "&lastIds=" + lastIds : ""}`);
  };

  public getTransactionsList = async (
    subAccountId: string,
    request: TransactionsListRequestModel
  ): Promise<ApiResponse<TransactionsItemModel[]>> => {
    return await api.get(`/accounts/${subAccountId}/wh/transactions?from=${request.from}&to=${request.to}${request.lastIds ? "&lastIds=" + request.lastIds : ""}`);
  };

  public getReceiptsList = async (
    subAccountId: string,
    request: ReceiptsListRequestModel
  ): Promise<ApiResponse<ReceiptsItemModel[]>> => {
    return await api.get(`/accounts/${subAccountId}/wh/receipts?from=${request.from}&to=${request.to}${request.lastIds ? "&lastIds=" + request.lastIds : ""}`);
  };
}

export default WarehouseService;
