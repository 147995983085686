import { Dispatch } from "react";
import * as H from "history";
import { Routes } from "./RoutesConfig";
import SessionStorage from "../local-storage/SessionStorage";
import UserState from "../../slices/user/UserState";
import { setActiveSubAccount } from "../../slices/user/userSlice";

class AccountRoutingProvider {
  private _sessionStorage: SessionStorage = SessionStorage.getInstance();
  private _history: H.History<any> | undefined;
  private _location: H.Location<any> | undefined;
  private _dispatch: Dispatch<any>;
  private _user: UserState | undefined;
  private urlSubAccountNumber?: number | undefined;

  constructor(
    history: H.History<any>,
    dispatch: Dispatch<any>,
    location: H.Location<any>,
    user: UserState
  ) {
    this._history = history;
    this._dispatch = dispatch;
    this._location = location;
    this._user = user;
  }

  setUrlSubAccountNumber = (urlSubAccountNumber?: number | undefined) => {
    this.urlSubAccountNumber = urlSubAccountNumber;

    return this;
  };

  /**
   * active subAccount selecting priorities
   * 1. url param subAccount number
   * 2. sessionStorage's or application state's subAccount number
   * 3. first subAccount from users subAccounts list
   */
  checkUrlSubAccountNumberExist = (): AccountRoutingProvider | undefined => {
    if (!this.urlSubAccountNumber) {
      let activeSubAccount = this._user?.activeSubAccount;
      if (!activeSubAccount) {
        activeSubAccount =
          this._sessionStorage.getActiveSubAccount() ||
          this._user?.profile?.subAccounts[0];
        this._dispatch(setActiveSubAccount(activeSubAccount));
      }
      this._history?.replace(
        `/${activeSubAccount?.accountNumber}${this._location?.pathname}`
      );
      return undefined;
    }
    return this;
  };

  checkUrlSubAccountNumberAccess = (): AccountRoutingProvider | undefined => {
    const hasAccess =
      this.findUserSubAccountByNumber(this.urlSubAccountNumber) !== undefined;

    if (!hasAccess) {
      console.error(
        "You don't have access to this subAccount",
        this.urlSubAccountNumber
      );

      // if user doesn't have access to the url sub account redirect him to home
      this._history?.replace(Routes.home);

      return undefined;
    }

    return this;
  };

  checkUrlSubAccountNumberTheSameAsActiveSubAccount = () => {
    const activeSubAccount = this._user?.activeSubAccount;

    // TODO change to !== when accountNumber will number istead string
    if (
      !activeSubAccount ||
      activeSubAccount.accountNumber.toString() !== this.urlSubAccountNumber?.toString()
    ) {
      this._dispatch(
        setActiveSubAccount(
          this.findUserSubAccountByNumber(this.urlSubAccountNumber)
        )
      );
    }
  };

  private findUserSubAccountByNumber = (accNumber?: number | undefined) => {
    const foundSubAccount = this._user?.profile?.subAccounts.find(
      (subAccount) =>
        subAccount.accountNumber.toString() === accNumber?.toString()
    );
    return foundSubAccount;
  }
}

export default AccountRoutingProvider;
