import {
  DriversResponseContentModel,
  PickupLogItem,
  PickupsGroupModel,
  PickupsResponseContentModel,
  ResponseScrollModel
} from "../../app/data/pickup/models";

export default interface PickupAssignmentState {
  requestStarted: boolean;
  fetchPortionStarted: boolean;
  requestCreator: string;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestFailStatus: number;
  requestError: string;
  pickups: (PickupsResponseContentModel | PickupsGroupModel)[];
  pickupsRequest: ResponseScrollModel | null;
  pickupsFetchedAll: boolean;
  drivers: DriversResponseContentModel[];
  driversRequest: ResponseScrollModel | null;
  driversFetchedAll: boolean;
  pickupLog: PickupLogItem[];
  pickupsLogRequest: {
    page: number,
    last: boolean
  };
  createdGroupId: string;
};

export const initialPickupAssignmentState: PickupAssignmentState = {
  requestStarted: false,
  fetchPortionStarted: false,
  requestCreator: "",
  requestSucceed: false,
  requestFailed: false,
  requestFailStatus: 0,
  requestError: "",
  pickups: [],
  pickupsRequest: null,
  pickupsFetchedAll: false,
  drivers: [],
  driversRequest: null,
  driversFetchedAll: false,
  pickupLog: [],
  pickupsLogRequest: {
    page: 0,
    last: false
  },
  createdGroupId: "",
};
