import { WarehouseItemFilterRequest, WarehouseInventoryResponse, WarehouseLocationSummaryResponse, WarehouseLowQuantityResponse, WarehouseTopSellingResponse } from './models';
import api, { ApiResponse } from '../api';
import axios from "axios";
import { urlParams } from '../../../services/common/urlParams';
import { ServiceCenterModel } from '../tracking/ShipmentDetailsModel';

class WarehouseInventoryService {
  private source: any;
  private static instance: WarehouseInventoryService;

  private constructor() {}

  public static getInstance(): WarehouseInventoryService {
    if (!WarehouseInventoryService.instance) {
      WarehouseInventoryService.instance = new WarehouseInventoryService();
    }
    return WarehouseInventoryService.instance;
  }

  public async getCustomerInventoryItems(filterRequest: WarehouseItemFilterRequest): Promise<ApiResponse<WarehouseInventoryResponse>> {
    this.source && this.source.cancel();
    this.source = axios.CancelToken.source();
    return await api.post('/warehouse/inventory/customer/item/list', filterRequest, {
      cancelToken: this.source.token
    });
  }

  public exportInventoryCSV = async (
    request: WarehouseItemFilterRequest,
  ): Promise<ApiResponse<any>> => {
    return await api.get('/warehouse/inventory/customer/item/list/download/csv', { params: urlParams(request.filters) });
  }

  public async getLocationSummary(): Promise<ApiResponse<WarehouseLocationSummaryResponse>> {
    return await api.get(`/warehouse/inventory/customer/summary/locations`);
  }

  public async getLowQuantityItems(): Promise<ApiResponse<WarehouseLowQuantityResponse>> {
    return await api.get(`/warehouse/inventory/customer/insights/quantity/low`);
  }

  public async getTopSellingItems(): Promise<ApiResponse<WarehouseTopSellingResponse>> {
    return await api.get(`/warehouse/inventory/customer/insights/selling/top`);
  }

  public getAllTerminals = async (): Promise<ApiResponse<ServiceCenterModel>> => {
    return await api.get(`/accounts/all-terminals`);
  }
}

export default WarehouseInventoryService;
