import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export interface ClaimAddressModel {
  address1: string | undefined,
  city: string | undefined,
  state: string | undefined,
  postalCode: string | undefined
};

export interface ClaimFreightChargesModel {
  description: string,
  price: number | undefined
};

export interface ClaimItemModel {
  description?: string,
  claimedPiecesPrice: number | undefined,
  claimedPiecesWeight: number | undefined,
  claimedPiecesQty: number | undefined,
  freightCharges?: ClaimFreightChargesModel[],
  deleted?: boolean // used to keep index of item in edit mode
};

export interface ClaimDocumentModel {
  type: string,
  id: string
};

export interface ClaimModel {
  details: {
    bolNumber: string | undefined,
    comments: string | undefined,
    originalClaimNumber: string | undefined,
    probillNumber: string | undefined,
    reason: string
  },
  contactInformation: {
    company: string | undefined,
    contact: string,
    taxSSN: string,
    phone: string | undefined,
    phoneAllowSms: boolean,
    email: string,
    address: ClaimAddressModel
  },
  remitToInformation: {
    company: string | undefined,
    address: ClaimAddressModel
  },
  claimedItems?: ClaimItemModel[],
  claimedFreightCharges?: ClaimFreightChargesModel[],
  totalClaimAmount?: number | undefined,
  areFreightChargesIncluded?: boolean,
  documents: ClaimDocumentModel[]
};

export interface ClaimGeneralModel {
  reason: string,
  comments: string | undefined,
  probillNumber: string | undefined
  bolNumber: string | undefined,
  originalClaimNumber: string | undefined
};

export const ClaimGeneralSchema: Yup.ObjectSchema<ClaimGeneralModel> = Yup.object().shape({
  reason: Yup.string().required(validationMessages.required),
  comments: Yup.string().max(255, "The value must be no more than 255 characters"),
  probillNumber: Yup.string()
    .matches(/^\d*$/, validationMessages.notValid)
    .max(48, "The value must be no more than 48 characters")
    .when(["bolNumber"], {
      is: (bolNumber) => !bolNumber,
      then: Yup.string().required("At least one of the Probill # or BOL # is required")
    }
  ),
  bolNumber: Yup.string().max(48, "The value must be no more than 48 characters").when(["probillNumber"], {
    is: (probillNumber) => !probillNumber,
    then: Yup.string().required("At least one of the Probill # or BOL # is required")
  }),
  originalClaimNumber: Yup.string().max(12, "The value must be no more than 12 characters")
}, [["probillNumber", "bolNumber"]]).defined();

export interface ClaimContactModel {
  company: string | undefined,
  contact: string,
  taxSSN: string,
  phone: string | undefined,
  phoneAllowSms?: boolean,
  email: string,
  address: ClaimAddressModel
};

export const ClaimContactSchema: Yup.ObjectSchema<ClaimContactModel> = Yup.object({
  company: Yup.string().matches(/\S+/, validationMessages.notValid).max(100, "The value must be no more than 100 characters").required(validationMessages.required),
  contact: Yup.string().matches(/\S+/, validationMessages.notValid).max(100, "The value must be no more than 100 characters").required(validationMessages.required),
  taxSSN: Yup.string()
    .matches(/^\d*$/, validationMessages.notValid)
    .min(9, "The value must be no less than 9 digits")
    .max(9, "The value must be no more than 9 digits")
    .required(validationMessages.required),
  phone: Yup.string()
    .matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, validationMessages.notValid)
    .max(25, "The value must be no more than 25 characters")
    .required(validationMessages.required),
  phoneAllowSms: Yup.boolean(),
  email: Yup.string().max(60, "The value must be no more than 60 characters").email(validationMessages.notValid).required(validationMessages.required),
  address: Yup.object().shape({
    address1: Yup.string().matches(/\S+/, validationMessages.notValid).max(100, "The value must be no more than 100 characters").required(validationMessages.required),
    city: Yup.string().matches(/\S+/, validationMessages.notValid).max(100, "The value must be no more than 100 characters").required(validationMessages.required),
    state: Yup.string().matches(/\S+/, validationMessages.notValid).max(100, "The value must be no more than 100 characters").required(validationMessages.required),
    postalCode: Yup.string()
      .matches(/^\d*$/, validationMessages.notValid)
      .min(5, "The value must be no less than 5 digits")
      .max(5, "The value must be no more than 5 digits")
      .required(validationMessages.required)
  }).defined()
}).defined();

export interface ClaimRemitToModel {
  company: string | undefined,
  address: ClaimAddressModel
};

export const ClaimRemitToSchema: Yup.ObjectSchema<ClaimRemitToModel> = Yup.object({
  company: Yup.string().matches(/\S+/, validationMessages.notValid).max(100, "The value must be no more than 100 characters").required(validationMessages.required),
  address: Yup.object().shape({
    address1: Yup.string().matches(/\S+/, validationMessages.notValid).max(100, "The value must be no more than 100 characters").required(validationMessages.required),
    city: Yup.string().matches(/\S+/, validationMessages.notValid).max(100, "The value must be no more than 100 characters").required(validationMessages.required),
    state: Yup.string().matches(/\S+/, validationMessages.notValid).max(100, "The value must be no more than 100 characters").required(validationMessages.required),
    postalCode: Yup.string()
      .matches(/^\d*$/, validationMessages.notValid)
      .min(5, "The value must be no less than 5 digits")
      .max(5, "The value must be no more than 5 digits")
      .required(validationMessages.required)
  }).defined()
}).defined();

export const ClaimItemSchema: Yup.ObjectSchema<ClaimItemModel> = Yup.object({
  itemNumber: Yup.string().max(10, "The value must be no more than 10 characters"),
  description: Yup.string().max(100, "The value must be no more than 100 characters").required(validationMessages.required),
  claimedPiecesPrice: Yup.number()
    .min(0, "The value must be no less than 0")
    .max(999999, "The value must be no more than 999999")
    .typeError(validationMessages.notValid)
    .required(validationMessages.required),
  claimedPiecesWeight: Yup.number()
    .min(0, "The value must be no less than 0")
    .max(999999, "The value must be no more than 999999")
    .typeError(validationMessages.notValid)
    .required(validationMessages.required),
  claimedPiecesQty: Yup.number()
    .min(0, "The value must be no less than 0")
    .max(999999, "The value must be no more than 999999")
    .typeError(validationMessages.notValid)
    .required(validationMessages.required),
  freightCharges: Yup.array().of(
    Yup.object().shape({
      description: Yup.string().required(validationMessages.required),
      price: Yup.number()
    }).defined()
  )
}).defined();

export interface ClaimAllItemsModel {
  totalClaimAmount: number | undefined,
  areFreightChargesIncluded?: boolean
};

export const ClaimAllItemsSchema: Yup.ObjectSchema<ClaimAllItemsModel> = Yup.object({
  totalClaimAmount: Yup.number()
    .min(0, "The value must be no less than 0")
    .max(999999, "The value must be no more than 999999")
    .typeError(validationMessages.notValid)
    .required(validationMessages.required),
  areFreightChargesIncluded: Yup.boolean()
}).defined();

export interface ClaimSearchModel {
  claimNumber?: number;
  probill?: number;
  reason?: string;
  status?: string;
  from?: string;
  to?: string;
};

export interface EditClaimFormModel {
  totalClaimAmount: number,
};

export const EditClaimSchema: Yup.ObjectSchema<ClaimAllItemsModel> = Yup.object({
  totalClaimAmount: Yup.number()
    .min(0, "The value must be no less than 0")
    .max(999999, "The value must be no more than 999999")
    .typeError(validationMessages.notValid)
    .required(validationMessages.required),
}).defined();
