import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import CompanySettingsService from "../../app/data/company-settings/companySettingsService";
import { initialCompanySettingsShippersState } from "./CompanySettingsShippersState";

const companySettingsService = CompanySettingsService.getInstance();

export const companySettingsShippersSlice = createSlice({
  name: "companySettingsAccounts",
  initialState: initialCompanySettingsShippersState,
  reducers: {
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestError = null;
      state.requestCreator = payload;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = true;
      state.requestSucceed = false;
      state.requestError = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestFailed = false;
      state.requestSucceed = true;
      state.requestError = null;
    },
    resetErrors: (state) => {
      state.requestFailed = false;
      state.requestError = "";
    },
    setShippers: (state, { payload }) => {
      state.shippers = payload;
    }
  }
});

export const {
  requestStarted,
  requestFailed,
  requestSucceed,
  resetErrors,
  setShippers
} = companySettingsShippersSlice.actions;

export const companySettingsShippersSelector = (state: IState) => state.companySettingsShippers;

export const requestShipper = (
  data: any,
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("SEND_REQUEST"));
  const response = await companySettingsService.sendShipperRequest(data);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess();
  } else {
    dispatch(requestFailed(response.getError()));
  }
};

export const getShippers = (): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_SHIPPERS"));
  const response = await companySettingsService.getShippers();
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setShippers(response.data));
  } else {
    dispatch(requestFailed(response.getError()));
  }
};

const companySettingsShippersReducer = companySettingsShippersSlice.reducer;
export default companySettingsShippersReducer;
