import { createSlice } from "@reduxjs/toolkit";
import { IState } from "../..";
import { PlanningTipsState } from "./planningTipsState";

const initialState: PlanningTipsState = {
  startDate: "",
  startTime: "",
  routeDuration: "",
  trailerNumber: 0,
  trailerCapacity: "",
  trailerType: "",
  defaultStopTime: "",
  routeName: ""
};

export const planningTipsSlice = createSlice({
  name: "planningTips",
  initialState,
  reducers: {
    resetPlanningTipsSlice: (state) => initialState,
    setStartDate: (state, { payload }) => {
      state.startDate = payload;
    },
    setStartTime: (state, { payload }) => {
      state.startTime = payload;
    },
  },
});

export const {
  resetPlanningTipsSlice,
  setStartDate,
  setStartTime
} = planningTipsSlice.actions;

export const planningTipsSelector = (state: IState) => state.planningTips;

const planningTipsReducer = planningTipsSlice.reducer;
export default planningTipsReducer;