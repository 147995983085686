import React from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { paymentSelector } from "../../../slices/payment/paymentSlice";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import Amount from "../../../ui-components/molecules/amount/amount";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import LabeledValue, {
  LabeledValueDirection,
  LabeledValueValueSize,
} from "../../../ui-components/molecules/labeled-value/labeledValue";
import PaymentState from "../../../slices/payment/PaymentState";

const PaymentReceipt: React.FC = () => {
  const payment: PaymentState = useSelector(paymentSelector);

  const getPaymentMethodString = () => {
    return `${
      payment.paymentResult?.accountType
    } - ${payment.paymentResult?.accountNumber?.replace("XXXX", "")}`;
  };

  return (
    <>
      {payment.paymentResult && (
        <div
          className={`payment-receipt ${
            payment.paymentResult?.isSuccess ? "success" : "failed"
          }`}
        >
          {!payment.paymentResult?.isSuccess && (
            <div className="payment-receipt__message">Failed payment!</div>
          )}

          {payment.paymentResult?.isSuccess && (
            <>
              <XGSIcon
                className="payment-receipt__icon"
                icon={XGSIcons.faCheckCircle}
              />
              <div className="payment-receipt__message">Successfully paid!</div>
              <div className="payment-receipt__transaction">
                Transaction ID: {payment.paymentResult?.transId}
              </div>
              <div className="payment-receipt__details">
                <LabeledValue
                  label="DATE PAID"
                  value={
                    <Moment format="MM/DD/YYYY">
                      {payment.paymentResult?.dateTime}
                    </Moment>
                  }
                  direction={LabeledValueDirection.vertical}
                  valueSize={LabeledValueValueSize.medium}
                  extraClassName="payment-receipt__details__item"
                />
                <LabeledValue
                  label="INVOICE NUMBER"
                  value={payment.paymentResult?.refId}
                  direction={LabeledValueDirection.vertical}
                  valueSize={LabeledValueValueSize.medium}
                  extraClassName="payment-receipt__details__item"
                />
                <LabeledValue
                  label="PAYMENT METHOD"
                  value={getPaymentMethodString()}
                  direction={LabeledValueDirection.vertical}
                  valueSize={LabeledValueValueSize.medium}
                  extraClassName="payment-receipt__details__item"
                />
              </div>
              <div className="payment-receipt__amount">
                <LabeledValue
                  label="AMOUNT PAID"
                  value={<Amount amount={payment.paymentResult?.totalAmount} />}
                  direction={LabeledValueDirection.vertical}
                  centered
                  valueSize={LabeledValueValueSize.large}
                />
              </div>
              <div className="payment-receipt__bottom">
                If you have any questions, contact us at <b>info@xgsi.com</b> or
                call <b>(844) 947-7447</b>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default PaymentReceipt;
