import styled from "styled-components";

const LoginButton = styled.button`
  margin-top: 48px;
  margin-bottom: 32px;
  width: 100%;
  height: 40px;
  font-size: 16px;
`;
/*  ${(props) =>
    props.primary &&
    css`
      background: palevioletred;
      color: white;
    `};*/

export default LoginButton;
