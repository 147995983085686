import { ServiceCenterModel } from '../../app/data/tracking/ShipmentDetailsModel';
import { InventoryItem, WarehouseLocationSummary, LowQuantityItem, TopSellingItem, WarehouseItemFilterRequest } from '../../app/data/warehouse-inventory/models';

export interface WarehouseInventoryState {
  loadingItems: boolean;
  loadingItemsSucceed: boolean;
  loadingItemsFailed: boolean;
  loadingItemsError: string | null;
  items: InventoryItem[];
  nextItemId: string | null;
  infiniteLoadingItems: boolean;
  infiniteLoadingItemsSucceed: boolean;
  inifiniteLoadingItemsHasNext: boolean;
  itemsFilter: WarehouseItemFilterRequest | null;
  terminals: ServiceCenterModel[];

  loadingLocationSummary: boolean;
  loadingLocationSummarySucceed: boolean;
  loadingLocationSummaryFailed: boolean;
  loadingLocationSummaryError: string | null;
  locationSummary: WarehouseLocationSummary[];

  loadingLowQuantityItems: boolean;
  loadingLowQuantityItemsSucceed: boolean;
  loadingLowQuantityItemsFailed: boolean;
  loadingLowQuantityItemsError: string | null;
  lowQuantityItems: LowQuantityItem[];

  loadingTopSellingItems: boolean;
  loadingTopSellingItemsSucceed: boolean;
  loadingTopSellingItemsFailed: boolean;
  loadingTopSellingItemsError: string | null;
  topSellingItems: TopSellingItem[];

  loadingTerminals: boolean;
  loadedTerminals: boolean;
  loadingTerminalsFailed: boolean;
}

export const initialWarehouseInventoryState: WarehouseInventoryState = {
  loadingItems: false,
  loadingItemsSucceed: false,
  loadingItemsFailed: false,
  loadingItemsError: null,
  items: [],
  nextItemId: null,
  infiniteLoadingItems: false,
  infiniteLoadingItemsSucceed: false,
  inifiniteLoadingItemsHasNext: false,
  itemsFilter: null,

  loadingLocationSummary: false,
  loadingLocationSummarySucceed: false,
  loadingLocationSummaryFailed: false,
  loadingLocationSummaryError: null,
  locationSummary: [],

  loadingLowQuantityItems: false,
  loadingLowQuantityItemsSucceed: false,
  loadingLowQuantityItemsFailed: false,
  loadingLowQuantityItemsError: null,
  lowQuantityItems: [],

  loadingTopSellingItems: false,
  loadingTopSellingItemsSucceed: false,
  loadingTopSellingItemsFailed: false,
  loadingTopSellingItemsError: null,
  topSellingItems: [],

  loadedTerminals: false,
  loadingTerminals: false,
  loadingTerminalsFailed: false,
  terminals: []
};
