import { LinehaulActivity, LinehaulLane, LinehaulTrip } from "../../app/data/linehaul-mileage/models";

export interface LinehaulMileageState {
    loadingTrips: boolean;
    loadingTripsFailed: boolean;
    loadingTripsSucceed: boolean;
    inifiniteLoadingTrips: boolean;
    infiniteLoadingTripsSucceed: boolean;
    inifinteLoadingTripsHasNext: boolean;
    loadingTripsError: string | null;
    loadingLanes: boolean;
    loadingLanesFailed: boolean;
    loadingLanesSucceed: boolean;
    loadingLanesError: string | null;
    inifiniteLoadingLanes: boolean;
    infiniteLoadingLanesSucceed: boolean;
    inifinteLoadingLanesHasNext: boolean;
    lanesPageNumber: number;
    loadingActivity: boolean;
    loadingActivityFailed: boolean;
    loadingActivitySucceed: boolean;
    loadingActivityError: string | null;
    requestCreator?: string;
    requestStarted?: boolean;
    requestSucceed?: boolean;
    requestFailed?: boolean;
    trips: LinehaulTrip[];
    lanes: LinehaulLane[];
    activity: LinehaulActivity[];
    tripsPageNumber: number;
}

export const initialLinehaulMileageState: LinehaulMileageState = {
    loadingTrips: false,
    loadingTripsFailed: false,
    loadingTripsSucceed: false,
    inifiniteLoadingTrips: false,
    infiniteLoadingTripsSucceed: false,
    inifinteLoadingTripsHasNext: true,
    loadingTripsError: null,
    loadingLanes: false,
    loadingLanesFailed: false,
    loadingLanesSucceed: false,
    infiniteLoadingLanesSucceed: false,
    inifiniteLoadingLanes: false,
    inifinteLoadingLanesHasNext: true,
    lanesPageNumber: 1,
    loadingLanesError: null,
    loadingActivity: false,
    loadingActivityFailed: false,
    loadingActivitySucceed: false,
    loadingActivityError: null,
    trips: [],
    lanes: [],
    activity: [],
    tripsPageNumber: 1
};
