import { InvoiceResponseModel } from "../../app/data/documents/models";

export default interface InvoicesState {
  request: {
    [key: string]: {
      requestStarted: boolean;
      requestSucceed: boolean;
      requestFailed: boolean;
      requestError?: string | null;
    }
  }
  invoices: InvoiceResponseModel[];
  invoicesRequest: {
    lastIds: string | null;
    loadedAll: boolean;
  } | null;
  lastIds: string | null,
  loadedAll: boolean;
  summary: {
    total: number;
    undelivered: number;
    paidAmount: number;
    partiallyPaidAmount: number;
    missedAmount: number;
    missedPayments: number
  }
};

export const initialInvoicesState: InvoicesState = {
  request: {},
  invoices: [],
  invoicesRequest: null,
  lastIds: null,
  loadedAll: false,
  summary: {
    total: 0,
    undelivered: 0,
    paidAmount: 0,
    partiallyPaidAmount: 0,
    missedAmount: 0,
    missedPayments: 0
  }
};