import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./routeStops.scss";
import { RouteStopsState } from "../../../../slices/dispatch-planning/routes/routesState";
import { routeStopsEdited, routeStopsSelector, routeStopsSetDisplayStops } from "../../../../slices/dispatch-planning/routes/routeStopsSlice";
import Loading from "../../../../ui-components/loading/loading";
import RouteStop from "./route-stop/routeStop";
import { RouteStop as RouteStopType } from "../../../../app/data/plan-probills/routes/models";
import AddStops from "./add-stops/addStops";
import XGSIcon from "../../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../../ui-components/icon/xgsIcons";

const RouteStops: React.FC<{}> = memo((props) => {
    const dispatch = useDispatch();
    const routesStopsState: RouteStopsState = useSelector(
        routeStopsSelector
    );
    const [dropZone, setDropZone] = useState(0);
    const [dragged, setDragged] = useState<number | null>(null); // storing the dragged item as an index
    const [mouse, setMouse] = useState<[number, number]>([0, 0]);

    const [isShowPlannableProbills, setIsShowPlannableProbills] = useState<boolean>(false);
    const [isAllStopsCollapsed, setIsAllStopsCollapsed] = useState<boolean>(false);

    const [items, setItems] = useState<RouteStopType[]>([]);
    useEffect(() => {
        dispatch(routeStopsSetDisplayStops(items));
    }, [dispatch, items]);
    useEffect(() => {
        setItems(routesStopsState.routeStops)
    }, [routesStopsState.routeStops]);
    useEffect(() => {
        const handler = (e: MouseEvent) => {
            setMouse([e.x, e.y]);
        };
        document.addEventListener("mousemove", handler);
        return () => document.removeEventListener("mousemove", handler);
    }, []);


    // get closest drop zone
    useEffect(() => {
        if (dragged !== null) {
            // get all drop-zones
            const elements = Array.from(document.getElementsByClassName("drop-zone"));
            // get all drop-zones' y-axis position
            // if we were using a horizontally-scrolling list, we would get the .left property
            const positions = elements.map((e) => e.getBoundingClientRect().top);
            // get the difference with the mouse's y position
            const absDifferences = positions.map((v) => Math.abs(v - mouse[1]));

            // get the item closest to the mouse
            let result = absDifferences.indexOf(Math.min(...absDifferences));

            // if the item is below the dragged item, add 1 to the index
            if (result > dragged) result += 1;

            setDropZone(result);
        }
    }, [dragged, mouse]);

    const _reorderListForward = <T,>(l: T[], start: number, end: number) => {
        const temp = l[start];

        for (let i = start; i < end; i++) {
            l[i] = l[i + 1];
        }
        l[end - 1] = temp;

        return l;
    };

    useEffect(() => {
        const handler = (e: MouseEvent) => {
            if (dragged !== null) {
                e.preventDefault();
                setDragged(null);

                setItems((items) => reorderList([...items], dragged, dropZone));
            }
        };

        document.addEventListener("mouseup", handler);
        return () => document.removeEventListener("mouseup", handler);
    });
    const _reorderListBackward = <T,>(l: T[], start: number, end: number) => {
        const temp = l[start];

        for (let i = start; i > end; i--) {
            l[i] = l[i - 1];
        }

        l[end] = temp;

        return l;
    };
    const reorderList = <T,>(l: T[], start: number, end: number) => {
        dispatch(routeStopsEdited(true))
        if (start < end) return _reorderListForward([...l], start, end);
        else if (start > end) return _reorderListBackward([...l], start, end);

        return l; // if start == end
    };

    useEffect(() => {
        const handler = (e: MouseEvent) => {
            if (dragged !== null) {
                e.preventDefault();
                setDragged(null);
            }
        };
        document.addEventListener("mouseup", handler);
        return () => document.removeEventListener("mouseup", handler);
    });

    return (
        <>
            {routesStopsState.loading && <><Loading isLoading={true} />Loading Stops...</>}
            {routesStopsState.loadingFailed && <div>Error in getting data</div>}
            {(routesStopsState.loaded && routesStopsState.routeStops && routesStopsState.routeStops.length) &&
                <div className="routes-collapse-all-button"
                    onClick={() => { setIsAllStopsCollapsed(!isAllStopsCollapsed) }}
                >{(isAllStopsCollapsed) ? <div><XGSIcon icon={XGSIcons.faPlusSquare} />{" Expand all"}</div>
                    : <div><XGSIcon icon={XGSIcons.faMinusSquare} />{" Collapse all"}</div>}
                </div>}
            {(routesStopsState.loaded && routesStopsState.routeStops && routesStopsState.routeStops.length) ?
                <div className="routes-container-sub">
                    {dragged !== null && (
                        <div className="floating list-item"
                            style={{
                                left: `${mouse[0]}px`,
                                top: `${mouse[1]}px`,
                            }}
                        ><RouteStop
                                stopObj={items[dragged]}
                                index={dragged}
                                setDragged={setDragged} 
                                isAllStopsCollapsed = {isAllStopsCollapsed}
                                /> </div>
                    )}
                    <div >
                        {/* {className='list'} */}
                        <div
                            className={`list-item drop-zone ${dragged === null || dropZone !== 0 ? "hidden" : ""
                                }`}
                        />
                        {items.map((stop, index) => (
                            <>
                                {dragged !== index && (
                                    <>
                                        <RouteStop
                                            key={`rs_${index}`}
                                            stopObj={stop}
                                            index={index}
                                            setDragged={setDragged}
                                            isAllStopsCollapsed = {isAllStopsCollapsed}
                                        />
                                        <div
                                            key={`rsd_${index}`}
                                            className={`list-item drop-zone ${dragged === null || dropZone !== index + 1 ? "hidden" : ""}`}
                                        />
                                    </>
                                )}
                            </>
                        ))}
                    </div>
                    <div
                        className="route-add-stop"
                        onClick={() => { setIsShowPlannableProbills(!isShowPlannableProbills) }}
                        title="Add stop to the Route"
                    >
                        <div
                            className="route-add-stop-title"
                        >
                            {"Add Stop"}
                            <span className="route-add-stop-icon">
                                <XGSIcon
                                    icon={XGSIcons.faPlusCircle} />
                            </span>
                        </div>
                    </div>
                </div>
                : ""
            }
            {(isShowPlannableProbills) &&
                <AddStops
                    show={isShowPlannableProbills}
                    routeId={(items && items[0]) ? items[0].routeId : "0"}
                    onClose={setIsShowPlannableProbills}
                    terminalNumber={(items && items[0]) ? `${items[0].terminalNumber}`: "0"}
                />
            }
        </>
    );
});

export default RouteStops;