import * as Yup from "yup";
import { AddressModel } from "../common/address";
import { validationMessages } from "../common/validationMessages";

export interface CreateShipperModel {
  name: string;
  address: AddressModel,
};

export const CreateShipperSchema: Yup.ObjectSchema<CreateShipperModel> = Yup.object({
  name: Yup.string().required(validationMessages.required).matches(/(\w+|-+)/, validationMessages.notValid).max(36, "The value must be no more than 36 characters"),
  address: Yup.object().shape({
    address1: Yup.string().required(validationMessages.required).matches(/(\w+|-+)/, validationMessages.notValid).max(256, "The value must be no more than 255 characters"),
    city: Yup.string().required(validationMessages.required).matches(/(\w+|-+)/, validationMessages.notValid).max(64, "The value must be no more than 64 characters"),
    postalCode: Yup.string().matches(/^\d*$/, validationMessages.notValid).min(5, "The value must be no less than 5 digits").max(5, "The value must be no more than 5 digits").required(validationMessages.required),
    state: Yup.string().required(validationMessages.required),
    additionalAddressLine: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(150, "The value must be no more than 150 characters"),
  }).defined(),
}).defined();