import React, { memo } from "react";
import Exclamation, {
  ExclamationTypes,
} from "../../ui-components/molecules/exclamation/exclamation";
import "./successBlock.scss";

export enum LoginLinkTypes {
  AGENT,
  CUSTOMER,
}

export interface SuccessBlockProps {
  showLoginLink?: boolean;
  loginLinkType?: LoginLinkTypes;
  className?: string;
}

const getLoginLink = (loginLinkType?: LoginLinkTypes) => {
  if (!!loginLinkType) return "/";
  if (loginLinkType === LoginLinkTypes.AGENT) return process.env.REACT_APP_AGENT_DOMAIN;
  return "/";
};
const SuccessBlock: React.FC<SuccessBlockProps> = memo((props) => {
  return (
    <div className={`xgs-success-block ${props.className || ""}`}>
      <div className="xgs-success-block__content">
        <Exclamation type={ExclamationTypes.success}>{props.children}</Exclamation>
      </div>
      {props.showLoginLink && (
        <div className="xgs-success-block__login-link">
          <a href={getLoginLink(props.loginLinkType)} className="blue-link">
            {props.loginLinkType === LoginLinkTypes.CUSTOMER && "Go to Customer Portal login"}
            {props.loginLinkType === LoginLinkTypes.AGENT && "Go to Agent Portal login"}
          </a>
        </div>
      )}
    </div>
  );
});

export default SuccessBlock;
