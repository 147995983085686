import React from "react";

import Amount from "../../../molecules/amount/amount";

export interface AmountCellProps {
  amount: number | undefined;
}

const AmountCell: React.FC<AmountCellProps> = (props) => {
  return (
    <div className="xgs-table-amount-cell">
      <Amount amount={props.amount} />
    </div>
  );
};

export default AmountCell;