import React, { memo } from "react";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import "./filtersInstructions.scss";

interface FiltersInstructionsProps {
  isEmployee: boolean;
  onClose: () => void;
};

const FiltersInstructions: React.FC<FiltersInstructionsProps> = memo((props) => {
  return (
    <div className="xgs-tracking-filter__instructions">
      <div className="xgs-tracking-filter__instructions__close blue-link" onClick={props.onClose}>Close</div>
      <div className="xgs-tracking-filter__instructions__header">
        Help
      </div>
      {!props.isEmployee && (
        <div className="xgs-tracking-filter__instructions__item">
          <div className="xgs-tracking-filter__instructions__item__label">
            Account Name (<span>Account Number</span>) role
          </div>
          <div className="xgs-tracking-filter__instructions__item__description">
            This filter allows you to choose one or more options to view shipments based off your role as Payor, Shipper, or Consignee. Value entered would be the company or contact name.
          </div>
          <div className="xgs-tracking-filter__instructions__item__values">
            <div className="xgs-tracking-filter__instructions__item__values__item"><strong>Payor:</strong> The party responsible for paying the freight charges issued by the carrier.</div>
            <div className="xgs-tracking-filter__instructions__item__values__item"><strong>Shipper:</strong> The party responsible for providing the shipment to the carrier for transport.</div>
            <div className="xgs-tracking-filter__instructions__item__values__item"><strong>Consignee:</strong> The party entitled to receive the shipment from the carrier.</div>
          </div>
        </div>
      )}
      <div className="xgs-tracking-filter__instructions__item">
        <div className="xgs-tracking-filter__instructions__item__label">
          Tracking Number
        </div>
        <div className="xgs-tracking-filter__instructions__item__description">
          Use this filter to track one or more shipments using various types of identifying numbers associated with the shipment including the Probill number, PO number or BOL number. You can search for multiple shipments by entering the values separated by commas or spaces.
        </div>
        <div className="xgs-tracking-filter__instructions__item__values">
          <div className="xgs-tracking-filter__instructions__item__values__item"><strong>Probill number:</strong> Assigned by the carrier as the tracking/invoice number.</div>
          <div className="xgs-tracking-filter__instructions__item__values__item"><strong>PO Number:</strong> Purchase Order Number provided by the consignee to the shipper.</div>
          <div className="xgs-tracking-filter__instructions__item__values__item"><strong>BOL Number:</strong> Bill of Lading Number issued by the shipper to the carrier.</div>
        </div>
      </div>
      <div className="xgs-tracking-filter__instructions__item">
        <div className="xgs-tracking-filter__instructions__item__label">
          Dates Range (From and To)
        </div>
        <div className="xgs-tracking-filter__instructions__item__description">
          Use this filter to sort shipments based off a specified range for the bill date. The date the carrier issued the Probill number is expected.
        </div>
      </div>
      <div className="xgs-tracking-filter__instructions__item">
        <div className="xgs-tracking-filter__instructions__item__label">
          Origin/Destination
        </div>
        <div className="xgs-tracking-filter__instructions__item__description">
          Use this filter to see shipments that moved through the specified XGS Service Center.
        </div>
        <div className="xgs-tracking-filter__instructions__item__values">
          <div className="xgs-tracking-filter__instructions__item__values__item"><strong>Origin:</strong> The service center responsible for picking up the shipment.</div>
          <div className="xgs-tracking-filter__instructions__item__values__item"><strong>Destination:</strong> The service center responsible for delivering the shipment.</div>
        </div>
      </div>
      <div className="xgs-tracking-filter__instructions__item">
        <div className="xgs-tracking-filter__instructions__item__label">
          Status
        </div>
        <div className="xgs-tracking-filter__instructions__item__description">
          Use this filter to view shipments based off one or more specified probill statuses.
        </div>
        <div className="xgs-tracking-filter__instructions__item__values">
          <div className="xgs-tracking-filter__instructions__item__values__item"><strong>Arrived at destination facility:</strong> Shipment is at an XGS location and is waiting for final delivery. If this is a will-call shipment, the facility will call to schedule a pick-up time.</div>
          <div className="xgs-tracking-filter__instructions__item__values__item"><strong>At origin facility:</strong> Shipment is at our terminal awaiting transport to its final destination.</div>
          <div className="xgs-tracking-filter__instructions__item__values__item"><strong>Canceled:</strong> Shipment has been canceled.</div>
          <div className="xgs-tracking-filter__instructions__item__values__item"><strong>Contact Customer Service:</strong> Contact Customer Service to find out the details/status of your shipment.</div>
          <div className="xgs-tracking-filter__instructions__item__values__item"><strong>Delivered:</strong> Shipment has been delivered to its final destination.</div>
          <div className="xgs-tracking-filter__instructions__item__values__item"><strong>In Transit to Destination:</strong> Shipment has been pick-up from the origin facility and is transit to the destination facility.</div>
          <div className="xgs-tracking-filter__instructions__item__values__item"><strong>Out for Delivery:</strong> Shipment has left the local facility and is routed for delivery at its final destination.</div>
          <div className="xgs-tracking-filter__instructions__item__values__item"><strong>Picked Up:</strong> Shipment is on our trucks and is in transit to the destination terminal.</div>
          <div className="xgs-tracking-filter__instructions__item__values__item"><strong>Provided to Agent for Delivery:</strong> Shipment is currently with our partner carrier to the destination area for final delivery.</div>
        </div>
      </div>
      <div className="xgs-tracking-filter__instructions__item">
        <div className="xgs-tracking-filter__instructions__item__label">
          Linehaul Manifest
        </div>
        <div className="xgs-tracking-filter__instructions__item__description">
          Use this filter to view a list of shipments traveling from one service center to another. The identification number issued to a trailer moving between service centers is expected.
        </div>
      </div>
      <div className="xgs-tracking-filter__instructions__item">
        <div className="xgs-tracking-filter__instructions__item__label">
          Delivery Manifest
        </div>
        <div className="xgs-tracking-filter__instructions__item__description">
          Use this filter to see a list of shipments out on delivery to a destination. The identification number issued to a trailer moving from the destination service center to the final destinations is expected.
        </div>
      </div>
      {props.isEmployee && (
        <>
          <div className="xgs-tracking-filter__instructions__item">
            <div className="xgs-tracking-filter__instructions__item__label">
              Payor's Account Number
            </div>
            <div className="xgs-tracking-filter__instructions__item__description">
              Use this filter to view a list of shipments belonging to a specified payor. XGS account number associated with the billing address and may also be called the paypoint account number is expected. Used by XGS employees only.
            </div>
          </div>
          <div className="xgs-tracking-filter__instructions__item">
            <div className="xgs-tracking-filter__instructions__item__label">
              Consignee
            </div>
            <div className="xgs-tracking-filter__instructions__item__description">
              Use this option to filter based on the Consignee name or delivery address. The party entitled to receive the shipment from the carrier is expected. Used by XGS employees only.
            </div>
          </div>
          <div className="xgs-tracking-filter__instructions__item">
            <div className="xgs-tracking-filter__instructions__item__label">
              Customer
            </div>
            <div className="xgs-tracking-filter__instructions__item__description">
              Use this filter to view a group of accounts or users associated with a customer. Company name for the MyXGS customer is expected. Used by XGS employees only.
            </div>
          </div>
        </>
      )}
      <div className="xgs-tracking-filter__instructions__button">
        <Button
          theme={ButtonThemes.blue}
          onClick={props.onClose}
        >
          Close
        </Button>
      </div>
    </div>
  );
});

export default FiltersInstructions;
