import React from "react";
import { useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ContentContainer from "../../../templates/content-container/contentContainer";
import { featureBannerSelector } from "../../../slices/feature-banner/featureBannerSlice";
import FeatureBannerForm from "./featureBannerForm";

const TopBanner: React.FC<{}> = () => {
  const featureBannerState = useSelector(featureBannerSelector);

  return (
    <ContentContainer
      title="Feature Banner"
      isLoading={featureBannerState.requestStarted && featureBannerState.requestCreator === "GET"}
    >
      <Tabs>
        <TabList>
          <Tab>Customers</Tab>
          <Tab>Employees</Tab>
        </TabList>
        <TabPanel>
          <FeatureBannerForm type="CUSTOMER" />
        </TabPanel>
        <TabPanel>
          <FeatureBannerForm type="EMPLOYEE" />
        </TabPanel>
      </Tabs>
    </ContentContainer>
  );
};

export default TopBanner;
