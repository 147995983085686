import React, { useCallback, useState } from "react";
import { CustomerInventoryListFilter } from "./filter/filter";
import { useDispatch, useSelector } from "react-redux";
import { fetchWarehouseItems, infiniteLoadWarehouseItems, warehouseInventorySelector } from "../../slices/warehouse-inventory/warehouseInventorySlice";
import Table from "../../ui-components/table/table";
import { customerInventoryColumns } from "./customerInventoryColumns";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import "./customerInventory.scss";
import ContentContainer from "../../templates/content-container/contentContainer";
import DownloadButton from "../../ui-components/download-button/downloadButton";
import { useDownload } from "../../hooks/useDownload";
import WarehouseInventoryService from "../../app/data/warehouse-inventory/warehouseInventoryService";

const initialFilterState = {
  loc: "",
  sku: "",
  roll: "",
  pallet: "",
  desc: ""
};

export const CustomerInventory = () => {
  const [filters, setFilters] = useState(initialFilterState);
  const warehouseInventoryState = useSelector(warehouseInventorySelector);
  const dispatch = useDispatch();

  const {download, resetDownload, loadingState} = useDownload(
    "INVENTORY",
    () => WarehouseInventoryService.getInstance().exportInventoryCSV(
      warehouseInventoryState.itemsFilter!,
    )
  )

  const onSearch = useCallback((filters) => {
    setFilters(filters);
    resetDownload()
    dispatch(fetchWarehouseItems({
      pageSize: 25,
      filters: {
        locationTerminalNumber: filters.loc,
        sku: filters.sku,
        rollNumber: filters.roll,
        palletLpNumber: filters.pallet,
        description: filters.desc
      }
    }));
    // eslint-disable-next-line
  }, [dispatch]);


  const onDownload = () => {
    download();
  }

  const onInfiniteScroll = () => {
    dispatch(infiniteLoadWarehouseItems({
      pageSize: 25,
      filters: {
        locationTerminalNumber: filters.loc,
        sku: filters.sku,
        rollNumber: filters.roll,
        palletLpNumber: filters.pallet,
        description: filters.desc
      }
    }, warehouseInventoryState.nextItemId!))
  }

  const onClear = useCallback(() => {
    setFilters(initialFilterState);
  }, []);
  
  return (
    <ContentContainer title="Inventory">
      <div className="xgs-customer-inventory">
      <div className="xgs-tracking-filter__download-csv">
        <DownloadButton
          title="Download CSV"
          spinner={loadingState?.downloadStarted}
          onClick={() => {
            onDownload()
          }}
        />
      </div>  
        <CustomerInventoryListFilter onClear={onClear} onSearch={onSearch}/>
        {!warehouseInventoryState.loadingItemsFailed && (
          <Table
            responsive
            data={warehouseInventoryState.items}
            isLoading={warehouseInventoryState.loadingItems}
            columns={customerInventoryColumns}
            infiniteScroll
            rowHeight={56}
            onInfiniteScroll={onInfiniteScroll}
            maxTableWidth={1324}
            infiniteScrollHasNext={warehouseInventoryState.inifiniteLoadingItemsHasNext}
            infiniteScrollLoading={warehouseInventoryState.infiniteLoadingItems}
          />
        )}
        {warehouseInventoryState.loadingItemsFailed && <XGSErrorMessage>{warehouseInventoryState.loadingItemsError}</XGSErrorMessage>}
      </div>
    </ContentContainer>
  )
}