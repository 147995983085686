import moment from 'moment';
import dateFormats from '../../app/data/common/dateFormats';
import { LinehaulTrip } from '../../app/data/linehaul-mileage/models';

const exportTripsToCSV = (data: LinehaulTrip[]) => {
  const csvHeader = [
    "Manifest # / Dispatch Date",
    "Driver",
    "Lane Name",
    "Lane Miles",
    "Origin",
    "Destination",
    "Relay Location",
    "Status",
    "Exceptions"
  ];

  const csvRows = data.map(row => {
    const manifestDispatch = `${row.manifestNumber} ${moment(row.dispatchedDate, "YYYYMMDD").format(dateFormats.uiDate)}`;
    const driver = row.driver ? `${row.driver.firstName} ${row.driver.lastName} ${row.driver.driverId}` : '';
    const laneName = row.lane?.laneName || '';
    const laneMiles = row.lane?.miles || '';
    const origin = `${row.originTerminalAirportcode} - ${row.originTerminalName} ${row.originTerminalNumber}`;
    const destination = `${row.destinationTerminalAirportcode} - ${row.destinationTerminalName} ${row.destinationTerminalNumber}`;
    const relayLocation = row.lane ? `${row.lane.relayCity} ${row.lane.relayState} ${row.lane.relayZipcode}` : '';
    const status = row.isReviewed ? 'REVIEWED' : 'NOT REVIEWED';
    const exceptions = row.exceptions.map(exc => exc.type.toLowerCase().charAt(0).toUpperCase() + exc.type.toLowerCase().slice(1)).join(" ") || "";

    return [
      manifestDispatch,
      driver,
      laneName,
      laneMiles.toString(),
      origin,
      destination,
      relayLocation,
      status,
      exceptions
    ].map(word => word.replace(",", " "));
  });

  const csvContent = [csvHeader.join(','), ...csvRows].join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', `trips-${moment().format(dateFormats.uiDate)}.csv`);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default exportTripsToCSV;
