
interface TokenModel {
  id: string;
  dateCreated: string;
  dateUpdated: string;
  userCreated: string;
  userUpdated: string;
  token: string;
  expirationDate: string;
}

export default interface JWTGeneratorState {
  token: TokenModel | null;  
  loading: boolean;
  loading_was_succeed: boolean;
  loading_was_failed: boolean;
  loading_error?: string | null;
};

export const initialJWTGeneratorState: JWTGeneratorState = {
  token: null,
  loading: false,
  loading_was_succeed: false,
  loading_was_failed: false,
  loading_error: null
};
