import React from "react";
import { useField, FieldInputProps } from "formik";
import LabeledPhoneInput from "../../molecules/labeled-inputs/labeled-phone-input/labeledPhoneInput";
import { CommonLabeledInputProps } from "../../molecules/labeled-inputs/labeledInput";

export interface XGSFormInputProps
  extends CommonLabeledInputProps,
    React.HTMLProps<HTMLInputElement> {
  inputmode?: string;
  onValueChange: (value: string) => void;
}

const XGSFormPhoneInput: React.FC<XGSFormInputProps> = ({
  label,
  required,
  labelMode,
  requiredAsteriskDisabled,
  ...props
}) => {
  const [field, meta] = useField(props as FieldInputProps<string>);

  return (
    <LabeledPhoneInput
      label={label}
      labelMode={labelMode}
      required={required}
      requiredAsteriskDisabled={requiredAsteriskDisabled}
      fields={field}
      meta={meta}
      {...props}
    />
  );
};

export default XGSFormPhoneInput;
