import { BaseState } from "..";
import { PaymentResult } from "../../app/data/payment/PaymentResult";
import { TokenInfo } from "../../app/data/payment/TokenInfo";

export default interface PaymentState extends BaseState {
  showPopup: boolean;
  hiddenFormSubmitted: boolean;
  paymentResult: PaymentResult | null | undefined;
  tokenInfo: TokenInfo | null | undefined;
  error: string | null | undefined;
  paymentSum: number | undefined;
  paymentInitiator: string | undefined;
}

export const initialPaymentState: PaymentState = {
  loading: false,
  loadingFailed: false,
  loaded: false,
  showPopup: false,
  hiddenFormSubmitted: false,
  paymentResult: null,
  tokenInfo: null,
  error: null,
  paymentSum: 0,
  paymentInitiator: undefined
};
