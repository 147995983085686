import React from "react";
import { useField, Field, ErrorMessage } from "formik";
import "./xgsFormTextarea.scss";

export interface XGSFormTextareaProps
  extends React.HTMLProps<HTMLTextAreaElement> {
  counter?: number;
  requiredAsteriskDisabled?: boolean;
}

const TextError: React.FC<{}> = (props) => {
  return <div className="xgs-labeled-input__validation-error">{props.children}</div>
};

const XGSFormTextarea: React.FC<XGSFormTextareaProps> = ({
  name,
  label,
  className,
  counter,
  ...props
}) => {
  const meta = useField(name as string)[1];  
  const {requiredAsteriskDisabled, ...restProps} = props;

  return (
    <div className="xgs-textarea">
      <div className="xgs-textarea__label">
          {label}{" "}{props.required && !requiredAsteriskDisabled && <span>*</span>}
      </div>
      {counter && meta.value && (
        <div className={`xgs-textarea__counter ${(meta.value.length > counter) ? "xgs-textarea__counter--error" : ""}`}>
          { meta.value.length } / { counter }
        </div>
      )}
      {name && (
        <>
          <Field
            as="textarea"
            name={name}
            className={`${className || ""} ${(meta.touched && meta.error) ? "xgs-textarea__invalid" : ""}`}
            {...restProps}
          />
          <ErrorMessage
            component={TextError}
            name={name}
          />
        </>
      )}
    </div>
  );
};

export default XGSFormTextarea;
