import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import UserService from "../../app/data/user/userService";
import { initialPortalRegistrationState } from "./PortalRegistrationState";
import { PortalRegistrationModel } from "../../app/data/user/requestModels";

const userService = UserService.getInstance();

export const portalRegistrationSlice = createSlice({
  name: "portalRegistration",
  initialState: initialPortalRegistrationState,
  reducers: {
    resetPortalRegistrationState: (state) => initialPortalRegistrationState,
    request_started: (state, { payload }) => {
      state.request_started = true;
      state.request_failed = false;
      state.request_succeed = false;
      state.request_error = null;
      state.requestCreator = payload;
    },
    request_completed: (state) => {
      state.request_started = false;
      state.request_failed = false;
      state.request_succeed = true;
      state.request_error = null;
    },    
    request_failed: (state, { payload }) => {
      state.request_started = false;
      state.request_failed = true;
      state.request_succeed = false;
      state.request_error = payload;
    }
  }
});

export const {
  resetPortalRegistrationState,
  request_started,
  request_completed,
  request_failed
} = portalRegistrationSlice.actions;

export const portalRegistrationSelector = (state: IState) => state.portalRegistration;

export const signup = (
  request: PortalRegistrationModel,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(request_started("SUBMIT"));
  const response = await userService.portalRegistration(request);
  if (response.ok()) {
    dispatch(request_completed());
    onSuccess && onSuccess();
  } else {
    dispatch(request_failed(response.getError ? response.getError() : "Error"));
  }
};

const portalRegistrationReducer = portalRegistrationSlice.reducer;
export default portalRegistrationReducer;
