import React from "react";
import { PicklistsLoadingStatusSummary } from "../../../../app/data/picklists/models";
import { PicklistsSummaryTile, PicklistsSummaryTileTypes } from "./picklistsSummaryTile";
import "./picklistsSummary.scss";

interface PicklistsSummaryProps {
    picklistSummary: PicklistsLoadingStatusSummary,
    isLoading: boolean
}

export const PicklistsSummary: React.FC<PicklistsSummaryProps> = ({ picklistSummary, isLoading }) => {

    return (<div className="xgs-picklists-summary">
        <PicklistsSummaryTile
            type={PicklistsSummaryTileTypes.totalItems}
            isLoading={isLoading}
            title="Total items Loaded"
            value={(picklistSummary && picklistSummary.totalItemsCount) ? `${picklistSummary.loadedItemsCount}/${picklistSummary.totalItemsCount}` : "0"}
        />
        <PicklistsSummaryTile
            type={PicklistsSummaryTileTypes.totalWeight}
            isLoading={isLoading}
            title="Total weight Loaded (Lb)"
            value={(picklistSummary) ? `${picklistSummary.loadedItemsWeight}` : "0"}
        />
        <PicklistsSummaryTile
            type={PicklistsSummaryTileTypes.lateLoadings}
            isLoading={isLoading}
            title="Late Loadings"
            value={(picklistSummary) ? `${picklistSummary.lateLoadingCount}` : "0"}
        />
        <PicklistsSummaryTile
            type={PicklistsSummaryTileTypes.missingItems}
            isLoading={isLoading}
            title="Missing items"
            value={(picklistSummary) ? `${picklistSummary.missingItemsCount}` : "0"}
        />
    </div>
    )
};