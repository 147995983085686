import { createSlice } from "@reduxjs/toolkit";
import { ProbillsToAddToRouteState } from "./routesState";
import { IState } from "../..";

const initialState: ProbillsToAddToRouteState = {
  selectedProbills: [],
};

export const probillsToAddToRouteSlice = createSlice({
  name: "probillsToAddToRoute",
  initialState,
  reducers: {
    resetProbillsToAddToRoute: (state) => {
      state.selectedProbills = [];
    },
    setProbillsToAddToRoute: (state, { payload }) => {
      state.selectedProbills = payload;
    },
  },
});

export const { 
  resetProbillsToAddToRoute,
  setProbillsToAddToRoute
} = probillsToAddToRouteSlice.actions;

export const probillsToAddToRouteSelector = (state: IState) =>
  state.probillsToAddToRoute;
  
const probillsToAddToRouteReducer = probillsToAddToRouteSlice.reducer;
export default probillsToAddToRouteReducer;
