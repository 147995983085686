import React from "react";
import { Link } from "react-router-dom";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import { SelectColumnFilter } from "../../../ui-components/table/tableWithFilters/filters/selectColumnFilter/selectColumnFilter";

const getFormattedDate = (date: number) => {
  if (!date || !(+date)) return "";
  let dateStr = date.toString();
  const abrevations = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", " Sep", "Oct", "Nov", "Dec"];
  return `${abrevations[+dateStr.slice(4, 6) - 1]} ${dateStr.slice(6)}, ${dateStr.slice(0, 4)}`;
}

const getFormattedTime = (time: number) => {
  let timeStr = `000000${time}`.slice(-6);
  let hour = +timeStr.slice(0, 2);
  if (hour < 12) return `${timeStr.slice(0, 2)}:${timeStr.slice(2, 4)} am`;
  else return `${timeStr.slice(0, 2)}:${timeStr.slice(2, 4)} pm`;
}

const tagsMap: any = {
  "Appointment Needed": {
    icon: XGSIcons.faCalendarTimes,
    style: {
      color: "#C43232",
      backgroundColor: "#DD3F3F33"
    }
  },
  "Appointment Set": {
    icon: XGSIcons.faCalendarCheck,
    style: {
      color: "#14870A",
      backgroundColor: "#5DC75433"
    },
    formatDetails: (details: any) => {
      if (details && +details) return `(${getFormattedDate(details)})`;
      else return "";
    }
  },
  "Standing Appointment": {
    icon: XGSIcons.faCalendar,
    getStyles: (details: any) => {
      if (details && details.startTime && (details.startTime === details.endTime)) {
        return {
          color: "#C43232",
          backgroundColor: "#DD3F3F33"
        }
      } else return {
        color: "#14870A",
        backgroundColor: "#5DC75433"
      }
    },
    formatDetails: (details: any) => {
      if (details && details.startTime) {
        return `(${getFormattedTime(details.startTime)} - ${getFormattedTime(details.endTime)})`;
      }
      else return "";
    }
  },
  "Residential": {
    icon: XGSIcons.faHome,
    style: {
      color: "#B89311",
      backgroundColor: "#E8D15A33"
    }
  },
  "Driver Collect": {
    icon: XGSIcons.faDollarSign,
    style: {
      color: "#B89311",
      backgroundColor: "#E8D15A33"
    }
  }
}

const getTags = (tagTypes: any) => {
  if (!tagTypes || !tagTypes.length) return "";
  return <div className="plan-probills-tags-list">{
    tagTypes.map((tagObj: any, tagIdx: any) => {
      let tagType = tagObj.type;
      let tagDetails = tagObj.details;
      if (tagsMap[tagType]) {
        let styles = (tagsMap[tagType].getStyles) ? tagsMap[tagType].getStyles(tagDetails) : tagsMap[tagType].style;
        return <div
          key={`${tagIdx}_t`}
          className="plan-probills-tag-conatiner"
          style={{ ...styles }}>
          <div className="plan-probills-tag">
            <XGSIcon
              icon={tagsMap[tagType].icon} />
            <div className="plan-probills-tag-type">{tagType}</div>
          </div>
          {tagsMap[tagType].formatDetails && <div className="plan-probills-tag-type">{tagsMap[tagType].formatDetails(tagDetails)}</div>}
          {!tagsMap[tagType].formatDetails && <div className="plan-probills-tag-type">{tagDetails}</div>}
        </div>
      } else {
        return "";
      }
    })
  }
  </div>

}
const getFormattedConsignee = (rawValue: string) => {
  if (rawValue) {
    let splitValues = rawValue.split("|");
    if (splitValues && splitValues.length === 2) {
      return <div>{splitValues[0]}{" ("}<span>
        {splitValues[1]}
        </span>{")"}</div>
    } else {
      return rawValue;
    }
  } else {
    return ""
  }
}
const getPlanProbillsColumns = (
) => [
    {
      id: "selection",
      Header: "",
      width: 30,
      Cell: ({ row }: any) => (
        <div>
          <input
            type="checkbox"
            onClick={(e) => e.stopPropagation()}
            {...row.getToggleRowSelectedProps()}
            indeterminate={undefined}
          />
        </div>
      ),
      disableSortBy: true
    },
    {
      Header: "Probill",
      accessor: "probillNumber",
      width: 80,
      Cell: (cellProps: any) => (
        <Link
          className="xgs-table-link-cell"
          to={`/shipments/${cellProps.value}`}
          target={"_blank"} 
          rel={"noopener noreferrer"}
        >
          {cellProps.value}
        </Link>
      )
    },
    {
      Header: "Static Route",
      accessor: "staticRouteName",
      width: 110,
      Filter: SelectColumnFilter,
      filter: 'equals'
    },
    {
      Header: "Consignee",
      accessor: "consignee",
      width: 120,
      Cell: (cellProps: any) => (
        <div>{getFormattedConsignee(cellProps.value)}</div>
      )
    },
    {
      Header: "Street Address",
      accessor: "consigneeAddress",
      width: 150
    },
    {
      Header: "City",
      accessor: "consigneeCity",
      width: 100,
      Filter: SelectColumnFilter,
      filter: 'equals'
    },
    // {
    //   Header: "State",
    //   accessor: "consigneeState",
    //   width: 60,
    //   Filter: SelectColumnFilter,
    //   filter: 'equals'
    // },
    {
      Header: "Zip Code",
      accessor: "consigneeZip",
      width: 90,
      Filter: SelectColumnFilter,
      filter: 'equals'
    },
    {
      Header: "Pieces",
      accessor: "probillItems",
      width: 70,
      disableFilters: true
    },
    {
      Header: "Weight",
      accessor: "probillWeight",
      width: 70,
      Cell: (cellProps: any) => (
        <>
          {cellProps.value.toLocaleString('en', { useGrouping: true })}
        </>
      ),
      disableFilters: true
    },
    {
      Header: "ETA",
      accessor: "etaDate",
      width: 100,
      Cell: (cellProps: any) => (
        <>
          {getFormattedDate(cellProps.value)}
        </>
      ),
      Filter: SelectColumnFilter,
      filter: 'equals',
      filterLableFormater: getFormattedDate
    },
    {
      Header: "Status",
      accessor: "probillStatus",
      width: 130,
      Cell: (cellProps: any) => (
        <div style={{
          display: "flex",
          height: "auto",
          padding: "4px",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          color: "#7936D0",
          backgroundColor: "#9747FF33",
          borderRadius: "8px"

        }}>
          {cellProps.value}
        </div >
      ),
      Filter: SelectColumnFilter,
      filter: 'equals'
    },
    {
      Header: "Accessorials",
      accessor: "tags",
      width: 180,
      Cell: (cellProps: any) => (
        <div>{getTags(cellProps.value)}</div>
      ),
      disableFilters: true,
      disableSortBy: true
    }
  ];

export default getPlanProbillsColumns;
