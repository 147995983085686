import React, { memo, useEffect} from "react";
import "./addStops.scss";
import getPlanProbillsColumns from "../../../plan-probills/planProbillsColumns";
import { DispatchPlanProbillsState } from "../../../../../slices/dispatch-planning/dispatchProbillsState";
import { useDispatch, useSelector } from "react-redux";
import { dispatchPlanProbillsSelector, getPlannableProbills } from "../../../../../slices/dispatch-planning/dispatchPlanProbillsSlice";
import { PlannableProbills } from "../../../../../app/data/plan-probills/models";
import XGSIcon from "../../../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../../../ui-components/icon/xgsIcons";
import { addProbillsToRouteResetState } from "../../../../../slices/dispatch-planning/routes/addProbillsToRouteSlice";
import { setStopsToAddToRoute } from "../../../../../slices/dispatch-planning/routes/stopsToAddToRouteSlice";
import AddStopsHeader from "./addStopsHeader";
import TableWithFilters from "../../../../../ui-components/table/tableWithFilters/tableWithFilters";

interface AddStopsProps {
    show: boolean;
    routeId: string;
    onClose?: any;
    terminalNumber: string
}
const AddStops: React.FC<AddStopsProps> = memo((props) => {
    const dispatch = useDispatch();
    const dispatchPlanProbillsState: DispatchPlanProbillsState = useSelector(
        dispatchPlanProbillsSelector
    );
    const selectedProbillsChanged = React.useCallback(
        (state: {
            selectedRowsData: PlannableProbills[];
        }) => {
            const selectedProbill: PlannableProbills[] = state.selectedRowsData.map(
                (selectedRow) => selectedRow
            );
            dispatch(setStopsToAddToRoute(selectedProbill));
        },
        [dispatch]
    );

    useEffect(() => {
        dispatch(getPlannableProbills(props.terminalNumber));
         // eslint-disable-next-line
    }, [])

    const onClose = ()=>{
        props.onClose(false);
        dispatch(addProbillsToRouteResetState());
    }
    return (
        <>
            {props.show &&
                <div className="add-stops-overlay">
                    <div className="add-stops-modal">
                        <div className="add-stops-modal-header">
                            <div className="add-stops-modal-header-titile">
                                Note: The selected probills may create multiple stops based on the address
                                <AddStopsHeader 
                                show= {props.show}
                                routeId= {props.routeId}
                                onClose = {props.onClose}
                                terminalNumber= {props.terminalNumber} />
                            </div>
                            <div
                                className="add-stops-modal-header-close"
                                onClick={onClose}
                            >
                                <XGSIcon
                                    icon={XGSIcons.faTimesCircle} />
                            </div>
                        </div>
                        <div className="add-stops-modal-table">
                        <TableWithFilters
                            isLoading={dispatchPlanProbillsState.loading}
                            columns={getPlanProbillsColumns()}
                            data={dispatchPlanProbillsState.plannableProbills}
                            ignoreCellClick="selection"
                            cursorPointer={true}
                            onSelectedRowsChange={selectedProbillsChanged}
                            infiniteScroll={false}
                            responsive={true}
                            sorting={true}
                            enableMultiSort={true}
                            filters={true}
                        />
                        </div>
                    </div>
                </div>
            }
        </>
    );
});

export default AddStops;