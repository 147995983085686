import api, { ApiResponse } from "./../api";
import { ManualReturnRequestModel, ReturnRequestModel } from "./models";

class ReturnService {
  private static instance: ReturnService;
  private constructor() {}
  static getInstance(): ReturnService {
    if (!ReturnService.instance) {
      ReturnService.instance = new ReturnService();
    }
    return ReturnService.instance;
  };

  public createReturn = async (
    request: ReturnRequestModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/bols/returns", request);
  };

  public requestManualReturn = async (
    request: ManualReturnRequestModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/bols/returns/manual", request);
  };

  public uploadReferenceDocument = async (
    data: FormData
  ): Promise<ApiResponse<any>> => {
    return await api.post("/bols/returns/document", data, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  };

  public shareReturn = async (
    probill: number,
    email: string
  ): Promise<ApiResponse<any>> => {
    return await api.post("/bols/returns/share", {
      probillNumber: probill,
      email
    });
  };

  public getMillsList = async (): Promise<ApiResponse<any>> => {
    return await api.get("/bols/returns/mills");
  };
};

export default ReturnService;
