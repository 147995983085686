import api, { ApiResponse } from "./../api";

class NotificationsService {
  private static instance: NotificationsService;
  private constructor() {}
  static getInstance(): NotificationsService {
    if (!NotificationsService.instance) {
      NotificationsService.instance = new NotificationsService();
    }
    return NotificationsService.instance;
  }

  public getNotifications = async (): Promise<ApiResponse<any>> => {
    return await api.get("/notifications");
  };

  public updateNotification = async (
    type: string,
    enabled: boolean,
    notifyBy: string
  ): Promise<ApiResponse<any>> => {
    return await api.put("/notifications", [
      {
        type, enabled, notifyBy
      }
    ]);
  };

  public updateTimezone = async (
    timezone: string,
    userId: string
  ): Promise<ApiResponse<any>> => {
    return await api.post("/notifications/timezone", {
      timezone,
      userId
    });
  };
}

export default NotificationsService;
