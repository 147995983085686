import api, { ApiResponse } from "../api";
import { HolidayDateModel } from "./models";

class HolidayCalendarService {
  private static instance: HolidayCalendarService;
  private constructor() {}

  static getInstance(): HolidayCalendarService {
    if (!HolidayCalendarService.instance) {
      HolidayCalendarService.instance = new HolidayCalendarService();
    }
    return HolidayCalendarService.instance;
  }

  public getHolidaySuggestions = async (): Promise<ApiResponse<HolidayDateModel>> => {
    return await api.get("/holidays/suggestions")
  }

  public getHolidays = async (): Promise<ApiResponse<number[]>> => {
    return await api.get("/holidays")
  }

  public createHoliday = async (date: string, name: string): Promise<ApiResponse<any>> => {
    return await api.post(`/holidays`, {
      date,
      name
    })
  }

  public removeHoliday = async (date: string): Promise<ApiResponse<any>> => {
    return await api.post(`/holidays/delete`, {
      date
    })
  }

  public getCustomerHolidays = async (teamId: string): Promise<ApiResponse<number[]>> => {
    return await api.get(`/holidays/${teamId}`)
  }

  public createCustomerHoliday = async (teamId: string, date: string, name: string): Promise<ApiResponse<any>> => {
    return await api.post(`/holidays/${teamId}`, {
      date,
      name
    })
  }

  public removeCustomerHoliday = async (teamId: string, date: string): Promise<ApiResponse<any>> => {
    return await api.post(`holidays/${teamId}/delete`, {date})
  }

  public getHolidayLogs = async (): Promise<ApiResponse<any>> => {
    return await api.get(`/holidays/logs`)
  }

  public getCustomerHolidayLogs = async (teamId: string): Promise<ApiResponse<any>> => {
    return await api.get(`/holidays/${teamId}/logs`)
  }
}

export default HolidayCalendarService;
