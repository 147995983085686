import React from "react";

import CompliancePercentage from "./compliancePercentage";

const config = [
  {
    percentage: 0,
    isCompleted: false,
    description: "Delivery not started",
  },
  {
    percentage: 50,
    isCompleted: false,
    description: "1-99% In-progress compliance",
  },
  {
    percentage: 50,
    isCompleted: true,
    description: "1-99% final compliance",
  },
  {
    percentage: 100,
    isCompleted: true,
    description: "All deliveries compliant",
  },
  {
    percentage: 0,
    isCompleted: true,
    description: "Driver did not use App",
  },  
];

const CompliancePercentageLegend: React.FC = () => {
  return (    
    <div className="compliance-percentage-legend">
      {config.map((item, i) => (
        <div className="legend__item" key={i}>
          <CompliancePercentage id={i} percentage={item.percentage} isCompleted={item.isCompleted} />
          <span className="legend__description">{item.description}</span>
        </div>
      ))}
    </div>
  )
};

export default CompliancePercentageLegend;