import React from "react";
import { Doughnut } from "react-chartjs-2";
import {
  ChartData,
  ChartOptions,
} from "chart.js";

import { ReactComponent as SuccessMark } from "../../../images/success_mark.svg";
import { ReactComponent as WarningMark } from "../../../images/warning_mark.svg";

export interface CompliancePercentageProps {
  id: number;
  percentage: number;
  isCompleted: boolean;
}

const CompliancePercentage: React.FC<CompliancePercentageProps> = React.memo(({ id, percentage, isCompleted }) => {
  const renderProgressChart = () => {
    const activeColor = isCompleted ? "#E82A2B" : "#2F80ED";

    const chartDataObj: ChartData<"doughnut"> = {
      datasets: [
        {          
          data: [percentage, 100 - percentage],
          backgroundColor: [
            activeColor,
            "#E0E0E0",
          ],
          borderColor: [
            activeColor,
            "#E0E0E0",
          ],
          borderWidth: 1,
        }
      ]
    };

    const chartOptions: ChartOptions<"doughnut"> = {      
      plugins: {
        legend: {
          display: false
        }
      },
      events: [],
      cutout: "88%",
    };

    return <div className="xgs-manifest__compliance-percentage__chart">
      <Doughnut
        data={chartDataObj}
        options={chartOptions}
        id={`compliance-chart-${id}`}
      />
      <div className="xgs-manifest__compliance-percentage__chart-label">
        {Math.floor(percentage)}
      </div>
    </div>
  };

  const renderCompletedPercentage = () => {
    switch (percentage) {
      case 100:
        return <SuccessMark />

      case 0:
        return <WarningMark />

      default:
        return renderProgressChart();
    }
  }

  return (
    <div>      
      {isCompleted ? renderCompletedPercentage() : renderProgressChart()}
    </div>
  );
});

export default CompliancePercentage;