import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { Routes } from "../../../app/route/RoutesConfig";
import Loading from "../../../ui-components/loading/loading";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import Table from "../../../ui-components/table/table";
import CustomCell, {
  CustomCellTypes,
} from "../../../ui-components/table/custom-cells/customCell";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import { GetRatesRequestModel } from "../../../app/data/rates/models";
import { StepProps } from "../../../app/data/common/models";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import RequestRatesState from "../../../slices/rates/RequestRatesState";
import {
  getRates,
  requestRatesSelector,
  resetResult,
  resetRequestRatesState,
  requestVolume,
  submitRequest
} from "../../../slices/rates/requestRatesSlice";
import { setBolFromRequestRates } from "../../../slices/bol/bolSlice";
import { resetCommonState } from "../../../slices/common/commonSlice";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import DownloadPDF from "../download-pdf/downloadPDF";
import ShareQuote from "../share-quote/shareQuote";

export interface ResultPreviewProps extends StepProps {
  quickMode?: boolean;
}

const ResultPreview: React.FC<ResultPreviewProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { previous, push } = props;
  const requestRatesState: RequestRatesState = useSelector(requestRatesSelector);
  const userState: UserState = useSelector(userSelector);

  const columns = [
    {
      Header: (
        <div style={{ textAlign: "right" }}>
          Pieces
        </div>
      ),
      accessor: "pieces",
      width: 60,
      minWidth: 60,
      Cell: (cellProps: any) => <div style={{ textAlign: "right" }}>{cellProps.value || ""}</div>
    },
    {
      Header: (
        <div style={{ textAlign: "center" }}>
          <span>Class</span>
        </div>
      ),      
      accessor: "freightClass",
      width: 60,
      Cell: (cellProps: any) => <div style={{ textAlign: "center" }}>{cellProps.value}</div>
    },
    {
      width: 308,
      Header: "Description",
      accessor: "description",
      Cell: (cellProps: any) => <>{cellProps.value}{cellProps.row.original.commodityCode ? ` (${cellProps.row.original.commodityCode})` : ""}</>
    },
    {
      Header: (
        <div style={{ textAlign: "center" }}>
          <span>Units</span>
        </div>
      ),
      accessor: "units",
      Cell: (cellProps: any) => <div style={{ textAlign: "center" }}>{cellProps.value || ""}</div>,
      width: 60
    },
    {
      Header: (
        <div style={{ textAlign: "right" }}>
          <span>Sq. yards</span>
        </div>
      ),
      accessor: "yards",
      Cell: (cellProps: any) => <div style={{ textAlign: "right" }}>{cellProps.value || ""}</div>,
      width: 90
    },
    {
      Header: (
        <div style={{ textAlign: "right" }}>
          Weight
        </div>
      ),
      accessor: "weight",
      Cell: (cellProps: any) => <div style={{ textAlign: "right" }}>{cellProps.value || ""}</div>,
      width: 100
    },
    {
      Header: (
        <div style={{ textAlign: "right" }}>
          Rate
        </div>
      ),      
      accessor: "rate",
      Cell: (cellProps: any) => <div style={{ textAlign: "right" }}>{cellProps.value || ""}</div>,
      width: 100
    },
    {
      Header: (
        <div style={{ textAlign: "right" }}>
          Freight charges
        </div>
      ),       
      accessor: "freightCharges",
      Cell: (cellProps: any) => (
        <div style={{ textAlign: "right" }}>
          {cellProps.value ? <CustomCell
            cellType={CustomCellTypes.amount}
            selector="freightCharges"
            data={cellProps.row.original}
            value={cellProps.value}
          /> : ""}
        </div>
      ),
      width: 100
    }
  ];

  const formatPhone = (phone: string) => {
    let cleaned = ("" + phone).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? match[1] + "-" + match[2] + "-" + match[3] : null;
  };

  const onClickBack = () => {
    dispatch(resetResult());
    previous && previous();
  };

  const onSubmit = () => {
    requestRatesState.result.quoteNumber && dispatch(submitRequest(requestRatesState.result.id));
  };

  const onCreateBOL = () => {
    mixpanel.track("Started BOL creation from just requested rates", {
      "Mode": props.quickMode ? "quick" : "guided"
    });
    dispatch(setBolFromRequestRates({
      quoteNumber: requestRatesState.result.quoteNumber,
      pickupZip: requestRatesState.pickupLocation?.value,
      items: requestRatesState.items
    }));
    history.push(`/${userState.activeSubAccount?.accountNumber}${Routes.bol.create}`);    
  };

  const prepareRequest = () => {
    if (!requestRatesState.pickupLocation || !requestRatesState.destinationLocation || !userState.activeSubAccount?.id) return;
    let request: GetRatesRequestModel = {
      account: userState.activeSubAccount.id,
      originZip: requestRatesState.pickupLocation.value,
      destinationZip: requestRatesState.destinationLocation.value,
      tenderDate: requestRatesState.pickupDate.toApiDateFormat(),
      items: [],
      specialServices: requestRatesState.services
    }
    for (const item of requestRatesState.items) {
      request.items.push({
        pieces: Number(item.pieces),
        ratingClass: item.freightClass,
        description: item.description,
        yards: item.yards ? Number(item.yards) : 0,
        weight: Number(item.weight),
        roll: item.rolls || false
      });
    }
    return request;
  };

  const requestRates = () => {
    mixpanel.track("Rates have been requested", {
      "Mode": props.quickMode ? "quick" : "guided"
    });
    let request = prepareRequest();
    request && dispatch(getRates(request));
  };

  const startOver = () => {
    dispatch(resetRequestRatesState());
    dispatch(resetCommonState());
    if (props.quickMode) {
      dispatch(resetResult());
      push && push("");
    } else {
      push && push("pickup-location-step");
    }
  };

  const onClickRequestVolume = () => {
    if (requestRatesState.result.error.type === "VOLUME") {
      mixpanel.track("Volume rates request was sent", {
        "Mode": props.quickMode ? "quick" : "guided"
      });
    } else if (requestRatesState.result.error.type === "INVALID_LANE") {
      mixpanel.track("Non-serviceable zip codes request was sent", {
        "Mode": props.quickMode ? "quick" : "guided"
      });
    }
    let request = prepareRequest();
    request && dispatch(requestVolume(request, requestRatesState.result.error.type));
  };

  useEffect(() => {
    requestRates();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="xgs-request-rates__result-preview">
      <div className="xgs-request-rates__step__header">
        <div>Rating</div>
        {(requestRatesState.requestCreator === "submitRequest" && requestRatesState.requestSucceed && requestRatesState.result?.quoteNumber) && (
          <div className="xgs-request-rates__result-preview__top-buttons">
            <DownloadPDF quoteNumber={requestRatesState.result.quoteNumber} />
            <ShareQuote quoteId={requestRatesState.result.id} />
          </div>
        )}
      </div>
      {requestRatesState.requestStarted && requestRatesState.requestCreator === "GET_RATES" && (
        <Loading isLoading={true} />
      )}
      {requestRatesState.requestFailed && (
        <XGSErrorMessage className="xgs-request-rates__result-preview__error">{requestRatesState.requestError}</XGSErrorMessage>
      )}
      {requestRatesState.result && !requestRatesState.result.error && (
        <>
          <div className="xgs-request-rates__result-preview__details">
            <div className="xgs-request-rates__result-preview__details__name">Origin:</div>
            <div className="xgs-request-rates__result-preview__details__value">
              {requestRatesState.result.shipmentInfo.originCityState}, {requestRatesState.result.shipmentInfo.originZip}
              {requestRatesState.result.shipmentInfo.originPhone && (
                <div className="xgs-request-rates__result-preview__details__phone">
                  <XGSIcon
                    icon={XGSIcons.faPhoneAlt}
                    size="sm"
                    className="xgs-request-rates__result-preview__details__phone__icon"
                  />{formatPhone(requestRatesState.result.shipmentInfo.originPhone)} - XGS {requestRatesState.result.shipmentInfo.originServiceCenterName}
                </div>
              )}
            </div>
          </div>
          <div className="xgs-request-rates__result-preview__details">
            <div className="xgs-request-rates__result-preview__details__name">Destination:</div>
            <div className="xgs-request-rates__result-preview__details__value">
              {requestRatesState.result.shipmentInfo.destinationCityState}, {requestRatesState.result.shipmentInfo.destinationZip}
              {requestRatesState.result.shipmentInfo.destinationPhone && (
                <div className="xgs-request-rates__result-preview__details__phone">
                  <XGSIcon
                    icon={XGSIcons.faPhoneAlt}
                    size="sm"
                    className="xgs-request-rates__result-preview__details__phone__icon"
                  />{requestRatesState.result.shipmentInfo.direct && (
                    <>
                      {formatPhone(requestRatesState.result.shipmentInfo.destinationPhone)} - XGS {requestRatesState.result.shipmentInfo.destinationServiceCenterName}
                    </>
                  )}
                  {!requestRatesState.result.shipmentInfo.direct && (
                    <>
                      (844) 947-7447 opt.&nbsp;1 - XGS Customer service
                    </>
                  )}
                </div>
              )}
              {!requestRatesState.result.shipmentInfo.direct && (
                <div className="xgs-request-rates__indirect xgs-request-rates__indirect--results">
                  Indirect Point!
                </div>
              )}
            </div>
          </div>
          <div className="xgs-request-rates__result-preview__details">
            {requestRatesState.result.shipmentInfo.etaDate && (
              <>
                <div className="xgs-request-rates__result-preview__details__name">Estimated arrival:</div>
                <div className="xgs-request-rates__result-preview__details__value">{requestRatesState.result.shipmentInfo.etaDate.toUiDateFormat()}, {requestRatesState.result.shipmentInfo.etaDayOfWeek.substring(0, 3)}</div>
              </>
            )}
            {!requestRatesState.result.shipmentInfo.etaDate && requestRatesState.result.shipmentInfo.message && (
              <div>{requestRatesState.result.shipmentInfo.message}</div>
            )}
          </div>
          <div className="xgs-request-rates__result-preview__summary__header">Shipment summary</div>
          <Table
            columns={columns}
            data={requestRatesState.result.items}
            totals={[
              {
                title: "Total Charges",
                type: CustomCellTypes.amount,
                value: requestRatesState.result.items.reduce((total: number, item: any) => total + item.freightCharges, 0) || "0"
              }
            ]}
          />
          <div className="xgs-request-rates__result-preview__explanations">
            RATES ARE BASED OFF CURRENT XGSI 3500 TARIFF UNLESS SPECIAL PRICING IS ASSIGNED TO YOUR ACCOUNT. 
            ALL QUOTES ARE SUBJECT TO PUBLISHED CUSTOMER PRICING AND THE TERMS OF ANY XGSI 3501 RULES TARIFF 
            ITEMS APPLICABLE AT TIME OF SHIPMENT (SEE ITEM # 720 FOR COMPLETE RULES). 
            QUOTES ARE VALID FOR 15 DAYS FROM ISSUE DATE ONLY.
          </div>
        </>
      )}
      {requestRatesState.result && requestRatesState.result.error && (
        <>
          {(requestRatesState.result.error.type === "DEFAULT") && (
            <XGSErrorMessage className="xgs-request-rates__result-preview__error__message">{requestRatesState.result.error.message}</XGSErrorMessage>
          )}
          {(requestRatesState.result.error.type !== "DEFAULT") && (
            <>
              {!(requestRatesState.requestCreator === "ERROR_REQUEST" && requestRatesState.requestSucceed) && (
                <>
                  {(requestRatesState.result.error.type === "VOLUME") && (
                    <div className="xgs-request-rates__result-preview__error__message xgs-request-rates__result-preview__error__message--gray">
                      Due to the weight amount entered we will need to send this request to our team for a volume quote so that they can get back with you ASAP and ensure you receive the best rates possible.
                    </div>
                  )}
                  {(requestRatesState.result.error.type === "INVALID_LANE") && (
                    <div className="xgs-request-rates__result-preview__error__message xgs-request-rates__result-preview__error__message--gray">
                      <p>Pricing for the requested service requires additional review by an XGS Pricing Analyst.</p>
                      <p>Would you like to send your quote request to our pricing team and have them follow-up with you?</p>
                    </div>
                  )}
                  <div className="xgs-request-rates__result-preview__error__buttons">
                    <Button
                      theme={ButtonThemes.blue}
                      onClick={onClickRequestVolume}
                      className="xgs-request-rates__result-preview__error__button xgs-request-rates__result-preview__error__button--submit"
                      spinner={requestRatesState.requestStarted && requestRatesState.requestCreator === "ERROR_REQUEST"}
                    >
                      Yes, send to XGS for review
                    </Button>
                    <Button
                      theme={ButtonThemes.gray}
                      onClick={startOver}
                      className="xgs-request-rates__result-preview__error__button"
                    >
                      No, cancel this rate request
                    </Button>
                  </div>
                </>
              )}
              {(requestRatesState.requestCreator === "ERROR_REQUEST" && requestRatesState.requestSucceed) && (
                <>
                  <div className="xgs-request-rates__result-preview__success-block">
                    <XGSIcon
                      icon={XGSIcons.faCheck}
                      size="2x"
                      className="xgs-request-rates__result-preview__success-block__icon"
                    />
                    <div className="xgs-request-rates__result-preview__success-block__text">
                      We have sent the request information to our sales department. They will contact you ASAP by emailing to <strong>{userState.profile?.email}</strong>.
                    </div>
                  </div>
                  <div className="xgs-request-rates__result-preview__error__buttons">
                    <Button
                      theme={ButtonThemes.blue}
                      onClick={startOver}
                      className="xgs-request-rates__step__nav-button"
                    >
                      Start over
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
      <div className="xgs-request-rates__result-preview__bottom-block">
        {requestRatesState.requestCreator === "SUBMIT_RATES_REQUEST" && requestRatesState.requestSucceed && (
          <div className="xgs-request-rates__result-preview__success-block">
            <XGSIcon
              icon={XGSIcons.faCheck}
              size="2x"
              className="xgs-request-rates__result-preview__success-block__icon"
            />
            <div className="xgs-request-rates__result-preview__success-block__text">
              Your request was successfully submitted.<br />
              The quote number is <strong>{requestRatesState.result.quoteNumber}</strong>.
            </div>
          </div>
        )}
        {(requestRatesState.requestFailed || requestRatesState.result)
          && !(requestRatesState.requestCreator === "SUBMIT_RATES_REQUEST" && requestRatesState.requestSucceed)
          && !(requestRatesState.requestCreator === "ERROR_REQUEST" && requestRatesState.requestSucceed)
          && (
          <Button
            theme={ButtonThemes.gray}
            onClick={onClickBack}
            className="xgs-request-rates__step__nav-button"
          >
            Back
          </Button>
        )}
        {requestRatesState.result && !requestRatesState.result.error && (
          <>
            {!(requestRatesState.requestCreator === "SUBMIT_RATES_REQUEST" && requestRatesState.requestSucceed) && (
              <Button
                theme={ButtonThemes.blue}
                onClick={onSubmit}
                className="xgs-request-rates__step__nav-button xgs-request-rates__result-preview__request-button"
                spinner={requestRatesState.requestStarted && requestRatesState.requestCreator === "SUBMIT_RATES_REQUEST"}
              >
                Request quote number
              </Button>
            )}
            {requestRatesState.requestCreator === "SUBMIT_RATES_REQUEST" && requestRatesState.requestSucceed && (
              <>
                <Button
                  theme={ButtonThemes.blue}
                  onClick={startOver}
                  className="xgs-request-rates__step__nav-button"
                >
                  Start over
                </Button>
                <Button
                  theme={ButtonThemes.blue}
                  onClick={onCreateBOL}
                  className="xgs-request-rates__step__nav-button xgs-request-rates__result-preview__create-bol"
                >
                  Create Bill of Lading
                </Button>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ResultPreview;
