import React from "react";
import { useSelector } from "react-redux";
import { NavLink, NavLinkProps } from "react-router-dom";

import UserState from "../../slices/user/UserState";
import { userSelector } from "../../slices/user/userSlice";

const XGSNavLink: React.FC<NavLinkProps> = (props) => {
  const userState: UserState = useSelector(userSelector);

  const { to, children, ...rest } = props;

  const subAccountPath = userState?.activeSubAccount?.accountNumber
    ? `/${userState.activeSubAccount.accountNumber}`
    : "";

    const terminalPath = userState?.activeTerminal?.id
    ? `/${userState.activeTerminal?.id}`
    : "";  

  return (
    <NavLink
      {...rest}
      to={`${subAccountPath}${terminalPath}${to}`}
    >
      {children}
    </NavLink>
  )
}

export default XGSNavLink;