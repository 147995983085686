import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Form, Formik, FormikProps } from "formik";
import {
  CreateTeamFormModel,
  CreateTeamRequestModel,
  CreateTeamSchema
} from "../../../app/data/company/models";
import XGSPopup from "../../../ui-components/xgs-popup/xgsPopup";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import Loading from "../../../ui-components/loading/loading";
import CreateTeamState from "../../../slices/customers/teams/CreateTeamState";
import {
  clearAccountsInfo,
  createTeamSelector,
  getAccountsInfo,
  sendCreateTeamRequest
} from "../../../slices/customers/teams/createTeamSlice";
import { ERROR_MESSAGES } from "../../../app/data/common/errorMessages";
import "./createTeam.scss";

export interface CreateTeamProps {
  show: boolean;
  type: "CUSTOMER" | "AGENT";
  onTeamCreated?: () => void;
  onClose: () => void;
};

let initialValues: CreateTeamFormModel = {
  accounts: "",
  email: "",
  name: "",
  agent: false
};

const CreateTeam: React.FC<CreateTeamProps> = ({ type, ...props }) => {
  const [inputTimeout, setInputTimeout] = useState(0);
  const createTeamFormRef = useRef<any>(null);
  const createTeamState: CreateTeamState = useSelector(createTeamSelector);
  const dispatch = useDispatch();

  const parseAccounts = (accountNumbers: string): number[] => {
    return accountNumbers.split(/(?:,| )+/)
      .map(Number)
      .filter(accountNumber => accountNumber && accountNumber > 99 && accountNumber <= 99999999);
  };

  const onAccountNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    if (target.value.length > 2) {
      const accountNumbers = parseAccounts(target.value);
      inputTimeout && clearTimeout(inputTimeout);
      setInputTimeout(setTimeout(() => {
        dispatch(getAccountsInfo(accountNumbers));
      }, 500));
    }
    createTeamFormRef.current?.handleChange(e);
  };

  const onSubmitTeam = (data: CreateTeamFormModel) => {
    let request: CreateTeamRequestModel = {
      email: data.email,
      name: data.name,
      accounts: [],
      agent: type === "CUSTOMER" ? false : (type === "AGENT" ? true : false)
    }
    request.accounts = parseAccounts(data.accounts);
    dispatch(sendCreateTeamRequest(request, () => {
      toast.info(`${type === "CUSTOMER" ? "Customer" : ""}${type === "AGENT" ? "Agent" : ""} created!`);
      props.onTeamCreated && props.onTeamCreated();
      props.onClose();
    }));
  };

  useEffect(() => {
    if (!createTeamState.request_was_failed) return;
    toast.error(createTeamState.request_fail_reason || ERROR_MESSAGES.COMMON);
  }, [createTeamState.request_was_failed, createTeamState.request_fail_reason]);

  useEffect(() => {
    props.show && dispatch(clearAccountsInfo());
  }, [dispatch, props.show]);

  return (
    <XGSPopup
      title=""
      opened={props.show}
      onClose={props.onClose}
      className="xgs-create-team__popup"
    >
      <div>
        <div className="xgs-create-team__popup__header">Create new {type === "CUSTOMER" ? "customer" : ""}{type === "AGENT" ? "agent" : ""}</div>
        <Formik
          onSubmit={onSubmitTeam}
          initialValues={initialValues}
          validationSchema={CreateTeamSchema}
          innerRef={createTeamFormRef}
        >
          {(props: FormikProps<CreateTeamFormModel>) => (
            <Form>
              <div className="xgs-create-team__popup__field">
                <XGSFormInput
                  type="text"
                  name="name"
                  label={`${type === "CUSTOMER" ? "Customer " : ""}${type === "AGENT" ? "Agent " : ""} name:`}
                  required={true}
                  requiredAsteriskDisabled={true}
                  labelMode={LabelModes.column}
                />
              </div>
              <input type="hidden" name="agent" />
              <div className="xgs-create-team__popup__field">
                <XGSFormInput
                  type="text"
                  name="email"
                  label="Administrator email:"
                  required={true}
                  requiredAsteriskDisabled={true}
                  labelMode={LabelModes.column}
                />
              </div>
              <div className="xgs-create-team__popup__field">
                <XGSFormInput
                  type="text"
                  name="accounts"
                  label="Account number(s):"
                  required={true}
                  requiredAsteriskDisabled={true}
                  labelMode={LabelModes.column}
                  onChange={onAccountNumberChange}
                  placeholder="One or more account number"
                />
              </div>
              <div className="xgs-create-team__account__wrapper">
                {(createTeamState.accountsInfo_was_started || createTeamState.accountsInfo?.length > 0 || createTeamState.accountsInfo_was_failed) && !props.errors.accounts && (
                  <div className="xgs-create-team__account__area">
                    {createTeamState.accountsInfo && (
                      <div className="xgs-create-team__account__title">Account data:</div>
                    )}
                    <div className="xgs-create-team__account__data">
                      <Loading isLoading={createTeamState.accountsInfo_was_started} />
                      {createTeamState.accountsInfo_was_failed && (
                        <div className="xgs-create-team__account__error">
                          {createTeamState.accountsInfo_fail_reason || "Error"}
                        </div>
                      )}
                      {createTeamState.accountsInfo && createTeamState.accountsInfo.map((accountInfo: any) => (
                        <div
                          className="xgs-create-team__account__data__item"
                          key={accountInfo.accountId}>
                          <div className="xgs-create-team__account__data__item__id">{accountInfo.accountId}:</div>
                          <strong>{accountInfo.accountName}</strong><br />
                          {accountInfo.address ? `${accountInfo.address}, ` : ""}{accountInfo.city ? `${accountInfo.city}, ` : ""}{accountInfo.state ? `${accountInfo.state}, ` : ""}{accountInfo.zip}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <Button
                theme={ButtonThemes.blue}
                type="submit"
                className="xgs-create-team__popup__button"
                disabled={!props.isValid || !props.dirty || !createTeamState.accountsInfo || createTeamState.accountsInfo.length === 0}
                spinner={createTeamState.request_was_started}
              >
                Create&nbsp;{type === "CUSTOMER" ? "customer" : ""}{type === "AGENT" ? "agent" : ""}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </XGSPopup>
  );
};

export default CreateTeam;
