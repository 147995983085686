export const services = [
  {
    value: "INSIDE_DELIVERY",
    label: "Inside Delivery",
    description: "Move shipments from or to positions beyond the immediately adjacent loading or unloading area."
  },
  {
    value: "LIMITED_ACCESS",
    label: "Limited Access",
    description: "When pick-up or delivery service is provided for locations where tractor trailer clearance is restricting."
  },
  {
    value: "LIFTGATE",
    label: "Liftgate",
    description: "Liftgate required in order to accomplish pickup or delivery of a shipment."
  },
  {
    value: "RESIDENTIAL_AREA",
    label: "Residential Delivery",
    description: "Pick up or delivery to a residential area or apartment. (Charges not applicable in conjunction with White Glove Service.)"
  },
  {
    value: "HAZMAT",
    label: "Hazmat",
    description: "Hazmat is not supported"
  },
  {
    value: "PREPAID",
    label: "Prepaid and Add",
    description: "Shipper prepays the freight bill for the shipment it is moving for its customer, then charges its customer after delivery."
  },
  {
    value: "SORT",
    label: "Sort and Segregate",
    description: "Sort and/or segregate Consignor or Consignee's shipment."
  },
  {
    value: "CONSTRUCTION",
    label: "New Construction or Job Site",
    description: "Delivery to construction area."
  },
  {
    value: "PREDELIVERY_CALL",
    label: "Predelivery Call or Notify Fee",
    description: "Telephone or written notice of arrival of shipment. (Charge not be applied to white glove services.)"
  },
  {
    value: "TRADESHOW",
    label: "Tradeshow or Convention Center",
    description: "Shipments originating at or destined to exhibition sites."
  },
  {
    value: "WILL_CALL",
    label: "Will Call (Direct Points by Contract Only)",
    description: "Pickup freight by Carriers dock. Storage charges on freight awaiting pickup will begin at 7 A.M., on the 4th day after freight is received by the Carrier."
  }
];

