import React from "react";
import ShipmentCommentModel from "../../../app/data/tracking/ShipmentCommentsModels";
import Table from "../../../ui-components/table/table";

export interface ShipmentCommentsProps {
  comments: ShipmentCommentModel[];
}

const ShipmentCommentsList: React.FC<ShipmentCommentsProps> = (props) => {

  const columns = [
    {
      Header: "Date",
      accessor: "dateAdded",
      width: 120,
      minWidth: 80,
      Cell: ({ row }: any) => (
        <>{row.original.dateAdded?.toUiDateFormat()} <span className="xgs-shipment-details__comments__time">{row.original.timeAdded}</span></>
      )
    },
    {
      Header: "Type",
      accessor: "type",
      width: 64,
      minWidth: 48
    },
    {
      Header: "Comment",
      accessor: "text",
      width: 520,
      minWidth: 280
    }
  ];

  return (
    <div className="xgs-shipment-details__comments">
      <Table
        columns={columns}
        data={props.comments}
        rowHeight={56}
        responsive
        minTableHeight={160}
        strictMinTableHeight
        infiniteScroll
      />
    </div>
  );
};


export default ShipmentCommentsList;
