import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import mixpanel from "mixpanel-browser";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import { logout } from "../../slices/user/userSlice";
import AnnouncementState from "../../slices/announcement/AnnouncementState";
import {
  acceptUserAnnouncement,
  announcementSelector,
  getUserAnnouncement,
  setAnnouncementChecked
} from "../../slices/announcement/announcementSlice";
import { userSelector } from "../../slices/user/userSlice";
import UserState from "../../slices/user/UserState";
import modalStyle from "../../app/data/common/modalStyle"
import { Routes } from "../../app/route/RoutesConfig";
import "../../sass/modal.scss";
import "./announcement.scss";

Modal.setAppElement("#root");

const Announcement: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const announcementState: AnnouncementState = useSelector(announcementSelector);
  const userState: UserState = useSelector(userSelector);
  const [showModal, setShowModal] = useState<boolean>(false);

  const onAccept = () => {
    if (!announcementState.announcement.id) return;
    if (!announcementState.announcement.needUserAcceptance || userState.profile?.impersonated) setShowModal(false);
    if (!userState.profile?.impersonated) {
      dispatch(acceptUserAnnouncement(announcementState.announcement.id, () => {
        setShowModal(false);
      }));
    }
  };

  const onLogOut = () => {
    setShowModal(false);
    if (userState.profile?.impersonated) {
      localStorage.removeItem("xgs-impersonate-token");
      history.push(Routes.home);
      window.location.reload();
    } else {
      mixpanel.track("Logged Out", {"Reason": "Rejection of the announcement"});
      mixpanel.reset();
      dispatch(logout());
    }
  };

  useEffect(() => {
    if (announcementState.announcementChecked) return;
    dispatch(getUserAnnouncement((response) => {
      if (response && !response.accepted) {
        setShowModal(true);
        dispatch(setAnnouncementChecked(true));
      }
    }));
  }, [announcementState.announcementChecked, dispatch]);

  return (
    <Modal
      isOpen={showModal}
      style={modalStyle}
      onRequestClose={onAccept}
      shouldCloseOnOverlayClick={!(announcementState.announcement ? announcementState.announcement.needUserAcceptance : false)}
      shouldCloseOnEsc={!(announcementState.announcement ? announcementState.announcement.needUserAcceptance: false)}
    >
      {announcementState.announcement && (
        <>
          <div className="xgs-modal__header xgs-announcement__modal__header">
            {announcementState.announcement.header}
          </div>
          <div className="xgs-modal__content xgs-announcement__modal__content">
            <div className="ql-editor" dangerouslySetInnerHTML={{__html: announcementState.announcement.body}}></div>
          </div>
          <div className="xgs-modal__buttons text-center">
            <Button
              theme={ButtonThemes.blue}
              onClick={onAccept}
              spinner={announcementState.requestStarted && announcementState.requestCreator === "USER_ACCEPT"}
              className="xgs-modal__button"
              type="button"
            >
              {announcementState.announcement.needUserAcceptance ? "Accept" : "Ok"}
            </Button>
            {announcementState.announcement.needUserAcceptance && (
              <Button
                theme={ButtonThemes.gray}
                onClick={onLogOut}
                className="xgs-modal__button"
                type="button"
              >
                Log Out
              </Button>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default Announcement;
