import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import ClaimsState from "../../slices/claims/ClaimsState";
import {
  claimsSelector,
  editClaim,
} from "../../slices/claims/claimsSlice";
import { EditClaimFormModel, EditClaimSchema } from "../../app/data/claims/models";
import XGSPopup, { PopupTheme } from "../../ui-components/xgs-popup/xgsPopup";
import { Form, Formik, FormikProps } from "formik";
import XGSFormInput from "../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../ui-components/molecules/labeled-inputs/labeledInput";
import { toast } from "react-toastify";

interface EditClaimPopupProps {
  show: boolean;
  onClose: () => void;
  claim?: {
    claimNumber: string,
    totalClaimAmount: number,
  },
};

const EditClaimPopup: React.FC<EditClaimPopupProps> = (props) => {
  const dispatch = useDispatch();
  const claimsState: ClaimsState = useSelector(claimsSelector);
  const formRef = useRef<any>(null);

  const onSubmit = (values: EditClaimFormModel) => {
    if (!props.claim?.claimNumber) return;

    dispatch(editClaim(props.claim.claimNumber, { ...values, totalClaimAmount: Number(values.totalClaimAmount) }, () => {      
      toast.info(<>The claim was successfully edited!</>);
      props.onClose();
    }));
  }

  useEffect(() => {
    if (!claimsState.requestFailed) return;
    toast.error(claimsState.requestError || "Error! Please try again later.");
  }, [claimsState.requestFailed, claimsState.requestError]);

  useEffect(() => {
    props.show && formRef.current && formRef.current.resetForm();;
  }, [dispatch, props.show]);

  return (
    <XGSPopup
      title="Edit Amount"
      opened={props.show}
      onClose={props.onClose}
      className="xgs-claims__edit-popup"
      theme={PopupTheme.NEW}
      closeOnDocumentClick={false}
    >
      <div>        
        <div>Type new amount and save</div>
        <Formik
          onSubmit={onSubmit}
          initialValues={{
            totalClaimAmount: props.claim?.totalClaimAmount || 0,
          }}
          validationSchema={EditClaimSchema}
          innerRef={formRef}
        >
          {(formikProps: FormikProps<EditClaimFormModel>) => (
            <Form>
              <div className="xgs-claims__edit-popup__field">
                <XGSFormInput
                  type="text"
                  name="totalClaimAmount"
                  required={true}
                  requiredAsteriskDisabled={true}
                  labelMode={LabelModes.column}
                />
              </div>              

              <div className="xgs-claims__edit-popup__buttons">
                <Button
                  type="button"
                  onClick={() => {
                    formikProps.resetForm();
                    props.onClose();
                  }}
                  theme={ButtonThemes.gray}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  theme={ButtonThemes.blue}
                  disabled={!formikProps.isValid || !formikProps.dirty}
                  spinner={claimsState.requestStarted}
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </XGSPopup>
  );
};

export default EditClaimPopup;
