import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import moment from "moment";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import LabeledDateRangeInput from "../../../ui-components/molecules/labeled-inputs/labeled-date-range-input/labeledDateRangeInput";
import XGSFormSelect from "../../../ui-components/form/select/xgsFormSelect";
import Table from "../../../ui-components/table/table";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import ContentContainer from "../../../templates/content-container/contentContainer";
import {
  clearPickupLog,
  getPickupsLog,
  pickupAssignmentSelector
} from "../../../slices/pickup/pickupAssignmentSlice";
import {
  PickupsLogFilterModel,
  PickupsLogFilterSchema
} from "../../../app/data/pickup/models";
import { HUMAN_FRIENDLY_ACTIONS } from "../common";
import "./index.scss";

const initialValues = {
  actionPerformed: undefined,
  dateFrom: undefined,
  dateTo: undefined,
  userEmail: "",
  pickupNumber: ""
};

const PickupLog: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const pickupAssignmentState = useSelector(pickupAssignmentSelector);
  const filterFormRef = useRef<FormikProps<PickupsLogFilterModel>>(null);
  const [filter, setFilter] = useState<PickupsLogFilterModel>(initialValues);


  const columns = [
    {
      width: 148,
      Header: "Event Time",
      accessor: "actionExecutedTime",
      Cell: (cellProps: any) => (
        <>
          {cellProps.value && (
            <div className="xgs-pickup-assignment__details__log__date">
              <div>{cellProps.value.toUiDateFormat()}</div> <div className="xgs-pickup-assignment__details__log__gray-value">{cellProps.value.toUiTimeShortFormat()}</div>
            </div>
          )}
        </>
      )
    },
    {
      width: 100,
      Header: "Pickup #",
      accessor: "pickupNumber"
    },
    {
      width: 245,
      Header: "User",
      accessor: "userExecuted"
    },
    {
      width: 160,
      Header: "Action",
      accessor: "actionPerformed",
      Cell: (cellProps: any) => (
        <>{!!cellProps.value && (HUMAN_FRIENDLY_ACTIONS[cellProps.value] ? HUMAN_FRIENDLY_ACTIONS[cellProps.value] : cellProps.value)}</>
      )
    },
    {
      width: 340,
      Header: "Additional Info",
      id: "additionalInfo",
      Cell: ({ row }: any) => (
        <div className="xgs-pickup-log__additional-info" title={row.original.notes || ""}>
          {row.original.notes && (
            <span className="xgs-pickup-assignment__details__log__additional__item">
             {row.original.notes}
            </span>
          )}
          {row.original.driverName && (
            <span className="xgs-pickup-assignment__details__log__additional__item">
             {row.original.driverName} {row.original.driverId ? (<span className="xgs-pickup-assignment__details__log__gray-value">({row.original.driverId})</span>) : ""}
            </span>
          )}
          {!row.original.driverName && row.original.driverId && (
            <span className="xgs-pickup-assignment__details__log__additional__item">
             {row.original.driverId}
            </span>
          )}
        </div>
      )
    }
  ];

  const getActionPerformedValues = () => {
    return Object.keys(HUMAN_FRIENDLY_ACTIONS).map((key) => {
      return {
        value: key,
        label: HUMAN_FRIENDLY_ACTIONS[key]
      };
    });
  };

  const onListInfiniteScroll = () => {
    if (pickupAssignmentState.pickupsLogRequest.last) return;
    const nextPage = pickupAssignmentState.pickupsLogRequest.page + 1;
    dispatch(getPickupsLog(filter, nextPage));
  };

  const onFilterSubmit = (data: PickupsLogFilterModel) => {
    if (data.dateFrom) data.dateFrom = data.dateFrom.toApiDateFormat();
    if (data.dateTo) data.dateTo = data.dateTo.toApiDateFormat();
    setFilter(data);
    dispatch(getPickupsLog(data));
  };

  useEffect(() => {
    dispatch(getPickupsLog());

    return () => dispatch(clearPickupLog());
  }, [dispatch]);

  return (
    <ContentContainer title="Pickup Log" mods={{ "full-width": true }}>
      <div className="xgs-pickup-log">
        <div className="xgs-list__controls">
          <Formik
            initialValues={initialValues}
            onSubmit={onFilterSubmit}
            validationSchema={PickupsLogFilterSchema}
            innerRef={filterFormRef}
          >
             {(props: FormikProps<PickupsLogFilterModel>) => (
              <Form>
                <div className="xgs-list__controls__search">
                  <XGSFormSelect
                    name="actionPerformed"
                    label="Performed Action"
                    labelMode={LabelModes.column}
                    placeholder="Select..."
                    options={getActionPerformedValues()}
                    onValueChange={(o) => {
                      props.setFieldValue("actionPerformed", o?.value || undefined);
                      if (
                        (pickupAssignmentState.requestStarted && (pickupAssignmentState.requestCreator === "GET_PICKUPS_LOG"))
                        || !props.isValid
                      ) return;
                      onFilterSubmit({...props.values, actionPerformed: o?.value })
                    }}
                    className="xgs-list__controls__search__input"
                    isClearable
                  />
                  <LabeledDateRangeInput
                    label="Dates range:"
                    labelMode={LabelModes.column}
                    className="xgs-list__controls__date-range"
                    start={props.values.dateFrom}
                    end={props.values.dateTo}
                    maxDate={new Date(moment().format("MM/DD/YYYY"))}
                    onStartChange={(v) => {
                      props.setFieldValue("dateFrom", v || undefined);
                      props.submitForm();
                    }}
                    onEndChange={(v) => {
                      props.setFieldValue("dateTo", v || undefined);
                      props.submitForm();
                    }}
                  />
                  <XGSFormInput
                    label="User:"
                    name="userEmail"
                    labelMode={LabelModes.column}
                    onChange={(e) => {
                      props.setFieldValue("userEmail", e.currentTarget.value);
                      props.handleChange(e);
                    }}
                    onKeyDown={(e) => {
                      props.setFieldValue("userEmail", e.currentTarget.value || undefined);
                      if (pickupAssignmentState.requestStarted && (pickupAssignmentState.requestCreator === "GET_PICKUPS_LOG")) return;
                      if (e.key === "Enter" && props.isValid) onFilterSubmit({...props.values, userEmail: e.currentTarget.value});
                      props.handleChange(e);
                    }}
                    type="text"
                    placeholder="someone@xgsi.com"
                    className="xgs-list__controls__search__input xgs-list__controls__search__input--narrow"
                  />
                  <XGSFormInput
                    label="Pickup #:"
                    labelMode={LabelModes.column}
                    name="pickupNumber"
                    onChange={(e) => {
                      props.setFieldValue("pickupNumber", e.currentTarget.value);
                      props.handleChange(e);
                    }}
                    onKeyDown={(e) => {
                      props.setFieldValue("pickupNumber", e.currentTarget.value || undefined);
                      if (pickupAssignmentState.requestStarted && (pickupAssignmentState.requestCreator === "GET_PICKUPS_LOG")) return;
                      if (e.key === "Enter" && props.isValid) onFilterSubmit({...props.values, pickupNumber: e.currentTarget.value});
                      props.handleChange(e);
                    }}
                    type="text"
                    placeholder="e.g. PU00012345"
                    className="xgs-list__controls__search__input xgs-list__controls__search__input--narrow"
                  />
                  <Button
                    type="submit"
                    theme={ButtonThemes.blue}
                    disabled={
                      (pickupAssignmentState.requestStarted && (pickupAssignmentState.requestCreator === "GET_PICKUPS_LOG"))
                    }
                    className="xgs-list__controls__button"
                  >
                    Search
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {!pickupAssignmentState.requestFailed && (
          <Table
            isLoading={pickupAssignmentState.requestStarted && (pickupAssignmentState.requestCreator === "GET_PICKUPS_LOG")}
            columns={columns}
            data={pickupAssignmentState.pickupLog}
            rowHeight={48}
            minTableHeight={220}
            noResultsText="No entries"
            infiniteScroll
            infiniteScrollLoading={pickupAssignmentState.fetchPortionStarted && pickupAssignmentState.requestCreator === "GET_PICKUPS_LOG_PORTION"}
            infiniteScrollHasNext={!pickupAssignmentState.pickupsLogRequest.last}
            onInfiniteScroll={onListInfiniteScroll}
          />
        )}
        {pickupAssignmentState.requestFailed && (
          <XGSErrorMessage className="xgs-pickup-log__error">{pickupAssignmentState.requestError || "Error while obtaining the log"}</XGSErrorMessage>
        )}
      </div>
    </ContentContainer>
  );
};

export default PickupLog;
