import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import ShipperState, { initialShipperState } from "./ShipperState";

export const shipperSlice = createSlice({
  name: "shipper",
  initialState: initialShipperState,
  reducers: {
    resetShipper: () => initialShipperState,    
    setShipper: (state, { payload }) => {
      state.shipper = payload;
    },
    setAddressLookupValue: (state, { payload }) => {
      state.addressLookupValue = payload;
    },
  }
});

export const {
  resetShipper,
  setShipper,
  setAddressLookupValue,
} = shipperSlice.actions;

export const shipperSelector = (state: IState): ShipperState => state.shipper;

const shipperReducer = shipperSlice.reducer;
export default shipperReducer;
