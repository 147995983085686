import React, { useState } from "react"
import XGSSelect, { XGSSelectOption } from "../../../../xgs-select/xgsSelect";

export interface SelectColumnFilterProps {
    column: any
}
export const SelectColumnFilter: React.FC<SelectColumnFilterProps> = ({
    column
}) => {
    const [value, setValue] = useState<XGSSelectOption>();
    const options: XGSSelectOption[] = [];
    const optionsCount: any = {};
    column.preFilteredRows.forEach((row: any) => {
        let cellValue = row.values[column.id];
        if (!optionsCount[cellValue]) optionsCount[cellValue] = 0;
        optionsCount[cellValue]++;
    });
    Object.keys(optionsCount).forEach(key => {
        let formattedLable = (column.filterLableFormater) ? column.filterLableFormater(key) : key;
        options.push({
            label: `${formattedLable} (${optionsCount[key]})`,
            value: key
        });
    })
    return (
        <XGSSelect
            id='column-filter-select'
            value={value}
            options={options}
            disabled={false}
            onValueChange={(value) => {
                column.setFilter(value?.value || undefined);
                setValue(value!)
            }}
            isClearable
        />
    );
};