import React, { useMemo } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { invoiceDetailsSelector } from "../../../../slices/invoice/invoiceSlice";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import UserState from "../../../../slices/user/UserState";
import { userSelector } from "../../../../slices/user/userSlice";
import { getPaymentInfo } from "../../../../slices/payment/paymentSlice";
import { InvoiceDetailsPath, PathParams } from "../route";
import XGSIcons from "../../../../ui-components/icon/xgsIcons";
import Amount from "../../../../ui-components/molecules/amount/amount";
import HeaderStatus from "../../../../ui-components/molecules/header-status/headerStatus";
import ContentContainerHeader from "../../../../ui-components/molecules/content-container-header/contentContainerHeader";
import DownloadPDF from "../../../download-invoice-pdf/downloadPDF";
import styles from "./../../../../sass/variables.module.scss";

const InvoiceDetailsHeader: React.FC<{}> = (props) => {
  const userState: UserState = useSelector(userSelector);
  const urlParams = useParams() as PathParams;
  const dispatch = useDispatch();
  const { invoiceDetails, ...invoiceDetailsState } = useSelector(invoiceDetailsSelector);

  const urlInvoiceNumber = useMemo(() => {
    return parseInt(urlParams[InvoiceDetailsPath.invoiceNumber], 10);
  }, [urlParams]);

  const pay = async () => {
    if (userState.activeSubAccount && invoiceDetails?.invoice) {
      dispatch(
        getPaymentInfo(
          invoiceDetails?.invoice.invoiceNumber,
          userState.activeSubAccount?.id
        )
      );
    }
  };

  const isPaid = () =>
    invoiceDetails && invoiceDetails.invoice && invoiceDetails.invoice.paid;

  const isInvoiceDateCurrent = invoiceDetails?.invoice?.dateInvoice ? moment(invoiceDetails.invoice.dateInvoice).isSame(moment(), "day") : false;

  const showPayButton = () => !!(
    !isPaid() &&
    invoiceDetails?.invoice?.openAmount &&
    invoiceDetails.invoice.openAmount > 0 &&
    !isInvoiceDateCurrent
  );

  return (
    <ContentContainerHeader
      title={`Invoice ${urlInvoiceNumber}`}
      isLoading={invoiceDetailsState.loading}
      className="xgs-invoice-details__header"
    >
      {invoiceDetailsState.loaded && (
        <>
          <div className="xgs-site__content-container-header__bar__column xgs-site__content-container-header__column xgs-invoice-details__header__status">
            <HeaderStatus
              label={isPaid() ? "Invoice Paid" : "Open amount"}
              showIcon={isPaid()}
              icon={XGSIcons.faCheckCircle}
              iconColor={styles.green1}
            >
              <Amount amount={invoiceDetails?.invoice.openAmount} />
            </HeaderStatus>
            {showPayButton() && (
              <Button theme={ButtonThemes.blue} onClick={pay}>
                Pay Invoice
              </Button>
            )}
          </div>            
          <div className="xgs-site__content-container-header__bar__column xgs-site__content-container-header__column">
            &nbsp;
          </div>
          <div className="xgs-site__content-container-header__bar__float-buttons">
            <DownloadPDF invoiceNumbers={urlInvoiceNumber} type="single" />
          </div>
        </>
      )}
    </ContentContainerHeader>
  );
};

export default InvoiceDetailsHeader;
