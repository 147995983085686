import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export interface FeedbackModel {
  rating?: string,
  positive: string,
  negative: string,
  missing: string,
}

export interface FeedbackRequestModel extends FeedbackModel {
  page: string,
}

export const FeedbackSchema: Yup.ObjectSchema<FeedbackModel> = Yup.object({
  positive: Yup.string()
    .matches(/(\w+|-+)/, validationMessages.notValid)
    .required(validationMessages.required)
    .max(500, "The message must be no more than 500 characters"),
  negative: Yup.string()
    .matches(/(\w+|-+)/, validationMessages.notValid)
    .required(validationMessages.required)
    .max(500, "The message must be no more than 500 characters"),
  missing: Yup.string()
    .matches(/(\w+|-+)/, validationMessages.notValid)
    .required(validationMessages.required)
    .max(500, "The message must be no more than 500 characters"),
}).defined();
