export enum CompanySettingsPath {
  activeTab = "activeTab",
  selectedItem = "selectedItem",
  innerTab = "innerTab",
}

export interface PathParams {
  [CompanySettingsPath.activeTab]: string;
  [CompanySettingsPath.selectedItem]: string;
  [CompanySettingsPath.innerTab]: string;
}

export const CompanySettingsTabRoutes = {
  users: "/users",
  accounts: "/accounts",
  shippers: "/shippers",
  holidays: "/holidays"
};
