
export default interface MetricsState {
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError?: string | null;
  response: any;
}

export const initialMetricsState: MetricsState = {
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestError: null,
  response: null
};
