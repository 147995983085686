// src/app/data/account-inapplicable-information/accountInapplicableInformationService.ts
import api, { ApiResponse } from '../api';
import { AccountInapplicableInformationType } from './models';

class AccountInapplicableInformationService {
  private static instance: AccountInapplicableInformationService;

  private constructor() {}

  public static getInstance(): AccountInapplicableInformationService {
    if (!AccountInapplicableInformationService.instance) {
      AccountInapplicableInformationService.instance = new AccountInapplicableInformationService();
    }
    return AccountInapplicableInformationService.instance;
  }

  public async disableInformationType(accountId: string, type: AccountInapplicableInformationType): Promise<ApiResponse<void>> {
    return await api.post(`/accounts/${accountId}/disabled-information-types/disable`, null, { params: { type } });
  }

  public async enableInformationType(accountId: string, type: AccountInapplicableInformationType): Promise<ApiResponse<void>> {
    return await api.post(`/accounts/${accountId}/disabled-information-types/enable`, null, { params: { type } });
  }

  public async getDisabledInformationTypes(accountId: string): Promise<ApiResponse<AccountInapplicableInformationType[]>> {
    return await api.get(`/accounts/${accountId}/disabled-information-types`);
  }
}

export default AccountInapplicableInformationService;
