// src/store/accountInapplicableInformation/accountInapplicableInformationState.ts
import { AccountInapplicableInformationType } from '../../app/data/account-inapplicable-information/models';

export interface AccountInapplicableInformationState {
  loading: boolean;
  loadingSucceeded: boolean;
  loadingFailed: boolean;
  loadingError: string | null;
  disabledInformationTypes: AccountInapplicableInformationType[];
  requestStarted: boolean;
  requestFailed: boolean;
  requestSucceeded: boolean;
  requestError?: string;
}

export const initialAccountInapplicableInformationState: AccountInapplicableInformationState = {
  loading: false,
  loadingSucceeded: false,
  loadingFailed: false,
  loadingError: null,
  disabledInformationTypes: [],
  requestFailed: false,
  requestStarted: false,
  requestSucceeded: false,

};
