import React from "react";
import { useSelector } from "react-redux";
import { WizardNavigation } from "../../xgs-wizard/wizardNavigation";
import { ShipperCard } from "../../shipper-card/shipperCard";
import { WizardStepIndicator } from "../../xgs-wizard/wizardStepIndicator";
import { shipperSelector } from "../../../slices/shipper/shipperSlice";
import { StepObject } from "react-albus";
import { WizardCheck } from "../../xgs-wizard/wizardCheck";
import { CreateShipperModel } from "../../../app/data/shipper/models";

export interface ConfirmShipperStepProps { 
  steps: StepObject[];
  previous: () => void;
  onCreate: (shipper: CreateShipperModel) => void;  
}

const ConfirmShipperStep: React.FC<ConfirmShipperStepProps> = (props) => {
  const shipperState = useSelector(shipperSelector);

  const { shipper } = shipperState;

  const next = () => {    
    props.onCreate(shipper);
  };

  return (
    <>
      <WizardCheck/>

      <div className="wizard__heading">
        Review
      </div>

      <div className="wizard__desc">
        Do you want to create a shipper with the originally entered information?
      </div>

      <WizardStepIndicator step={1} stepCount={props.steps.length} />

      <ShipperCard shipper={shipper} hideButtons={true}/>

      <WizardNavigation
        previous={props.previous}
        next={next}
        nextButtonLabel="Create and add shipper"
        hideNextButtonArrow={true}
      />
    </>
  );
};

export default ConfirmShipperStep;