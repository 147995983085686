import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  matchPath,
  useHistory
} from "react-router-dom";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { Terminal } from "../../app/data/common/models";
import DropdownPopup from "../../ui-components/dropdown-popup/dropdownPopup";
import XGSIcon from "../../ui-components/icon/xgsIcon";
import XGSInput from "../../ui-components/xgs-input/xgsInput";
import UserState from "../../slices/user/UserState";
import {
  closeMobileSideMenu,
  setActiveTerminal,
  userSelector
} from "../../slices/user/userSlice";
import { xgsUserRoutesComponents } from "../../app/route/RoutesConfig";
import buildingIcon from "../../images/building.svg";
import searchIcon from "../../images/search_gray.svg";
import { cn } from "../../services/common/className";
import "./terminalSelector.scss";

const TerminalSelector: React.FC = () => {
  const userState: UserState = useSelector(userSelector);
  const [opened, setOpened] = useState<boolean>(false);
  const [unblocked, setUnblocked] = useState<boolean>(true);
  const [filter, setFilter] = useState<string>("");
  const dispatch = useDispatch();
  const history = useHistory();

  const replaceTerminalNumberParam = (terminalId: number) => {
    let route = xgsUserRoutesComponents.find(publicRoute => matchPath(window.location.pathname, publicRoute))?.path[0];
    if (!route) return window.location.pathname;
    const index = route.split("/").findIndex((item: string) => item === ":terminalNumber");
    if (index < 0) return window.location.pathname;
    const path = window.location.pathname.split("/");
    path[index] = terminalId.toString();
    return path.join("/");
  };

  const terminalItemClicked = (terminal: Terminal) => {
    closeDropdown();
    dispatch(closeMobileSideMenu());
    if (terminal.id !== userState.activeTerminal?.id) {
      dispatch(setActiveTerminal(terminal));
      let currentPath = replaceTerminalNumberParam(terminal.id);
      history.push(currentPath + window.location.search || "");
    }
  };

  const getTerminalsList = () => {
    if (userState.profile?.serviceCenters) {
      return userState.profile.serviceCenters
        .filter(terminal =>
          filter === ""
          || `${terminal.name.toLowerCase()}`.includes(filter.toLowerCase())
          || `${terminal.shortName?.toLowerCase()}`.includes(filter.toLowerCase())
          || `${terminal.id}`.includes(filter))
        .map((terminal, i) => (
          <div
            className={cn("xgs-terminal-selector__list__item")({ "selected": terminal.id === userState.activeTerminal?.id })}
            key={`xgs-terminal-selector-item-${i}`}
            onClick={() => terminalItemClicked(terminal)}
          >
            <div className="xgs-terminal-selector__list__item__block">
              <span className="xgs-terminal-selector__list__item__name">
                {terminal.shortName ? `${terminal.shortName} - ` : ""}{terminal.name}
              </span>
              <span className="xgs-terminal-selector__list__item__id">
                {terminal.id}
              </span>
            </div>
          </div>
        )
      );
    }
  };

  const showDropDown = (): boolean => {
    if (userState?.profile) {
      return userState.profile.serviceCenters?.length > 1;
    }
    return false;
  }

  const openDropdown = () => {
    if (!showDropDown()) return;
    setFilter("");
    setOpened(true);
    showDropDown();
    setTimeout(() => {
      document.getElementById("xgs-terminal-selector__list__filter--js")?.focus();
    }, 200);
  };

  const closeDropdown = () => {
    setOpened(false);
    setTimeout(() => setUnblocked(true), 150);
  };

  const needFilter = () => {
    return userState.profile?.serviceCenters && (userState.profile.serviceCenters.length > 10);
  };

  useEffect(() => {
    if (!userState.activeTerminal?.id && userState.profile?.serviceCenters) {
      const primaryTerminal = userState.profile.serviceCenters.find(terminal => terminal.primary) || userState.profile.serviceCenters[0];
      dispatch(setActiveTerminal(primaryTerminal));
    }
  }, [dispatch, userState.profile?.serviceCenters, userState.activeTerminal]);

  return (
    <>
      {userState.profile?.serviceCenters && (userState.profile?.serviceCenters.length > 0) && (
        <div className="xgs-terminal-selector">
          {opened}
          <div
            className={`xgs-terminal-selector__control ${showDropDown() ? "clickable" : ""}`}
            onClick={() => {
              if (!unblocked) return;
              setUnblocked(false);
              openDropdown();
            }}
          >
            <div className="xgs-terminal-selector__control__content">
              <div className="xgs-terminal-selector__control__content__icon">
                <img src={buildingIcon} alt="terminal" />
              </div>
              <div>
                <div className="xgs-terminal-selector__control__content__header">
                  Current Terminal
                </div>
                <div className="xgs-terminal-selector__control__content__terminal">
                  {userState.activeTerminal?.name}
                </div>
              </div>
            </div>
            {showDropDown() && (
              <div className="xgs-terminal-selector__control__arrow">
                <XGSIcon
                  icon={opened ? Icons.faChevronUp : Icons.faChevronDown}
                />
              </div>
            )}
          </div>
          <DropdownPopup
            opened={opened}
            onClose={closeDropdown}
            className="xgs-terminal-selector__list"
          >
            <span className="xgs-terminal-selector__list__header">Select terminal:</span>
            {needFilter() && (
              <div className="xgs-terminal-selector__list__filter">
                <XGSInput
                  type="text"
                  name="filter"
                  value={filter}
                  placeholder="Search..."
                  onChange={(e) => setFilter(e.currentTarget.value)}
                  id="xgs-terminal-selector__list__filter--js"
                  appendCustomIcon={<img src={searchIcon} alt="search" />}
                />
              </div>
            )}
            <div
              className="xgs-terminal-selector__list__items"
              style={{ maxHeight: needFilter() ? "calc(50vh - 126px)" : "calc(50vh - 70px)" }}
            >
              {getTerminalsList()}
            </div>
          </DropdownPopup>
        </div>
      )}
    </>
  );
};

export default TerminalSelector;
