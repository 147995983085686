import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { initialXgsInlineSwitchState } from "./XgsInlineSwitchState";

export const xgsInlineSwitchSlice = createSlice({
  name: "xgsInlineSwitch",
  initialState: initialXgsInlineSwitchState,
  reducers: {
    resetXgsInlineSwitchState: (state) => initialXgsInlineSwitchState,
    setActiveInline: (state, { payload }) => {
      state.activeInlineName = payload;
    }
  }
});

export const {
  resetXgsInlineSwitchState,
  setActiveInline
} = xgsInlineSwitchSlice.actions;

export const xgsInlineSwitchSelector = (state: IState) => {
  return state.xgsInlineSwitch;
};

const xgsInlineSwitchReducer = xgsInlineSwitchSlice.reducer;
export default xgsInlineSwitchReducer;
