import React from "react";
import "./picklistProgressBar.scss";

interface PicklistProgressBarProps {
    totalItemsCount: number;
    loadedItemsCount: number;
    progressTitle?: string;
}

export const PicklistProgressBar: React.FC<PicklistProgressBarProps> = ({ totalItemsCount, loadedItemsCount, progressTitle }) => {
    let loadingProgress = 0;

    if (totalItemsCount && totalItemsCount > 0 && loadedItemsCount) {
        loadingProgress = Math.floor((loadedItemsCount / totalItemsCount) * 100)
    }
    return (
        <div className="picklist-porgress-bar__container">
            <span className="picklist-porgress-bar__info">
                <span className="picklist-porgress-bar__progress-percentage">{loadingProgress}%</span>
                <span className="picklist-porgress-bar__progress">
                    {loadedItemsCount}/{totalItemsCount}
                    {(progressTitle) ? ` ${progressTitle}`: ""}
                    </span>
            </span>
            <div className={"picklist-porgress-bar__bar" + (loadingProgress === 100 ? " --completed" : "")}>
                <div style={{ width: loadingProgress + "%" }}></div>
            </div>
        </div>
    )
};