import React from "react";

import "./growingTextarea.scss";

export interface GrowingTextareaProps
  extends React.HTMLProps<HTMLTextAreaElement> {  
}

const GrowingTextarea: React.FC<GrowingTextareaProps> = (props) => {
  return (
    <div className="xgs-growing-textarea" data-value={props.value}>
      <textarea
          rows={1}
          {...props}
        />
    </div>
  );
};

export default GrowingTextarea;
