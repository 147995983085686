import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import { PicklistPdfState } from "./picklistsState";
import PicklistsService from "../../../app/data/picklists/PicklistsService";

const initialState: PicklistPdfState = {
  loading: false,
  loadingFailed: false,
  loaded: false
};

const picklistsService = PicklistsService.getInstance();

export const picklistPdfSlice = createSlice({
  name: "picklistPdf",
  initialState,
  reducers: {
    picklistPdfResetState: (state) => initialState,
    picklistPdfLoadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
    },
    picklistPdfLoadingSucceed: (state) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
    },
    picklistPdfLoadingFailed: (state) => {
      state.loading = false;
      state.loadingFailed = true;
    }
  },
});

export const {
  picklistPdfResetState,
  picklistPdfLoadingStarted,
  picklistPdfLoadingSucceed,
  picklistPdfLoadingFailed
} = picklistPdfSlice.actions;

export const picklistPdfSelector = (state: IState) => state.picklistPdf;

export const downloadPicklistPdf = (
  picklistNumber: any
): AppThunk => async (dispatch) => {
  try {
    dispatch(picklistPdfLoadingStarted());
    const response =  await picklistsService.getPicklistPdf({picklistNumber});
    const pdfBlob = new Blob([response.data], { type: "application/pdf" });
    const url = window.URL.createObjectURL(pdfBlob);
    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute(
      "download",
      `picklist_${picklistNumber}.pdf`
    );
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(url);
    dispatch(picklistPdfLoadingSucceed());
  } catch (error) {
    dispatch(picklistPdfLoadingFailed());

  }

};

const picklistPdfReducer = picklistPdfSlice.reducer;
export default picklistPdfReducer;