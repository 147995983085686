import React, { useState, useEffect } from "react";
import * as SmartySDK from "smartystreets-javascript-sdk";
import debounce from "lodash/debounce";
import { LabelModes } from "../molecules/labeled-inputs/labeledInput";
import LabeledSelectInput from "../molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import "./addressLookup.scss";

export interface AddressLookupProps {
  defaultValue?: any;
  isLoading?: boolean;
  label?: string;
  labelMode?: LabelModes;
  onBlur?: () => void;
  onValueChange: (val: any) => void;
  required?: boolean;
  allowReset?: boolean;
}

const smartyCore = SmartySDK.core;
const smartyLookup = SmartySDK.usAutocompletePro.Lookup;
const credentials = new smartyCore.SharedCredentials(process.env.REACT_APP_SMARTY_KEY || "");
const clientBuilder = new smartyCore.ClientBuilder(credentials).withLicenses([process.env.REACT_APP_SMARTY_LICENSE || ""]);
const client = clientBuilder.buildUsAutocompleteProClient();

const AddressLookup: React.FC<AddressLookupProps> = ({
  defaultValue,
  isLoading,
  label,
  labelMode,
  onValueChange,
  required,
  ...props
}) => {
  const [addressLookupStarted, setAddressLookupStarted] = useState<boolean>(false);
  const [addressFieldValue, setAddressFieldValue] = useState<any>("");

  const _locationSearch = (inputValue: string, callback: (params: any) => void) => {
    if (!inputValue) return callback([]);
    setAddressLookupStarted(true);
    let lookup = new smartyLookup(inputValue);
    client.send(lookup).then((response) => {
      // deduplicate addresses (excluding secondary and entries from response)
      const addresses = response.result.map(item => JSON.stringify({
        label: `${item.streetLine}, ${item.city}, ${item.state}, ${item.zipcode}`,
        value: {
          address: item.streetLine,
          city: item.city,
          state: item.state,
          zip: item.zipcode
        }
      }));
      const uniqueAddressesSet = new Set(addresses);
      let uniqueAddresses = Array.from(uniqueAddressesSet);
      uniqueAddresses = uniqueAddresses.map(item => JSON.parse(item));
      callback(uniqueAddresses);
      setAddressLookupStarted(false);
    }).catch((err) => {
      console.log("err:", err);
      setAddressLookupStarted(false);
    });
  };

  const locationSearch = debounce(_locationSearch, 500);

  useEffect(() => {
    setAddressFieldValue(defaultValue);    
  }, [defaultValue]);

  return (
    <LabeledSelectInput
      isLoading={addressLookupStarted || isLoading}
      label={label || "Address Lookup:"}
      labelMode={labelMode || LabelModes.column}      
      name="addressLookup"
      loadOptions={locationSearch}
      options={[]}
      openMenuOnClick={false}
      placeholder="Start typing the address..."
      required={required || false}
      requiredAsteriskDisabled={!required}
      async={true}
      value={addressFieldValue}
      className="xgs-address-lookup"
      onValueChange={(v) => {
        if (!v?.value) {
          if (!props.allowReset) { 
            return; 
          } else {
            setAddressFieldValue(null);
          }
        }

        let val = v.value as unknown as any;
        if (val) {
          setAddressFieldValue({
            label: `${val.address}, ${val.city}, ${val.state}, ${val.zip}`,
            value: {
              address: val.address,
              city: val.city,
              state: val.state,
              zip: val.zip
            }
          });
          onValueChange(val);
        }
      }}
      {...props}
    />
  );
};

export default AddressLookup;
