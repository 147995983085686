import React from "react";
import { cn } from "../../../services/common/className";

import { ConsigneeProfile, ProbillProfile, StopProfile } from "../../../app/data/manifest/manifestProfiles";
import { STOP_STATUSES } from "../../../app/data/manifest/models";

import Collapsible from "../../../ui-components/collapsible/collapsible";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import Stop from "./stop";
import Probill from "./Probill";

import "./manifestDetails.scss";
import { TagColor } from "../../../app/data/common/tagColor";

export interface RouteStopProps {
  stop: StopProfile;
  consignees: ConsigneeProfile[];
}

const RouteStop: React.FC<RouteStopProps> = ({ stop, consignees }) => {
  const shouldShowProbillStatus = stop.stopStatus !== "upcoming";

  const statusColor = (stopStatus: string, itemsCount : number, exceptionsCount: number) => {
    if (stopStatus === STOP_STATUSES.INTERNAL.COMPLETED) {
      if (exceptionsCount === 0) return TagColor.GREEN;
      if (exceptionsCount < itemsCount) return TagColor.RED
      else return TagColor.RED;

    } else if (stopStatus === STOP_STATUSES.INTERNAL.INPROGRESS)  {
      return TagColor.YELLOW
    }

    return TagColor.GREY;
  };

  const consigneeCssStatus = (consignee: ConsigneeProfile) => {
    if (consignee.consigneeExceptionCount) return "has-exceptions";

    const statusPositive = consignee.probills.length === consignee.consigneeStatuses.filter(st => st === "POD" || st === "DRIVER_ACK").length
    const hasStatusPending = -1 < consignee.consigneeStatuses.findIndex(st => st.toUpperCase() === "PENDING")

    if (hasStatusPending && shouldShowProbillStatus) {
      return "in-progress";
    } else if (statusPositive) {
      return "complete";
    }

    return ""
  }

  return (
    <Stop
      name={`Stop ${stop.id}`}
      address={stop.address}
      status={(
        <>
          {stop.stopStatus}
          {!(1 > stop.exceptions) && (<XGSIcon icon={XGSIcons.faExclamationTriangle} size={"sm"} />)}
        </>
      )}
      mods={{ color: statusColor(stop.stopStatus, stop.items, stop.exceptions) }}
    >
      {consignees && consignees[stop.id]?.length && consignees[stop.id].map((consignee: ConsigneeProfile) => (
        <Collapsible
        key={consignee.consigneeNumber}
        mix={cn("manifest-details__stop__collapsible")({status: consigneeCssStatus(consignee)})}
        header={(
          <div className="manifest-details__stop__card-content">
            <h4 className="manifest-details__stop__card-name">{consignee.consigneeName}</h4>

            <div className="manifest-details__stop__card-info" >
              <span className="manifest-details__stop__card-info-item">
                Probills: <b>{consignee.probills.length}</b>
              </span>

              {(consignee.consigneeStatuses.length > 0) ?
                (<span className="manifest-details__stop__card-info-item">POD: <b>{consignee.consigneeStatuses.length}</b></span>)
                : ""
              }

              <span className="manifest-details__stop__card-info-item">
                Items: <b>{consignee.consigneeItemsCount}</b>
              </span>

              {consignee?.consigneeExceptionCount > 0 && (
                <span className={cn("manifest-details__stop__card-info-item")({ "has-exceptions": true })}>
                  Exceptions: <b>{consignee.consigneeExceptionCount}</b>
                </span>
              )}
            </div>
          </div>
          )}
        >
          <div className="manifest-details__stop__list">
            {consignee && consignee.probills.map((probill: ProbillProfile) => (
              <li className="manifest-details__stop__list-item" key={probill.probillNumber}>
                <Probill
                  probill={probill}
                  shouldShowStatus={shouldShowProbillStatus}
                />
              </li>
            ))}
          </div>
      </Collapsible>
      ))}
    </Stop>
  )
}

export default RouteStop;