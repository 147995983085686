import { createSlice } from "@reduxjs/toolkit";
import { AddProbillsToRouteState } from "./routesState";
import { IState } from "../..";
import { AppThunk } from "../../../app/store";
import RoutesService from "../../../app/data/plan-probills/routes/routesService";
import { getRouteStops } from "./routeStopsSlice";

const initialState: AddProbillsToRouteState = {
  loading: false,
  loadingFailed: false,
  loaded: false,
  error: ""
};

const routesService = RoutesService.getInstance();

export const addProbillsToRouteSlice = createSlice({
  name: "addProbillsToRoute",
  initialState,
  reducers: {
    addProbillsToRouteResetState: (state) => initialState,
    addProbillsToRouteLoadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
    },
    addProbillsToRouteLoadingSucceed: (state) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
    },
    addProbillsToRouteLoadingFailed: (state, { payload }) => {
      state.loading = false;
      state.loadingFailed = true;
      state.error = payload;
    },

  },
});

export const {
  addProbillsToRouteResetState,
  addProbillsToRouteLoadingStarted,
  addProbillsToRouteLoadingSucceed,
  addProbillsToRouteLoadingFailed,

} = addProbillsToRouteSlice.actions;

export const addProbillsToRouteSelector = (state: IState) => state.addProbillsToRoute;

export const addProbillsToRoute = (
  reqData: any,
  onSuccess?: () => void,
  onError?: (error: string) => void,
  onClose?: () => void
): AppThunk => async (dispatch) => {
  dispatch(addProbillsToRouteLoadingStarted());
  const response = await routesService.addProbillsToRoute({ ...reqData });
  if (response.ok() && response.data && response.data.result) {
    dispatch(addProbillsToRouteLoadingSucceed());
    dispatch(getRouteStops(reqData));
    onSuccess && onSuccess();
    onClose && onClose()
  } else {
    let error = "Internal Server Error, re-try the action or contact support";
    if (response && response.data && response.data.error) error = response.data.error;
    dispatch(addProbillsToRouteLoadingFailed(error));
    onError && onError(error)
  }
};

const addProbillsToRouteReducer = addProbillsToRouteSlice.reducer;
export default addProbillsToRouteReducer;