import React, { memo } from "react";
import NumberFormat from "react-number-format";

export interface XGSPhoneInputProps
  extends React.HTMLProps<HTMLInputElement> {
  onValueChange: (value: string) => void;
  isFailed?: boolean;
}

export interface NumberFormatValues {
  floatValue: number | undefined;
  formattedValue: string;
  value: string;
}

const XGSPhoneInput: React.FC<XGSPhoneInputProps> = memo((props) => {
  return (
    <div className="xgs-input">
      <NumberFormat
        type="tel"
        name={props.name}
        allowNegative={false}
        isNumericString={false}
        thousandSeparator={false}
        format="(###) ###-####"
        mask="_"
        className={`xgs-input__field ${props.isFailed ? "not-valid" : ""}`}
        value={`${props.value}`}
        onValueChange={(valuesObj: NumberFormatValues) => props.onValueChange(valuesObj.formattedValue || "")}
        onBlur={(e) => {
          props.onBlur && props.onBlur(e);
        }}
        onKeyDown={(e) => {
          props.onKeyDown && props.onKeyDown(e);
        }}
      />
    </div>
  );
});

export default XGSPhoneInput;
