import { AccountOpenHoursType, DayOfWeek, StandingAppointmentDto } from "../../../app/data/standing-appointment/models";

export type StandingAppointmentState = {
  standingAppointments: StandingAppointmentDto | null;
  serviceDays: Record<DayOfWeek, boolean> | null;
  requestCreator: string;
  requestStarted: boolean;
  requestFailed: boolean;
  requestSucceed: boolean;
  requestFailedReason?: string;
  loadingServiceDays: boolean;
  loadingServiceDaysFailed?: boolean;
  loadedServiceDays: boolean;
  loadingServiceDaysFailReason?: string;
  standingAppointmentAutoFill: {
    data: StandingAppointmentDto;
    type: AccountOpenHoursType;
  } | null
}

export const initialStandingAppointmentState: StandingAppointmentState = {
  standingAppointments: null,
  requestCreator: "",
  requestStarted: false,
  requestFailed: false,
  requestSucceed: false,
  loadingServiceDays: false,
  loadedServiceDays: false,
  loadingServiceDaysFailed: false,
  serviceDays: null,
  standingAppointmentAutoFill: null
}