import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import { UserUtils } from "../../../app/data/user/userUtils";
import "./shipmentClaimsNumbers.scss";

export interface ShipmentClaimsNumbersProps {
  claims: number[];
}

const ShipmentClaimsNumbers: React.FC<ShipmentClaimsNumbersProps> = (props) => {
  const userState: UserState = useSelector(userSelector);

  return (
    <div className="xgs-shipment-details__claim-numbers">
      <XGSIcon
        icon={XGSIcons.faExclamationCircle}
        size="sm"
        className="xgs-shipment-details__claim-numbers__icon"
      />
      This shipment has the following claim{props.claims.length > 1 ? "s" : ""} associated with it:&nbsp;
      {UserUtils.isCustomer(userState.profile)
        ? props.claims.map((claimNumber, index) => (
          <span key={"claim-number-" + index}>
             <Link to={`${userState.activeSubAccount?.accountNumber ? "/" + userState.activeSubAccount?.accountNumber : ""}/claims/${claimNumber}`} target="_blank" className="blue-link">{(index ? ", " : "") + claimNumber}</Link>
          </span>
        ))
        : props.claims.join(", ")
      }.
    </div>
  );
};

export default ShipmentClaimsNumbers;
