
export const confirmationModalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 1001
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "24px",
    maxWidth: "520px",
    maxHeight: "90vh",
    width: "fit-content"
  }
};

export const modalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 1001
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "37px 60px 24px 60px",
    maxWidth: "520px",
    maxHeight: "90vh",
    width: "100%"
  }
};

export const wideModalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 1001
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "37px 60px 24px 60px",
    maxWidth: "720px",
    maxHeight: "90vh",
    width: "100%"
  }
};

export default modalStyle;
