import { createSlice } from "@reduxjs/toolkit";
import { StandingAppointmentService } from "../../../app/data/standing-appointment/standingAppointmentService";
import { initialStandingAppointmentState } from "./standingAppointmentState";
import { AppThunk } from "../../../app/store";
import { IState } from "../..";
import { AccountOpenHoursType, StandingAppointmentDto } from "../../../app/data/standing-appointment/models";
import moment from "moment";

const standingAppointmentService = StandingAppointmentService.getInstance();

export const standingAppointmentSlice = createSlice({
  name: "standingAppointment",
  initialState: initialStandingAppointmentState,
  reducers: {
    resetStandingAppointmentState: (state) => initialStandingAppointmentState,
    requestStarted: (state, { payload }) => {
      state.requestCreator = payload;
      state.requestStarted = true;
      state.requestFailed = false;
      state.requestSucceed = false;
    },
    requestSucceed: (state, { payload }) => {
      state.standingAppointments = payload;
      state.requestStarted = false;
      state.requestSucceed = true;
    },
    requestSuccedNoPayload: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
    },
    requestFailed: (state, { payload }) => {
      state.requestFailed = true;
      state.requestStarted = false;
      state.requestFailedReason = payload;
    },
    updateStandingAppointmentLocally: (state, {payload}) => {
      state.standingAppointments = {
        userUpdated: payload.userUpdated,
        dailyHoursMap: payload.dailyHoursMap,
        dateUpdated: moment().format("MM/DD/YYYY hh:mm:ss A")
      };
    },
    startLoadingServiceDays: (state) => {
      state.loadedServiceDays = false;
      state.loadingServiceDays = true;
      state.loadingServiceDaysFailed = false;
    },
    finishLoadingServiceDays: (state, {payload}) => {
      state.serviceDays = payload;
      state.loadedServiceDays = true;
      state.loadingServiceDays = false;
    },
    failedLoadingServiceDays: (state, {payload}) => {
      state.loadedServiceDays = false;
      state.loadingServiceDays = false;
      state.loadingServiceDaysFailed = true;
      state.loadingServiceDaysFailReason = payload;
    },
    finishLoadingAutofillData: (state, {payload}) => {
      state.standingAppointmentAutoFill = payload;
    }
  },
});

export const {
  resetStandingAppointmentState,
  requestFailed,
  requestStarted,
  requestSucceed,
  requestSuccedNoPayload,
  updateStandingAppointmentLocally,
  startLoadingServiceDays,
  failedLoadingServiceDays,
  finishLoadingServiceDays,
  finishLoadingAutofillData
} = standingAppointmentSlice.actions;

export const standingAppointmentSelector = (state: IState) =>
  state.standingAppointment;

export const getServiceDays =
  (accountId: string): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingServiceDays());
    const response = await standingAppointmentService.getServiceDays(accountId);
    if (response.ok()) {
      dispatch(finishLoadingServiceDays(response.data));
    } else {
      response.getError && dispatch(failedLoadingServiceDays(response.getError()));
    }
  }

export const getStandingAppointment =
  (accoundId: string, type: AccountOpenHoursType): AppThunk =>
  async (dispatch) => {
    dispatch(requestStarted("GET"));
    const response = await standingAppointmentService.getStandingAppointment(
      accoundId,
      type
    );
    if (response.ok()) {
      dispatch(requestSucceed(response.data));
    } else {
      if (response.status === 404) {
        dispatch(requestSucceed(null));
      }  else {
        response.getError && dispatch(requestFailed(response.getError()));
      }
    }
  };

export const createStandingAppointment =
  (
    accoundId: string,
    standingAppointmentDto: StandingAppointmentDto,
    type: AccountOpenHoursType,
    onSuccess: () => void,
    onFailed: () => void
  ): AppThunk =>
  async (dispatch) => {
    dispatch(requestStarted("CREATE"));
    const response = await standingAppointmentService.createStandingAppointment(
      accoundId,
      standingAppointmentDto,
      type
    );
    if (response.ok()) {
      dispatch(requestSuccedNoPayload());
      onSuccess();
    } else {
      response.getError && dispatch(requestFailed(response.getError()));
      onFailed();
    }
  };

export const updateStandingAppointment =
  (
    accoundId: string,
    standingAppointmentDto: StandingAppointmentDto,
    type: AccountOpenHoursType,
    onSuccess: () => void,
    onFailed: () => void
  ): AppThunk =>
  async (dispatch) => {
    dispatch(requestStarted("UPDATE"));
    const response = await standingAppointmentService.updateStandingAppointment(
      accoundId,
      standingAppointmentDto,
      type
    );
    if (response.ok()) {
      dispatch(requestSuccedNoPayload());
      onSuccess();
    } else {
      response.getError && dispatch(requestFailed(response.getError()));
      onFailed();
    }
  };

  export const getStandingAppointmentAutofill = (accountId: string): AppThunk =>
  async (dispatch) => {
    const types = [AccountOpenHoursType.BUSINESS, AccountOpenHoursType.RECEIVING, AccountOpenHoursType.SHIPPING];
    const fetchPromises = types.map(type => standingAppointmentService.getStandingAppointment(accountId, type));  
    const responses = await Promise.all(fetchPromises);

    for (let idx = 0; idx < types.length; idx++) {
      let current = responses[idx];
      if (current.ok()) {
        dispatch(finishLoadingAutofillData({
          data: current.data,
          type: types[idx]
        }))
        return;
      }
    }
  };

export const standingAppointmentReducer = standingAppointmentSlice.reducer;
