import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import СompanyService from "../../../app/data/company/companyService";
import UserService from "../../../app/data/user/userService";
import { initialUsersState } from "./TeamUsersState";
import { InvitationDataModel } from "../../../app/data/user/UserProfile";
import { ProfileSettingsRequestModel } from "../../../app/data/user/requestModels";
import cloneDeep from "lodash/cloneDeep";
import { SearchCustomersModel } from "../../../app/data/company/models";

const companyService = СompanyService.getInstance();
const userService = UserService.getInstance();

export const teamUsersSlice = createSlice({
  name: "users",
  initialState: initialUsersState,
  reducers: {
    fetch_was_started: (state, { payload }) => {
      state.requestCreator = payload.creator;
      if (payload.creator === "GET_USERS") {
        if (payload.infinite) {
          state.fetchPortion_was_started = true;
        } else {
          state.fetch_was_started = true;
          state.usersRequest = null;
        }
        state.usersFetchedAll = false;
      } else {
        state.fetch_was_started = true;
      }
      state.fetch_was_succeed = false;
      state.fetch_was_failed = false;
      state.fetch_fail_reason = null;
    },
    fetch_was_succeed: (state) => {
      state.fetch_was_succeed = true;
      state.fetch_was_started = false;
      state.fetchPortion_was_started = false;
      state.fetch_was_failed = false;
    },
    set_users: (state, { payload }) => {
      state.users = payload.responseData.content;
      state.usersRequest = payload.responseData.scroll;
      state.usersFetchedAll = !!(payload.responseData.content.length < 50);
      if (payload.all) state.usersFetchedAll = true;      
    },
    add_users: (state, { payload }) => {
      state.users = [...state.users, ...payload.content];
      state.usersRequest = (payload.content.length < 50) ? null : payload.scroll;
      state.usersFetchedAll = !!(payload.content.length < 50);
    },
    fetch_was_failed: (state, { payload }) => {
      state.fetch_was_failed = true;
      state.fetch_was_succeed = false;
      state.fetch_was_started = false;
      state.fetch_fail_reason = payload;
    },
    update_was_started: (state, { payload }) => {
      state.update_was_started = true;
      state.update_was_succeed = false;
      state.update_was_failed = false;
      state.update_fail_reason = null;
      state.update_creator = payload;
    },
    update_was_succeed: (state) => {
      state.update_was_succeed = true;
      state.update_was_started = false;
      state.update_was_failed = false;
      state.update_creator = undefined;
    },
    update_user: (state, { payload }) => {
      let index = state.users.findIndex(user => user.id === payload.id);
      // we could use 'state.users[index][field] = data' below, 
      // but TS has limitation of accessing fields with possible different types by variable property name
      let user = cloneDeep(state.users[index]);
      if (payload.field !== "serviceAccessLevels") {
        state.users[index] = Object.defineProperty(user, payload.field, {
          configurable: true,
          enumerable: true,
          writable: true,
          value: payload.data
        });
      } else {
        const serviceExistIndex = user.serviceAccessLevels.findIndex(obj => obj.service === payload.data[0].service);
        if (serviceExistIndex !== -1) {
          user.serviceAccessLevels.splice(serviceExistIndex, 1);
        }
        state.users[index] = Object.defineProperty(user, payload.field, {
          configurable: true,
          enumerable: true,
          writable: true,
          value: [
            ...user.serviceAccessLevels,
            ...payload.data
          ]
        });
      }
    },
    update_user_obj: (state, { payload }) => {
      let index = state.users.findIndex(user => user.id === payload.id);
      if (payload.request.email) {
        payload.request.pendingEmail = payload.request.email;
        delete payload.request.email;
      }
      state.users[index] = {...state.users[index], ...payload.request};
    },
    update_user_invite: (state, { payload }) => {
      let index = state.users.findIndex(user => user.id === payload.id);
      state.users[index].invitation = {
        date: payload.data.date,
        link: payload.data.link
      };
      
      // we could use 'state.users[index][field] = data' below, 
      // but TS has limitation of accessing fields with possible different types by variable property name
      // let user = cloneDeep(state.users[index]);
      // state.users[index].invitation = Object.defineProperty(user, "date", {
      //   configurable: true,
      //   enumerable: true,
      //   writable: true,
      //   value: payload.data.date
      // });
      // state.users[index].invitation = Object.defineProperty(user, "link", {
      //   configurable: true,
      //   enumerable: true,
      //   writable: true,
      //   value: payload.data.link
      // });      
    },
    update_was_failed: (state, { payload }) => {
      state.update_was_failed = true;
      state.update_was_succeed = false;
      state.update_was_started = false;
      state.update_fail_reason = payload;
    },
    resetErrors: (state) => {
      state.fetch_was_failed = false;
      state.fetch_fail_reason = null;
      state.update_was_failed = false;
      state.update_fail_reason = null;
    },
    resetUsers: (state) => {
      state.users = [];
      state.usersRequest = null;
    },
    store_user_permissions: (state, { payload }) => {
      state.userPermissions = payload;
    },
    clearUserPermissions: (state) => {
      state.userPermissions = null;
    },
    updateAccountPermissionsLocally: (state, { payload }) => {
      if (payload.isNew) {
        state.userPermissions.unshift({
          account: {
            id: payload.accountId,
            name: payload.accountName,
            accountNumber: payload.accountNumber
          },
          permissions: payload.permissions
        });
      } else {
        const i = state.userPermissions.findIndex((permission: any) => permission.account.id === payload.accountId);
        if (i !== -1) {
          if (payload.permissions.length > 0) {
            state.userPermissions[i].permissions = payload.permissions;
          } else {
            state.userPermissions = state.userPermissions.slice(0, i).concat(state.userPermissions.slice(i + 1, state.userPermissions.length));
          }
        }
      }
    }
  }
});

export const {
  fetch_was_started,
  fetch_was_succeed,  
  fetch_was_failed,
  set_users,
  add_users,
  update_was_started,
  update_was_succeed,
  update_user,
  update_user_obj,
  update_user_invite,
  update_was_failed,
  resetErrors,
  resetUsers,
  store_user_permissions,
  clearUserPermissions,
  updateAccountPermissionsLocally
} = teamUsersSlice.actions;

export const usersSelector = (state: IState) => state.teamUsers;

export const getAllUsers = (
  fullList: boolean,
  search: SearchCustomersModel | undefined,
  lastIds?: string,
): AppThunk => async (dispatch) => {
  dispatch(fetch_was_started({
    creator: "GET_USERS",
    infinite: !!lastIds
  }));
  
  const response = await companyService.getAllUsers(lastIds, search);

  if (response.ok()) {
    dispatch(lastIds
      ? add_users(response.data)
      : set_users({
        responseData: response.data,
        all: fullList
      }));
    dispatch(fetch_was_succeed());
  } else {
    response.getError && dispatch(fetch_was_failed(response.getError()));
  }
};

export const getUsers = (
  teamId: string, // current / {id}
  fullList: boolean,
  searchString: string | undefined,
  lastIds?: string,
): AppThunk => async (dispatch) => {
  dispatch(fetch_was_started({
    creator: "GET_USERS",
    infinite: !!lastIds
  }));
  let response;
  if (teamId === "current") {
    response = await companyService.getCurrentTeamUsers(fullList, searchString, lastIds);
  } else {
    response = await companyService.getTeamUsers(teamId, fullList, searchString, lastIds);
  }
  if (response.ok()) {
    dispatch(lastIds
      ? add_users(response.data)
      : set_users({
        responseData: response.data,
        all: fullList
      }));
    dispatch(fetch_was_succeed());
  } else {
    response.getError && dispatch(fetch_was_failed(response.getError()));
  }
};

export const getUserPermissions = (
  userId: string
): AppThunk => async (dispatch) => {
  dispatch(fetch_was_started({
    creator: "GET_PERMISSIONS",
    infinite: false
  }));  
  const response = await companyService.getUserPermissions(userId);
  if (response.ok()) {
    dispatch(fetch_was_succeed());
    dispatch(store_user_permissions(response.data));
  } else {
    response.getError && dispatch(fetch_was_failed(response.getError()));
  }
};

export const updateUser = (
  userId: string,
  field: string,
  data: any,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(update_was_started(field));
  const request = {};
  // we could use 'request[field] = data' below, but TS has limitation of accessing fields with possible different types by variable property name
  Object.defineProperty(request, field, {
    enumerable: true,
    value: data
  });
  const response = await companyService.updateUser(userId, request);
  if (response.ok()) {
    dispatch(update_user({
      id: userId,
      field: field,
      data: data
    }));
    dispatch(update_was_succeed());
    onSuccess && onSuccess();
  } else {
    response.getError && dispatch(update_was_failed(response.getError()));
  }
};

export const updateUserInviteData = (
  userId: string,
  data: InvitationDataModel,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(update_was_started("invite"));
  dispatch(update_user_invite({
    id: userId,
    data: data
  }));
  dispatch(update_was_succeed());
  onSuccess && onSuccess();
};

export const deleteUser = (
  userId: string,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(update_was_started("delete"));
  const response = await companyService.deleteUser(userId);
  if (response.ok()) {
    dispatch(update_was_succeed());
    onSuccess && onSuccess();
  } else {
    response.getError && dispatch(update_was_failed(response.getError()));
  }
};

export const updateUserProfile = (
  request: ProfileSettingsRequestModel,
  userId: string,
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(update_was_started("UPDATE_PROFILE"));
  const response = await userService.updateProfile(userId, request);
  if (response.ok()) {
    dispatch(update_user_obj({
      id: userId,
      request
    }));    
    dispatch(update_was_succeed());
    onSuccess();
  } else {
    response.getError && dispatch(update_was_failed(response.getError()));
  }
};

export const impersonateUser = (
  userEmail: string,
  onSuccess: (arg: string) => void
): AppThunk => async (dispatch) => {
  dispatch(update_was_started("IMPERSONATE"));
  const response = await userService.impersonateUser(userEmail);
  if (response.ok() && response.data.accessToken) {
    dispatch(update_was_succeed());
    onSuccess(response.data.accessToken);
  } else {
    response.getError && dispatch(update_was_failed(response.getError()));
  }
};

const teamUsersReducer = teamUsersSlice.reducer;
export default teamUsersReducer;
