
export const BOL_TYPES = [
  {
    label: "BOL",
    value: "BOL"
  },
  {
    label: "Returns",
    value: "RETURN"
  }
];

export const PAY_TYPES = [
  {
    label: "Collect",
    value: "COLLECT"
  },
  {
    label: "Prepaid",
    value: "PREPAID"
  },
  {
    label: "Third Party",
    value: "THIRD_PARTY"
  }
];

export const UPLOAD_MAX_SIZE = 4; // MB

export const UPLOAD_FILE_TYPES = {
  "application/pdf": [],
  "application/msword": [], // doc
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], // docx
  "application/vnd.ms-excel": [], // xls
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], //xlsx
  "image/jpeg": [],
  "image/png": []
};

export const RETURN_SERVICES = [
  {
    value: "LIMITED_ACCESS",
    label: "Limited Access",
    description: "When pick-up or delivery service is provided for locations where tractor trailer clearance is restricting."
  },
  {
    value: "LIFTGATE",
    label: "Liftgate",
    description: "Liftgate required in order to accomplish pickup or delivery of a shipment."
  },
  {
    value: "RESIDENTIAL_AREA",
    label: "Residential Delivery",
    description: "Pick up or delivery to a residential area or apartment. (Charges not applicable in conjunction with White Glove Service.)"
  },
  {
    value: "PREDELIVERY_CALL",
    label: "Predelivery Call or Notify Fee",
    description: "Telephone or written notice of arrival of shipment. (Charge not be applied to white glove services.)"
  }
];

export const REQUESTOR_ROLES = [
  {
    label: "Shipper",
    value: "SHIPPER"
  },
  {
    label: "Consignee",
    value: "CONSIGNEE"
  },
  {
    label: "Third Party",
    value: "THIRD_PARTY"
  }
];
