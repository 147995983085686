import React, { DetailedHTMLProps } from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import XGSIcon from "../../icon/xgsIcon";

import "./headerStatus.scss";

export interface HeaderStatusProps
  extends DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  label: string;
  showIcon?: boolean | null;
  tooltip?: string;
  icon?: IconDefinition;
  iconColor?: string;
}

const HeaderStatus: React.FC<HeaderStatusProps> = ({
  label,
  showIcon,
  tooltip,
  icon,
  iconColor,
  ...props
}) => {
  return (
    <div className="xgs-container-status-header" {...props}>
      {showIcon && icon && (
        <div className="iconblock">
          <XGSIcon icon={icon} style={{ color: iconColor }} />
        </div>
      )}        
      <div className="infoblock">
        <label title={tooltip}>{label}</label>
        <span>{props.children}</span>
      </div>
    </div>
  );
};

export default HeaderStatus;
