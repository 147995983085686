export interface DriverSearch {
  driver: {
    driverId: string;
  };
  searchDetails: {
    matchedStr: string;
  }
};

export type DriverSearchResponseModel = DriverSearch[];

export const STOP_STATUSES = {
  INTERNAL: {
    COMPLETED: 'completed',
    INPROGRESS: 'active',
    UPCOMING: 'upcoming',
    INCOMPLETE: 'incomplete',
    UNKNOWN: 'unknown',
  },
  EXTERNAL: {
    COMPLETE: 'complete',
    PENDING: 'pending',
    INCOMPLETE: 'incomplete',
  }
};  