import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import moment from "moment";
import debounce from "lodash/debounce";
import mixpanel from "mixpanel-browser";
import DatePicker from "react-datepicker";
import ContentContainer from "../../templates/content-container/contentContainer";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import LabeledSelectInput from "../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { XGSSelectOption } from "../../ui-components/xgs-select/xgsSelect";
import XGSFormDate from "../../ui-components/form/date/xgsFormDate";
import { LabelModes } from "../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import { weekDays } from "../../ui-components/xgs-date/xgs-date/xgsDate";
import {
  TransitCalculatorModel,
  TransitCalculatorSchema
} from "../../app/data/transit-calculator/models";
import TransitCalcState from "../../slices/transit-calculator/TransitCalculatorState";
import {
  getTransitTime,
  resetTransitCalculatorState,
  searchLocations,
  transitCalculatorSelector
} from "../../slices/transit-calculator/transitCalculatorSlice";
import CommonState from "../../slices/common/CommonState";
import {
  commonSelector,
  getPickupDays
} from "../../slices/common/commonSlice";
import { nextBusinessDay } from "../../hooks/utils";
import "./transitCalculator.scss";

const initialValues: TransitCalculatorModel = {
  originZip: "",
  destinationZip: "",
  tenderDate: ""
};

const operationDays = (dataObj: any) => {
  return (
    <div className="xgs-transit-calc__results__center__days">
      <div className="xgs-transit-calc__results__center__days__label">Service days:</div>
      <div className="flexbox">
        <div className={`xgs-transit-calc__results__center__day ${dataObj["mo"] ? "xgs-transit-calc__results__center__day--open" : ""}`}>Mo</div>
        <div className={`xgs-transit-calc__results__center__day ${dataObj["tu"] ? "xgs-transit-calc__results__center__day--open" : ""}`}>Tu</div>
        <div className={`xgs-transit-calc__results__center__day ${dataObj["we"] ? "xgs-transit-calc__results__center__day--open" : ""}`}>We</div>
        <div className={`xgs-transit-calc__results__center__day ${dataObj["th"] ? "xgs-transit-calc__results__center__day--open" : ""}`}>Th</div>
        <div className={`xgs-transit-calc__results__center__day ${dataObj["fr"] ? "xgs-transit-calc__results__center__day--open" : ""}`}>Fr</div>
        <div className={`xgs-transit-calc__results__center__day ${dataObj["sa"] ? "xgs-transit-calc__results__center__day--open" : ""}`}>Sa</div>
        <div className={`xgs-transit-calc__results__center__day ${dataObj["su"] ? "xgs-transit-calc__results__center__day--open" : ""}`}>Su</div>
      </div>
    </div>
  );
};

const TransitCalculator: React.FC<{}> = (props) => {
  const [originZipFormValue, setOriginZipFormValue] = useState<XGSSelectOption | null>();
  const [destinationZipFormValue, setDestinationZipFormValue] = useState<XGSSelectOption | null>();
  const transitCalcState: TransitCalcState = useSelector(transitCalculatorSelector);
  const commonState: CommonState = useSelector(commonSelector);
  const dispatch = useDispatch();

  const nextServiceCenterWorkDay = () => {
    if (!commonState.pickupDays || Object.keys(commonState.pickupDays).length === 0) return nextBusinessDay();
    let day = moment().add(1, "day"); // default value to avoid TS error (possible undefined)
    for (let i = 1; i < 8; i++) {
      day = moment().add(i, "day");
      let dayOfWeek = day.format("dd").toLowerCase();
      if (commonState.pickupDays[dayOfWeek as keyof weekDays] === true) break;
    }
    return day.format("MM/DD/YYYY");
  };

  const onSubmit = (request: TransitCalculatorModel) => {
    if (request.tenderDate) {
      request.tenderDate = request.tenderDate.toApiDateFormat();
    }
    dispatch(getTransitTime(request));
    mixpanel.track("Transit time calculated");
  };

  let locationSearch = (type: string, query: string) => {
    query?.length > 1 && dispatch(searchLocations(type, query));
  }
  locationSearch = debounce(locationSearch, 300);

  useEffect(() => {
    dispatch(resetTransitCalculatorState());
  }, [dispatch]);

  return (
    <ContentContainer title="Transit Calculator">
      <div className="xgs-transit-calc">
        Enter your information below to calculate transit time.
        <div className="xgs-transit-calc__columns">
          <div className="xgs-transit-calc__form">
            <Formik
              onSubmit={onSubmit}
              initialValues={initialValues}
              validationSchema={TransitCalculatorSchema}
            >
              {(props: FormikProps<TransitCalculatorModel>) => (
                <Form className="form-items">
                  <LabeledSelectInput
                    className="xgs-request-rates__location-field"
                    isDisabled={false}
                    isLoading={transitCalcState.requestStarted && transitCalcState.requestCreator === "ORIGIN"}
                    label="Origin Zip:"
                    labelMode={LabelModes.column}
                    name="originZip"
                    onInputChange={(query) => locationSearch("ORIGIN", query)}
                    onValueChange={(v) => {
                      props.setFieldValue("originZip", v?.value || "");
                      setOriginZipFormValue(v);
                      if (v?.value && /^\d{5}$/.test(v.value)) {
                        dispatch(getPickupDays(v.value));
                      }
                      props.setFieldValue("tenderDate", "")
                    }}
                    openMenuOnClick={transitCalcState.originLocations.length > 0}
                    options={transitCalcState.originLocations}
                    placeholder="Enter Zip or City..."
                    value={originZipFormValue}
                  />
                  <LabeledSelectInput
                    className="xgs-request-rates__location-field"
                    isDisabled={false}
                    isLoading={transitCalcState.requestStarted && transitCalcState.requestCreator === "DESTINATION"}
                    label="Destination Zip:"
                    labelMode={LabelModes.column}
                    name="destinationZip"
                    onInputChange={(query) => locationSearch("DESTINATION", query)}
                    onValueChange={(v) => {
                      props.setFieldValue("destinationZip", v?.value || "");
                      setDestinationZipFormValue(v);
                    }}
                    openMenuOnClick={transitCalcState.destinationLocations.length > 0}
                    options={transitCalcState.destinationLocations}
                    placeholder="Enter Zip or City..."
                    value={destinationZipFormValue}
                  />
                  <XGSFormDate
                    name="tenderDate"
                    label="Tender Date:"
                    onDateChange={(v) => props.setFieldValue("tenderDate", v)}
                    onChange={() => null}
                    minDate={new Date(nextServiceCenterWorkDay())}
                    required={true}
                    requiredAsteriskDisabled={true}
                    labelMode={LabelModes.column}
                    disableWeekends={true}
                    disabledWeekDays={commonState.pickupDays}
                    disabled={!originZipFormValue}
                  />
                  {transitCalcState.requestFailed && (
                    <XGSErrorMessage className="xgs-transit-calc__form__error">{transitCalcState.requestError}</XGSErrorMessage>
                  )}
                  <Button
                    theme={ButtonThemes.blue}
                    className="xgs-transit-calc__form__button"
                    disabled={!props.isValid || !props.dirty}
                    spinner={transitCalcState.requestStarted && transitCalcState.requestCreator === "CALCULATE"}
                  >
                    Calculate Transit
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
          {transitCalcState.result && (
            <div className="xgs-transit-calc__results">
              {transitCalcState.result.error && (
                <div className="xgs-transit-calc__results__error">
                  {transitCalcState.result.error}
                </div>
              )}
              {transitCalcState.result.data && (
                <>
                  <div className="xgs-transit-calc__results__eta">
                    {transitCalcState.result.data.etaDate && (
                      <>
                        <div className="xgs-transit-calc__results__eta__value">ETA date is <strong>{transitCalcState.result.data.etaDate.toUiDateFormat()}</strong></div>
                        <div className="xgs-transit-calc__results__eta__additional">
                          <ul>
                            <li>Please note that ETA dates are calculated based on freight being tendered at origin before 5:00 pm local time.</li>
                            <li>ETA may be affected for deliveries made to residential areas, areas with limited access, or deliveries requiring a predelivery call.</li>
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="xgs-transit-calc__results__center">
                    {transitCalcState.result.data.originCenter && (
                      <>
                        <div className="xgs-transit-calc__results__center__header">Origin Service Center</div>
                        <div className="xgs-transit-calc__results__center__columns">
                          <div className="xgs-transit-calc__results__center__contacts">
                            {transitCalcState.result.data.originCenter.address && <div>{transitCalcState.result.data.originCenter.address}</div>}
                            {transitCalcState.result.data.originCenter.city && <div>{transitCalcState.result.data.originCenter.city}</div>}                        
                            {transitCalcState.result.data.originCenter.phone && (
                              <div className="xgs-transit-calc__results__center__phone">{transitCalcState.result.data.originCenter.phone.formatPhone()}</div>
                            )}
                          </div>
                          {transitCalcState.result.data.originCenter.operationDays && (
                            <>
                              { operationDays(transitCalcState.result.data.originCenter.operationDays) }
                            </>
                          )}
                        </div>
                        {!transitCalcState.result.data.originCenter.address &&
                          !transitCalcState.result.data.originCenter.city &&
                          !transitCalcState.result.data.originCenter.phone &&
                          !transitCalcState.result.data.originCenter.operationDays && (
                            <>
                              No data found for the provided Origin Zip.
                            </>
                        )}
                      </>
                    )}
                  </div>
                  {transitCalcState.result.data.tenderDateLiteral && transitCalcState.result.data.etaDate && (
                    <div
                      className="xgs-transit-calc__results__date-range"
                      onClick={(e) => { e.stopPropagation(); }}
                    >
                      <DatePicker
                        onChange={() => {}}
                        selected={new Date(transitCalcState.result.data.tenderDateLiteral + "T00:00:00")}
                        startDate={new Date(transitCalcState.result.data.tenderDateLiteral + "T00:00:00")}
                        endDate={new Date(transitCalcState.result.data.etaDate + "T00:00:00")}
                        inline
                        readOnly
                        disabled
                        disabledKeyboardNavigation
                        monthsShown={moment(transitCalcState.result.data.tenderDateLiteral + "T00:00:00").month() === moment(transitCalcState.result.data.etaDate + "T00:00:00").month() ? 1 : 2}
                        shouldCloseOnSelect={false}
                        focusSelectedMonth={false}
                        selectsRange
                        calendarStartDay={1}
                      />
                    </div>
                  )}
                  <div className="xgs-transit-calc__results__center">
                    {transitCalcState.result.data.destinationCenter && (
                      <>
                        <div className="xgs-transit-calc__results__center__header">Destination Service Center</div>
                        <div className="xgs-transit-calc__results__center__columns">
                          <div className="xgs-transit-calc__results__center__contacts">
                            {transitCalcState.result.data.destinationCenter.address && <div>{transitCalcState.result.data.destinationCenter.address}</div>}
                            {transitCalcState.result.data.destinationCenter.city && <div>{transitCalcState.result.data.destinationCenter.city}</div>}
                            {transitCalcState.result.data.destinationCenter.phone && (
                              <div className="xgs-transit-calc__results__center__phone">{transitCalcState.result.data.destinationCenter.phone.formatPhone()}</div>
                            )}
                          </div>
                          {transitCalcState.result.data.destinationCenter.operationDays && (
                            <>
                              { operationDays(transitCalcState.result.data.destinationCenter.operationDays) }
                            </>
                          )}
                        </div>
                        {!transitCalcState.result.data.destinationCenter.address &&
                          !transitCalcState.result.data.destinationCenter.city &&
                          !transitCalcState.result.data.destinationCenter.phone &&
                          !transitCalcState.result.data.destinationCenter.operationDays && (
                            <>
                              No data found for the provided Destination Zip.
                            </>
                        )}                        
                      </>
                    )}
                  </div>
                  <div className="xgs-transit-calc__results__customer-service">
                    <div><strong>National Customer Service</strong></div>
                    <div>Contact us at 844-XGS-SHIP (947-7447)</div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </ContentContainer>
  );
};

export default TransitCalculator;
