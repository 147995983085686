import React from "react";
import { ReactComponent as ViewIcon } from "../../../images/view.svg";
import { cn } from "../../../services/common/className";
import UserProfile from "../../../app/data/user/UserProfile";

import "./userMenu.scss";

export interface UserpickProps {
  profile?: UserProfile | null,
};

const Userpick: React.FC<UserpickProps> = (props) => {
  return (
    <div className={cn("xgs-userpick")({ impersonated: props.profile?.impersonated })}>
      {props.profile?.impersonated ? (
        <ViewIcon />
      ) : (
        props.profile?.firstName.slice(0, 1)
      )}
    </div>
  );
}

export default Userpick;