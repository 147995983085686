import React from "react";
import Invoice from "../../../app/data/invoice/models";
import CustomCell, { CustomCellTypes } from "../../../ui-components/table/custom-cells/customCell";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import * as styles from "./../../../sass/variables.module.scss";

const getAgingInvoicesColumns = (
  getInvoiceDetailsUrl: (row: Invoice) => string
) => [
  {
    id: "selection",
    Header: "",
    width: 48,
    Cell: ({ row }: any) => (
      <div>
        {!row.values.paid && (
          <input
            type="checkbox"
            onClick={(e) => e.stopPropagation()}
            {...row.getToggleRowSelectedProps()}
            indeterminate={undefined}
          />
        )}
      </div>
    )
  },
  {
    Header: "Invoice Number",
    accessor: "invoiceNumber",
    width: 94,
    Cell: (cellProps: any) => (
      <CustomCell
        cellType={CustomCellTypes.link}
        selector="invoiceNumber"
        data={cellProps.row.original}
        value={cellProps.value}
        options={{ url: getInvoiceDetailsUrl(cellProps.row.original) }}
      />
    )
  },
  {
    Header: (
      <div style={{ textAlign: "center" }}>
        Paid
      </div>
    ),
    accessor: "paid",
    width: 70,
    Cell: (cellProps: any) => (
      <>
        {cellProps.row.original.paid && (
          <div style={{ textAlign: "center" }}>
            <XGSIcon
              icon={XGSIcons.faCheckCircle}
              color={styles.green1}
            />
          </div>
        )}
      </>
    )
  },
  {
    Header: "Invoice Date",
    accessor: "dateInvoice",
    width: 96,
    Cell: (cellProps: any) => (
      <CustomCell
        cellType={CustomCellTypes.date}
        selector="dateInvoice"
        data={cellProps.row.original}
        value={cellProps.value}
      />
    )
  },
  {
    Header: (
      <div style={{ textAlign: "right" }}>
        Amount
      </div>
    ),
    accessor: "invoiceAmount",
    Cell: (cellProps: any) => (
      <div style={{ textAlign: "right" }}>
        <CustomCell
          cellType={CustomCellTypes.amount}
          selector="invoiceAmount"
          data={cellProps.row.original}
          value={cellProps.value}
        />
      </div>
    ),
    width: 178
  },
  {
    Header: (
      <div style={{ textAlign: "right" }}>
        Open Amount
      </div>
    ),    
    accessor: "openAmount",
    Cell: (cellProps: any) => (
      <div style={{ textAlign: "right" }}>
        <CustomCell
          cellType={CustomCellTypes.amount}
          selector="openAmount"
          data={cellProps.row.original}
          value={cellProps.value}
        />
      </div>
    ),
    width: 178
  },
  {
    Header: "Due Date",
    accessor: "dateDue",
    width: 118,
    Cell: (cellProps: any) => (
      <CustomCell
        cellType={CustomCellTypes.date}
        selector="dateDue"
        data={cellProps.row.original}
        value={cellProps.value}
      />
    )
  },
  {
    Header: "Bill of Lading",
    accessor: "bolNumber",
    width: 150
  },
  {
    Header: "PO Number",
    accessor: "poNumber",
    width: 150
  },
];

export default getAgingInvoicesColumns;
