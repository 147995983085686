import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Table from "../../ui-components/table/table";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import XGSIcon from "../../ui-components/icon/xgsIcon";
import XGSIcons from "../../ui-components/icon/xgsIcons";
import TeamShippersState from "../../slices/customers/teams/TeamShippersState";
import {
  getShippers,
  resetErrors,
  teamShippersSelector
} from "../../slices/customers/teams/teamShippersSlice";
import AddShippers from "./add-shippers/addShippers";
import RemoveShipper from "./remove-shipper/removeShipper";
import { ERROR_MESSAGES } from "../../app/data/common/errorMessages";
import { SHIPPER_SOURCE } from "../../app/data/shipper/constants";
import "./company.scss";

export interface TeamShippersProps {
  id: string | undefined;
}

const TeamShippers: React.FC<TeamShippersProps> = (props) => {
  const dispatch = useDispatch();
  const teamShippersState: TeamShippersState = useSelector(teamShippersSelector);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [removeAccountId, setRemoveAccountId] = useState("");

  const columns = [
    {
      width: 96,
      minWidth: 96,
      Header: "Account #",
      accessor: "accountNumber"
    },
    {
      width: 144,
      minWidth: 144,
      Header: "Source",
      accessor: (row: any) => {
        switch(row.source) {
          case SHIPPER_SOURCE.CUSTOMER: return "Customer request";
          case SHIPPER_SOURCE.AS400: return "AS400";
          default: return "";
        }        
      },
    },
    {
      width: 176,
      minWidth: 160,
      Header: "Customer name",
      accessor: "name"
    },
    {
      width: 200,
      minWidth: 200,
      Header: "Address",
      id: "addressColumn",
      Cell: ({ row }: any) => (
        <span>
          {row.original.address?.line1 ? `${row.original.address.line1}, ` : ""}{row.original.address?.line2 ? `${row.original.address.line2}, ` : ""}{row.original.address?.city ? `${row.original.address.city}, ` : ""}{row.original.address?.state ? `${row.original.address.state}, ` : ""}{row.original.address?.zip}
        </span>
      )
    },
    {
      width: 52,
      minWidth: 52,
      Header: (
        <div style={{ textAlign: "center" }}>
          &nbsp;
        </div>
      ),
      id: "actionColumn",
      Cell: ({ row }: any) => {
        if (row.original.source !== SHIPPER_SOURCE.CUSTOMER) return "";

        return (
        <div style={{ textAlign: "center" }}>
          <Button
            className="xgs-company__small-button"
            type="button"
            theme={ButtonThemes.gray}
            spinner={false}
            onClick={(e) => {
              e.stopPropagation();
              setRemoveAccountId(row.original.id);
              setShowRemoveConfirmation(true);
            }}>
            <XGSIcon
              icon={XGSIcons.faTimes}
              size="sm"
            />
          </Button>
        </div>
      )}
    }
  ];

  const onListInfiniteScroll = () => {
    if (!props.id || teamShippersState.shippersRequest?.last) return;
    const nextPage = teamShippersState.shippersRequest?.page + 1;
    dispatch(getShippers(props.id, nextPage));
  };

  useEffect(() => {
    if (!props.id) return;
    dispatch(resetErrors());
    dispatch(getShippers(props.id));
  }, [dispatch, props.id]);

  useEffect(() => {
    if (!teamShippersState.request_was_failed || teamShippersState.request_fail_code === 400) return;
    toast.error(teamShippersState.request_fail_reason || ERROR_MESSAGES.COMMON);
    dispatch(resetErrors());
  }, [dispatch, teamShippersState.request_was_failed, teamShippersState.request_fail_reason, teamShippersState.request_fail_code]);

  useEffect(() => {
    if (!teamShippersState.shippers_fetch_was_failed) return;
    toast.error(teamShippersState.shippers_fetch_fail_reason || ERROR_MESSAGES.COMMON);
    dispatch(resetErrors());
  }, [dispatch, teamShippersState.shippers_fetch_was_failed, teamShippersState.shippers_fetch_fail_reason]);

  return (
    <div>
      <div
        className="xgs-teams__details__add-button blue-link"
        onClick={() => {
          setShowAddPopup(true);
        }}
      >
        <XGSIcon
          icon={XGSIcons.faPlusCircle}
          size="sm"
          className="xgs-teams__details__add-button__icon"
        />
        Add shippers
      </div>
      {teamShippersState.shippers_fetch_was_failed && (
        <XGSErrorMessage>{teamShippersState.shippers_fetch_fail_reason}</XGSErrorMessage>
      )}
      {!teamShippersState.shippers_fetch_was_failed && (
        <div className="xgs-teams__details__table-wrapper">
          <div className="xgs-teams__details__table">
            <Table
              autoHeight={true}
              isLoading={teamShippersState.shippers_fetch_was_started}
              columns={columns}
              data={teamShippersState.shippers}
              rowHeight={78}
              minTableHeight={420}
              noResultsText="There are no shippers"
              infiniteScroll={true}
              infiniteScrollLoading={teamShippersState.shippers_fetchPortion_was_started}
              infiniteScrollHasNext={!teamShippersState.shippersRequest?.last}
              onInfiniteScroll={onListInfiniteScroll}
              responsive={true}
            />
          </div>
        </div>
      )}
      {props.id && (
        <>
          <RemoveShipper
            teamId={props.id}
            accountId={removeAccountId}
            show={showRemoveConfirmation}
            onClose={() => {
              setShowRemoveConfirmation(false);
              setRemoveAccountId("");
            }} />
          <AddShippers
            teamId={props.id}
            show={showAddPopup}
            onShippersAdded={() => {
              props.id && dispatch(getShippers(props.id));
            }}
            onClose={() => {
              setShowAddPopup(false);
            }} />
        </>
      )}
    </div>
  );
};

export default TeamShippers;
