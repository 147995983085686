import styled from "styled-components";

export const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;
export const ContentItemTitle = styled.div`
  font-size: 18px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

