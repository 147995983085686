import api, { ApiResponse } from "./../api";
import {
  ClaimModel,
  ClaimSearchModel,
  EditClaimFormModel
} from "./models";

class ClaimsService {
  private static instance: ClaimsService;
  private constructor() {}
  static getInstance(): ClaimsService {
    if (!ClaimsService.instance) {
      ClaimsService.instance = new ClaimsService();
    }
    return ClaimsService.instance;
  }

  public getClaimsList = async (
    searchParams: ClaimSearchModel | null,
    lastIds?: string
  ): Promise<ApiResponse<any>> => {
    let params = {
      ...(lastIds && {lastIds}),
      ...searchParams
    };
    return await api.get("/claims", {params});
  };

  public uploadClaimDocument = async (
    data: FormData
  ): Promise<ApiResponse<any>> => {
    return await api.post("/claims/document", data, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  };

  public submitClaim = async (
    claim: ClaimModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/claims", claim);
  };

  public getClaimDetails = async (
    claimNumber: number
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/claims/${claimNumber}`);
  };

  public downloadDocument = async (
    claimNumber: number,
    id: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/claims/${claimNumber}/documents/${id}`, {
      responseType: "blob"
    });
  };

  public editClaim = async (
    id: string,
    claim: EditClaimFormModel
  ): Promise<ApiResponse<any>> => {
    return await api.put(`/claims/${id}`, claim);
  };
}

export default ClaimsService;
