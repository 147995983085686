import React from "react";
import "./pickupManagement.scss";

export interface PickupDetailsProps {
  header?: string;
  content: {
    label?: string | React.ReactNode;
    value: React.ReactNode;
  }[];
  oneLine?: boolean;
};

const PickupDetailsBlock: React.FC<PickupDetailsBlockProps> = (props) => {
  return (
    <div className="xgs-pickup-assignment__details__block">
      {props.header && (
        <div className="xgs-pickup-assignment__details__block__header">
          {props.header}
        </div>
      )}
      <div className={`xgs-pickup-assignment__details__block__content ${props.oneLine ? "xgs-pickup-assignment__details__block__content--one-line" : ""}`}>
        {props.content && props.content.length > 0 && (
          <>
            {props.content.map((item, i) => (
              <div
                className="xgs-pickup-assignment__details__block__content__pair"
                key={"line-" + i}
              >
                {item.label && (
                  <div className="xgs-pickup-assignment__details__block__content__label">
                    {typeof item.label === "string" ? `${item.label}:` : item.label}
                  </div>
                )}
                <div className="xgs-pickup-assignment__details__block__content__value">
                  {item.value}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default PickupDetailsBlock;
