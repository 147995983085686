import React from 'react';
import moment from 'moment';
import XGSIcon from '../../../ui-components/icon/xgsIcon';
import XGSIcons from '../../../ui-components/icon/xgsIcons';
import {arrangeName} from "../../../hooks/utils";

export interface DriverInfoProps {
  driverName: string
  trailerNumber: number
  currentStop: string
  driverId?: number
  dispatchedDate: string
}

const DriverInfo: React.FC<DriverInfoProps> = (props) => {

  const date = moment(props.dispatchedDate, "MMM D YYYY hh:mm");

  const driverInfo = (
    <>
      <div>
        <span className="driver-info-item">
        <span className="manifest-details__label">Driver:</span>
          {arrangeName(props.driverName)}
        </span>
        <span className="driver-info-item">
        <span className="manifest-details__label">Trailer:</span>         
          {props.trailerNumber}
        </span>
      </div>
      <div>
        <div className="driver-info-item">
          <span className="manifest-details__label">Dispatch date:</span>
           {date.format("MMM D, YYYY")} 
           &nbsp;
           <span className="text-light">{date.format("h:mm A")}</span>        
        </div>
        <span className="driver-info-item --route">
          {props.currentStop ? (
            <>
              <span className="manifest-details__label">Next stop:</span> 
              {props.currentStop}
              {props.driverId && (<XGSIcon icon={XGSIcons.faExternalLinkAlt} size={'sm'}/>)}
            </>
          ) : (
            <>
              <XGSIcon icon={XGSIcons.faCheck} size={'sm'} />
              route completed
            </>
          )}
        </span>
      </div>
    </>
  )

  return props.driverId ? (
    <a className="xgs-manifest__details-view__driver-info xgs-manifest__details-view__driver-info--active"
       href={"https://cloud.samsara.com/o/25236/fleet/drivers/" + props.driverId}
       target="_blank"
       rel="noopener noreferrer"
    >
      {driverInfo}
    </a>
  ) : (
    <div className="xgs-manifest__details-view__driver-info">{driverInfo}</div>
  )
}

export default DriverInfo