export interface CollectionResponse<T> {
  content: T[]
}

export interface InfiniteScrollCollectionResponse<T> {
  results: T[];
  count: number;
  lastIds: string | number;
}

export interface InfiniteScrollCollectionContentResponse<T> {
  content: T[];
  scroll: {
    lastIds: string;
  };
}

export interface InfiniteScrollBaseRequest {
  lastIds?: string | number | null;
}

export interface Contact {
  number: number;
  phone: string;
  info: string;
}

export interface NameIdModel {
  id: number;
  name: string;
}

export interface LocationDataModel {
  zip: string;
  city: string;
  state: string;
  direct: boolean;
};

export interface StepProps {
  next?: () => void;
  previous?: () => void;
  push?: (id: string) => void;
  go?: (n: number) => void;
};

export interface Terminal {
  id: number;
  name: string;
  shortName?: string;
  primary?: boolean;
};

export interface PaginationModel {
  page: number,
  last: boolean,
};

export interface PageResponseModel<Type> {
  content: Type[],
  pageable: {
    pageNumber: number,
  },
  last: boolean,
}

export interface ItemModel {
  packageType: string;
  units?: number;
  yards?: number;
  weight?: number;
  length?: number;
  width?: number;
  height?: number;
  ratingClass: string;
  description?: string;
  sidemark?: string;
  notes?: string;
  deleted?: boolean; // used to keep index of item in edit mode
};

export enum SortOrder {
  asc = "asc",
  desc = "desc",
}

export interface SortParams {
  sortBy: string;
  order: SortOrder;
}