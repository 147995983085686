import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ContentContainer from "../../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import Table from "../../../ui-components/table/table";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import DownloadButton from "../../../ui-components/download-button/downloadButton";
import {
  recognitionLogsSelector,
  getLogfile,
  getRecognitionLogsRecords
} from "../../../slices/documents/recognitionLogsSlice";
import "./recognitionLogs.scss";

const RecognitionLogs: React.FC<{}> = (props) => {
  const recognitionLogsState = useSelector(recognitionLogsSelector);
  const dispatch = useDispatch();
  const [downloadFilename, setDownloadFilename] = useState<string>("");

  const columns = [
    {
      width: 160,
      minWidth: 160,
      Header: "Date",
      accessor: "datetime",
      Cell: (cellProps: any) => (
        <div>
          {cellProps.value ? cellProps.value.toUiDateFormat() : ""}
          {<span className="text-light xgs-table__cell-subitem">{moment(cellProps.value, "hh:mm:ss").format("h:mm A")}</span>}
        </div>
      )
    },
    {
      width: 480,
      minWidth: 220,
      Header: "File Name",
      accessor: "filename"
    },
    {
      width: 200,
      minWidth: 200,
      Header: "Attachment",
      id: "attachment",
      Cell: ({ row }: any) => (
        <>
          <DownloadButton
            title="Download File"
            onClick={() => !recognitionLogsState.requestStarted && downloadLogfile(row.values.filename)}
            spinner={recognitionLogsState.requestStarted && (recognitionLogsState.requestCreator === "GET_LOGFILE") && (row.values.filename === downloadFilename)}
          />
        </>
      )
    }
  ];

  const downloadLogfile = (filename: string) => {
    setDownloadFilename(filename);
    dispatch(getLogfile(filename, (file: Blob) => {
      let documentfileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = documentfileURL;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloadFilename("");
    }));
  };

  useEffect(() => {
    dispatch(getRecognitionLogsRecords());
  }, [dispatch]);

  return (   
      <ContentContainer
        className="xgs-list xgs-list--full-width"
        mods={{ "full-width": true }}
        titleComponent={
          <ContentContainerToolbar
            title="Recognition Logs"
            mods={{ "full-width": true }}
          />
        }
      >
        <div className="xgs-documents__recognition-logs">
          {(recognitionLogsState.requestFailed && recognitionLogsState.requestFailStatus && recognitionLogsState.requestCreator === "GET_RECORDS") && (
            <XGSErrorMessage>{recognitionLogsState.requestError}</XGSErrorMessage>
          )}
          {!(recognitionLogsState.requestFailed && recognitionLogsState.requestFailStatus && recognitionLogsState.requestCreator === "GET_RECORDS") && (
            <Table
              isLoading={recognitionLogsState.requestStarted && recognitionLogsState.requestCreator === "GET_RECORDS"}
              keepTableOnLoading
              columns={columns}
              data={recognitionLogsState.records}
              rowHeight={56}
              minTableHeight={240}
              noResultsText="There are no records"
              responsive
              infiniteScroll
            />
          )}
        </div>
      </ContentContainer>
    );
};

export default RecognitionLogs;