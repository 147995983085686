import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export interface ChatMessageModel {
  text?: string;
  incoming?: boolean;
  promptId?: string;
  prompts?: ChatPromptModel[];
  newGroup?: boolean;
};

export interface ChatFormModel {
  probillNumber: string;
}

export interface ChatPromptModel {
  promptId: string;
  promptMessage: string;
}

export interface ChatMessageRequestModel {
  probillNumber: number | null;
  chatId: string | null;
  query?: string;
  promptId?: string;
}

export interface ChatMessageResponseModel {
  chatId: string,
  responseMessage: string,
  chatPrompts?: ChatPromptModel[],
}

export const ChatFormSchema: Yup.ObjectSchema<ChatFormModel> = Yup.object().shape({  
  probillNumber: Yup.string()
  .matches(/^\d+$/, validationMessages.notValid)
  .min(8, "The value must be no less than 8 digits")
  .typeError(validationMessages.notValid)
  .required(validationMessages.required),
}).defined()