import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import CompanySettingsService from "../../app/data/company-settings/companySettingsService";
import { initialCompanySettingsUsersState } from "./CompanySettingsUsersState";
import cloneDeep from "lodash/cloneDeep";

const companySettingsService = CompanySettingsService.getInstance();

export const companySettingsUsersSlice = createSlice({
  name: "companySettingsUsers",
  initialState: initialCompanySettingsUsersState,
  reducers: {
    fetch_was_started: (state, { payload }) => {
      if (payload) {
        state.fetchPortion_was_started = true;
      } else {
        state.fetch_was_started = true;
      }
      state.fetch_was_succeed = false;
      state.fetch_was_failed = false;
      state.fetch_fail_reason = null;
      state.usersFetchedAll = false;
    },
    fetch_was_succeed: (state) => {
      state.fetch_was_succeed = true;
      state.fetch_was_started = false;
      state.fetchPortion_was_started = false;
      state.fetch_was_failed = false;
    },
    set_users: (state, { payload }) => {
      state.users = payload.responseData.content;
      state.usersRequest = payload.responseData.scroll;
      if (payload.responseData.all) state.usersFetchedAll = true;
    },
    add_users: (state, { payload }) => {
      state.users = [...state.users, ...payload.content];
      state.usersRequest = payload.scroll;
      state.usersFetchedAll = !!(payload.content.length < 50);
    },
    fetch_was_failed: (state, { payload }) => {
      state.fetch_was_failed = true;
      state.fetch_was_succeed = false;
      state.fetch_was_started = false;
      state.fetch_fail_reason = payload;
    },
    update_was_started: (state, { payload }) => {
      state.update_was_started = true;
      state.update_was_succeed = false;
      state.update_was_failed = false;
      state.update_fail_reason = null;
      state.update_creator = payload;
    },
    update_was_succeed: (state) => {
      state.update_was_succeed = true;
      state.update_was_started = false;
      state.update_was_failed = false;
      state.update_creator = undefined;
    },
    update_user: (state, { payload }) => {
      let index = state.users.findIndex(user => user.id === payload.id);
      // we could use 'state.users[index][field] = data' below, 
      // but TS has limitation of accessing fields with possible different types by variable property name
      let user = cloneDeep(state.users[index]);
      state.users[index] = Object.defineProperty(user, payload.field, {
        configurable: true,
        enumerable: true,
        writable: true,
        value: payload.data
      });
    },    
    update_was_failed: (state, { payload }) => {
      state.update_was_failed = true;
      state.update_was_succeed = false;
      state.update_was_started = false;
      state.update_fail_reason = payload;
    },
    resetErrors: (state) => {
      state.fetch_was_failed = false;
      state.fetch_fail_reason = null;
      state.update_was_failed = false;
      state.update_fail_reason = null;
    },
    resetUsers: (state) => {
      state.users = [];
      state.usersRequest = null;
    }
  }
});

export const {
  fetch_was_started,
  fetch_was_succeed,
  set_users,
  add_users,
  fetch_was_failed,
  update_was_started,
  update_was_succeed,
  update_user,
  update_was_failed,
  resetErrors,
  resetUsers
} = companySettingsUsersSlice.actions;

export const companySettingsUsersSelector = (state: IState) => state.companySettingsUsers;

export const getUsers = (
  all: boolean,
  lastIds?: string
): AppThunk => async (dispatch) => {
  dispatch(fetch_was_started(!!lastIds));
  const response = await companySettingsService.getUsers(all, lastIds && lastIds);
  if (response.ok()) {
    dispatch(fetch_was_succeed());
    dispatch(lastIds
      ? add_users(response.data)
      : set_users({
        responseData: response.data,
        all: all
      }));
  } else {
    response.getError && dispatch(fetch_was_failed(response.getError()));
  }
};

export const updateUser = (
  userId: string,
  field: string,
  data: string,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(update_was_started(field));
  const request = {};
  // we could use 'request[field] = data' below, but TS has limitation of accessing fields with possible different types by variable property name
  Object.defineProperty(request, field, {
    enumerable: true,
    value: data
  });
  const response = await companySettingsService.updateUser(userId, request);
  if (response.ok()) {
    dispatch(update_user({
      id: userId,
      field: field,
      data: data
    }));
    dispatch(update_was_succeed());
    onSuccess && onSuccess();
  } else {
    response.getError && dispatch(update_was_failed(response.getError()));
  }
};

const companySettingsUsersReducer = companySettingsUsersSlice.reducer;
export default companySettingsUsersReducer;
