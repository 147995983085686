import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";
import { Form, Formik, FormikProps } from "formik";
import Modal from "react-modal";
import modalStyle from "../../../../app/data/common/modalStyle";
import InvoiceDocumentsState from "../../../../slices/invoice-documents/InvoiceDocumentsState";
import {
  downloadAllDocuments,
  downloadDocument,
  getInvoiceDocuments,
  invoiceDocumentsSelector,
  resetInvoiceDocuments,
  clearDownloadedFile,
  sendAllDocuments
} from "../../../../slices/invoice-documents/invoiceDocumentsSlice";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import Loading from "../../../../ui-components/loading/loading";
import XGSFormInput from "../../../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../../../ui-components/molecules/labeled-inputs/labeledInput";
import { userSelector } from "../../../../slices/user/userSlice";
import UserState from "../../../../slices/user/UserState";
import { UserUtils } from "../../../../app/data/user/userUtils";
import {
  EmailDocumentsModel,
  EmailDocumentsSchema
} from "../../../../app/data/invoice/models";
import papersImage from "../../../../images/papers.svg";
import "./invoiceDetailsDocuments.scss";

export interface InvoiceDetailsDocumentsProps {
  invoiceNumber: number;
};

const documentTypes = [
  {
    name: "BOL",
    title: "BOL"
  },
  {
    name: "INV",
    title: "Invoice"
  },
  {
    name: "ATH",
    title: "ATH"
  },
  {
    name: "POD",
    title: "POD"
  }
];

Modal.setAppElement("#root");

let initialValues: EmailDocumentsModel = {
  email: ""
};

const InvoiceDetailsDocuments: React.FC<InvoiceDetailsDocumentsProps> = (props) => {
  const invoiceDocumentsState: InvoiceDocumentsState = useSelector(invoiceDocumentsSelector);
  const userState: UserState = useSelector(userSelector);
  const dispatch = useDispatch();
  const [currentDownloadId, setCurrentDownloadId] = useState<number | null>(null);
  const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
  const [documentsEmail, setDocumentsEmail] = useState<string>("");
  const emailDocumentsRef = useRef<any>(null);

  const onClickDownload = (id: number, documentType: string, fileType: string) => {
    setCurrentDownloadId(id);
    dispatch(downloadDocument(id, fileType, (link: string) => {
      let documentWindow = window.open(link, "_blank");
      if (documentWindow === null || typeof(documentWindow) === "undefined") {
        // if new window was blocked by a system
        toast.error("To view the document, you must turn off the pop-up blocking in your browser.", {
          autoClose: 7000
        });
      }
      setCurrentDownloadId(null);
      dispatch(clearDownloadedFile());
    }));
  };

  const onClickSendEmail = (data: EmailDocumentsModel) => {
    dispatch(sendAllDocuments(props.invoiceNumber, data.email, () => {
      setShowEmailModal(false);
      toast.info("The documents were sent via email!", { autoClose: 3000 });
      mixpanel.track("All invoice's documents were sent via email");
    }));
  };


  const onShowEmailModal = () => {
    setShowEmailModal(true);
    let email = userState.profile?.email || "";
    if (email) {
      setTimeout(() => {
        emailDocumentsRef.current?.setFieldValue("email", email);
      }, 100);
      setDocumentsEmail(email);
    }
  };

  const onClickDownloadAll = () => {
    dispatch(downloadAllDocuments(props.invoiceNumber, (file: Blob) => {
      let documentfileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = documentfileURL;
      link.download = `${props.invoiceNumber}_documents.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      mixpanel.track("All invoice's documents were downloaded (PDF)");
    }));
  };

  useEffect(() => {
    if (props.invoiceNumber) dispatch(getInvoiceDocuments(props.invoiceNumber));
  }, [dispatch, props.invoiceNumber]);

  useEffect(() => {
    if (invoiceDocumentsState.requestStarted) return;
    invoiceDocumentsState.requestFailed && toast.error(invoiceDocumentsState.requestError || "Error. Please try later or contact support.");
  }, [invoiceDocumentsState.requestStarted, invoiceDocumentsState.requestFailed, invoiceDocumentsState.requestError]);

  useEffect(() => {
    return () => {
      dispatch(resetInvoiceDocuments());
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="xgs-invoice-documents">
      {invoiceDocumentsState.requestStarted && invoiceDocumentsState.requestCreator === "list" && (
        <div className="xgs-invoice-documents__loading">
          <Loading isLoading={true} />
        </div>
      )}
      {(invoiceDocumentsState.requestSucceed || invoiceDocumentsState.documents) && (
        <>
          {(!invoiceDocumentsState.documents || invoiceDocumentsState.documents.length === 0)
          ? (
            <div className="xgs-invoice-documents__no-items">There are no imaged documents</div>
          )
          : (
            <div className="xgs-invoice-documents__items">
              {invoiceDocumentsState.documents && invoiceDocumentsState.documents.length > 1 && (
                <div className="xgs-invoice-documents__item xgs-invoice-documents__item--separated">
                    <div className="xgs-invoice-documents__item__header">All Documents</div>
                    <div className="xgs-invoice-documents__item__image xgs-invoice-documents__item__image--fixed-width">
                      <img
                        className="xgs-invoice-documents__item__all__icon"
                        src={papersImage}
                        alt=""
                      />
                      {invoiceDocumentsState.requestStarted && (invoiceDocumentsState.requestCreator === "download") && !currentDownloadId && (
                        <div className="xgs-invoice-documents__item__loading xgs-invoice-documents__item__loading--offset">
                          <Loading isLoading={true} />
                        </div>
                      )}
                    </div>
                    <div className={`xgs-invoice-documents__item__links ${invoiceDocumentsState.requestStarted && invoiceDocumentsState.requestCreator === "download" ? "xgs-invoice-documents__item__links--disabled" : ""}`}>
                      <span className="xgs-invoice-documents__item__link" onClick={() => { !invoiceDocumentsState.requestStarted && onClickDownloadAll(); }}>PDF</span> | <span className="xgs-invoice-documents__item__link" onClick={() => { !invoiceDocumentsState.requestStarted && onShowEmailModal() }}>Email</span>
                    </div>
                </div>
              )}
              {invoiceDocumentsState.documents && invoiceDocumentsState.documents.length > 0 && invoiceDocumentsState.documents.map((doc) =>
                <div className="xgs-invoice-documents__item" key={doc.id}>
                  {(UserUtils.hasPermission(userState.profile || null, userState.activeSubAccount?.id || null, "PAYMENT") || doc.type !== "INV") && (
                    <>
                      <div className="xgs-invoice-documents__item__header">{documentTypes.find(type => type.name === doc.type)?.title}</div>
                      <div className="xgs-invoice-documents__item__image">
                        {doc.thumbnail && (
                          <img src={`data:image/jpeg;base64,${doc.thumbnail}`} alt={documentTypes.find(type => type.name === doc.type)?.title} />
                        )}
                        {!doc.thumbnail && (
                          <div className="xgs-invoice-documents__item__image__placeholder"><div>No Preview Available</div></div>
                        )}
                        {invoiceDocumentsState.requestStarted && invoiceDocumentsState.requestCreator === "download" && doc.id === currentDownloadId && (
                          <div className="xgs-invoice-documents__item__loading">
                            <Loading isLoading={true} />
                          </div>
                        )}
                      </div>
                      <div className={`xgs-invoice-documents__item__links ${invoiceDocumentsState.requestStarted && invoiceDocumentsState.requestCreator === "download" ? "xgs-invoice-documents__item__links--disabled" : ""}`}>
                        <span className="xgs-invoice-documents__item__link" onClick={() => { !invoiceDocumentsState.requestStarted && onClickDownload(doc.id, doc.type, 'pdf'); }}>PDF</span> | <span className="xgs-invoice-documents__item__link" onClick={() => { !invoiceDocumentsState.requestStarted && onClickDownload(doc.id, doc.type, 'jpg'); }}>JPG</span>
                        {invoiceDocumentsState.documents && invoiceDocumentsState.documents.length === 1 && (
                          <>
                            &nbsp;|&nbsp;<span className="xgs-invoice-documents__item__link" onClick={() => { !invoiceDocumentsState.requestStarted && onShowEmailModal() }}>Email</span>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
      {invoiceDocumentsState.requestFailed && invoiceDocumentsState.requestCreator === "list" && (
        <div className="xgs-invoice-documents__error">
          {invoiceDocumentsState.requestError || "Error while loading documents."}
        </div>
      )}
      <Modal
        isOpen={showEmailModal}
        style={modalStyle}
      >
        <div className="xgs-modal__header">
          Send documents via email
        </div>
        <Formik
          onSubmit={onClickSendEmail}
          initialValues={initialValues}
          validationSchema={EmailDocumentsSchema}
          innerRef={emailDocumentsRef}
          enableReinitialize
        >
          {(props: FormikProps<EmailDocumentsModel>) => (
            <Form>
              <div className="xgs-modal__content">
                <XGSFormInput
                  type="text"
                  name="email"
                  label="Email to:"
                  labelMode={LabelModes.column}
                  required={true}
                  requiredAsteriskDisabled={true}
                  placeholder="name@domain.com"
                  value={documentsEmail}
                  onChange={(e) => {
                    setDocumentsEmail(e.currentTarget.value)
                    props.handleChange(e);
                  }}
                />
              </div>
              <div className="xgs-modal__buttons">
                <Button
                  theme={ButtonThemes.blue}
                  spinner={invoiceDocumentsState.requestStarted && invoiceDocumentsState.requestCreator === "SEND_DOCUMENTS"}
                  type="submit"
                  className="xgs-modal__button"
                >
                  Send
                </Button>
                <Button
                  theme={ButtonThemes.gray}
                  onClick={() => setShowEmailModal(false)}
                  type="button"
                  className="xgs-modal__button"
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default InvoiceDetailsDocuments;
