import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import WarehouseService from "../../../app/data/warehouse/warehouseService";
import { InventoryListRequestModel } from "../../../app/data/warehouse/models";
import { initialInventoryState } from "./InventoryState";

const warehouseService = WarehouseService.getInstance();

export const inventorySlice = createSlice({
  name: "inventory",
  initialState: initialInventoryState,
  reducers: {
    resetInventoryState: (state) => initialInventoryState,
    listRequestStarted: (state, { payload }) => {
      state.listRequest = payload;
      if (state.listRequest.lastIds) {
        state.listRequestStarted = true;        
      } else {
        state.listInitialRequestStarted = true;
      }
      state.listRequestFailed = false;
      state.listRequestSucceed = false;
      state.listRequestError = null;
    },
    listRequestFailed: (state, { payload }) => {
      state.listRequestStarted = false;
      state.listInitialRequestStarted = false;
      state.listRequestFailed = true;
      state.listRequestSucceed = false;
      state.listRequestError = payload;
    },
    listRequestSucceed: (state, { payload }) => {
      state.listRequestStarted = false;
      state.listInitialRequestStarted = false;      
      state.listRequestFailed = false;
      state.listRequestSucceed = true;
      state.listRequestError = null;
      state.listRequestedAll = !!(payload.content.length < 50);
      state.list = state.listRequest.lastIds ? [...state.list, ...payload?.content] : payload?.content;
      state.listRequest.lastIds = payload.scroll?.lastIds;
    },    
    transactionsRequestStarted: (state, { payload }) => {
      state.transactionsLastIds = payload;
      if (state.transactionsLastIds) {
        state.transactionsRequestStarted = true;        
      } else {
        state.transactionsInitialRequestStarted = true;
      }      
      state.transactionsRequestFailed = false;
      state.transactionsRequestSucceed = false;
      state.transactionsRequestError = null;
    },
    transactionsRequestFailed: (state, { payload }) => {
      state.transactionsRequestStarted = false;
      state.transactionsInitialRequestStarted = false;
      state.transactionsRequestFailed = true;
      state.transactionsRequestSucceed = false;
      state.transactionsRequestError = payload;
    },
    transactionsRequestSucceed: (state, { payload }) => {
      state.transactionsRequestStarted = false;
      state.transactionsInitialRequestStarted = false;      
      state.transactionsRequestFailed = false;
      state.transactionsRequestSucceed = true;
      state.transactionsRequestError = null;
      state.transactionsRequestedAll = !!(payload.content.length < 50);
      state.transactions = state.transactionsLastIds ? [...state.transactions, ...payload?.content] : payload?.content;
      state.transactionsLastIds = payload.scroll?.lastIds;      
    }    
  }
});

export const {
  resetInventoryState,
  listRequestStarted,
  listRequestFailed,  
  listRequestSucceed,
  transactionsRequestStarted,
  transactionsRequestFailed,  
  transactionsRequestSucceed
} = inventorySlice.actions;

export const inventorySelector = (state: IState) => {
  return state.inventory;
};

export const getInventoryList = (
  subAccountId: string,
  request: InventoryListRequestModel,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(listRequestStarted(request));
  const response = await warehouseService.getInventoryList(subAccountId, request);
  if (response.ok()) {
    dispatch(listRequestSucceed(response.data));
    onSuccess && onSuccess();
  } else {
    dispatch(listRequestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const getInventoryTransactions = (
  subAccountId: string,
  item: string,
  lastIds: string,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(transactionsRequestStarted(lastIds));
  const response = await warehouseService.getInventoryTransactions(subAccountId, item, lastIds);
  if (response.ok()) {
    dispatch(transactionsRequestSucceed(response.data));    
    onSuccess && onSuccess();
  } else {
    dispatch(transactionsRequestFailed(response.getError ? response.getError() : "Error"));
  }
};

const inventoryReducer = inventorySlice.reducer;
export default inventoryReducer;
