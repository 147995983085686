import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import { ProfileSettingsRequestModel, ProfilePasswordRequestModel } from "../../../app/data/user/requestModels";
import UserService from "../../../app/data/user/userService";
import { initialProfileState } from "./ProfileState";

const userService = UserService.getInstance();

export const profileSlice = createSlice({
  name: "profile",
  initialState: initialProfileState,
  reducers: {
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestCreator = payload;
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestError = null;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = true;
      state.requestSucceed = false;
      state.requestError = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestFailed = false;
      state.requestSucceed = true;
      state.requestError = null;
    },
    update_was_started: (state) => {
      state.update_was_started = true;
      state.update_was_succeed = false;
      state.update_was_failed = false;
      state.update_fail_reason = null;
    },
    update_was_succeed: (state) => {
      state.update_was_succeed = true;
      state.update_was_started = false;
      state.update_was_failed = false;
    },
    update_was_failed: (state, { payload }) => {
      state.update_was_failed = true;
      state.update_was_succeed = false;
      state.update_was_started = false;
      state.update_fail_reason = payload;
    },
    password_change_was_started: (state) => {
      state.password_change_was_started = true;
      state.password_change_was_succeed = false;
      state.password_change_was_failed = false;
      state.password_change_fail_reason = null;
    },
    password_change_was_succeed: (state) => {
      state.password_change_was_succeed = true;
      state.password_change_was_started = false;
      state.password_change_was_failed = false;
    },
    password_change_was_failed: (state, { payload }) => {
      state.password_change_was_failed = true;
      state.password_change_was_succeed = false;
      state.password_change_was_started = false;
      state.password_change_fail_reason = payload;
    },
    resetErrors: (state) => {
      state.update_was_failed = false;
      state.update_fail_reason = null;
      state.password_change_was_failed = false;
      state.password_change_fail_reason = null;
    },
    storeAdditionalSettings: (state, { payload }) => {
      state.additionalSettings = payload;
    }
  }
});

export const {
  requestStarted,
  requestFailed,
  requestSucceed,
  update_was_started,
  update_was_succeed,
  update_was_failed,
  password_change_was_started,
  password_change_was_succeed,
  password_change_was_failed,
  resetErrors,
  storeAdditionalSettings
} = profileSlice.actions;

export const profileSelector = (state: IState) => state.profile;

export const updateProfile = (
  requestModel: ProfileSettingsRequestModel,
  userId: string,
  onSuccess: () => void,
  impersonated?: boolean
): AppThunk => async (dispatch) => {
  if (!userId) return;
  dispatch(update_was_started());
  const response = await userService.updateProfile(userId, requestModel);
  if (response.ok()) {
    dispatch(update_was_succeed());
    response.data.accessToken && !impersonated && localStorage.setItem("xgs-access-token", response.data.accessToken);
    onSuccess && onSuccess();
  } else {
    dispatch(update_was_failed(response.getError ? response.getError() : "Error"));
  }
};

export const updatePassword = (
  requestModel: ProfilePasswordRequestModel,
  onSuccess: () => void  
): AppThunk => async (dispatch) => {
  dispatch(password_change_was_started());
  const response = await userService.updatePassword(requestModel);
  if (response.ok()) {
    dispatch(password_change_was_succeed());
    onSuccess && onSuccess();
  } else {
    dispatch(password_change_was_failed(response.getError ? response.getError() : "Error"));
  }
};

export const cancelEmailChanging = (
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(update_was_started());
  const response = await userService.cancelEmailChange();
  if (response.ok()) {
    dispatch(update_was_succeed());
    onSuccess();
  } else {
    dispatch(update_was_failed(response.getError ? response.getError() : "Error"));
  }
};

export const checkEmailToken = (
  token: string,
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(update_was_started());
  const response = await userService.checkEmailToken(token);
  if (response.ok()) {
    dispatch(update_was_succeed());
    onSuccess();
  } else {
    dispatch(update_was_failed(response.getError ? response.getError() : "Error"));
  }
};

export const getAdditionalSettings = (
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_ADDITIONAL_SETTINGS"));
  const response = await userService.getAdditionalSettings();
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(storeAdditionalSettings(response.data));
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

const profileReducer = profileSlice.reducer;
export default profileReducer;
