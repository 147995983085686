import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import XGSFormSelect from "../../../ui-components/form/select/xgsFormSelect";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import {
  ClaimContactModel,
  ClaimContactSchema
} from "../../../app/data/claims/models";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import XGSFormPhoneInput from "../../../ui-components/form/phoneInput/xgsFormPhoneInput";
import AddressLookup from "../../../ui-components/address-lookup/addressLookup";
import { usaStates } from "../../../app/data/common/usaStates";
import ClaimsState from "../../../slices/claims/ClaimsState";
import {
  claimsSelector,
  setClaimContact
} from "../../../slices/claims/claimsSlice";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import { StepProps } from "../../../app/data/common/models";
import "../claims.scss";
import XGSCheckbox from "../../../ui-components/xgs-checkbox/xgsCheckbox";

let initialValues: ClaimContactModel = {
  company: "",
  contact: "",
  taxSSN: "",
  phone: undefined,
  phoneAllowSms: false,
  email: "",
  address: {
    address1: undefined,
    city: undefined,
    state: undefined,
    postalCode: undefined
  }
};

const ClaimContact: React.FC<StepProps> = (props) => {
  const { previous, next } = props;
  const claimsState: ClaimsState = useSelector(claimsSelector);
  const userState: UserState = useSelector(userSelector);
  const dispatch = useDispatch();
  const claimsFormRef = useRef<any>(null);
  const [stateValue, setStateValue] = useState<XGSSelectOption | null>();
  const [addressLookupValue, setAddressLookupValue] = useState<any>("");
  const [phoneValue, setPhoneValue] = useState<string>("");
  const [allowSmsCheckbox, setAllowSmsCheckbox] = useState<boolean>(false);

  const onClickBack = (data: ClaimContactModel) => {
    dispatch(setClaimContact(data));
    previous && previous();
  };

  const onClickNext = (data: ClaimContactModel) => {
    dispatch(setClaimContact(data));
    next && next();
  };

  useEffect(() => {
    if (!claimsState.contact) return;
    claimsFormRef.current?.setFieldValue("company", claimsState.contact.company);
    claimsFormRef.current?.setFieldValue("contact", claimsState.contact.contact);
    claimsFormRef.current?.setFieldValue("taxSSN", claimsState.contact.taxSSN);
    claimsFormRef.current?.setFieldValue("phone", claimsState.contact.phone);
    claimsState.contact.phone && setPhoneValue(claimsState.contact.phone);
    claimsFormRef.current?.setFieldValue("phoneAllowSms", claimsState.contact.phoneAllowSms);
    claimsState.contact.phoneAllowSms && setAllowSmsCheckbox(claimsState.contact.phoneAllowSms);
    claimsFormRef.current?.setFieldValue("email", claimsState.contact.email);
    claimsFormRef.current?.setFieldValue("address.address1", claimsState.contact.address.address1);
    claimsFormRef.current?.setFieldValue("address.city", claimsState.contact.address.city);
    claimsFormRef.current?.setFieldValue("address.postalCode", claimsState.contact.address.postalCode);
    claimsFormRef.current?.setFieldValue("address.state", claimsState.contact.address.state);
    const state = usaStates.find(obj => obj.value === claimsState.contact?.address.state);
    state && setStateValue({
      value: state.value,
      label: state.label
    });
    if (claimsState.contact.address.address1) {
      setAddressLookupValue({
        label: `${claimsState.contact.address.address1}, ${claimsState.contact.address.city}, ${claimsState.contact.address.state}, ${claimsState.contact.address.postalCode}`,
        value: {
          address: claimsState.contact.address.address1,
          city: claimsState.contact.address.city,
          state: claimsState.contact.address.state,
          zip: claimsState.contact.address.postalCode
        }
      });
    }
    setTimeout(() => {
      claimsFormRef.current?.validateForm();
    }, 50);
  }, [claimsState.contact]);

  useEffect(() => {
    if (claimsState.contact) return;
    claimsFormRef.current?.setFieldValue("company", userState.activeSubAccount?.name || undefined);
    claimsFormRef.current?.setFieldValue("contact", `${userState.profile?.firstName || ""}${userState.profile?.lastName ? " " + userState.profile?.lastName : ""}` || "");
    claimsFormRef.current?.setFieldValue("phone", userState.profile?.phoneNumber || undefined);
    userState.profile?.phoneNumber && setPhoneValue(userState.profile?.phoneNumber);
    claimsFormRef.current?.setFieldValue("email", userState.profile?.email || "");
    claimsFormRef.current?.setFieldValue("address.address1", userState.activeSubAccount?.address.line1 || undefined);
    claimsFormRef.current?.setFieldValue("address.city", userState.activeSubAccount?.address.city || undefined);
    claimsFormRef.current?.setFieldValue("address.postalCode", `${userState.activeSubAccount?.address.zip || ""}`);
    claimsFormRef.current?.setFieldValue("address.state", userState.activeSubAccount?.address.state || undefined);
    if (userState.activeSubAccount?.address.state) {
      const stateOption = usaStates.find(state => state.value === userState.activeSubAccount?.address.state);
      stateOption && setStateValue(stateOption);
    }
  }, [claimsState.contact, userState.profile, userState.activeSubAccount]);

  return (
    <div className="xgs-claims__form">
      <Formik
        onSubmit={onClickNext}
        initialValues={initialValues}
        validationSchema={ClaimContactSchema}
        innerRef={claimsFormRef}
        enableReinitialize
      >
        {(props: FormikProps<ClaimContactModel>) => (
          <Form>
            <div className="xgs-wizard__step__header">Contact Information</div>
            <div className="xgs-claims__section">
              <div className="xgs-claims__row">
                <div className="xgs-claims__row__column-50">
                  <div className="xgs-form__field">
                    <XGSFormInput
                      type="text"
                      name="company"
                      label="Company:"
                      required={true}
                      requiredAsteriskDisabled={false}
                      labelMode={LabelModes.column}
                    />
                  </div>
                </div>
                <div className="xgs-claims__row__column-50">
                  <div className="xgs-form__field">
                    <XGSFormInput
                      type="text"
                      name="taxSSN"
                      label="Tax/SS#:"
                      required={true}
                      requiredAsteriskDisabled={false}
                      labelMode={LabelModes.column}
                    />
                  </div>
                </div>
              </div>
              <div className="xgs-form__address-block__container">
                <AddressLookup
                  defaultValue={addressLookupValue}
                  onValueChange={(val) => {
                    props.setFieldValue("address.address1", val.address);
                    props.setFieldValue("address.city", val.city);
                    props.setFieldValue("address.state", val.state);
                    const stateOption = usaStates.find(state => state.value === val.state);
                    stateOption && setStateValue(stateOption);
                    props.setFieldValue("address.postalCode", val.zip);
                    setTimeout(() => {
                      props.setFieldTouched("address.address1", true, true);
                    }, 100);
                  }}
                  onBlur={() => {
                    props.setFieldTouched("address.address1", true, true);
                  }}
                />
                <div className="xgs-form__address-block">
                  <XGSFormInput
                    type="text"
                    name="address.address1"
                    label="Address:"
                    required={true}
                    requiredAsteriskDisabled={false}
                    labelMode={LabelModes.column}
                    className="xgs-bol__field"
                  />
                  <XGSFormInput
                    type="text"
                    name="address.city"
                    label="City:"
                    required={true}
                    requiredAsteriskDisabled={false}
                    labelMode={LabelModes.column}
                    className="xgs-bol__field"
                  />
                  <div className="xgs-bol__fields-row">
                    <XGSFormSelect
                      isSearchable={false}
                      label="State:"
                      labelMode={LabelModes.column}
                      name="address.state"
                      onValueChange={(v) => {
                        props.setFieldValue("address.state", v?.value);
                        if (v?.value) {
                          const stateOption = usaStates.find(state => state.value === v.value);
                          stateOption && setStateValue(stateOption);
                        }
                      }}
                      options={usaStates}
                      required={true}
                      requiredAsteriskDisabled={false}
                      formik={true}
                      value={stateValue}
                      className="xgs-bol__field xgs-bol__fields-row__state"
                    />
                    <div className="xgs-claims__form__field__zip">
                      <XGSFormInput
                        type="text"
                        name="address.postalCode"
                        label="Zip:"
                        required={true}
                        requiredAsteriskDisabled={false}
                        labelMode={LabelModes.column}
                        className="xgs-bol__field xgs-bol__field--zip"
                        id="xgs-bol__return__shipper-zip--js"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="xgs-claims__row">
                <div className="xgs-claims__row__column-50">
                  <div className="xgs-form__field">
                    <XGSFormInput
                      type="text"
                      name="contact"
                      label="Contact:"
                      required={true}
                      requiredAsteriskDisabled={false}
                      labelMode={LabelModes.column}
                    />
                  </div>
                </div>
              </div>
              <div className="xgs-claims__row">
                <div className="xgs-claims__row__column-50">
                  <div className="xgs-form__field">
                    <XGSFormInput
                      type="text"
                      name="email"
                      label="Email:"
                      required={true}
                      requiredAsteriskDisabled={false}
                      labelMode={LabelModes.column}
                    />
                  </div>
                </div>
                <div className="xgs-claims__row__column-50">
                  <div className="xgs-form__field">
                    <XGSFormPhoneInput
                      name="phone"
                      label="Phone:"
                      labelMode={LabelModes.column}
                      onValueChange={(value) => {
                        props.setFieldValue("phone", value);
                        setPhoneValue(value);
                      }}
                      onBlur={props.handleBlur}
                      value={phoneValue}
                      required={true}
                      requiredAsteriskDisabled={false}
                    />
                    <XGSCheckbox 
                    mix="xgs-claims__phone-checkbox" 
                    style={{ marginTop: 8, marginBottom: 0 }}
                    name="phoneAllowSms"
                          onChange={(e) => {
                            setAllowSmsCheckbox(!allowSmsCheckbox);
                            props.handleChange(e);
                          }}
                          value="true"
                          checked={allowSmsCheckbox}
                    >
                      
                          Allow to send SMS to this phone number
                    </XGSCheckbox>
                  </div>
                  <div className="xgs-form__field__notes">
                    <strong>Note:</strong> by providing a telephone number and checking this checkbox you are consenting to be contacted by SMS text message.
                    Message &amp; data rates may apply. You can reply STOP to opt-out of further messaging.
                  </div>
                </div>
              </div>
            </div>
            <div className="xgs-wizard__step__buttons">
              <Button
                type="button"
                theme={ButtonThemes.gray}
                onClick={() => onClickBack(props.values)}
              >
                Back
              </Button>
              <Button
                type="submit"
                theme={ButtonThemes.blue}
                disabled={!props.isValid || !props.values.taxSSN}
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ClaimContact;
