import api, { ApiResponse } from "../api2";
import { PicklistDetailsgStatusResponse, PicklistsLoadingStatusResponse } from "./models";

class PicklistsService {
  private source: any;
  private static instance: PicklistsService;

  private constructor() { }

  static getInstance(): PicklistsService {
    if (!PicklistsService.instance) {
      PicklistsService.instance = new PicklistsService();
    }
    return PicklistsService.instance;
  }

  public convertPicklistToRoute = async (
    rawRequestModel: any
  ): Promise<ApiResponse<any>> => {
    return await api.post('/tms/picklist/convertToRoute', rawRequestModel, {
    });
  };

  public getPicklistPdf = async (
    rawRequestModel: any
  ): Promise<ApiResponse<any>> => {
    return await api.post('/tms/picklist/getPDF', rawRequestModel, {
      "responseType": "blob"
    });
  };

  public getPicklistsLoadingStatus = async (
    terminalNumber: number
  ): Promise<ApiResponse<PicklistsLoadingStatusResponse>> => {
    return await api.get(`/tms/picklist/loading/status?terminalNumber=${terminalNumber}`);
  };

  public getPicklistDetails = async (
    picklistNumber: number
  ): Promise<ApiResponse<PicklistDetailsgStatusResponse>> => {
    return await api.get(`/tms/picklist/details?picklistNumber=${picklistNumber}`);
  };

}

export default PicklistsService;