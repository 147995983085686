import * as styles from "./../../sass/variables.module.scss";

export const blocks = [
  { 
    label: "At Risk Details",
    value: "AT_RISK"
  },
  { 
    label: "ETA Details",
    value: "ETA"
  },
  { 
    label: "Loads Snapshot",
    value: "LOADS_SNAPSHOT"
  }
];

export const statuses = [
  { 
    label: "Deliveries at risk",
    value: "DELIVERIES_AT_RISK",
    color: styles.orange
  },
  { 
    label: "Very late",
    value: "VERY_LATE",
    color: styles.red
  },
  { 
    label: "Late",
    value: "LATE",
    color: styles.red
  },
  { 
    label: "On time",
    value: "ON_TIME",
    color: styles.green1
  },
  { 
    label: "Will call",
    value: "WILL_CALL"
  },
  { 
    label: "Out for delivery",
    value: "OUT_FOR_DELIVERY"
  },
  { 
    label: "In transit",
    value: "IN_TRANSIT"
  },
  { 
    label: "Delivered",
    value: "DELIVERED"
  }
];
