import api, { ApiResponse } from "./../api";

class GeneralSettingsService {
  private static instance: GeneralSettingsService;
  private constructor() {}
  static getInstance(): GeneralSettingsService {
    if (!GeneralSettingsService.instance) {
      GeneralSettingsService.instance = new GeneralSettingsService();
    }
    return GeneralSettingsService.instance;
  }

  public getShipmentSearchSettings = async (): Promise<ApiResponse<any>> => {
    return await api.get("/shipment/settings");
  };

  public updateShipmentSearchSettings = async (
    filterValue: string,
  ): Promise<ApiResponse<any>> => {
    return await api.post("/shipment/settings", { filterValue });
  }; 
}

export default GeneralSettingsService;
