import React from "react";
import Button, { ButtonSizes, ButtonThemes } from "../button/button";
import { ReactComponent as DownloadIcon } from "../../images/download.svg";

export interface DownloadButtonProps {  
  title?: string;
  onClick: () => void;
  spinner?: boolean,
  disabled?: boolean;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({  
  title,
  onClick,  
  spinner,
  ...props
}) => {
  return (
    <Button
      {...props}
      theme={ButtonThemes.transparent}
      size={ButtonSizes.auto}
      onClick={onClick}
      spinner={spinner}
    >
      {!spinner && (
          <div className="xgs-btn__icon-container">
            <DownloadIcon className="xgs-btn__icon" />
          </div>
        )
      }
      {title}
    </Button>
  )
};


export default DownloadButton;