import React from "react";
import XGSIcon from "../icon/xgsIcon";
import XGSIcons from "../icon/xgsIcons";

export const WizardCheck: React.FC = (props) => {
  return (    
    <div className="wizard__check">
    <XGSIcon
      className="wizard__check__tick"
      icon={XGSIcons.faCheck}
    />
  </div>
  );
};
