import { PickupStatus } from "../../app/data/common/route";
import { TagColor } from "../../app/data/common/tagColor";
import { PickupsGroupModel, PickupsResponseContentModel } from "../../app/data/pickup/models";
import { XGSSelectOption } from "../../ui-components/xgs-select/xgsSelect";

export const getPickupById = (pickups, id) => (
  pickups.reduce((result, item) => {
    if (result) return result
    if (item.pickups?.length > 0) {
      return getPickupById(item.pickups, id)
    }
    return item.pickup.pickupId === id ? item : null
  }, null)
);

export const getPickupsByIds = (pickups, ids) => (
  pickups.reduce((result, item) => {    
    if (item.pickups?.length > 0) {
      return [...result, ...getPickupsByIds(item.pickups, ids)];
    }
    return ids.find(id => id === item.pickup.pickupId) ? [...result, item] : result
  }, [])
);

export const getPickupGroupStatus = (items: PickupsResponseContentModel[]) => {
  if (items.find(item => item.status === PickupStatus.MISSED)) return PickupStatus.MISSED;
  if (items.find(item => item.status === PickupStatus.COMPLETED)) return PickupStatus.COMPLETED;
  if (items.find(item => item.status === PickupStatus.UNASSIGNED)) return PickupStatus.UNASSIGNED;
  if (items.find(item => item.status === PickupStatus.ASSIGNED)) return PickupStatus.ASSIGNED;
  return PickupStatus.CANCELED;
}

export const isGroup = (item: PickupsResponseContentModel | PickupsGroupModel ): item is PickupsGroupModel => ("pickups" in item && !!item.pickups);

export const isSubPickup = (item: PickupsResponseContentModel | PickupsGroupModel): item is PickupsResponseContentModel => !isGroup(item) && !item.pickup.single;

export const convertToSubPickup = (item: PickupsResponseContentModel) => ({ ...item, pickup: { ...item.pickup, single: false }});

export const getStatusColor = (status: PickupStatus) => {
  switch (status) {
    case PickupStatus.ASSIGNED:
      return TagColor.YELLOW;
    case PickupStatus.UNASSIGNED:
      return TagColor.ORANGE;
    case PickupStatus.COMPLETED:
      return TagColor.GREEN;
    case PickupStatus.MISSED:
      return TagColor.RED;
    default:
      return TagColor.GREY
  }
}

export const getStatusesOptions = () => {
  const keys = Object.keys(PickupStatus)
  const statusesOptions: XGSSelectOption[] = [];
  keys.forEach((key, index) => {
    let label = key.toLowerCase();
    label = label.charAt(0).toUpperCase() + label.slice(1);
    statusesOptions.push({
      label: label,
      value: key.toString()
    })
  });
  return statusesOptions;
};

export const getCustomerPickupSourceLabel = (source: string) => {
  switch (source) {
    case "Customer":
    case "Customer API":   return "BOL";
    case "Customer Return": return "Return";
    case "Employee": return "XGS";
    case "EDI":
    case "EDI Pickup": return "EDI";
    default: return "-"
  }
};
