import React, { useEffect, useState } from "react";
import ContentContainer from "../../templates/content-container/contentContainer";
import { useDispatch, useSelector } from "react-redux";
import { fetchLinehaulMileage, infiniteLoadLinehaulTrips, linehaulMileageSelector, resetLinehaulMileageState } from "../../slices/linehaul-mileage/linehaulMileageSlice";
import { LabelModes } from "../../ui-components/molecules/labeled-inputs/labeledInput";
import "./linehaulMileage.scss";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import moment from "moment";
import { TripDetails } from "./TripDetails";
import LabeledDateRangeInput from "../../ui-components/molecules/labeled-inputs/labeled-date-range-input/labeledDateRangeInput";
import LabeledSelectInput from "../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { getServiceCenters, serviceCentersSelector } from "../../slices/service-centers/serviceCentersSlice";
import ServiceCentersState from "../../slices/service-centers/ServiceCentersState";
import { XGSSelectOption } from "../../ui-components/xgs-select/xgsSelect";
import Table from "../../ui-components/table/table";
import { getTripsColumns } from "./getTripsColumns";
import ReactSwitch from "react-switch";
import { userSelector } from "../../slices/user/userSlice";
import DownloadButton from "../../ui-components/download-button/downloadButton";
import exportTripsToCSV from "./exportTripToCsv";
import LinehaulMileageService from "../../app/data/linehaul-mileage/linehaulMileageService";
import { toast } from "react-toastify";

export const LinehaulMileage: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const linehaulMileageState = useSelector(linehaulMileageSelector);
  const [dateFrom, setDateFrom] = useState<string>(moment().subtract(2, "weeks").toString());
  const [dateTo, setDateTo] = useState<string>(moment().toString());
  const serviceCentersState: ServiceCentersState = useSelector(serviceCentersSelector);
  const [destination, setDestination] = useState<XGSSelectOption | null | undefined>();
  const [selectedTripId, setSelectedTripId] = useState<string | null>()
  const [hideReviewed, setHideReviewed] = useState(false);
  const userState = useSelector(userSelector);
  const [loadingCSV, setLoadingCSV] = useState(false);

  useEffect(() => {
    if (!serviceCentersState.loaded && !serviceCentersState.loading) dispatch(getServiceCenters());
  }, [dispatch, serviceCentersState]);

  useEffect(() => {
    dispatch(
      fetchLinehaulMileage(
        dateFrom,
        dateTo, 
        userState.activeTerminal?.id, 
        destination?.value as number | undefined, 
        hideReviewed ? !hideReviewed : null, 
        1
      ))
    setSelectedTripId(null);
  }, [dispatch, dateFrom, dateTo, userState.activeTerminal?.id, destination, hideReviewed])

  useEffect(() => {
    return () => {
      dispatch(resetLinehaulMileageState());
    }
  }, [dispatch])

  const getDestinationsOptions = () => {
    return (
      serviceCentersState.destinations?.map(
        (destination): XGSSelectOption => ({
          label: destination.name,
          value: destination.id.toString(),
        })
      ) || []
    );
  };
  
  const onDestinationChanged = (_destination: XGSSelectOption | null | undefined) => {
    setDestination(_destination);
  };

  const exportCSV = async () => {
    setLoadingCSV(true);
    const response = await LinehaulMileageService.getInstance().getLinehaulMileage(
      dateFrom,
      dateTo, 
      userState.activeTerminal?.id as number, 
      destination?.value as number | undefined, 
      hideReviewed ? !hideReviewed : null, 
      true
    )
    setLoadingCSV(false)
    if (response.ok()) {
      exportTripsToCSV(response.data.trips);
    } else {
      const error = response.getError() as any;
      const message = (
        (typeof error === "string" && error.includes("AllResults")) || 
        (error.some((message: string) => message.includes("AllResults")))) ?
          "Date range must be less than or equal to 15 days" : "Something went wrong";
        
      toast.error(message);
    }
  }

  const onInfiniteScroll = () => {
    dispatch(infiniteLoadLinehaulTrips(
      dateFrom,
      dateTo, 
      userState.activeTerminal?.id as number, 
      destination?.value as number | undefined, 
      hideReviewed ? !hideReviewed : null, 
      linehaulMileageState.tripsPageNumber + 1
    ))
  }
  return (
    <ContentContainer title="Linehaul Mileage">
      <div className="xgs-tracking-filter__download-csv">
        <DownloadButton
          title="Download CSV"
          spinner={loadingCSV}
          onClick={exportCSV}
        />
      </div> 
      <div className="xgs-lmi__controls">
        <LabeledDateRangeInput
          label="Dates range:"
          labelMode={LabelModes.column}
          className="xgs-list__controls__search__input"
          start={dateFrom}
          end={dateTo}
          onStartChange={(v) => {
            setDateFrom(v);
          }}
          onEndChange={(v) => {
            setDateTo(v);
          }}
        />
        <div className="xgs-lmi__controls__item">
          <LabeledSelectInput
            onValueChange={onDestinationChanged}
            value={destination}
            options={getDestinationsOptions()}
            label="Destination:"
            labelMode={LabelModes.column}
            className="xgs-tracking-filter__input"
            isClearable
          />
        </div>
        <div className="xgs-lmi__controls__button">
          <span style={{ marginRight: 10}}>Hide Reviewed</span>
          <ReactSwitch
            onChange={setHideReviewed}
            checked={hideReviewed}
            width={32}
            height={20}
            offColor="#BDBDBD"
            onColor="#2F80ED"
            uncheckedIcon={<></>}
            checkedIcon={<></>}
          />
        </div>
      </div>
      {linehaulMileageState.loadingTripsFailed && <XGSErrorMessage>{linehaulMileageState.loadingTripsError}</XGSErrorMessage>}
      {!linehaulMileageState.loadingTripsFailed && <div className="xgs-lmi__trips">
        <Table
          isLoading={linehaulMileageState.loadingTrips}
          data={linehaulMileageState.trips}
          columns={getTripsColumns()}
          rowHeight={60}
          infiniteScroll
          infiniteScrollLoading={linehaulMileageState.inifiniteLoadingTrips}
          onRowClicked={(row: any) => setSelectedTripId(row.tripId)}
          cursorPointer
          infiniteScrollHasNext={linehaulMileageState.inifinteLoadingTripsHasNext}
          onInfiniteScroll={onInfiniteScroll}
          highlightRow={linehaulMileageState.trips.findIndex((trip) => trip.tripId === selectedTripId)}
        />
      </div>}
      <TripDetails 
        onClose={() => setSelectedTripId(null)} 
        show={!!selectedTripId}
        trip={linehaulMileageState.trips.find(trip => trip.tripId === selectedTripId)!} 
      />
    </ContentContainer>
  )
}
