import styled from "styled-components";

const LoginTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  color: #000000;
  margin-bottom: 8px;
  height: 32px;
`;
/*  ${(props) =>
    props.primary &&
    css`
      background: palevioletred;
      color: white;
    `};*/

export default LoginTitle;
