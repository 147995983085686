import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { PathParams, ReportsPath } from "../../route";
import Loading from "../../../../ui-components/loading/loading";
import XGSErrorMessage from "../../../../ui-components/error-message/errorMessage";
import ReportsState from "../../../../slices/reports/ReportsState";
import {
  reportsSelector,
  unsubscribeReport
} from "../../../../slices/reports/reportsSlice";
import {
  getCurrentUser,
  userSelector
} from "../../../../slices/user/userSlice";
import UserState from "../../../../slices/user/UserState";
import "./reportUnsubscribe.scss";

const ReportUnsubscribe: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const params = useParams() as PathParams;
  const reportsState: ReportsState = useSelector(reportsSelector);
  const userState: UserState = useSelector(userSelector);
  const [invalidParam, setInvalidParam] = useState<boolean>(false);
  const reportId = params[ReportsPath.id];

  const email = useMemo(() => {
    let emailValue = "";
    setInvalidParam(false);
    try {
      emailValue = window.atob(params[ReportsPath.email]);
    } catch(e) {
      if (e.code === 5) {
        setInvalidParam(true);
      }
    }
    return emailValue;
  }, [params]);

  useEffect(() => {
    if (!reportId || !email) return;
    dispatch(getCurrentUser(""));
    dispatch(unsubscribeReport(reportId, email));
  }, [reportId, email, dispatch]);

  return (
    <div className="xgs-reports__scheduled__unsubscribe">
      {reportsState.requestStarted && (reportsState.requestCreator === "UNSUBSCRIBE") && (
        <Loading isLoading={true} />
      )}
      {reportsState.requestFailed && (reportsState.requestCreator === "UNSUBSCRIBE") && (
        <XGSErrorMessage className="xgs-reports__scheduled__unsubscribe__error">{reportsState.requestError || "Error while unsubscribing the report"}</XGSErrorMessage>
      )}
      {invalidParam && (
        <XGSErrorMessage className="xgs-reports__scheduled__unsubscribe__error">Error in unsubscribe link.</XGSErrorMessage>
      )}
      {reportsState.requestSucceed && (reportsState.requestCreator === "UNSUBSCRIBE") && (
        <div className="xgs-reports__scheduled__unsubscribe__success">
          You have successfully opted out of receiving the report by email.
        </div>
      )}
      {!reportsState.requestStarted && !userState.getCurrentUser_is_Started && (
        <div className="xgs-reports__scheduled__unsubscribe__home">
          {userState.loggedIn
            ? <>Go to <a href="/" className="blue-link">dashboard</a></>
            : <>Go to <a href="/" className="blue-link">Log In</a> page</>
          }
        </div>
      )}
    </div>
  );
};

export default ReportUnsubscribe;
