import UserProfile from "../../app/data/user/UserProfile";

export default interface CompanySettingsUsersState {
  fetch_was_started: boolean;
  fetchPortion_was_started: boolean;
  fetch_was_succeed: boolean;
  fetch_was_failed: boolean;
  fetch_fail_reason?: string | null;  
  update_was_started: boolean;
  update_creator?: string;
  update_was_succeed: boolean;
  update_was_failed: boolean;
  update_fail_reason?: string | null;
  users: UserProfile[];
  usersRequest: any;
  usersFetchedAll: boolean;
}

export const initialCompanySettingsUsersState: CompanySettingsUsersState = {
  fetch_was_started: false,
  fetchPortion_was_started: false,
  fetch_was_succeed: false,
  fetch_was_failed: false,
  fetch_fail_reason: null,
  update_was_started: false,
  update_creator: undefined,
  update_was_succeed: false,
  update_was_failed: false,
  update_fail_reason: null,
  users: [],
  usersRequest: null,
  usersFetchedAll: false
};
