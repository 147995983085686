import React, { useState } from "react";
import SlideOutSidebar from "../../ui-components/slide-out-sidebar/slideOutSidebar";
import { LabelModes } from "../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import LabeledSelectInput from "../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { debounce } from "lodash";
import ManifestsService from "../../app/data/manifest/manifestsService";
import { DriverSearch } from "../../app/data/manifest/models";
import { useSelector } from "react-redux";
import { linehaulMileageSelector } from "../../slices/linehaul-mileage/linehaulMileageSlice";

export interface SelectDriverProps {
  show: boolean;
  onDriverSelect: (driverId: string) => void;
  closeModal: any;
}

export const SelectDriver: React.FC<SelectDriverProps> = (props) => {
  const [driver, setDriver] = useState<string>();
  const linehaulMileageState = useSelector(linehaulMileageSelector);

  // eslint-disable-next-line
  const loadDriverOptions = React.useCallback(
    debounce((query, callback) => {
      ManifestsService.getInstance().getDriversSearch(query)
      .then(options => callback(options.map(({ driver, searchDetails }: DriverSearch) => ({
        value: driver.driverId,
        label: <span dangerouslySetInnerHTML={{ __html: searchDetails.matchedStr }} />
      }))))
    }, 300),
    []
  );
  
  return (
    <SlideOutSidebar size={"small"} header="Add Lane" show={props.show} onClose={props.closeModal}>
      <div className="xgs-lmi__title">Assign a Driver</div>
      <LabeledSelectInput
        label="Driver"
        labelMode={LabelModes.column}
        placeholder="Driver name or number"
        isClearable
        async
        loadOptions={loadDriverOptions}
        options={[]}
        onValueChange={(t) => setDriver(t?.value as any)}
        className="xgs-item-form__field"
      />
      <div className="xgs-lmi__driver-select__buttons">
        <Button onClick={props.closeModal} theme={ButtonThemes.gray}>Cancel</Button>
        <Button
          onClick={() => {
            props.onDriverSelect(driver!);
          }} 
          disabled={!driver}
          spinner={linehaulMileageState.requestStarted}
          theme={ButtonThemes.blue}
        >
            Assign driver
        </Button>
      </div>
    </SlideOutSidebar>
  )
}