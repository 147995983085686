import api, { ApiResponse } from "./../api";
import { ReportModel } from "./models";

class ReportsService {
  private static instance: ReportsService;
  private constructor() {};
  static getInstance(): ReportsService {
    if (!ReportsService.instance) {
      ReportsService.instance = new ReportsService();
    }
    return ReportsService.instance;
  };

  public getReports = async (subAccountId: string): Promise<ApiResponse<any>> => {
    return await api.get(`/accounts/${subAccountId}/scheduledReports`);
  };

  public createReport = async (subAccountId: string, report: ReportModel): Promise<ApiResponse<any>> => {
    return await api.post(`/accounts/${subAccountId}/scheduledReports`, report);
  };

  public updateReport = async (subAccountId: string, report: ReportModel): Promise<ApiResponse<any>> => {
    return await api.put(`/accounts/${subAccountId}/scheduledReports/${report.id}`, report);
  };

  public deleteReport = async (subAccountId: string, reportId: string): Promise<ApiResponse<any>> => {
    return await api.delete(`/accounts/${subAccountId}/scheduledReports/${reportId}`);
  };

  public runReport = async (subAccountId: string, reportId: string): Promise<ApiResponse<any>> => {
    return await api.post(`/accounts/${subAccountId}/scheduledReports/${reportId}/run`);
  };

  public unsubscribeReport = async (
    reportId: string,
    email: string
  ): Promise<ApiResponse<any>> => {
    return await api.post("/scheduled-reports/unsubscribe", {
      reportId,
      email
    });
  };
};

export default ReportsService;
