import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Form, Formik, FormikProps } from "formik";
import Modal from "react-modal";
import debounce from "lodash/debounce";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import Table from "../../../ui-components/table/table";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import LabeledTextInput from "../../../ui-components/molecules/labeled-inputs/labeled-text-input/labeledTextInput";
import LabeledSelectInput from "../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSFormCheckbox from "../../../ui-components/form/checkbox/xgsFormCheckbox";
import PendingRequestsState from "../../../slices/customers/pending/PendingRequestsState";
import {
  approveRegistrationRequest,
  pendingRequestsSelector,
  searchTeamAccounts,
  storeTeamAccounts
} from "../../../slices/customers/pending/pendingRequestsSlice";
import TeamsState from "../../../slices/customers/teams/TeamsState";
import {
  teamsSelector,
  getCustomers,
  resetTeams,
  resetAs400Account
} from "../../../slices/company/companySlice";
import {
  RegistrationApproveModel,
  RegistrationApproveSchema
} from "../../../app/data/user/requestModels";
import { permissionTypes } from "../../company/constants";
import { wideModalStyle } from "../../../app/data/common/modalStyle"
import "../../../sass/modal.scss";
import "./pendingRequests.scss";

export interface ApproveRequestProps {
  requestId: string | undefined;
  requestData: any;
  show: boolean;
  onUpdate: () => void;
  onClose: () => void;
}

export interface PermissionFormModel {
  account?: XGSSelectOption,
  permissions: XGSSelectOption[]
}

const approveInitialValues: RegistrationApproveModel = {
  request: "",
  team: "",
  administrator: false
}

const RegistrationApproveModal: React.FC<ApproveRequestProps> = ({requestData, onClose, ...props}) => {
  const dispatch = useDispatch();
  const requestsState: PendingRequestsState = useSelector(pendingRequestsSelector);
  const teamsState: TeamsState = useSelector(teamsSelector);
  const [teamSearchString, setTeamSearchString] = useState<string>("");
  const [teamId, setTeamId] = useState<string>("");
  const [teamName, setTeamName] = useState<string>("");
  const [step, setStep] = useState<string>("team");
  const [permissions, setPermissions] = useState<PermissionFormModel[]>([]);
  const approveFormRef = useRef<any>(null);

  const teamsColumns = [
    {
      width: 260,
      minWidth: 200,
      Header: "Name",
      accessor: "name"
    },
    {
      width: 130,
      minWidth: 110,
      Header: (
        <div style={{ textAlign: "center" }}>
          Accounts
        </div>
      ),
      accessor: "accounts",
      Cell: (cellProps: any) => (
        <div style={{ textAlign: "center" }}>
          {cellProps.value}
        </div>
      )
    },
    {
      width: 100,
      minWidth: 90,
      Header: (
        <div style={{ textAlign: "center" }}>
          Users
        </div>
      ),
      accessor: "users",
      Cell: (cellProps: any) => (
        <div style={{ textAlign: "center" }}>
          {cellProps.value}
        </div>
      )
    }
  ];

  const onApprove = (data: RegistrationApproveModel) => {
    let requestObj = {...data};
    if (data.teamName) {
      requestObj.accountNumber = teamsState.as400Account.accountId;
      delete requestObj["team"];
    }
    if (!requestObj.administrator) {
      if (data.teamName) {
        // only one as400 account (which not in our DB)
        requestObj.permissions = [{
          accountNumber: Number(permissions[0].account?.value),
          permissions: permissions[0].permissions.map(permission => permission.value)
        }];
      } else {
        const permissionsArr = permissions
          .filter(permissionObj => (permissionObj.account && permissionObj.permissions.length > 0))
          .map((permissionObj) => {
            return {
              accountId: permissionObj.account?.value,
              permissions: permissionObj.permissions?.map(permission => permission.value)
            }
          });
        if (permissionsArr && permissionsArr.length > 0) {
          requestObj.permissions = [...permissionsArr];
        }
      }
    }
    dispatch(approveRegistrationRequest(requestObj, () => {
      onClose();
      toast.info("The request was approved!");
      props.onUpdate();
    }));
  };

  const onTeamSearchStart = (searchStr: string) => {
    if (!searchStr || !String(searchStr).match(/\S+/)) return;
    dispatch(resetAs400Account());
    dispatch(getCustomers("", searchStr, true));
    setTeamId("");
    approveFormRef.current?.setFieldValue("team", "");
    setTeamName("");
    approveFormRef.current?.setFieldValue("teamName", undefined);
    setTimeout(() => {
      approveFormRef.current?.setErrors({});
    }, 50);
  };

  const onTeamsInfiniteScroll = () => {
    dispatch(getCustomers(teamsState.teamsScrollRequest?.lastIds, teamsState.teamsSearchString, true));
  };

  const onTeamClick = (row: any) => {
    approveFormRef.current?.setFieldValue("team", row.id);
    setTeamId(row.id)
    setTimeout(() => {
      approveFormRef.current?.setFieldValue("administrator", false);
      approveFormRef.current?.setFieldValue("teamName", undefined);
      setTeamName("");
      approveFormRef.current?.setErrors({});
    }, 50);
    setPermissions([]);
    dispatch(storeTeamAccounts([]));
  };

  const getTeamAccountsOptions = () => {
    return requestsState.teamAccounts
      .filter((teamAccount: any) => !permissions.find(permissionObj => permissionObj.account?.value === teamAccount.value));
  };

  let accountsSearch = (query: string, i: number) => {
    if (!teamId || query?.length < 2) return;
    dispatch(searchTeamAccounts(teamId, query, i));
  }
  accountsSearch = debounce(accountsSearch, 300);

  const isAddAccountDisabled = () => {
    const permissionsArr = [...permissions];
    const nonValidPermissions = permissionsArr.find(permissionObj => (permissionObj.account === undefined || permissionObj.permissions.length === 0)) || undefined;
    return !!nonValidPermissions;
  };

  useEffect(() => {
    if (!props.show) return;
    setStep("team");
    setTimeout(() => {
      approveFormRef.current?.setFieldValue("request", props.requestId);
      approveFormRef.current?.setFieldValue("team", "");
      approveFormRef.current?.setFieldValue("teamName", undefined);
      approveFormRef.current?.setFieldValue("administrator", false);
      setTeamName("")
    }, 50);
    dispatch(resetAs400Account());
    setPermissions([]);
    if (requestData.registrationRequest.accountNumber) {
      setTeamSearchString(requestData.registrationRequest.accountNumber);
      onTeamSearchStart(requestData.registrationRequest.accountNumber);
    } else {
      dispatch(resetTeams());
      setTeamSearchString("");
      setTimeout(() => {
        const searchField = document.getElementById("xgs-pending-requests__team-search-field--js") as HTMLInputElement;
        if (searchField) searchField.focus();
      }, 280);
    }
    // eslint-disable-next-line
  }, [props.show]);

  return (
    <Modal
      isOpen={props.show}
      style={wideModalStyle}
    >
      <div className="xgs-pending-requests__details__modal">
        <div className="xgs-modal__header">
          Approve Request
        </div>
        <Formik
          onSubmit={onApprove}
          initialValues={approveInitialValues}
          validationSchema={RegistrationApproveSchema}
          innerRef={approveFormRef}
          validateOnMount
          enableReinitialize
        >
          {(props: FormikProps<RegistrationApproveModel>) => (
            <Form>
              <div className="xgs-pending-requests__details__modal">
                {step === "team" && (
                  <>
                    <div className="xgs-modal__content">
                      <div className="xgs-pending-requests__details__modal__step__header">Set Customer</div>
                      <div className="xgs-pending-requests__details__modal__step__content">
                        <input type="hidden" name="request" />
                        <div className="xgs-pending-requests__details__modal__team">
                          <div className="xgs-pending-requests__details__modal__search">
                            <LabeledTextInput
                              label="Customer / account name / number:"
                              labelMode={LabelModes.column}
                              className="xgs-pending-requests__details__modal__search__input"
                              value={teamSearchString}
                              onChange={(e) => setTeamSearchString(e.currentTarget.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  !teamsState.teams_fetch_was_started && onTeamSearchStart(e.currentTarget.value);
                                }
                              }}
                              type="text"
                              placeholder="Customer, included account name or number"
                              id="xgs-pending-requests__team-search-field--js"
                            />
                            <Button
                              className="xgs-pending-requests__details__modal__search__button"
                              theme={ButtonThemes.blue}
                              disabled={teamsState.teams_fetch_was_started || !teamSearchString || !String(teamSearchString).match(/\S+/)}
                              type="button"
                              onClick={() => {
                                onTeamSearchStart(teamSearchString);
                              }}
                            >
                              Search
                            </Button>
                          </div>
                          {teamsState.teams_fetch_was_failed && (
                            <XGSErrorMessage>{teamsState.teams_fetch_fail_reason}</XGSErrorMessage>
                          )}
                          {!teamsState.teams_fetch_was_failed && !(teamsState.as400Account && teamsState.teams.length === 0) && (
                            <>
                              <div className="xgs-pending-requests__details__modal__team__label" style={{
                                fontWeight: (teamsState.as400Account && teamsState.teams.length > 0) ? "bold" : "normal"
                              }}>Select an existing customer for the user:</div>
                              <div className={teamsState.teams.length > 1 ? "xgs-pending-requests__details__modal__table__wrapper-high" : "xgs-pending-requests__details__modal__table__wrapper-low"}>
                                <Table
                                  autoHeight={true}
                                  isLoading={teamsState.teams_fetch_was_started}
                                  columns={teamsColumns}
                                  data={teamsState.teams}
                                  cursorPointer={true}
                                  onRowClicked={onTeamClick}
                                  rowHeight={52}
                                  noResultsText="No customers found matching the specified criteria."
                                  minTableHeight={140}
                                  infiniteScroll={true}
                                  infiniteScrollLoading={teamsState.teams_fetchPortion_was_started}
                                  infiniteScrollHasNext={!teamsState.teamsFetchedAll}
                                  onInfiniteScroll={onTeamsInfiniteScroll}
                                  responsive={true}
                                  highlightRow={teamId ? teamsState.teams.findIndex((team: any) => team.id === teamId) : null}
                                  highlightRowClass="xgs-pending-requests__details__modal__table__highlighted-row"
                                />
                              </div>
                            </>
                          )}
                          {!teamsState.teams_fetch_was_failed && teamsState.as400Account && (
                            <>
                              {!teamsState.teams_fetch_was_started && (
                                <>
                                  <div className="xgs-pending-requests__details__modal__team__label" style={{ marginTop: 16 }}>
                                    {(teamsState.as400Account && teamsState.teams.length === 0)
                                      ? (
                                      <>Create a new customer for the user:</>
                                    ) : (
                                      <strong>Or create a new customer for the user:</strong>
                                    )}
                                  </div>
                                  <div className="xgs-pending-requests__details__modal__team__new">
                                    <XGSFormInput
                                      type="text"
                                      name="teamName"
                                      label="Customer name:"
                                      required={true}
                                      requiredAsteriskDisabled={true}
                                      labelMode={LabelModes.column}
                                      onChange={(e) => {
                                        approveFormRef.current?.setFieldValue("team", undefined);
                                        setTeamId("");
                                        setTeamName(e.currentTarget.value);
                                        props.handleChange(e);
                                      }}
                                      value={teamName}
                                    />
                                    <div className="xgs-pending-requests__details__modal__team__new__label">The following account (# {teamsState.as400Account.accountId}) will become part of the customer:</div>
                                    <div className="xgs-pending-requests__details__modal__team__new__account">
                                       <div className="xgs-pending-requests__details__modal__team__new__account__name">{teamsState.as400Account.accountName}</div>
                                      {teamsState.as400Account.address && (
                                        <>
                                          {teamsState.as400Account.address}<br />
                                        </>
                                      )}
                                      {teamsState.as400Account.city ? `${teamsState.as400Account.city}, ` : ""}
                                      {teamsState.as400Account.state ? `${teamsState.as400Account.state}, ` : ""}{teamsState.as400Account.zip}
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="xgs-modal__buttons text-center">
                      <Button
                        theme={ButtonThemes.gray}
                        onClick={() => {
                          onClose();
                        }}
                        className="xgs-modal__button"
                        type="button"
                      >
                        Cancel
                      </Button>
                      <Button
                        theme={ButtonThemes.blue}
                        className="xgs-modal__button"
                        type="button"
                        disabled={!props.dirty || !(props.values.team || (props.values.teamName && !props.errors.teamName))}
                        onClick={() => {
                          setStep("permissions");
                          if (props.values.teamName && permissions.length === 0) {
                            setPermissions([{
                              account: teamsState.as400Account.accountId,
                              permissions: [{
                                label: "View Account",
                                value: "VIEW",
                                isFixed: true
                              }]
                            }]);
                          }
                        }}
                      >
                        Next
                      </Button>
                    </div>
                  </>
                )}
                {step === "permissions" && (
                  <>
                    <div className="xgs-modal__content">
                      <div className="xgs-pending-requests__details__modal__step__header">Set Permissions</div>
                      <div className="xgs-pending-requests__details__modal__step__content">
                        <XGSFormCheckbox
                          mix="xgs-pending-requests__details__modal__checkbox"
                          name="administrator"
                        >
                          Make Administrator
                        </XGSFormCheckbox>
                        {!props.values.administrator && (
                          <>
                            {teamsState.as400Account && props.values.teamName && (
                              <div className="xgs-pending-requests__details__modal__permissions__rows">
                                <div className="xgs-pending-requests__details__modal__permissions__row">
                                  <div className="xgs-pending-requests__details__modal__permissions__account">
                                    <div className="xgs-pending-requests__details__modal__permissions__account__static">
                                      <div className="xgs-pending-requests__details__modal__permissions__account__static__label">Account:</div>
                                      {teamsState.as400Account.accountName}
                                    </div>
                                  </div>
                                  <div className="xgs-pending-requests__details__modal__permissions__select">
                                    <LabeledSelectInput
                                      onMultiValuesChange={(options) => {
                                        setPermissions(permissionsArr => {
                                          let newPermissions = [...permissionsArr];
                                          newPermissions[0].permissions = options || [{
                                            label: "View Account",
                                            value: "VIEW",
                                            isFixed: true
                                          }];
                                          return newPermissions;
                                        });
                                      }}
                                      options={permissionTypes.map(({isFixed, ...commonProps}) => commonProps)}
                                      label="Permissions:"
                                      labelMode={LabelModes.column}
                                      isMulti
                                      isClearable
                                      disabled={false}
                                      value={permissions[0]?.permissions || []}
                                      styles={{ menuPortal: base => ({ ...base, zIndex: 1003 }) }}
                                      menuPortalTarget={document.body as HTMLInputElement}
                                      menuPlacement="auto"
                                      menuPosition="fixed"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            {(!teamsState.as400Account || !props.values.teamName) && (
                              <div className="xgs-pending-requests__details__modal__permissions__rows">
                                {permissions.map((permissionObj, permissionIndex) => (
                                  <div className="xgs-pending-requests__details__modal__permissions__row" key={JSON.stringify(permissionObj)}>
                                    <div className="xgs-pending-requests__details__modal__permissions__account">
                                      <LabeledSelectInput
                                        label={permissionIndex === 0 ? "Account:" : ""}
                                        labelMode={LabelModes.column}
                                        isDisabled={false}
                                        isLoading={requestsState.request_was_started && requestsState.request_creator === "ACCOUNT_SEARCH" && requestsState.teamAccountIndex === permissionIndex}
                                        options={getTeamAccountsOptions()}
                                        placeholder="Name or number..."
                                        onValueChange={(v) => {
                                          if (!v?.value) return;
                                          setPermissions(permissionsArr => {
                                            let newPermissions = [...permissionsArr];
                                            newPermissions[permissionIndex].account = v;
                                            return newPermissions;
                                          });
                                        }}
                                        onInputChange={(q) => accountsSearch(q, permissionIndex)}
                                        openMenuOnClick={requestsState.teamAccounts?.length > 0}
                                        value={permissions[permissionIndex].account}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 1003 }) }}
                                        menuPortalTarget={document.body as HTMLInputElement}
                                        menuPlacement="auto"
                                        menuPosition="fixed"
                                      />
                                    </div>
                                    <div className="xgs-pending-requests__details__modal__permissions__select">
                                      <LabeledSelectInput
                                        onMultiValuesChange={(options) => {
                                          setPermissions(permissionsArr => {
                                            let newPermissions = [...permissionsArr];
                                            newPermissions[permissionIndex].permissions = options || [{
                                              label: "View Account",
                                              value: "VIEW",
                                              isFixed: true
                                            }];
                                            return newPermissions;
                                          });
                                        }} 
                                        options={permissionTypes}
                                        label={permissionIndex === 0 ? "Permissions:" : ""}
                                        labelMode={LabelModes.column}
                                        isMulti
                                        isClearable
                                        disabled={!permissions[permissionIndex].account}
                                        value={permissions[permissionIndex].permissions}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 1003 }) }}
                                        menuPortalTarget={document.body as HTMLInputElement}
                                        menuPlacement="auto"
                                        menuPosition="fixed"
                                      />
                                    </div>
                                    <div className={`xgs-pending-requests__details__modal__permissions__remove ${permissionIndex === 0 ? " xgs-pending-requests__details__modal__permissions__remove--first" : ""}`}>
                                      <div
                                        className=""
                                        onClick={() => {
                                          let newPermissions = [...permissions];
                                          newPermissions.splice(permissionIndex, 1);
                                          setPermissions(newPermissions);
                                        }}
                                      >
                                        <XGSIcon
                                          icon={XGSIcons.faTimesCircle}
                                          size="1x"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                <div className="xgs-pending-requests__details__modal__permissions__add-account">
                                  <Button
                                    theme={ButtonThemes.gray}
                                    type="button"
                                    disabled={isAddAccountDisabled()}
                                    onClick={() => {
                                      setPermissions([
                                        ...permissions,
                                        {
                                          account: undefined,
                                          permissions: [{
                                            label: "View Account",
                                            value: "VIEW",
                                            isFixed: true
                                          }]
                                        }
                                      ]);
                                    }}
                                  >
                                    {permissions.length > 0 ? "Add Another Account" : "Add Account Permissions"}
                                  </Button>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                        {props.values.administrator && (
                          <div className="xgs-pending-requests__details__modal__permissions__administrator">
                            The user will become a customer administrator with full access to all customer accounts.
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="xgs-modal__buttons text-center">
                      <Button
                        theme={ButtonThemes.gray}
                        onClick={onClose}
                        className="xgs-modal__button"
                        type="button"
                      >
                        Cancel
                      </Button>
                      <Button
                        theme={ButtonThemes.blue}
                        className="xgs-modal__button"
                        type="button"
                        onClick={() => setStep("team")}
                      >
                        Previous
                      </Button>
                      <Button
                        theme={ButtonThemes.green}
                        spinner={requestsState.request_was_started && requestsState.request_creator === "APPROVE"}
                        className="xgs-modal__button"
                        type="submit"
                        disabled={!props.isValid || !props.dirty || !(props.values.team || props.values.teamName)}
                      >
                        Approve
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default RegistrationApproveModal;
