import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { AgingState } from "../../../slices";
import UserState from "../../../slices/user/UserState";
import { agingSelector, getAging } from "../../../slices/aging/agingSlice";
import { userSelector } from "../../../slices/user/userSlice";
import Loading from "../../../ui-components/loading/loading";
import AgingItemList from "../list/agingItemList";
import "./agingSummary.scss";

const AgingSummary: React.FC = () => {
  const agingState: AgingState = useSelector(agingSelector);
  const userState: UserState = useSelector(userSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAging(userState.activeSubAccount?.id));
  }, [userState.activeSubAccount, dispatch]);

  return (
    <div className="xgs-aging">
      <div className="xgs-aging__header">
        <div>
          <FontAwesomeIcon
            icon={Icons.faChartBar}
            size="lg"
            className="chart-icon"
          />
          <span>Aging Summary</span>
          <Loading isLoading={agingState.loading} />
        </div>
      </div>
      <div className="xgs-aging__content">
        <AgingItemList data={agingState.data} />
      </div>
    </div>
  );
}

export default AgingSummary;
