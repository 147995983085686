export default interface StatusCheckSubAccount {
  accountId: number;
  accountName: string;
  accountNumber: number;
  state: string;
  city: string;
  address: string;
  collectorType: string;
  zip: number;
}

export enum SubAccountCreditStatusTypes {
  onHold = "210"
}