import { UserStatuses, UserRoles } from "./enums";
import SubAccount from "../account/SubAccount";
import { ref, object, string, ObjectSchema } from "yup";
import { Terminal } from "../common/models";
import { validationMessages } from "../common/validationMessages";

export interface UserProfileTeam {
  id: string;
  name: string;
}

export interface InvitationDataModel {
  date: string;
  link: string;
}

export default interface UserProfile {
  accountsOnCreditHold: Number[];
  dateCreated: string;
  disabledFeatures: string[];
  email: string;
  faxNumber: string;
  firstName: string;
  id: string;
  impersonated?: boolean;
  invitation?: InvitationDataModel;
  lastName: string;
  paypointNumbers?: number[];
  pendingEmail?: string;
  pendingRequests?: number;
  phoneNumber: string;
  receiveLatestAnnouncements: boolean;
  role: UserRoles;
  salesManager: string | null;
  serviceAccessLevels: {
    service: string;
    level: string;
  }[];
  sso: boolean;
  status: UserStatuses;
  subAccounts: SubAccount[];
  team: UserProfileTeam;
  timezone: string | null;
  title: string;
  primaryServiceCenter?: Terminal;
  secondaryServiceCenters?: Terminal[];
}

export interface UserPasswordChangeForm {
  newPassword: string,
  newPasswordRepeat: string
}

export const UserPasswordChangeFormSchema: ObjectSchema<UserPasswordChangeForm> = object({
  newPassword: string().matches(/(\w+|-+)/, validationMessages.notValid).min(5, "The value must be more than 4 characters").max(30, validationMessages.max30chars).required(validationMessages.required),
  newPasswordRepeat: string()
  .required(validationMessages.required)
  .test({
    name: "equalTo",
    exclusive: false,
    message: "Passwords do not match",
    params: {
      reference: ref("newPassword"),
    },
    test(value: any) {
      return value === this.resolve(ref("newPassword"));
    }
  })
}).defined();
