import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import PlanProbillsService from "../../app/data/plan-probills/planProbillsService";
import { DispatchCreateRouteState } from "./dispatchProbillsState";
import { getPlannableProbills } from "./dispatchPlanProbillsSlice";

const initialState: DispatchCreateRouteState = {
  loading: false,
  loadingFailed: false,
  loaded: false,
  routeId: "",
  error: ""
};

const planProbillsService = PlanProbillsService.getInstance();

export const dispatchCreateRouteSlice = createSlice({
  name: "dispatchCreateRoute",
  initialState,
  reducers: {
    resetState: (state) => initialState,
    loadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
    },
    loadingSucceed: (state, { payload }) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
      state.routeId = payload.routeId;
    },
    loadingFailed: (state, {payload}) => {
      state.loading = false;
      state.loadingFailed = true;
      state.routeId = "";
      state.error = payload;
    },
  },
});

export const {
  resetState,
  loadingStarted,
  loadingSucceed,
  loadingFailed
} = dispatchCreateRouteSlice.actions;

export const dispatchCreateRouteSlector = (state: IState) => state.dispatchCreateRoute;

export const createRoute = (
  routeRequest: any
): AppThunk => async (dispatch) => {
  dispatch(loadingStarted());
  const response = await planProbillsService.createRoute({ ...routeRequest });
  dispatch(getPlannableProbills(routeRequest.terminalNumber));
  if (response.ok() && response.data && response.data.result) {
    dispatch(loadingSucceed(response.data.results));
  } else {
    let error = "Internal Server Error, re-try the action or contact support";
    if(response && response.data && response.data.error) error = response.data.error;
    dispatch(loadingFailed(error));
  }
};

const dispatchCreateRouteReducer = dispatchCreateRouteSlice.reducer;
export default dispatchCreateRouteReducer;