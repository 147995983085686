import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import Table from "../../ui-components/table/table";
import CustomCell, { CustomCellTypes } from "../../ui-components/table/custom-cells/customCell";
import ContentContainer from "../../templates/content-container/contentContainer";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import UserState from "../../slices/user/UserState";
import { userSelector } from "../../slices/user/userSlice";
import { Routes } from "../../app/route/RoutesConfig";
import RatesState from "../../slices/rates/RatesState";
import {
  getRatesList,
  resetRatesState,
  ratesSelector
} from "../../slices/rates/ratesSlice";
import "./rates.scss";

const RequestRatesList: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userState: UserState = useSelector(userSelector);
  const ratesState: RatesState = useSelector(ratesSelector);

  const columns = [
    {
      width: 120,
      Header: "Rate Quote",
      accessor: "quoteNumber"
    },
    {
      width: 220,
      Header: "Origin",
      accessor: "origin"
    },
    {
      width: 220,
      Header: "Destination",
      accessor: "destination"
    },
    {
      width: 120,
      Header: "Date",
      accessor: "createdAt",
      Cell: (cellProps: any) => (
        <>
          { cellProps.value.toUiDateFormat() } { cellProps.value.split("T")[1] }
        </>
      )      
    },
    {
      width: 120,
      Header: "Expires",
      accessor: "expiresOn",
      Cell: (cellProps: any) => (
        <>
          { cellProps.value.toUiDateFormat() }
        </>
      )
    },
    {
      Header: (
        <div style={{ textAlign: "right" }}>
          Quote Total
        </div>
      ),       
      accessor: "total",
      Cell: (cellProps: any) => (
        <div style={{ textAlign: "right" }}>
          <CustomCell
            cellType={CustomCellTypes.amount}
            selector="total"
            data={cellProps.row.original}
            value={cellProps.value || "0"}
          />
        </div>
      ),
      width: 100
    }
  ];

  const onRatesInfiniteScroll = () => {
    dispatch(getRatesList(ratesState.ratesRequest.lastIds));
  };

  const onRateClick = (row: any) => {
    row?.id && history.push(`/${userState.activeSubAccount?.accountNumber}${Routes.rates.list}/${row.id}`);
  };

  useEffect(() => {
    dispatch(resetRatesState());
    dispatch(getRatesList());

    return () => {
      dispatch(resetRatesState());
    }
  }, [dispatch]);

  return (
    <ContentContainer title="Rate Quotes">
      <div className="xgs-rates__list">
        <div className="xgs-rates__request-buttons-area">
          <div className="xgs-rates__request-buttons">
            <Button
              theme={ButtonThemes.blue}
              onClick={() => { history.push(`/${userState.activeSubAccount?.accountNumber}${Routes.rates.guided}`); }}
              className="xgs-rates__request-button"
            >
              Guided Request
            </Button>
            <Button
              theme={ButtonThemes.blue}
              onClick={() => { history.push(`/${userState.activeSubAccount?.accountNumber}${Routes.rates.quick}`); }}
              className="xgs-rates__request-button"
            >
              Quick Request
            </Button>
          </div>
          <div className="xgs-rates__request-buttons-help">
            <div className="xgs-rates__request-buttons-help__item">
              The <span className="xgs-rates__request-buttons-help__item__keyword">guided request</span> will walk you through each step of your rate quote and is best used 
              if this is your first-time quoting a shipment.
            </div>
            <div className="xgs-rates__request-buttons-help__item">
              A <span className="xgs-rates__request-buttons-help__item__keyword">quick request</span> offers a single page form submission for those familiar with quoting shipments.
            </div>
          </div>
        </div>
        <div className="xgs-rates__notifications-help">
          You can disable receiving an email with the generated rate quote on the <Link className="blue-link" to="/settings/notifications">Notifications</Link> page.
        </div>
        {ratesState.requestFailed && (
          <XGSErrorMessage>{ratesState.requestError || "Error while obtaining the list of rates"}</XGSErrorMessage>
        )}
        {!ratesState.requestFailed && (
          <>
            <Table
              isLoading={ratesState.requestStarted}
              columns={columns}
              data={ratesState.rates}
              cursorPointer={true}
              onRowClicked={onRateClick}
              rowHeight={48}
              minTableHeight={200}
              noResultsText="You have not requested any rates yet"
              infiniteScroll={true}
              infiniteScrollLoading={ratesState.requestPortionStarted}
              infiniteScrollHasNext={!ratesState.ratesFetchedAll}
              onInfiniteScroll={onRatesInfiniteScroll}
            />
          </>
        )}
      </div>
    </ContentContainer>
  );
};

export default RequestRatesList;
