import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentContainer from "../../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import getPlanProbillsColumns from "./planProbillsColumns";
import PlanProbillsHeader from "./planProbillsHeader/planProbillsHeader";
import { resetSelectedProbillNumbers, setSelectedProbillNumbers, setSelectedTerminal } from "../../../slices/dispatch-planning/dispatchSelectedProbillsSlice";
import "./planProbills.scss";
import { PlannableProbills } from "../../../app/data/plan-probills/models";
import { dispatchPlanProbillsSelector, getPlannableProbills } from "../../../slices/dispatch-planning/dispatchPlanProbillsSlice";
import { DispatchPlanProbillsState } from "../../../slices/dispatch-planning/dispatchProbillsState";
import TableWithFilters from "../../../ui-components/table/tableWithFilters/tableWithFilters";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";

const PlanProbills: React.FC<{}> = memo((props) => {
  const dispatch = useDispatch();
  const [terminal, setTerminal] = useState<number | null | undefined>();
  const dispatchPlanProbillsState: DispatchPlanProbillsState = useSelector(
    dispatchPlanProbillsSelector
  );
  const userState: UserState = useSelector(
    userSelector
  );
  useEffect(() => {
    const userTerminal = userState.activeTerminal?.id;
    onTerminalChanged(userTerminal);
    // eslint-disable-next-line
  }, [userState.activeTerminal])

  useEffect(() => {
    return () => {
      dispatch(resetSelectedProbillNumbers());
    }
  }, [dispatch])

  const selectedProbillsChanged = React.useCallback(
    (state: {
      selectedRowsData: PlannableProbills[];
    }) => {
      const selectedProbill: PlannableProbills[] = state.selectedRowsData.map(
        (selectedRow) => selectedRow
      );
      dispatch(setSelectedProbillNumbers(selectedProbill));
    },
    [dispatch]
  );

  const onTerminalChanged = (userTerminal: number | null | undefined) => {
    setTerminal(userTerminal);
    dispatch(resetSelectedProbillNumbers());
    if (!userTerminal) return; // can not get the Probills without knowing the terminal
    dispatch(setSelectedTerminal(userTerminal));
    dispatch(getPlannableProbills(`${userTerminal}`));
  };

  return (
    <ContentContainer
      titleComponent={
        <ContentContainerToolbar
          title="Plan Route"
        >
        </ContentContainerToolbar>
      }
    >
      <PlanProbillsHeader />
      {(!terminal) &&
        <XGSErrorMessage>
          <div>
            You do not have assigned terminals.
            Email <a className="white-link" href="mailto:helpdesk@xgsi.com">helpdesk@xgsi.com</a> to request terminal assignment for your account.
          </div>
        </XGSErrorMessage>
      }
      {(terminal) &&
        <div className="xgs-plan-probills__table">
          <TableWithFilters
            isLoading={dispatchPlanProbillsState.loading}
            columns={getPlanProbillsColumns()}
            data={dispatchPlanProbillsState.plannableProbills}
            ignoreCellClick="selection"
            cursorPointer={true}
            onSelectedRowsChange={selectedProbillsChanged}
            infiniteScroll={false}
            responsive={true}
            sorting={true}
            enableMultiSort={true}
            filters={true}
          />
        </div>
      }
    </ContentContainer>
  );
});

export default PlanProbills;
