import ShipmentModel from "../../app/data/tracking/ShipmentModel";
import SearchShipmentRequestModel from "../../app/data/tracking/SearchShipmentRequestModel";
import { BaseState } from "..";

export default interface TrackShipmentState extends BaseState {
  count: number,
  shipments: ShipmentModel[],
  loadingPortion: boolean,
  loadedAll: boolean,
  error: string,
  loadingStats: boolean,
  loadingStatsFailed: boolean,
  loadedStats: boolean,
  loadingStatsError?: string,
  request: SearchShipmentRequestModel,
  teams: any,
  searchTeamStarted: boolean,
  searchTeamSuccess: boolean,
  searchTeamFailed: boolean,
  searchTeamError: string | null,
  loadingCSVStarted: boolean,
  loadingCSVSuccess: boolean,
  loadingCSVFailed: boolean,
  loadingCSVError: string | null,
  CSVLink: string | null,
  stats?: {
    appointmentRequired: number;
    appointmentPending: number;
  }
}

export const initialTrackShipmentState: TrackShipmentState = {
  count: 0,
  shipments: [],
  loading: false,
  loadingPortion: false,
  loadingFailed: false,
  loaded: false,
  loadedAll: false,
  loadingStats: false,
  loadingStatsFailed: false,
  loadedStats: false,
  loadingStatsError: "",
  error: "",
  request: {},
  teams: [],
  searchTeamStarted: false,
  searchTeamSuccess: false,
  searchTeamFailed: false,
  searchTeamError: null,
  loadingCSVStarted: false,
  loadingCSVSuccess: false,
  loadingCSVFailed: false,
  loadingCSVError: null,
  CSVLink: null
};
