import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import * as SmartySDK from "smartystreets-javascript-sdk";
import Modal from "react-modal";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import XGSFormInput from "../../../../ui-components/form/input/xgsFormInput";
import XGSFormPhoneInput from "../../../../ui-components/form/phoneInput/xgsFormPhoneInput";
import { XGSSelectOption } from "../../../../ui-components/xgs-select/xgsSelect";
import { LabelModes } from "../../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledSelectInput from "../../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import XGSIcons from "../../../../ui-components/icon/xgsIcons";
import XGSIcon from "../../../../ui-components/icon/xgsIcon";
import FormAddressSelector from "../../../../ui-components/form/address/formAddressSelector";
import {
  PickupConsigneeModel,
  PickupConsigneeSchema
} from "../../../../app/data/pickup/models";
import { StepProps } from "../../../../app/data/common/models";
import {
  pickupSelector,
  checkConsignee,
  getConsignees,
  setConsignee,
} from "../../../../slices/pickup/pickupSlice";
import { modalStyle } from "../../../../app/data/common/modalStyle";
import { ERROR_MESSAGES } from "../../../../app/data/common/errorMessages";

const smartyCore = SmartySDK.core;
const smartyLookup = SmartySDK.usAutocompletePro.Lookup;
const credentials = new smartyCore.SharedCredentials(process.env.REACT_APP_SMARTY_KEY || "");
const clientBuilder = new smartyCore.ClientBuilder(credentials).withLicenses([process.env.REACT_APP_SMARTY_LICENSE || ""]);
const client = clientBuilder.buildUsAutocompleteProClient();

let initialValues: PickupConsigneeModel = {
  name: "",
  address: {
    address1: "",
    city: "",
    postalCode: "",
    state: "",
    additionalAddressLine: "",
  },
  phone: "",
  email: ""
};

const BolConsigneeStep: React.FC<StepProps> = (props) => {
  const { previous, next } = props;
  const pickupState = useSelector(pickupSelector);
  const dispatch = useDispatch();
  const consigneeFormRef = useRef<any>(null);
  const [consigneeFormValue, setConsigneeFormValue] = useState<XGSSelectOption | null>();
  const [addressLookupValue, setAddressLookupValue] = useState<any>("");
  const [addressLookupStarted, setAddressLookupStarted] = useState<boolean>(false);
  const [phoneFieldValue, setPhoneFieldValue] = useState<string>("");
  const [postalCodeServiceable, setPostalCodeServiceable] = useState<boolean>(false);
  const [showConsigneeWarning, setShowConsigneeWarning] = useState<boolean>(false);
  const [showZipCheckError, setShowZipCheckError] = useState<boolean>(false);

  const consignees = () => {
    let options: XGSSelectOption[] = [];
    if (pickupState.consignees && pickupState.consignees.length > 0) {
      for (let i = 0; i < pickupState.consignees.length; i++) {
        options.push({
          label: pickupState.consignees[i].name,
          subtitle: `${pickupState.consignees[i].address.city}, ${pickupState.consignees[i].address.state}`,
          value: `${i}`
        });
      }
    }
    return options;
  };

  const onConsigneeSelect = (v: XGSSelectOption | null | undefined) => {
    setConsigneeFormValue(v);
    if (!v?.value || v.value === "-1" || !pickupState.consignees || pickupState.consignees.length === 0) return;
    let index = Number(v.value);
    let consignee = pickupState.consignees[index];
    consigneeFormRef.current?.resetForm();
    consigneeFormRef.current?.setFieldValue("name", consignee.name);
    consigneeFormRef.current?.setFieldValue("phone", consignee.phone.formatPhone());
    setPhoneFieldValue(consignee.phone.formatPhone());
    consigneeFormRef.current?.setFieldValue("email", consignee.email);
    locationSearch(`${consignee.address.address1}, ${consignee.address.city}, ${consignee.address.state}, ${consignee.address.postalCode}`, (res) => {
      setAddressLookupValue(res.length > 0 ? res[0] : null);
      consigneeFormRef.current?.setFieldValue("address.address1", res.length > 0 ? res[0]?.value.address : "");
      consigneeFormRef.current?.setFieldValue("address.city", res.length > 0 ? res[0]?.value.city : "");
      consigneeFormRef.current?.setFieldValue("address.postalCode", res.length > 0 ? res[0]?.value.zip : "");
      consigneeFormRef.current?.setFieldValue("address.state", res.length > 0 ? res[0]?.value.state : "");
      consigneeFormRef.current?.setFieldValue("address.additionalAddressLine", res.length > 0 ? consignee.address.additionalAddressLine : "");       
      setTimeout(() => {
        consigneeFormRef.current?.validateForm();
        checkZip();
      }, 50);
    });
  };

  const locationSearch = (inputValue: string, callback: (params: any) => void) => {
    if (!inputValue) return callback([]);
    setAddressLookupStarted(true);
    let lookup = new smartyLookup(inputValue);
    client.send(lookup).then((response) => {
      callback(response.result.map(item => {
        return {
          label: `${item.streetLine}, ${item.city}, ${item.state}, ${item.zipcode}`,
          value: {
            address: item.streetLine,
            city: item.city,
            state: item.state,
            zip: item.zipcode
          }
        };
      }));
      setAddressLookupStarted(false);
    }).catch((err) => {
      console.log("err:", err);
      setAddressLookupStarted(false);
    });
  };

  const onClickBack = (data: PickupConsigneeModel) => {
    dispatch(setConsignee(data));
    previous && previous();
  };

  const onClickNext = (data: PickupConsigneeModel) => {
    dispatch(setConsignee(data));
    next && next();
  };

  let checkZip = (zip?: string) => {
    setPostalCodeServiceable(false);
    setShowZipCheckError(false);
    let postalCode = zip || consigneeFormRef.current?.values.address.postalCode;
    if (!/^\d{5}$/.test(postalCode)) return;
    dispatch(checkConsignee(postalCode, () => {
      setPostalCodeServiceable(true);
    }, () => {      
      setShowConsigneeWarning(true);
      setShowZipCheckError(true);
    }, () => {
      toast.error(pickupState.requestError || "Error", { autoClose: 5000 });
    }));
    // eslint-disable-next-line
  };
  checkZip = debounce(checkZip, 800);

  useEffect(() => {
    initialValues = {
      name: pickupState.consignee.name,
      address: {
        address1: pickupState.consignee.address.address1,
        city: pickupState.consignee.address.city,
        postalCode: pickupState.consignee.address.postalCode,
        state: pickupState.consignee.address.state,
        additionalAddressLine: pickupState.consignee.address.additionalAddressLine,
      },
      phone: pickupState.consignee.phone,
      email: pickupState.consignee.email
    }
    consigneeFormRef.current?.setFieldValue("name", pickupState.consignee.name);
    if (pickupState.consignee.address.address1) {
      setAddressLookupValue({
        label: `${pickupState.consignee.address.address1}, ${pickupState.consignee.address.city}, ${pickupState.consignee.address.state}, ${pickupState.consignee.address.postalCode}`,
        value: {
          address: pickupState.consignee.address.address1,
          city: pickupState.consignee.address.city,
          state: pickupState.consignee.address.state,
          zip: pickupState.consignee.address.postalCode
        }
      });
    }
    consigneeFormRef.current?.setFieldValue("address.address1", pickupState.consignee.address.address1);
    consigneeFormRef.current?.setFieldValue("address.city", pickupState.consignee.address.city);
    consigneeFormRef.current?.setFieldValue("address.state", pickupState.consignee.address.state);    
    consigneeFormRef.current?.setFieldValue("address.postalCode", pickupState.consignee.address.postalCode);
    consigneeFormRef.current?.setFieldValue("address.additionalAddressLine", pickupState.consignee.address.additionalAddressLine);
    consigneeFormRef.current?.setFieldValue("phone", pickupState.consignee.phone);
    setPhoneFieldValue(pickupState.consignee.phone);
    consigneeFormRef.current?.setFieldValue("email", pickupState.consignee.email);
  }, [pickupState.consignee]);

  useEffect(() => {
    if (pickupState.consignees) return;
    dispatch(getConsignees());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPostalCodeServiceable(pickupState.consigneeCheckResult);
  }, [pickupState.consigneeCheckResult, pickupState.consignee.address.postalCode, pickupState.consigneeZipChecked]);

  return (
    <div className="xgs-pickup__consignee">
      <Formik
        onSubmit={onClickNext}
        initialValues={initialValues}
        validationSchema={PickupConsigneeSchema}
        innerRef={consigneeFormRef}
        validateOnMount
      >
        {(props: FormikProps<PickupConsigneeModel>) => (
          <Form>
            <div className="xgs-pickup__step-header">Consignee Information</div>
            <div className="xgs-gray-area xgs-pickup__field xgs-pickup__field--no-max-width" style={{display: "none"}}>
              <LabeledSelectInput
                name="consignee"
                label="Load Previous Consignee:"
                placeholder="Select..."
                isSearchable={false}
                labelMode={LabelModes.column}
                value={consigneeFormValue}
                onValueChange={onConsigneeSelect}
                options={consignees()}
                required={false}
                requiredAsteriskDisabled={true}
                formik={true}
                isLoading={pickupState.requestStarted && pickupState.requestCreator === "GET_CONSIGNEES"}
                className="xgs-pickup__field"
              />
            </div>
            <XGSFormInput
              type="text"
              name="name"
              label="Consignee:"
              required={true}
              requiredAsteriskDisabled={false}
              labelMode={LabelModes.column}
              className="xgs-pickup__field"
            />
            <div className="xgs-pickup__field">
              <FormAddressSelector
                editable={true}
                required
                addressLookupValue={addressLookupValue}
                setAddressLookupValue={(v) => setAddressLookupValue(v)}
                onZipChange={(zip) => {
                  if (/^\d{5}$/.test(zip)) {
                    checkZip(zip);
                  }
                }}
                isLoading={addressLookupStarted || (pickupState.requestStarted && pickupState.requestCreator === "CHECK_CONSIGNEE_ZIP")}
                error={showZipCheckError
                  ? ERROR_MESSAGES.ZIP_PICKUP_UNAVAILABLE
                  : ""}
              />
            </div>
            <div className="xgs-pickup__row xgs-pickup__row--last">
              <div className="xgs-pickup__row__item">
                <XGSFormPhoneInput
                  name="phone"
                  label="Phone:"
                  labelMode={LabelModes.column}
                  onValueChange={(value) => {
                    props.setFieldValue("phone", value);
                    setPhoneFieldValue(value);
                  }}
                  onBlur={props.handleBlur}
                  value={phoneFieldValue}
                  required={true}
                  requiredAsteriskDisabled={false}
                  className="xgs-pickup__field"
                />
              </div>
              <div className="xgs-pickup__row__item">
                <XGSFormInput
                  type="text"
                  name="email"
                  label="Email:"
                  required={false}
                  requiredAsteriskDisabled={true}
                  labelMode={LabelModes.column}
                  className="xgs-pickup__field"
                />
              </div>
            </div>
            <div className="xgs-form__field__notes">
              <strong>Note:</strong> by providing a telephone number and submitting this form you are consenting to be contacted by SMS text message.
              Message &amp; data rates may apply. You can reply STOP to opt-out of further messaging.
            </div>
            <div className="xgs-pickup__buttons">
              <Button
                type="button"
                theme={ButtonThemes.gray}
                onClick={() => onClickBack(props.values)}
              >
                Back
              </Button>
              <Button
                type="submit"
                theme={ButtonThemes.blue}
                disabled={!props.isValid || !props.values.name || !postalCodeServiceable}
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <Modal
        isOpen={showConsigneeWarning}
        style={modalStyle}
      >
        <>
          <div className="xgs-modal__content">
            <div className="xgs-bol__consignee__warning">
              <XGSIcon
                icon={XGSIcons.faExclamationCircle}
                className="xgs-bol__consignee__warning__icon"
              />
              <div>
                Unfortunately, we do not have network coverage at the zip code you provided so your pickup request cannot be processed through the portal.
              </div>
            </div>
          </div>
          <div className="xgs-modal__buttons xgs-bol__return__modal__buttons">
            <Button
              type="button"
              theme={ButtonThemes.blue}
              className="xgs-modal__button"
              onClick={() => setShowConsigneeWarning(false)}
            >
              Close
            </Button>
          </div>
        </>
      </Modal>
    </div>
  );
};

export default BolConsigneeStep;
