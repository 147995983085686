import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSFormCheckbox from "../../../ui-components/form/checkbox/xgsFormCheckbox";
import {
  ServicesModel,
  ServicesSchema
} from "../../../app/data/rates/models";
import { StepProps } from "../../../app/data/common/models";
import RequestRatesState from "../../../slices/rates/RequestRatesState";
import {
  requestRatesSelector, 
  setServices
} from "../../../slices/rates/requestRatesSlice";
import { services } from "../constants";

const SpecialServices: React.FC<StepProps> = (props) => {
  const dispatch = useDispatch();
  const { next, previous } = props;
  const requestRatesState: RequestRatesState = useSelector(requestRatesSelector);
  const servicesFormRef = useRef<any>(null);
  
  const onClickBack = (data: ServicesModel) => {
    dispatch(setServices(data.services));
    previous && previous();
  };

  const onSubmit = (data: ServicesModel) => {
    dispatch(setServices(data.services));
    next && next();
  };

  const initialValues: ServicesModel = {
    services: []
  };

  const isDisabled = (fieldValue: string, currentValues: string[]) => {
    return (fieldValue === "INSIDE_DELIVERY" && currentValues.find(service => service === "RESIDENTIAL_AREA"))
      || (fieldValue === "RESIDENTIAL_AREA" && currentValues.find(service => service === "INSIDE_DELIVERY"))
      || fieldValue === "HAZMAT";
  };

  useEffect(() => {
    initialValues.services = requestRatesState.services;
    servicesFormRef.current?.setFieldValue("services", requestRatesState.services);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="xgs-request-rates__pickup-services">
      <div className="xgs-request-rates__step__header">Special Services</div>
      <div className="xgs-request-rates__step__subheader">Please pick any additional services you need</div>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={ServicesSchema}
        innerRef={servicesFormRef}        
      >
        {(props: FormikProps<ServicesModel>) => (
          <Form className="form-items">
            <div className="xgs-request-rates__services-options">
              {services.map((field) =>
                <div
                  role="group"
                  aria-labelledby="pickup-services-list"
                  className="xgs-request-rates__services-options__row"
                  key={field.value}
                >
                  <XGSFormCheckbox
                    name="services"
                    value={field.value}
                    disabled={!!isDisabled(field.value, props.values.services)}
                  >
                    <div>
                      <div className="xgs-request-rates__services-options__name">{field.label}</div>
                      <div className="xgs-request-rates__services-options__description">{field.description}</div>
                    </div>
                  </XGSFormCheckbox>
                </div>
              )}
            </div>
            <Button
              theme={ButtonThemes.gray}
              onClick={() => { onClickBack(props.values) }}
              className="xgs-request-rates__step__nav-button"
            >
              Back
            </Button>
            <Button
              type="submit"
              theme={ButtonThemes.blue}
              className="xgs-request-rates__step__nav-button"
            >
              Next
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SpecialServices;
