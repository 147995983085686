import moment from "moment";
import dateFormats from "../../app/data/common/dateFormats";

export const getActivityLogColumns = () => [
{
    Header: "Event Time",
    accessor: "createdAt",
    width: 190,
    Cell: (cellProps: any) => (
      moment(cellProps?.value).format(dateFormats.uiDateTime)
    )
  },
  {
    Header: "User",
    accessor: "createdBy",
    width: 170,
  },
  {
    Header: "Action",
    accessor: "event",
    width: 140,
  },
  {
    Header: "Additional Info",
    accessor: "note",
    width: 270
  }
]
