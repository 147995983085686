import api, { ApiResponse } from "./../api";
import { ChatMessageRequestModel, ChatMessageResponseModel } from "./models";

class ChatService {
  private static instance: ChatService;

  private constructor() {}

  static getInstance(): ChatService {
    if (!ChatService.instance) {
      ChatService.instance = new ChatService();
    }
    return ChatService.instance;
  } 

  public sendMessage = async (message: ChatMessageRequestModel): Promise<ApiResponse<ChatMessageResponseModel>> => {
    return await api.post("/proxy/xgsai/chat/probill/query", message);    
  };
}

export default ChatService;
