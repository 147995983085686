
export default interface RecordsState {
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError?: string | null;
  requestCreator?: string | null;
  fetchPortionStarted: boolean;
  records: any[];
  recordsRequest: any;
  recordsFetchedAll: boolean;
  probills: any;
  probillsRaw: any;
  search: any;
}

export const initialRecordsState: RecordsState = {
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestError: null,
  requestCreator: null,
  fetchPortionStarted: false,
  records: [],
  recordsRequest: null,
  recordsFetchedAll: false,
  probills: [],
  probillsRaw: [],
  search: null
};
