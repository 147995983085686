import api, { ApiResponse } from "../api";
import { SubAccount } from "./models";

class CompanySettingsService {
  private static instance: CompanySettingsService;
  private constructor() {}

  static getInstance(): CompanySettingsService {
    if (!CompanySettingsService.instance) {
      CompanySettingsService.instance = new CompanySettingsService();
    }
    return CompanySettingsService.instance;
  }

  public getSubAccount = async (
    accountId: string
  ): Promise<ApiResponse<SubAccount>> => {
    return await api.get(`/accounts/${accountId}`);
  };

  public updateSubAccount = async (
    accountId: string,
    account: SubAccount
  ): Promise<ApiResponse<any>> => {
    return await api.post(`/accounts/${accountId}`, account);
  };

  public getUsers = async (
    all: boolean,
    lastIds?: string
  ): Promise<ApiResponse<any>> => {
    let params;
    if (all) {
      params = {
        all: true
      }
    } else if (lastIds) {
      params = { lastIds }
    }
    return await api.get("/accounts/users", { params });
  };

  public updateUser = async (
    userId: string,
    request: any
  ): Promise<ApiResponse<any>> => {
    return await api.put(`/accounts/users/${userId}`, request);
  };

  public getPermissions = async (
    accountId: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/accounts/${accountId}/permissions`);
  };

  public updatePermissions = async (
    accountId: string,
    user: string,
    permissions: string[]
  ): Promise<ApiResponse<any>> => {
    return await api.put(`/accounts/${accountId}/permissions`, {
      user,
      permissions
    });
  };

  public setAllUsersPermissions = async (
    accountId: string
  ): Promise<ApiResponse<any>> => {
    return await api.post(`/accounts/${accountId}/permissions/all`);
  };

  public sendShipperRequest = async (
    data: any
  ): Promise<ApiResponse<any>> => {
    return await api.post(`/teams/shippers/request`, data);
  };

  public getShippers = async (): Promise<ApiResponse<any>> => {
    return await api.get("teams/shippers?external=true&internal=true");
  };

  public getAccountDetailsChangeLog = async (accountId: string): Promise<ApiResponse<any>> => {
    return await api.get(`accounts/${accountId}/logs`);
  }
}

export default CompanySettingsService;
