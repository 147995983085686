
export default interface SignupByInviteState {
  confirmInvite_started: boolean;
  confirmInvite_failed: boolean;
  confirmInvite_succeed: boolean;
  confirmInvite_error: string | null;
  invite: any;
  signup_started: boolean;
  signup_failed: boolean;
  signup_succeed: boolean;
  signup_error: string | null;  
}

export const initialSignupByInviteState: SignupByInviteState = {
  confirmInvite_started: false,
  confirmInvite_failed: false,
  confirmInvite_succeed: false,
  confirmInvite_error: null,
  invite: null,
  signup_started: false,
  signup_failed: false,
  signup_succeed: false,
  signup_error: null
};
