import React, { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { StandingAppointment } from "./standingAppointment";
import { AccountOpenHoursType, accountOpenHoursTypes } from "../../../app/data/standing-appointment/models";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import styles from "./../../../sass/variables.module.scss";
import unavailableIconGrey from "../../../images/unavailable-grey.svg";
import unavailableIconWhite from "../../../images/unavailable-white.svg";
import { useSelector } from "react-redux";
import { accountInapplicableInformationSelector } from "../../../slices/account-inapplicable-information/accountInapplicableInformationSlice";

export const StandingAppointmentTabs: React.FC<any> = (props) => {
  const [selectedTab, setSelectedTab] = useState(AccountOpenHoursType.BUSINESS);
  const accountInapplicableInformationState = useSelector(accountInapplicableInformationSelector);

  const isInapplicableType = (type: AccountOpenHoursType) => {
    return accountInapplicableInformationState.disabledInformationTypes.includes(type);
  }

  const renderInapplicableIcon = (type: AccountOpenHoursType) => {
    if (!isInapplicableType(type)) return null;
    if (type === selectedTab) {
      return <>&nbsp;<img src={unavailableIconWhite} alt=""/></>
    } else {
      return <>&nbsp;<img src={unavailableIconGrey} alt=""/></>
    }
  }
  return (
    <>
      <div className="xgs-standing-appointment__heading">Availability Hours</div>
      <div className="xgs-bol__item-form__misc-text">
        <ul>
          <li>
            Non-service days are marked with &nbsp;
            <XGSIcon icon={XGSIcons.faCalendarTimes} color={styles.red} />.
          </li>
          <li>
            Times are displayed in <strong>local time-zone</strong> of the account.
          </li>
        </ul>
      </div>
      <Tabs defaultIndex={0} onSelect={(idx) => setSelectedTab(accountOpenHoursTypes[idx])}>
        <TabList>
          <Tab>Business {renderInapplicableIcon(AccountOpenHoursType.BUSINESS)}</Tab>
          <Tab>Shipping {renderInapplicableIcon(AccountOpenHoursType.SHIPPING)}</Tab>
          <Tab>Receiving {renderInapplicableIcon(AccountOpenHoursType.RECEIVING)}</Tab>
        </TabList>
        <TabPanel>
          <StandingAppointment accountId={props.accountId} type={AccountOpenHoursType.BUSINESS} />
        </TabPanel>
        <TabPanel>
          <StandingAppointment accountId={props.accountId} type={AccountOpenHoursType.SHIPPING} />
        </TabPanel>
        <TabPanel>
          <StandingAppointment accountId={props.accountId} type={AccountOpenHoursType.RECEIVING} />
        </TabPanel>
      </Tabs>
    </>
  );
};
