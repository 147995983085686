import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export interface FeatureBannerResponseModel {
  message: string;
  url: string;
  date: string;
};

export interface FeatureBannerFormModel {
  message?: string;
  url?: string;
  recipientType?: "CUSTOMER" | "EMPLOYEE";
  type: "POPUP" | "LINK_TO_PAGE";
  buttonLabel: string;
  popupText: string;
};

export const FeatureBannerSchema: Yup.ObjectSchema<FeatureBannerFormModel> = Yup.object().shape({
  message: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).required(validationMessages.required).max(150, "The value must be no more than 150 characters"),
  buttonLabel: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).required(validationMessages.required).max(20, "The value must be no more than 20 characters"),
  url: Yup.string()
    .max(255, "The value must be no more than 255 characters")
    .matches(/(\w+|-+)/, validationMessages.notValid)
    .when("type", {
      is: val => val === "LINK_TO_PAGE",
      then: Yup.string().max(255, "The value must be no more than 255 characters").matches(/(\w+|-+)/, validationMessages.notValid).required(validationMessages.required)
    }),
  popupText: Yup.string()
    .max(2048, "The value exceeds the maximum length")
    .matches(/(\w+|-+)/, validationMessages.notValid)
    .when("type", {
      is: val => val === "POPUP",
      then: Yup.string().max(2048, "The value exceeds the maximum length").matches(/(\w+|-+)/, validationMessages.notValid).required(validationMessages.required)
    })
}).defined();

export interface FeatureBannerImageResponseModel {
  id: string;
  uri: string;
  originalFileName: string;
};
