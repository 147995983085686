import { AccountInformationChangeLog, SubAccount } from "../../app/data/company-settings/models";

export default interface CompanyAccountDetailsState {
  fetch_was_started: boolean;
  fetch_was_succeed: boolean;
  fetch_was_failed: boolean;
  fetch_fail_reason?: string | null;  
  fetch_logs_started: boolean;
  fetch_logs_succeed: boolean;
  fetch_logs_failed: boolean;
  fetch_logs_fail_reason?: string | null;  
  update_was_started: boolean;
  update_was_succeed: boolean;
  update_was_failed: boolean;
  update_fail_reason?: string | null;
  accountDetails: SubAccount | null;
  changelogs?: AccountInformationChangeLog[] | null;
}

export const initialCompanyAccountDetailsState: CompanyAccountDetailsState = {
  fetch_was_started: false,
  fetch_was_succeed: false,
  fetch_was_failed: false,
  fetch_fail_reason: null,
  fetch_logs_started: false,
  fetch_logs_succeed: false,
  fetch_logs_failed: false,
  update_was_started: false,
  update_was_succeed: false,
  update_was_failed: false,
  update_fail_reason: null,
  accountDetails: null,
  changelogs: []
};
