import React from "react";
import XGSIcon from "../../ui-components/icon/xgsIcon";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import styles from "../../sass/variables.module.scss";
import XGSIcons from "../../ui-components/icon/xgsIcons";

export interface AppointmentColumnCellProps {
  date?: string;
  earlyTime?: string;
  lateTime?: string;
  exceptionStatus?: string;
  appointmentPending?: boolean;
}

export const AppointmentColumnCell: React.FC<AppointmentColumnCellProps> = (props) => {
  return (
    <div>
      {props.appointmentPending && (
        <div className="xgs-appointment-summary__appointment-cell xgs-appointment-summary__appointment-cell--caution">
          <XGSIcon color={styles.red} icon={faExclamationTriangle} /> <>Appt needed</>
        </div>
      )}
      {!props.appointmentPending && props.date && (
        <div className="xgs-appointment-summary__appointment-cell">
          <div className="xgs-appointment-summary__appointment-cell__check">
            <XGSIcon
              className="xgs-appointment-summary__appointment-cell__check__tick"
              icon={XGSIcons.faCheck}
            />
          </div>
          {props.date.toUiDateOrdinal()} <br /> {
            !!props.earlyTime && (
              <>
              {props.earlyTime?.toUiTimeFormat()} - {props.lateTime?.toUiTimeFormat()}
              </>
            )
          }
        </div>
      )}
      {props.exceptionStatus && (
        <div className="xgs-tracking__cells__warning"> {props.exceptionStatus.toUpperCase()}</div>
      )}
    </div>
  );
};
