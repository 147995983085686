import React, { DetailedHTMLProps, ButtonHTMLAttributes } from "react";

import "./labledInformation.scss";


export interface LabledInformationProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  label: string;
  information: any;
}

const LabledInformation: React.FC<LabledInformationProps> = ({
  label,
  information,
  ...props
}) => {
  return (
    <div className={`xgs-labled-information`} {...props}>
      <label>{label}</label>
      <span>{information}</span>
    </div>
  );
};

export default LabledInformation;
