import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import Helmet from "react-helmet";
import LogRocket from "logrocket";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import LoginTitle from "../loginTitle";
import { ForgotPasswordRequest, ForgotPasswordRequestSchema } from "../../../app/data/user/requestModels";
import ForgotPasswordState from "../../../slices/user/ForgotPasswordState";
import { forgotPasswordSelector, sendForgotPasswordRequest, resetForgotPassword } from "../../../slices/user/forgotPasswordSlice";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import Loading from "../../../ui-components/loading/loading";
import LoginButton from "../loginButton";
import LoginWrapper from "../loginWrapper";
import LoginContent from "../loginContent";
import "./forgotPassword.scss";

const initialValues: ForgotPasswordRequest = {
  login: ""
};

const ForgotPassword: React.FC<{}> = (props) => {
  const forgotPasswordState: ForgotPasswordState = useSelector(forgotPasswordSelector);
  const dispatch = useDispatch();

  const onSubmit = async (data: ForgotPasswordRequest) => {
    dispatch(sendForgotPasswordRequest(data));
  };

  useEffect(() => {
    dispatch(resetForgotPassword());
    if (process.env.REACT_APP_XGS_ENV === "production") {
      LogRocket.init("isggxn/xgs-customer-portal");
      window.addEventListener("unhandledrejection", e => {
        LogRocket.captureException(e.reason);
      });
    }

    return function cleanup() {
      dispatch(resetForgotPassword());
    };
  }, [dispatch]);

  return (
    <LoginWrapper>
      <LoginContent>
        <Helmet title="Forgot Password" />
        <LoginTitle>Forgot your password?</LoginTitle>
        {forgotPasswordState.failed && (
          <XGSErrorMessage className="xgs-login-failed-message xgs-login-failed-message--top">
            {forgotPasswordState.failReason
              ? (<>{forgotPasswordState.failReason}</>)
              : (<>Something went wrong!</>)
            }
          </XGSErrorMessage>
        )}
        {forgotPasswordState.started && (
          <Loading isLoading={true} />
        )}
        {!forgotPasswordState.succeed && (
          <>
            <div className="xgs-forgot-password__text">Enter your email address below and we'll send you password reset instructions.</div>
            <Formik
              onSubmit={onSubmit}
              initialValues={initialValues}
              validationSchema={ForgotPasswordRequestSchema}
            >
              {(props: FormikProps<ForgotPasswordRequest>) => (
                <Form className="form-items">
                  <XGSFormInput
                    type="text"
                    name="login"
                    label="Email:"
                    required={true}
                    requiredAsteriskDisabled={true}
                    labelMode={LabelModes.column}
                  />
                  <LoginButton
                    as={Button}
                    theme={ButtonThemes.blue}
                    type="submit"
                    disabled={forgotPasswordState.started}>
                    Send Instructions
                  </LoginButton>
                </Form>
              )}
            </Formik>
          </>
        )}
        {forgotPasswordState.succeed && (
          <div className="xgs-forgot-password__text">
            If this user exists, we have sent you a password reset email. Please check your inbox or spam folder.
          </div>
        )}
        <div className="xgs-site__box__link">Go back to <a href="/" className="blue-link">Log In</a></div>
      </LoginContent>
    </LoginWrapper>
  );
};

export default ForgotPassword;
