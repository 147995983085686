import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import RoutesService from "../../../app/data/plan-probills/routes/routesService";
import { RouteStopsState } from "./routesState";

const initialState: RouteStopsState = {
  loading: false,
  loadingFailed: false,
  loaded: false,
  routeStops: [],
  stopsForDispaly:[],
  terminalLocation: {},
  isRouteEditied: false,
  currentDistance: 0,
  currentDuration: 0
};

const routesService = RoutesService.getInstance();

export const routeStopsSlice = createSlice({
  name: "routeStops",
  initialState,
  reducers: {
    resetRouteStopsState: (state) => initialState,
    routeStopsLoadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
      state.isRouteEditied = false;
    },
    routeStopsLoadingSucceed: (state, { payload }) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
      state.routeStops = payload.stops;
      state.terminalLocation = payload.terminalLocation;
      state.isRouteEditied = false;

    },
    routeStopsLoadingFailed: (state) => {
      state.loading = false;
      state.loadingFailed = true;
      state.routeStops = [];
      state.isRouteEditied = false;

    },
    routeStopsSetDisplayStops: (state, { payload }) => {
      state.stopsForDispaly = payload;
    },
    routeStopsEdited: (state, {payload}) =>{
      state.isRouteEditied = payload;
    },
    routeStopsSetCurrentCost: (state, { payload }) => {
      state.currentDistance = payload.totalDistance;
      state.currentDuration = payload.totalDuration
    },
  },
});

export const {
  resetRouteStopsState,
  routeStopsLoadingStarted,
  routeStopsLoadingSucceed,
  routeStopsLoadingFailed,
  routeStopsSetDisplayStops,
  routeStopsEdited,
  routeStopsSetCurrentCost
} = routeStopsSlice.actions;

export const routeStopsSelector = (state: IState) => state.routeStops;

export const getRouteStops = (
  routeRequest: any
): AppThunk => async (dispatch) => {
  dispatch(routeStopsLoadingStarted());
  const response = await routesService.getRouteStops({ ...routeRequest });
  if (response.ok() && response.data && response.data.result) {
    dispatch(routeStopsLoadingSucceed(response.data.results));
  } else {
    dispatch(routeStopsLoadingFailed());
  }
};

const routeStopsReducer = routeStopsSlice.reducer;
export default routeStopsReducer;